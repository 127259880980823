<template>
  <div class="flex flex-row">
    <div class="bg-dark-primary text-white flex space-x-2 flex-row justify-start items-end text-xs pl-3 px-0.5 z-50" ref="menuBoundary">
        <div ref="viewMenuBoundary" class="flex items-end">
            <MenuButton class="text-xs" @click="toggleShowViewMenu()" >
                View
            </MenuButton>
        </div>
        <base-popover-click
            v-if="showViewMenu"
            :xPosition="xPositionView"
            :yPosition="yPositionView"
        >
          <ViewMenu/>
        </base-popover-click>
        <!-- <div v-show="userPermissions.isDev ||
              userPermissions.isAccountManager ||
              userPermissions.isManagement ||
              userPermissions.isSuperuser ||
              userPermissions.isAccountStaff"
            ref="actionMenuBoundary"
            class="flex items-end">
            <MenuButton class="text-xs mt-1" @click="toggleShowActionMenu()" >
                Actions
            </MenuButton>
        </div>
        <base-popover-click
            v-if="showActionMenu"
            :xPosition="xPositionAction"
            :yPosition="yPositionAction"
        >
          <ActionMenu @hide-menu="hideActionMenu"/>
        </base-popover-click> -->
    </div>
    <!-- <div v-show="userPermissions.isDev" class="bg-dark-primary text-white flex space-x-2 flex-row justify-start items-end text-xs px-0.5 " ref="menuBoundaryConfig">
      <div class="flex items-end" ref="configMenuBoundary">
        <MenuButton class="text-xs mt-1" @click="toggleShowConfigMenu">
                  Page Configs
        </MenuButton>
      </div>
      <base-popover-click v-if="showConfigMenu" :xPosition="xPositionConfig" :yPosition="yPositionConfig">
        <ConfigMenu />
      </base-popover-click>
    </div> -->
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
// import { useClickOutside } from '@/composables/useClickOutside';
import MenuButton from './MenuButton.vue';
import ViewMenu from './ViewMenu.vue';
import ActionMenu from './ActionMenu.vue';
import ConfigMenu from './ConfigMenu.vue';
import { useClickOutside } from '@/composables/useClickOutside';
import { useStore } from 'vuex';

const xPositionView = ref(0)
const yPositionView = ref(0)
const xPositionAction = ref(0)
const yPositionAction = ref(0)
const xPositionConfig = ref(0)
const yPositionConfig = ref(0)

const isFormVisible = ref(false)

const store = useStore()

const props = defineProps(['userPermissions'])

const emit = defineEmits([])

const showViewMenu = ref(false);
const showActionMenu = ref(false);
const showConfigMenu = ref(false)
const viewMenuBoundary = ref(null);
const actionMenuBoundary = ref(null);
const menuBoundary = ref(null);
const configMenuBoundary = ref(null);
const menuBoundaryConfig = ref(null)

useClickOutside(menuBoundary, ()=> {
  hideViewMenu()
  hideActionMenu()
})

useClickOutside(menuBoundaryConfig, ()=> {
  // console.log(menuBoundaryConfig)
  hideConfigMenu()
})

const toggleShowViewMenu = () => {
    // console.log('show view menu fired')
    if(!showViewMenu.value){
        showViewMenu.value = true
        xPositionView.value = viewMenuBoundary.value.getBoundingClientRect().left
        yPositionView.value = viewMenuBoundary.value.getBoundingClientRect().bottom
        hideActionMenu()
    } else {
        hideViewMenu()
    }
}

const hideViewMenu = () => {
    // console.log('hide view menu fired')
    showViewMenu.value = false
    xPositionView.value = 0
    yPositionView.value = 0
}

const toggleShowActionMenu = () => {
    // console.log('show view menu fired')
    if(!showActionMenu.value){
        showActionMenu.value = true
        xPositionAction.value =  actionMenuBoundary.value.getBoundingClientRect().left
        yPositionAction.value = actionMenuBoundary.value.getBoundingClientRect().bottom
        hideViewMenu()
    } else {
        hideActionMenu()
    }
}

const hideActionMenu = () => {
    // console.log('hide view menu fired')
    showActionMenu.value = false
    xPositionAction.value = 0
    yPositionAction.value = 0
}

async function toggleShowConfigMenu(){
  if(!showConfigMenu.value){
    showConfigMenu.value = true
    xPositionConfig.value = configMenuBoundary.value.getBoundingClientRect().left
    yPositionConfig.value = configMenuBoundary.value.getBoundingClientRect().bottom
  } else {
    hideConfigMenu()
  }
}
  
function hideConfigMenu(){
  showConfigMenu.value = false
  xPositionConfig.value = 0
  yPositionConfig.value = 0
}

const toggleCompanyFilterPane = () => {
  emit('toggle-company-filter-pane')
}


function toggleCompanyInfoFilters() {
  emit('toggle-company-info-filters')
}
  
function toggleCampaignInfoFilters() {
  emit('toggle-campaign-info-filters')
}
  
function toggleResearchInfoFilters() {
  emit('toggle-research-info-filters')
}

function setResetFiltersTrue() {
  emit('set-reset-filters-true')
}

function toggleBulkOperationPane() {
  emit('toggle-bulk-operation-pane')
}

function closeForm() {
  isFormVisible.value = false
}

function fetchNewCompanies() {
  emit('refetch-companies')
}




</script>
<template>
    <div class="mt-2">
      <div class="flex w-full justify-start mb-3">
        <div class="flex space-x-5">
          <div class="flex text-base items-end ml-2">
            Timecard Edit History
          </div>
          <div class="flex flex-row px-2 py-2 border">
            <div class="my-auto text-sm">
              Number of Rows: 
            </div>
            <select 
              class="my-auto bg-slate-200 text-base ml-1"
              v-model="numberOfRecords"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="0">All</option>
            </select>
            <base-button-affirmative @click="getHistory" class="ml-3 text-base">
              Get Records
            </base-button-affirmative>
          </div>
        </div>
      </div>
      <div class="">

          <div v-show="!isTableVisible && !isLoading" class="flex w-full h-full items-center">
            <div class="text-lg text-center justify-center mx-auto items">
              To view the timecard's history of edits, select the number of records you<br>would like to view and click the "Get Records" button.
            </div>
          </div>
          <div>
            <table 
            v-show="!isLoading && isTableVisible"
            class="w-full h-full">
              <!-- <thead>
                <tr>
                  <th class="min-w-[1/7]"></th>
                  <th class="min-w-[3/7]"></th>
                  <th class="min-w-[3/7]"></th>
                </tr>
              </thead> -->
              <tbody v-for="history in histories" :key="JSON.stringify(history)">
                <tr v-if="history.changes.length == 0" class="border-dark-primary border-b">
                  <td colspan="7">
                    <div class="w-full flex flex-row justify-between py-2" nowrap>
                      <div class="ml-2 text-sm">{{ parseDate(history.change_date) }}</div>
                      <div class="text-sm">{{ history.change_user ? `${history.change_user[0].first_name} ${history.change_user[0].last_name}` :  ""}}</div>
                      <div class="mr-2 text-sm">{{ history.change_type }}</div>
                    </div>
                  </td> 
                </tr>
                <tr v-if="history.changes.length != 0" class="border-dark-primary border-b">
                  <td colspan="7">
                    <div class="w-full flex flex-row justify-between pt-3 pb-1" nowrap>
                      <div class="ml-2 text-sm">{{ parseDate(history.change_date) }}</div>
                      <div class="text-sm">{{ history.change_user ? `${history.change_user[0].first_name} ${history.change_user[0].last_name}` :  ""}}</div>
                      <div class="mr-2 text-sm">{{ history.change_type }}</div>
                    </div>
                  </td> 
                </tr>
                  <!-- <base-table-cell class="text-lg">
                    {{ parseDate(history.change_date) }}
                  </base-table-cell>
                  <base-table-cell>
                    {{ history.change_title }}
                  </base-table-cell>
                  <base-table-cell v-if="history.change_user">
                    {{ history.change_user[0].first_name }} {{ history.change_user[0].last_name }}
                  </base-table-cell>
                  <base-table-cell v-if="!history.change_user">
                  </base-table-cell>
                  <base-table-cell>
                    {{ history.change_type }}
                  </base-table-cell> -->
                <tr v-if="history.changes.length != 0" class="bg-gray-100">
                  <base-table-cell class="w-1/5 min-w-min border-slate-300 border-l border-b border-t-dark-primary" nowrap>
                    Changed Field
                  </base-table-cell>
                  <base-table-cell class="w-2/5 min-w-min border-slate-300 border-l border-b border-t-dark-primary" colspan="3">
                    Old Value
                  </base-table-cell>
                  <base-table-cell class="w-2/5 min-w-min border-slate-300 border-l border-r border-b border-t-dark-primary" colspan="3">
                    New Value
                  </base-table-cell>
                </tr>
                <tr v-for="change in history.changes" :key="JSON.stringify(change)">
                  <td
                    class="border-l border-b border-r pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top border-slate-300"
                  >
                    {{ change.change_field }}
                  </td>
                  <td
                    colspan="3"
                    class="border-b border-r pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top bg-red-100 border-slate-300"
                  >
                    {{ change.change_old_field }}
                  </td>
                  <td
                    colspan="3"
                    class="border-r border-b pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top bg-green-100 border-slate-300"
                  >
                    {{ change.change_new_field }}
                  </td>
                </tr>
                <tr v-if="history.changes.length != 0">
                  <td colspan="7" class="py-2">
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="isLoading && !isTableVisible" role="status" class="pl-3 pb-3 w-full flex">
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <svg
                    aria-hidden="true"
                    class="mx-2 mt-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="white"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#2E9CCA"
                    />
                  </svg>
                  <div class="mt-2 text-lg">
                    Getting History...
                  </div>
                </div>
                <div class="mt-2 ml-4">
                  &#x26A0; This may take longer than expected.
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </template>
  
  <script setup>
    import { ref, defineProps, defineEmits} from 'vue';
    import { getTimecardHistory } from '@/services/Timecard'
    import { useStore } from 'vuex';
    import { useToast } from 'vue-toast-notification';
  
    const $toast = useToast()
    const store = useStore()
    const props = defineProps({
      timecard: Object
    });
  
    const histories = ref([])
    const isLoading = ref(false)
    const isTableVisible = ref(false)
    const numberOfRecords = ref(10)
  
    async function loadTimecardHistory() {
      isLoading.value = true;
      const token = store.state.auth.token;
      const timecardId = props.timecard.id;
      try {
        const result = await getTimecardHistory(token, timecardId, numberOfRecords.value);
        isTableVisible.value = true;
        histories.value = result.data.histories
        // console.log(histories.value)
      } catch(exception) {
        isTableVisible.value = false;
        $toast.open(
          {
            message: `Error - could not retrieve history.`,
            type: 'error',
            duration: 20000
          }
        )
      }
  
      isLoading.value = false;
    }
  
    async function getHistory() {
      if (isLoading.value) {
        $toast.open(
          {
            message: `Please wait until after records are done loading.`,
            type: 'warning',
            duration: 20000
          }
        )
        return
      }
      isTableVisible.value = false;
      await loadTimecardHistory();
    }
  
    function parseDate(datestring) {
      const date = new Date(datestring)
      const formattedDate = date.toLocaleString()
      return formattedDate;
    }
  </script>
import axios from "axios";

import { BASE_URL } from "./api";

export async function getPartsManufacturerApprovals(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/pma_list/${payload.endpoint}`,
    headers: {
        'Authorization': `Token ${payload.token}`
    },
  })
    .then((response) => {
      return {
        approvals: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        page_size: response.data.page_size,
        num_pages: response.data.num_pages,
      };
    })
    .catch((error) => {
      throw error;
    });
}

export async function getCompanyNameFilterChoices(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/pma_list/get_company_name_filter_choices/`,
    headers: {
        'Authorization': `Token ${payload.token}`
    },
  })
    .then((response) => {
      return {
        company_names: response.data.company_names,
      };
    })
    .catch((error) => {
      throw error;
    });
}

// export async function createReferrer(formData, token) {
//     return axios({
//         method: 'post',
//         url: `${BASE_URL}/referrers/`,
//         headers: { 
//             'Authorization': `Token ${token}`, 
//             'Content-Type': 'application/json'
//         },
//         data: formData
//     }).then((response) => {
//         console.log(response);
//         return response
//     }).catch((error) => {
//         throw error
//     })
// }

// export async function updateReferrer(referrerId, formData, token) {
//   console.log(`${BASE_URL}/referrers/${referrerId}/`);
//   console.log(formData)
//   return axios({
//       method: 'put',
//       url: `${BASE_URL}/referrers/${referrerId}/`,
//       headers: { 
//           'Authorization': `Token ${token}`, 
//           'Content-Type': 'application/json'
//       },
//       data: formData
//   }).then((response) => {
//       return response
//   }).catch((error) => {
//       throw error
//   })
// }
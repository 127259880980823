<template>
    <div>
        <RevenueHeadcountCell :company="company" />
        <ZiRevenueHeadcountCell :company="company"/>
    </div>
</template>

<script>
    export default {
        name: "zi-revenue-headcount-revenue-headcount-cell"
    }
</script>

<script setup>
import { defineProps } from 'vue';
import ZiRevenueHeadcountCell from '../company-cell-components/ZiRevenueHeadcountCell.vue';
import RevenueHeadcountCell from '../company-cell-components/RevenueHeadcountCell.vue';

    const props = defineProps({
        company: Object
    })
</script>
export default {
    setCampaigns(state, campaigns) {
        console.log('setCampaigns Mutation')
        state.campaigns = campaigns;
        console.log("campaigns list:", campaigns)
    },
    setIsLoadingTrue(state) {
        state.isLoading = true;
    },
    setIsLoadingFalse(state) {
        state.isLoading = false;
    },
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
}
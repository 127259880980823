<template>
  <div class="flex justify-center h-screen">
    <div class="flex py-10 w-2/3 h-2/3">
      <div class="flex flex-col border border-gray-200 h-11/12">
        <div
          class="text-base sm:text-[8px] md:text-[10px] lg:text-xs xl:text-sm 2xl:text-xl font-bold text-dark-primary pb-4 text-start ml-4"
        >
          ZoomInfo
        </div>
        <div class="flex justify-end border border-gray-200 h-11/12">
          <div class="px-2">
            <VideoCard
              source="https://aerocle-instructional-videos.s3.amazonaws.com/zoom_info_walkthrough.mp4"
              title="What is ZoomInfo?"
              :imageObject="zoomInfo"
            ></VideoCard>
          </div>
          <div class="px-2">
            <VideoCard
              source="https://aerocle-instructional-videos.s3.amazonaws.com/Company+Contacts.mp4"
              title="Company Contacts and Get ZoomInfo Contacts"
              :imageObject="contactInfo"
            ></VideoCard>
          </div>
        </div>
      </div>
      <div class="flex flex-col border border-gray-200 h-11/12">
        <div
          class="text-base sm:text-[8px] md:text-[10px] lg:text-xs xl:text-sm 2xl:text-xl font-bold text-dark-primary pb-4 text-start ml-4"
        >
          Aerocle
        </div>
        <div class="flex justify-start h-11/12">
          <div class="px-2">
            <VideoCard
              source="https://aerocle-instructional-videos.s3.amazonaws.com/do_not_research_reasons.mp4"
              title="Translate Legacy Do Not Research Reasons Tutorial"
              :imageObject="legacy"
            ></VideoCard>
          </div>
          <div class="px-2">
            <VideoCard
              source="https://aerocle-instructional-videos.s3.amazonaws.com/resolve_duplicates_walkthrough.mp4"
              title="Resolve Duplicates Tutorial"
              :imageObject="duplicates"
            ></VideoCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VideoCard from "../components/instructional_videos/VideoCard.vue";
import { ref } from "vue";

const legacy = ref({
  filePath: require("../assets/images/Legacy.png"),
});

const duplicates = ref({
  filePath: require("../assets/images/Duplicates.png"),
});

const zoomInfo = ref({
  filePath: require("../assets/images/ZoomInfo.png"),
});

const contactInfo = ref({
  filePath: require("../assets/images/CompanyContacts.png"),
});
</script>

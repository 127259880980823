<template>
    <div class="flex flex-row space-x-2 justify-start items-center whitespace-nowrap py-1">
        <div class="ml-2 text-sm">Quick Links:</div>
        <div class="flex flex-row space-x-2 justify-start items-center">
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="websiteLink" target="_blank" v-if="hasValidWebsiteUrl">Website</a>
            <p v-if="hasValidWebsiteUrl">|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="waybackMachineLink" target="_blank">Wayback Machine</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="googleLink" target="_blank">Google</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="openCorporatesLink" target="_blank">Open Corporates</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="cageReportLink" target="_blank">Cage Report</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="dunnAndBradstreetLink" target="_blank">D & B</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="buzzfileLink" target="_blank">Buzzfile</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" href="https://tools.emailhippo.com" target="_blank">Email Hippo</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" href="https://www.zerobounce.net" target="_blank">Zero Bounce</a>
            <p>|</p>
            <a class="text-accent-primary hover:underline hover:cursor-pointer" :href="acquiredByLink" target="_blank">Acquired By</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "company-quick-links"
}
</script>

<script setup>
import {defineProps, computed } from 'vue';

const props = defineProps({
    company: Object,
})

const hasValidWebsiteUrl = computed(() => {
    if(props.company.website_url) {
        if(!props.company.website_url.includes('no-url-')){
            return true
        }
    }
    return false
})

const websiteLink = computed(() => {
    if(props.company.website_url) {
        return props.company.website_url
    } else {
        return null
    }
})

const waybackMachineLink = computed(() => {
    if(props.company.website_url) {
        return `https://web.archive.org/web/20230000000000*/${props.company.website_url}`
    } else {
        return 'https://web.archive.org/web/20230000000000*/'
    }
})

const googleLink = computed(() => {
    return `https://www.google.com/search?q=${props.company.company_name}`
})

const openCorporatesLink = computed(() => {

    return `https://opencorporates.com/companies?q=${props.company.company_name}`
})

const cageReportLink = computed(() => {
    let formattedCompanyName = props.company.company_name.split(' ').join('%2520')
    return `https://cage.report/Search/${formattedCompanyName}`
})

const dunnAndBradstreetLink = computed(() => {
    return `https://www.dnb.com/site-search-results.html#AllSearch=${props.company.company_name}`
})

const buzzfileLink = computed(() => {
    return `https://www.buzzfile.com/Search/Company/Results?searchTerm=${props.company.company_name}&type=1&page=1`
})

const acquiredByLink = computed(() => {
    let state = ''
    if(props.company.state) {
        state = `%20${props.company.state}`
    }
    return `https://www.google.com/search?q=${props.company.company_name}${state}%20acquisition`
})


</script>
<template>
    <div>
        <span>{{company.source_owner}}</span>
    </div>
</template>

<script>
    export default {
        name: "source-owner-cell"
    }
</script>

<script setup>
import { defineProps } from 'vue';

    const props = defineProps({
        company: Object
    })
</script>
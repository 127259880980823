<template>
    <div :class="{'bg-red-200' : engagement.is_missing_invoices}" class="whitespace-pre-wrap break-before-auto min-w-fit">
        {{ engagement.is_missing_invoices ? engagement.is_missing_invoices : '--' }}
    </div>
</template>

<script>
export default {
    name: 'engagement-missing-invoice-cell'
}
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    engagement: Object
})

</script>

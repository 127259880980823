<template>
    <div>
        <div @dblclick="toggleEditable" v-if="isEditable">
        <EditEngagementInput :engagement-id="engagement.id" :input-text="engagement.target_invoice_date" input-type="number" field-name="target_invoice_date" @refetch-engagements="fetchNewEngagements" @toggle-editable="toggleEditable" />
        </div>
        <div @dblclick="toggleEditable"  class="text-center" v-else>
            {{ targetInvoiceDate }}
        </div>
    </div>
</template>

<script>
export default {
    name: "engagement-target-invoice-date-cell"
}
</script>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import EditEngagementInput from './EditEngagementInput.vue';

const props = defineProps({
    engagement: Object
})

const targetInvoiceDate = computed(() => {
    if(props.engagement.target_invoice_date){
        if(props.engagement.target_invoice_date.toString().endsWith('1')){
            return `${props.engagement.target_invoice_date}st`
        } else if (props.engagement.target_invoice_date.toString().endsWith('2')){
            return `${props.engagement.target_invoice_date}nd`
        } else {
            return `${props.engagement.target_invoice_date}th`
        } 
    } else {
        return '--'
    }
})

const isEditable = ref(false)

const emit = defineEmits(['refetch-engagements'])

function fetchNewEngagements(){
    emit('refetch-engagements')
}

function toggleEditable(){
    isEditable.value = !isEditable.value
}

</script>


<template>
    <div>
        {{ company.raw_address }}
    </div>

</template>

<script>
    export default {
        name: "raw-address-cell"
    }
</script>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        company: Object
    })
</script>
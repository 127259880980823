<template>
    <base-table-row :isSelected="isSelected">
      <base-table-cell>
        <input type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
      </base-table-cell>
      <base-table-cell>
        <div @click="showForm('')" class="text-accent-primary hover:text-dark-primary hover:cursor-pointer hover:underline">
          {{ company.id }}
          {{ company.status }}
        </div>
        <div>
          <base-button v-if="company.campaign" @click="showDetails('crmDetails')">CRM Details</base-button>
        </div>
        <div>
          <base-button @click="showDetails('activityDetails')">Email History</base-button>
        </div>
        <div class="grid grid-cols-2">
            <div class="hover:cursor-pointer w-fit p-1" @click="showForm('contracts')">
                <img src="@/assets/images/contract.png" class="h-4 hover:h-5 " alt="">
            </div>
            <div class="hover:cursor-pointer w-fit p-1 flex items-center" @click="showForm('contacts')" @click.right.prevent="showForm('researchPosts')">
                <div class="h-full text-base hover:text-lg">👥</div>
            </div>
            <div class="hover:cursor-pointer w-fit p-1" @click="showForm('capabilities')">
                <img src="@/assets/images/capabilities.png" class="h-4 hover:h-5 " alt="">
            </div>
            
        </div>
        <base-modal :is-form-visible="showCRMDetails" @close="closeDetails">
          <template #header>
            <h2 class="text-lg">
              {{ company.company_name }} CRM Details
            </h2>
          </template>
          <template #body>
            <CRMDetailsPage 
            :company="company" 
            :campaigns="campaigns" 
            :crm-statuses="crmStatuses"
            :crm-action-types="crmActionTypes"
            @refetch-companies="fetchNewCompanies" />
          </template>
        </base-modal>
        <base-modal :is-form-visible="showActivityDetails" @close="closeDetails">
          <template #header>
            <h2 class="text-lg">
              {{ company.company_name }} Activities
            </h2>
          </template>
          <template #body>
            <CompanyActivitiesPage :company="company" />
          </template>
        </base-modal>
        <base-modal :is-form-visible="isFormVisible" @close="closeForm">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="company"
              :campaigns="campaigns"
              @close="closeForm"
              @refetch-companies="fetchNewCompanies"
              :default-tab="defaultTab"
            />
          </template>
        </base-modal>
      </base-table-cell>
      <base-table-cell v-for="cell in companyCells" :key="cell.id">
        <component :is="cell.company_cell.component_name" :company="company" :permissions="permissions" :crm-statuses="crmStatuses" :campaigns="campaigns" @refetch-companies="fetchNewCompanies" ></component>
      </base-table-cell>
</base-table-row>
  </template>
  
<script>
import CompanyNameDBATierPCINVCityStateURLCell from './company-compound-cells/CompanyName+DBA+Tier+PC+INV+City+State+URLCell.vue';
import AccountManagerNotesAccountManagerResearchNotesCell from './company-compound-cells/AccountManagerNotes+AccountManagerResearchNotesCell.vue';
import ClientFeedbackClientFeedbackButtonsCell from './company-compound-cells/ClientFeedback+ClientFeedbackButtonsCell.vue';
import ClientFeedbackClientFeedbackButtonsClientMeetingNotesCell from './company-compound-cells/ClientFeedback+ClientFeedbackButtons+ClientMeetingNotesCell.vue';
import ClientFeedbackClientMeetingNotesCell from './company-compound-cells/ClientFeedback+ClientMeetingNotesCell.vue';
import CompanyNameCageCodeCell from './company-compound-cells/CompanyName+CageCodeCell.vue';
import CompanyNameDBATierPCINVCRMSummaryCell from './company-compound-cells/CompanyName+DBA+Tier+PC+INV+CRMSummaryCell.vue';
import CRMNextActionDueDateMarkNextActionButtons from './company-compound-cells/CRMNextActionDueDate+MarkNextActionButtons.vue';
import NameDBADescriptionCustomerNotesPlatformNotesPCINVMgrNotes from './company-compound-cells/Name+DBA+Description+CustomerNotes+PlatformNotes+PC+INV+MgrNotes.vue';
import PrimaryContactAllContactsCell from './company-compound-cells/PrimaryContact+AllContactsCell.vue';
import RawAddressURLCell from './company-compound-cells/RawAddress+URLCell.vue';
import RevenueHeadcountLocationURLCell from './company-compound-cells/Revenue+Headcount+Location+URLCell.vue';
import RevenueHeadcountSqFtCell from './company-cell-components/RevenueHeadcountSqFtCell.vue';
import TierTierButtonsResearchNeededCell from './company-compound-cells/Tier+TierButtons+ResearchNeededCell.vue';
import ZiRevenueHeadcountRevenueHeadcountCell from './company-compound-cells/ZiRevenueHeadcount+RevenueHeadcountCell.vue';
import ZiRevenueHeadcountRevenueHeadcountSqFtCell from './company-compound-cells/ZiRevenueHeadcount+RevenueHeadcountSqFtCell.vue';
import ZiRevenueHeadcountRevenueHeadcountRevLastUpdatedCell from './company-compound-cells/ZiRevenueHeadcount+RevenueHeadcount+RevLastUpdatedCell.vue';
import ZiUrlWebsiteUrlUrlVettedByCell from './company-compound-cells/ZiUrl+WebsiteUrl+UrlVettedByCell.vue';
import EditCompany from './EditCompany.vue';
import NameDBADescriptionPCINVNotesCell from './company-compound-cells/Name+DBA+Description+PC+INV+NotesCell.vue';
import CompanyNameCell from './company-cell-components/CompanyNameCell.vue';
import CampaignTierCell from './company-compound-cells/Campaign+TierCell.vue';
import CampaignTierTierButtonsResearchNeededRemoveFromCampaignCell from './company-compound-cells/Campaign+Tier+TierButtons+ResearchNeeded+RemoveFromCampaignCell.vue';
import CampaignRemoveFromCampaignCell from './company-compound-cells/Campaign+RemoveFromCampaignCell.vue';
import ZiIdGetZiIdGetZiBasicClearZiCell from './company-compound-cells/ZiId+GetZiId+GetZiBasic+ClearZiCell.vue';
import ZiLocationLocationTransferZiCell from './company-compound-cells/ZiLocation+Location+TransferZiCell.vue';
import ZiUrlUrlCell from './company-compound-cells/ZiUrl+UrlCell.vue';
import UrlVettedCell from './company-cell-components/UrlVettedCell.vue';
import SourceSourceOwnerCell from './company-compound-cells/Source+SourceOwnerCell.vue';
import ZiRevenueHeadcountRevenueHeadcountSqFtRevLastUpdatedCell from './company-compound-cells/ZiRevenueHeadcount+RevenueHeadcountSqFt+RevLastUpdatedCell.vue';
import CageCodeCell from './company-cell-components/CageCodeCell.vue';
import ResearchCompleteCell from './company-cell-components/ResearchCompleteCell.vue';
import AccountManagerNotesCell from './company-cell-components/AccountManagerNotesCell.vue';
import AcctMgrResearchNotesCell from './company-cell-components/AcctMgrResearchNotesCell.vue';
import AddTaskModalCell from './company-cell-components/AddTaskModalCell.vue';
import AllContactsCell from './company-cell-components/AllContactsCell.vue';
import CampaignCell from './company-cell-components/CampaignCell.vue';
import ClearZiButtonCell from './company-cell-components/ClearZiButtonCell.vue';
import ClientFeedbackCell from './company-cell-components/ClientFeedbackCell.vue';
import ClientMeetingNotesCell from './company-cell-components/ClientMeetingNotesCell.vue';
import CollectionTagCell from './company-cell-components/CollectionTagCell.vue';
import CRMActivityOutcomeCell from './company-cell-components/CRMActivityOutcomeCell.vue';
import CRMNextActionCell from './company-cell-components/CRMNextActionCell.vue';
import CRMStatusCell from './company-cell-components/CRMStatusCell.vue';
import CRMSummaryCell from './company-cell-components/CRMSummaryCell.vue';
import CustomerNotesCell from './company-cell-components/CustomerNotesCell.vue';
import DaysSinceLastActivityCell from './company-cell-components/DaysSinceLastActivityCell.vue';
import DaysUntilNextActionCell from './company-cell-components/DaysUntilNextActionCell.vue';
import DaysUntilNextTaskDueCell from './company-cell-components/DaysUntilNextTaskDueCell.vue';
import DBACell from './company-cell-components/DBACell.vue';
import DrivingTimeCell from './company-cell-components/DrivingTimeCell.vue';
import DescriptionCell from './company-cell-components/DescriptionCell.vue';
import FurtherResearchNeededCell from './company-cell-components/FurtherResearchNeededCell.vue';
import GetZiBasicButtonCell from './company-cell-components/GetZiBasicButtonCell.vue';
import GetZiIdButtonCell from './company-cell-components/GetZiIdButtonCell.vue';
import InvestorsCell from './company-cell-components/InvestorsCell.vue';
import LastCompletedTaskCell from './company-cell-components/LastCompletedTaskCell.vue';
import LocationCell from './company-cell-components/LocationCell.vue';
import LocationCityStateCell from './company-cell-components/LocationCityStateCell.vue';
import MarkClientFeedbackButtonCell from './company-cell-components/MarkClientFeedbackButtonCell.vue';
import MarkCRMNextActionDueDateButtonsCell from './company-cell-components/MarkCRMNextActionDueDateButtonsCell.vue';
import MarkTierButtonsCell from './company-cell-components/MarkTierButtonsCell.vue';
import NewCRMStatusCell from './company-cell-components/NewCRMStatusCell.vue';
import NextTaskDueCell from './company-cell-components/NextTaskDueCell.vue';
import OutreachNotesCell from './company-cell-components/OutreachNotesCell.vue';
import ParentCompanyCell from './company-cell-components/ParentCompanyCell.vue';
import PlatformNotesCell from './company-cell-components/PlatformNotesCell.vue';
import PrimaryContactsCell from './company-cell-components/PrimaryContactsCell.vue';
import RawAddressCell from './company-cell-components/RawAddressCell.vue';
import RevenueHeadcountCell from './company-cell-components/RevenueHeadcountCell.vue';
import RevenueHeadcountRevLastUpdatedCell from './company-cell-components/RevenueHeadcountRevLastUpdatedCell.vue';
import RevenueHeadcountSqFtRevLastUpdatedCell from './company-cell-components/RevenueHeadcountSqFtRevLastUpdatedCell.vue';
import SourceCell from './company-cell-components/SourceCell.vue';
import SourceOwnerCell from './company-cell-components/SourceOwnerCell.vue';
import SquareFootageCell from './company-cell-components/SquareFootageCell.vue';
import TierCell from './company-cell-components/TierCell.vue';
import TierTierButtonsResearchNeededRemoveFromCampaignCell from './company-compound-cells/Tier+TierButtons+ResearchNeeded+RemoveFromCampaignCell.vue';
import TransferZiBasicCell from './company-cell-components/TransferZiBasicCell.vue';
import WebsiteUrlCell from './company-cell-components/WebsiteUrlCell.vue';
import ZiIdCell from './company-cell-components/ZiIdCell.vue';
import ZiLocationCell from './company-cell-components/ZiLocationCell.vue';
import ZiRevenueHeadcountCell from './company-cell-components/ZiRevenueHeadcountCell.vue';
import ZiUrlCell from './company-cell-components/ZiUrlCell.vue';
import ZiLocationLocationCell from './company-compound-cells/ZiLocation+LocationCell.vue';  
import CRMDetailsPage from './CRMDetailsPage.vue';
import CRMStatusCRMSummaryCell from './company-compound-cells/CRMStatus+CRMSummaryCell.vue';
import CompanyNameDBAPCINVCategoryNotesDescriptionCustomersPlatformsCell from './company-compound-cells/CompanyName+DBA+PC+INV+Category+Notes+Description+Customers+PlatformsCell.vue';
import ProblemCompanyNotesCell from './company-cell-components/ProblemCompanyNotesCell.vue';
import AllOpenTasksCell from './company-cell-components/AllOpenTasksCell.vue';
import RelevantBusinessCell from './company-cell-components/RelevantBusinessCell.vue';
import CategoryCell from './company-cell-components/CategoryCell.vue';
import CRMStatusCRMSummaryRelevantBusinessCell from './company-compound-cells/CRMStatus+CRMSummary+RelevantBusinessCell.vue';
import CampaignTierCategoryCell from './company-compound-cells/Campaign+Tier+CategoryCell.vue';
import CRMNextActionDueDateDueDateButtonsCell from './company-compound-cells/CRMNextAction+DueDate+DueDateButtonsCell.vue';
import BingPlacesResultsCell from './company-cell-components/BingPlacesResultsCell.vue';
import BingWebResultsCell from './company-cell-components/BingWebResultsCell.vue';
import CompanyNameDBATierPCINVLocationCategoryURLCell from './company-compound-cells/CompanyName+DBA+Tier+PC+INV+Location+Category+URLCell.vue';
import ClientFeedbackClientMeetingNotesTierButtonsCell from './company-compound-cells/ClientFeedback+ClientMeetingNotes+TierButtonsCell.vue';
import ClientUpdateNotesCell from './company-cell-components/ClientUpdateNotesCell.vue';
import CRMStatusCRMSummaryRelevantBusinessClientUpdateNotesCell from './company-compound-cells/CRMStatus+CRMSummary+RelevantBusiness+ClientUpdateNotesCell.vue';
import CRMStatusCRMSummaryRelevantBusinessClientUpdateNotesOutreachNotesCell from './company-compound-cells/CRMStatus+CRMSummary+RelevantBusiness+ClientUpdateNotes+OutreachNotesCell.vue';
import ClientCell from './company-cell-components/ClientCell.vue';
import CompanyActivitiesPage from './CompanyActivitiesPage.vue';
import CollectionTagSourceCell from './company-cell-components/CollectionTagSourceCell.vue';
import EverAxisPillarCell from './company-cell-components/EverAxisPillarCell.vue';
import EventSummaryCell from './company-cell-components/EventSummaryCell.vue';
import CampaignTierCategorySourcesCell from './company-compound-cells/Campaign+Tier+Category+SourcesCell.vue';
import ShortcutActionsCell from './company-cell-components/ShortcutActionsCell.vue';
import ParentCell from './company-cell-components/ParentCell.vue';
import AllEventsCell from './company-cell-components/AllEventsCell.vue';
import IgpCategoryCell from './company-cell-components/IgpCategoryCell.vue';
import CompanyPhonePrimaryContactAllContactsCell from './company-compound-cells/CompanyPhone+PrimaryContact+AllContactsCell.vue';
import CompanyNameDBACampaignTierCategoryPCINVCityStateURLCell from './company-compound-cells/CompanyName+DBA+Campaign+Tier+Category+PC+INV+City+State+URLCell.vue';
import CompanyNameDBAPCINVCategoryAcctMgrNotesDescriptionCell from './company-compound-cells/CompanyName+DBA+PC+INV+Category+AcctMgrNotes+DescriptionCell.vue';
import CompanyNameDBAPCINVCategoryAcctMgrNotesDescriptionURLCell from './company-compound-cells/CompanyName+DBA+PC+INV+Category+AcctMgrNotes+Description+URLCell.vue';
import RevenueHeadcountSquareFootageZIRevenueZIHeadcountZIIDGetZIBasicClearZICell from './company-compound-cells/Revenue+Headcount+SquareFootage+ZIRevenue+ZIHeadcount+ZIID+GetZIBasic+ClearZICell.vue';
import RevenueLastUpdatedRevenueHeadcountSquareFootageZIRevenueZIHeadcountZIIDGetZIBasicClearZICell from'@/components/company-compound-cells/RevenueLastUpdated+Revenue+Headcount+SquareFootage+ZIRevenue+ZIHeadcount+ZIID+GetZIBasic+ClearZICell.vue';

import { vetURL, promoteOrDemoteResearch, getZoomInfoId, clearZoomInfo, getZoomInfoBasic, transferZoomInfoBasic } from '@/services/Company';
  
    export default {
        name: 'new-company-row',
        props: [
            'company', 
            'campaigns', 
            'changedCompanyFilters',
            'clearedSelectedCompanies',
            'selectAll',
            'selectAllOnPage',
            'permissions',
            'selectedCompaniesOnPageMap',
            'pageConfig',
            'crmStatuses',
            'crmActionTypes'
        ],
        components: {
            EditCompany,
            CompanyNameCell,
            NameDBADescriptionPCINVNotesCell,
            CageCodeCell,
            CampaignTierCell,
            ZiIdGetZiIdGetZiBasicClearZiCell,
            ZiLocationLocationTransferZiCell,
            ZiUrlUrlCell,
            UrlVettedCell,
            SourceSourceOwnerCell,
            ZiRevenueHeadcountRevenueHeadcountSqFtRevLastUpdatedCell,
            ResearchCompleteCell,
            AccountManagerNotesAccountManagerResearchNotesCell,
            ClientFeedbackClientFeedbackButtonsCell,
            ClientFeedbackClientFeedbackButtonsClientMeetingNotesCell,
            ClientFeedbackClientMeetingNotesCell,
            CompanyNameCageCodeCell,
            CompanyNameDBATierPCINVCRMSummaryCell,
            CRMNextActionDueDateMarkNextActionButtons,
            NameDBADescriptionCustomerNotesPlatformNotesPCINVMgrNotes,
            PrimaryContactAllContactsCell,
            RawAddressURLCell,
            RevenueHeadcountLocationURLCell,
            RevenueHeadcountSqFtCell,
            TierTierButtonsResearchNeededCell,
            ZiRevenueHeadcountRevenueHeadcountCell,
            ZiRevenueHeadcountRevenueHeadcountSqFtCell,
            ZiRevenueHeadcountRevenueHeadcountRevLastUpdatedCell,
            ZiUrlWebsiteUrlUrlVettedByCell,
            AccountManagerNotesCell,
            AcctMgrResearchNotesCell,
            AddTaskModalCell,
            AllContactsCell,
            AllOpenTasksCell,
            CampaignCell,
            ClearZiButtonCell,
            ClientFeedbackCell,
            ClientMeetingNotesCell,
            CollectionTagCell,
            CRMActivityOutcomeCell,
            CRMNextActionCell,
            CRMStatusCell,
            CRMSummaryCell,
            CustomerNotesCell,
            DaysSinceLastActivityCell,
            DaysUntilNextActionCell,
            DaysUntilNextTaskDueCell,
            DBACell,
            DescriptionCell,
            DrivingTimeCell,
            FurtherResearchNeededCell,
            GetZiBasicButtonCell,
            GetZiIdButtonCell,
            InvestorsCell,
            LastCompletedTaskCell,
            LocationCell,
            LocationCityStateCell,
            MarkClientFeedbackButtonCell,
            MarkCRMNextActionDueDateButtonsCell,
            MarkTierButtonsCell,
            NewCRMStatusCell,
            NextTaskDueCell,
            OutreachNotesCell,
            ParentCompanyCell,
            PlatformNotesCell,
            PrimaryContactsCell,
            RawAddressCell,
            RevenueHeadcountCell,
            RevenueHeadcountRevLastUpdatedCell,
            RevenueHeadcountSqFtRevLastUpdatedCell,
            SourceCell,
            SourceOwnerCell,
            SquareFootageCell,
            TierCell,
            TransferZiBasicCell,
            WebsiteUrlCell,
            ZiIdCell,
            ZiLocationCell,
            ZiRevenueHeadcountCell,
            ZiUrlCell,
            ZiLocationLocationCell,
            CRMDetailsPage,
            CompanyNameDBATierPCINVCityStateURLCell,
            CRMStatusCRMSummaryCell,
            TierTierButtonsResearchNeededRemoveFromCampaignCell,
            CampaignRemoveFromCampaignCell,
            CompanyNameDBAPCINVCategoryNotesDescriptionCustomersPlatformsCell,
            ProblemCompanyNotesCell,
            RelevantBusinessCell,
            CategoryCell,
            CRMStatusCRMSummaryRelevantBusinessCell,
            CampaignTierCategoryCell,
            CRMNextActionDueDateDueDateButtonsCell,
            CampaignTierTierButtonsResearchNeededRemoveFromCampaignCell,
            BingPlacesResultsCell,
            BingWebResultsCell,
            CompanyNameDBATierPCINVLocationCategoryURLCell,
            ClientFeedbackClientMeetingNotesTierButtonsCell,
            ClientUpdateNotesCell,
            CRMStatusCRMSummaryRelevantBusinessClientUpdateNotesCell,
            CRMStatusCRMSummaryRelevantBusinessClientUpdateNotesOutreachNotesCell,
            ClientCell,
            CompanyActivitiesPage,
            CollectionTagSourceCell,
            EverAxisPillarCell,
            EventSummaryCell,
            CampaignTierCategorySourcesCell,
            ShortcutActionsCell,
            ParentCell,
            AllEventsCell,
            IgpCategoryCell,
            CompanyPhonePrimaryContactAllContactsCell,
            CompanyNameDBACampaignTierCategoryPCINVCityStateURLCell,
            CompanyNameDBAPCINVCategoryAcctMgrNotesDescriptionCell,
            RevenueHeadcountSquareFootageZIRevenueZIHeadcountZIIDGetZIBasicClearZICell,
            CompanyNameDBAPCINVCategoryAcctMgrNotesDescriptionURLCell,
            RevenueLastUpdatedRevenueHeadcountSquareFootageZIRevenueZIHeadcountZIIDGetZIBasicClearZICell,
        },
        data() {
            return {
                isEditable: false,
                isModalVisible: false,
                isPopoverVisible: false,
                isFormVisible: false,
                reLoadFormData: true,
                isDetailsVisible: false,
                showCRMDetails: false,
                showActivityDetails: false,
                isSelected: this.selectAll,
                // iseSelected: this.selectAllOnPage,
                showFullNotes: this.company.account_manager_research_notes && this.company.account_manager_research_notes.length < 50,
                xPosition: 0,
                yPosition: 0,
                defaultTab: null
            }
        },
        mounted() {
        if(this.onPageAndSelected){
            this.isSelected = true
            }
        },
        methods: {
            toggleEditable() {
                return this.isEditable = !this.isEditable
            },
            fetchNewCompanies() {
                this.$emit('refetch-companies')
            },
            showModal() {
                this.isModalVisible = true
            },
            closeModal() {
                this.isModalVisible = false
            },
            showPopover() {
            this.isPopoverVisible = true;
            this.xPosition = this.$refs.companyName.getBoundingClientRect().right
            this.yPosition = this.$refs.companyName.getBoundingClientRect().top
            },
            hidePopover() {
            this.isPopoverVisible = false;
            this.xPosition = 0;
            this.yPosition = 0;
            },
            showForm(tabName='') {
                this.$emit('refetch-form-data')
                this.isFormVisible = true
                this.defaultTab = tabName
            },
            closeForm() {
                this.isFormVisible = false
            },
            showDetails(detailsType) {
                this.isDetailsVisible = true
                if(detailsType === 'crmDetails'){
                    this.showCRMDetails = true
                    this.showActivityDetails = false
                } else if (detailsType === 'activityDetails'){
                    this.showActivityDetails = true
                    this.showCRMDetails = false
                }
              },
            closeDetails(){
                this.isDetailsVisible = false
                this.showActivityDetails = false
                this.showCRMDetails = false
              },
            toggleShowNotes(){
                this.showFullNotes = !this.showFullNotes
            },
            toggleSelected() {
            if(this.isSelected) {
                this.isSelected = false
                // console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
            } else if(!this.isSelected) {
                this.isSelected = true
                // console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
            }
            },
            resetSelected() {
                this.isSelected = false
                this.$emit('reset-cleared-selected-companies')
            },
            async vetURLAction(action) {
                const token = this.$store.getters['auth/showToken']
                await vetURL(this.company.id, action, token)
                this.fetchNewCompanies()
            },
            async promoteOrDemote(action) {
                const token = this.$store.getters['auth/showToken']
                await promoteOrDemoteResearch(this.company.id, action, token)
                this.fetchNewCompanies()
            },
            async getZiId() {
                const token = this.$store.getters['auth/showToken']
                await getZoomInfoId(this.company.id, token)
                this.fetchNewCompanies()
            },
            async clearZi() {
                const token = this.$store.getters['auth/showToken']
                await clearZoomInfo(this.company.id, token)
                this.fetchNewCompanies()
            },
            async getZiBasic() {
                const token = this.$store.getters['auth/showToken']
                await getZoomInfoBasic(this.company.id, token)
                this.fetchNewCompanies()
            },
            async transferZiBasic() {
                const token = this.$store.getters['auth/showToken']
                await transferZoomInfoBasic(this.company.id, token)
                this.fetchNewCompanies() 
            }
        },
        watch: {
        changedCompanyFilters() {
            if(this.changedCompanyFilters){
                this.isSelected = false
                this.$emit('reset-changed-company-filters')
            }
            this.resetSelected()
        },
        clearedSelectedCompanies: {
            handler() {
                if(this.clearedSelectedCompanies){
                    this.isSelected = false
                    this.$emit('reset-cleared-selected-companies')
                }
                this.resetSelected()
            },
            immediate: true
        },
        clearedSelectedCompaniesOnPage() {
            if(this.clearedSelectedCompaniesOnPage){
            this.isSelected = false
            this.$emit('reset-cleared-selected-companies-on-page')
            }
            this.resetSelected()
        },
        selectAll() {
            if(this.selectAll){
            this.isSelected = true;
            // console.log('company row - emitted add-selected-company')
            return this.$emit('add-selected-company', this.company)
            }else if(!this.selectAll){
            this.isSelected = false;
            // console.log('company row - emitted remove-selected-company')
            return this.$emit('remove-selected-company', this.company)
            }
        },
        selectAllOnPage() {
            if(this.selectAllOnPage){
            this.isSelected = true;
            // console.log('company row - emitted add-selected-company')
            return this.$emit('add-selected-company', this.company)
            }else if(!this.selectAllOnPage){
            this.isSelected = false;
            // console.log('company row - emitted remove-selected-company')
            return this.$emit('remove-selected-company', this.company)
            }
        }
        },
        computed: {
            fullAddress(){
                return `${this.company.address ? this.company.address : "None"} ${this.company.city ? this.company.city : "None"}, ${this.company.state ? this.company.state : "None"} ${this.company.country ? this.company.country : "None"}`
            },
            fullZiAddress() {
                return `${this.company.zi_address ? this.company.zi_address : "None"} ${this.company.zi_city ? this.company.zi_city : "None"}, ${this.company.zi_state ? this.company.zi_state : "None"} ${this.company.zi_country ? this.company.zi_country : "None"}`
            },
            truncatedNotes(){
                if(this.company.account_manager_research_notes && this.company.account_manager_research_notes.length > 50){
                    return this.company.account_manager_research_notes.substring(0,51)
                } else if (this.company.account_manager_research_notes){
                    return this.company.account_manager_research_notes
                } else {
                    return "None"
                }
            },
            httpCompanyUrl(){
                if(this.company.website_url.startsWith('http://') || this.company.website_url.startsWith('https://')){
                    return this.company.website_url
                } else {
                    return `http://${this.company.website_url}`
                }
            },
            ziRevenueDisplay(){
            if(this.company.zi_revenue){
                let ziRevenue = this.company.zi_revenue / 1000
                return ziRevenue.toFixed(2)
            } else {
                return null
            }
            },
            onPageAndSelected(){
            if(this.selectedCompaniesOnPageMap.size > 0){
                return this.selectedCompaniesOnPageMap.has(this.company.id)
            } else {
                return false
            }
            },
            companyCells(){
            let pageConfigCells = this.pageConfig.cells
            pageConfigCells.sort((a, b) => {return a.ordinal - b.ordinal})
            return pageConfigCells
            }
        },
    }
</script>
<template>
    <div @dblclick="setIsEditableTrue" v-on:keyup.escape="setIsEditableFalse" class="flex flex-wrap justify-between">
        <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Full Name: </div>
        <div v-if="isEditable" class="text-accent-primary hover:underline hover:text-dark-primary hover:cursor-pointer pb-0.5 inline" @click="setIsEditableFalse">Collapse Name</div>
        <div v-if="isEditable" class="w-full">
            <div class="border-t border-b border-gray-300 py-1">
                <div class="flex flex-wrap items-center space-x-1 py-0.5">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Prefix:</div>
                    <CompanyContactPrefixCell class="inline " :company-contact="companyContact" @refetch-companies="fetchNewCompanies" />
                </div>
                <div class="flex flex-wrap items-center space-x-1 py-0.5">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">First Name:</div>
                    <CompanyContactFirstNameCell class="inline " :company-contact="companyContact" @refetch-companies="fetchNewCompanies" />
                </div>
                <div class="flex flex-wrap items-center space-x-1 py-0.5">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Preferred Name:</div>
                    <CompanyContactPreferredNameCell  class="inline " :company-contact="companyContact" @refetch-companies="fetchNewCompanies" />
                </div>
                <div class="flex flex-wrap items-center space-x-1 py-0.5">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Middle Name:</div>
                    <CompanyContactMiddleNameCell class="inline " :company-contact="companyContact" @refetch-companies="fetchNewCompanies" />
                </div>
                <div class="flex flex-wrap items-center space-x-1 py-0.5">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Last Name:</div>
                    <CompanyContactLastNameCell class="inline " :company-contact="companyContact" @refetch-companies="fetchNewCompanies" />
                </div>
                <div class="flex flex-wrap items-center space-x-1 py-0.5">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Suffix:</div>
                    <CompanyContactSuffixCell  class="inline " :company-contact="companyContact" @refetch-companies="fetchNewCompanies" />
                </div>
            </div>
        </div>
        <span v-else class="cursor-pointer">{{ contactFullName }}</span>
    </div>
</template>

<script>
  export default {
      name: "company-contact-full-name-cell"
  }

</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";
import CompanyContactPrefixCell from "./CompanyContactPrefixCell.vue";
import CompanyContactFirstNameCell from "./CompanyContactFirstNameCell.vue";
import CompanyContactMiddleNameCell from "./CompanyContactMiddleNameCell.vue";
import CompanyContactLastNameCell from "./CompanyContactLastNameCell.vue";
import CompanyContactSuffixCell from "./CompanyContactSuffixCell.vue";
import CompanyContactPreferredNameCell from "./CompanyContactPreferredNameCell.vue";

  const isEditable = ref(false)

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

  function setIsEditableTrue () {
    console.log('setIsEditableTrue')
      isEditable.value = true
  }

  function setIsEditableFalse () {
        console.log('setIsEditableFalse')
        isEditable.value = false
  }

  const contactFullName = computed(() => {
    let fullName = ''
    if(props.companyContact.prefix){
        fullName += `${props.companyContact.prefix} `
    }
    if(props.companyContact.first_name){
        fullName += `${props.companyContact.first_name} `
    }
    if(props.companyContact.middle_name){
        fullName += `${props.companyContact.middle_name} `
    }
    if(props.companyContact.last_name){
        fullName += `${props.companyContact.last_name}`
    }
    if(props.companyContact.suffix){
        fullName += ` ${props.companyContact.suffix}`
    }
    if(props.companyContact.preferred_name) {
        fullName += ` (${props.companyContact.preferred_name})`
    }
    if(fullName !== '') {
        return fullName
    } else {
        return '--'
    }
  })

</script>
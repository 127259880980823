<template>
    <base-card-select :isSelected="isSelected">
        <div class="flex flex-row">
            <div class="flex-none">
                <input class="mx-1" type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
            </div>
            <div class="flex-auto">
                <section class="flex justify-between items-center">
                    <div class="flex justify-start">
                        <div>
                            <div @click="showForm" class="text-accent-primary hover:text-dark-primary hover:cursor-pointer hover:underline px-1">
                                {{ company.id }}
                            </div>
                            <base-modal :is-form-visible="isFormVisible" @close="closeForm">
                                <template #header>
                                    <h2 class="text-lg">Edit Company</h2>
                                </template>
                                <template #body>
                                    <CompanyForm
                                    :company="company"
                                    :campaigns="campaigns"
                                    @close="closeForm"
                                    @refetch-companies="fetchNewCompanies"
                                />
                            </template>
                            </base-modal>
                        </div>
                        <div class="px-1">
                            | <span class="font-bold">{{ company.company_name }}</span>
                        </div>
                        <div class="px-1">
                            | Cage Code: {{ company.cage_code }}
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <span class="font-bold">Rev:&nbsp;</span>{{ company.revenue }} M <span v-if="company.employee_headcount" >&nbsp;/&nbsp;<span class="font-bold">&nbsp;HC:&nbsp;</span>{{ company.employee_headcount }}</span>
                    </div>
                </section>
                <section class="flex justify-start my-1">
                    <span class="font-bold">Campaign:&nbsp;</span>{{ companyCampaign }} | <span class="font-bold">&nbsp;Tier:&nbsp;</span>{{ companyTier }}
                </section>
                <section class="flex justify-start my-1">
                    <span class="font-bold">Address:&nbsp;</span>{{ company.raw_address }}
                </section>
                <section v-if="company.website_url" class="my-1">
                    <span class="font-bold">URL:&nbsp;</span>{{ company.website_url }}
                </section>
                <section class="mt-2">
                    <div v-for="governingSpecification in governingSpecifications" :key="governingSpecification.id">
                        <a target='_blank' :href="`${governingSpecification.document_url}`"><span class="font-bold text-accent-primary hover:text-dark-primary">{{ governingSpecification.qpl_number }}&nbsp;</span></a>{{ governingSpecification.title }}
                    </div>
                </section>
            </div>   
        </div>

    </base-card-select>
  </template>
  
<script>
import CompanyForm from './CompanyForm.vue';
import { vetURL, promoteOrDemoteResearch, getZoomInfoId, clearZoomInfo, getZoomInfoBasic, transferZoomInfoBasic } from '@/services/Company';
import { exportDefaultSpecifier } from '@babel/types';

  
export default {
    name: 'dla-company-row',
    props: ['company', 'changedCompanyFilters', 'clearedSelectedCompanies', 'selectAll', 'selectAllOnPage', 'permissions', 'selectedCompaniesOnPageMap'],
    components: {
    CompanyForm,
},
          data() {
              return {
                  isEditable: false,
                  isModalVisible: false,
                  isPopoverVisible: false,
                  isFormVisible: false,
                  reLoadFormData: true,
                  isSelected: this.selectAll,
                  showFullNotes: this.company.account_manager_research_notes && this.company.account_manager_research_notes.length < 50,
                  xPosition: 0,
                  yPosition: 0,
                  companyProductRowIds: [],
                  governmentDesignationIds: [],
                  governingSpecificationIds: [],
                  governmentDesignations: null,
                  governingSpecifications: null,
              }
          },
          mounted() {
            if(this.onPageAndSelected){
                this.isSelected = true
              }
              let governmentDesignationList = []
              this.companyProductRows.forEach(companyProductRow => {
                  let governmentDesignation = companyProductRow.government_designation
                  if(!this.governmentDesignationIds.includes(governmentDesignation.id)) {
                      governmentDesignationList.push(governmentDesignation)
                      this.governmentDesignationIds.push(governmentDesignation.id)
                  }
              })
              this.governmentDesignations = governmentDesignationList
              let governingSpecificationList = []
              this.governmentDesignations.forEach(governmentDesignation => {
                  let governingSpecification = governmentDesignation.governing_specification
                  if(!this.governingSpecificationIds.includes(governingSpecification.id)) {
                      governingSpecificationList.push(governingSpecification)
                      this.governingSpecificationIds.push(governingSpecification.id)
                  }
              })
              this.governingSpecifications = governingSpecificationList
          },
          methods: {
              toggleEditable() {
                  return this.isEditable = !this.isEditable
              },
              fetchNewCompanies() {
                  this.$emit('refetch-companies')
              },
              showModal() {
                  this.isModalVisible = true
              },
              closeModal() {
                  this.isModalVisible = false
              },
              showPopover() {
                this.isPopoverVisible = true;
                this.xPosition = this.$refs.companyName.getBoundingClientRect().right
                this.yPosition = this.$refs.companyName.getBoundingClientRect().top
              },
              hidePopover() {
                this.isPopoverVisible = false;
                this.xPosition = 0;
                this.yPosition = 0;
              },
              showForm() {
                  this.$emit('refetch-form-data')
                  this.isFormVisible = true
              },
              closeForm() {
                  this.isFormVisible = false
              },
              toggleShowNotes(){
                  this.showFullNotes = !this.showFullNotes
              },
              toggleSelected() {
                if(this.isSelected) {
                  this.isSelected = false
                  // console.log('company row - emitted remove-selected-company')
                  return this.$emit('remove-selected-company', this.company)
                } else if(!this.isSelected) {
                  this.isSelected = true
                  // console.log('company row - emitted add-selected-company')
                  return this.$emit('add-selected-company', this.company)
                }
              },
              resetSelected() {
                  this.isSelected = false
                  this.$emit('reset-cleared-selected-companies')
              },
              async vetURLAction(action) {
                  const token = this.$store.getters['auth/showToken']
                  await vetURL(this.company.id, action, token)
                  this.fetchNewCompanies()
              },
              async promoteOrDemote(action) {
                  const token = this.$store.getters['auth/showToken']
                  await promoteOrDemoteResearch(this.company.id, action, token)
                  this.fetchNewCompanies()
              },
              async getZiId() {
                  const token = this.$store.getters['auth/showToken']
                  await getZoomInfoId(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async clearZi() {
                  const token = this.$store.getters['auth/showToken']
                  await clearZoomInfo(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async getZiBasic() {
                  const token = this.$store.getters['auth/showToken']
                  await getZoomInfoBasic(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async transferZiBasic() {
                  const token = this.$store.getters['auth/showToken']
                  await transferZoomInfoBasic(this.company.id, token)
                  this.fetchNewCompanies() 
              }
          },
          watch: {
            changedCompanyFilters() {
                if(this.changedCompanyFilters){
                  this.isSelected = false
                  this.$emit('reset-changed-company-filters')
                }
                this.resetSelected()
            },
            clearedSelectedCompanies() {
              if(this.clearedSelectedCompanies){
                this.isSelected = false
                this.$emit('reset-cleared-selected-companies')
              }
              this.resetSelected()
            },
            clearedSelectedCompaniesOnPage() {
              if(this.clearedSelectedCompaniesOnPage){
                this.isSelected = false
                this.$emit('reset-cleared-selected-companies-on-page')
              }
              this.resetSelected()
            },
            selectAll() {
              if(this.selectAll){
                this.isSelected = true;
                console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
              }else if(!this.selectAll){
                this.isSelected = false;
                console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
              }
            },
            selectAllOnPage() {
              if(this.selectAllOnPage){
                this.isSelected = true;
                // console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
              }else if(!this.selectAllOnPage){
                this.isSelected = false;
                // console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
              }
            }
          },
          computed: {
              fullAddress(){
                  return `${this.company.address ? this.company.address : "None"} ${this.company.city ? this.company.city : "None"}, ${this.company.state ? this.company.state : "None"} ${this.company.country ? this.company.country : "None"}`
              },
              fullZiAddress() {
                  return `${this.company.zi_address ? this.company.zi_address : "None"} ${this.company.zi_city ? this.company.zi_city : "None"}, ${this.company.zi_state ? this.company.zi_state : "None"} ${this.company.zi_country ? this.company.zi_country : "None"}`
              },
              truncatedNotes(){
                  if(this.company.account_manager_research_notes && this.company.account_manager_research_notes.length > 50){
                      return this.company.account_manager_research_notes.substring(0,51)
                  } else if (this.company.account_manager_research_notes){
                      return this.company.account_manager_research_notes
                  } else {
                      return "None"
                  }
              },
              httpCompanyUrl(){
                  if(this.company.website_url.startsWith('http://') || this.company.website_url.startsWith('https://')){
                      return this.company.website_url
                  } else {
                      return `http://${this.company.website_url}`
                  }
              },
              ziRevenueDisplay(){
                if(this.company.zi_revenue){
                  let ziRevenue = this.company.zi_revenue / 1000
                  return ziRevenue.toFixed(2)
                } else {
                  return null
                }
              },
              onPageAndSelected(){
                if(this.selectedCompaniesOnPageMap.size > 0){
                  return this.selectedCompaniesOnPageMap.has(this.company.id)
                } else {
                  return false
                }
              },
              companyProductRows(){
                let companyProductRowsList = []
                this.company.dla_company.forEach(dlaCompany => {
                    let companyProductRows = dlaCompany.raw_company_product_rows;
                    companyProductRows.forEach(companyProductRow => {
                        if(!this.companyProductRowIds.includes(companyProductRow.id)) {
                            companyProductRowsList.push(companyProductRow);
                            this.companyProductRowIds.push(companyProductRow.id);
                        }
                    });
                })
                return companyProductRowsList
              },
              companyCampaign() {
                if(this.company.campaign){
                    return this.company.campaign.campaign_name
                } else {
                    return '--'
                }
              },
              companyTier(){
                if(this.company.tier_object){
                    return this.company.tier_object.tier_name
                } else {
                    return '--'
                }
              }
            //   governmentDesignations(){
            //     let governmentDesignationList = []
            //     this.companyProductRows.forEach(companyProductRow => {
            //         let governmentDesignation = companyProductRow.government_designation
            //         if(!this.governmentDesignationIds.includes(governmentDesignation.id)) {
            //             governmentDesignationList.push(governmentDesignation)
            //             this.governmentDesignationIds.push(governmentDesignation.id)
            //         }
            //     })
            //     return governmentDesignationList
            // },
            //   governingSpecifications(){
            //     let governingSpecificationList = []
            //     this.governmentDesignations.forEach(governmentDesignation => {
            //         let governingSpecification = governmentDesignation.governing_specification
            //         if(!this.governingSpecificationIds.includes(governingSpecification.id)) {
            //             governingSpecificationList.push(governingSpecification)
            //             this.governingSpecificationIds.push(governingSpecification.id)
            //         }
            //     })
            //     return governingSpecificationList.sort((a, b) => a.qpl_number - b.qpl_number)
            // }
          },
      }
  </script>
<template>
    <div class="p-2">
        <h1 class=" text-lg">Campaign Change Report as of {{ determineDate() }}</h1>
        <div class="flex flex-col p-2 space-y-4">
            <div v-for="company in campaignChanges" :key="company.id" class="w-full p-4">
                <h2 class=" font-bold">{{ company.company }} ({{ company.id }})</h2>
                <table class="m-2 table-fixed w-full border border-black border-collapse">
                    <thead class="bg-gray-100 border drop-shadow-md text-sm">
                        <tr>
                            <th class="border-r border-x-gray-600" >
                                Change Date
                            </th> 
                            <th class="border-x border-x-gray-600">
                                Campaign
                            </th>
                            <th class="border-x border-x-gray-600">
                                Tier
                            </th>
                            <!-- <th class="border-x border-x-gray-600">
                                Previous Campaign
                            </th> -->
                            <!-- <th class="border-x border-x-gray-600">
                                Previous Tier
                            </th> -->
                            <th class="border-l border-x-gray-600">
                                User
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <base-table-row v-for="history in company.histories" :key="history.id">
                            <base-table-cell class="text-center bg-gray-100">
                                {{ formatDate(history.created_at) }}
                            </base-table-cell>
                            <base-table-cell class="text-center bg-gray-100">
                                <!-- {{ history.previous_campaign ? history.previous_campaign.campaign_name : "None" }} → {{ history.campaign ? history.campaign.campaign_name : "None" }} -->
                                {{ checkCampaignChange(history) }}
                            </base-table-cell>
                            <base-table-cell class="text-center bg-gray-100">
                                <!-- {{ history.previous_tier ? history.previous_tier : "None" }} → {{ history.tier ? history.tier : "None" }} -->
                                {{ checkTierChange(history) }}
                            </base-table-cell>
                            <!-- <base-table-cell class="text-center bg-gray-100">
                                
                            </base-table-cell> -->
                            <!-- <base-table-cell class="text-center bg-gray-100">
                                
                            </base-table-cell> -->
                            <base-table-cell class="text-center bg-gray-100">
                                {{ history.user ? history.user.first_name + " " + history.user.last_name : "None" }}
                            </base-table-cell>
                        </base-table-row>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'campaign-change-report'
}
</script>

<script setup>
import { useStore } from 'vuex';
import { ref } from 'vue';
import { getCampaignChangeReport } from '@/services/Company';

const store = useStore()

const campaignChanges = ref([])

function checkCampaignChange(history) {
    if(history.campaign && history.previous_campaign) {
        if(history.campaign.campaign_name != history.previous_campaign.campaign_name){
            return `${history.previous_campaign.campaign_name} → ${history.campaign.campaign_name}`
        } else {
            return history.campaign.campaign_name
        }
    } else if(history.campaign && !history.previous_campaign){
        return `None → ${history.campaign.campaign_name}`
    } else if(!history.campaign && history.previous_campaign){
        return `${history.previous_campaign.campaign_name} → None`
    } else {
        return "None"
    }
}

function checkTierChange(history){
    if(history.tier && history.previous_tier) {
        if(history.tier != history.previous_tier){
            return `${history.previous_tier} → ${history.tier}`
        } else {
            return history.tier
        }
    } else if(history.tier && !history.previous_tier){
        return `None → ${history.tier}`
    } else if(!history.tier && history.previous_tier){
        return `${history.previous_tier} → None`
    } else {
        return "None"
    }

}

function determineDate(){
    const startDate = new Date('August 30, 2023 00:00:00')
    const twoWeeksAgo = new Date().setDate(new Date().getDate() - 14)
    if(startDate > twoWeeksAgo){
        return Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(startDate)
    } else {
        return Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(twoWeeksAgo)
    }
}

async function loadCampaignChanges(){
    const token = store.state.auth.token
    const result = await getCampaignChangeReport(token)
    campaignChanges.value = result.company_campaign_histories
}

function formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric', hour: "numeric", minute: "numeric"}).format(date);
    }

loadCampaignChanges()
determineDate()

</script>
<template>
    <div class="w-full">
        <!-- <div class="w-full py-2"> Query {{ queryString }}</div> -->
        <FTSQuery
            :key="condition.conditionKey"
            v-for="condition, index in conditions"
            :index="index"
            :conditionKey="condition.conditionKey"
            :nestingLevel="0"
            :conditionType="condition.conditionType"
            :conjunction="conjunction"
            @upsert-query-condition="updateQueryConditions"
            @remove-query-condition="removeQueryCondition"
        >
            <template #conjunctionSelector>
                <select class="border border-gray-400 rounded-md h-8 w-fit -ml-1" required v-model="conjunction">
                    <option value="AND" selected>AND</option>
                    <option value="OR">OR</option>
                    <!-- <option value="NOT">NOT</option> -->
                </select>
            </template>
        </FTSQuery>
        <div class="w-full">
            <div class="flex flex-row justify-start items-center py-1 border-b border-gray-400 w-full space-x-1 pl-2">
                <base-button class="py-0" @click.prevent="addCondition('condition')">+ Condition</base-button>
                <base-button class="py-0" @click.prevent="addCondition('conditionGroup')">+ Condition Group</base-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, reactive, computed, defineProps, defineEmits, watch } from 'vue';
import FTSQuery from './FTSQuery.vue';


const props = defineProps(['conditionsCleared'])

const emit = defineEmits(['change-fts-queries', 'set-conditions-cleared-false'])

const conjunction = ref('OR')

watch(() => props.conditionsCleared, ()=> {
    if(props.conditionsCleared){
        conditions.value = [
            {
                conditionKey: Math.random(),
                conditionType: 'condition'
            }
        ]
        conjunction.value = 'OR'
        emit('set-conditions-cleared-false')
    }
})

const queryString = computed(()=> {
    let returnString = ''
    let conditionString = ''
    for(let condition of conditions.value){
        if(condition.searchPhrase){
            conditionString += `${condition.queryString},`
        }
    }
    if(conditionString.length > 0){
        conditionString = conditionString.slice(0, -1)
        returnString = `${conjunction.value}(${conditionString})`
    }
    return returnString
})

const conditions = ref([
    {
        conditionKey: Math.random(),
        conditionType: 'condition'
    }
])



// const emitChangedFTSQueries = () => {
//     emit('change-fts-queries', queries)
// }

function updateQueryConditions(queryCondition) {
    console.log('updateQueryConditions', queryCondition)
    console.log('condition', conditions.value.find(condition => condition.conditionKey === queryCondition.conditionKey))
    if(queryCondition.conditionKey){
        let existingCondition = conditions.value.find(condition => condition.conditionKey === queryCondition.conditionKey)
        if(existingCondition){
            console.log('condition has conditionKey')
            existingCondition.queryString = queryCondition.queryString
            existingCondition.searchPhrase = queryCondition.searchPhrase
        } else {
            conditions.value.push(queryCondition)
        }
        
    }
    if(queryString.value.length > 0) {
        emit('change-fts-queries', queryString.value)
    }

}

function removeQueryCondition(conditionKey) {
    let existingCondition = conditions.value.find(condition => condition.conditionKey === conditionKey)
    if(existingCondition){
        conditions.value.splice(conditions.value.findIndex(condition => condition.conditionKey === conditionKey), 1)
    }  
}

function addCondition(conditionType) {
    conditions.value.push({
        conditionKey: Math.random(),
        conditionType: conditionType
    })
}


// function addQueryGroup() {
//     queries.value.push({
//         key: Math.random(),
//         type: 'condition_group',
//         conjunction: 'AND',
//         subQueries: [{
//             id: 1,
//             key: Math.random(),
//             conjunction: 'AND',
//             prefix: 'IS_LIKE',
//             type: 'condition',
//             searchPhrase: null
//         }]

//     })
// }
</script>
<template>
  <base-table-row>
    <base-table-cell>
      <div>
        {{ task.id }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ taskType }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ task.task_title }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ task.company.company_name }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ source }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ assignedUser }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ task.status }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ completedAtBy }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ cancalledAt }}
      </div>
    </base-table-cell>
  </base-table-row>
</template>

<script setup>
// imports

import { defineProps, computed } from "vue";
import { formatDate } from "@/composables/formatDate";

// constants/variables

const props = defineProps({
  task: Object,
});

// computed values

const assignedUser = computed(() => {
  if (props.task.user) {
    return props.task.user;
  } else {
    return "None";
  }
});

const source = computed(() => {
  let res = "";
  if (props.task.company && props.task.company.sources) {
    props.task.company.sources.forEach((source) => {
      if (res.length === 0) {
        res = source.source_name;
      } else {
        res += ", " + source.source_name;
      }
    });
  }
  return res;
});

const cancalledAt = computed(() => {
  if (props.task.cancelled_at) {
    return formatDate(props.task.cancelled_at);
  } else {
    return "-----";
  }
});

const taskType = computed(() => {
  if (props.task.task_type === 'research_task') {
    return 'Research Task'
  } else {
    return 'Account Manager Task'
  }
})

const completedAtBy = computed(() => {
  if (props.task.completed_at) {
    return formatDate(props.task.completed_at) + completedByName.value;
  }
  return "-----";
});

const completedByName = computed(() => {
  if (props.task.completed_by) {
    return (
      " by " +
      props.task.completed_by.first_name +
      " " +
      props.task.completed_by.last_name
    );
  } else {
    return "";
  }
});
</script>

<template>
    <div class="mx-5 min-h-screen bg-gray-200 w-full">
        <div v-show="showFilterPane">
        <div v-show="showBasicFilters" class="pt-3 pb-0.5 border-b mb-5 border-b-gray-400 flex flex-row justify-between">
            <h1 class="">Client Appendix Filters:</h1>
            <div>
                <base-button-affirmative @click="resetFilter" class="text-xs">
                Reset
                </base-button-affirmative>
            </div>
        </div>
        <div v-show="showBasicFilters" class="grid grid-cols-4 w-full">
            <base-filter-no-button> 
                <template v-slot:label>Campaign</template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md dark:text-black"
                        placeholder="Select Campaign"
                        v-model="clientAppendicesFilters.campaign"
                        :options="campaignOptions"
                        @update:modelValue="setCampaignFilter"
                    />
                </template>
            </base-filter-no-button>
            <base-filter-no-button> 
                <template v-slot:label>Tier</template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md dark:text-black"
                        placeholder="Select Tier"
                        v-model="clientAppendicesFilters.tier"
                        :options="tierOptions"
                        @update:modelValue="setTierFilter"
                        :multiple="true"
                    />
                </template>
            </base-filter-no-button>
            <base-filter-no-button> 
                <template v-slot:label>Client Feedback</template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md dark:text-black"
                        placeholder="Select Client Feedback"
                        v-model="clientAppendicesFilters.client_feedback"
                        :options="clientFeedbackOptions"
                        @update:modelValue="setClientFeedbackFilter"
                        :multiple="true"
                    />
                </template>
            </base-filter-no-button>
            <base-filter-no-button> 
                <template v-slot:label>Client</template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md dark:text-black"
                        placeholder="Select Client"
                        v-model="clientAppendicesFilters.client"
                        :options="clientOptions"
                        @update:modelValue="setClientFilter"
                    />
                </template>
            </base-filter-no-button>
        </div>
        <div v-show="showTableFilters" class="pt-3 pb-0.5 border-b mb-5 border-b-gray-400 flex flex-row justify-between">
            <h1 class="">Client Appendix Table Columns:</h1>
        </div>
        <div v-show="showTableFilters" class="w-1/2">
            <base-filter-no-button>
                <template v-slot:label> Columns </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md dark:text-black text-xs"
                        placeholder="Select Columns"
                        v-model="tableColsSelected"
                        :options="tableColsOptions"
                        @update:modelValue="setTableHeaders"
                        :multiple="true"
                    > </base-list-box>
                </template>
            </base-filter-no-button>
            
        </div>
    </div>
        <!-- DM-7-30-24 <div class="justify-end flex flex-row mr-1">
            <base-button @click="copyInfo"> Copy All Company Data to Clipboard</base-button>
        </div> -->
        
        <div class="flex flex-row items-center mb-5">
            <div class="flex items-center">
          <span
            class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="goToFirst"
            >&lt;&lt;</span
          >
          <span
            class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="getPrev"
            >&lt;</span
          >
          <div class="flex text-md flex-row">
            Page {{ page }} of {{ numPages }}
          </div>
          <span
            class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="getNext"
            >></span
          >
          <span
            class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="goToLast"
            >>></span
          >
        </div>
        <div class="ml-5">
        <base-filter-horizontal>
                  <template v-slot:label> Pg Size </template>
                  <template v-slot:widget>
                    <base-list-box-horizontal
                      class="w-full rounded-r-md dark:text-black text-xs"
                      placeholder="Select"
                      :options="pageSizeOptions"
                      :modelValue="clientAppendicesFilters.pageSizeFilter"
                      :multiple="false"
                      @update:modelValue="setPageSizeFilter"
                    />
                  </template>
        </base-filter-horizontal>
    </div>
            <div
            class="ml-5 flex flex-row items-center text-md align-middle lg:ml-3 lg:col-span-1 lg:justify-start"
            >
                {{pageSize }} of {{ count }} Total Companies
            </div>
          <div v-if="isLoading" role="status" class="pl-3">
            <svg
              aria-hidden="true"
              class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="white"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#2E9CCA"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else class="pl-3">
            <svg
              @click="loadCompaniesPaginated(fetchFilter)"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#25274D"
              class="mx-1 w-6 h-6 hover:cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        </div>
        
        <div v-if="companies.length > 0">
            <client-appendices-table
            :tableHeaders="tableHeaders"
            :companies="companies"
            >
            </client-appendices-table>
        </div>
        <div class="w-full border-t border-t-gray-400 mt-10" v-else> 
        </div>
        <base-dialog-box v-if="isDialogueBoxOpen">
            <template #header>
            {{ dialogueBoxHeader}}
            <base-button-negative @click="closeDialogueBox"> X </base-button-negative>
            </template>
            <template #body> 
            {{dialogueBoxBody}}   
            </template>
        </base-dialog-box>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, watch } from 'vue';
    import { useStore } from "vuex";
    import { getAllClientsSimple } from '@/services/Client.js';
    import { getCampaigns } from '@/services/Campaigns.js';
    import { getCompaniesForClientAppendices, getCompaniesForClientAppendicesPaginated } from '@/services/Company.js';
    import ClientAppendicesTable from './ClientAppendicesTable.vue';

    const store = useStore();
    const isLoading = ref(false);

    const isDialogueBoxOpen = ref(false);
    const dialogueBoxHeader = ref("");
    const dialogueBoxBody = ref("");
    
    const count = ref(0);
    const next = ref(null);
    const prev = ref(null);
    const pageSize = ref(0);
    const numPages = ref(0);
    const page = ref(0);

    const companies = ref([]);
    const tableHeaders = ref(['ID', 'Company Name']);

    const showFilterPane = computed(() => store.getters["company/showFilterPane"]);
    const showBasicFilters = computed(() => store.getters['company/showBasicFilters'])
    const showTableFilters = computed(() => store.getters['company/showTableFilters'])


    const clientAppendicesFilters = reactive({
        campaign: null,
        tier: [],
        client_feedback: [],
        client: null,
        pageSize: 200,
    });

    const tableColsSelected = ref([]);

    const tableColsOptions = ref([
        {value: 'Doing Business As', label: 'Doing Business As'},
        {value: 'Client', label: 'Client'},
        {value: 'City', label: 'City'},
        {value: 'State', label: 'State'},
        {value: 'Country', label: 'Country'},
        {value: 'Tier', label: 'Tier'},
        {value: 'Website Url', label: 'Website Url'},
        {value: 'Client Feedback', label: 'Client Feedback'}
    ]);

    const pageSizeOptions = ref([
        { value: "clear", label: "----" },
        { value: 50, label: "50" },
        { value: 100, label: "100" },
        { value: 200, label: "200" },
        { value: 300, label: "300" },
        { value: 400, label: "400" },
        { value: 500, label: "500" },
    ]);

    function openDialogueBox(header_text, body_text) {
        dialogueBoxHeader.value = header_text;
        dialogueBoxBody.value = body_text;
        isDialogueBoxOpen.value = true;
        }

    function closeDialogueBox() {
        isDialogueBoxOpen.value = false;
        dialogueBoxHeader.value = "";
        dialogueBoxBody.value = "";
    }

    function setTableHeaders(target) {
        if (target.length == 0) {
            tableColsSelected.value = [];
        } else {
            tableColsSelected.value = target;
        }
        tableHeaders.value = [];
        tableHeaders.value.push('ID');
        tableHeaders.value.push('Company Name');
        // for (let colVal of tableColsSelected.value) {
        //     tableHeaders.value.push(colVal)
        // }
        if(tableColsSelected.value.includes('Doing Business As')) {
            tableHeaders.value.push('Doing Business As');
        }
        if (tableColsSelected.value.includes('Client')) {
            tableHeaders.value.push('Client');
        }
        if (tableColsSelected.value.includes('City')) {
            tableHeaders.value.push('City');
        }
        if (tableColsSelected.value.includes('State')) {
            tableHeaders.value.push('State');
        }
        if (tableColsSelected.value.includes('Country')) {
            tableHeaders.value.push('Country');
        } 
        if (tableColsSelected.value.includes('Tier')) {
            tableHeaders.value.push('Tier');
        }
        if (tableColsSelected.value.includes('Website Url')) {
            tableHeaders.value.push('Website Url');
        }
        if (tableColsSelected.value.includes('Client Feedback')) {
            tableHeaders.value.push('Client Feedback');
        }
    }

    const tierOptions = ref([
        {value: "clear", label: "------------"},
        {value: "none", label: "No Tier"},
        {value: "tier_1", label: "Tier 1"},
        {value: "tier_2", label: "Tier 2"},
        {value: "tier_3", label: "Tier 3"},
        {value: "tier_4", label: "Tier 4"},        
        {value: "tier_(review)_5", label: "Tier 5"},
    ]);

    const clientFeedbackOptions = ref([
        {value: "clear", label: "------------"},
        {value: "none", label: "None"},
        {value: "approved", label: "Approved"},
        {value: "excluded", label: "Excluded"},
        {value: "hold", label: "Hold"},
        {value: "pass", label: "Pass"},        
        {value: "research further", label: "Research Further"},
    ]);

    const campaignOptions = ref([]);
    const clientOptions = ref([]);

    const awaitingSearch = ref(false);


    watch(
        () => clientAppendicesFilters,
        () => {
            if (!awaitingSearch.value) {
            setTimeout(() => {
                loadCompaniesPaginated(fetchFilter.value);
                awaitingSearch.value = false;
            }, 1000); // 1 sec delay
            }
            awaitingSearch.value = true;
        },
        { deep: true }
        );
    
    function setPageSizeFilter(target) {
        target == "clear"
        ? (clientAppendicesFilters.pageSizeFilter = null)
        : (clientAppendicesFilters.pageSizeFilter = target);
    }

    function setCampaignFilter(target) {
        target == "clear"
        ? (clientAppendicesFilters.campaign = null)
        : (clientAppendicesFilters.campaign = target);
    }

    function setTierFilter(target) {
        target.includes("clear")
        ? (clientAppendicesFilters.tier = [])
        : (clientAppendicesFilters.tier = target);
    }

    function setClientFeedbackFilter(target) {
        target.includes("clear")
        ? (clientAppendicesFilters.client_feedback = [])
        : (clientAppendicesFilters.client_feedback = target);
    }

    function setClientFilter(target) {
        target == "clear"
        ? (clientAppendicesFilters.client = null)
        : (clientAppendicesFilters.client = target);
    }

    const fetchFilter = computed(function () {
    // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
    let query = new URLSearchParams({});
    if (clientAppendicesFilters.campaign) {
        query.append("campaign", clientAppendicesFilters.campaign);
    }
    if (clientAppendicesFilters.tier.length > 0) {
        clientAppendicesFilters.tier.forEach((tier) => {
            query.append("tier", tier)
        });
    }
    if (clientAppendicesFilters.client_feedback.length > 0) {
        clientAppendicesFilters.client_feedback.forEach((feedback)=> {
            query.append("client_feedback", feedback)
        });
    }
    if (clientAppendicesFilters.client) {
        query.append("client", clientAppendicesFilters.client);
    }
    if(clientAppendicesFilters.pageSizeFilter) {
        query.append("page_size", clientAppendicesFilters.pageSizeFilter);
    }
    // (B) CONVERT TO STRING, APPEND TO URL
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
        queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
        url = "?" + query.toString();
    } else {
        url = "?";
    }
    return url;
    // (C) WHATEVER COMES NEXT...
    });

    function resetFilter() {
        clientAppendicesFilters.campaign = null;
        clientAppendicesFilters.client = null;
        clientAppendicesFilters.client_feedback = [];
        clientAppendicesFilters.tier = [];
    }

    async function loadCampaignOptions() {
        isLoading.value = true;
        let payload = {
            token: store.state.auth.token,
            endpoint: ""
        }
        const result = await getCampaigns(payload);

        campaignOptions.value.push({value: "clear", label: "------------"});
        for (const campaign of result) {
            campaignOptions.value.push({value: campaign.id, label: campaign.campaign_name});
        }
        isLoading.value = false;
    }

    async function loadClientOptions() {
        isLoading.value = true;
        let payload = {
            token: store.state.auth.token,
        }
        const result = await getAllClientsSimple(payload);

        clientOptions.value.push({value: "clear", label: "------------"});
        for (const client of result.data) {
            clientOptions.value.push({value: client.id, label: client.client_name});
        }
        isLoading.value = false;
    }

    async function loadCompanies(endpoint = fetchFilter.value) {
        if (isLoading.value) {
            openDialogueBox("Page Still Loading", "Wait for previous query to finish, or refresh the page.")
            return;
        }
        isLoading.value = true;
        let payload = {
            token: store.state.auth.token,
            endpoint: endpoint
        }

        const output = await getCompaniesForClientAppendices(payload);
        // companies.value = output.data.data
        //  console.log(companies.value)
        // count.value = companies.value.length
        isLoading.value = false;
        return output.data.data;
    }

    async function loadCompaniesPaginated(endpoint = fetchFilter.value) {
        if (isLoading.value) {
            openDialogueBox("Page Still Loading", "Wait for previous query to finish, or refresh the page.")
            return;
        }
        isLoading.value = true;
        let payload = {
            token: store.state.auth.token,
            endpoint: endpoint
        }
        console.log("endpoint paginated: ", payload.endpoint);
        const output = await getCompaniesForClientAppendicesPaginated(payload);
        companies.value = output.companies;
        next.value = output.next;
        prev.value = output.prev;
        count.value = output.count;
        page.value = output.page;
        pageSize.value = companies.value.length;
        numPages.value = output.num_pages;
        
        console.log(output)
        isLoading.value = false;
    }

    function getNext() {
        if (next.value) {
            loadCompaniesPaginated(fetchFilter.value + next.value);
        } else {
            openDialogueBox("No Action Taken", "No Next Page");
        }
    }

    function getPrev() {
        if (prev.value) {
            loadCompaniesPaginated(fetchFilter.value + prev.value);
        } else {
            openDialogueBox("No Action Taken", "No Previous Page");
        }
    }

    function goToFirst() {
        loadCompaniesPaginated(fetchFilter.value + `&page=${1}`);
    }

    function goToLast() {
        loadCompaniesPaginated(fetchFilter.value + `&page=${numPages.value}`);
    }

    async function copyInfo(){
        if (count.value > 5000) {
            openDialogueBox("Error - Try Again","There are too many companies to copy. Please narrow your search parameters.");
            return;
        } 
        else if (count.value > 2000) {
            openDialogueBox("Error - Try Again", "Copying 2000+ companies may be a time-intensive process and slow other processes down. Confirm that you want to proceed.")
        }
        
        if (count.value < 1) {
            openDialogueBox("Error - Try Again", "You have no companies chosen.");
            return;
        }

        const all_companies = await loadCompanies(fetchFilter.value);

        // DM-7-30-24
        // let text = "";
        // text = text.concat("Exhibit A: Acquisition Target List (this list may be modified):");
        // text = text.concat("\n")
        // for (const company of all_companies) {
        //     text = text.concat(company.name);
        //     text = text.concat(', ');
        //     if(tableHeaders.value.includes('Doing Business As') && company.doing_business_as != null) {
        //         text = text.concat(company.doing_business_as);
        //         text = text.concat(', ');
        //     }
        //     if (tableHeaders.value.includes('Client') && company.client != null) {
        //         text = text.concat(company.client);
        //         text = text.concat(', ')
        //     }
        //     if (tableHeaders.value.includes('City') && company.city != null) {
        //         text = text.concat(company.city);
        //         text = text.concat(', ');
        //     }
        //     if (tableHeaders.value.includes('State') && company.state != null) {
        //         text = text.concat(company.state);
        //         text = text.concat(', ');
        //     }
        //     if (tableHeaders.value.includes('Country') && company.country != null) {
        //         text = text.concat(company.country);
        //         text = text.concat(', ');
        //     } 
        //     if (tableHeaders.value.includes('Tier') && company.tier != null) {
        //         text = text.concat(company.tier);
        //         text = text.concat(', ');
        //     }
        //     if (tableHeaders.value.includes('Website Url') && company.website_url != null) {
        //         text = text.concat(company.website_url);
        //         text = text.concat(', ');
        //     }
        //     if (tableHeaders.value.includes('Client Feedback') && company.client_feedback != null) {
        //         text = text.concat(company.client_feedback);
        //         text = text.concat(', ');
        //     }
        //     text = text.concat('\n');
        // }
        // try {
        //     await navigator.clipboard.writeText(text);
        //     openDialogueBox("Success", 'Content copied to clipboard.');
        //     /* Resolved - text copied to clipboard successfully */
        // } catch (err) {
        //     openDialogueBox("Error",'Failed to copy: ' + err);
        //     /* Rejected - text failed to copy to the clipboard */
        // }
    }

    loadCampaignOptions();
    loadClientOptions();
</script>


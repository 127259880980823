<template>
        <div @dblclick="toggleEditable" class="flex flex-wrap items-center align-middle cursor-pointer h-full">
            <div v-if="canCopy && fieldValue"
                @mouseenter="showClipboardIcon"
                @mouseleave="hideClipboardIcon"
            >
                <CopyClipboardSVG
                    v-if="visibleClipboard"
                    :fill="clipboardFill"
                    @mouseenter="highlightClipboard"
                    @mouseleave="unHighlightClipboard"
                    class="cursor-copy w-4 h-4 mr-1" @click="copyField()"
                />
                <slot v-else></slot>
            </div>
            <slot v-else></slot>
            <div v-if="isEditable" class="h-full">
                <BaseEditInput
                    :inputType="inputType"
                    :modelId="modelId" 
                    :modelType="modelType"
                    :inputText="fieldValue" 
                    :fieldName="fieldName"
                    @toggle-editable="toggleEditable"
                    @refetch="refetch"
                    @update-field-value="setField"
                />
            </div>
            <div v-else
                class="h-full"
                :style="displayedFieldStyle"
            >
                <a v-if="inputType=='url' && fieldValue" 
                    class="break-all text-accent-primary  hover:text-dark-primary hover:underline hover:cursor-pointer" 
                    target="_blank"
                    :href="urlPath"
                >
                    {{ displayedField }}
                </a>
                <div v-else>
                    <div v-if="canCopy && fieldValue" class="flex space-x-1 items-center w-full"
                        @mouseenter="showClipboardIcon"
                        @mouseleave="hideClipboardIcon"
                    >
                        <div class="">
                            {{ displayedField }}
                        </div>
                        <!-- <CopyClipboardSVG
                            v-if="visibleClipboard"
                            class="cursor-copy w-4 h-4" @click="copyField()"
                        /> -->
                    </div>
                    <div v-else>
                        {{ displayedField }}
                    </div>
            
                </div>
                <!-- <div v-else >
                    {{ displayedField }}
                </div> -->
            </div>
        </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, ref, watch } from 'vue';
import BaseEditInput from './BaseEditInput.vue';
import { formatDate } from "@/composables/formatDate";
import { useClipboard } from '@vueuse/core'
import CopyClipboardSVG from './svg-components/CopyClipboardSVG.vue';

const props = defineProps([
    'modelId',
    'modelType',
    'fieldName',
    'fieldValue',
    'inputType',
    'fieldStyle',
    'canCopy'
])

const emit = defineEmits(['refetch'])

const field = ref()
const isEditable = ref(false)

const visibleClipboard = ref(false)
const clipboardFill = ref('')

watch(props, ()=> {
    field.value = null
})

const displayedField = computed(() => {
    if(field.value) {
        if(props.inputType === 'date') {
            return formatDate(field.value)
        }
        return field.value
    } else if (props.fieldValue) {
        if(props.inputType === 'date') {
            return formatDate(props.fieldValue)
        }
        return props.fieldValue
    } else {
        return '--'
    }
})

const source = ref(displayedField.value)
const { text, copy, copied, isSupported } = useClipboard({ source })

watch(() => displayedField.value, ()=> {
    source.value = displayedField.value
})

const displayedFieldStyle = computed(() => {
    if(props.fieldStyle) {
        return props.fieldStyle
    } else {
        return ''
    }
})

const urlPath = computed(() => {
    if(props.inputType=='url'){
        if(props.fieldValue) {
            if(props.fieldValue.startsWith('http://') || props.fieldValue.startsWith('https://')) {
                return props.fieldValue
            } else {
                return `https://${props.fieldValue}`
            }
        }
    }
    return "#"
})

function toggleEditable () {
    isEditable.value = !isEditable.value
}

function setField(newValue) {
    field.value = newValue
}

function copyField() {
    copy(source.value)
}

function refetch() {
    emit('refetch')
}

function showClipboardIcon() {
    visibleClipboard.value = true
}

function hideClipboardIcon() {
    visibleClipboard.value = false
}

function highlightClipboard() {
    clipboardFill.value = '#000'
}

function unHighlightClipboard() {
    clipboardFill.value = '#FFF'
}
</script>
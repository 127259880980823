import axios from "axios";

import { BASE_URL } from "./api";

export async function getReferralSources(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/referrers/${payload.endpoint}`,
    headers: {
        'Authorization': `Token ${payload.token}`
    },
  })
    .then((response) => {
      return {
        referrals: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        pageSize: response.data.page_size,
        num_pages: response.data.num_pages,
      };
    })
    .catch((error) => {
      throw error;
    });
}

export async function createReferrer(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/referrers/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        console.log(response);
        return response
    }).catch((error) => {
        throw error
    })
}

export async function updateReferrer(referrerId, formData, token) {
  console.log(`${BASE_URL}/referrers/${referrerId}/`);
  console.log(formData)
  return axios({
      method: 'put',
      url: `${BASE_URL}/referrers/${referrerId}/`,
      headers: { 
          'Authorization': `Token ${token}`, 
          'Content-Type': 'application/json'
      },
      data: formData
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}
export default {
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
    setActiveFilters(state, activeFilters){
        state.activeFilters = activeFilters
    },
}
<template>
    <div v-if="showTimecardUsers">
    <!-- <div
      class="pt-3 pb-0.5 border-b mx-5 mb-2 border-b-gray-400 flex flex-row justify-between"
    >
      <h1 class="text-sm">Show Users in Timecard Group:</h1>
    </div> -->
    <div class="flex flex-row w-full mb-5">
        <div class = "mx-5">
            <base-button v-if="!tableExpanded" @click="toggleTableExpanded"> Show Users List</base-button>
            <base-button v-if="tableExpanded" @click="toggleTableExpanded"> Collapse Users List</base-button>
        </div>
        <!-- <div v-if="tableExpanded">
            <base-table-condensed class="w-full">
                <template #table-header>
                    <base-table-header
                    :key="header"
                    :header="header"
                    v-for="header in tableHeaders"
                    class="border-b border-b-gray-400 overflow-y-auto sticky top-[55px]"
                    >
                    {{ header }}
                    </base-table-header>
                </template>
                <template #table-body>
                    <template v-for="user in timecardUsers" :key="user.id">
                        <base-table-row>
                            <base-table-cell> {{user.first_name + " " + user.last_name}} </base-table-cell>
                            <base-table-cell> <base-button> Remove User from Timecard Group</base-button> </base-table-cell>
                        </base-table-row>
                    </template>
                </template>
            </base-table-condensed>
        </div> -->
       
    </div>
    <base-modal-small @close="toggleTableExpanded" class="z-20" v-if="tableExpanded">  
    <template #header> Users in Timecard Group </template>   
    <template #body>
        <div v-if="tableExpanded" class=" mt-1 grid grid-cols-2 overflow-y-scroll h-[90%]">
            <div v-for="user in timecardUsers" :key="user.id" class="flex flex-row justify-between items-center mx-10 border-b border-gray-200">
                <p class="mx-3 my-1"> {{user.first_name + " " + user.last_name}} </p>
                <base-button-negative class="font-bold" @click="removeHourlyUserFromTimecardGroup(user)"> X </base-button-negative>
            </div>
        </div>
    </template>   
    </base-modal-small>
</div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed} from 'vue';
import { getUsersByGroup } from '@/services/User.js';
import { useStore } from "vuex";
import { removeUserFromTimecardGroup } from '@/services/Timecard';

const store = useStore();
const showTimecardUsers = computed(() => store.getters['timecards/showTimecardUsers'])

const tableExpanded = ref(false);
const timecardUsers = ref([]);
const tableHeaders = ref(['User', 'Actions']);
function toggleTableExpanded() {
    tableExpanded.value = !(tableExpanded.value)
}

const emit = defineEmits(['reload', 'open-dialogue']);
onMounted(() => {
 loadTimecardUsers();
});

async function loadTimecardUsers() {
    let payload = {
        token: store.state.auth.token,
        group: 'generate_timecard_group',
        endpoint: "list_users",
    }
    const output = await getUsersByGroup(payload);
    timecardUsers.value = output.users;

}

function reloadPage() {
    loadTimecardUsers();
    emit('reload');
}

async function removeHourlyUserFromTimecardGroup(user) {
    if (!user || user.id == "") {
        emit('open-dialogue', 'Error', 'An error occurred while trying to identify the user. Please reload the page.');
        return;
    } 
    const data = JSON.stringify({
        user: user.id,
    });
    const output = await removeUserFromTimecardGroup(data, store.state.auth.token);
    if (output) {
        emit('open-dialogue', "Success", "Successfully removed " + user.first_name + " " + user.last_name + " from the Timecard group.");
    } else {
        emit('open-dialogue', "Error", "An error occurred while trying to remove user from Timecard Group. Please try again." );
    }
    reloadPage();
}
</script>
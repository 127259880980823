<template>
    <div class="w-full flex flex-col items-start justify-start space-y-1 border-b border-gray-300 pb-2">
        <div class="flex-col">
            <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Full Name:</div>
            <input type="text" placeholder="Prefix" required class="p-1 border h-full w-full inline rounded-t" v-model="contactData.prefix">
            <input type="text" placeholder="First" required class="p-1 border h-full w-full inline" v-model="contactData.first_name">
            <input type="text" placeholder="Preferred" required class="p-1 border h-full w-full inline" v-model="contactData.preferred_name">
            <input type="text" placeholder="Middle" required class="p-1 border h-full w-full inline" v-model="contactData.middle_name">
            <input type="text" placeholder="Last" required class="p-1 border h-full w-full inline" v-model="contactData.last_name">
            <input type="text" placeholder="Suffix" required class="p-1 border h-full w-full inline rounded-b" v-model="contactData.suffix">
        </div>
        <div class="flex-col">
            <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Company Info:</div>
            <input type="text" placeholder="Title" required class="p-1 border h-full w-full inline rounded-t" v-model="companyRelationshipData.title">
            <input type="text" placeholder="Company Email" required class="p-1 border h-full w-full inline" v-model="companyRelationshipData.email_address">
            <input type="text" placeholder="Company Phone" required class="p-1 border h-full w-full inline" v-model="companyRelationshipData.phone_number">
            <textarea class="p-1 border h-full w-full rounded-b" placeholder='Notes' v-model="companyRelationshipData.contact_notes"></textarea>
        </div>
        <div class="flex-col">
            <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Personal Info:</div>
            <input type="text" placeholder="Personal Email" class="p-1 border h-full w-full rounded-t" v-model="contactData.personal_email">
            <input type="text" placeholder="Personal Phone" class="p-1 border h-full w-full" v-model="contactData.personal_phone">
            <input type="text" placeholder="LinkedIn Profile" class="p-1 border h-full w-full rounded-b" v-model="contactData.linkedin_profile">
            <!-- <textarea class="p-1 border h-full w-full rounded-b" placeholder="Personal Notes" v-model="contactData.personal_notes"></textarea> -->
        </div>
        <div class="flex-col w-full">
            <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Is Primary Contact:</div>
            <select name="isPrimaryContact" v-model="companyRelationshipData.is_primary_contact" class="p-1 border h-full w-full rounded" id="">
                <option :value="true">True</option>
                <option :value="false" selected>False</option>
            </select>
        </div>
        <div class="flex flex-row space-x-1 pt-1 justify-end w-full">
            <base-button-affirmative @click="addContact">Save</base-button-affirmative>
            <base-button-negative @click="closeForm">Cancel</base-button-negative>
        </div>
    </div>
<!-- 
                <div class="flex items-center justify-between space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Name: </base-pill>
                    </div>
                    <div class="flex space-x-1 w-full">
                        <input type="text" placeholder="First" required class="p-1 border h-full w-full inline" v-model="contactData.first_name">
                        <input type="text" placeholder="Last" required class="p-1 border h-full w-full inline" v-model="contactData.last_name">
                    </div>
                </div>
                <div class="flex items-center justify-between space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Preferred Name: </base-pill>
                    </div>
                    <div class="flex space-x-1 w-full">
                        <input type="text" placeholder="Preferred Name" required class="p-1 border h-full w-full inline" v-model="contactData.preferred_name">
                    </div>
                </div>
                <div class="flex items-center justify-between space-x-1">
                    <div class="flex justify-end ">
                        <base-pill class="h-fit">Personal Email: </base-pill>
                    </div>
                    <div class="flex space-x-1  w-full">
                        <input type="email" class="p-1 border h-full w-full" v-model="contactData.personal_email">
                    </div>
                </div>
                <div  class="flex items-center justify-between space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Linkedin: </base-pill>
                    </div>
                    <div class="flex space-x-1 w-full">
                        <input inputType="url" class="p-1 border h-full w-full" v-model="contactData.linkedin_profile">
                    </div>
                </div>
                <div class="flex items-center space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Personal Notes: </base-pill>
                    </div>
                    <div class="w-full">
                        <textarea class="p-1 border h-full w-full" v-model="contactData.personal_notes"></textarea>
                    </div>
                </div>
            </div>
            <div class="flex-col">
                <div class="flex items-center space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Title: </base-pill>
                    </div>
                    <div class="flex space-x-1 w-full">
                        <input type="text" required class="p-1 border h-full w-full" v-model="companyRelationshipData.title">
                    </div>
                </div>
                <div class="flex items-center space-x-1">
                    <div class="flex justify-end ">
                        <base-pill class="h-fit">Outreach Phone: </base-pill>
                    </div>
                    <div class="flex space-x-1  w-full">
                        <input inputType="tel" required class="p-1 border h-full w-full" v-model="companyRelationshipData.phone_number">
                    </div>
                </div>
                <div class="flex items-center space-x-1">
                    <div class="flex justify-end ">
                        <base-pill class="h-fit">Outreach Email: </base-pill>
                    </div>
                    <div class="flex space-x-1  w-full">
                        <input inputType="email" required class="p-1 border h-full w-full" v-model="companyRelationshipData.email_address">
                    </div>
                </div>
                <div class="flex items-center space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Contact Notes: </base-pill>
                    </div>
                    <div class="w-full">
                        <textarea class="p-1 border h-full w-full" v-model="companyRelationshipData.contact_notes"></textarea>
                    </div>
                </div>
                <div class="flex items-center space-x-1">
                    <div class="flex justify-end">
                        <base-pill class="h-fit">Is Primary: </base-pill>
                    </div>
                    <div class="flex space-x-1">
                        <select name="isPrimaryContact" v-model="companyRelationshipData.is_primary_contact" class="p-1 border h-full w-full" id="">
                        <option :value="true">True</option>
                        <option :value="false" selected>False</option>
                    </select>
                    </div>
                </div>
                <div></div>
                <div class="flex flex-row space-x-2 justify-end w-full">
                    <base-button-affirmative @click="addContact">Save</base-button-affirmative>
                    <base-button-negative @click="closeForm">X</base-button-negative>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
    export default {
        name: "company-contact-form-vertical"
    }
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { createContactAndCompanyRelationship } from '@/services/Contact';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const $toast = useToast()

    const emit = defineEmits(['refetch-companies', 'close-form'])

    const firstName = ref('')
    const lastName = ref('')
    const preferredName = ref('')
    const personalEemail = ref('')
    const emailAddress = ref('')
    const linkedIn = ref('')
    const contactNotes = ref('')
    const title = ref('')
    const personalPhone = ref('')
    const phoneNumber = ref('')
    const isPrimaryContact = ref('')
    

    function setFirstName(event) {
        contactData.value.first_name = event.target.value
    }

    function setLastName(event) {
        contactData.value.last_name = event.target.value
    }

    function setEmail(event) {
        contactData.value.email = event.target.value
    }

    function setLinkedIn(event) {
        contactData.value.linkedin_profile = event.target.value
    }

    function setContactNotes(event) {
        contactData.value.notes = event.target.value
    }

    function setTitle(event) {
        contactData.value.title = event.target.value
    }

    function setPhone(event) {
        contactData.value.phone = event.target.value
    }

    function setPrimaryContact(event) {
        contactData.value.is_primary_contact = event.target.value
    }

    const contactData = ref({
        first_name: null,
        last_name: null,
        personal_phone: null,
        personal_email: null,
        linkedin_profile: null,
        personal_notes: null,
        preferred_name: null,
    })

    const companyRelationshipData = ref({
        email_address: null,
        contact_notes: null,
        title: null,
        phone_number: null,
        is_primary_contact: false,
        status: "active",
        company_id: props.company.id
    })

    function closeForm(){
        emit('close-form')
    }

    async function addContact(){
        const token = store.state.auth.token
        let errorMessage = "Error, missing "
        if(!contactData.value.first_name || !contactData.value.last_name){
            if(!contactData.value.action_name){
                errorMessage += "first name "
            }
            if(!contactData.value.last_name){
                errorMessage += "last name "
            }
            alert(errorMessage)
            return
        }
        const data = {
            "contact": contactData.value,
            "company_relationship": companyRelationshipData.value
        }
        console.log('form data', data)
        const response = await createContactAndCompanyRelationship(data, token)
        console.log('response', response)
        if (response.status == 200){
            $toast.open(
                {
                    message: `Contact created for ${props.company.company_name}`,
                    type: 'success',
                    duration: 20000
                }
            )
            emit('refetch-companies')
            emit('close-form')
        } else {
            $toast.open(
                {
                    message: `${response.response.data.detail}`,
                    type: 'error',
                    duration: 20000
                }
            )
        }

    }


</script>
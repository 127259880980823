<template>
    <div class="w-full h-full">
        <div class="w-full my-2 mx-auto px-2 rounded-b-lg max-w-[1200px] bg-white">
            <div class="w-full h-full space-y-2 p-2">
                <div class="grid grid-cols-4 space-x-2 px-1 w-full">
                    <div class="grid grid-cols-2 space-x-2 col-span-2">
                        <div>
                            <div>Parent ID:</div>
                            <input class="p-1 border mt-1 flex-col border-gray-400 rounded-md w-full" v-model="acquisitionPostData.parent_id" type="tel"/>
                        </div>
                        <div class="flex flex-col justify-end">
                            <base-button @click="setSelfOwned">Self-Owned</base-button>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div>Parent Ownership Type:</div>
                        <select class="border border-gray-400 mt-1 rounded-md p-1 w-full" v-model="ownershipType" name="" id="">
                            <option v-for="ownershipType in ownershipTypeOptions" :key="ownershipType.value" :value="ownershipType.value">
                                {{ ownershipType.label }}
                            </option>
                        </select>
                    </div>
                    <div v-if="!isSelfOwned" class="grid grid-cols-2 space-x-2 w-full">
                        <div>
                            <div>Acquisition Date:</div>
                            <input class="p-1 border mt-1 border-gray-400 rounded-md" v-model="acquisitionPostData.acquisition_date" type="date"/>
                        </div>  
                        <div>
                            <div>Acquisition Order:</div>
                            <input class="p-1 border mt-1 border-gray-400 rounded-md w-full" v-model="acquisitionPostData.ordinal" type="number"/>
                        </div>
                    </div>
                    <div v-else >
                        <div>Acquisition Order:</div>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md w-full" v-model="acquisitionPostData.ordinal" type="number"/>
                    </div>
                    <div v-if="isLoading" role="status" class="flex justify-start items-center pt-2 h-8">
                        <svg aria-hidden="true" class="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="white"/>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#2E9CCA"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else class=" w-full p-1 h-8">{{ parentCompanyName }}</div>
                </div>
                <div class="flex w-full justify-end space-x-2">
                    <base-button-affirmative @click="addAcquisitionPost">Post</base-button-affirmative>
                    <base-button-negative @click="closeForm">Cancel</base-button-negative>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { getCompanyByID } from '@/services/Company';
import { updateCompany } from '@/services/Company';
import { createCompanyParent } from '@/services/CompanyParentSubsidiary';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const props = defineProps(['company', 'maxOrdinal'])
const emit = defineEmits(['refetch-companies', 'close-form'])

const store = useStore()
const $toast = useToast()

const parentCompanyName = ref(null)
const awaitingSearch = ref(false)
const shouldLoadCompany = ref(true)
const isLoading = ref(false)
const parentOwnershipType = ref(null)
const ownershipType = ref(null)


const acquisitionPostData = ref({
    parent_id: null,
    subsidiary_id: props.company.id,
    acquisition_date: null,
    ordinal: props.maxOrdinal
})

function closeForm(){
    emit('close-form')
}

watch(() => acquisitionPostData.value.parent_id, ()=> {
    shouldLoadCompany.value = false
    if(!awaitingSearch.value) {
        setTimeout(() => {
            setParentCompanyName(acquisitionPostData.value.parent_id)
            awaitingSearch.value = false
        }, 1500)
    }
    awaitingSearch.value = true

})

const isSelfOwned = computed(()=> {
    if(acquisitionPostData.value.parent_id == props.company.id) {
        return true
    } else {
        return false
    }
})

watch(()=> isSelfOwned.value, ()=> {
    if(isSelfOwned.value){
        acquisitionPostData.value.acquisition_date = null
    }
})

const ownershipTypeOptions = computed(() => {
    return [
        {value: null, label: "-------"},
        {value: "Government Owned", label: "Government Owned"},
        {value: "Private Equity Owned", label: "Private Equity Owned"},
        {value: "Proprietor Owned", label: "Proprietor Owned"},
        {value: "Public", label: "Public"},
    ]
})


function setSelfOwned() {
    acquisitionPostData.value.parent_id = props.company.id
}

async function setParentCompanyName(companyId) {
    if(acquisitionPostData.value.parent_id){
        isLoading.value = true;
        shouldLoadCompany.value = true;
        console.log('setParentCompanyName')
        let payload = {
            token: store.state.auth.token,
            companyId: companyId
        }
        const result = await getCompanyByID(payload)
        if(shouldLoadCompany.value) {
            console.log(result)
            console.log(result.response)
            if(result.response.status == 200) {
                parentCompanyName.value = result.response.data.company_name
                ownershipType.value = result.response.data.ownership
                parentOwnershipType.value = result.response.data.ownership
            } else {
                parentCompanyName.value = 'No Company Found'
                ownershipType.value = '--'
            }
            isLoading.value = false;
        } else {
            return
        }
    } else {
        parentCompanyName.value = 'Please Enter Company ID'
        ownershipType.value = '--'
    }
}


async function addAcquisitionPost(){
    const token = store.state.auth.token
    const result = await createCompanyParent(acquisitionPostData, token)
    if(ownershipType.value != parentOwnershipType.value) {
        const data = JSON.stringify({
            ownership: ownershipType.value
        })
        await updateCompany(acquisitionPostData.value.parent_id, data, token)
    }
    if(result.status==200) {
        $toast.open({
            message: `Successfully Created Acquisition.`,
            type: 'success',
            duration: 5000
        })
        emit('refetch-companies')
    } else {
        $toast.open({
            message: 'Error Creating Acquisition.',
            type: 'error',
            duration: 20000
        })
    }
    emit('refetch-companies')
    emit('close-form')
}

</script>
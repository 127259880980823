<template>
    <div class="mx-5 w-full">
        <base-table class="w-full">
            <template #table-header>
                <base-table-header
                :key="header"
                :header="header"
                v-for="header in headers"
                class="border-b border-b-gray-400 overflow-y-auto sticky top-[55px]"
                >
                    {{ header }}
                </base-table-header>
            </template>
            <template #table-body>
                <template v-for="template in data" :key="template.id">
                    <base-table-row>
                        <base-table-cell> {{ template.id }} </base-table-cell>
                        <base-table-cell> {{ template.email_template_name }}</base-table-cell>
                        <base-table-cell> {{ template.email_from }}</base-table-cell>
                        <base-table-cell> {{ template.email_to ? template.email_to.email_address : '' }}</base-table-cell>
                        <base-table-cell> {{ template.client ? template.client.client_name : '' }}</base-table-cell>
                        <base-table-cell> {{ template.status.toLowerCase() }} </base-table-cell>
                        <base-table-cell class=""> 
                            <base-button @click= "openEditEmailTemplate(template.id)"> Edit Template</base-button>
                        </base-table-cell>
                    </base-table-row>
                </template>
            </template>
        </base-table>
    </div>
</template>

<script setup>
import {ref, defineProps, defineEmits} from 'vue';
import { useStore } from 'vuex';
import { useRouter} from 'vue-router';

const router = useRouter(); 

const emit = defineEmits(['edit']);
const props = defineProps({
    'headers' : Array,
    'data' : Array
})

async function openEditEmailTemplate(id) {
    const pathStr = '/edit_email_template/' + id
    router.push({path: pathStr})
}


</script>
<template>
    <div class="mt-2 grid grid-cols-1 gap-1">
        <button @click="removeFromCampaign" class="p-1 border text-xs border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">Remove Campaign</button>
    </div>
</template>

<script>
    export default {
        name: "remove-from-campaign-cell"
    }
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { useStore } from 'vuex';
import { assignCampaign } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    async function removeFromCampaign(){
        const token = store.state.auth.token
        await assignCampaign("null", props.company.id, token)
        emit('refetch-companies')
    }
</script>
<template>
  <!-- <tr :class="isSelected ? bg-sky-100 : white">
    <slot></slot>
  </tr> -->
  <tr v-if="isSelected" class="bg-light-primary text-dark-primary">
    <slot></slot>
  </tr>
  <tr v-else class="odd:white even:bg-gray-50">
    <slot></slot>
  </tr>

</template>

<script>
export default {
  name: "BaseTableRow.vue",
  props: ['isSelected']
}
</script>
<style scoped>
</style>
<template>
    <div class="w-full flex flex-row">
      <div class="w-2 border-dark-secondary border-b"></div>
      <div v-for="tab in tabs" :key="tab.tabName" class="w-full flex flex-row">
        <div @click="focusTab(tab)" :class="tab.style">
          {{ tab.tabName }}
        </div>
        <div class="w-1 border-dark-secondary border-b">
        </div>
      </div>
      <div :class="blankSpaceStyle">
      </div>
    </div>
  </template>
  
  <script setup>
    import { ref, defineEmits, defineProps, computed} from 'vue'
    const props = defineProps({
      // elements within tabList should be in the format {tabName: "", emitName: ""} 
      // tabList ORDER MATTERS
      tabList: Array,
      selectedColor: String, // i.e. red-100, gray-200
      notSelectedColor: String, // i.e. red-100, gray-200
      hoverColor: String // i.e. red-100, gray-200
    })
  
    const emit = defineEmits(['set-page'])
    const tabs = ref([]) // true is selected, false is not selected
    const selectedStyling = `py-1 px-2 w-full text-center text-xs py rounded-t-md border-dark-secondary border-t border-l border-r bg-${props.selectedColor} truncate`
    const notSelectedStyling = `py-1 px-2 w-full text-center text-xs py rounded-t-md border border-gray-400 border-b-dark-secondary bg-${props.notSelectedColor} hover:bg-${props.hoverColor} cursor-pointer truncate`
  
    // populate states
    // first tab is initially focused
    for(let i = 0; i < props.tabList.length; i++) {
      if (i == 0) {
        tabs.value.push({
          tabName: props.tabList[i].tabName,
          style: selectedStyling
        })
      } else {
        tabs.value.push({
          tabName: props.tabList[i].tabName,
          style: notSelectedStyling
        })
      }
    }
    // console.log(tabs.value)
  
    // spacing
    const width = computed(() => {
      return 100-(15*props.tabList.length)
    })
  
  
    const blankSpaceStyle = computed(() => {
      return `w-[${parseInt(width.value)}%] border-b border-dark-secondary`
    })
  
    // general tab control
    function focusTab(selectedTab) {
      // reset stylings
      tabs.value.forEach((tab) => {
        tab.style = notSelectedStyling
      })
  
      // Set selected tab to correct styling
      selectedTab.style = selectedStyling
  
      emit('set-page', selectedTab.tabName)
    }
  </script>
  
  <script>
  export default {
    name: "base-side-bar-tab",
  }
  </script>
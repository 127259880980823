<template>
    <div class="w-full h-[calc(100vh-3.5rem)] fixed flex space-y-2 text-xs items-center flex-col bg-gray-200">
        <transition
            enter-active-class="transition duration-400 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-400 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
        >
            <div v-if="isLoading" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.75] flex flex-col justify-center items-center z-40"
                    tabindex="-1" >
                <div class="flex flex-col justify-center items-center p-4 rounded-md">
                <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#d3d3d3"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                </svg>
                <div class="text-white p-4 whitespace-nowrap text-base font-semibold tracking-wide">
                    Retrieving Company CRM Information
                </div>
                </div>
            </div>
        </transition>
        <div class="w-full h-full flex flex-col fixed border-gray-300 border-x  max-w-[2200px] bg-gray-50">
            <div class="px-2 py-3 flex justify-between items-center space-x-3 w-full  text-sm border-b  border-gray-300">
                <div class="flex justify-start items-center space-x-2 w-[392px] min-w-[392px] pr-2">
                    <div class="w-9 cursor-pointer hover:bg-gray-300 rounded-md p-1"
                        :style="navToggleStyle"
                        @click="toggleLeftNav"
                    >                 
                        <DoubleLeftArrowSVG
                            v-if="visibleLeftNav"
                            stroke="#4B5563"
                        />
                        <DoubleRightArrowSVG
                            v-else
                            stroke="#4B5563"
                        />
                    </div>
                    <div class="w-full flex justify-start items-center space-x-2">
                        <div class="h-8 w-full">
                            <select
                                class="border border-gray-300 px-2 py-1 block rounded-md w-full size-fit break-normal h-full"
                                v-model="crmResolutionFilters.clientFilter.value"
                            >
                                <option value=null disabled selected hidden class="text-gray-400">Choose Client</option>
                                <option v-for="option in crmResolutionFilters.clientFilter.options" :key="option.value" :value="option.value">{{ option.label }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="crmResolutionFilters.clientFilter.value" class="w-fit cursor-pointer hover:bg-gray-300 rounded-full p-1"
                    @click="loadCompanies"
                >
                    <div v-if="isLoading" role="status" class="">
                        <svg
                            aria-hidden="true"
                            class="w-6 h-6 animate-spin"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="white"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#2E9CCA"
                            />
                            </svg>
                        </div>
                        <div v-else class="">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#4B5563"
                            class=" w-6 h-6 hover:cursor-pointer"
                            >
                            <path
                                class=""
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                            </svg>
                        </div>
                    </div>
                    <div v-else>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#A3A3A3"
                            class=" w-6 h-6"
                        >
                            <path
                                class=""
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>
                    </div>
                </div>
                <div class="w-full flex justify-between items-center">
                    <div class="flex justify-start space-x-2">
                        <div class="tracking-widest font-semibold text-gray-600 whitespace-nowrap">
                            Company ID:
                        </div>
                        <div class="whitespace-nowrap truncate">
                            {{ selectedCompanyId }}
                        </div>
                    </div>
                    <div class="flex justify-start space-x-2">
                        <div class="tracking-widest font-semibold text-gray-600  whitespace-nowrap">
                            Company Name:
                        </div>
                        <div class="whitespace-nowrap truncate">
                            {{ selectedCompanyName }}
                        </div>
                    </div>
                    <div class="flex justify-start space-x-2 pr-2">
                        <div class="tracking-widest font-semibold text-gray-600  whitespace-nowrap">
                            Company Campaign:
                        </div>
                        <div class="whitespace-nowrap truncate">
                            {{ selectedCompanyCampaign }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full h-full flex justify-center">
                <TransitionGroup name="navport">
                    <div v-if="visibleLeftNav" key='leftNav' class="w-[400px] min-w-[400px] h-full sticky top-0 bg-gray-100 border-r border-gray-300 p-2">

                        <div class="w-full h-full px-1 py-2">
                            <div class="relative top-0 flex w-full left-0 right-0 z-10 tracking-widest text-gray-600 border-b  border-gray-300">
                                <div class="bg-gray-100  px-2 pb-4 pt-2  sticky top-0  flex w-[30%] justify-start font-semibold ">
                                    ID
                                </div>
                                <div class="bg-gray-100 pr-2 pb-4 pt-2  sticky top-0 flex w-[70%] justify-between whitespace-nowrap font-semibold">
                                    <div>
                                        Company Name
                                    </div>
                                    <div>
                                        {{ numberOfCompanies }}
                                    </div>
                                </div>
                            </div>
                            <div class="relative top-0 overflow-y-scroll h-[calc(100vh-11rem)]">
                                <CRMCompanyRow
                                        v-for="(row, index) in displayedRows" 
                                        :key="row.data.id"
                                        :rowData="row"
                                        :index="index"
                                        @select-company="selectCompany"
                                    />
                            </div>
                        </div>
                    </div>
                    <div class="w-full h-full" key="viewPort">
                        <CRMViewPort
                            v-if="selectedCompany"
                            :selection="selection"
                            :lastSelected="lastSelected"
                            :rowData="selectedCompany"
                            :crmActionTypeOptions="crmActionTypeOptions"
                            :userOptions="userOptions"
                            @resolve="resolveCompanyCRM"
                        />
                        <div 
                            v-else
                            class="w-full h-full flex flex-col text-sm justify-center items-center font-semibold tracking-widest bg-gray-50 text-gray-600"
                        >
                            <div class="text-lg tracking-widest py-2 border-b border-gray-300">No Companies To Resolve</div>
                            <div class="py-2 tracking-wider">Refresh, Or Select A Different Client</div>
                            
                        </div>
                    </div>
                </TransitionGroup>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { useTextSelection } from '@vueuse/core'
// import { getDummyCompanies, updateDummyCompanyActions } from '@/services/DummyCompany';
import { getCRMResolutionCompanies, resolveCRMActions } from '@/services/Company';
import CRMCompanyRow from './CRMCompanyRow.vue';
import CRMViewPort from './CRMViewPort.vue';
import DoubleRightArrowSVG from '../svg-components/DoubleRightArrowSVG.vue';
import DoubleLeftArrowSVG from '../svg-components/DoubleLeftArrowSVG.vue';
import { getClients } from '@/services/Client';
import {getActions} from '@/services/CrmAction';
import { getUsers } from '@/services/User';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const companies = ref([])
const visibleLeftNav = ref(true)
const isLoading = ref(false)

const selection = useTextSelection()

const lastSelected = ref(null)

const crmActionTypeOptions = ref([])

const userOptions = ref([
    {value: null, label: 'None'}
])

const numberOfCompanies = ref(0)


watch(() => selection, () => {
    if(selection) {
        if(selection.text.value && selection.text.value !== ''){
            // console.log('lastSelected', selection.text.value)
            lastSelected.value = selection.text.value
        }
    }
}, {deep: true})


// const lastSelected = computed(() => {
//     // console.log(window.getSelection().toString().text)
//     if(selection) {
//         if(selection.text.value && selection.text.value !== ''){
//             console.log('lastSelected', selection.text.value)
//             return selection.text.value
//         }
//     }
//     return ''
// })


const displayedRows = computed(() => {
    if(companies.value.length > 0) {
        return companies.value.filter(company => company.display)
    }
    return []
})

watch(() => displayedRows.value, () => {
    if(displayedRows.value.length > 0) {
        selectCompany(displayedRows.value[0])
    } else {
        resetSelected()
    }   

})

const crmResolutionFilters = reactive({
    clientFilter: {
        name: 'Client',
        value: null,
        defaultValue: null,
        options: [
        ]

    },
})

watch(() => crmResolutionFilters.clientFilter.value, () => {
    loadCompanies()
}, {deep: true})

const selectedCompany = ref(null)


const selectedCompanyName = computed(() => {
    return selectedCompany.value ? selectedCompany.value.data.company_name : '--'
})


const selectedCompanyId = computed(() => {
    return selectedCompany.value ? selectedCompany.value.data.id : '--'
})


const selectedCompanyCampaign = computed(() => {
    if(selectedCompany.value) {
        if(selectedCompany.value.data) {
            if(selectedCompany.value.data.campaign) {
                return selectedCompany.value.data.campaign.campaign_name
            }
        }
    }
    return '--'
})

const navToggleStyle = computed(() => {
    if(visibleLeftNav.value) {
        return 'background-color: #9CA3AF;'
    } else {
        return ''
    }
})

function toggleLeftNav() {
    visibleLeftNav.value = !visibleLeftNav.value
}


function selectCompany(companyRow) {
    if(companyRow) {
        resetSelected()
        selectedCompany.value = companyRow
        selectedCompany.value.isSelected = true
    }
}

function resetSelected() {
    for(let company of companies.value) {
        company.isSelected = false
    }
    selectedCompany.value = null
}

function validateCRMActionRows(actionRows) {
    let invalidCRMActionRows = []
    let validCRMActionRows = []
    for(let actionRow of actionRows) {
        if(actionRow.action_id) {
            if(actionRow.resolved_date && actionRow.campaign_id) {
                // valid
                validCRMActionRows.push(actionRow)
            } else {
                // empty or invalid
                if(actionRow.user || actionRow.action_notes || actionRow.details) {
                    // invalid
                    invalidCRMActionRows.push(actionRow)
                }
                // empty
            }
        } else {
            invalidCRMActionRows.push(actionRow)
        }
    }
    if(invalidCRMActionRows.length > 0) {
        alert(`Validation Error: Found ${invalidCRMActionRows.length} invalid row(s). Please fix before continuing.`)
        return null
    }
    return validCRMActionRows
}

async function setClientOptions() {
  const token = store.getters['auth/showToken']
  const result = await getClients({token: token, endpoint: '?has_unresolved_crm_summaries=true'})
  crmResolutionFilters.clientFilter.options = result.clients.map((client) => {
      return {value: client.id, label: client.client_name}
  })
}

async function setUserOptions() {
    let payload = {
        token: store.state.auth.token,
        endpoint: '?group_id=1&group_id=7&group_id=8&exclude_dev=True'
    }
    const result = await getUsers(payload)
    userOptions.value = [{value: null, label: 'None'}, ...result.users.map((user) => {
        return { value: user.id, label: `${user.first_name} ${user.last_name}`}
    })]
}


// async function loadDummyCompanies() {
//     isLoading.value = true
//     const token = store.state.auth.token
//     const response = await getDummyCompanies(token)
//     companies.value = response.data.results.map(result => ({data: result, display: true, isSelected: false,}));
//     if(companies.value.length > 0) {
//         // selectedCompany.value = companies.value[0]
//         selectCompany(displayedRows.value[0])
//     }
//     isLoading.value = false
// }

async function loadCompanies() {
    console.log('loadCompanies')
    isLoading.value = true
    const payload = {
        token: store.state.auth.token,
        endpoint: `?client_drf=${crmResolutionFilters.clientFilter.value}&get_all=true`
    }
    const response = await getCRMResolutionCompanies(payload)
    console.log('loadCompanies response')
    console.log(response)
    companies.value = response.data.map(result => ({data: result, display: true, isSelected: false,}));
    if(companies.value.length > 0) {
        numberOfCompanies.value = companies.value.length
        selectCompany(displayedRows.value[0])
    } else {
        numberOfCompanies.value = 0
    }

    isLoading.value = false
}

async function resolveCompanyCRM(companyId, companyCRMActions) {
    console.log('resolveCompanyCRM', companyId)
    let actionRows = validateCRMActionRows(companyCRMActions)
    if(actionRows) {
        let match = companies.value.find((object) => object.data.id == companyId)
        // let matchIndex = companies.value.find((object) => object.data.id == companyId)
        console.log('match result', match)
        if(match) {
            console.log('match found', match)
            match.display = false
        }
        const payload = {
            token: store.state.auth.token,
            formData: JSON.stringify(
            {
                crm_actions: actionRows
            }),
            companyId: companyId
        }
        console.log('payload')
        console.log(payload)
        const response = await resolveCRMActions(payload)
        if(response.status == 200) {
            $toast.open({
                message: `Successfully Resolved Actions for Company ${companyId}.`,
                type: 'success',
                duration: 5000
            })
        } else {
            $toast.open({
                message: `Unable to Resolve Actions for Company ${companyId}`,
                type: 'error',
                duration: 20000
            })
        }
    }

}

async function setCRMActionTypeOptions() {
    const token = store.state.auth.token
    const response = await getActions(token)
    // console.log('response', response.data)
    crmActionTypeOptions.value = response.data.map((crmActionType) => {
        return {value: crmActionType.id, label: crmActionType.action_name}
    })
}

// loadDummyCompanies()
setClientOptions()
setUserOptions()
setCRMActionTypeOptions()
</script>

<style scoped>
.navport-move,
.navport-enter-active {
    transition: all 0.3s ease;
}
.navport-leave-active {
  transition: all 0.14s ease;
}
.navport-enter-from,
.navport-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.navport-leave-active {
    /* position: absolute; */
    position: relative;
}
</style>
<template>
    <div>
        <span>{{revenueHeadcount}}</span>
    </div>
    <div>
        <span><base-pill>Rev. Last Upd:</base-pill>{{ formattedRevenueDate }}</span>
    </div>
    <div>
        <span><base-pill>Rev. Source:</base-pill>{{ formattedRevenueSource }}</span>
    </div>
</template>

<script>
    export default {
        name: "revenue-headcount-rev-last-updated-cell"
    }
</script>

<script setup>
import {defineProps, computed} from "vue"
import {formatDate} from '@/composables/formatDate'

    const props = defineProps({
        company: Object
    })

    const revenueHeadcount = computed(() => {
        return `${props.company.revenue} M / ${props.company.employee_headcount} ppl`
    })

    const formattedRevenueSource = computed(() => {
        if(props.company.revenue_source) {
            return props.company.revenue_source
        } else {
            return '--'
        }
    })

    const formattedRevenueDate = computed(() => {
        // console.log(props.revenueLastUpdatedDate)
        try {
            return formatDate(props.company.revenue_last_updated)
        } catch {
            return '--'
        }
    })



</script>
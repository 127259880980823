<template>
  <tr>
    <base-table-cell> {{ project.id + " " + project.status }}</base-table-cell>
    <base-table-cell> {{ project.end_date }}</base-table-cell>
    <base-table-cell> {{ project.priority }}</base-table-cell>
    <base-table-cell>
      <p>{{ project.project_name }}</p>
      <base-button
        @click="toggleRow()"
        v-if="project.projectassignment_set.length > 0"
      >
        Researchers
      </base-button>
    </base-table-cell>
    <base-table-cell> {{ project.stage }}</base-table-cell>

    <base-table-cell>
      {{
        project.research_lead.first_name + " " + project.research_lead.last_name
      }}</base-table-cell
    >
    <base-table-cell>
      {{ project.client ? project.client.client_name : " " }}</base-table-cell
    >
    <base-table-cell> {{ project.description }}</base-table-cell>
    <base-table-cell> {{ project.start_date }}</base-table-cell>
    <base-table-cell>
      {{ parseInt(project.percent_complete) + "%" }}</base-table-cell
    >
    <base-table-cell>
      {{ Math.round(project.etc_person_days) + " days" }}</base-table-cell
    >
    <base-table-cell >
      <template v-if="project.status == 'active'" >  
        <base-button @click="editRow(project)" class="mr-3"> Edit </base-button>
        <base-button
          v-if="project.status == 'active'"
          @click="archiveProject(project)"
        >
          Archive
        </base-button>
      </template>
      
      <base-button
        v-if="project.status == 'inactive'"
        @click="unarchiveProject(project)"
      >
        Unarchive
      </base-button>
    </base-table-cell>
  </tr>
  <template v-if="isExpanded">
    <base-table-cell></base-table-cell>

    <base-table-cell 
        :key="header"
        :header="header"
        v-for="header in researchersTableHeaders"
        colspan="4"
        class="border-b border-b-gray-400 font-semibold overflow-y-auto sticky top-[55px]"
        >
          {{ header }}
        </base-table-cell>
      </template>
  <template
    v-for="projectAssignment in project.projectassignment_set"
    :key="projectAssignment.id"
  >
    <tr v-if="isExpanded">
      <base-table-cell></base-table-cell>
      <base-table-cell colspan="4" class="bg-gray-100">
        {{
          projectAssignment.researcher.first_name +
          " " +
          projectAssignment.researcher.last_name
        }}
      </base-table-cell>
      <base-table-cell colspan="4" class="bg-gray-100">
        {{
          projectAssignment.start_date ? projectAssignment.start_date : "None"
        }}
        -
        {{ projectAssignment.end_date ? projectAssignment.end_date : "None" }}
      </base-table-cell>
      <base-table-cell colspan="4" class="bg-gray-100">
        {{ Math.round(projectAssignment.hourly_capacity_per_week) }}
        Hours
      </base-table-cell>
    </tr>
  </template>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from "vue";
import { markResearchProjectStatus } from "@/services/ResearchProjects";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  project: Object,
  isExpandedAll: Boolean,
});

const researchersTableHeaders = ref( ['Researcher Name', 'Project Assignment Date Range', 'Hourly Capacity/Week'])
const isEditProjectOpen = ref(true);

const emits = defineEmits(["reload_page", "edit_project"]);
watch(
  () => props.isExpandedAll,
  () => {
    if (props.isExpandedAll) {
      isExpanded.value = true;
    } else if (!props.isExpandedAll) {
      isExpanded.value = false;
    }
  }
);

const isExpanded = ref(props.isExpandedAll);

function editRow(project) {
  emits("edit_project", project);
}

function closeEditProjectModal() {
  isEditProjectOpen.value = false;
}

function archiveProject(project) {
  if (
    !confirm(
      "You are about to archive " +
        project.project_name +
        ". Are you sure you want to proceed?"
    )
  ) {
    return;
  }

  const archiveData = JSON.stringify({
    project_id: project.id,
    project_status: "inactive",
  });
  toggleArchiveStatus(archiveData);
}

function unarchiveProject(project) {
  if (
    !confirm(
      "You are about to unarchive " +
        project.project_name +
        ". Are you sure you want to proceed?"
    )
  ) {
    return;
  }
  const archiveData = JSON.stringify({
    project_id: project.id,
    project_status: "active",
  });
  toggleArchiveStatus(archiveData);
}

async function toggleArchiveStatus(data) {
  const output = await markResearchProjectStatus(data, store.state.auth.token);
  emits("reload_page");
}

function toggleRow() {
  isExpanded.value = !isExpanded.value;
}
</script>

<template>
  <div id="research_projects" class="w-full min-h-screen">
    <div
      class="pt-3 pb-0.5 border-b mx-5 mb-5 border-b-gray-400 flex flex-row justify-between"
    >
      <h1 class="">Research Projects:</h1>
      <div>
        <base-button-affirmative @click="resetFilter" class="text-xs">
          Reset
        </base-button-affirmative>
      </div>
    </div>
    <div v-if="showFilterPane" class="px-5 grid grid-cols-12">
      <base-filter class="col-span-2">
        <template v-slot:label>Active Status</template>
        <template v-slot:widget
          ><base-list-box
            class="w-full rounded-b-md dark:text-black"
            placeholder="Select Status"
            v-model="researchProjectFilters.active_status"
            :options="activeStatusOptions"
            @update:modelValue="setActiveStatusFilter"
        /></template>
      </base-filter>
      <base-filter class="col-span-2">
        <template v-slot:label>Done Status</template>
        <template v-slot:widget
          ><base-list-box
            class="w-full rounded-b-md dark:text-black"
            placeholder="Select Status"
            v-model="researchProjectFilters.done_status"
            :options="doneStatusOptions"
            @update:modelValue="setDoneStatusFilter"
        /></template>
      </base-filter>
      <base-filter class="col-span-2">
        <template v-slot:label>Research Project Name</template>
        <template v-slot:widget
          ><input
            v-model="researchProjectFilters.project_name"
            placeholder="Input Project Name"
            class="h-full px-2 w-full rounded-b-md dark:text-black"
            type="text"
        /></template>
      </base-filter>
      <base-filter class="col-span-3">
        <template v-slot:label>Start Date Range</template>
        <template v-slot:widget>
          <div class="flex h-full">
            <input
              v-model="researchProjectFilters.start_date_min"
              class="h-full px-2 w-1/2 rounded-b-md border dark:text-black"
              type="date"
            />
            <input
              v-model="researchProjectFilters.start_date_max"
              class="h-full px-2 w-1/2 rounded-b-md border dark:text-black"
              type="date"
            />
          </div>
        </template>
      </base-filter>
      <base-filter class="col-span-3">
        <template v-slot:label>End Date Range</template>
        <template v-slot:widget>
          <div class="flex h-full">
            <input
              v-model="researchProjectFilters.end_date_min"
              class="h-full px-2 w-1/2 rounded-b-md border dark:text-black"
              type="date"
            />
            <input
              v-model="researchProjectFilters.end_date_max"
              class="h-full px-2 w-1/2 rounded-b-md border dark:text-black"
              type="date"
            />
          </div>
        </template>
      </base-filter>
      <base-filter class="col-span-2">
        <template v-slot:label>Stage</template>
        <template v-slot:widget>
          <base-list-box
            class="w-full rounded-b-md dark:text-black"
            placeholder="Select Stage"
            v-model="researchProjectFilters.stage"
            :options="stageOptions"
            @update:modelValue="setStageFilter"
            :multiple="true"
          />
        </template>
      </base-filter>
      <base-filter class="col-span-2">
        <template v-slot:label>
          Priority Status </template>
        <template v-slot:widget>
          <base-list-box
            class="w-full rounded-b-md dark:text-black"
            placeholder="Select Status"
            v-model="researchProjectFilters.priority"
            :options="priorityOptions"
            @update:modelValue="setPriorityFilter"
            :multiple="true"

          />
        </template>
      </base-filter>
    </div>
    <div class="mx-5 my-3">
      <base-button @click="expandAll" class="text-xs mx-1">
        Expand All
      </base-button>

      <base-button @click="collapseAll" class="text-xs mx-1">
        Collapse All
      </base-button>
    </div>
    <div class="pl-5">
      <div class="flex flex-row items-center ml-3">
        <div class="flex items-center">
          <span
            class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="goToFirst"
            >&lt;&lt;</span
          >
          <span
            class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="getPrev"
            >&lt;</span
          >
          <div class="flex text-md flex-row">
            Page {{ page }} of {{ totalPages }}
          </div>
          <span
            class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="getNext"
            >></span
          >
          <span
            class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="goToLast"
            >>></span
          >
        </div>
        <div class="flex flex-row items-center">
          <div v-if="isLoading" role="status" class="pl-3">
            <svg
              aria-hidden="true"
              class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="white"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#2E9CCA"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else class="pl-3">
            <svg
              @click="loadResearchProjects(fetchFilter, true)"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#25274D"
              class="mx-1 w-6 h-6 hover:cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex flex-row items-center text-md align-middle lg:ml-3 lg:col-span-1 lg:justify-start"
        >
          {{ count }} Total Research Projects
        </div>
        <div class="mx-5 my-3 text-right">
          <base-button @click="createNewProject">
            Create New Research Project
          </base-button>
        </div>
        <div class="mx-5 my-3 text-right">
          <base-button @click="createNewProjectAssignment">
            Create New Project Assignment
          </base-button>
        </div>
      </div>
    </div>
    <div>
      <research-projects-table
        :tableHeaders="tableHeadersList"
        :content="researchProjectsList"
        :isExpandAll="isExpandAll"
        v-on:reload-page="loadResearchProjects"
        v-on:edit-project="editProject"
        class="mx-3"
      ></research-projects-table>
    </div>
    <div>
      <base-modal :is-form-visible="isAddProjectModalOpen" @close="closeAddProjectModal">
        <template #header>
          <h2 class="text-lg">Create New Research Project</h2>
        </template>
        <template #body>
          <new-research-project-form v-on:close="closeAddProjectModal" />
        </template>
      </base-modal>
    </div>
    <div>
        <base-modal :is-form-visible="isEditProjectModalOpen" @close="closeEditProjectModal">
            <template #header>
            <h2 class="text-lg">Edit Research Project</h2>
            </template>
            <template #body>
            <edit-research-project-form
                :project="selectedReseachProjectToEdit"
                v-on:close="closeEditProjectModal"
                v-on:close-and-reload="closeAndReloadProjectModal"
            />
            </template>
        </base-modal>
    </div>
    <div>
      <base-modal
        :is-form-visible="isNewProjectAssignmentModalOpen"
        @close="closeProjectAssignmentForm"
      >
        <template #header>
          <h2 class="text-lg">Create New Project Assignment</h2>
        </template>
        <template #body>
          <NewProjectAssignmentForm
            @close-form="closeProjectAssignmentForm"
            :researchers="researcherResults"
            :researchProjects="researchProjectResults"
          />
        </template>
      </base-modal>
      </div>
  </div>
</template>

<script setup>
import ResearchProjectsTable from "@/components/research-projects/ResearchProjectsTable.vue";
import NewResearchProjectForm from "./NewResearchProjectForm.vue";
import EditResearchProjectForm from "./EditResearchProjectForm.vue";
import { useStore } from "vuex";
import { getResearchProjects } from "@/services/ResearchProjects";
import {
  getProjectAssignments,
  getAllProjectAssignments,
  getAllResearchers,
  getAllResearchProjects,
  archiveProjectAssignment,
} from "@/services/ProjectAssignment.js";
import { getUsersByGroup } from "@/services/User";
import NewProjectAssignmentForm from "@/components/project-assignments/NewProjectAssignmentForm.vue";

import { ref, onMounted, computed, reactive, watch } from "vue";

const store = useStore();
const showFilterPane = computed(() => store.getters['research/showFilterPane'])

const next = ref(null);
const prev = ref(null);
const count = ref(null);
const page = ref(null);
const pageSize = ref(null);
const totalPages = ref(null);
const isLoading = ref(false);
const isAddProjectModalOpen = ref(false);
const isEditProjectModalOpen = ref(false);
const selectedReseachProjectToEdit = ref(null);
const isNewProjectAssignmentModalOpen = ref(false);

const researcherResults = ref([]);
const researchProjectResults = ref([]);

const researchProjectsList = ref([]);
const tableHeadersList = ref([
  "ID",
  "Due (End) Date",
  "Priority",
  "Research Project",
  "Stage",
  "Research Lead",
  "Client",
  "Description",
  "Start Date",
  "Percent Complete",
  "Estimate to Complete (Person-Days)",
  "Actions",
]);
const isExpandAll = ref(false);

const doneStatusOptions = ref([
  { value: "clear", label: "------------" },
  { value: "Done", label: "Done" },
  { value: "Not Done", label: "Not Done" },
]);

const activeStatusOptions = ref([
  { value: "clear", label: "------------" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
]);

const stageOptions = ref([
  { value: "clear", label: "------------" },
  { value: "Stage 1", label: "Stage 1" },
  { value: "Stage 2", label: "Stage 2" },
  { value: "Contacts", label: "Contacts" },
  { value: "Programs", label: "Programs" },
  { value: "Certification", label: "Certification" },
  { value: "Acquisition", label: "Acquisition" },
]);

const priorityOptions = ref([
  { value: "clear", label: "------------" },
  { value: 0, label: "No Priority" },
  { value: 1, label: "Priority 1" },
  { value: 2, label: "Priority 2" },
  { value: 3, label: "Priority 3" },
  { value: 4, label: "Priority 4" },
  { value: 5, label: "Priority 5" },
  { value: 6, label: "Priority 6" },
  { value: 7, label: "Priority 7" },
  { value: 8, label: "Priority 8" },
  { value: 9, label: "Priority 9" },
  { value: 10, label: "Priority 10" },
]);

const researchProjectFilters = reactive({
  done_status: null,
  active_status: null,
  project_name: null,
  start_date_min: null,
  start_date_max: null,
  end_date_min: null,
  end_date_max: null,
  stage: [],
  priority: [],
  stageExpressionFilter: "or",
  priorityExpressionFilter: "or"
});

function expandAll() {
  isExpandAll.value = true;
}

function collapseAll() {
  isExpandAll.value = false;
}

const awaitingSearch = ref(false);

watch(
  () => researchProjectFilters,
  () => {
    if (!awaitingSearch.value) {
      setTimeout(() => {
        loadResearchProjects(fetchFilter.value, false);
        awaitingSearch.value = false;
      }, 1000); // 1 sec delay
    }
    awaitingSearch.value = true;
  },
  { deep: true }
);

function setDoneStatusFilter(target) {
  target == "clear"
    ? (researchProjectFilters.done_status = "")
    : (researchProjectFilters.done_status = target);
}

function setActiveStatusFilter(target) {
  target == "clear"
    ? (researchProjectFilters.active_status = "")
    : (researchProjectFilters.active_status = target);
}

function setPriorityFilter(target) {
  target.includes("clear")
    ? (researchProjectFilters.priority = [])
    : (researchProjectFilters.priority = target);
}

function setStageFilter(target) {
  target.includes("clear")
    ? (researchProjectFilters.stage = [])
    : (researchProjectFilters.stage = target);
}

// onMounted(() => {
//   researchProjectsList.value = loadResearchProjects("");
// });

function resetFilter() {
  researchProjectFilters.done_status = "";
  researchProjectFilters.active_status = "";
  researchProjectFilters.project_name = "";
  researchProjectFilters.start_date_min = null;
  researchProjectFilters.start_date_max = null;
  researchProjectFilters.end_date_min = null;
  researchProjectFilters.end_date_max = null;
  researchProjectFilters.priority = [];
  researchProjectFilters.stage = [];
}

function closeAddProjectModal() {
  isAddProjectModalOpen.value = false;
}

function closeProjectAssignmentForm() {
  isNewProjectAssignmentModalOpen.value = false;
}

function editProject(project) {
  selectedReseachProjectToEdit.value = project;
  isEditProjectModalOpen.value = true;
}

function closeEditProjectModal() {
  selectedReseachProjectToEdit.value = null;
  isEditProjectModalOpen.value = false;
}

function closeAndReloadProjectModal() {
  closeEditProjectModal();
  loadResearchProjects()
}

const fetchFilter = computed(function () {
  // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
  let query = new URLSearchParams({});
  if (researchProjectFilters.done_status) {
    query.append("done_status", researchProjectFilters.done_status);
  }
  if (researchProjectFilters.active_status) {
    query.append("active_status", researchProjectFilters.active_status);
  }
  if (researchProjectFilters.project_name) {
    query.append("project_name", researchProjectFilters.project_name);
  }
  if (researchProjectFilters.start_date_min) {
    query.append(
      "start_date_lower_bound",
      researchProjectFilters.start_date_min
    );
  }
  if (researchProjectFilters.start_date_max) {
    query.append(
      "start_date_upper_bound",
      researchProjectFilters.start_date_max
    );
  }
  if (researchProjectFilters.end_date_min) {
    query.append("end_date_lower_bound", researchProjectFilters.end_date_min);
  }
  if (researchProjectFilters.end_date_max) {
    query.append("end_date_upper_bound", researchProjectFilters.end_date_max);
  }
  if (researchProjectFilters.priority.length > 0) {
    researchProjectFilters.priority.forEach((priority) => {
      query.append("priority", priority);
    });
  }
  // query.append("priority_lookup", researchProjectFilters.priorityExpressionFilter);
  if (researchProjectFilters.stage.length > 0) {
    researchProjectFilters.stage.forEach((stage) => {
      query.append("stage", stage);
    });
  }
  // query.append("stage_lookup", researchProjectFilters.stageExpressionFilter);
  // (B) CONVERT TO STRING, APPEND TO URL
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
  // (C) WHATEVER COMES NEXT...
});

async function loadResearchProjects(endpoint = fetchFilter.value, retainSpot) {
  console.log("gets here");
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  console.log("Endpoint: " + payload.endpoint);
  const output = await getResearchProjects(payload);

  next.value = output.next ? `&page=${output.page + 1}` : null;
  prev.value = output.prev ? `&page=${output.page - 1}` : null;
  count.value = output.count;
  page.value = output.page;
  pageSize.value = output.results.length;
  totalPages.value = output.numPages;

  console.log(output.results);
  researchProjectsList.value = output.results;
  isLoading.value = false;
  researchProjectsList.value = output.results;
}

async function loadAllResearchers() {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: "list_users",
    group: "advanced_research_group"
  };
  const result = await getUsersByGroup(payload);
  // console.log(result)
  // console.log("Before")
  researcherResults.value = result.users;
  // console.log(researcherResults.value);
  // console.log("After")
  isLoading.value = false;
}

async function loadAllResearchProjectsForNewProjectAssignment() {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
  };
  const result = await getAllResearchProjects(payload);
  // console.log("Before")
  researchProjectResults.value = result.researchProjects.data;

  
  console.log("Research Projects from create project assignment popup: ", researchProjectResults.value);
  // console.log("After")
  isLoading.value = false;
}

function createNewProject() {
  isAddProjectModalOpen.value = true;
}

function createNewProjectAssignment() {
  isNewProjectAssignmentModalOpen.value = true;
}

function getNext() {
  if (next.value) {
    loadResearchProjects(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadResearchProjects(fetchFilter.value + prev.value);
  } else {
    alert("No prev page");
  }
}

function goToFirst() {
  loadResearchProjects(fetchFilter.value + `&page=${1}`);
}

function goToLast() {
  loadResearchProjects(fetchFilter.value + `&page=${totalPages.value}`);
}

loadResearchProjects("")
loadAllResearchProjectsForNewProjectAssignment();
loadAllResearchers();
</script>


<template>
  <base-dblclick-input
    modelType="company"
    :modelId="company.id"
    fieldName="relevant_business"
    :fieldValue="company.relevant_business"
    inputType="text"
    @refetch="fetchNewCompanies"
  >
  </base-dblclick-input>
</template>

<script>
  export default {
      name: "relevant-business-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
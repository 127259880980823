<template>
    <div class="w-full h-full pt-2">
        <base-form class="h-[90%] w-full bg-gray-100 border rounded-md border-gray-300">
            <template #body>
                <div class="flex flex-wrap space-x-1 p-1">
                    <div v-for="(value, key) in postCategoriesCounts" :key="key">
                       <span @click="createResearchPost(value.name)" class="hover:underline hover:text-dark-secondary cursor-pointer">{{ key }}</span> ({{ value.count }}) |
                    </div>
                </div>
                <div v-if="researchPosts.length > 0" class="space-y-4 mx-2.5">
                    <ResearchPost
                        v-for="researchPost in researchPosts"
                        :key="researchPost.id"
                        :researchPost="researchPost"
                        :currentUser="currentUser"
                        :post-type="postType"
                        :company="company"
                        @refetch-research-posts="getResearchPostsByType"
                        @refetch-companies="fetchNewCompanies"
                     />
                </div>
            </template>
            <template #footer>
                <div class="rounded-b-md">
                    <div v-if="showPostForm===true" class="bg-white rounded-b-md -my-2">
                        <ResearchPostForm
                            :company="company"
                            :post-type="postType"
                            @refetch-research-posts="getResearchPostsByType"
                            @refetch-companies="fetchNewCompanies"
                            @close-form="hidePostForm"
                            :post-category="postCategory"
                        />
                    </div>
                    <div v-else class="flex flex-row justify-center py-2 bg-white rounded-b-md">
                        <base-button  @click="createResearchPost">Add Research Post</base-button>
                    </div>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script setup>
    import {ref, computed, defineProps, defineEmits } from "vue";
    import { useStore } from "vuex";
    import { getContactResearchPostsByCompany } from "@/services/ResearchPost"
    import { getCollectionResearchPostsByCompany } from "@/services/ResearchPost";
    import ResearchPostForm from "./ResearchPostForm.vue";
    import ResearchPost from "./ResearchPost.vue";


    const store = useStore()


    const props = defineProps({
        company: Object,
        postType: String,
    })

    const emit = defineEmits(['refetch-companies'])

    const showPostForm = ref('false')

    const researchPosts = ref([])

    const currentUser = computed(() => {
        return store.getters['auth/showUser']
    })

    function createResearchPost(category=null){
        postCategory.value = category
        showPostForm.value = true
    }

    function hidePostForm() {
        showPostForm.value = false
    }

    function fetchNewCompanies () {
        // console.log('CompanyResearchPosts fetch new companies')
        emit('refetch-companies')
    }


    async function getResearchPostsByType() {
        // console.log('getCollectionResearchPosts')
        const token = store.state.auth.token
        let result = null
        if(props.postType=='contact_research') {
            result = await getContactResearchPostsByCompany(props.company.id, token)
        } else if (props.postType=='collection_research') {
            result = await getCollectionResearchPostsByCompany(props.company.id, token)
        }
        // console.log('research posts')
        // console.log(result)
        researchPosts.value = result.data.results
    }

    const postCategory = ref(null)

    const postCategoriesCounts = computed(() => {
        if(props.postType == 'contact_research'){
            return         {
                WEB: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Website Research"
                            }).length,
                    name: "Website Research"
                },
                ZI: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Zoominfo Research"
                            }).length,
                    name: "Zoominfo Research"
                    },
                LI: {
                    count: researchPosts.value.filter((post) => {
                        return post.post_category === "LinkedIn Research"
                    }).length,
                    name: "LinkedIn Research"
                },
                BF: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Buzzfile Research"
                            }).length,
                    name: "Buzzfile Research"    
                    },
                CAGE: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "CAGE Research"
                        }).length,
                    name: "CAGE Research"
                },
                OC: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Open Corporates Research"
                        }).length,
                    name: "Open Corporates Research"
                },
                USAS: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "USA Spending Research"
                        }).length,
                    name: "USA Spending Research"
                },
                DACIS: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "DACIS Research"
                        }).length,
                    name: "DACIS Research"},
                HI: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Hunter.io Research"
                        }).length,
                    name: "Hunter.io Research"},
                RR: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Rocket Reach Research"
                        }).length,
                    name: "Rocket Reach Research"},
                SH: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Signal Hire Research"
                        }).length,
                    name: "Signal Hire Research"},
                WHOIS: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Whois Research"
                        }).length,
                    name: "Whois Research"},
                DNB: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "D&B Research"
                        }).length,
                    name: "D&B Research"},
                EV: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Email Verification"
                        }).length,
                    name: "Email Verification"},
                BIO: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Biographical Info"
                        }).length,
                    name: "Biographical Info"},
                OTHER: {
                    count: researchPosts.value.filter((post) => {
                            return post.post_category === "Other Research"
                        }).length,
                    name: "Other Research"},
            }
        } else {
            return null
        }
    })


    getResearchPostsByType()

</script>
<template>
    <base-dblclick-input
        model-type="company"
        :model-id="company.id"
        field-name="investors"
        :field-value="company.investors"
        input-type="text"
        @refetch="fetchNewCompanies"
    >
        <base-pill>Inv: </base-pill>
    </base-dblclick-input>
</template>

<script>
  export default {
      name: "investors-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
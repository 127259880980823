<template>
  <div class="flex flex-row w-full">
    <div class="pl-1.5 pt-0.5 flex flex-col w-2/4">   
      <div class="flex flex-row justify-start w-full mb-1">
        <CompanyContactBusinessEmailCell :company-contact="companyRelationship" @refetch-companies="fetchNewContacts">
          <template #icon>
            <span class="font-bold pr-1"><img id="email" src="@/assets/images/icons8-email-30.png" alt="email" class="w-[16px] inline"/></span>
          </template>
        </CompanyContactBusinessEmailCell>
      </div>
      <div class="flex flex-row justify-start w-ful mb-1">
        <CompanyContactBusinessPhoneCell :company-contact="companyRelationship" @refetch-companies="fetchNewContacts">
          <template #icon>
            <span class="font-bold pr-1"><img id="phone" src="@/assets/images/icons8-phone-30.png" alt="phone" class="w-[16px] inline"/></span>
          </template>
        </CompanyContactBusinessPhoneCell>
      </div>
    </div>
    <div class="pl-1.5 pt-0.5 flex flex-col w-2/4">
      <div class="flex flex-row justify-start w-full mb-1">
        <CompanyContactBusinessNotesCell :company-contact="companyRelationship" @refetch-companies="fetchNewContacts">
          <template #icon>
            <span class="pr-1"><img id="notes" src="@/assets/images/icons8-notes-50.png" alt="notes" class="w-[16px] inline"/></span>
          </template>
        </CompanyContactBusinessNotesCell>
      </div>
      <div class="flex flex-row justify-start items-center align-middle w-full mb-1">
        <span class="pr-1"><img id="notes" src="@/assets/images/icons8-star-50.png" alt="primary" class="w-[16px] inline"/></span>
        <CompanyContactIsPrimaryCell :company-contact="companyRelationship" @refetch-companies="fetchNewContacts" />
      </div>
    </div>
  </div>
  </template>
  
  <script>
    export default {
      name: 'contact-personal-info',
      data: () => ({
      }),
      methods: {
      }
    }
  
  </script>
  
  
<script setup>
import { defineProps, defineEmits } from 'vue';
import CompanyContactBusinessPhoneCell from '@/components/company-cell-components/CompanyContactBusinessPhoneCell.vue';
import CompanyContactBusinessEmailCell from '@/components/company-cell-components/CompanyContactBusinessEmailCell.vue';
import CompanyContactBusinessNotesCell from '@/components/company-cell-components/CompanyContactBusinessNotesCell.vue';
import CompanyContactIsPrimaryCell from '@/components/company-cell-components/CompanyContactIsPrimaryCell.vue';

const props = defineProps({
    companyRelationship: Object
})

const emit = defineEmits(['refetch-contacts'])

const fetchNewContacts = () => {
  emit('refetch-contacts')
}
</script>
<template>
  <base-dblclick-input
    class="inline"
    model-type="contactCompanyRelationship"
    :model-id="companyContact.id"
    field-name="title"
    :field-value="companyContact.title"
    input-type="text"
    @refetch="fetchNewCompanies"
  ></base-dblclick-input>
</template>

<script>
  export default {
      name: "company-contact-title-cell"
  }

</script>

<script setup>
import {defineProps, defineEmits} from "vue";

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

</script>
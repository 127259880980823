<template>
  <form @submit.prevent>
    <div class="flex items-center">
      <div class="group m-1 w-full">
        <label class="bg-light-primary text-xs py-1 px-2 w-full flex items-center justify-between rounded-tl-md" :class="{'bg-amber-300': active}" for="companyName">
          <slot name="label" class="rounded-tl-md"></slot>
          <button @click="close" class="text-xs w-4 h-4 bg-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-200">-</button>
        </label>
        <div class="bg-white rounded-bl-md rounded-br-none h-8 text-xs
                    border border-gray-300 text-gray-900
                    focus:ring-dark-primary focus:border-dark-primary">
          <slot name="widget"></slot>
        </div>
      </div>
      <div>
            <slot name="addOn"></slot>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "BaseFilterAddOn.vue",
  // methods: {
  //       close() {
  //           this.$emit("close");
  //       },
  //   },
}
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits(['close'])
const props = defineProps({
  active: Boolean
})

function close () {
  emit('close')
}
</script>
<template>
    <div class="grid grid-cols-2 gap-1">
        <button @click="markFeedback('approved')" class="text-xs p-1 border col-span-2 border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">Approved</button>
        <button @click="markFeedback('excluded')" class="text-xs p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">Excl</button>
        <button @click="markFeedback('hold')" class="text-xs p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">Hold</button>
        <button @click="markFeedback('pass')" class="text-xs p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">Pass</button>
        <button @click="markFeedback('research_futher')" class="text-xs p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">RF</button>
        <button @click="markFeedback('None')" class="text-xs p-1 border col-span-2 border-dark-primary rounded bg-dark-primary text-white hover:underline">None</button>
    </div>
</template>

<script>
    export default {
        name: "mark-client-feedback-buttons-cell"
    }
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { markClientFeedback } from '@/services/Company';
import { useStore } from 'vuex';

    const store = useStore()

    const props = defineProps({
        company:  Object
    })

    const emit = defineEmits(['refetch-companies'])

    async function markFeedback(feedback) {
        let token = store.state.auth.token
        await markClientFeedback(props.company.id, feedback, token)
        emit('refetch-companies')
    }
    

</script>
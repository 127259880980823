<template>
    <div class="w-full">
      <base-card v-show="showFilterPane && showFilter">
        <div v-if="splitPage" class="grid grid-cols-2 gap-2">
            <div class="flex flex-col">
                <DLACompanyFilter :splitPage="splitPage" @change-company-filters="setCompanyFilters" @set-changed-company-filters="setChangedCompanyFilters" @split-page="toggleSplitPage"></DLACompanyFilter> 
            </div>
            <div class="flex flex-col">
                <QPLSearch
                    :splitPage="splitPage"
                    :selectedCompanies="selectedCompanies"
                    :selectedResults="selectedResults"
                    :userPermissions="userPermissions"
                    :numSelectedCompanies="numSelectedCompanies"
                    :filters="fetchResultFilter"
                    :selectedGovernmentDesignations="selectedGovernmentDesignations"
                    :numSelectedGovernmentDesignations="numSelectedGovernmentDesignations"
                    :selectedGoverningSpecifications="selectedGoverningSpecifications"
                    :numSelectedGoverningSpecifications="numSelectedGoverningSpecifications"
                    @clear-selected-companies="clearSelectedResults"
                    @reset-select-all="resetSelectAllResults"
                    @search-by-qpl="searchByQPL"
                    @change-result-filters="setResultFilters"
                    @set-changed-result-filters="setChangedResultFilters"
                    @split-page="toggleSplitPage">
                </QPLSearch> 
            </div>
        </div>
        <div v-else>
            <DLACompanyFilter :splitPage="splitPage" @change-company-filters="setCompanyFilters" @set-changed-company-filters="setChangedCompanyFilters" @split-page="toggleSplitPage"></DLACompanyFilter>
        </div>
      </base-card>
      <base-card>
        <div v-if="splitPage" class="grid grid-cols-2 gap-2">
            <div class="flex flex-col">
                <div class="flex flex-col" :class="[{'h-[70vh]': showFilter}, {'h-[85vh]': !showFilter}]">
                    <base-card class="flex flex-row justify-between w-full mb-0">
                        <div class="flex flex-row items-center text-sm">
                            <div class="ml-1 mr-1 flex-row justify-start items-center">
                                <div class="flex flex-row items-center align-middle text-center">
                                    <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllOnPage" @change="toggleSelectAllOnPage"/>
                                    <p>
                                        <span v-if="!selectAllOnPage" class="pl-1 align-middle">Select | </span>
                                        <span v-else class="pl-1 align-middle">Deselect | </span>
                                        <span class="hover:text-accent-primary hover:cursor-pointer" @click="resetSelectAllClearSelectedCompanies">Clear All</span> 
                                    </p>
                                </div>
                            </div>
                            <div class="mx-1">
                                {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ companyCount }}
                            </div>
                            <div class="mx-1">
                                <base-filter-horizontal class="p-0 m-0">
                                <template v-slot:label>
                                    Page Size
                                </template>
                                <template v-slot:widget>
                                    <base-list-box-horizontal
                                    class="w-full rounded-r-md text-xs"
                                    placeholder="Select"
                                    :options="pageSizeOptions"
                                    :modelValue="companyFilters.companyPageSizeFilter"
                                    :multiple="false"
                                    @update:modelValue="setCompanyPageSizeFilter"
                                    />
                                </template>
                            </base-filter-horizontal>
                            </div>
                            <div class="">
                                <div v-if="companiesLoading" role="status" class="pl-3">
                                <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="white"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="#2E9CCA"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else class="pl-3">
                                <svg @click="loadDLACompanies(fetchCompanyFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="#25274D" class=" mx-1 w-6 h-6 hover:cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                </svg>
                            </div>
                            </div>
                            <div class="flex items-center ml-1">
                                <div class="flex items-center">
                                <base-menu-button @click="goToFirst">&lt;&lt;</base-menu-button>
                                <base-menu-button @click="getPrev">&lt;</base-menu-button>
                                <div class="flex flex-row">
                                    <span><JumpToPage class="mx-1" :currentPage="companyPage" :maxPage="companyTotalPages" @fetch-page="jumpToPage"/></span>of {{ companyTotalPages }}
                                </div>    
                                <base-menu-button @click="getNext">></base-menu-button>
                                <base-menu-button @click="goToLast">>></base-menu-button>
                                </div>
                            </div>
                            <div class="text-sm flex ml-1">
                                <!-- <base-menu-button @click="toggleFilter">{{ showFilterText }}</base-menu-button> -->
                            </div>
                        </div>
                    </base-card>
                    <div v-if="companyResults.length > 0" class="w-full h-full overflow-y-auto">
                        <CompanyCard @add-remove-selected-companies="addRemoveSelectedCompanies"
                            @add-selected-company="addSelectedCompany"
                            @remove-selected-company="removeSelectedCompany"
                            @refetch-companies="loadDLACompanies(fetchCompanyFilter, true)"
                            @reset-changed-company-filters="resetSelectedCompaniesOnChange"
                            @reset-cleared-selected-companies="resetClearedSelectedCompanies"
                            @reset-cleared-selected-companies-on-page="resetClearedSelectedCompaniesOnPage"
                            :selectAll="selectAll"
                            :selectAllOnPage="selectAllOnPage"
                            :selectedCompaniesOnPageMap="selectedCompaniesOnPageMap"
                            :key="company.id"
                            :company="company"
                            :clearedSelectedCompanies="clearedSelectedCompanies"
                            :clearedSelectedCompaniesOnPage="clearedSelectedCompaniesOnPage"
                            :changedCompanyFilters="changedCompanyFilters"
                            :permissions="userPermissions"
                            v-for="company in companyResults"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="flex flex-col" :class="[{'h-[70vh]': showFilter}, {'h-[85vh]': !showFilter}]">
                    <base-card class="flex flex-row justify-between w-full">
                    <div class="flex flex-row items-center text-sm">
                    <div class="ml-1 mr-1 flex-row justify-start items-center">
                        <div class="flex flex-row items-center align-middle text-center">
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllResultsOnPage" @change="toggleSelectAllResultsOnPage"/>
                            <p>
                                <span v-if="!selectAllResultsOnPage" class="pl-1 align-middle">Select | </span>
                                <span v-else class="pl-1 align-middle">Deselect | </span>
                                <span class="hover:text-accent-primary hover:cursor-pointer" @click="resetSelectAllClearSelectedResults">Clear All</span> 
                            </p>
                        </div>
                    </div>
              <div class="mx-1">
                {{ qplSearchViewingFromNbr }}-{{ qplSearchViewingToNbr }} | {{ qplSearchCount }}
              </div>
              <div class="mx-1">
                <base-filter-horizontal class="p-0 m-0">
                  <template v-slot:label>
                    Page Size
                  </template>
                  <template v-slot:widget>
                    <base-list-box-horizontal
                      class="w-full rounded-r-md text-xs"
                      placeholder="Select"
                      :options="pageSizeOptions"
                      :modelValue="resultFilters.resultPageSizeFilter"
                      :multiple="false"
                      @update:modelValue="setResultPageSizeFilter"
                    />
                  </template>
              </base-filter-horizontal>
            </div>
              <div class="">
                <div v-if="qplSearchLoading" role="status" class="pl-3">
                  <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
                <div v-else class="pl-3">
                  <svg @click="searchByQPL(fetchResultFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="#25274D" class=" mx-1 w-6 h-6 hover:cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                  </svg>
              </div>
              </div>
              <div class="flex items-center ml-1">
                <div class="flex items-center">
                  <base-menu-button @click="qplSearchGoToFirst">&lt;&lt;</base-menu-button>
                  <base-menu-button @click="qplSearchGetPrev">&lt;</base-menu-button>
                  <div class="flex flex-row">
                    <span><JumpToPage class="mx-1" :currentPage="qplSearchPage" :maxPage="qplSearchTotalPages" @fetch-page="qplSearchJumpToPage"/></span>of {{ qplSearchTotalPages }}
                  </div>    
                  <base-menu-button @click="qplSearchGetNext">></base-menu-button>
                  <base-menu-button @click="qplSearchGoToLast">>></base-menu-button>
                </div>
              </div>
              <div class="text-sm flex ml-1">
                <!-- <base-menu-button @click="toggleFilter">{{ showFilterText }}</base-menu-button> -->
                <!-- <base-menu-button @click="toggleRowExpand">{{ showRowExpandText }}</base-menu-button> -->
              </div>
            </div>
                    </base-card>
                    <div v-if="qplSearchResults.length > 0" class="h-full overflow-y-auto">
                        <base-table-condensed>
                        <template #table-header>
                            <base-table-header-condensed :key="header.id" :header="header" v-for="header in tableHeadersCondensedList"> {{
                                header
                            }}
                            </base-table-header-condensed>
                        </template>
                
                        <template #table-body>
                            <DLACompanyRowCondensed
                            @add-selected-company="addSelectedResult"
                            @remove-selected-company="removeSelectedResult"
                            @refetch-companies="searchByQPL(fetchResultFilter, true)"
                            @reset-changed-company-filters="resetSelectedResultsOnChange"
                            @reset-cleared-selected-companies="resetClearedSelectedResults"
                            @reset-cleared-selected-companies-on-page="resetClearedSelectedResultsOnPage"
                            :selectAll="selectAll"
                            :selectAllOnPage="selectAllResultsOnPage"
                            :selectedCompaniesOnPageMap="selectedResultsOnPageMap"
                            :key="company.id"
                            :company="company"
                            :clearedSelectedCompanies="clearedSelectedResults"
                            :clearedSelectedCompaniesOnPage="clearedSelectedResultsOnPage"
                            :changedCompanyFilters="changedResultFilters"
                            :permissions="userPermissions"
                            v-for="company in qplSearchResults"
                                        />
                        </template>
                        </base-table-condensed>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col" :class="[ {'h-[70vh]': showFilter}, {'h-[85vh]': !showFilter}]">
          <div class="flex flex-row justify-between mb-4">
            <div class="flex flex-row items-center text-sm">
              <div class="ml-2 mr-3 flex-row justify-start items-center">
                <div class="flex flex-row items-center align-middle text-center">
                  <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllOnPage" @change="toggleSelectAllOnPage"/>
                  <p>
                    <span v-if="!selectAllOnPage" class="pl-1 align-middle">Select All On Page | </span>
                    <span v-else class="pl-1 align-middle">Deselect All On Page | </span>
                    <span
                      class="pl-1 align-middle text-accent-primary hover:text-dark-primary"
                      @click="showModal"
                    >{{numSelectedCompanies}} Selected Companies
                    </span> |
                    <span class="text-accent-primary hover:text-dark-primary hover:cursor-pointer" @click="resetSelectAllClearSelectedCompanies">Clear All</span> 
                    <base-modal-small 
                      v-if="isModalVisible"
                      @close="closeModal"
                    >
                    <template v-slot:header>
                      Selected Companies
                    </template>
                    <template v-slot:body>
                      <SelectedCompaniesTable :selectedCompanies="selectedCompanies" />
                    </template>
                    </base-modal-small>
                  </p>
                </div>
                <div class="text-sm text-right">

                </div>
              </div>
              <div class="mx-3">
                {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ companyCount }}  Total Companies
              </div>
              <div class="mx-3">
                <base-filter-horizontal>
                  <template v-slot:label>
                    Page Size
                  </template>
                  <template v-slot:widget>
                    <base-list-box-horizontal
                      class="w-full rounded-r-md text-xs"
                      placeholder="Select"
                      :options="pageSizeOptions"
                      :modelValue="companyFilters.companyPageSizeFilter"
                      :multiple="false"
                      @update:modelValue="setCompanyPageSizeFilter"
                    />
                  </template>
              </base-filter-horizontal>
            </div>
              <div class="mx-1">
                <div v-if="companiesLoading" role="status" class="pl-3">
                  <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
                <div v-else class="pl-3">
                  <svg @click="loadDLACompanies(fetchCompanyFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="#25274D" class=" mx-1 w-8 h-8 hover:cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                  </svg>
              </div>
              </div>
              <div class="flex items-center ml-5">
                <div class="flex items-center">
                  <base-menu-button @click="goToFirst">&lt;&lt;</base-menu-button>
                  <base-menu-button @click="getPrev">&lt;</base-menu-button>
                  <div class="flex flex-row">
                    Page <span><JumpToPage class="mx-1" :currentPage="companyPage" :maxPage="companyTotalPages" @fetch-page="jumpToPage"/></span>of {{ companyTotalPages }}
                  </div>
                  <base-menu-button @click="getNext">></base-menu-button>
                  <base-menu-button @click="goToLast">>></base-menu-button>
                </div>
              </div>
              <div class="text-sm flex ml-6">
                <base-menu-button @click="toggleFilter">{{ showFilterText }}</base-menu-button>
                <!-- <base-menu-button @click="toggleRowExpand">{{ showRowExpandText }}</base-menu-button> -->
              </div>
            </div>
          </div>
          <div v-if="companyResults.length > 0" class="h-full overflow-y-auto">
            <base-table>
              <!--      <base-table @refetch-companies="fetchNewCompanies">-->
              <template #table-header>
                <base-table-header :key="header.id" :header="header" v-for="header in tableHeadersList"> {{
                    header
                  }}
                </base-table-header>
              </template>
    
              <template #table-body>
                <DLACompanyRow @add-remove-selected-companies="addRemoveSelectedCompanies"
                  @add-selected-company="addSelectedCompany"
                  @remove-selected-company="removeSelectedCompany"
                  @refetch-companies="loadDLACompanies(fetchCompanyFilter, true)"
                  @reset-changed-company-filters="resetSelectedCompaniesOnChange"
                  @reset-cleared-selected-companies="resetClearedSelectedCompanies"
                  @reset-cleared-selected-companies-on-page="resetClearedSelectedCompaniesOnPage"
                  :selectAll="selectAll"
                  :selectAllOnPage="selectAllOnPage"
                  :selectedCompaniesOnPageMap="selectedCompaniesOnPageMap"
                  :campaigns="campaigns"
                  :key="company.id"
                  :company="company"
                  :clearedSelectedCompanies="clearedSelectedCompanies"
                  :clearedSelectedCompaniesOnPage="clearedSelectedCompaniesOnPage"
                  :changedCompanyFilters="changedCompanyFilters"
                  :permissions="userPermissions"
                  v-for="company in companyResults"
                            />
              </template>
            </base-table>
          </div>
          <base-modal :is-form-visible="showCreateCompanyForm" @close="closeCreateCompanyForm">
            <template #header>
              <h2 class="text-lg">Create Company</h2>
            </template>
            <template #body>
              <CreateCompany
                :campaigns="campaigns"
                @close="closeCreateCompanyForm"
                @refetch-companies="loadDLACompanies"
              />
            </template>
          </base-modal>
        </div>
      </base-card>
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'DLACompanies',
    data: () => ({
    }),
    methods: {
    }
  }
  </script>
  
  <script setup>
  import {ref, reactive, shallowReactive, computed, watch} from 'vue';
  import {useStore} from 'vuex';
  import DLACompanyFilter from './DLACompanyFilter.vue';
  import QPLSearch from './QPLSearch.vue';
  import DLACompanyRow from './DLACompanyRow.vue';
  import DLACompanyRowCondensed from './DLACompanyRowCondensed.vue';
  import CompanyCard from './CompanyCard.vue';
  import BaseCard from "@/components/BaseCard";
  import SelectedCompaniesTable from './SelectedCompaniesTable.vue';
  import {getDLACompanies} from '../services/Company.js';
  import JumpToPage from './JumpToPage.vue';
  import { getCampaigns } from '@/services/Campaign';
  import CreateCompany from './CreateCompany.vue';
  
  const campaigns = ref([])
  const companiesLoading = ref(false);
  const qplSearchLoading = ref(false);
  const tableHeadersCondensedList = [
    ' ',
    'ID',
    'Name / Cage Code',
    'Campaign / Tier',
    'Location / URL',
    'Rev / HC',
    'Governing Specifications',
    'Government Designations'
  ]
  const tableHeadersList = [
    ' ',
    'ID',
    'Name',
    'Campaign',
    'Cage Code',
    'Location',
    'URL',
    'Rev / Headcount',
    'Governing Specifications',
    'Government Designations'
  ];
  const companyResults = ref([]);
    const companyNext = ref(null);
    const companyPrev = ref(null);
  const companyCount = ref(null);
  const companyPage = ref(null);
  const companyPageSize = ref(null);
    const companyTotalPages = ref(0);
  const qplSearchResults = ref([]);
  const qplSearchNext = ref(null);
  const qplSearchPrev= ref(null);
  const qplSearchCount = ref(null);
  const qplSearchPage = ref(null);
  const qplSearchPageSize = ref(null);
  const qplSearchTotalPages = ref(0);

  const companyFilters = reactive({
    companyNameFilter: null,
    companyAddressFilter: null,
    companySourcesFilter: null,
    companyMinRevenueFilter: null,
    companyMaxRevenueFilter: null,
    companyMinRevenueZiCombinedFilter: null,
    companyMaxRevenueZiCombinedFilter: null,
    companyGoverningSpecificationFilter: null,
    companyGovernmentDesignationFilter: null,
    companyPageSizeFilter: 50,
    companyCollectionTagsFilter: null,
    companyStateFilter: null,
    companyStateExpressionFilter: 'or',
  });
  const resultFilters = reactive({
    resultPageSizeFilter: 50,
    resultCollectionTagsFilter: null,
    resultGovernmentDesignationFilter: null,
    resultGoverningSpecificationFilter: null,
  });

  const showCreateCompanyForm = computed(() => store.getters['company/showCreateCompanyForm'])
  const showFilterPane = computed(() => store.getters["company/showFilterPane"]);

  function closeCreateCompanyForm() {
    store.dispatch('company/setShowCreateCompanyFormFalse')
  }

  const showFilter = ref(true);
  const showFilterText = ref('Hide Filter');
  const showRowExpand = ref(true);
  const showRowExpandText = ref('Expand Rows');
  const isModalVisible = ref(false);
  const splitPage = ref(false);

  const changedCompanyFilters = ref(false);
  const changedResultFilters = ref(false);

  const selectedCompanies = ref([]);
  const selectAll = ref(false);
  const selectAllOnPage = ref(false);
  const clearedSelectedCompanies = ref(false);
  const clearedSelectedCompaniesOnPage = ref(false);

  const selectedResults = ref([]);
  const selectAllResults = ref(false);
  const selectAllResultsOnPage = ref(false);
  const clearedSelectedResults = ref(false);
  const clearedSelectedResultsOnPage = ref(false);

  const awaitingSearch = ref(false);
  const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
  });

  
  
  const pageSizeOptions = ref([
      {value: "clear", label: "----"},
      {value: 10, label: "10"},
      {value: 25, label: "25"},
      {value: 50, label: "50"},
      {value: 100, label: "100"},
      {value: 200, label: "200"},
    ])
  
  watch(() => companyFilters, () => {
        if (!awaitingSearch.value) {
          setTimeout(() => {
            loadDLACompanies();
            awaitingSearch.value = false;
          }, 2500); // 2.5 sec delay
        }
        awaitingSearch.value = true;
      },
      {deep: true}
  );
  
  const store = useStore()
  
  async function loadDLACompanies(endpoint = fetchCompanyFilter.value, retainSpot) {
    companiesLoading.value = true;
    let payload = {
      token: store.state.auth.token,
      endpoint: retainSpot ? endpoint + `&page=${companyPage.value}` : endpoint 
    }
    const result = await getDLACompanies(payload);
    companyResults.value = result.companies;
    companyNext.value = result.next ? `&page=${result.page + 1}` : null
    companyPrev.value = result.prev ? `&page=${result.page - 1}` : null
    companyCount.value = result.count
    companyPage.value = result.page
    companyPageSize.value = result.companies.length
    companyTotalPages.value = result.num_pages
    // console.log("this.companyResults", this.companyResults);
    companiesLoading.value = false;
    resetSelectedCompaniesOnChange()
  }
  
  function showModal() {
      isModalVisible.value = true;
  }
  
  function closeModal() {
    isModalVisible.value = false;
  }
  
  function resetSelectAllClearSelectedCompanies() {
    resetSelectAll()
    clearSelectedCompanies()
    // resetClearedSelectedCompanies()
  }

  
  function setChangedCompanyFilters(){
    changedCompanyFilters.value = true;
  }
  
  function resetSelectedCompaniesOnChange(){
    // console.log('companies resetChangedCompanyFilters')
    if(changedCompanyFilters.value){
      changedCompanyFilters.value = false;
      resetSelectAllClearSelectedCompanies()
    }
  
  }
  
  function clearSelectedCompanies() {
    // console.log('companies - clearSelectedCompanies ran')
    selectedCompanies.value = [];
    clearedSelectedCompanies.value = true;
  }
  
  
  function resetClearedSelectedCompanies() {
    // console.log('resetClearedSelectedCompanies')
    clearedSelectedCompanies.value = false;
  }
  
  function clearSelectedCompaniesOnPage() {
    // logic here to keep selected companies off page and only clear companies on page
    selectedCompanies.value = selectedCompanies.value.filter(company => selectedCompaniesOnPage.value.indexOf(company.id) === -1);
    console.log('clear selected companies on page')
    clearedSelectedCompaniesOnPage.value = true;
  }
  
  function resetClearedSelectedCompaniesOnPage() {
    clearedSelectedCompaniesOnPage.value = true;
  }
  
  function toggleSelectAllOnPage() {
    clearSelectedCompaniesOnPage()
    resetClearedSelectedCompaniesOnPage()
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }else{
      selectAllOnPage.value = true;
    }
  }
  
  function resetSelectAll() {
    if(selectAll.value){
      selectAll.value = false;
    }
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }
  }
  
  function getNext() {
    if (companyNext.value) {
      loadDLACompanies(fetchCompanyFilter.value + companyNext.value)
      resetSelectAllClearSelectedCompanies()
    } else {
      alert("No next page")
    }
  }
  
  function getPrev() {
    if (companyPrev.value) {
      loadDLACompanies(fetchCompanyFilter.value + companyPrev.value)
      resetSelectAllClearSelectedCompanies()
    } else {
      alert("No prev page")
    }
  }
  
  const selectedCompanyIds = computed(function() {
    if(selectedCompanies.value.length > 0){
      return selectedCompanies.value.map(({id}) => {
        return id
      })
  
    } else {
      return []
    }
  })
  
  const companyResultsIds = computed(function() {
    if(companyResults.value.length > 0){
      return companyResults.value.map(({id}) => {
        return id
      })
    } else {
      return []
    }
  })
  
  const numSelectedCompanies = computed(function() {
    return selectedCompanies.value.length
  })
  
  const selectedCompaniesOnPage = computed(function() {
    if(numSelectedCompanies.value > 0){
      return selectedCompanyIds.value.filter((companyId) => {
        return companyResultsIds.value.includes(companyId)
    })
    } else {
      return []
    }
  })
  
  const selectedCompaniesOnPageMap = computed(function() {
    return new Map(selectedCompaniesOnPage.value.map((companyId) => [companyId, companyId]))
  })
  
  const numSelectedCompaniesOnPage = computed(function() {
    return selectedCompaniesOnPage.value.length
    
  })
  
  const selectedCompaniesOffPage = computed(function(){
    return selectedCompanyIds.value.filter((companyId) => {
      return !companyResultsIds.value.includes(companyId)
    })
  })
  
  const numSelectedCompaniesOffPage = computed(function() {
    return selectedCompaniesOffPage.value.length
  })

  const selectedDLACompanies = computed(function() {
    let dlaCompanyList = []
    let dlaCompanyIdList = []
    selectedCompanies.value.forEach(selectedCompany => {
        let dlaCompanies = selectedCompany.dla_company
        dlaCompanies.forEach(dlaCompany => {
            if(!dlaCompanyIdList.includes(dlaCompany.id))
                dlaCompanyList.push(dlaCompany)
                dlaCompanyIdList.push(dlaCompany.id)
        })
    })
    return dlaCompanyList;
  })

  const numSelectedDLACompanies = computed(function() {
    return selectedDLACompanies.value.length
  })

  const selectedCompanyProductRows = computed(function() {
    let companyProductRowList = []
    let companyProductRowIds = []
    selectedDLACompanies.value.forEach(dlaCompany => {
        let companyProductRows = dlaCompany.raw_company_product_rows
        companyProductRows.forEach(companyProductRow => {
            if(!companyProductRowIds.includes(companyProductRow.id)) {
                companyProductRowList.push(companyProductRow)
                companyProductRowIds.push(companyProductRow.id)
            }
        })
    })
    return companyProductRowList;
  })

  const numSelectedCompanyProductRows = computed(function() {
    return selectedCompanyProductRows.value.length
  })

  const selectedGovernmentDesignations = computed(function() {
    let governmentDesignationList = []
    let governmentDesignationIds = []
    selectedCompanyProductRows.value.forEach(companyProductRow => {
        let governmentDesignation = companyProductRow.government_designation
        if(!governmentDesignationIds.includes(governmentDesignation.id)){
            governmentDesignationList.push(governmentDesignation)
            governmentDesignationIds.push(governmentDesignation.id)
        }
    })
    console.log('governmentdesignationids', governmentDesignationIds)
    return governmentDesignationList;
  })

  const numSelectedGovernmentDesignations = computed(function() {
    return selectedGovernmentDesignations.value.length
  })

  const selectedGoverningSpecifications = computed(function() {
    let governingSpecificationList = []
    let governingSpecificationIds = []
    selectedGovernmentDesignations.value.forEach(governmentDesignation => {
        let governingSpecification = governmentDesignation.governing_specification
        if(!governingSpecificationIds.includes(governingSpecification.id)){
            governingSpecificationList.push(governingSpecification)
            governingSpecificationIds.push(governingSpecification.id)
        }
    })
    console.log(governingSpecificationIds)
    return governingSpecificationList;
  })

  const numSelectedGoverningSpecifications = computed(function() {
    return selectedGoverningSpecifications.value.length
  })
  
  const viewingFromNbr = computed(function () {
    return (companyPage.value * companyPageSize.value + 1) - companyPageSize.value
  })
  
  const viewingToNbr = computed(function () {
    if (companyPage.value === companyTotalPages.value) {
      return companyCount.value;
    } else {
      return companyPage.value * companyPageSize.value
    }
  })
  
  const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    // console.log('user permissions store', userPermissionsStore)
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies =  userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  
    // console.log('user permissions', userPermissions)
  }
  
  const RenderBulkOperations = computed(function () {
    if(userPermissions.isAccountManager){
      return true
    }
    if(userPermissions.isAccountStaff){
      return true
    }
    if(userPermissions.isDev){
      return true
    }
    if(userPermissions.isManagement){
      return true
    }
    if(userPermissions.isResearchLead){
      return true
    }
    if(userPermissions.isSuperuser){
      return true
    }
    return false
  })
  
  function toggleFilter() {
    showFilter.value = !showFilter.value;
    if (showFilterText.value === 'Hide Filter') {
      showFilterText.value = 'Show Filter'
    } else {
      showFilterText.value = 'Hide Filter'
    }
  }
  
  function toggleRowExpand() {
    showRowExpand.value = !showRowExpand.value;
    if (showRowExpandText.value === 'Expand Rows') {
      showRowExpandText.value = 'Collapse Rows'
    } else {
      showRowExpandText.value = 'Expand Rows'
    }
  }
  
  function addSelectedCompany(company) {
    let foundIndex = selectedCompanyIds.value.indexOf(company.id);
    if (foundIndex === -1) {
      selectedCompanies.value.push(company)
    }
  }
  
  function removeSelectedCompany(company) {
    let foundIndex = selectedCompanyIds.value.indexOf(company.id);
    if (foundIndex !== -1) {
      selectedCompanies.value.splice(foundIndex, 1)
    }
  }
  
  function addRemoveSelectedCompanies(company) {
    let foundIndex = selectedCompanies.value.indexOf(company);
    if (foundIndex === -1) {
      selectedCompanies.value.push(company)
    } else {
        selectedCompanies.value.splice(foundIndex, 1)
      }
    }
  
  function setCompanyFilters(updatedFilters) {
    // console.log("setCompanyFilters", updatedFilters);
    companyFilters.companyNameFilter = updatedFilters.value.companyNameFilter;
    companyFilters.companyDescriptionFilter = updatedFilters.value.companyDescriptionFilter;
    companyFilters.companyAddressFilter = updatedFilters.value.companyAddressFilter;
    companyFilters.companySourcesFilter = updatedFilters.value.companySourcesFilter;
    companyFilters.companyMinRevenueFilter = updatedFilters.value.companyMinRevenueFilter;
    companyFilters.companyMaxRevenueFilter = updatedFilters.value.companyMaxRevenueFilter;
    companyFilters.companyCampaignFilter = updatedFilters.value.companyCampaignFilter;
    companyFilters.companyTierFilter = updatedFilters.value.companyTierFilter;
    // companyFilters.companyCollectionTagsFilter = updatedFilters.value.companyCollectionTagsFilter;
    companyFilters.companyStateFilter = updatedFilters.value.companyStateFilter;
    companyFilters.companyGoverningSpecificationFilter = updatedFilters.value.companyGoverningSpecificationFilter
    companyFilters.companyGovernmentDesignationFilter = updatedFilters.value.companyGovernmentDesignationFilter
  }

  function setCompanyPageSizeFilter(target) {
      target == "clear" ? companyFilters.companyPageSizeFilter = null : companyFilters.companyPageSizeFilter = target
      resetSelectAllClearSelectedCompanies()
  }
  
  function toggleSplitPage() {
    if(splitPage.value) {
        splitPage.value = false
    } else {
        splitPage.value = true
    }
  }
  
  const fetchCompanyFilter = computed(function () {
    // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
    let query = new URLSearchParams({
      // name: "John Doe",
      // email: "john@doe.com",
      // colors: JSON.stringify(["Red", "Green", "Blue"])
    });
    query.append("has_campaign", companyFilters.companyHasCampaignFilter)
    if (companyFilters.companyNameFilter) {
      query.append("company_name", companyFilters.companyNameFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueFilter) {
      query.append("min_revenue", companyFilters.companyMinRevenueFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueFilter) {
      query.append("max_revenue", companyFilters.companyMaxRevenueFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueZiCombinedFilter) {
      query.append("min_revenue_combined", companyFilters.companyMinRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueZiCombinedFilter) {
      query.append("max_revenue_combined", companyFilters.companyMaxRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyDescriptionFilter) {
      query.append("description", companyFilters.companyDescriptionFilter); // To append more data
    }
    if (companyFilters.companyAddressFilter) {
      query.append("address_search", companyFilters.companyAddressFilter); // To append more data
    }
    if (companyFilters.companyOnboardingStatusFilter) {
      query.append("onboarding_status", companyFilters.companyOnboardingStatusFilter); // To append more data
    }
    if (companyFilters.companyDNRReasonFilter) {
      query.append("do_not_research_reason", companyFilters.companyDNRReasonFilter); // To append more data
    }
    if (companyFilters.companyClientFilter) {
      companyFilters.companyClientFilter.forEach(client => {
          query.append("client_drf", client)
        })
    }
    if (companyFilters.companyPageSizeFilter) {
      query.append("page_size", companyFilters.companyPageSizeFilter); // To append more data
    }
    if (companyFilters.companySourcesFilter) {
        companyFilters.companySourcesFilter.forEach(source => {
          query.append("source_object", source)
        })
  
    }
    if (companyFilters.companySourceOwnerFilter) {
        companyFilters.companySourceOwnerFilter.forEach(owner => {
          query.append("source_owners", owner)
        })
    }
    if (companyFilters.companyCampaignFilter) {
        companyFilters.companyCampaignFilter.forEach(campaign => {
          query.append("campaign", campaign)
        })
    }
    if (companyFilters.companyTierFilter) {
        companyFilters.companyTierFilter.forEach(tier => {
          query.append("tier_selection", tier)
        })
    }
    if (companyFilters.companyClientFeedbackFilter) {
        companyFilters.companyClientFeedbackFilter.forEach(feedback => {
          query.append("client_feedback", feedback)
        })
    }
    if (companyFilters.companyResearchStatusFilter) {
        companyFilters.companyResearchStatusFilter.forEach(status => {
          query.append("research_statuses", status)
        })
        query.append("research_statuses_lookup", companyFilters.companyResearchStatusExpressionFilter)
    }
    if (companyFilters.companyResearchStatusFilterTwo) {
        companyFilters.companyResearchStatusFilterTwo.forEach(status => {
          query.append("research_statuses_2", status)
        })
        query.append("research_statuses_2_lookup", companyFilters.companyResearchStatusExpressionFilterTwo)
    }
    if (companyFilters.companyBusinessTypeFilter) {
        companyFilters.companyBusinessTypeFilter.forEach(businessType => {
          query.append("business_type", businessType)
        })
    }
    if (companyFilters.companySectorFilter) {
        companyFilters.companySectorFilter.forEach(sector => {
          query.append("sector", sector)
        })
    }
    if (companyFilters.companySubSectorFilter) {
        companyFilters.companySubSectorFilter.forEach(subSector => {
          query.append("sub_sector", subSector)
        })
    }
    if (companyFilters.companyCRMStatusFilter) {
        companyFilters.companyCRMStatusFilter.forEach(status => {
          query.append("crm_status", status)
        })
    }
    if (companyFilters.companyCRMNextActionFilter) {
        companyFilters.companyCRMNextActionFilter.forEach(action => {
          query.append("next_action", action)
        })
    }
    if (companyFilters.companyDueTimeRangeFilter) {
        companyFilters.companyDueTimeRangeFilter.forEach(time => {
          query.append("due_date", time)
        })
    }
    if (companyFilters.companyCollectionTagsFilter) {
        companyFilters.companyCollectionTagsFilter.forEach(collectionTag => {
          query.append("collection_tag", collectionTag)
        })
    }
    if (companyFilters.companyStateFilter) {
        companyFilters.companyStateFilter.forEach(state => {
          query.append("state", state)
        })
        query.append("state_lookup", companyFilters.companyStateExpressionFilter)
    }

    if (companyFilters.companyGoverningSpecificationFilter) {
      companyFilters.companyGoverningSpecificationFilter.forEach(governingSpecification => {
        query.append("governing_specification", governingSpecification)
      })
    }

    if (companyFilters.companyGovernmentDesignationFilter) {
      companyFilters.companyGovernmentDesignationFilter.forEach(governmentDesignation => {
        query.append("government_designation", governmentDesignation)
      })
    }
  
  // (B) CONVERT TO STRING, APPEND TO URL
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      // console.log(value)
      queriesCount = queriesCount + 1;
    }
    // console.log("queriesCount", queriesCount)
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    // console.log(url);
    return url
  // (C) WHATEVER COMES NEXT...
  // REDIRECT OR AJAX CALL OR FETCH
  // window.location.href = url;
  })

  function jumpToPage(targetPage) {
    loadDLACompanies(fetchCompanyFilter.value + `&page=${targetPage}`)
    resetSelectAllClearSelectedCompanies()
  }
  
  function goToFirst(){
    loadDLACompanies(fetchCompanyFilter.value + `&page=${1}`)
    resetSelectAllClearSelectedCompanies()
  }
  
  function goToLast(){
    loadDLACompanies(fetchCompanyFilter.value + `&page=${companyTotalPages.value}`)
    resetSelectAllClearSelectedCompanies()
  }

  const fetchResultFilter = computed(function () {
    // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
    let query = new URLSearchParams({
    });

    if (resultFilters.resultPageSizeFilter) {
      query.append("page_size", resultFilters.resultPageSizeFilter); // To append more data
    }
    if (resultFilters.resultGovernmentDesignationFilter) {
        resultFilters.resultGovernmentDesignationFilter.forEach(governmentDesignation => {
          query.append("government_designation", governmentDesignation)
        })
    }
    if (resultFilters.resultGoverningSpecificationFilter) {
        resultFilters.resultGoverningSpecificationFilter.forEach(governingSpecification => {
          query.append("governing_specification", governingSpecification)
        })
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    return url
  })

  async function searchByQPL(endpoint = fetchResultFilter.value, retainSpot) {
    qplSearchLoading.value = true;
    let payload = {
      token: store.state.auth.token,
      endpoint: retainSpot ? endpoint + `&page=${qplSearchPage.value}` : endpoint 
    }
    const result = await getDLACompanies(payload);
    qplSearchResults.value = result.companies;
    qplSearchNext.value = result.next ? `&page=${result.page + 1}` : null
    qplSearchPrev.value = result.prev ? `&page=${result.page - 1}` : null
    qplSearchCount.value = result.count
    qplSearchPage.value = result.page
    qplSearchPageSize.value = result.companies.length
    qplSearchTotalPages.value = result.num_pages
    // console.log("this.companyResults", this.companyResults);
    qplSearchLoading.value = false;
    resetSelectedCompaniesOnChange()
  }

  function setResultFilters(updatedFilters) {
    // console.log("setCompanyFilters", updatedFilters);
    resultFilters.resultCollectionTagsFilter = updatedFilters.value.resultCollectionTagsFilter;
    resultFilters.resultGovernmentDesignationFilter = updatedFilters.value.resultGovernmentDesignationFilter;
    resultFilters.resultGoverningSpecificationFilter = updatedFilters.value.resultGoverningSpecificationFilter;
  }

  function setResultPageSizeFilter(target) {
      target == "clear" ? resultFilters.resultPageSizeFilter = null : resultFilters.resultPageSizeFilter = target
      resetSelectAllClearSelectedResults()
      searchByQPL()
  }

  function setChangedResultFilters(){
    changedResultFilters.value = true;
  }

  function resetSelectAllClearSelectedResults() {
    resetSelectAllResults()
    clearSelectedResults()
  }

  function resetSelectedResultsOnChange(){
    if(changedResultFilters.value){
      changedResultFilters.value = false;
      resetSelectAllClearSelectedResults()
    }
  }

  function clearSelectedResults() {
    selectedResults.value = [];
    clearedSelectedResults.value = true;
  }

  function resetClearedSelectedResults() {
    clearedSelectedResults.value = false;
  }

  function clearSelectedResultsOnPage() {
    selectedResults.value = selectedResults.value.filter(company => selectedResultsOnPage.value.indexOf(company.id) === -1);
    console.log('clear selected companies on page')
    clearedSelectedResultsOnPage.value = true;
  }

  function resetClearedSelectedResultsOnPage() {
    clearedSelectedResultsOnPage.value = true;
  }

  function qplSearchJumpToPage(targetPage) {
    searchByQPL(fetchResultFilter.value + `&page=${targetPage}`)
    resetSelectAllClearSelectedResults()
  }

  function toggleSelectAllResultsOnPage() {
    clearSelectedResultsOnPage()
    resetClearedSelectedResultsOnPage()
    if(selectAllResultsOnPage.value){
      selectAllResultsOnPage.value = false;
    }else{
      selectAllResultsOnPage.value = true;
    }
  }

  function resetSelectAllResults() {
    if(selectAllResults.value){
      selectAllResults.value = false;
    }
    if(selectAllResultsOnPage.value){
      selectAllResultsOnPage.value = false;
    }
  }

  const selectedResultIds = computed(function() {
    if(selectedResults.value.length > 0){
      return selectedResults.value.map(({id}) => {
        return id
      })
  
    } else {
      return []
    }
  })

  const qplSearchResultsIds = computed(function() {
    if(qplSearchResults.value.length > 0){
      return qplSearchResults.value.map(({id}) => {
        return id
      })
    } else {
      return []
    }
  })

  const numSelectedResults = computed(function() {
    return selectedResults.value.length
  })

  const selectedResultsOnPage = computed(function() {
    if(numSelectedResults.value > 0){
      return selectedResultIds.value.filter((companyId) => {
        return qplSearchResultsIds.value.includes(companyId)
    })
    } else {
      return []
    }
  })

  const selectedResultsOnPageMap = computed(function() {
    return new Map(selectedResultsOnPage.value.map((companyId) => [companyId, companyId]))
  })
  
  const numSelectedResultsOnPage = computed(function() {
    return selectedResultsOnPage.value.length
    
  })
  
  const selectedResultsOffPage = computed(function(){
    return selectedResultIds.value.filter((companyId) => {
      return !qplSearchResultsIds.value.includes(companyId)
    })
  })
  
  const numSelectedResultsOffPage = computed(function() {
    return selectedResultsOffPage.value.length
  })
  
  function qplSearchGoToFirst(){
    searchByQPL(fetchResultFilter.value + `&page=${1}`)
    resetSelectAllClearSelectedResults()
  }
  
  function qplSearchGoToLast(){
    searchByQPL(fetchResultFilter.value + `&page=${qplSearchTotalPages.value}`)
    resetSelectAllClearSelectedResults()
  }

  function qplSearchGetNext() {
    if (qplSearchNext.value) {
      searchByQPL(fetchResultFilter.value + qplSearchNext.value)
      resetSelectAllClearSelectedResults()
    } else {
      alert("No next page")
    }
  }
  
  function qplSearchGetPrev() {
    if (qplSearchPrev.value) {
      searchByQPL(fetchResultFilter.value + qplSearchPrev.value)
      resetSelectAllClearSelectedResults()
    } else {
      alert("No prev page")
    }
  }

  const qplSearchViewingFromNbr = computed(function () {
    return (qplSearchPage.value * qplSearchPageSize.value + 1) - qplSearchPageSize.value
  })
  
  const qplSearchViewingToNbr = computed(function () {
    if (qplSearchPage.value === qplSearchTotalPages.value) {
      return qplSearchCount.value;
    } else {
      return qplSearchPage.value * qplSearchPageSize.value
    }
  })

  function addSelectedResult(company) {
    let foundIndex = selectedResultIds.value.indexOf(company.id);
    if (foundIndex === -1) {
      selectedResults.value.push(company)
    }
  }
  
  function removeSelectedResult(company) {
    let foundIndex = selectedResultIds.value.indexOf(company.id);
    if (foundIndex !== -1) {
      selectedResults.value.splice(foundIndex, 1)
    }
  }

  async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
  }
  
  
  setUserPermissions()
  loadDLACompanies()
  loadCampaigns()
  closeCreateCompanyForm()
  </script>
  
  <style scoped>
  </style>
  
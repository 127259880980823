<template>
    <div class="w-full h-full">
        <base-card class="w-full max-w-[1200px] text-gray-900 shadow-md bg-white" :style="postStyle">
            <div class="w-full h-full space-y-2 p-2">
                <div class="w-full border-b border-gray-300 pb-2 flex justify-between">
                    <div class="whitespace-nowrap text-xs space-y-0.5 grid grid-cols-6 space-x-2 items-center w-full">
                        <div class="col-span-2">
                            <div class="whitespace-nowrap text-xs inline pl-1">
                                {{ formatDateTime(researchPost.created_at) }}
                            </div>
                            <div v-if="formatDateTime(researchPost.created_at) !== formatDateTime(researchPost.updated_at)" class="whitespace-nowrap text-xs inline text-gray-800 pl-1">
                                (Edited: {{ formatDateTime(researchPost.updated_at) }} )
                            </div>
                        </div>
                        <div v-if="postType=='collection_research'" class="w-full flex col-span-3">
                            <div v-if="isEditable" class="w-full flex space-x-2">
                                <BaseListBoxRounded
                                    class="h-8 border border-gray-400 rounded-md w-full inline"
                                    placeholder=""
                                    :modelValue="researchPostData.post_category"
                                    :options="postCategoryOptions"
                                    :multiple="false"
                                    @update:modelValue="setPostCategory"
                                    >
                                </BaseListBoxRounded>
                                <BaseListBoxRounded
                                class="h-8 border border-gray-400 rounded-md w-full inline"
                                placeholder=""
                                :modelValue="researchPostData.collection_tag"
                                :options="collectionOptions"
                                :multiple="false"
                                @update:modelValue="setCollection"
                                >
                            </BaseListBoxRounded>
                            </div>
                            <div v-else class="grid grid-cols-2 justify-between items-start space-x-2 w-full">
                                <div>
                                    {{ researchPost.post_category }}
                                </div>
                                <div>
                                    {{ collectionName }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="flex justify-start items-start space-x-2 w-full col-span-3">
                            <div v-if="isEditable" class="flex justify-start items-start space-x-2 w-full px-2">
                                <BaseListBoxRounded
                                    class="h-8 border border-gray-400 rounded-md w-full"
                                    placeholder=""
                                    :modelValue="researchPostData.post_category"
                                    :options="postCategoryOptions"
                                    :multiple="false"
                                    @update:modelValue="setPostCategory"
                                    >
                                </BaseListBoxRounded>
                            </div>
                            <div v-else class="flex justify-start items-start space-x-2">
                                {{ researchPost.post_category }}
                            </div>
                        </div>
                        <div class="flex justify-start items-start">
                            {{ createdBy }}
                        </div>
                    </div>
                    <div class="w-[100px] flex justify-end">
                        <div v-if="currentUser.id === createdById"
                            class="space-x-2 text-gray-800 hover:cursor-pointer hover:text-accent-primary flex justify-end items-center"
                            >
                            <div v-if="isEditable" @click="cancelEditable">
                                Cancel
                            </div>
                            <div v-else @click="makeEditable">
                                Edit
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isEditable" class="flex justify-start items-center py-0.5 space-x-2">
                    <textarea type="text" required class="p-1 border h-full w-full inline border-gray-400 rounded-md" rows="4" v-model="researchPostData.post_body"></textarea>
                </div>
                <div v-else class="flex w-full whitespace-pre-wrap break-all">
                    {{ researchPost.post_body }}
                </div>
                <div v-if="isEditable" class="grid grid-cols-3 w-full justify-between space-x-2 px-2">
                    <div class="w-full "></div>
                    <div class="flex w-full justify-center items-center space-x-2">
                        <input type="checkbox" id="flag" class="align-middle" :checked="flagged" @change="flagAsDone"/>
                        <div class="whitespace-nowrap">
                            Flag Done 
                        </div>
                    </div>
                    <div  class="flex w-full justify-end space-x-2">
                        <base-button-affirmative @click="updateContactResearchPost">Update</base-button-affirmative>
                        <base-button-negative @click="deleteContactResearchPost">Delete</base-button-negative>
                    </div>
                </div>
            </div>
        </base-card>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import BaseListBoxRounded from '@/components/BaseListBoxRounded.vue';
import { useStore } from 'vuex';
import { formatDateTime } from '@/composables/formatDateTime';
import { updateResearchPost } from '@/services/ResearchPost';
import { deleteResearchPost } from '@/services/ResearchPost';
import { useToast } from 'vue-toast-notification';

const props = defineProps(['company', 'researchPost', 'currentUser', 'postType'])

const emit = defineEmits(['refetch-research-posts', 'refetch-companies'])

const store = useStore()

const $toast = useToast()

const isEditable = ref(false)

// const postCategory = ref(props.researchPost.post_category)
// const postBody = ref(props.researchPost.post_body)
// const postFlag = ref(props.researchPost.flag)
// const postImageAttachment = ref(props.researchPost.image_attachment)

const researchPostData = ref({
    post_category: props.researchPost.post_category,
    post_body: props.researchPost.post_body,
    collection_tag: props.researchPost.collection_tag,
    collection_tag_name: props.researchPost.collection_tag_name,
    flag: props.researchPost.flag,
    image_attachment: props.researchPost.image_attachment,
})

const flagged = computed(()=> {
    if(researchPostData.value.flag) {
        return true
    } else {
        return false
    }
})

const createdBy = computed(() => {
    if(props.researchPost.created_by) {
        return `${props.researchPost.created_by.first_name} ${props.researchPost.created_by.last_name}`
    } else {
        return '--'
    }
})

const createdById = computed(() => {
    if(props.researchPost.created_by) {
        return props.researchPost.created_by.id
    } else {
        return null
    }
})

const postCategoryOptions = computed(() => {
    if(props.postType == 'contact_research') {
        return [
            {value: null, label: "-------"},
            {value: "Website Research", label: "Website Research"},
            {value: "Zoominfo Research", label: "Zoominfo Research"},
            {value: "LinkedIn Research", label: "LinkedIn Research"},
            {value: "Buzzfile Research", label: "Buzzfile Research"},
            {value: "CAGE Research", label: "CAGE Research"},
            {value: "Open Corporates Research", label: "Open Corporates Research"},
            {value: "USA Spending Research", label: "USA Spending Research"},
            {value: "DACIS Research", label: "DACIS Research"},
            {value: "Hunter.io Research", label: "Hunter.io Research"},
            {value: "Rocket Reach Research", label: "Rocket Reach Research"},
            {value: "Signal Hire Research", label: "Signal Hire Research"},
            {value: "Whois Research", label: "Whois Research"},
            {value: "D&B Research", label: "D&B Research"},
            {value: "Other Research", label: "Other Research"},
            {value: "Get Cell Phone Number", label: "Get Cell Phone Number"},
            {value: "Biographical Info", label: "Biographical Info"},
            {value: "Email Verification", label: "Email Verification"},
            {value: "Candidate Contacts", label: "Candidate Contacts"},
            {value: "Need Help", label: "Need Help"},
        ]
    } else if (props.postType == 'collection_research') {
        return [
            {value: null, label: "-------"},
            {value: "Interesting", label: "Interesting"},
            {value: "Not Interesting", label: "Not Interesting"},
        ]
    } else {
        return []
    } 
})

const postStyle = computed(() => {
    if(props.researchPost.post_category==='Need Help') {
        return 'background-color: #fde68a; border: 1px solid #92400e;'
    }
    else if(props.researchPost.post_category==='Interesting') {
        return 'background-color: #bae6fd; border: 1px solid #075985;'
    }
    else if(props.researchPost.post_category==='Not Interesting') {
        return 'background-color: #fed7aa; border: 1px solid #9a3412;'
    }
    else if(props.researchPost.flag) {
        return 'background-color: #a7f3d0; border: 1px solid #065f46;'
    } else {
        return ''
    }
})

const collectionOptions = computed(() => {
    if(props.company.collection_tags) {
        let collectionTags = props.company.collection_tags
        return [{value:null, label: "------------"}, ...collectionTags.map((collectionTag) => {
            return {value: collectionTag.collection_tag.id, label: collectionTag.collection_tag.collection_tag_name}
        })]
    } else {
        return [{value: null, label: "------------"}]
    }
})

const collectionName = computed(() => {
    if(props.researchPost.collection_tag) {
        for(let collectionTag of props.company.collection_tags) {
            if(collectionTag.collection_tag.id === props.researchPost.collection_tag) {
                return `${props.researchPost.collection_tag_name}`
            }
        }
        return `${props.researchPost.collection_tag_name} (Removed)`
    }
    return '--'
})

function setPostCategory(target) {
    researchPostData.value.post_category = target;
}

function flagAsDone() {
    if(researchPostData.value.flag) {
        researchPostData.value.flag = null
    } else {
        researchPostData.value.flag = 'Done'
    }
}

function setCollection(value) {
    researchPostData.value.collection_tag = value;
    setCollectionName(value)
}

function setCollectionName(collectionId) {
    for(let collectionOption of collectionOptions.value) {
        if(collectionId == collectionOption.value) {
            researchPostData.value.collection_tag_name = collectionOption.label
        }
    }
}

function makeEditable () {
    isEditable.value = true
}

function cancelEditable () {
    isEditable.value = false
}

async function updateContactResearchPost () {
    // console.log('update')
    const token = store.state.auth.token
    const result = await updateResearchPost(props.researchPost.id, researchPostData.value, token)
    // console.log(result)
    if(result.status==200) {
        $toast.open({
            message: `Successfully Updated Research Post.`,
            type: 'success',
            duration: 5000
        })
        isEditable.value = false
        emit('refetch-research-posts')
        emit('refetch-companies')
    } else {
        $toast.open({
            message: 'Error Updating Research Post.',
            type: 'error',
            duration: 20000
        })
    }
}

async function deleteContactResearchPost () {
    // console.log('delete')
    const token = store.state.auth.token
    const result = await deleteResearchPost(props.researchPost.id, token)
    if(result.status==200) {
        $toast.open({
            message: `${result.data.status} `,
            type: 'success',
            duration: 5000
        })
        isEditable.value = false
        emit('refetch-research-posts')
        emit('refetch-companies')
    } else {
        $toast.open({
            message: result.status,
            type: 'error',
            duration: 20000
        })
    }
}
</script>
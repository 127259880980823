<template>
      <div
        class="flex flex-col"
      >
        <!-- {{ company.zoom_info_company ? company.zoom_info_company.zoom_info_id : "--" }} -->
        {{ zoomInfoCompanyId }}
      </div>
</template>

<script>
    export default {
        name: "zi-id-cell"
    }
</script>

<script setup>
    import { defineProps, computed } from "vue"

    const props = defineProps({
        company: Object
    })

    const zoomInfoCompanyId = computed(() => {
      if(props.company.zoom_info_company) {
        return props.company.zoom_info_company.zoom_info_id
      } else if(props.company.zoom_info_id_candidates.length > 0){
        return 'Multiple ZI ID Candidates'
      } else {
        return '--'
      }
    })



</script>
<template>
    <div class="grid grid-cols-3 gap-1">
        <button @click="assignNextActionDueDate(3)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">+3d</button>
        <button @click="assignNextActionDueDate(7)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">+1w</button>
        <button @click="assignNextActionDueDate(14)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">+2w</button>
        <button @click="assignNextActionDueDate(21)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">+3w</button>
        <button @click="assignNextActionDueDate(30)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">+1m</button>
        <button @click="assignNextActionDueDate(90)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">+3m</button>
        <button @click="assignNextActionDueDate(null)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">Clear</button>
    </div>
</template>

<script>
    export default {
        name: "mark-crm-next-action-due-date-buttons-cell"
    }
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { useStore } from 'vuex';
import { updateCompany } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    async function assignNextActionDueDate(days){
        const token = store.state.auth.token
        let data = null
        if(days){
            let today = new Date()
            console.log(today)
            let dueDate = new Date(today.setDate(today.getDate() + days)).toISOString().split('T')[0]
            console.log(dueDate)
            data = JSON.stringify({
                crm_next_action_due_date: dueDate
            })
        } else {
            data = JSON.stringify({
                crm_next_action_due_date: null
            })
        }
        await updateCompany(props.company.id, data, token)
        emit('refetch-companies')
    }

    

</script>
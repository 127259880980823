import axios from 'axios';
import { BASE_URL } from './api';


export async function getInvoices(token, queryParams='') {
    return axios({
        method: 'get',
        url: `${BASE_URL}/invoices/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function getInvoicesNeedingReminders(token, queryParams='') {
    return axios({
        method: 'get',
        url: `${BASE_URL}/invoices/get_invoices_needing_reminders/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function updateInvoice(invoiceId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/invoices/${invoiceId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function addInvoiceAccountingNote(invoiceId, formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/invoices/${invoiceId}/add_accounting_post/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function sendInvoiceReminder(invoiceId, data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/invoices/${invoiceId}/send_invoice_reminder/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
<template>
    <div>
      <base-card v-show="showFilterPane">
        <CompanyFilters @change-company-filters="setCompanyFilters" @set-changed-company-filters="setChangedCompanyFilters"></CompanyFilters>
      </base-card>
      <base-card
        v-if="RenderBulkOperations" 
        v-show="showBulkOperationsPane">
        <CompanyBulkOperations
          :selectedCompanies="selectedCompanies"
          :userPermissions="userPermissions"
          :filters="fetchFilter"
          @refetch-companies="loadCompCompanies(fetchFilter, true)"
          @clear-selected-companies="clearSelectedCompanies"
          @reset-select-all="resetSelectAll">
        </CompanyBulkOperations>
      </base-card>
      <base-card>
        <div class="flex flex-col text-sm text-gray-900" :class="[{'h-[60vh]': showBulkOperationsPane && showFilterPane}, {'h-[65vh]': !showBulkOperationsPane && showFilterPane}, {'h-[85vh]': !showFilterPane}, {'h-[90vh]': !showFilterPane && !showBulkOperationsPane}]">
          <div class="flex flex-row justify-between mb-4">
            <div class="flex flex-row items-center text-sm">
              <div class="ml-2 mr-3 flex-row justify-start items-center">
                <div class="flex flex-row items-center align-middle text-center">
                  <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllOnPage" @change="toggleSelectAllOnPage"/>
                  <p>
                    <span v-if="!selectAllOnPage" class="pl-1 align-middle">Select All On Page | </span>
                    <span v-else class="pl-1 align-middle">Deselect All On Page | </span>
                    <span
                      class="pl-1 align-middle text-accent-primary hover:text-dark-primary"
                      @click="showModal"
                    >{{numSelectedCompanies}} Selected Companies
                    </span>
                    <base-modal-small 
                      v-if="isModalVisible"
                      @close="closeModal"
                    >
                    <template v-slot:header>
                      Selected Companies
                    </template>
                    <template v-slot:body>
                      <SelectedCompaniesTable :selectedCompanies="selectedCompanies" />
                    </template>
                    </base-modal-small>
                  </p>
                </div>
                <div class="text-sm text-right">
                  <span class="hover:text-accent-primary hover:cursor-pointer" @click="resetSelectAllClearSelectedCompanies">Clear All Selected Companies</span> 
                  (<span class="">{{ numSelectedCompaniesOnPage }} on page</span> | 
                  <span class="">{{ numSelectedCompaniesOffPage }} off page</span>)
                </div>
              </div>
              <div class="mx-3">
                {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }}  Total Companies
              </div>
              <base-filter-horizontal>
                <template v-slot:label>
                  Page Size
                </template>
                <template v-slot:widget>
                  <base-list-box-horizontal
                    class="w-full rounded-r-md text-xs"
                    placeholder="Select"
                    :options="pageSizeOptions"
                    :modelValue="companyFilters.companyPageSizeFilter"
                    :multiple="false"
                    @update:modelValue="setCompanyPageSizeFilter"
                  />
                </template>
            </base-filter-horizontal>
              <div class="mx-1">
                <div v-if="isLoading" role="status" class="pl-3">
                  <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
                <div  v-else class="pl-3">
                  <svg @click="loadCompCompanies(fetchFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="#25274D" class="mx-1 w-8 h-8 hover:cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                  </svg>
                </div>
              </div>
              <div class="flex items-center ml-2">
              <div class="flex items-center">
                <base-menu-button @click="goToFirst">&lt;&lt;</base-menu-button>
                <base-menu-button @click="getPrev">&lt;</base-menu-button>
                <div class="flex flex-row">
                  Page <span><JumpToPage class="mx-1" :current-page="page" :max-page="totalPages" @fetch-page="jumpToPage"/></span>of {{ totalPages }}
                </div>
                <base-menu-button @click="getNext">></base-menu-button>
                <base-menu-button @click="goToLast">>></base-menu-button>
              </div>
              <base-button v-if="userPermissions.isAccountManager || userPermissions.isDev || userPermissions.isManagement" @click="getCSV" class="underline ml-2">⬇</base-button>

            </div>
              <!-- <div class="text-sm flex ml-6">
                <base-menu-button @click="toggleFilter">{{ showFilterText }}</base-menu-button>
                <base-menu-button v-if="RenderBulkOperations" @click="toggleBulk">{{ showBulkText }}</base-menu-button>
                <base-menu-button @click="toggleStatusSummary">{{ showStatusSummary ? "Hide Status Summary" : "Show StatusSummary" }}</base-menu-button>
              </div> -->
            </div>
          </div>
          <div class="mx-3">
            <CRMStatusSummary v-if="showStatusSummaryPane" :query="fetchFilter" :company-count="count" :companies="companyResults" />
          </div>
          <div v-if="companyResults.length > 0" class="h-full overflow-y-auto">
            <base-table>
              <!--      <base-table @refetch-companies="fetchNewCompanies">-->
              <template #table-header>
                <base-table-header :key="header.id" :header="header" v-for="header in tableHeadersList"> {{
                    header
                  }}
                </base-table-header>
              </template>
    
              <template #table-body>
                <NewCRMRow @add-remove-selected-companies="addRemoveSelectedCompanies"
                  @add-selected-company="addSelectedCompany"
                  @remove-selected-company="removeSelectedCompany"
                  @refetch-companies="loadCompCompanies(fetchFilter, true)"
                  @reset-changed-company-filters="resetSelectedCompaniesOnChange"
                  @reset-cleared-selected-companies="resetClearedSelectedCompanies"
                  :selectAll="selectAll"
                  :selectAllOnPage="selectAllOnPage"
                  :selectedCompaniesOnPageMap="selectedCompaniesOnPageMap"
                  :key="company.id"
                  :company="company"
                  :campaigns="campaigns"
                  :clearedSelectedCompanies="clearedSelectedCompanies"
                  :clearedSelectedCompaniesOnPage="clearedSelectedCompaniesOnPage"
                  :changedCompanyFilters="changedCompanyFilters"
                  :permissions="userPermissions"
                  :crm-statuses="crmStatuses"
                  :current-statuses="mostRecentStatuses"
                  v-for="company in companyResults"
                            />
              </template>
            </base-table>
          </div>
          <base-modal :is-form-visible="showCreateCompanyForm" @close="closeCreateCompanyForm">
            <template #header>
              <h2 class="text-lg">Create Company</h2>
            </template>
            <template #body>
              <CreateCompany
                :campaigns="campaigns"
                @close="closeCreateCompanyForm"
                @refetch-companies="loadCompCompanies(fetchFilter, true)"
              />
            </template>
          </base-modal>
        </div>
      </base-card>
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'new-crm',
    data: () => ({
    }),
    methods: {
    }
  }
  </script>
  
  <script setup>
  import {ref, reactive, shallowReactive, computed, watch, provide} from 'vue';
  import { useRoute } from 'vue-router';
  import {useStore} from 'vuex';
  import CompanyFilters from './CompanyFilters.vue';
  import NewCRMRow from './NewCRMRow.vue';
  import BaseCard from "@/components/BaseCard";
  import CompanyBulkOperations from "@/components/CompanyBulkOperations";
  import SelectedCompaniesTable from './SelectedCompaniesTable.vue';
  import JumpToPage from './JumpToPage.vue';
  import {getCompanies} from '../services/Company.js';
  import { getCampaigns } from '@/services/Campaign';
  import { getCrmStatuses } from '@/services/CrmStatus';
  import CRMStatusSummary from './CRMStatusSummary.vue';
  import { getMostRecentCrmStatus } from '../services/Company.js';
  import { resetFiltersVisiblity } from '@/composables/resetFilterVisibility';
  import { exportToCSV } from '../services/Company.js';
  import CreateCompany from './CreateCompany.vue';

  const route = useRoute()
  
  const isLoading = ref(false);
  const tableHeadersList = [
    ' ',
    'ID',
    'Company CRM Summary',
    'Account Manager Notes',
    'Client Feedback/Notes',
    'Revenue/Headcount Location/URL',
    'Contacts',
    'CRM Status',
    'Last Completed Task',
    'Next Task Due',
    'Days Until Task Due',
    // 'CRM Next Action Due Date',
    // 'Days Until Next Action',
    'All Open Tasks'
    // 'Edit'
  ];
  const companyResults = ref([]);
  const campaigns = ref([])
  const crmStatuses = ref([])
  const next = ref(null);
  const prev = ref(null);
  const count = ref(null);
  const page = ref(1);
  const pageSize = ref(null);
  const companyFilters = reactive({
    companyNameFilter: null,
    companyDescriptionFilter: null,
    companyAddressFilter: null,
    companySourcesFilter: null,
    companySourceOwnerFilter: null,
    companyCampaignFilter: [route.params.campaignId],
    companyTierFilter: null,
    companyClientFeedbackFilter: null,
    companyOnboardingStatusFilter: null,
    companyDNRReasonFilter: null,
    companyResearchStatusFilter: null,
    companyResearchStatusExpressionFilter: null,
    companyResearchStatusFilterTwo: null,
    companyResearchStatusExpressionFilterTwo: null,
    companyMinRevenueFilter: null,
    companyMaxRevenueFilter: null,
    companyMinHeadcountFilter: null,
    companyMaxHeadcountFilter: null,
    companyMinRevenueZiCombinedFilter: null,
    companyMaxRevenueZiCombinedFilter: null,
    companyClientFilter: null,
    companyPageSizeFilter: 50,
    companyBusinessTypeFilter: null,
    companySectorFilter: null,
    companySubSectorFilter: null,
    companyHasCampaignFilter: "True",
    companyCRMStatusFilter: null,
    companyCRMNextActionFilter: null,
    // companyDueTimeRangeFilter: null,
    compayDueDateStartFilter: null,
    companyDueDateEndFilter: null,
    companyCollectionTagsFilter: null,
    companyStateFilter: null,
    companyNAICSCodeFilter: null,
    companyStateExpressionFilter: 'or',
    companyTaskUserFilter:null,
    companyEveraxisPillarFilter: null,
    companyProductServiceCodeFilter: null,
  });
  const changedCompanyFilters = ref(false);
  const isModalVisible = ref(false);
  const selectedCompanies = ref([]);
  const selectAll = ref(false);
  const selectAllOnPage = ref(false);
  const clearedSelectedCompanies = ref(false);
  const clearedSelectedCompaniesOnPage = ref(false);
  const awaitingSearch = ref(false);
  const mostRecentStatuses = ref(null)
  const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
  });
  
  const pageSizeOptions = ref([
      {value: "clear", label: "----"},
      {value: 10, label: "10"},
      {value: 25, label: "25"},
      {value: 50, label: "50"},
      {value: 100, label: "100"},
      {value: 200, label: "200"},
    ])

  watch(() => companyFilters, () => {
        // console.log("Hit Watcher.", newValue)
        // console.log("companyFilters", companyFilters)
        // console.log("companyFilters.companyNameFilter", companyFilters.companyNameFilter)
        // console.log("companyFilters.companyNameFilter.value", companyFilters.companyNameFilter.value)
        if (!awaitingSearch.value) {
          setTimeout(() => {
            loadCompCompanies();
            awaitingSearch.value = false;
          }, 2500); // 2.5 sec delay
        }
        awaitingSearch.value = true;
      },
      {deep: true}
  );
  
  const store = useStore()
  
  const totalPages = ref(0)
  
  const showFilterPane = computed(() => store.getters['company/showFilterPane'])
  const showBulkOperationsPane = computed(() => store.getters['company/showBulkOperationsPane'])
  const showStatusSummaryPane = computed(() => store.getters['company/showStatusSummaryPane'])
  const showCreateCompanyForm = computed(() => store.getters['company/showCreateCompanyForm'])

  function closeCreateCompanyForm() {
    store.dispatch('company/setShowCreateCompanyFormFalse')
  }

  async function loadCompCompanies(endpoint = fetchFilter.value, retainSpot) {
    // console.log("loadCompCompanies BEGIN")
    isLoading.value = true;
    // console.log("store", this.$store.state.auth.token)
    let payload = {
      token: store.state.auth.token,
      endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint 
    }
    // console.log("payload", payload)
    const result = await getCompanies(payload);
    // console.log("result after await", result)
    // console.log("result companies", result.companies)
    companyResults.value = result.companies;
    next.value = result.next ? `&page=${result.page + 1}` : null
    prev.value = result.prev ? `&page=${result.page - 1}` : null
    count.value = result.count
    page.value = result.page
    pageSize.value = result.companies.length
    totalPages.value = result.num_pages
    // console.log("this.companyResults", this.companyResults);
    isLoading.value = false;
    resetSelectedCompaniesOnChange()
    loadMostRecentStatuses()
    setShowStatusSummaryPaneTrue()
  }
  
  async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
  }

  function showModal() {
    isModalVisible.value = true;
  }


  function closeModal() {
    isModalVisible.value = false;
  }
  
  function resetSelectAllClearSelectedCompanies() {
    resetSelectAll()
    clearSelectedCompanies()
  // resetClearedSelectedCompanies()
  }
  
  function setChangedCompanyFilters(){
    changedCompanyFilters.value = true;
  }
  
  function resetSelectedCompaniesOnChange(){
    if(changedCompanyFilters.value){
      changedCompanyFilters.value = false;
      resetSelectAllClearSelectedCompanies()
    }
  }
  
  function clearSelectedCompanies() {
    // console.log('clearSelectedCompanies')
    selectedCompanies.value = [];
    clearedSelectedCompanies.value = true;
  }
  
  function resetClearedSelectedCompanies() {
    clearedSelectedCompanies.value = false;
  }

  function clearSelectedCompaniesOnPage() {
    // logic here to keep selected companies off page and only clear companies on page
    selectedCompanies.value = selectedCompanies.value.filter(company => selectedCompaniesOnPage.value.indexOf(company.id) === -1);
    console.log('clear selected companies on page')
    clearedSelectedCompaniesOnPage.value = true;
  }

  function resetClearedSelectedCompaniesOnPage() {
    clearedSelectedCompaniesOnPage.value = true;
  }
  
  function toggleSelectAllOnPage() {
    clearSelectedCompaniesOnPage()
    resetClearedSelectedCompaniesOnPage()
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }else{
      selectAllOnPage.value = true;
    }
  }
  
  function resetSelectAll() {
    if(selectAll.value){
      selectAll.value = false;
    }
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }
  }
  
  function getNext() {
    if (next.value) {
      loadCompCompanies(fetchFilter.value + next.value)
      resetSelectAllClearSelectedCompanies()
    } else {
      alert("No next page")
    }
  }
  
  function getPrev() {
    if (prev.value) {
      loadCompCompanies(fetchFilter.value + prev.value)
      resetSelectAllClearSelectedCompanies()
    } else {
      alert("No prev page")
    }
  }

  async function getCSV(){
    const token = store.state.auth.token
    // console.log('filters', fetchFilter.value)
    await exportToCSV(fetchFilter.value, token)
  }
  
  const selectedCompanyIds = computed(function() {
    if(selectedCompanies.value.length > 0){
      return selectedCompanies.value.map(({id}) => {
        return id
      })
  
    } else {
      return []
    }
  })

  const companyResultsIds = computed(function() {
    if(companyResults.value.length > 0){
      return companyResults.value.map(({id}) => {
        return id
      })
    } else {
      return []
    }
  })

  const numSelectedCompanies = computed(function() {
    return selectedCompanies.value.length
  })

  const selectedCompaniesOnPage = computed(function() {
    if(numSelectedCompanies.value > 0){
      return selectedCompanyIds.value.filter((companyId) => {
        return companyResultsIds.value.includes(companyId)
    })
    } else {
      return []
    }
  })

  const selectedCompaniesOnPageMap = computed(function() {
    return new Map(selectedCompaniesOnPage.value.map((companyId) => [companyId, companyId]))
  })

  const numSelectedCompaniesOnPage = computed(function() {
    return selectedCompaniesOnPage.value.length
    
  })

  const selectedCompaniesOffPage = computed(function(){
    return selectedCompanyIds.value.filter((companyId) => {
      return !companyResultsIds.value.includes(companyId)
    })
  })

  const numSelectedCompaniesOffPage = computed(function() {
    return selectedCompaniesOffPage.value.length
  })
    
    const viewingFromNbr = computed(function () {
      return (page.value * pageSize.value + 1) - pageSize.value
    })
    
    
    const viewingToNbr = computed(function () {
      if (page.value === totalPages.value) {
        return count.value;
      } else {
        return page.value * pageSize.value
      }
    })
  
  
  // const totalPages = computed(function () {
  //   return Math.ceil(count.value / pageSize.value )
  // })
  
  const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    // console.log('user permissions store', userPermissionsStore)
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies =  userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  
    // console.log('user permissions', userPermissions)
  }
  
  const RenderBulkOperations = computed(function () {
    if(userPermissions.isAccountManager){
      return true
    }
    if(userPermissions.isAccountStaff){
      return true
    }
    if(userPermissions.isDev){
      return true
    }
    if(userPermissions.isManagement){
      return true
    }
    if(userPermissions.isResearchLead){
      return true
    }
    if(userPermissions.isSuperuser){
      return true
    }
    return false
  })
  
  
  function addSelectedCompany(company) {
    let foundIndex = selectedCompanyIds.value.indexOf(company.id);
    if (foundIndex === -1) {
      selectedCompanies.value.push(company)
      // console.log("Did not find.  Pushing.", selectedCompanies)
    }
  }
  
  function removeSelectedCompany(company) {
    let foundIndex = selectedCompanyIds.value.indexOf(company.id);
    if (foundIndex !== -1) {
      selectedCompanies.value.splice(foundIndex, 1)
      // console.log("Found index.  Deleting.", selectedCompanies)
    }
  }
  
  function addRemoveSelectedCompanies(company) {
    // console.log("addRemoveSelectedCompanies BEGIN", company)
    let foundIndex = selectedCompanies.value.indexOf(company);
    // console.log("foundIndex", foundIndex)
    if (foundIndex === -1) {
      selectedCompanies.value.push(company)
      // console.log("Did not find.  Pushing.", selectedCompanies)
    } else {
        selectedCompanies.value.splice(foundIndex, 1)
        // console.log("Found index.  Deleting.", selectedCompanies)
      }
    }
  
  function setCompanyFilters(updatedFilters) {
    // console.log("setCompanyFilters", updatedFilters);
    companyFilters.companyNameFilter = updatedFilters.value.companyNameFilter;
    companyFilters.companyDescriptionFilter = updatedFilters.value.companyDescriptionFilter;
    companyFilters.companyAddressFilter = updatedFilters.value.companyAddressFilter;
    companyFilters.companySourcesFilter = updatedFilters.value.companySourcesFilter;
    companyFilters.companySourceOwnerFilter = updatedFilters.value.companySourceOwnerFilter;
    companyFilters.companyCampaignFilter = updatedFilters.value.companyCampaignFilter;
    companyFilters.companyTierFilter = updatedFilters.value.companyTierFilter;
    companyFilters.companyClientFeedbackFilter = updatedFilters.value.companyClientFeedbackFilter;
    companyFilters.companyOnboardingStatusFilter = updatedFilters.value.companyOnboardingStatusFilter;
    companyFilters.companyDNRReasonFilter = updatedFilters.value.companyDNRReasonFilter;
    companyFilters.companyResearchStatusFilter = updatedFilters.value.companyResearchStatusFilter;
    companyFilters.companyResearchStatusExpressionFilter = updatedFilters.value.companyResearchStatusExpressionFilter;
    companyFilters.companyResearchStatusFilterTwo = updatedFilters.value.companyResearchStatusFilterTwo;
    companyFilters.companyResearchStatusExpressionFilterTwo = updatedFilters.value.companyResearchStatusExpressionFilterTwo;
    companyFilters.companyMinRevenueFilter = updatedFilters.value.companyMinRevenueFilter;
    companyFilters.companyMaxRevenueFilter = updatedFilters.value.companyMaxRevenueFilter;
    companyFilters.companyMinHeadcountFilter = updatedFilters.value.companyMinHeadcountFilter;
    companyFilters.companyMaxHeadcountFilter = updatedFilters.value.companyMaxHeadcountFilter;
    companyFilters.companyMinRevenueZiCombinedFilter = updatedFilters.value.companyMinRevenueZiCombinedFilter;
    companyFilters.companyMaxRevenueZiCombinedFilter = updatedFilters.value.companyMaxRevenueZiCombinedFilter;
    companyFilters.companyClientFilter = updatedFilters.value.companyClientFilter;
    // companyFilters.companyPageSizeFilter = updatedFilters.value.companyPageSizeFilter;
    companyFilters.companyBusinessTypeFilter = updatedFilters.value.companyBusinessTypeFilter;
    companyFilters.companySectorFilter = updatedFilters.value.companySectorFilter;
    companyFilters.companySubSectorFilter = updatedFilters.value.companySubSectorFilter;
    companyFilters.companyCRMStatusFilter = updatedFilters.value.companyCRMStatusFilter;
    companyFilters.companyCRMNextActionFilter = updatedFilters.value.companyCRMNextActionFilter;
    // companyFilters.companyDueTimeRangeFilter = updatedFilters.value.companyDueTimeRangeFilter;
    companyFilters.companyDueDateStartFilter = updatedFilters.value.companyDueDateStartFilter;
    companyFilters.companyDueDateEndFilter = updatedFilters.value.companyDueDateEndFilter;
    companyFilters.companyCollectionTagsFilter = updatedFilters.value.companyCollectionTagsFilter;
    companyFilters.companyStateFilter = updatedFilters.value.companyStateFilter;
    companyFilters.companyNAICSCodeFilter = updatedFilters.value.companyNAICSCodeFilter;
    companyFilters.companyTaskUserFilter = updatedFilters.value.companyTaskUserFilter;
    companyFilters.companyEveraxisPillarFilter = updatedFilters.value.companyEveraxisPillarFilter;
    companyFilters.companyProductServiceCodeFilter = updatedFilters.value.companyProductServiceCodeFilter;
  }
  
  function setCompanyPageSizeFilter(target) {
      target == "clear" ? companyFilters.companyPageSizeFilter = null : companyFilters.companyPageSizeFilter = target
      resetSelectAllClearSelectedCompanies()
  }
  
  
  const fetchFilter = computed(function () {
    // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
    let query = new URLSearchParams({
      // name: "John Doe",
      // email: "john@doe.com",
      // colors: JSON.stringify(["Red", "Green", "Blue"])
    });
    query.append("has_campaign", companyFilters.companyHasCampaignFilter)
    if (companyFilters.companyNameFilter) {
      query.append("company_name", companyFilters.companyNameFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueFilter) {
      query.append("min_revenue", companyFilters.companyMinRevenueFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueFilter) {
      query.append("max_revenue", companyFilters.companyMaxRevenueFilter); // To append more data
    }
    if (companyFilters.companyMinHeadcountFilter) {
      query.append("min_headcount", companyFilters.companyMinHeadcountFilter); // To append more data
    }
    if (companyFilters.companyMaxHeadcountFilter) {
      query.append("max_headcount", companyFilters.companyMaxHeadcountFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueZiCombinedFilter) {
      query.append("min_revenue_combined", companyFilters.companyMinRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueZiCombinedFilter) {
      query.append("max_revenue_combined", companyFilters.companyMaxRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyDescriptionFilter) {
      query.append("description", companyFilters.companyDescriptionFilter); // To append more data
    }
    if (companyFilters.companyAddressFilter) {
      query.append("address_search", companyFilters.companyAddressFilter); // To append more data
    }
    if (companyFilters.companyOnboardingStatusFilter) {
      query.append("onboarding_status", companyFilters.companyOnboardingStatusFilter); // To append more data
    }
    if (companyFilters.companyDNRReasonFilter) {
      query.append("do_not_research_reason", companyFilters.companyDNRReasonFilter); // To append more data
    }
    if (companyFilters.companyClientFilter) {
      companyFilters.companyClientFilter.forEach(client => {
          query.append("client_drf", client)
        })
    }
    if (companyFilters.companyPageSizeFilter) {
      query.append("page_size", companyFilters.companyPageSizeFilter); // To append more data
    }
    if (companyFilters.companySourcesFilter) {
        companyFilters.companySourcesFilter.forEach(source => {
          query.append("source_object", source)
        })
  
    }
    if (companyFilters.companySourceOwnerFilter) {
        companyFilters.companySourceOwnerFilter.forEach(owner => {
          query.append("source_owners", owner)
        })
    }
    if (companyFilters.companyCampaignFilter) {
        companyFilters.companyCampaignFilter.forEach(campaign => {
          query.append("campaign", campaign)
        })
    }
    if (companyFilters.companyTierFilter) {
        companyFilters.companyTierFilter.forEach(tier => {
          query.append("tier_selection", tier)
        })
    }
    if (companyFilters.companyClientFeedbackFilter) {
        companyFilters.companyClientFeedbackFilter.forEach(feedback => {
          query.append("client_feedback", feedback)
        })
    }
    if (companyFilters.companyResearchStatusFilter) {
        companyFilters.companyResearchStatusFilter.forEach(status => {
          query.append("research_statuses", status)
        })
        query.append("research_statuses_lookup", companyFilters.companyResearchStatusExpressionFilter)
    }
    if (companyFilters.companyResearchStatusFilterTwo) {
        companyFilters.companyResearchStatusFilterTwo.forEach(status => {
          query.append("research_statuses_2", status)
        })
        query.append("research_statuses_2_lookup", companyFilters.companyResearchStatusExpressionFilterTwo)
    }
    if (companyFilters.companyBusinessTypeFilter) {
        companyFilters.companyBusinessTypeFilter.forEach(businessType => {
          query.append("business_type", businessType)
        })
    }
    if (companyFilters.companySectorFilter) {
        companyFilters.companySectorFilter.forEach(sector => {
          query.append("sector", sector)
        })
    }
    if (companyFilters.companySubSectorFilter) {
        companyFilters.companySubSectorFilter.forEach(subSector => {
          query.append("sub_sector", subSector)
        })
    }
    if (companyFilters.companyCRMStatusFilter) {
        companyFilters.companyCRMStatusFilter.forEach(status => {
          query.append("crm_status", status)
        })
    }
    if (companyFilters.companyCRMNextActionFilter) {
        companyFilters.companyCRMNextActionFilter.forEach(action => {
          query.append("next_action", action)
        })
    }
    if (companyFilters.companyTaskUserFilter) {
        companyFilters.companyTaskUserFilter.forEach(user => {
          query.append("task_user", user)
        })
    }
    // if (companyFilters.companyDueTimeRangeFilter) {
    //     companyFilters.companyDueTimeRangeFilter.forEach(time => {
    //       query.append("due_date", time)
    //     })
    // }
    if (companyFilters.companyDueDateStartFilter) {
        query.append("due_date_start", companyFilters.companyDueDateStartFilter)
    }
    if (companyFilters.companyDueDateEndFilter) {
        query.append("due_date_end", companyFilters.companyDueDateEndFilter)
    }
    if (companyFilters.companyCollectionTagsFilter) {
      companyFilters.companyCollectionTagsFilter.forEach(collectionTag => {
        query.append("collection_tag", collectionTag)
      })
    }
    if (companyFilters.companyNAICSCodeFilter) {
      companyFilters.companyNAICSCodeFilter.forEach(naics => {
        query.append("naics_code", naics)
      })
    }
    if (companyFilters.companyStateFilter) {
        companyFilters.companyStateFilter.forEach(state => {
            query.append("state", state)
        })
        query.append("state_lookup", companyFilters.companyStateExpressionFilter)
    }
    if (companyFilters.companyEveraxisPillarFilter) {
        companyFilters.companyEveraxisPillarFilter.forEach(everaxisPillar => {
          query.append("everaxis_pillar", everaxisPillar)
        })
    }
    if (companyFilters.companyProductServiceCodeFilter) {
      query.append("product_service_code", companyFilters.companyProductServiceCodeFilter); // To append more data
    }
  
  // (B) CONVERT TO STRING, APPEND TO URL
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      // console.log(value)
      queriesCount = queriesCount + 1;
    }
    // console.log("queriesCount", queriesCount)
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    console.log(url);
    return url
  // (C) WHATEVER COMES NEXT...
  // REDIRECT OR AJAX CALL OR FETCH
  // window.location.href = url;
  })
  
  function jumpToPage(targetPage) {
    loadCompCompanies(fetchFilter.value + `&page=${targetPage}`)
    resetSelectAllClearSelectedCompanies()
  }
  
  function goToFirst(){
    loadCompCompanies(fetchFilter.value + `&page=${1}`)
    resetSelectAllClearSelectedCompanies()
  }
  
  function goToLast(){
    loadCompCompanies(fetchFilter.value + `&page=${totalPages.value}`)
    resetSelectAllClearSelectedCompanies()
  }

  function setShowStatusSummaryPaneTrue() {
    store.dispatch('company/setShowStatusSummaryPaneTrue')
  }

  async function loadCrmStatuses() {
    const token = store.state.auth.token
    const result  = await getCrmStatuses(token)
    crmStatuses.value = result
  }


  async function loadMostRecentStatuses() {
    const filters = fetchFilter.value.replace('companies', '')
    const token = store.state.auth.token
    const result = await getMostRecentCrmStatus(filters, token)
    mostRecentStatuses.value = result.crm_statuses
  }

  //Prevents selected page config from hiding any filters 
  const selectedConfig = computed(() => {
    return store.getters['pageConfigs/showSelectedPageConfig']
  })
  resetFiltersVisiblity(selectedConfig)
  
  setUserPermissions()
  loadCompCompanies()
  loadCampaigns()
  loadCrmStatuses()
  closeCreateCompanyForm()
  setShowStatusSummaryPaneTrue()
  // loadMostRecentStatuses()
  </script>
  
  <style scoped>
  </style>
  
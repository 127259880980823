import researchMutations from './mutations.js';
import researchActions from './actions.js';
import researchGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            showFilterPane: true,
        };

    },
    mutations: researchMutations,
    actions: researchActions,
    getters: researchGetters

};

import axios from 'axios';
import { BASE_URL } from './api';

export async function getSubSectors(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/sub_sectors/get_sub_sectors/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function createNewSubSector(data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/sub_sectors/create_sub_sector/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function deleteSubSector(subSectorID, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/sub_sectors/${subSectorID}/delete_sub_sector/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function updateSubSector(subSectorID, data, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/sub_sectors/${subSectorID}/edit_sub_sector/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
<template>
    <div class="w-full h-full -mt-2.5">
        <div class="h-[55%] w-full border-b border-gray-300">
            <CollectionTable
                :collections="collections"
                :is-loading="isLoading"
                :userId="userId"
                @refetch-collection-tags="getCollections"
            />
        </div>
        <div class="h-[45%]">
            <ManageCollections
                :userId="userId"
                :collections="collections"
                @refetch-collection-tags="getCollections"
            />
        </div>
    </div>
</template>

<script setup>
import {ref, defineProps } from 'vue';
import CollectionTable from './CollectionTable.vue'
import ManageCollections from './ManageCollections.vue'
import { getCollectionTags } from '@/services/CollectionTag';
import { useStore } from 'vuex';


const props = defineProps(['userId'])
const store = useStore()
const collections = ref(null)
const isLoading = ref(false)

async function getCollections () {
    // console.log('getting collections')
    isLoading.value = true
    let token = store.state.auth.token
    let response = await getCollectionTags(token)
    // console.log('collections response,', response)
    collections.value = response
    isLoading.value = false
}

getCollections()
</script>
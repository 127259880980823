<template>
    <base-dblclick-input
        modelType="company"
        :modelId="company.id"
        fieldName="doing_business_as"
        :fieldValue="company.doing_business_as"
        inputType="text"
        @refetch="fetchNewCompanies"
    >
    <base-pill>DBA: </base-pill>
    </base-dblclick-input>
</template>

<script>
  export default {
      name: "doing-business-as-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object,
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
import campaignMutations from './mutations.js';
import campaignActions from './actions.js';
import campaignGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            campaigns: [],
            isLoading: false,
            showFilterPane: true,
        };

    },
    mutations: campaignMutations,
    actions: campaignActions,
    getters: campaignGetters
    // ...mapMutations['companyMutations'],
    // ...mapActions['companyActions'],
    // ...mapGetters['companyGetters'],
};
<template>
  <base-table-row>
    <base-table-cell>
      <div class="flex-col text-black">
        <div>
          {{ approval.company_name }}
        </div>
        <base-button @click="toggleMoreInfo">More Info</base-button>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ approval.supplement_date }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ approval.part_name }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ approval.make }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ approval.model }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ approval.faa_approval_basis }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ fullAddress }}
      </div>
    </base-table-cell>
  </base-table-row>
  <base-table-row v-if="moreInfoSelected" class="border-none">
    <base-table-cell :colspan="7">
      <PMAMoreInfo :approval="approval"></PMAMoreInfo>
    </base-table-cell>
  </base-table-row>
</template>

<script setup>
// imports

import { defineProps, computed, defineEmits, ref, watch } from "vue";
import PMAMoreInfo from "./PMAMoreInfo.vue"

// constants/variables

const props = defineProps({
  approval: Object,
  resetObject: Object,
});
const emit = defineEmits([
  "reset-changed-company-filters",
  "open-edit",
  "delete-client",
  "load-clients",
]);

const moreInfoSelected = ref(false);

// computed values

const fullAddress = computed(() => {
  return (
    props.approval.address +
    ", " +
    props.approval.state +
    " " +
    props.approval.zip
  );
});

// watchers

watch(props.resetObject, () => {
  moreInfoSelected.value = false;
});

// functions

function toggleMoreInfo() {
  moreInfoSelected.value = !moreInfoSelected.value;
}
</script>

<template>
    <div class="fixed border rounded-md z-30 w-3/4" :style="popoverPosition">
        <div class="bg-light-primary px-2 py-1 text-sm w-full block rounded-t-md">
            <slot name="title"></slot>
        </div>
        <div class="w-full text-sm px-2 py-1 bg-white rounded-b-md">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "BasePopoverHover"
}
</script>
<script setup>
import { defineProps, computed } from "vue"; 

const props = defineProps({
    xPosition: Number,
    yPosition: Number,
})

const popoverPosition = computed(function () {
    return `left: ${props.xPosition + 5}px; top: ${props.yPosition}px;`
})


</script>
<template>
    <div>
        <div v-if="hasEntity" class="flex flex-col space-y-1" :class="{'cursor-wait' : loadingCompany}">
            <div class="flex whitespace-nowrap">
                <base-pill>Name: </base-pill>
                {{ contract.sam_gov_entity.company.company_name }}
            </div>
            <div class="flex whitespace-nowrap">
                <base-pill>Aerocle ID: </base-pill>
                <div @click="getCompanyandOpenForm" class="text-accent-primary hover:text-dark-primary hover:cursor-pointer underline">
                    {{ contract.sam_gov_entity.company.id }}
                </div>
            </div>
            <div class="flex whitespace-nowrap">
                <base-pill>CAGE Code: </base-pill>
                {{ contract.sam_gov_entity.company.cage_code }}
            </div>
            <base-modal :is-form-visible="isFormVisible" @close="closeForm">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="company"
              :campaigns="campaigns"
              @close="closeForm"
              :default-tab="defaultTab"
            />
          </template>
        </base-modal>
        </div>
        <div v-else class="flex flex-col space-y-1">
            <div class="flex whitespace-nowrap">
                <base-pill>Name: </base-pill>
                {{ contract.recipient_name }}
            </div>
            <div class="flex whitespace-nowrap">
                <base-pill>Aerocle ID: </base-pill>
                --
            </div>
            <div class="flex whitespace-nowrap">
                <base-pill>CAGE Code: </base-pill>
                {{ contract.cage_code }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'prime-contract-recipient-cell'
}
</script>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import EditCompany from '../EditCompany.vue';
import { getCompanyByID } from '@/services/Company';

const props = defineProps({
    contract: Object,
    campaigns: Array
})

const store = useStore()

const emit = defineEmits(['loading-company'])

const company = ref(null)
const isFormVisible = ref(false)
const loadingCompany = ref(false)
const defaultTab = ref('')
const hasEntity = computed(() => {
    return !!props.contract.sam_gov_entity
})

function closeForm() {
    isFormVisible.value = false
}

async function getCompanyandOpenForm() {
    loadingCompany.value = true
    emit('loading-company', true)
    const token = store.state.auth.token
    const companyId = props.contract.sam_gov_entity.company.id
    const result = await getCompanyByID({token: token, companyId: companyId})
    company.value = result.response.data
    console.log('company', company.value)
    isFormVisible.value = true
    loadingCompany.value = false
    emit('loading-company', false)
    // isFormVisible.value = true
}

</script>
<template>
    <div class="w-full h-full pt-2">
        <base-form class="h-[88%] w-full bg-gray-100 border rounded-md border-gray-300">
            <template #body>
                <div v-if="!verificationInLastYear" class="mx-2.5 my-1 text-sm">
                    No verified acquisitions in last year.
                </div>
                <div v-if="isReorderable" class="mx-2.5   my-1 flex flex-row space-x-2 justify-center text-gray-700 border-b border-gray-300">
                    <div class="w-[50px] pl-1 ">
                        Order:
                    </div>
                    <div class="w-full pl-1 max-w-[1200px]">
                        Acquisition Details:
                    </div>
                </div>
                <div v-if="acquisitions.length > 0" class="mx-2.5">
                    <div v-for="(acquisition, index) in acquisitions" :key="acquisition.id" class="flex flex-row justify-center space-x-2 ">
                        <div v-if="isReorderable" class="w-[50px] ">
                            <input class="mt-2 p-1 py-2.5 text-center border border-gray-200 shadow-md rounded-md w-full" @input.prevent="setAquisitionOrdinal($event, index)" :value="getAcquisitionOrdinal(index, acquisition)" type="number" min="0"/>
                        </div>
                        <AcquisitionPost
                        :acquisition="acquisition"
                        :currentUser="currentUser"
                        :company="company"
                        :recentVerification="verificationInLastYear"
                        @refetch-companies="fetchNewCompanies"
                     />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="rounded-b-md">
                    <div v-if="showPostForm===true" class="bg-white rounded-b-md -my-2">
                        <AcquisitionPostForm
                            :company="company"
                            :maxOrdinal="maxOrdinal"
                            @refetch-companies="fetchNewCompanies"
                            @close-form="hidePostForm"
                        />
                    </div>
                    <div v-else class="grid grid-cols-3 py-1 bg-white rounded-b-md items-center">
                        <div class="pl-2 flex justify-start items-center" >
                            <div v-if="isReorderable" class="flex space-x-2">
                                <div @click="saveAcquisitionOrder" class="hover:cursor-pointer hover:text-accent-primary">
                                    Save Acquisition Order
                                </div>
                                <div>|</div>
                                <div @click="cancelReorderAcquisitions"  class="hover:cursor-pointer hover:text-accent-primary">
                                    Cancel
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="acquisitions.length > 0"  @click="makeReorderable" class="hover:cursor-pointer hover:text-accent-primary">
                                    Reorder Acquisitions
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-center items-center">
                            <base-button  @click="createAcquisitionPost">Add Acquisition</base-button>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </template>
        </base-form>
    </div>
</template>


<script setup>
    import {ref, computed, defineProps, defineEmits, watch } from "vue";
    import { useStore } from "vuex";
    import { useToast } from 'vue-toast-notification';
    // import { getParentsByCompany } from "@/services/Company"
    // import { getSubsidiariesByCompany } from "@/services/Company";
    import AcquisitionPostForm from "./AcquisitionPostForm.vue";
    import AcquisitionPost from "./AcquisitionPost.vue";
    import { updateCompanyParentOrder } from '@/services/CompanyParentSubsidiary';
import ActionUserCell from "../crm-action-cell-components/ActionUserCell.vue";

    const store = useStore()
    const $toast = useToast()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    const isReorderable = ref(false)
    const showPostForm = ref('false')
    const acquisitions = ref(props.company.parents ? props.company.parents : [])

    watch(() => props.company, () => {
        if(props.company.parents.length > 0 ) {
            acquisitions.value = props.company.parents.map((parent) => {
                return parent
            })
        }
    })
    
    const currentUser = computed(() => {
        return store.getters['auth/showUser']
    })

    const maxOrdinal = computed(() => {
        if(acquisitions.value.length > 0) {
            return acquisitions.value.length + 1
        } else {
            return 1
        }
    })

    const verificationInLastYear = computed(() => {
        if(acquisitions.value.length > 0) {
            let mostRecentVerification = acquisitions.value[0].verified_at
            for(let acquisition of acquisitions.value) {
                if(acquisition.verified_at > mostRecentVerification) {
                    mostRecentVerification = acquisition.verified_at
                }
            }
            mostRecentVerification = new Date(mostRecentVerification)
            // console.log(mostRecentVerification)
            const oneYearAgo = new Date().setDate(new Date().getDate() - 365)
            if(mostRecentVerification > oneYearAgo) {
                return true
            } else {
                return false
            }
        } else {
            return null
        }
    })
   
    function createAcquisitionPost(){
        showPostForm.value = true
    }

    function hidePostForm() {
        showPostForm.value = false
    }

    function makeReorderable() {
        isReorderable.value = true
    }

    function cancelReorderAcquisitions() {
        isReorderable.value = false
    }

    function fetchNewCompanies () {
        emit('refetch-companies')
    }

    function getAcquisitionOrdinal (index, acquisition) {
        acquisition.ordinal = index + 1
        return (index + 1)
    }
    
    function setAquisitionOrdinal(event, index) {
        acquisitions.value[index].ordinal = event.target.value
    }

    async function saveAcquisitionOrder() {
        // console.log('save acquisition order')
        const token = store.state.auth.token
        let data = []
        for(let acquisition of acquisitions.value) {
            data.push({
                id: acquisition.id,
                ordinal: acquisition.ordinal
            })
        }
        let result = await updateCompanyParentOrder(data, props.company.id, token)
        if(result.status==200) {
            $toast.open({
                message: `Successfully updated aquisition order for ${props.company.company_name} (id-${props.company.id}).`,
                type: 'success',
                duration: 5000
            })
            fetchNewCompanies()
            isReorderable.value = false;
        } else {
        $toast.open({
            message: `Error updating aquisition order for ${props.company.company_name} (id-${props.company.id}).`,
            type: 'error',
            duration: 20000
        })
    }

    }

</script>
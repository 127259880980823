<template>
    <span class="border bg-gray-100 border-dark-primary whitespace-nowrap rounded-l-full rounded-r-full w-fit px-1.5 mr-0.5 text-center text-xs">
        <slot></slot>
    </span>
</template>

<script>
    export default{
        name: "base-pill"
    }
</script>
<template>
    <div class="w-full h-full">
        <base-form class="w-full h-full">
            <template #body>
                <div class="grid grid-cols-5 w-full gap-2 mb-1 p-1 border-b text-xs">
                    <div class="flex flex-col col-span-2">
                        <label>Head</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="trainingDocumentData.head">
                    </div>
                    <div class="flex flex-col">
                        <label>Group</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="trainingDocumentData.group">
                    </div>
                    <div class="flex flex-col">
                        <label>Sub Group</label>
                        <input type="text" class="p-1 border w-full inline" v-model="trainingDocumentData.sub_group">
                    </div>
                    <div class="flex flex-col">
                        <label>Ordinal</label>
                        <input type="number" required class="p-1 border w-full inline" v-model="trainingDocumentData.ordinal">
                    </div>
                    <div class="flex flex-col col-span-5">
                        <label>Body</label>
                        <textarea required class="p-1 border h-full w-full" rows="5" v-model="trainingDocumentData.body"></textarea>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="flex flex-row space-x-2 justify-center w-full col-span-5 my-2">
                    <base-button-affirmative @click="addTrainingDocument">Save</base-button-affirmative>
                    <base-button-negative @click="closeCreate">Cancel</base-button-negative>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useStore } from 'vuex';
import { createNewTrainingDocument } from '@/services/TrainingDocuments.js';

const store = useStore()
const emit = defineEmits(['refetch-training-documents', 'close-create'])

const trainingDocumentData = ref({
    head: null,
    body: null,
    group: null,
    sub_group: null,
    status: null,
    ordinal: null,
})

function closeCreate() {
    emit('close-create')
}

async function addTrainingDocument() {
    const token = store.state.auth.token
    let errorMessage = "Error: Missing "
    if (!trainingDocumentData.value.head || !trainingDocumentData.value.group || !trainingDocumentData.value.ordinal || !trainingDocumentData.value.body) {
        if (!trainingDocumentData.value.head) {
            errorMessage += "head, "
        }
        if (!trainingDocumentData.value.group) {
            errorMessage += "group, "
        }
        if (!trainingDocumentData.value.ordinal) {
            errorMessage += "ordinal, "
        }
        if (!trainingDocumentData.value.body) {
            errorMessage += "body, "
        }
        alert(errorMessage.slice(0, -2))
        return
    }

    trainingDocumentData.value.status = 'active'

    const data = trainingDocumentData.value
    // console.log('document data', data)
    await createNewTrainingDocument(data, token)
    emit('refetch-training-documents')
    emit('close-create')
}
</script>
<template>
  <div>
    <base-button class="whitespace-nowrap" @click="showModal">Add Task</base-button>
    <InfoModal
        :is-modal-visible="isModalVisible"
        @close="closeModal"
    >
      <template #header>
        Add CRM Task
      </template>
      <template #body>
        <base-table class="max-h-96 overflow-y-auto">
              <template #table-header>
                <base-table-header :key="header.id" :class="header.class" :header="header.label" v-for="header in tasksTableHeadersList"> {{
                    header.label
                  }}
                </base-table-header>
              </template>
              <template #table-body>
                <CRMActionRow  :company="company" @refetch-companies="fetchNewCompanies" @close-form="closeModal"/>
            </template>
        </base-table>
      </template>
    </InfoModal>
  </div>

</template>

<script>
    export default {
        name: "add-task-modal-cell"
    }
</script>

<script setup>
import CRMActionRow from '../CRMActionRow.vue';
import { defineProps, defineEmits, ref } from 'vue';
import InfoModal from "../InfoModal.vue"
    const isModalVisible = ref(false)

    const props = defineProps({
        company: Object,
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies () {
        emit('refetch-companies')
    }
    function showModal () {
        isModalVisible.value = true
    }

    function closeModal () {
        isModalVisible.value = false
    } 
    const tasksTableHeadersList = ref([
        {class: "w-3/14", label: 'Action Name'},
        {class: "w-1/14", label: 'Action Due Date'},
        {class: "w-1/14", label: 'Action Status'},
        {class: "w-1/14", label: 'User'},
        {class: "w-1/14", label: 'Action Resolved Date'},
        {class: "w-5/14", label: 'Action Notes'},
        {class: "w-2/14", label: 'Action Details'},
        {class: "w-1/14", label: 'Save/Cancel'},
    ])

</script>
<template>
    <div>
        <RevenueHeadcountCell :company="company" />
        <SquareFootageCell :company="company" />
        <ZiRevenueHeadcountCell :company="company" @refetch-companies="fetchNewCompanies" />
        <ZiIdCell class="items-start" :company="company" @refetch-companies="fetchNewCompanies" />
        <GetZiIdButtonCell :company="company" @refetch-companies="fetchNewCompanies" />
        <GetZiBasicButtonCell :company="company" @refetch-companies="fetchNewCompanies" />
        <ClearZiButtonCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>
</template>

<script>
    export default {
        name: "revenue-headcount-square-footage-ziRevenue-ziHeadcount-ziId-getZiBasic-clearZi-cell"
    }
</script>

<script setup>
import RevenueHeadcountCell from '../company-cell-components/RevenueHeadcountCell.vue';
import SquareFootageCell from '../company-cell-components/SquareFootageCell.vue';
import ZiRevenueHeadcountCell from '../company-cell-components/ZiRevenueHeadcountCell.vue';
import ZiIdCell from "../company-cell-components/ZiIdCell.vue";
import GetZiIdButtonCell from "../company-cell-components/GetZiIdButtonCell.vue";
import GetZiBasicButtonCell from "../company-cell-components/GetZiBasicButtonCell.vue";
import ClearZiButtonCell from "../company-cell-components/ClearZiButtonCell.vue";
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }


</script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getCompanyActivities(payload){
  // console.log(payload.companyId);
  return axios({
      method: "get",
      url: `${BASE_URL}/companies/${payload.companyId}/get_company_activities/`,
      headers: { 
          'Authorization': `Token ${payload.token}`, 
          'Content-Type': 'application/json'
      },
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

export async function createActivity(payload){
  // console.log(payload.companyId);
  return axios({
      method: "post",
      url: `${BASE_URL}/activities/create_new_activity/`,
      headers: { 
          'Authorization': `Token ${payload.token}`, 
          'Content-Type': 'application/json'
      },
      data: payload.activity
  }).then((response) => {
    //   console.log("RESPONSE")
      return response
  }).catch((error) => {
    //   console.log("ERROR")
      throw error
    //   return error.response
  })
}

export async function updateActivity(payload){
//   console.log(payload.activity);
  return axios({
      method: 'put',
      url: `${BASE_URL}/activities/${payload.activityId}/edit_activity/`,
      headers: { 
          'Authorization': `Token ${payload.token}`, 
          'Content-Type': 'application/json'
      },
      data: payload.activity
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

export async function deleteActivity(payload){
  // console.log(payload.companyId);
  return axios({
      method: "delete",
      url: `${BASE_URL}/activities/${payload.activityId}/`,
      headers: { 
          'Authorization': `Token ${payload.token}`, 
          'Content-Type': 'application/json'
      },
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}
<template>
    <div class="text-dark-primary flex">
        <div class="flex flex-row items-center w-full justify-between mb-2">
            <p class="hover:cursor-pointer hover:underline hover:text-accent-primary" @click="showAllPanes">Show All</p>
            <p class="hover:cursor-pointer hover:underline hover:text-accent-primary" @click="hideAllPanes">Hide All</p>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { defineProps } from 'vue';

const props = defineProps({
    module: String
});
const store = useStore()

const showAllPanes = () => {
    store.dispatch(`${props.module}/setShowFilterPaneTrue`);

    if (props.module === 'company') {
        store.dispatch(`${props.module}/setShowBulkOperationsPaneTrue`);
        store.dispatch(`${props.module}/setShowStatusSummaryPaneTrue`);
        store.dispatch(`${props.module}/setShowBasicFiltersTrue`);
        store.dispatch(`${props.module}/setShowTableFiltersTrue`);
    } 
    else if (props.module === 'contact') {
        store.dispatch(`${props.module}/setShowBasicCompanyFiltersTrue`);
        store.dispatch(`${props.module}/setShowBasicContactFiltersTrue`);   
    } 
    else if (props.module === 'timecards') {
        store.dispatch(`${props.module}/setShowActionsPaneTrue`);
        store.dispatch(`${props.module}/setShowTimecardUsersTrue`);
    }
}

const hideAllPanes = () => {
    store.dispatch(`${props.module}/setShowFilterPaneFalse`);

    if (props.module === 'company') {
        store.dispatch(`${props.module}/setShowBulkOperationsPaneFalse`);
        store.dispatch(`${props.module}/setShowStatusSummaryPaneFalse`);
        store.dispatch(`${props.module}/setShowBasicFiltersFalse`);
        store.dispatch(`${props.module}/setShowTableFiltersFalse`);
    }  
    else if (props.module === 'contact') {
        store.dispatch(`${props.module}/setShowBasicCompanyFiltersFalse`);
        store.dispatch(`${props.module}/setShowBasicContactFiltersFalse`);  
    }
    else if (props.module === 'timecards') {
        store.dispatch(`${props.module}/setShowActionsPaneFalse`);
        store.dispatch(`${props.module}/setShowTimecardUsersFalse`);
    }
}

</script>
<template>
    <div v-for="governingSpecification in governingSpecifications" :key="governingSpecification.id">
        <a target='_blank' :href="`${governingSpecification.document_url}`"><span class="font-bold text-accent-primary hover:text-dark-primary">{{ governingSpecification.qpl_number }}</span></a> {{ governingSpecification.title }}
    </div>

</template>

<script>
    export default {
        name: "dla-governing-specification-cell"
    }
</script>

<script setup>
    import { defineProps, ref, computed } from 'vue';

    const props = defineProps({
        governingSpecifications: Array
    })

    // const governingSpecificationList = ref([])

    // const governingSpecifications = computed(function() {
    //     props.dlaCompanies.forEach(dlaCompany => {
    //         let governingSpecs = dlaCompany.governing_specifications;
    //         governingSpecs.forEach(governingSpec => {
    //             console.log(dlaCompany.id, governingSpec)
    //             if(!governingSpecificationList.value.includes(governingSpec)) {
    //                 governingSpecificationList.value.push(governingSpec);
    //             }
    //         });
    //     });
    //     console.log(governingSpecificationList)
    //     return governingSpecificationList.value
    // })


</script>
<template>
    <form @submit.prevent class="h-full w-full">
        <select
            class="border border-gray-400 p-2 block rounded-sm w-full size-fit break-normal h-full" 
            v-on:change="handleInputChange"
            :value="inputText"
            name="inputName"
            id="optionSelect"
        >
        <option v-for="option in fieldOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
    </form>
</template>

<script setup>
import { updateCompany } from '@/services/Company';
import { updateContact } from '@/services/Contact';
import { updateCompanyContact } from '@/services/CompanyContact';
import { updateContactCompanyRelationship } from '@/services/Contact';
import { updateContactResearchNote } from '@/services/ContactResearchNote';
import { updateCollectionTag } from '@/services/CollectionTag';
import { updateBusinessType } from '@/services/BusinessType';
import { updateSector } from '@/services/Sector';
import { updateSubSector } from '@/services/SubSector';
import { updateServiceRecord } from '@/services/ServiceRecord';
import { defineProps, defineEmits, reactive } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

// v-on:keyup.enter="handleInputChange"

const store = useStore()
const $toast = useToast()

const props = defineProps([
    'inputText',
    'modelId',
    'fieldName',
    'fieldOptions',
    'modelType'
])

const emit = defineEmits([
    'toggle-editable',
    'refetch',
    'close-modal',
    'update-field-label'
])

const modelUpdateMethodMapping = reactive({
    company: updateCompany.bind(null),
    contact: updateContact.bind(null),
    companyContact: updateCompanyContact.bind(null),
    contactCompanyRelationship: updateContactCompanyRelationship.bind(null),
    contactResearchNote: updateContactResearchNote.bind(null),
    collection: updateCollectionTag.bind(null),
    businessType: updateBusinessType.bind(null),
    sector: updateSector.bind(null),
    subSector: updateSubSector.bind(null),
    serviceRecord: updateServiceRecord.bind(null),
})

async function handleInputChange(e) {
    if(props.modelType) {
        let newValue = e.target.value
        console.log('e target')
        console.log(e.target)
        let newLabel = document.getElementById('optionSelect').selectedOptions[0].textContent
        const targetField = props.fieldName
        let updatedData = {}
        updatedData[targetField] = newValue
        const data = JSON.stringify(updatedData);
        console.log('data', data)
        let token = store.state.auth.token
        console.log('company id', props.modelId)
        console.log('new name', newValue)
        let result = null
        if(props.modelType in modelUpdateMethodMapping) {
            const modelUpdateMethod = modelUpdateMethodMapping[props.modelType]
            result = await modelUpdateMethod(props.modelId, data, token)
        } else {
            console.log('BaseEditSelect: modelType not recognized.')
            $toast.open({
                message: `model name not recognized.`,
                type: 'error',
                duration: 20000
            })
            return
        }
        if(result.status == 200) {
            $toast.open({
                message: `Successfully updated ${props.fieldName} for ${props.modelType} (id-${props.modelId}).`,
                type: 'success',
                duration: 5000
            })
            emit('toggle-editable')
            emit('refetch')
            emit('close-modal')
            if(!newValue) {
                emit('update-field-label', '--')
            } else {
                emit('update-field-label', newLabel)
            }
        } else {
            console.log('result')
            console.log(result)
            console.log('data')
            console.log(result.response.data)
            let errorMessage = result.response.data[props.fieldName][0]
            $toast.open({
                message: `Unable to update ${props.fieldName} for ${props.modelType} (id-${props.modelId}). \n ${errorMessage}`,
                type: 'error',
                duration: 20000
            })
        }
    } else {
        console.log('BaseEditSelect: No modelType defined')
        $toast.open({
            message: `No model selected.`,
            type: 'error',
            duration: 20000
        })
    }
}
</script>
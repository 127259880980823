<template>
    <span
        class="border bg-gray-100 border-dark-primary whitespace-nowrap rounded-l-full rounded-r-full w-fit px-1.5 text-center text-xs hover:cursor-help"
        @mouseover="showPopover"
        @mouseleave="hidePopover"
        ref="pillElement"
    >
        <slot></slot>
        <base-popover-hover-small
            v-if="isPopoverVisible"
            :xPosition="xPosition"
            :yPosition="yPosition"
        >
        <template v-slot:title>
            {{ popoverTitle }}
        </template>
        <template v-slot:body>
            {{ popoverBody }}
        </template>
        </base-popover-hover-small>
    </span>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps(['popoverTitle', 'popoverBody'])

const xPosition = ref(0)
const yPosition = ref(0)
const isPopoverVisible = ref(false)
const pillElement = ref(null)


function showPopover() {
    isPopoverVisible.value = true;
    xPosition.value = pillElement.value.getBoundingClientRect().right
    yPosition.value = pillElement.value.getBoundingClientRect().top
}

function hidePopover() {
    isPopoverVisible.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}



</script>
<template>
    <div>
        {{ daysUntilDue }}
    </div>
</template>

<script>
    export default {
        name: "days-until-next-action-cell"
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const daysUntilDue = computed(()=>{
        if(props.company.crm_next_action_due_date){
            const now = new Date()
            const dueDate = new Date(props.company.crm_next_action_due_date)
            const diffTime = dueDate - now;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays 
        } else {
            return "N/A"
        }
    })

</script>
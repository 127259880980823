<template>
    <div class="w-full flex bg-gray-50"
        @mouseenter="showInsert"
        @mouseleave="hideInsert"
    >
        <div class="w-12 flex justify-end items-center px-2 cursor-pointer"
            @click="parseSelectedToRow"
                    @mouseenter="setInsertDark"
                    @mouseleave="setInsertLight"
        >
            <div v-if="visibleInsert" class="h-6 w-6 rounded-full flex justify-center items-center"
            :style="insertStyle"
            >
                <InsertSVG
                />
            </div>
        </div>
        <div 
            class="w-full grid grid-cols-12"
        >
            <div class="w-full col-span-2  border-x border-b border-gray-300">
                <select v-model="actionRow.action_id" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                >
                    <option v-for="option in crmActionTypeOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="w-full border-r col-span-1 border-b border-gray-300">
                <input type="date" 
                    v-model="actionRow.resolved_date" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                />
            </div>
            <div class="w-full border-r border-b col-span-2 border-gray-300">
                <select v-model="actionRow.user_id" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                >
                    <option v-for="option in userOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="w-full border-r border-b col-span-3 border-gray-300"
                    :style="cellStyle"
            >
                <textarea
                    v-model="actionRow.action_notes" 
                    class="px-2 py-1 w-full h-full overflow-hidden"
                    rows="1"
                    ref="actionNotes"
                    :style="cellStyle"
                ></textarea>
            </div>
            <div class="w-full border-r border-b col-span-2 border-gray-300"
                :style="cellStyle"
            >
                <textarea
                    v-model="actionRow.action_details" 
                    class="px-2 py-1 w-full h-full overflow-hidden"
                    rows="1"
                    ref="actionDetails"
                    :style="cellStyle"
                ></textarea>
            </div>
            <div class="w-full border-r border-b col-span-2 border-gray-300"
                :style="cellStyle"
            >
            <select v-model="actionRow.campaign_id" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                >
                    <option v-for="option in campaignOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
        </div>
        <div class="w-12 flex justify-end items-center px-3">
            <button class="bg-dark-secondary text-white hover:bg-dark-primary w-5 h-5 rounded-full"
                @click="removeRow"
            >
                -
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, reactive, computed, watch, onMounted, onUpdated } from 'vue';
import InsertSVG from '../svg-components/InsertSVG.vue';

const props = defineProps(['crmAction', 'type', 'crmActionTypeOptions', 'userOptions', 'campaignOptions', 'campaignId'])

const emit = defineEmits(['remove-action-row', 'update-action-row', 'parse-selected-to-row'])

const actionNotes = ref(null)

const visibleInsert = ref(false)
const insertStyle = ref('')

const actionDetails = ref(null)

const actionOrderMappingDict = ref({
    2: 0,
    3: 1,
    4: 2,
    21: 3,
    1: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    22: 9,
    9: 10,
    10: 11,
    11: 12,
    12: 13,
    13: 14,
    14: 15,
    15: 16,
    16: 17,
    17: 18,
    18: 19,
    20: 20,
    19: 21,
})


const actionRow = reactive({
    id: props.crmAction ? props.crmAction.id : null,
    // action_name: props.crmAction ? props.crmAction.action_name : null,
    action_id: props.crmAction ? props.crmAction.action ? props.crmAction.action.id : null : null,
    resolved_date: props.crmAction ? props.crmAction.resolved_date : null,
    user_id: props.crmAction ? props.crmAction.user ? props.crmAction.user.id : null : null,
    action_notes: props.crmAction ? props.crmAction.action_notes : null,
    details: props.crmAction ? props.crmAction.action_details : null,
    action_order: props.crmAction ? props.crmAction.action_order : null,
    type: props.crmAction ? props.crmAction.type : null,
    campaign_id: props.crmAction ? props.crmAction.campaign ? props.crmAction.campaign.id : props.campaignId ? props.campaignId : null : props.campaignId ? props.campaignId : null,
})


watch(props.crmAction, () => {
    validateRow(props.crmAction)
    if(props.crmAction) {
        // actionRow.action_name = props.crmAction.action_name
        if(props.crmAction.action_id) {
            actionRow.action_id = props.crmAction.action_id
        } else if (props.crmAction.action) {
            actionRow.action_id = props.crmAction.action.id
        }
        else {
            actionRow.action_id = null
        }
        if(props.crmAction.user_id) {
            actionRow.user_id = props.crmAction.user_id
        } else {
            if(props.crmAction.user) {
                actionRow.user_id = props.crmAction.user.id
            }
            else {
                actionRow.user_id = null
            }
        }
        actionRow.resolved_date = props.crmAction.resolved_date
        actionRow.action_notes = props.crmAction.action_notes
        actionRow.action_details = props.crmAction.action_details
        actionRow.action_order = props.crmAction.action_order
        actionRow.type = props.crmAction.type
    } else {
        // actionRow.action_name = null
        actionRow.action_id = null
        actionRow.resolved_date = null
        actionRow.user_id = null
        actionRow.action_notes = null
        actionRow.action_details = null
        actionRow.action_order = null
        actionRow.type = null
    }

}, {deep: true})

watch(actionRow, ()=> {
    console.log('actionRow Watcher Fired')
    updateRow(actionRow)
})

const defaultCellStyle = computed(() => {
    if(props.crmAction) {
        if(props.crmAction.type) {
            if(props.crmAction.type == 1) {
                return 'background-color: #A7F3D0;'
            } else if(props.crmAction.type == 3) {
                return 'background-color: #FEF3C7;'
            } else if(props.crmAction.type == 0) {
                return 'background-color: #F87171;'
            } else {
                return 'background-color: #BAE6FD;'
            }
        }
    }
    return ''
})

const cellStyle = ref(defaultCellStyle.value)

function resize() {
    actionDetails.value.style.height = actionDetails.value.style.scrollHeight + 'px';
    actionNotes.value.style.height = actionNotes.value.scrollHeight + 'px';

}

onMounted(() => {
    validateRow(props.crmAction)
    resize()
})

onUpdated(() => {
    // validateRow(props.crmAction)
    resize()
})


// const CRMActionTypeOptions = ref(
//     [
//         {label: 'Email Sent', value: 'Email Sent'},
//         {label: 'Email 1', value: 'Email 1'},
//         {label: 'Email 2', value: 'Email 2'},
//         {label: 'Email 3', value: 'Email 3'},
//         {label: 'Email 4', value: 'Email 4'},
//         {label: 'Cold Call Attempt', value: 'Cold Call Attempt'},
//         {label: 'Cold Call Success', value: 'Cold Call Success'},
//         {label: 'LinkedIn DM', value: 'LinkedIn DM'},
//         {label: 'Initial Response', value: 'Initial Response'},
//         {label: 'Additional Response / Target Reached Back Out', value: 'Additional Response / Target Reached Back Out'},
//         {label: 'Shedd Call/Zoom', value: 'Shedd Call/Zoom'},
//         {label: 'Client Call/Zoom', value: 'Client Call/Zoom'},
//         {label: 'In-Person Client Meeting / Facility Tour', value: 'In-Person Client Meeting / Facility Tour'},
//         {label: 'NDA Sent for Review', value: 'NDA Sent for Review'},
//         {label: 'NDA Executed', value: 'NDA Executed'},
//         {label: 'Preliminary Info Request Sent', value: 'Preliminary Info Request Sent'},
//         {label: 'IOI Submitted', value: 'IOI Submitted'},
//         {label: 'LOI Submitted', value: 'LOI Submitted'},
//         {label: 'LOI Executed', value: 'LOI Executed'},
//         {label: 'Transaction Closed', value: 'Transaction Closed'},
//         {label: 'Client / Target Passed', value: 'Client / Target Passed'},
//         {label: 'Other', value: 'Other'},
//     ]
// )

// const userOptions = ref(
//     [
//         {label: 'None', value: null},
//         {label: 'Brandon Ahearn', value: 'Brandon Ahearn'},
//         {label: 'Madison Nguyen', value: 'Madison Nguyen'},
//         {label: 'Matthew Strickler', value: 'Matthew Strickler'},
//         {label: 'Scott Shedd', value: 'Scott Shedd'},
//         {label: 'Suzanne Strickler', value: 'Suzanne Strickler'},
//     ]
// )

function validateRow(row) {
    console.log('validate row')
    // console.log(row)
    // if(!row.action_name) {
    if(!row.action_id){
        console.log('no action id')
        cellStyle.value = 'background-color: #F87171;'
        row.type = 0
    } else {
        if(row.user_id || row.action_notes || row.action_details) {
            if(!row.resolved_date) {
                console.log('no due date')
                cellStyle.value = 'background-color: #F87171;'
                row.type = 0
                row.action_order = actionOrderMappingDict.value[row.action_id]
            } else {
                if(row.type == 1) {
                    cellStyle.value = 'background-color: #A7F3D0'
                } else {
                    cellStyle.value = 'background-color: #BAE6FD;'
                    row.type = 2
                    row.action_order = actionOrderMappingDict.value[row.action_id]
                }
            }
        } else if(row.resolved_date) {
            if(row.type == 1){
                cellStyle.value = 'background-color: #A7F3D0;'
            } else {
                cellStyle.value = 'background-color: #BAE6FD;'
                row.type = 2
                row.action_order = actionOrderMappingDict.value[row.action_id]
            }
        } else {
            cellStyle.value = 'background-color: #FEF3C7;'
            row.type = 3
            row.action_order = actionOrderMappingDict.value[row.action_id]
        }
    }
}

function showInsert() {
    visibleInsert.value = true
}

function hideInsert() {
    visibleInsert.value = false
}

function setInsertLight() {
    insertStyle.value = 'background-color: #EEF2FF;'
}

function setInsertDark() {
   insertStyle.value = 'background-color: #D1D5DB'
}

function removeRow() {
    emit('remove-action-row', actionRow.id)
}

function updateRow(rowData) {
    console.log('CRMActionRow: updateRow (emit)')
    emit('update-action-row', rowData)
}

async function parseSelectedToRow() {
    emit('parse-selected-to-row', actionRow.id)
}

</script>
export default {
    // setToken(state, token) {
    //     state.token = token
    // },
    // setUsername(state, username) {
    //     state.username = username
    // },
    // setExpiry(state, expiry) {
    //     state.expiry = expiry
    // }
    setUser(state, payload){
        state.token = payload.token
        state.user = payload.user,
        state.userGroups = payload.userGroups
        state.userPermissions = payload.userPermissions
        state.expiry = payload.expiry
        state.userFullName = payload.userFullName
    }
}
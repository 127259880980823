<template>
    <div class="w-full h-full">
        <base-form class="w-full h-full">
            <template #body>
                <div class="grid grid-cols-5 w-full gap-2 mb-1 p-1 text-xs">
                    <div class="flex flex-col">
                        <label>First Name</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="contactData.first_name">
                    </div>
                    <div class="flex flex-col">
                        <label>Last Name</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="contactData.last_name">
                    </div>
                    <div class="flex flex-col">
                        <label>Personal Email</label>
                        <input type="email" class="p-1 border" v-model="contactData.personal_email">
                    </div>
                    <div class="flex flex-col">
                        <label>Personal Phone</label>
                        <input type="email" class="p-1 border" v-model="contactData.personal_phone">
                    </div>
                    <div class="flex flex-col">
                        <label>LinkedIn Profile</label>
                        <input inputType="url" class="p-1 border" v-model="contactData.linkedin_profile">
                    </div>
                    <div class="flex flex-col col-span-5">
                        <label>Personal Notes</label>
                        <textarea class="p-1 border h-full w-full" v-model="contactData.personal_notes"></textarea>
                    </div>
                    <!-- <div class="flex justify-center w-full">
                        <div class="border border-dotted border-light-primary w-fit"></div>
                        </div> -->

            </div>
            </template>
            <template #footer>
                <div class="flex flex-row space-x-2 justify-center w-full col-span-5 my-2">
                        <base-button-affirmative @click="addContact">Save</base-button-affirmative>
                        <base-button-negative @click="closeForm">Cancel</base-button-negative>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script>
    export default {
        name: "new-contact-form"
    }
</script>

<script setup>
import { ref, defineEmits } from 'vue';
import { createNewContact } from '@/services/Contact';
import { useStore } from 'vuex';

    const store = useStore()

    const emit = defineEmits(['refetch-contacts', 'close-form'])


    const contactData = ref({
        first_name: null,
        last_name: null,
        personal_phone: null,
        personal_email: null,
        linkedin_profile: null,
        personal_notes: null,
    })

    // const companyRelationshipData = ref({
    //     email_address: null,
    //     contact_notes: null,
    //     title: null,
    //     phone_number: null,
    //     is_primary_contact: false,
    //     status: "active",
    //     company_id: props.company.id
    // })

    function closeForm(){
        emit('close-form')
    }

    async function addContact(){
        const token = store.state.auth.token
        let errorMessage = "Error, missing "
        if(!contactData.value.first_name || !contactData.value.last_name){
            if(!contactData.value.action_name){
                errorMessage += "first name "
            }
            if(!contactData.value.last_name){
                errorMessage += "last name "
            }
            alert(errorMessage)
            return
        }
        const data = contactData.value
        console.log('form data', data)
        await createNewContact(data, token)
        emit('refetch-contacts')
        emit('close-form')
    }


</script>
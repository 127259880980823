<template>
    <div class="text-sm px-1 pb-1 flex flex-row justify-between items-center border-b border-gray-100 print:hidden">
      <p class="inline">Company Filter:</p>
      <div class="flex">
        <base-button-affirmative @click="resetFilters" class="text-xs -py-2 mr-1">Reset</base-button-affirmative>
        <base-button-affirmative v-if="!splitPage" @click="emitSplitPage" class="ml-1">QPL Search</base-button-affirmative>
      </div>
    </div>
    <div class="pt-2 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 print:hidden">
      <base-filter-no-button :active="activeFilters.includes('Company')">
        <template v-slot:label>Company</template>
        <template v-slot:widget><input @input="setCompanyNameFilter" v-model="companyNameFilter" class="h-full px-2 w-full rounded-b-md shadow-md" type="text"></template>
      </base-filter-no-button>
      <base-filter-no-button :active="activeFilters.includes('Address')">
        <template v-slot:label>
          Address Search
        </template>
        <template v-slot:widget>
          <input v-model="companyAddressFilter" @input="setCompanyAddressFilter" class="h-full px-2 w-full rounded-b-md shadow-md" type="text">
        </template>
      </base-filter-no-button>
      <base-filter-no-button @click="setCampaignOptions" :active="activeFilters.includes('Campaign')">
        <template v-slot:label>
          Campaign
        </template>
        <template v-slot:widget>
          <base-list-box
            class="w-full rounded-b-md"
            placeholder="Select Campaign"
            :options="campaignOptions"
            :modelValue="companyCampaignFilter"
            :multiple="true"
            @update:modelValue="setCompanyCampaignFilter"
          />
        </template>
      </base-filter-no-button>
      <base-filter-no-button @click="setTierOptions" :active="activeFilters.includes('Tier')">
        <template v-slot:label>
          Tier
        </template>
        <template v-slot:widget>
          <base-list-box
            class="w-full rounded-b-md"
            placeholder="Select Tier"
            :options="tierOptions"
            :modelValue="companyTierFilter"
            :multiple="true"
            @update:modelValue="setCompanyTierFilter"
          />
        </template>
      </base-filter-no-button>
      <base-filter-no-button @click="setSourceOptions" :active="activeFilters.includes('Sources')">
        <template v-slot:label>
          Sources
        </template>
        <template v-slot:widget>
          <base-list-box
              class="w-full rounded-b-md"
              placeholder="Source"
              v-model="companySourcesFilter"
              :options="sourceOptions"
              @update:modelValue="setCompanySourcesFilter"
              :multiple="true"
              />
        </template>
      </base-filter-no-button>
      <base-filter-no-button :active="activeFilters.includes('Min Revenue')">
        <template v-slot:label>Min Revenue</template>
        <template v-slot:widget><input @input="setCompanyMinRevenueFilter" v-model="companyMinRevenueFilter" class="h-full px-2 w-full rounded-b-md shadow-md" type="number"></template>
      </base-filter-no-button>
      <base-filter-no-button :active="activeFilters.includes('Max Revenue')">
        <template v-slot:label>Max Revenue</template>
        <template v-slot:widget><input @input="setCompanyMaxRevenueFilter" v-model="companyMaxRevenueFilter" class="h-full px-2 w-full rounded-b-md shadow-md" type="number"></template>
      </base-filter-no-button>
      <base-filter-no-button @click="setGoverningSpecificationOptions" :active="activeFilters.includes('Governing Specifications')">
        <template v-slot:label>
          Governing Specifications
        </template>
        <template v-slot:widget>
          <base-list-box
              class="w-full rounded-b-md"
              placeholder="Specification"
              v-model="companyGoverningSpecificationFilter"
              :options="governingSpecificationOptions"
              @update:modelValue="setCompanyGoverningSpecificationFilter"
              :multiple="true"
              />
        </template>
      </base-filter-no-button>
      <!-- <base-filter @click="setGovernmentDesignationOptions(fetchGoverningSpecificationFilter)">
        <template v-slot:label>
          Government Designations
        </template>
        <template v-slot:widget>
          <base-list-box
              class="w-full rounded-b-md"
              placeholder="Designation"
              v-model="companyGovernmentDesignationFilter"
              :options="governmentDesignationOptions"
              @update:modelValue="setCompanyGovernmentDesignationFilter"
              :multiple="true"
              />
        </template>
    </base-filter> -->
      <!-- <base-filter @click="setCollectionTagOptions">
        <template v-slot:label>
          Collection Tags
        </template>
        <template v-slot:widget>
          <base-list-box
              class="w-full rounded-b-md"
              placeholder="Tag"
              v-model="companyCollectionTagsFilter"
              :options="collectionTagOptions"
              @update:modelValue="setCompanyCollectionTagsFilter"
              :multiple="true"
              />
        </template>
      </base-filter> -->
    </div>
  </template>
  
  <script>
  import { ref, computed, watch } from 'vue';
//   import BaseFilter from "@/components/BaseFilter";
  import BaseFilterNoButton from "@/components/BaseFilterNoButton";
  import { getSources } from '@/services/Source';
  import { getSourceOwners } from '@/services/SourceOwner';
  import { getCampaigns } from '@/services/Campaign';
  import { getTiers } from '@/services/Tiers';
  import { getClients } from '@/services/Client';
  import {getBusinessTypes} from '@/services/BusinessType'
  import {getSectors} from '@/services/Sector'
  import {getSubSectors} from '@/services/SubSector'
  import store from '@/store';
  import { useRoute } from 'vue-router';
  import { getCollectionTags } from '@/services/CollectionTag';
  import { getDLAGoverningSpecifications } from '@/services/DLAGoverningSpecification';
  import { getDLAGovernmentDesignationsByGoverningSpec } from '@/services/DLAGovernmentDesignation';
  import CompanyFilters from './CompanyFilters.vue';
  
  export default {
    name: "dla-company-filter",
    components: { BaseFilterNoButton },
    emits: ['change-company-filters', 'set-changed-company-filters', 'split-page'],
    props: ['splitPage'],
    setup(props, context) {
      const route = useRoute()
      const routeCampaign = ref(route.params.campaignId)
      // console.log('route param', route.params.campaignId)
      const companyNameFilter = ref('');
      const companyDescriptionFilter = ref('');
      const companyAddressFilter = ref('');
      const companySourcesFilter = ref([])
      const companySourceOwnerFilter = ref([])
      const companyCampaignFilter = ref([])
      const companyTierFilter = ref([])
      const companyClientFeedbackFilter = ref([])
      const companyOnboardingStatusFilter = ref('')
      const companyDNRReasonFilter = ref('')
      const companyResearchStatusFilter = ref([])
      const companyResearchStatusExpressionFilter = ref('')
      const researchStatusExpression = ref('or')
      const companyResearchStatusFilterTwo = ref([])
      const companyResearchStatusExpressionFilterTwo = ref('')
      const researchStatusExpressionTwo = ref('or')
      const companyMinRevenueFilter = ref(null)
      const companyMaxRevenueFilter = ref(null)
      const companyMinRevenueZiCombinedFilter = ref(null)
      const companyMaxRevenueZiCombinedFilter = ref(null)
      const companyClientFilter = ref([])
      const companyBusinessTypeFilter = ref([])
      const companySectorFilter = ref([])
      const companySubSectorFilter = ref([])
      const companyCRMStatusFilter = ref([])
      const companyCRMNextActionFilter = ref([])
      const companyDueTimeRangeFilter = ref([])
      const companyGoverningSpecificationFilter = ref([])
      const companyGovernmentDesignationFilter = ref([])
      const companyStateFilter = ref([])
      const companyStateExpressionFilter = ref('or')
      async function grabCampaign(){
        if(routeCampaign.value){
          await setCampaignOptions()
          companyCampaignFilter.value = [parseInt(routeCampaign.value)]
        }
      }
      const companyCollectionTagsFilter = ref([])
  
      const sourceOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const sourceOwnerOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const campaignOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const tierOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const clientOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const businessTypeOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const sectorOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const subSectorOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const collectionTagOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const clientFeedbackOptions = ref([
        {value: "clear", label: "------------"},
        {value: "approved", label: "Approved"},
        {value: "excluded", label: "Excluded"},
        {value: "hold", label: "Hold"},
        {value: "pass", label: "Pass"},
        {value: "research_further", label: "Research Further"},
      ])
      const stateOptions = ref([
        {value: "clear", label: "------------"},
        {value: "florida", label: "Florida"},
        {value: "texas", label: "Texas"},
      ])
      const onboardingStatusOptions = ref([
        {value: "clear", label: "------------"},
        {value: "imported", label: "Imported"},
        {value: "url_enriched", label: "URL Enriched"},
        {value: "verified_unique", label: "Verified Unique"},
        {value: "research_complete", label: "Research Complete"}
      ])
      const dnrReasonOptions = ref([
        {value: "clear", label: "------------"},
        {value: "Duplicate", label: "Duplicate"},
        {value: "Duplicate Assimilated", label: "Duplicate Assimilated"},
        {value: "Already Known", label: "Already Known"},
        {value: "No Website", label: "No Website"},
        {value: "Address Not Valid", label: "Address Not Valid"},
        {value: "Permanently Closed", label: "Permanently Closed"},
        {value: "Acquired", label: "Acquired"},
        {value: "Public/Large PE Owned", label: "Public/Large PE Owned"},
      ])
      const researchStatusOptions = ref([
        {value: "clear", label: "------------"},
        {value: "research_not_complete", label: "Research Not Complete"},
        {value: "research_complete", label: "Research Complete"},
        {value: "url_found", label: "URLs Found"},
        {value: "urls_missing", label: "URLs Missing"},
        {value: "urls_vetted", label: "URLs Vetted"},
        {value: "marked_no_url", label: "Marked No URL"},
        {value: "urls_found_not_vetted", label: "URLs Found Not Vetted"},
        {value: "urls_not_vetted", label: "URLs Not Vetted"},
        {value: "multiple_zoom_info_ids", label: "Multiple Zoom Info Ids"},
        {value: "no_zoom_info_id", label: "No Zoom Info ID"},
        {value: "one_zoom_info_id", label: "One Zoom Info ID"},
        {value: "revenue_found", label: "Revenue Found"},
        {value: "revenue_missing", label: "Revenue Missing"},
        {value: "do_not_research", label: "Do Not Research"},
        {value: "do_research", label: "Do Research"},
        {value: "further_research_needed", label: "Research Further"},
      ])
  
      const crmStatusOptions = ref([
          {value: "clear", label: "------------"},
          {value: "None", label: 'None'},
          {value: 'Revisit / Cut', label: 'Revisit / Cut'},
          {value: 'Client Pass', label: 'Client Pass'},
          {value: 'Not Contacted', label: 'Not Contacted'},
          {value: 'No Response', label: 'No Response'},
          {value: 'Hard No', label: 'Hard No'},
          {value: 'Responded / Coverage', label: 'Responded / Coverage'},
          {value: 'Active - In Dialogue', label: 'Active - In Dialogue'},
          {value: 'IOI Submitted', label: 'IOI Submitted'},
          {value: 'Under LOI', label: 'Under LOI'},
          {value: 'Research Further', label: 'Research Further'},
          {value: 'Hold', label: 'Hold'},
          {value: 'Closed', label: 'Closed'},
          {value: 'Dead - Acquired by Other Party', label: 'Dead - Acquired by Other Party'}
      ])
  
      const crmNextActionOptions = ref([
          {value: "clear", label: "------------"},
          {value: 'None', label: 'None'},
          {value: 'Determine Next Action', label: 'Determine Next Action'},
          {value: 'Scott to Follow-Up', label: 'Scott to Follow-Up'},
          {value: 'Send Initial Email', label:'Send Initial Email' },
          {value: 'Send Another Email', label: 'Send Another Email'},
          {value: 'Send Email on Acquisition', label: 'Send Email on Acquisition'},
          {value: 'Research Correct Email', label: 'Research Correct Email'},
          {value: 'Call', label: 'Call'},
          {value: 'Schedule Meeting', label: 'Schedule Meeting'},
          {value: 'Wait on Response', label: 'Wait on Response'},
          {value: 'Wait for Client Feedback', label: 'Wait for Client Feedback'},
          {value: 'Meet', label: 'Meet'},
          {value: 'Not Now', label: 'Not Now'},
          {value: 'Leave Alone', label: 'Leave Alone'},
          {value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out'}
      ])
  
      const dueTimeRangeOptions = ref([
        {value: "clear", label: "------------"},
        {value: 'In 2 Weeks', label: 'In 2 Weeks'},
        {value: 'In 1 Month', label: 'In 1 Month'},
        {value: 'Overdue', label: 'Overdue'}
      ]
      )
  
      const people = [
        {value: 1, label: "-Aeromed Consummables- 07-07-2022"},
        {value: 2, label: "All M+A Electronics / Military Embedded"},
        {value: 3, label: "All Master Companies"},
        {value: 4, label: "-Applegate- DACIS 07-11-2022"},
        {value: 5, label: "-ARC Drilling- Additional Targets 12-14-22"},
        {value: 6, label: "AS 9100"},
      ];
      const roles = [
        {value: 1, label: "Andrew Monteith"},
        {value: 2, label: "Augie Ros"},
        {value: 3, label: "Beccas Panos"},
        {value: 4, label: "Caitlin Chen"},
        {value: 5, label: "Daniel Strickler"},
        {value: 6, label: "Emalee Miller"},
        {value: 7, label: "Joel Abraham"},
        {value: 8, label: "Shaan Patel"},
      ];
      const form = ref({
        person_id: null
      });

      const governmentDesignationOptions = ref([
        {value: "clear", label: "------------"}
      ])
      const governingSpecificationOptions = ref([
        {value: "clear", label: "------------"}
      ])

      function emitSplitPage(){
        console.log('emitting split-page')
        context.emit('split-page')
      }
  
      function emitChangeCompanyFiltersAndSetChanged(){
        context.emit('change-company-filters', filters)
        context.emit('set-changed-company-filters')
      }
  
      function setCompanyNameFilter(event) {
        companyNameFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyMinRevenueFilter(event) {
        companyMinRevenueFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyMaxRevenueFilter(event) {
        companyMaxRevenueFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyMinRevenueZiCombinedFilter(event) {
        companyMinRevenueZiCombinedFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyMaxRevenueZiCombinedFilter(event) {
        companyMaxRevenueZiCombinedFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyDescriptionFilter(event) {
        companyDescriptionFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyAddressFilter(event) {
        companyAddressFilter.value = event.target.value;
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyOnboardingStatusFilter(target) {
        emitChangeCompanyFiltersAndSetChanged()
        target == "clear" ? companyOnboardingStatusFilter.value = "" : companyOnboardingStatusFilter.value = target
      }
  
      function setCompanyDNRReasonFilter(target) {
        emitChangeCompanyFiltersAndSetChanged()
        target == "clear" ? companyDNRReasonFilter.value = "" : companyDNRReasonFilter.value = target
      }
    
      function setCompanySourcesFilter(target) {
        if(target.includes("clear")){
          companySourcesFilter.value = []
        } else {
          companySourcesFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
      
      function setCompanyClientFilter(target) {
        if(target.includes("clear")){
          companyClientFilter.value = []
        } else {
          companyClientFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanySourceOwnersFilter(target) {
        if(target.includes("clear")){
          companySourceOwnerFilter.value = []
        } else {
          companySourceOwnerFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyCampaignFilter(target) {
        if(target.includes("clear")){
          companyCampaignFilter.value = []
        } else {
          companyCampaignFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyTierFilter(target) {
        if(target.includes("clear")){
          companyTierFilter.value = []
        } else {
          companyTierFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyBusinessTypeFilter(target) {
        if(target.includes("clear")){
          companyBusinessTypeFilter.value = []
        } else {
          companyBusinessTypeFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanySectorFilter(target) {
        if(target.includes("clear")){
          companySectorFilter.value = []
        } else {
          companySectorFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanySubSectorFilter(target) {
        if(target.includes("clear")){
          companySubSectorFilter.value = []
        } else {
          companySubSectorFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyClientFeedbackFilter(target) {
        if(target.includes("clear")){
          companyClientFeedbackFilter.value = []
        } else {
          companyClientFeedbackFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyResearchStatusFilter(target) {
        if(target.includes("clear")){
          companyResearchStatusFilter.value = []
        } else {
          companyResearchStatusExpressionFilter.value = researchStatusExpression.value
          companyResearchStatusFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setResearchStatusExpression(target) {
        researchStatusExpression.value = target
        if(companyResearchStatusFilter.value.length > 0){
          companyResearchStatusExpressionFilter.value = researchStatusExpression.value
          emitChangeCompanyFiltersAndSetChanged()
        }
      }
  
      function setCompanyResearchStatusFilterTwo(target) {
        if(target.includes("clear")){
          companyResearchStatusFilterTwo.value = []
        } else {
          companyResearchStatusExpressionFilterTwo.value = researchStatusExpressionTwo.value
          companyResearchStatusFilterTwo.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setResearchStatusExpressionTwo(target) {
        researchStatusExpressionTwo.value = target
        if(companyResearchStatusFilterTwo.value.length > 0){
          companyResearchStatusExpressionFilterTwo.value = researchStatusExpressionTwo.value
          emitChangeCompanyFiltersAndSetChanged()
        }
      }
  
      function setCompanyStateFilter(target) {
        if(target.includes("clear")){
          companyStateFilter.value = []
        } else {
          companyStateFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
  
      function setCompanyCollectionTagsFilter(target) {
        if(target.includes("clear")){
          companyCollectionTagsFilter.value = []
        } else {
          companyCollectionTagsFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyCRMStatusFilter(target) {
        if(target.includes("clear")){
          companyCRMStatusFilter.value = []
        } else {
          companyCRMStatusFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyCRMNextActionFilter(target) {
        if(target.includes("clear")){
          companyCRMNextActionFilter.value = []
        } else {
          companyCRMNextActionFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function setCompanyDueTimeRangeFilter(target) {
        if(target.includes("clear")){
          companyDueTimeRangeFilter.value = []
        } else {
          companyDueTimeRangeFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      function resetFilters() {
        companyNameFilter.value = ''
        companyDescriptionFilter.value = ''
        companyAddressFilter.value = ''
        companySourcesFilter.value =[]
        companySourceOwnerFilter.value =[]
        companyCampaignFilter.value =[]
        companyTierFilter.value =[]
        companyClientFeedbackFilter.value =[]
        companyOnboardingStatusFilter.value =''
        companyDNRReasonFilter.value =''
        companyResearchStatusFilter.value =[]
        companyResearchStatusExpressionFilter.value =''
        researchStatusExpression.value ='or'
        companyResearchStatusFilterTwo.value =[]
        companyResearchStatusExpressionFilterTwo.value =''
        researchStatusExpressionTwo.value ='or'
        companyMinRevenueFilter.value = null
        companyMaxRevenueFilter.value = null
        companyMinRevenueZiCombinedFilter.value = null
        companyMaxRevenueZiCombinedFilter.value = null
        companyClientFilter.value = []
        companyBusinessTypeFilter.value = []
        companySectorFilter.value = []
        companySubSectorFilter.value = []
        companyCollectionTagsFilter.value = []
        companyCRMStatusFilter.value = []
        companyCRMNextActionFilter.value = []
        companyDueTimeRangeFilter.value = []
        companyStateFilter.value = []
        companyGovernmentDesignationFilter.value=[]
        companyGoverningSpecificationFilter.value=[]
        emitChangeCompanyFiltersAndSetChanged()
      }
  
      async function setSourceOptions() {
        const token = store.getters['auth/showToken']
        const result = await getSources(token)
        sourceOptions.value = [{value: "clear", label: "------------"}, ...result.map((source) => {
          return {value: source.id, label: source.source_name }
        })]
      }
  
      async function setSourceOwnerOptions() {
        const token = store.getters['auth/showToken']
        const result = await getSourceOwners(token)
        sourceOwnerOptions.value = [{value: "clear", label: "------------"}, {value: "None", label: "None"}, ...result.map((sourceOwner) => {
          return {value: sourceOwner, label: sourceOwner}
        })]
      }
  
      async function setCampaignOptions() {
        const token = store.getters['auth/showToken']
        const result = await getCampaigns(token)
        campaignOptions.value = [{value: "clear", label: "------------"}, {value: "null", label: "Unassigned"}, ...result.map((campaign) => {
          return {value: campaign.id, label: campaign.campaign_name}
        })]
      }
  
      async function setTierOptions() {
        const token = store.getters['auth/showToken']
        const result = await getTiers(token)
        tierOptions.value = [{value: "clear", label: "------------"}, ...result.map((tier) => {
          return {value: tier.id, label: tier.tier_name}
        })]
      }
  
      async function setClientOptions() {
        const token = store.getters['auth/showToken']
        const result = await getClients({token: token})
        clientOptions.value = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
          return {value: client.id, label: client.client_name}
        })]
      }
  
      async function setBusinessTypeOptions() {
        const token = store.getters['auth/showToken']
        const result = await getBusinessTypes(token)
        businessTypeOptions.value = [{value: "clear", label: "------------"}, ...result.map((businessType) => {
          return {value: businessType.id, label: businessType.business_type_name}
        })]
      }
  
      async function setSectorOptions() {
        const token = store.getters['auth/showToken']
        const result = await getSectors(token)
        sectorOptions.value = [{value: "clear", label: "------------"}, ...result.map((sector) => {
          return {value: sector.id, label: sector.sector_name}
        })]
      }
  
      async function setSubSectorOptions() {
        const token = store.getters['auth/showToken']
        const result = await getSubSectors(token)
        subSectorOptions.value = [{value: "clear", label: "------------"}, ...result.map((subSector) => {
          return {value: subSector.id, label: subSector.sub_sector_name}
        })]
      }
  
      async function setCollectionTagOptions() {
        const token = store.getters['auth/showToken']
        const result = await getCollectionTags(token)
        collectionTagOptions.value = [{value: "clear", label: "------------"}, ...result.map((collectionTag) => {
          return {value: collectionTag.id, label: collectionTag.collection_tag_name }
        })]
      }

      function setCompanyGovernmentDesignationFilter(target) {
        if(target.includes("clear")){
          companyGovernmentDesignationFilter.value = []
        } else {
          companyGovernmentDesignationFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
    }

    function setCompanyGoverningSpecificationFilter(target) {
        if(target.includes("clear")){
          companyGoverningSpecificationFilter.value = []
        } else {
          companyGoverningSpecificationFilter.value = target;
        }
        emitChangeCompanyFiltersAndSetChanged()
    }

    async function setGovernmentDesignationOptions(endpoint) {
      console.log('endpoint', endpoint)
      let payload = {
        token: store.getters['auth/showToken'],
        endpoint: endpoint
    }
      const result = await getDLAGovernmentDesignationsByGoverningSpec(payload)
      console.log('result', result)
        governmentDesignationOptions.value = [{value: "clear", label: "------------"}, ...result.map((governmentDesignation) => {
            return {value: governmentDesignation.id, label: `${governmentDesignation.government_designation} (${governmentDesignation.governing_specification.qpl_number})`}
        })]
    }

    async function setGoverningSpecificationOptions() {
      const token = store.getters['auth/showToken']
      const result = await getDLAGoverningSpecifications(token)
        governingSpecificationOptions.value = [{value: "clear", label: "------------"}, ...result.map((governingSpecification) => {
            return {value: governingSpecification.id, label: `${governingSpecification.qpl_number} ${governingSpecification.title}`}
        })]
    }

    const fetchGoverningSpecificationFilter = computed(function () {
      let query = new URLSearchParams({
      });
      if (companyGoverningSpecificationFilter.value) {
        companyGoverningSpecificationFilter.value.forEach(governingSpecification => {
          query.append('governing_specifications', governingSpecification)
        })
      }
      let url;
      let queriesCount = 0;
      for (let value of query.values()) {
        queriesCount = queriesCount + 1;
      }
      if (queriesCount > 0) {
        url = "?" + query.toString();
      } else {
        url = "?";
      }
      console.log('url', url)
      return url
    })

      const filters = computed(() => {
        return {
          companyNameFilter: companyNameFilter,
          companyDescriptionFilter: companyDescriptionFilter,
          companyAddressFilter: companyAddressFilter,
          companySourcesFilter: companySourcesFilter,
          companySourceOwnerFilter: companySourceOwnerFilter,
          companyCampaignFilter: companyCampaignFilter,
          companyTierFilter: companyTierFilter,
          companyClientFeedbackFilter: companyClientFeedbackFilter,
          companyOnboardingStatusFilter: companyOnboardingStatusFilter,
          companyDNRReasonFilter: companyDNRReasonFilter,
          companyResearchStatusFilter:companyResearchStatusFilter,
          companyResearchStatusExpressionFilter:companyResearchStatusExpressionFilter,
          companyResearchStatusFilterTwo:companyResearchStatusFilterTwo,
          companyResearchStatusExpressionFilterTwo:companyResearchStatusExpressionFilterTwo,
          companyMinRevenueFilter:companyMinRevenueFilter,
          companyMaxRevenueFilter:companyMaxRevenueFilter,
          companyMinRevenueZiCombinedFilter:companyMinRevenueZiCombinedFilter,
          companyMaxRevenueZiCombinedFilter:companyMaxRevenueZiCombinedFilter,
          companyClientFilter:companyClientFilter,
          //  companyPageSizeFilter:companyPageSizeFilter,
          companyBusinessTypeFilter:companyBusinessTypeFilter,
          companySectorFilter:companySectorFilter,
          companySubSectorFilter:companySubSectorFilter,
          companyCollectionTagsFilter:companyCollectionTagsFilter,
          companyCRMStatusFilter:companyCRMStatusFilter,
          companyCRMNextActionFilter:companyCRMNextActionFilter,
          companyDueTimeRangeFilter:companyDueTimeRangeFilter,
          companyStateFilter:companyStateFilter,
          companyStateExpressionFilter:companyStateExpressionFilter,
          companyGovernmentDesignationFilter: companyGovernmentDesignationFilter,
          companyGoverningSpecificationFilter: companyGoverningSpecificationFilter,
        }
      })

      const activeFilters = computed(() => {
        let activeFilterList = []
        if(companyNameFilter.value){
          activeFilterList.push("Company")
        }
        if(companyAddressFilter.value){
          activeFilterList.push("Address")
        }
        if(companyCampaignFilter.value.length > 0){
          activeFilterList.push("Campaign")
        }
        if(companyTierFilter.value.length > 0){
          activeFilterList.push("Tier")
        }
        if(companySourcesFilter.value.length > 0){
          activeFilterList.push("Sources")
        }
        if(companyMinRevenueFilter.value){
          activeFilterList.push("Min Revenue")
        }
        if(companyMaxRevenueFilter.value){
          activeFilterList.push("Max Revenue")
        }
        if(companyGoverningSpecificationFilter.value.length > 0){
          activeFilterList.push("Governing Specifications")
        }
        return activeFilterList;
      })
  
      grabCampaign()
  
      return {
        filters,
        activeFilters,
        setCompanyNameFilter: setCompanyNameFilter,
        setCompanyDescriptionFilter: setCompanyDescriptionFilter,
        setCompanyAddressFilter: setCompanyAddressFilter,
        setCompanySourcesFilter: setCompanySourcesFilter,
        setCompanyOnboardingStatusFilter: setCompanyOnboardingStatusFilter,
        setCompanySourceOwnersFilter: setCompanySourceOwnersFilter,
        setCompanyDNRReasonFilter: setCompanyDNRReasonFilter,
        setCompanyResearchStatusFilter: setCompanyResearchStatusFilter,
        setCompanyResearchStatusFilterTwo: setCompanyResearchStatusFilterTwo,
        setCompanyTierFilter: setCompanyTierFilter,
        setCompanyCampaignFilter: setCompanyCampaignFilter,
        setCompanyClientFeedbackFilter: setCompanyClientFeedbackFilter,
        setCompanyMinRevenueFilter: setCompanyMinRevenueFilter,
        setCompanyMaxRevenueFilter: setCompanyMaxRevenueFilter,
        setCompanyMinRevenueZiCombinedFilter: setCompanyMinRevenueZiCombinedFilter,
        setCompanyMaxRevenueZiCombinedFilter: setCompanyMaxRevenueZiCombinedFilter,
        setCompanyClientFilter: setCompanyClientFilter,
        setCompanyBusinessTypeFilter: setCompanyBusinessTypeFilter,
        setCompanySectorFilter: setCompanySectorFilter,
        setCompanySubSectorFilter: setCompanySubSectorFilter,
        setCompanyCollectionTagsFilter: setCompanyCollectionTagsFilter,
        setCompanyCRMStatusFilter: setCompanyCRMStatusFilter,
        setCompanyCRMNextActionFilter: setCompanyCRMNextActionFilter,
        setCompanyDueTimeRangeFilter: setCompanyDueTimeRangeFilter,
        setSourceOptions: setSourceOptions,
        setSourceOwnerOptions: setSourceOwnerOptions,
        setCampaignOptions: setCampaignOptions,
        setTierOptions: setTierOptions,
        setClientOptions: setClientOptions,
        setBusinessTypeOptions: setBusinessTypeOptions,
        setSectorOptions: setSectorOptions,
        setSubSectorOptions: setSubSectorOptions,
        setResearchStatusExpression: setResearchStatusExpression,
        setResearchStatusExpressionTwo: setResearchStatusExpressionTwo,
        setCollectionTagOptions: setCollectionTagOptions,
        setCompanyStateFilter: setCompanyStateFilter,
        setCompanyGovernmentDesignationFilter: setCompanyGovernmentDesignationFilter,
        setCompanyGoverningSpecificationFilter: setCompanyGoverningSpecificationFilter,
        setGovernmentDesignationOptions: setGovernmentDesignationOptions,
        setGoverningSpecificationOptions: setGoverningSpecificationOptions,
        resetFilters: resetFilters,
        emitSplitPage: emitSplitPage,
        fetchGoverningSpecificationFilter,
        people,
        form,
        roles,
        sourceOptions,
        sourceOwnerOptions,
        campaignOptions,
        tierOptions,
        clientFeedbackOptions,
        dnrReasonOptions,
        researchStatusOptions,
        onboardingStatusOptions,
        clientOptions,
        businessTypeOptions,
        sectorOptions,
        subSectorOptions,
        collectionTagOptions,
        crmStatusOptions,
        crmNextActionOptions,
        dueTimeRangeOptions,
        stateOptions,
        governmentDesignationOptions,
        governingSpecificationOptions,
        companySourcesFilter,
        companySourceOwnerFilter,
        companyCampaignFilter,
        companyTierFilter,
        companyClientFeedbackFilter,
        companyOnboardingStatusFilter,
        companyDNRReasonFilter,
        companyResearchStatusFilter,
        companyResearchStatusExpressionFilter,
        researchStatusExpression,
        companyResearchStatusFilterTwo,
        companyResearchStatusExpressionFilterTwo,
        researchStatusExpressionTwo,
        companyNameFilter,
        companyDescriptionFilter,
        companyAddressFilter,
        companyMinRevenueFilter,
        companyMaxRevenueFilter,
        companyMinRevenueZiCombinedFilter,
        companyMaxRevenueZiCombinedFilter,
        companyClientFilter,
        companyCRMStatusFilter,
        companyBusinessTypeFilter,
        companySectorFilter,
        companySubSectorFilter,
        companyCollectionTagsFilter,
        companyCRMNextActionFilter,
        companyDueTimeRangeFilter,
        companyStateFilter,
        companyGovernmentDesignationFilter,
        companyGoverningSpecificationFilter,
      }
    }
  }
  </script>
  
  <style scoped>
    @import "vue-select/dist/vue-select.css";
  </style>


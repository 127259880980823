<template>
  <div class="w-full h-full">
    <!-- GENERAL VIEW -->
    <div v-show="!isCreateVisible" class="ml-2 p-1 mb-5 h-full flex flex-col">
      <div class="border-b border-b-dark-secondary mb-2 flex justify-between p-1 shrink-0">
        <h2 class=" my-auto text-lg">Activities </h2>
        <base-button v-if="canEdit" @click="showCreate">Create Activities</base-button>
      </div>
      <div class="w-full justify-between flex-1 overflow-y-scroll">
        <table v-if="!isLoading" class="w-full">
          <thead
            class="text-black bg-gray-100 border-b-1 top-[3.5rem]"
            v-if="!isLoading && activitiesList.length!=0"
          >
            <tr>
              <th 
                scope="col"
                class="pt-1 px-1 border border-dark-secondary"
                :key="header.id"
                :class="header.class"
                :header="header.label"
                v-for="header in activitiesTableHeadersList"
              >
                {{ header.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <CompanyActivitiesRow
              v-for="activity in activitiesList"
              :activity="activity"
              :key="activity.id"
              :canEdit="canEdit"
              @refetch-activities="loadActivityNewDeltas"
            />
            <div v-if="!isLoading && activitiesList.length==0" class="w-full text-lg text-red-600 pl-[20px] pt-2">
              No Activities
            </div>
          </tbody>
        </table>
        <div v-if="isLoading" role="status" class="pl-3 pb-3 w-full flex">
          <svg
            aria-hidden="true"
            class="mx-2 mt-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="white"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#2E9CCA"
            />
          </svg>
          <div class="mt-2 text-lg">
            Getting Activities...
          </div>
        </div>
      </div>
    </div>
    <!-- CREATE ACTIVITY VIEW -->
    <div v-if="isCreateVisible" class="ml-2 p-1 mb-5 h-full flex flex-col">
      <div class="border-b border-b-dark-secondary mb-2 flex shrink-0 justify-between p-1">
        <h2 class=" my-auto text-lg">Create Activities </h2>
        <base-button @click="addFormRow">Add New Activity</base-button>
      </div>
      <div class="flex-1 overflow-y-scroll">
        <CompanyActivityForm
        v-for="(row, index) in formRows"
        :key="row.key"
        :submitted="formRows[index].create"
        :companyId="company.id"
        :highlight="highlightRow"
        @close-form="closeCreateRow(index)"
        @failed-create="failedCreate(index)"
        @tried-create="incrementAttempts"
        @is-valid="markValid(index)"
        @not-valid="markInvalid(index)"
        @highlighted="incrementHighlights"
      />
      </div>
      <div
        class="flex flex-row space-x-2 justify-center w-full my-3  flex shrink-0"
      >
        <base-button-affirmative @click="submitCreate"
          >Create</base-button-affirmative
        >
        <base-button-negative @click="hideCreate">Cancel</base-button-negative>
      </div>
    </div>
    <div>
      <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
        <template #header>
            <span>{{ sourceStatus }}</span>
        </template>
        <template #body>
            <span>{{ sourceMessage }}</span>
        </template>
      </HeadlessDialogBox>
    </div>
  </div>
</template>

<script setup>
// <------------ GENERAL ------------>
  import { ref, defineProps, watch} from "vue";
  import { useStore } from "vuex";
  import { getCompanyActivities } from '@/services/Activity'
  import CompanyActivitiesRow from './CompanyActivitiesRow.vue'
  import CompanyActivityForm from "./CompanyActivityForm.vue";
  import HeadlessDialogBox from "./HeadlessDialogBox.vue";

  const store = useStore()
  const props = defineProps({
    company: Object
  })
  const isLoading = ref(false)
  const activitiesList = ref(null)
  // const activityToEdit = ref(null)
  // const activityToDelete = ref(null)
  
  const sourceStatus = ref("")
  const sourceMessage = ref("")
  const openDialogBox = ref(false)
  const activitiesTableHeadersList = ref([
    {class: "max-w-1/14", label: 'Expand'},
    {class: "max-w-1/14", label: 'Title'},
    {class: "max-w-4/14", label: 'Recipients'},
    {class: "max-w-4/14", label: 'Body (First 150 Characters)'},
    {class: "max-w-1/14", label: 'Date'},
    {class: "max-w-1/14", label: 'Type'},
  ])
  const canEdit = ref(canCreateActivities());
  // const canEdit = ref(false)

  async function loadCompanyActivities() {
    // console.log(store)
    isLoading.value = true;
    const payload = {
      token: store.state.auth.token,
      companyId: props.company.id
    }
    const result = await getCompanyActivities(payload)
    // console.log(result)
    activitiesList.value = result.data.activities
    // console.log(activitiesList.value)
    isLoading.value = false;
  }

  async function loadActivityNewDeltas() {
    const payload = {
      token: store.state.auth.token,
      companyId: props.company.id
    }
    const result = await getCompanyActivities(payload)
    // console.log("Got new activities")
    activitiesList.value = result.data.activities
  }

  function closeDialogBox() {
    openDialogBox.value = false;
  }

  /* 
  allowed_groups = [
    'account_staff_group',
    'account_manager_group',
    'dev_group',
    'management_group',
    'superuser_group'
  ]
  */ 
  function canCreateActivities() {
    if (
      store.state.auth.userPermissions.isAccountStaff ||
      store.state.auth.userPermissions.isAccountManager ||
      store.state.auth.userPermissions.isDev ||
      store.state.auth.userPermissions.isManagement ||
      store.state.auth.userPermissions.isSuperuser) {
        activitiesTableHeadersList.value.push({class: "max-w-1/14", label: 'Actions'})
        return true
      }
      return false
  }

// <------------ CREATE ACTIVITIES ------------>
  const isCreateVisible = ref(false)
  const formRows = ref([])
  const createAttempts = ref(0)
  const expectedAttempts = ref(0)
  const highlightRow = ref(false)
  const highlightCount = ref(0)

  function showCreate() {
    resetCreateForm();
    isCreateVisible.value = true;
  }

  function hideCreate() {
    // console.log("Hide create.")
    isCreateVisible.value = false;
    loadActivityNewDeltas();
  }

  function preliminaryCheck() {
    // checks if all formRows are valid
    // console.log("Start Prelim Check")
    let passed = true
    formRows.value.forEach((row) => {
      if (!row.valid) {
        // console.log("Failed Prelim Check")
        passed = false
      }
    });
    // console.log("Passed Prelim Check")
    return passed;
  }

  function submitCreate() {
    if (formRows.value.length == 0) {
      sourceStatus.value = "Warning"
      sourceMessage.value = "Please add activities to create."
      openDialogBox.value = true
      return
    }

    if (!preliminaryCheck()) {
      highlightRow.value = true
      sourceStatus.value = "Error"
      sourceMessage.value = "Please fill in all required fields."
      openDialogBox.value = true
      return
    }

    createAttempts.value = 0
    expectedAttempts.value = formRows.value.length

    formRows.value.forEach((row) => {
      row.create = true;
    })
  }

  function incrementAttempts() {
    // console.log("increment")
    createAttempts.value++;
  }

  watch(
    () => createAttempts,
    () => {
      if (createAttempts.value != expectedAttempts.value) {
        // console.log("Still Creating.")
        return
      }

      highlightRow.value = false

      if (formRows.value.length == 0) {
        // success
        sourceStatus.value = "Success";
        sourceMessage.value = "Created new activities.";
        openDialogBox.value = true;
        hideCreate();
      } else {
        // failure
        // sourceStatus.value = "Error"
        // sourceMessage.value = "Could not create select activities."
        // openDialogBox.value = true
      }
    },
    {deep: true}
  );

  function addFormRow(){
    formRows.value.push(
      {
        key: Math.random(),
        company: props.company.id,
        create: false,
        valid: false
      }
    )
  }

  function closeCreateRow(index) {
    formRows.value.splice(index, 1)
  }

  function resetCreateForm() {
    formRows.value = []
    formRows.value.push(
      {
        key: Math.random(),
        company: props.company.id,
        create: false,
        valid: false
      }
    )
  }

  function failedCreate(index) {
    // console.log("FAILURE")
    formRows.value[index].create = false
  }

  function markValid(index) {
    formRows.value[index].valid = true
  }

  function markInvalid(index) {
    formRows.value[index].valid = false
  }
  
  function incrementHighlights() {
    // console.log(`Increment: ${highlightCount.value} + 1`)
    highlightCount.value++;
  }

  watch(
    () => highlightCount,
    () => {
      // console.log('ANYTHING???')
      if (highlightCount.value == formRows.value.length) {
        highlightRow.value = false;
        highlightCount.value = 0
      }
      // console.log("DONE HIGHLIGHTING")
    },
    {deep: true}
  )
  
  resetCreateForm();
  loadCompanyActivities();
</script>
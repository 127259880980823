<template>
  <div class="h-[96vh] bg-black w-full">
    <div class="text-justify mt-20 mb-20 flex justify-center align-center grid grid-cols-6 w-full">
      <div></div>
      <div class="col-span-4 pt-20 w-full">
        <img class="object-fit w-full" id="splash" src="@/assets/images/jet-splash.png"/>
      </div>
      <div></div>
    </div>
    <div class="grid grid-cols-6">
      <div></div>
        <div class="text-gray-100 border-t border-gray-100  pt-3 text-lg sm:text-xl md:text-2xl lg:text-3xl flex justify-end col-span-4 font-oxanium">
          Welcome to Aerocle V2.
        </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guest-welcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

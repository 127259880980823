<template>
  <base-form>
    <template #body>
      <div
        class="grid grid-cols-3 w-full gap-2 mb-1 p-2  text-xs"
      >
        <div class="flex flex-col">
          <label>Project Name</label>
          <input
            type="text"
            required
            class="p-[9px] border w-full"
            v-model="projectName"
          />
        </div>
        <div class="flex flex-col">
          <label>Research Lead</label>
          <select required class="border p-2 w-full" v-model="researchLead">
            <option v-for="lead in leadsList" :key="lead.id" :value="lead.id">
              {{ lead.first_name + " " + lead.last_name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col">
          <label>Client</label>
          <select required class="border p-2 w-full" v-model="client">
            <option
              v-for="client in clientsList"
              :key="client.id"
              :value="client.id"
            >
              {{ client.client_name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col">
          <label> Start Date</label>
          <input type="date" class="p-2 border" v-model="startDate" />
        </div>
        <div class="flex flex-col">
          <label>End Date</label>
          <input type="date" class="p-2 border" v-model="endDate" />
        </div>
        <div class="flex flex-col">
          <label> Percent Complete</label>
          <input
            type="number"
            min="0"
            max="100"
            class="p-2 border"
            v-model="percentComplete"
          />
        </div>
        <div class="flex flex-col">
          <label>Estimate to Complete (Person-Days)</label>
          <input
            type="number"
            min="0"
            class="p-2 border"
            v-model="estimateToComplete"
          />
        </div>
        <div class="flex flex-col col-span-1">
          <label>Priority</label>
          <select required class="border p-2 w-full" v-model="priority">
            <option
              v-for="priority in priorityOptions"
              :key="priority.value"
              :value="priority.value"
            >
              {{ priority.label }}
            </option>
          </select>
        </div>
        <div class="flex flex-col col-span-2">
          <label>Stage</label>
          <select required class="border p-2 w-full" v-model="stage">
            <option
              v-for="stage in stageOptions"
              :key="stage.value"
              :value="stage.value"
            >
              {{ stage.label }}
            </option>
          </select>
        </div>
        <div class="flex flex-col col-span-5">
          <label>Description</label>
          <textarea
            class="p-2 border h-full w-full"
            v-model="description"
            rows="4"
          ></textarea>
        </div>
        <div class="flex flex-col justify-left w-full col-span-5 text-red-600">
          <p v-if="projectNameEmptyError">
            Please include a
            <span class="font-bold">Project Name</span>.
          </p>
          <p v-if="researchLeadEmptyError">
            Please include a
            <span class="font-bold">Research Lead</span>.
          </p>
          <p v-if="clientEmptyError">
            Please include a
            <span class="font-bold">Client</span>.
          </p>
          <p v-if="percentCompleteOutOfBoundsError">
            Please enter a <span class="font-bold">Percent Complete</span> value
            between 0 and 100.
          </p>
          <p v-if="estimateToCompleteNegativeValueError">
            Please enter a non-negative
            <span class="font-bold">Estimate to Complete (Person-Days)</span>
            value.
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row space-x-2 justify-center w-full col-span-5 my-2">
        <base-button-affirmative @click="saveEntry">Save</base-button-affirmative>
        <base-button-negative @click="closeBox">Cancel</base-button-negative>
      </div>
    </template>
  </base-form>

</template>

<script setup>
import { getClients } from "@/services/Client";
import { getAllUsers } from "@/services/User";
import { createResearchProject } from "@/services/ResearchProjects";
import { useStore } from "vuex";
import { ref, onMounted, defineEmits } from "vue";

const store = useStore();

const clientsList = ref([]);
const leadsList = ref([]);

const projectName = ref();
const researchLead = ref();
const client = ref();
const description = ref();
const startDate = ref();
const endDate = ref();
const percentComplete = ref();
const estimateToComplete = ref();
const priority = ref();
const stage = ref();

onMounted(() => {
  loadClients();
  loadResearchLead();
});

const stageOptions = ref([
  { value: "clear", label: "------------" },
  { value: "Stage 1", label: "Stage 1" },
  { value: "Stage 2", label: "Stage 2" },
  { value: "Contacts", label: "Contacts" },
  { value: "Programs", label: "Programs" },
  { value: "Certification", label: "Certification" },
  { value: "Acquisition", label: "Acquisition" },
]);

const priorityOptions = ref([
  { value: "clear", label: "------------" },
  { value: 0, label: "No Priority" },
  { value: 1, label: "Priority 1" },
  { value: 2, label: "Priority 2" },
  { value: 3, label: "Priority 3" },
  { value: 4, label: "Priority 4" },
  { value: 5, label: "Priority 5" },
  { value: 6, label: "Priority 6" },
  { value: 7, label: "Priority 7" },
  { value: 8, label: "Priority 8" },
  { value: 9, label: "Priority 9" },
  { value: 10, label: "Priority 10" },
]);

const percentCompleteOutOfBoundsError = ref(false);
const estimateToCompleteNegativeValueError = ref(false);
const projectNameEmptyError = ref(false);
const researchLeadEmptyError = ref(false);
const clientEmptyError = ref(false);

const emit = defineEmits(["close"]);

function setLeadId(event) {
  console.log(event.target);
}
async function loadClients() {
  const output = await getClients({ token: store.state.auth.token });
  console.log(output);
  clientsList.value = output.clients;
  return output;
}

async function loadResearchLead() {
  const output = await getAllUsers(store.state.auth.token);
  console.log(output.users);
  leadsList.value = output.users;
  return output;
}

function closeBox() {
  emit("close");
}

function saveEntry() {
  let error = false;
  percentCompleteOutOfBoundsError.value = false;
  estimateToCompleteNegativeValueError.value = false;
  projectNameEmptyError.value = false;
  researchLeadEmptyError.value = false;
  clientEmptyError.value = false;

  if (percentComplete.value != "") {
    if (percentComplete.value > 100 || percentComplete.value < 0) {
      percentCompleteOutOfBoundsError.value = true;
      error = true;
    }
  }
  if (estimateToComplete.value != "") {
    if (estimateToComplete.value > 100 || estimateToComplete.value < 0) {
      estimateToCompleteNegativeValueError.value = true;
      error = true;
    }
  }
  if (!projectName.value || projectName.value == "") {
    projectNameEmptyError.value = true;
    error = true;
  }
  if (!researchLead.value || researchLead.value == "") {
    researchLeadEmptyError.value = true;
    error = true;
  }
  if (!client.value || client.value == "") {
    clientEmptyError.value = true;
    error = true;
  }
  if (error) {
    return;
  }

  const projectSubmission = JSON.stringify({
    research_project: {
      project_name: projectName.value,
      research_lead: researchLead.value,
      client: client.value,
      start_date: startDate.value,
      end_date: endDate.value,
      percent_complete: percentComplete.value,
      etc_person_days: estimateToComplete.value,
      description: description.value,
      priority: priority.value,
      stage: stage.value
    },
  });

  console.log(projectSubmission);
  pushNewResearchProject(projectSubmission);
  closeBox();
  // alert("yay saved ");
}

async function pushNewResearchProject(project) {
  const output = await createResearchProject(project, store.state.auth.token);
}
</script>

<template>
  <div class="w-full">
  <div
    v-if="userPermissions.isHourly"
    class="pt-3 pb-0.5 border-b mx-5 mb-3 text-sm border-b-gray-400 flex flex-row justify-between"
  > 
    Timecards: 
  </div>
    <div v-if="showFilterPane">
      <div
        v-if="userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser  || userPermissions.isAccountManager"
        class=" pb-0 border-b mx-5 mb-5 text-sm border-b-gray-400 flex flex-row justify-between"
      >
        <h1 class="mt-4">Timecards Filters:</h1>
        <div>
          <base-button-affirmative @click="resetFilter" class="ml-3 mb-1 mt-2 text-xs">
            Reset
          </base-button-affirmative>
        </div>
      </div>
      <div class="px-5 grid grid-cols-9" v-if="userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser  || userPermissions.isAccountManager">
        <base-filter class="col-span-2">
          <template v-slot:label>Team Member</template>
          <template v-slot:widget>
            <base-list-box
              class="w-full rounded-b-md dark:text-black"
              placeholder="Select Team Member"
              v-model="timecardFilters.teamMember"
              :options="usersWithTimecards"
              @update:modelValue="setTeamMemberFilter"
            />
          </template>
        </base-filter>
        <base-filter class="col-span-2">
          <template v-slot:label>Status</template>
          <template v-slot:widget
            ><base-list-box
              class="w-full rounded-b-md dark:text-black"
              placeholder="Select Status"
              v-model="timecardFilters.status"
              :options="statusOptions"
              @update:modelValue="setStatusFilter"
          /></template>
        </base-filter>
        <base-filter class="col-span-2">
          <template v-slot:label>Week Starting: </template>
          <template v-slot:widget
            ><base-list-box
              class="w-full rounded-b-md dark:text-black"
              placeholder="Select Week Starting"
              v-model="timecardFilters.weekStarting"
              :options="weekStartingOptions"
              @update:modelValue="setWeekStartingFilter"

          /></template>
        </base-filter>
      </div>
    </div>
    <div
      class="pt-3 pb-0.5 border-b mx-5 mb-2 border-b-gray-400 flex flex-row justify-between"
    >
      <h1 class="text-sm">Actions:</h1>
    </div>
    <div class="flex justify-start px-5">
      <timecard-actions
          v-if="showActionsPane"
          :key="actionsKey"
          :teamMemberOptions="hourlyEmployeeOptions"
          :timecardUsers="timecardUsers"
          :userPermissions="userPermissions"
          v-on:reload="reloadAllData"
          v-on:open-dialogue="openDialogueBox">
      </timecard-actions>
      <timecard-group-table 
        v-if="userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser  || userPermissions.isAccountManager"
        :key="groupKey"
        v-on:reload="reloadAllData"
        v-on:open-dialogue="openDialogueBox">
      </timecard-group-table>
    </div>
    <div class="pl-5">
      <div class="flex flex-row items-center ml-3">
        <div class="flex items-center">
          <span
            class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="goToFirst"
            >&lt;&lt;</span
          >
          <span
            class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="getPrev"
            >&lt;</span
          >
          <div class="flex text-md flex-row">
            Page {{ page }} of {{ totalPages }}
          </div>
          <span
            class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="getNext"
            >></span
          >
          <span
            class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
            @click="goToLast"
            >>></span
          >
        </div>
        <div class="flex flex-row items-center">
          <div v-if="isLoading" role="status" class="pl-3">
            <svg
              aria-hidden="true"
              class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="white"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#2E9CCA"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else class="pl-3">
            <svg
              @click="reloadAllData()"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#25274D"
              class="mx-1 w-6 h-6 hover:cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex flex-row items-center text-md align-middle lg:ml-3 lg:col-span-1 lg:justify-start"
        >
          {{ count }} Total Timecard<span v-if="count !=1">s</span>
        </div>
    </div>
</div>
    <timecards-table
    :tableHeaders="tableHeaders"
    :content="timecardList"
    :timecardUsers="timecardUsers"
    class="mx-6"
    v-on:reload="reloadAllData()"
    v-on:openTimecard="openTimecardModal"
    v-on:open-dialogue="openDialogueBox"
    v-on:editTimecard="openEditTimecardModal"
    >
    </timecards-table>

  <view-timecard-modal 
    v-if="isTimecardModalOpen"
    :user-permissions="userPermissions"
    :timecard="selectedTimecard"
    v-on:close="closeViewTimecardModal"> 
  </view-timecard-modal>
  <edit-timecard-modal
    v-if="isEditTimecardModalOpen"
    :timecard="selectedTimecardToEdit"
    v-on:close="closeEditTimecardModal"
    v-on:close-and-reload="closeAndReloadEditTimecardModal"
  >
  </edit-timecard-modal>
  <base-dialog-box v-if="isDialogueBoxOpen">
    <template #header>
      {{ dialogueBoxHeader}}
      <base-button-negative @click="closeDialogueBox"> X </base-button-negative>
    </template>
    <template #body> 
      {{dialogueBoxBody}}   
    </template>
  </base-dialog-box>
</div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onMounted, reactive, computed, watch, shallowReactive } from "vue";
import { getAllTimecards, getTimecardDates, getTimecardsByUser} from "@/services/Timecard";
import TimecardsTable from "./TimecardsTable.vue";
import { getUsersByGroup, getActiveAndInactiveUsersByGroup, getUsers } from "@/services/User.js"
import BaseFilter from "@/components/BaseFilter";
import BaseListBox from "@/components/BaseListBox";
import TimecardActions from "./TimecardActions.vue";
import TimecardGroupTable from "./TimecardGroupTable.vue";
import ViewTimecardModal from "./ViewTimecardModal.vue";
import EditTimecardModal from "./EditTimecardModal.vue";

const userPermissions = shallowReactive({
  isManagement: null,
  isHourly: null,
  isSuperuser: null,
  isDev: null,
  isAccountManager: null,
});

const showFilterPane = computed(() => store.getters['timecards/showFilterPane'])
const showActionsPane = computed(() => store.getters['timecards/showActionsPane'])

const setUserPermissions = function () {
  let userPermissionsStore = store.getters['auth/showUserPermissions']
  userPermissions.isManagement = userPermissionsStore.isManagement
  userPermissions.isHourly = userPermissionsStore.isHourly
  userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
  userPermissions.isDev =  userPermissionsStore.isDev
  userPermissions.isAccountManager = userPermissionsStore.isAccountManager
  userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff
}

const store = useStore();

const next = ref(null);
const prev = ref(null);
const count = ref(null);
const page = ref(null);
const pageSize = ref(null);
const totalPages = ref(null);
const isLoading = ref(false);
const awaitingSearch = ref(false);

const timecardList = ref([]);
const hourlyPaidUsers = ref([]);
const usersWithTimecards = ref([
  {value: "clear", label: "-----------"}
]);
const activeHourlyPaidUsers = ref([]);
const allTimecardDates = ref([]);
const timecardUsers = ref([]);

const tableHeaders = ref(['User', 'Time Period', 'Week\'s Hours', 'Total Hours', 'Status', 'Actions'])

const selectedTimecard = ref(null);
const isTimecardModalOpen = ref(false);
const groupKey = ref(1);
const actionsKey = ref(1);
const selectedTimecardToEdit = ref(null);
const isEditTimecardModalOpen = ref(false);

const timecardFilters = reactive({
    teamMember: null,
    status: null,
    weekStarting: null,
});

const isDialogueBoxOpen = ref(false);
const dialogueBoxHeader = ref("");
const dialogueBoxBody = ref("");

const statusOptions = ref([
  { value: "clear", label: "------------" },
  { value: "active", label: "Active" },
  { value: "approved", label: "Approved" },
  { value: "paid", label: "Paid" },
  { value: "not_paid", label: "Not Paid" },
  { value: "submitted", label: "Submitted" }

]);

const teamMemberOptions = ref([
  { value: "clear", label: "------------" },
]);
const hourlyEmployeeOptions = ref([
  { value: "clear", label: "------------" },
]);
const weekStartingOptions = ref([
  { value: "clear", label: "------------" },
]);


onMounted(() => {
  setUserPermissions();
  if (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountManager || userPermissions.isAccountStaff) {
    loadTimecardData();
    loadHourlyUsers();
    loadTimecardDates();
    loadActiveHourlyUsers();
    loadUsersWithTimecards()
    loadTimecardUsers();
  }
  else {
    loadTimecardByUser();
  }
});

function openDialogueBox(header_text, body_text) {
  dialogueBoxHeader.value = header_text;
  dialogueBoxBody.value = body_text;
  isDialogueBoxOpen.value = true;
}

function closeDialogueBox() {
  isDialogueBoxOpen.value = false;
  dialogueBoxHeader.value = "";
  dialogueBoxBody.value = "";
}

const fetchFilter = computed(function () {
  // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
  let query = new URLSearchParams({});
  if (timecardFilters.teamMember) {
    query.append("user", timecardFilters.teamMember);
  }
  else if (userPermissions.isHourly) {
    query.append("user", store.state.auth.user.id);
  }
  if (timecardFilters.status) {
    query.append("status", timecardFilters.status);
  }
  if (timecardFilters.weekStarting) {
    query.append("week_of", timecardFilters.weekStarting);
  }

  // (B) CONVERT TO STRING, APPEND TO URL
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
  // (C) WHATEVER COMES NEXT...
});

watch(
  () => timecardFilters,
  () => {
    if (!awaitingSearch.value) {
      setTimeout(() => {
        loadTimecardData(fetchFilter.value, false);
        awaitingSearch.value = false;
      }, 1000); // 1 sec delay
    }
    awaitingSearch.value = true;
  },
  { deep: true }
);

function setTeamMemberFilter(target) {
  target == "clear"
    ? (timecardFilters.teamMember = "")
    : (timecardFilters.teamMember = target);
}

function setStatusFilter(target) {
  target == "clear"
    ? (timecardFilters.status = "")
    : (timecardFilters.status = target);
}

function setWeekStartingFilter(target) {
  target == "clear"
    ? (timecardFilters.weekStarting = "")
    : (timecardFilters.weekStarting = target);
}

function resetFilter() {
    timecardFilters.teamMember = "",
    timecardFilters.status = "",
    timecardFilters.weekStarting = ""
}

function getNext() {
  if (next.value) {
    if (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser) {
      loadTimecardData(fetchFilter.value + next.value);
    }
    else {
      loadTimecardByUser(next.value);
    }
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    if (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser) {
      loadTimecardData(fetchFilter.value + prev.value);
    }
    else {
      loadTimecardByUser(prev.value);
    }
  } else {
    alert("No prev page");
  }
}

function goToFirst() {
  if (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser) {
    loadTimecardData(fetchFilter.value + `&page=${1}`);
    }
    else {
      loadTimecardByUser(`&page=${1}`);
    }
}

function goToLast() {
  if (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser) {
    loadTimecardData(fetchFilter.value + `&page=${totalPages.value}`);
  }
  else {
    loadTimecardByUser(`&page=${totalPages.value}`);
  }
}


function openTimecardModal(timecard) {
  isTimecardModalOpen.value = true;
  selectedTimecard.value = timecard; 
}

function closeViewTimecardModal() {
  isTimecardModalOpen.value = false;
  selectedTimecard.value = null;
}

function openEditTimecardModal(timecard) {
  isEditTimecardModalOpen.value = true;
  selectedTimecardToEdit.value = timecard;
}

function closeEditTimecardModal() {
  isEditTimecardModalOpen.value = false;
  selectedTimecardToEdit.value = null;
}

function closeAndReloadEditTimecardModal() {
  isEditTimecardModalOpen.value = false;
  selectedTimecardToEdit.value = null;
  reloadAllData();
}

async function reloadAllData() {
    if (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountManager) {
    loadTimecardData();
    loadHourlyUsers();
    loadTimecardDates();
    loadActiveHourlyUsers();
    loadUsersWithTimecards();
    loadTimecardUsers();
    groupKey.value += 1;
    actionsKey.value += 1;
  }
  else {
    loadTimecardByUser();
  }
}

async function loadHourlyUsers() {
    let payload = {
        token: store.state.auth.token,
        group: 'hourly_group',
        endpoint: "list_users",
    }
    const response = await getActiveAndInactiveUsersByGroup(payload);
    hourlyPaidUsers.value = response.users;
    teamMemberOptions.value = [{ value: "clear", label: "------------" }];
    for (let user of hourlyPaidUsers.value) {
        const name = user.first_name + " " + user.last_name;
        teamMemberOptions.value.push({value: user.id, label: name});
    }
}

async function loadUsersWithTimecards() {
    let payload = {
        token: store.state.auth.token,
        endpoint: "?has_timecards=True",
    }
    const response = await getUsers(payload);
    // usersWithTimecards.value = response.users;
    if(response){
      usersWithTimecards.value = [{value: "clear", label: "------------"}, ...response.users.map((user) => {
        return {value: user.id, label: `${user.first_name} ${user.last_name}`}
      })]
    }
}

async function loadActiveHourlyUsers() {
    let payload = {
        token: store.state.auth.token,
        group: 'hourly_group',
        endpoint: "list_users",
    }
    const response = await getUsersByGroup(payload);
    activeHourlyPaidUsers.value = response.users;
    hourlyEmployeeOptions.value = [{ value: "clear", label: "------------" }];
    for (let user of activeHourlyPaidUsers.value) {
        const name = user.first_name + " " + user.last_name;
        hourlyEmployeeOptions.value.push({value: user.id, label: name});
    }
}

async function loadTimecardUsers() {
    let payload = {
        token: store.state.auth.token,
        group: 'generate_timecard_group',
        endpoint: "list_users",
    }
    const response = await getUsersByGroup(payload);
    // console.log(response);
    timecardUsers.value = [{ value: "clear", label: "------------" }];
    for (let user of response.users) {
        const id = user.id;
        timecardUsers.value.push(id);
    }
}

async function loadTimecardData(endpoint = fetchFilter.value, retainSpot) {
    isLoading.value = true;
    let payload = {
        token: store.state.auth.token,
        endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
    };
    const response = await getAllTimecards(payload);

    next.value = response.next ? `&page=${response.page + 1}` : null;
    prev.value = response.prev ? `&page=${response.page - 1}` : null;
    count.value = response.count;
    page.value = response.page;
    pageSize.value = response.results.length;
    totalPages.value = response.numPages;

    timecardList.value = response.results;
    isLoading.value = false;
    return response.results;
}

async function loadTimecardDates() {
    let payload = {
        token: store.state.auth.token,
    }
    const response = await getTimecardDates(payload);
    allTimecardDates.value = response.results.data;
    setTimecardDateOptions(allTimecardDates.value);
}

function setTimecardDateOptions(allTimecardDates) {
  // console.log('All timecard dates')
  // console.log(allTimecardDates)
    // let timecardDates = [];
    // let counter = 0;
    // for (const timecard of allTimecardDates) {
    //     let currDate = new Date(timecard.date);
    //     currDate = currDate.toUTCString()
    //     if (!(timecardDates.includes(currDate))) {
            // counter++;
    //         let timestart = new Date(timecard.date);
    //         timecardDates.push(timestart.toUTCString());
    //         let timecardStart = new Date(timecard.date);
    //         timecardStart.setDate(timecardStart.getDate() + 1);
    //         let formattedDateString = formatTimecardDates(timecardStart);
    //         weekStartingOptions.value.push({value: timecard.date, label: formattedDateString});
    //     }
    // }
    weekStartingOptions.value = [
      { value: "clear", label: "------------" },
    ]
    for (const timecard of allTimecardDates) {
      let formattedDateString = formatTimecardDates(timecard.date);
      weekStartingOptions.value.push({value: timecard.date, label: formattedDateString});
    }
}

function formatTimecardDates(timecardDate) {
  // console.log(timecardStart)
  let timecardStart = new Date(timecardDate);
    timecardStart.setDate(timecardStart.getDate() + 1);
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let formattedDateString = days[6] + ": " + (timecardStart.getMonth() + 1) + '/' + (timecardStart.getDate()) + '/' + timecardStart.getFullYear();
    return formattedDateString;
} 

async function loadTimecardByUser(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
    let payload = {
        token: store.state.auth.token,
        endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
    };
    const response = await getTimecardsByUser(payload, {'user': store.state.auth.user.id});
    next.value = response.next ? `&page=${response.page + 1}` : null;
    prev.value = response.prev ? `&page=${response.page - 1}` : null;
    count.value = response.count;
    page.value = response.page;
    pageSize.value = response.results.length;
    totalPages.value = response.numPages;
    timecardList.value = response.results;
    isLoading.value = false;
    return response.results;
}

</script>


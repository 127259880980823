<template>
  <div class="w-full h-full">
    <form @submit.prevent="submitForm">
    <base-form class="h-full">
      <template #body>

          <div
            class="grid grid-cols-3 w-full h-full gap-2 p-1 text-xs"
          >
            <div class="flex flex-col shrink-0">
              <label>To</label>
              <input
                disabled
                class="p-1 border w-full inline"
                v-model="formData.to"
              />
            </div>
            <div class="flex flex-col shrink-0">
              <label>From</label>
              <input
                disabled
                class="p-1 border w-full inline"
                v-model="formData.from"
              />
            </div>
            <div class="flex flex-col shrink-0">
              <label>cc</label>
              <input
                disabled
                class="p-1 border w-full inline"
                v-model="formData.cc"
              />
            </div>
            <div class="flex flex-col shrink-0">
              <label>Type*</label>
              <input
                required
                class="p-1 border w-full inline"
                v-model="formData.type"
              />
            </div>
            <div class="flex flex-col shrink-0">
              <label>Subject</label>
              <input
                disabled
                class="p-1 border w-full inline"
                v-model="formData.subject"
              />
            </div>
            <div class="flex flex-col shrink-0">
              <label>Date</label>
              <input
                disabled
                class="p-1 border w-full inline"
                v-model="formData.date"
              />
            </div>
            <div class="flex-col col-span-1 shrink-0">
              <div>Company*</div>
              <div >
                <BaseComboBox 
                class="border rounded-b-md w-full"
                  v-model="formData.company"
                  :single-value="true"
                  :options="companyList"
                  @update:modelValue="setCompany"
                />
              </div>
              <!-- <select
                  class="border border-dark-primary rounded-md p-1 w-full"
                  v-model="formData.company"
              >
                <option
                  v-for="company in companyList"
                  :key="company.value"
                  :value="company.value"
                >
                  {{ company.label }}
                </option>
              </select> -->
            </div>
            <div class="flex flex-col col-span-2 shrink-0">
              <div
                v-if="highlightBoxes"
                class="text-left pl-2 col-span-2 text-red-500 font-semibold"
              >
                Please select a company to create an activity.
              </div>
            </div>
            <div class="flex flex-col  flex-1 col-span-3 min-h-[300px] row-span-2 mb-5">
              <label>Body</label>
              <textarea
                disabled
                class="p-1 border h-full w-full"
                v-model="formData.body"></textarea>
            </div>
          </div>
      </template>
      <template #footer>
        <div
            class="flex flex-row space-x-2 justify-center items-center w-full my-2"
          >
          <base-button-affirmative
            >Save</base-button-affirmative
          >
          <!-- <base-button-negative @click="closeForm">Cancel</base-button-negative> -->
        </div>
      </template>
    </base-form>
  </form>
    <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
        <template #header>
            <span>{{ sourceStatus }}</span>
        </template>
        <template #body>
            <span>{{ sourceMessage }}</span>
        </template>
    </HeadlessDialogBox>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, reactive, watch } from 'vue';
import { useStore } from "vuex";
import { createActivity } from '@/services/ClientEmail';
import HeadlessDialogBox from '../HeadlessDialogBox.vue';
import BaseComboBox from '../BaseComboBox.vue';import { useToast } from 'vue-toast-notification';

const $toast = useToast()


const props = defineProps({
  activityEmail: Object,
  companyList: Object
});


const emit = defineEmits(['close-form', 'reload-emails']);
const store = useStore();

const formData = reactive({
  to: props.activityEmail.email_to,
  from: props.activityEmail.email_from,
  cc: props.activityEmail.email_cc,
  company: null,
  subject: props.activityEmail.email_subject,
  date: props.activityEmail.email_date,
  type: "Email",
  body: props.activityEmail.email_body,
});

function setCompany(target) {
  // console.log('company set')
  formData.company = target
}

const highlightBoxes =  ref(false);
const sourceStatus = ref("")
const sourceMessage = ref("")
const openDialogBox = ref(false)
const success = ref(false);

async function submitForm() {
  // console.log("This will do something")
  // console.log("after edit")
  // console.log(JSON.stringify(formData.body))
  // formData.body.replace(/\n/g, "\r\n");
  
  if (!formData.company) {
    highlightBoxes.value = true;
    return;
  }

  const new_activity = {
    activity_name: props.activityEmail.email_subject,
    activity_type: formData.type,
    activity_note: props.activityEmail.email_body,
    activity_date: props.activityEmail.email_date,
    email_from: props.activityEmail.email_from,
    email_to: props.activityEmail.email_to,
    email_cc: props.activityEmail.email_cc,
    company: formData.company,
  }

  const payload = {
    token: store.state.auth.token,
    data: JSON.stringify(new_activity),
    emailId: props.activityEmail.id
  }
  const result = await createActivity(payload);
  // console.log(result);
  if(result.status == 201) {
    // Delete email
    console.log("new activity created")
    $toast.open({
            message: `Successfully Created Email History ${new_activity.activity_name} for ${props.companyList.filter((company) => company.value === new_activity.company)[0].label}.`,
            type: 'success',
            duration: 20000
        })
      closeForm()
    // success.value = true;
    // sourceStatus.value = "Success";
    // sourceMessage.value = "New activity created.";
    // openDialogBox.value = true;
  } else {
    // console.log("could not create new activity")
    $toast.open({
            message: `Error, could not create Email History ${new_activity.activity_name} for ${props.companyList.filter((company) => company.value === new_activity.company)[0].label}.`,
            type: 'error',
            duration: 20000
        })
    // success.value = false;
    // sourceStatus.value = "Error";
    // sourceMessage.value = "Could not create new activity.";
    // openDialogBox.value = true;
  }
  emit("reload-emails")
}

function closeForm() {
  emit("close-form");
}

function closeDialogBox(){
    openDialogBox.value = false
    if (success.value) {
      closeForm();
    }
    success.value = false
}

</script>
<template>
    <div class="h-full w-full">
        <div class="pt-1 w-full">
            <BaseTabBar 
                :tabList="tabList"
                :selectedColor="selectedColor"
                :notSelectedColor="notSelectedColor"
                :hoverColor="hoverColor"
                @set-page="setView"
            />
        </div>
        <div v-if="selectedTab === 'Contract Details' " class="px-2 overscroll-none h-full">
            <div class="grid grid-cols-2 p-2 space-y-3 gap-4">
                <div class="col-span-2 flex flex-col">
                    <div class="w-full border-b border-b-dark-primary mb-2">
                        <span class="text-base">{{ contract.award_type }}</span>
                    </div>
                    <div class="grid grid-cols-3 space-x-4 w-full">
                        <div class="flex flex-col space-y-1">
                            <div class="font-bold">
                                Awarding Agency
                            </div>
                            <div>
                                <!-- <base-pill>Awarding Agency: </base-pill> -->
                                {{ contract.awarding_agency_name }}
                            </div>
                            <div>
                                <base-pill>Sub Agency: </base-pill>
                                {{ contract.awarding_sub_agency_name }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1" v-if="contract.sam_gov_entity">
                            <div class="font-bold">
                                Recipient
                            </div>
                            <div>
                                {{ contract.sam_gov_entity.company.company_name }} ({{ contract.sam_gov_entity.company.id }})
                            </div>
                            <div>
                                <!-- <base-pill>Website: </base-pill> -->
                                <a :href="contract.sam_gov_entity.company.website_url" target="_blank" class="underline text-accent-primary hover:text-dark-primary">
                                    {{ contract.sam_gov_entity.company.website_url }}
                                </a>
                            </div>
                            <div>
                                <!-- <base-pill>Location: </base-pill> -->
                                {{ contract.sam_gov_entity.company.city }}, {{ contract.sam_gov_entity.company.state }}
                            </div>
                            <div>
                                <base-pill>Campaign: </base-pill>
                                {{ contract.sam_gov_entity.company.campaign ? contract.sam_gov_entity.company.campaign.campaign_name : '--' }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1" v-else>
                            <div class="font-bold">
                                Recipient
                            </div>
                            <div>
                                {{ contract.recipient_name }}
                            </div>
                            <div>
                                {{ contract.recipient_city_name }}, {{ contract.recipient_state_name }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1">
                            <div class="font-bold">
                                Dates
                            </div>
                            <div class="flex w-3/4 justify-between">
                                <div class="flex justify-start class w-1/3">
                                    <base-pill class="min-w-full">Start Date: </base-pill>
                                </div>
                                <div class="flex justify-start w-1/2">
                                    {{ formatDateTwoDigitYear(contract.period_of_performance_start_date) }}
                                </div>
                            </div>
                            <div class="flex w-3/4 justify-between">
                                <div class="flex justify-start class w-1/3">
                                    <base-pill class="min-w-full">End Date: </base-pill>
                                </div>
                                <div class="flex justify-start w-1/2">
                                    {{ formatDateTwoDigitYear(contract.period_of_performance_current_end_date) }}
                                </div>
                            </div>
                            <div class="flex w-3/4 justify-between">
                                <div class="flex justify-start class w-1/3">
                                    <base-pill class="min-w-full">Action Date: </base-pill>
                                </div>
                                <div class="flex justify-start w-1/2">
                                    {{ formatDateTwoDigitYear(contract.action_date) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="w-full border-b border-b-dark-primary mb-2">
                        <span class="text-base">Award Amounts</span>
                    </div>
                    <div class="flex flex-col space-y-1">
                        <div class="flex w-3/4 justify-between">
                            <div class="flex justify-start class w-1/3">
                                <base-pill class="min-w-full">Obligated Amount: </base-pill>
                            </div>
                            <div class="flex justify-start w-1/2">
                                {{ formatCurrency(contract.total_dollars_obligated) }}
                            </div>
                        </div>
                        <div class="flex w-3/4 justify-between">
                            <div class="flex justify-start class w-1/3">
                                <base-pill class="min-w-full">Current Amount: </base-pill>
                            </div>
                            <div class="flex justify-start w-1/2">
                                {{ formatCurrency(contract.current_total_value_of_award) }}
                            </div>
                        </div>
                        <div class="flex w-3/4 justify-between">
                            <div class="flex justify-start class w-1/3">
                                <base-pill class="min-w-full">Potential Amount: </base-pill>
                            </div>
                            <div class="flex justify-start w-1/2">
                                {{ formatCurrency(contract.potential_total_value_of_award) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="w-full border-b border-b-dark-primary mb-2">
                        <span class="text-base">Description</span>
                    </div>
                    <div class="flex flex-col space-y-1">
                        <div class="flex w-full space-x-1">
                            <div class="normal-case">
                                {{ contract.transaction_description }}
                            </div>
                            <!-- <div class="flex justify-start class w-1/4">
                                <base-pill class="min-w-full">Obligated Amount: </base-pill>
                            </div>
                            <div class="flex justify-start w-1/2">
                                {{ formatCurrency(contract.total_dollars_obligated) }}
                            </div> -->
                        </div>
                        <div class="grid grid-cols-2 gap-2">
                            <div class="flex flex-col space-y-1">
                                <div class="font-bold">
                                    Naics Code
                                </div>
                                <div>
                                    {{ contract.naics_code }}: {{ contract.naics_description }}
                                </div>
                            </div>
                            <div class="flex flex-col space-y-1">
                                <div class="font-bold">
                                    Product or Service Code (PSC)
                                </div>
                                <div>
                                    {{ contract.product_or_service_code }}: {{ contract.product_or_service_code_description }}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="selectedTab === 'Sub Contracts' " class="px-2 overscroll-none h-full">
            <PrimeContractSubContracts :contract="contract" />
        </div>
        <div v-if="selectedTab === 'Recipient Details' " class="px-2 overscroll-none h-full">
            recipient details page
        </div>
    </div>
</template>

<script>
export default {
    name: 'prime-contract-details-page'
}
</script>

<script setup>
import {ref, computed, defineProps} from 'vue'
import BaseTabBar from '../BaseTabBar.vue';
import PrimeContractSubContracts from './PrimeContractSubContracts.vue'
import { formatDateTwoDigitYear } from '@/composables/formatDate';

const props = defineProps({
    contract: Object
})

const tabList = ref([
    {tabName: 'Contract Details', isSelected: true},
    {tabName: 'Sub Contracts', isSelected: false},
    // {tabName: 'Recipient Details', isSelected: false}
    
])

const selectedColor = 'white';
const notSelectedColor = 'gray-100'
const hoverColor = 'slate-50'

const selectedTab = ref('Contract Details')

function setView(tabname) {
    console.log('set view', tabname)
    selectedTab.value = tabname
    tabList.value.forEach((tab) => {
        if(tabname === tab.tabName) {
            tab.isSelected = true 
        } else {
            tab.isSelected = false
        }
    })
}

function formatCurrency(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(amount);
}

</script>
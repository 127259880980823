import axios from 'axios';
import { BASE_URL } from './api';

export async function getCompanies(payload) {
    return axios({
        method: "get",
        url: `${BASE_URL}/crm_for_pdf/${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        }
    })
    .then(response => {
        return response.data
    })
    .catch(error => console.error(error))

}
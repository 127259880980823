<template>
    <div>
        <div class="h-[96vh] flex flex-col justify-center items-center">
            <div class="flex flex-col justify-start items-center w-3/4">
                <div class="w-2/4 flex flex-row items-center justify-center">
                    <!-- <p class="flex text-8xl text-dark-primary mr-4">404</p> -->
                    <div class="flex flex-col text-xl">
                        <p class="py-1">We're in the maintenance hangar.</p>
                        <p class="py-1">Thank you for your patience, we'll let you know when everything is back up and you can return <router-link class=" text-blue-400 underline" to="/">Home</router-link>!</p>
                        
                    </div>
                </div>
                <div class="w-2/4 flex justify-center items-start">
                    <FighterJetSVG />
                </div>
            </div>
        </div>
        <div class="text-xs text-dark-primary text-right">
                <a rel="nofollow" href="https://fortawesome.github.com/Font-Awesome">Image By Font Awesome by Dave Gandy</a>
        </div>
    </div>
</template>

<script>
    export default {
    name: "maintenance-page"
}
</script>

<script setup>
import FighterJetSVG from './svg-components/FighterJetSVG.vue'
</script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getCollectionTags(token) {
    // console.log('getcollectionTags')
    return axios({
        method: "get",
        url: `${BASE_URL}/collection_tags`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error(error))

}

export async function getCollectionTagsForActiveUser(token) {
    return axios({
        method: "get",
        url: `${BASE_URL}/collection_tags/get_collection_tags_for_active_user`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function updateCollectionTag(collectionTagId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/collection_tags/${collectionTagId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function removeAllCompaniesAndDeleteCollectionTag(collectionTagId, token){
    // console.log('collection tag id in axios call', collectionTagId)
    return axios({
        method: 'put',
        url: `${BASE_URL}/collection_tags/${collectionTagId}/remove_all_companies_from_collection_and_delete_collection/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => console.log(error))
}
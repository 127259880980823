<template>
  <base-table-row>
    <base-table-cell>
      <div class="flex-col">
        <div>{{ referral.id }}</div>
        <div>{{ referral.status }}</div>
      </div>
    </base-table-cell>

    <base-table-cell>
      <div class="flex flex-col">
        {{ fullName }}
        <div>
          <base-button
            v-if="referral.companies.length > 0"
            @click="toggleView"
            class="resize-x"
            >Companies</base-button
          >
        </div>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="">
        {{ referral.referrer_email }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pr-12">
        {{ referral.referrer_address }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pr-12">
        {{ referral.referrer_phone }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pr-10">
        <base-button @click="openEdit">Edit</base-button>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pr-10" v-if="referral.status == 'active'">
        <base-button @click="archiveReferrer">Archive</base-button>
      </div>
      <div v-else>
        <DisabledButton
          class="cursor-not-allowed focus:outline-none disabled:opacity-75 border border-gray-400 text-gray-400"
          >Archive</DisabledButton
        >
      </div>
    </base-table-cell>
  </base-table-row>
  <base-table-row v-if="companiesAreVisible && referral.companies.length != 0">
    <base-table-cell :colspan="7">
      <div class="w-11/12">
        <ReferralCompanyTable class="mt-4 w-full ml-12 pl-12">
          <template #table-header>
            <base-table-header
              :key="header.id"
              class="pt-1 pb-1"
              v-for="header in tableHeadersList"
            >
              {{ header }}
            </base-table-header>
          </template>
          <template #table-body>
            <ReferralCompanyRow
              v-for="company in referral.companies"
              :key="company"
              :name="company.company_name"
              :link="company.website_url"
              :address="company.address"
              :campaign="company.campaign"
              :time="company.referral_date"
            ></ReferralCompanyRow>
          </template>
        </ReferralCompanyTable>
      </div>
    </base-table-cell>
  </base-table-row>
</template>

<script setup>
import { defineProps, computed, ref, watch , defineEmits } from "vue";


import ReferralCompanyRow from "./ReferralCompanyRow.vue";
import ReferralCompanyTable from "../ReferralCompanyTable.vue";
import DisabledButton from "../UI/DisabledButton.vue";



const props = defineProps({
  expandedObject: Object,
  changedReferralFilters: Boolean,
  referral: Object
});

const tableHeadersList = ref([
  "Company Name",
  "URL",
  "Address",
  "Campaign",
  "Client",
  "Referral Date",
]);

const emit = defineEmits([
  "reset-changed-company-filters",
  "open-edit",
  "archive-referrer",
]);
var companiesAreVisible = ref(false);

function openEdit() {
  emit("open-edit", props.referral);
}

function archiveReferrer() {
  const formData = JSON.stringify({
    referrer_first_name: props.firstName,
    referrer_last_name: props.lastName,
    status: "inactive",
  });
  emit("archive-referrer", props.id, formData);
}

watch(props.expandedObject, () => {
  companiesAreVisible.value = props.expandedObject.companiesVisible;
});

function toggleView() {
  companiesAreVisible.value = !companiesAreVisible.value;
}

const fullName = computed(function () {
  return props.referral.referrer_first_name + " " + props.referral.referrer_last_name;
});
</script>

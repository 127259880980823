<template>
    <div>
        <CampaignCell :company="company" />
        <base-pill>Tier:</base-pill> <TierCell :company="company" class="inline"/>
        <RemoveFromCampaignCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>

</template>

<script>
    export default {
        name: "campaign-remove-from-campaign-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import CampaignCell from '../company-cell-components/CampaignCell.vue';
import RemoveFromCampaignCell from '../company-cell-components/RemoveFromCampaignCell.vue';

const props = defineProps({
    company: Object
})

const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies(){
    emit('refetch-companies')
}

</script>
<template>
    <div @dblclick="toggleEditable" class="inline-flex items-center align-middle w-full cursor-pointer h-full">
        <slot></slot>
        <span v-if="isEditable" class="w-full h-full">
            <BaseEditSelect 
                :fieldOptions="fieldOptions"
                :modelId="modelId" 
                :modelType="modelType"
                :inputText="fieldValue" 
                :fieldName="fieldName"
                @toggle-editable="toggleEditable"
                @refetch="refetch"
                @update-field-label="setFieldLabel"
            />
        </span>
        <span v-else
            class="h-full w-full"
            :style="displayedFieldStyle"
        >
            <a v-if="inputType=='url' && fieldValue" 
                class="break-all text-accent-primary  hover:text-dark-primary hover:underline hover:cursor-pointer" 
                target="_blank"
                :href="urlPath"
            >
                {{ displayedField }}
            </a>
            <span v-else class="">
                {{ displayedField }}
            </span>
        </span>
    </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, ref, watch } from 'vue';
import BaseEditSelect from './company-cell-components/BaseEditSelect.vue';
import { formatDate } from "@/composables/formatDate";

const props = defineProps([
'modelId',
'modelType',
'fieldName',
'fieldValue',
'displayedFieldValue',
'fieldOptions',
'inputType',
'fieldStyle',
])

const emit = defineEmits(['refetch'])

const fieldLabel = ref()
const isEditable = ref(false)

watch(props, ()=> {
fieldLabel.value = null
})

const displayedField = computed(() => {
if(fieldLabel.value) {
    if(props.inputType === 'date') {
        return formatDate(fieldLabel.value)
    }
    return fieldLabel.value
} else if (props.displayedFieldValue) {
    if(props.inputType === 'date') {
        return formatDate(props.displayedFieldValue)
    }
    return props.displayedFieldValue
} else {
    return '--'
}
})

const displayedFieldStyle = computed(() => {
if(props.fieldStyle) {
    return props.fieldStyle
} else {
    return ''
}
})

const urlPath = computed(() => {
if(props.inputType=='url'){
    if(props.fieldValue) {
        if(props.fieldValue.startsWith('http://') || props.fieldValue.startsWith('https://')) {
            return props.fieldValue
        } else {
            return `https://${props.fieldValue}`
        }
    }
}
return "#"
})

function toggleEditable () {
isEditable.value = !isEditable.value
}

// function setField(newValue) {
// field.value = newValue
// }

function setFieldLabel(newValue) {
    console.log('setFieldLabel')
    fieldLabel.value = newValue
    console.log(fieldLabel.value)
}

function refetch() {
emit('refetch')
}
</script>
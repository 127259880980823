<template>
  <div class="w-full">
    <div class="pt-3 pb-0.5 border-b mx-5 mb-3 border-b-gray-400 flex flex-row justify-between">
      <h1 class="text-sm">Email Templates</h1>
    </div>

    <div class="pb-0.5 mx-5 flex flex-row justify-left">
      <base-pagination-header
              @load-data="loadEmailTemplates(fetchFilter)"
              @get-next="getNext"
              @go-to-first="goToFirst"
              @get-prev="getPrev"
              @go-to-last="goToLast"
              @jump-to-page="jumpToPage"
              @set-page-size-filter="setPageSizeFilter"
              :page="page"
              :page_filter="emailTemplateFilters"
              :numPages="numPages"
              :isLoading="isLoading"
            >
              <template v-slot:message>
                <div class="pl-3 pt-1">
                  {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total
                  Email Templates
                </div>
              </template>
      </base-pagination-header>
      <!-- <h1 class="text-sm mt-2 mr-5">Total: {{ emailTemplates.length }} Email Templates</h1> -->
      <base-button class="text-xs mb-3" @click="openCreateTemplatePage"> Create New Email Template </base-button>
    </div>
    <email-templates-table :headers="tableHeaders" :data="emailTemplates"> </email-templates-table>
  </div>
</template>

<script setup>
import {ref, onMounted, computed, reactive} from 'vue';
import {useStore } from 'vuex';
import { useRouter} from 'vue-router';
import EmailTemplatesTable from './EmailTemplatesTable.vue';
import { getEmailTemplatesFullDetail } from '@/services/EmailTemplates'


const store = useStore();
const router = useRouter();
const emailTemplates = ref([])
const page = ref(null)
const next = ref(null)
const prev = ref(null)
const numPages = ref(null)
const pageSize = ref(null)
const count = ref(null)

const tableHeaders = ref(['ID', 'Email Template Name', 'Mail From', 'Mail To', 'Client', 'Status', 'Actions']);

onMounted(() => {
  loadEmailTemplates();
});

function openCreateTemplatePage() {
    router.push({path: 'create_email_template'})
}
const isLoading = ref(false)

const emailTemplateFilters = reactive({
  pageSizeFilter: 50,
});

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});
  if (emailTemplateFilters.pageSizeFilter) {
    query.append("page_size", emailTemplateFilters.pageSizeFilter); // To append more data
  }
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

async function loadEmailTemplates(endpoint=fetchFilter.value) {
  isLoading.value = true
  let payload = {
      token: store.state.auth.token,
      endpoint: endpoint
  }
  const response = await getEmailTemplatesFullDetail(payload);
  // console.log('result', response)
  isLoading.value = false
  emailTemplates.value = response.results;
  page.value = response.page
  next.value = response.next ? `&page=${response.page + 1}` : null
  prev.value = response.prev ? `&page=${response.page - 1}` : null
  numPages.value = response.numPages
  pageSize.value = response.pageSize
  count.value = response.count
    // console.log(emailTemplates.value)
}

function getNext() {
  if (next.value) {
    loadEmailTemplates(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}
function getPrev() {
  if (prev.value) {
    loadEmailTemplates(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadEmailTemplates(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (numPages.value != page.value) {
    loadEmailTemplates(fetchFilter.value + `&page=${numPages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadEmailTemplates(fetchFilter.value + `&page=${targetPage}`);
}

const viewingFromNbr = computed(function () {
  return page.value * 50 + 1 - 50;
});

const viewingToNbr = computed(function () {
  if (page.value === numPages.value) {
    return count.value;
  } else {
    return page.value * 50;
  }
});

const pageSizeFilter = ref(null)

function setPageSizeFilter(target) {
  target == "clear"
    ? (pageSizeFilter.value = null)
    : (pageSizeFilter.value = target);
  loadEmailTemplates(fetchFilter.value);
}

</script>
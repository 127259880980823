<template>
    <base-dblclick-textarea
        model-type="contact"
        :model-id="companyContact.id"
        field-name="personal_notes"
        :field-value="companyContact.personal_notes"
        @refetch="fetchNewCompanies"
    >
        <slot name="icon"></slot>
    </base-dblclick-textarea>
</template>

<script>
  export default {
      name: "company-contact-personal-notes-cell"
  }
</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies() {
      emit('refetch-companies')
  }
</script>
<template>
  <div class="inline-flex text-sm text-gray-900 pt-1 pb-2.5 ml-2.5">
    <div
      class="lg:col-span-3 flex flex-row items-center justify-start lg:grid lg:grid-cols-3 h-full"
    >
      <div class="flex flex-row col-span-2 items-center">
        <div class="w-36">
          <base-filter-horizontal>
            <template v-slot:label> Pg Size </template>
            <template v-slot:widget>
              <base-list-box-horizontal
                class="w-full rounded-r-md text-xs text-black"
                placeholder="Select"
                :options="pageSizeOptions"
                :modelValue="pageSizeFilter"
                :multiple="false"
                @update:modelValue="setPageSizeFilter"
              />
            </template>
          </base-filter-horizontal>
        </div>

        <div class="ml-4 flex flex-row items-center">
          <base-pagination-arrows
            @get-next="getNext"
            @go-to-first="goToFirst"
            @get-prev="getPrev"
            @go-to-last="goToLast"
            @jump-to-page="jumpToPage"
            :page="page"
            :numPages="numPages"
          ></base-pagination-arrows>
        </div>

        <base-pagination-loader
          @load-data="loadData"
          :isLoading="isLoading"
        ></base-pagination-loader>

        <slot name="message"> </slot>
      </div>
    </div>
  </div>
</template>

<script setup>

// imports

import { ref, defineEmits, defineProps, watch } from "vue";

// constants

const props = defineProps([
  "isLoading",
  "page",
  "numPages",
  "page_filter",
]);
const emit = defineEmits([
  "get-next",
  "load-data",
  "go-to-first",
  "get-prev",
  "go-to-last",
  "jump-to-page",
  "set-page-size-filter",
]);

const pageSizeFilter = ref(50);
const pageSizeOptions = ref([
  { value: "clear", label: "----" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
]);

// watchers

watch(props.page_filter, () => {
  if (props.page_filter.pageSizeFilter) {
    pageSizeFilter.value = props.page_filter.pageSizeFilter;
  } else {
    pageSizeFilter.value = 50;
  }
  
});

// functions

function loadData() {
  emit("load-data");
}

function getNext() {
  emit("get-next");
}

function goToFirst() {
  emit("go-to-first");
}

function getPrev() {
  emit("get-prev");
}

function goToLast() {
  emit("go-to-last");
}

function jumpToPage(targetPage) {
  emit("jump-to-page", targetPage);
}

function setPageSizeFilter(pageSize) {
  emit("set-page-size-filter", pageSize);
}
</script>

<template>
    <div class="flex-col w-full" v-if="parentCompany">
        <div v-if="isSelfOwned" class="flex-col w-full">
            Self-Owned
        </div>
        <div v-else class="flex-col w-full">
            <div>
                <base-pill>ID:</base-pill>
            </div>
            <div>
                {{ companyId }}
            </div>
            <div>
                <base-pill>Name:</base-pill>
            </div>
            <div>
                {{ companyName }}
            </div>
            <div>
                <base-pill>Acquisition Date:</base-pill>
            </div>
            <div>
                {{ acquisitionDate }}
            </div>
        </div>
        <div class="flex-col w-full">
            <div>
                <base-pill>Ownership Type:</base-pill>
            </div>
            <div>
                {{ ownershipType }}
            </div>
        </div>
        <div class="flex-col w-full">
            <div>
                <base-pill>Verified By:</base-pill>
            </div>
            <div>
                {{ verifiedBy }}
            </div>
        </div>
    </div>
    <div v-else>
        --
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { formatDate } from '@/composables/formatDate';


const props = defineProps(['company'])
const emit = defineEmits(['refetch-companies'])


const parentCompany = computed(() => {
    if(props.company.parents) {
        for(let parent of props.company.parents) {
            if(parent.is_current == true) {
                return parent
            }
        }
        // let parents = props.company.parents
        // parents = parents.sort((a, b) => {
        //     return a.ordinal - b.ordinal
        // })
        // let lastParent = parents[parents.length - 1]
        // console.log('last parent')
        // console.log(lastParent)
        // return lastParent
    }
    return null
})

const companyId = computed(() => {
    if(parentCompany.value) {
        if(parentCompany.value.parent) {
            return parentCompany.value.parent.id
        } else {
            return '--'
        }
    } else {
        return '--'
    }
})

const companyName = computed(() => {
    if(parentCompany.value) {
        if(parentCompany.value.parent) {
            return parentCompany.value.parent.company_name
        } else {
            return '--'
        }
    } else {
        return '--'
    }
})

const isSelfOwned = computed(() => {
    if(parentCompany.value) {
        if(parentCompany.value.parent) {
            if(parentCompany.value.parent.id == props.company.id) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    } else {
        return false
    }
})

const verifiedBy = computed(() => {
    if(parentCompany.value) {
        return `${parentCompany.value.verified_by.first_name} ${parentCompany.value.verified_by.last_name}`
    } else {
        return '--'
    }
})

const ownershipType = computed(() => {
    if(parentCompany.value) {
        if(parentCompany.value.parent) {
            if(parentCompany.value.parent.ownership) {
                return parentCompany.value.parent.ownership
            } else {
                return '--'
            }
        } else {
            return '--'
        }
    } else {
        return '--'
    }
})

const acquisitionDate = computed(() => {
    if(parentCompany.value) {
        if(parentCompany.value.acquisition_date) {
            return formatDate(parentCompany.value.acquisition_date)
        } else {
            return '--'
        }
    } else {
        return '--'
    }
})

</script>
<template>
  <div
    class="bg-slate-200 shadow-lg hover:font-extrabold border rounded-xl w-11/12 pb-5 h-full"
    @click="openWindow"
  >
    <img
      class="border rounded-t-xl object-scale-down"
      :src="imageObject.filePath"
      alt="Video Thumbnail"
    />
    <div class="flex flex-col pl-1">
      <div
        class="text-sm sm:text-[8px] md:text-[10px] lg:text-xs xl:text-sm 2xl:text-sm pt-8 pl-2 text-accent-primary"
      >
        Instructional Video
      </div>
      <div
        class="text-sm sm:text-[8px] md:text-[10px] lg:text-xs xl:text-sm 2xl:text-sm pt-8 pb-5 pl-2 text-dark-primary max-w-full sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg"
      >
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["source", "title", "imageObject"]);

function openWindow() {
  window.open(props.source);
}
</script>

<template>
    <div>
        <div v-for="collectionTag in company.collection_tags" :key="collectionTag.id">
            <base-removable-pill @remove="remove(collectionTag.collection_tag.id)">
                {{collectionTag.collection_tag.collection_tag_name}}
            </base-removable-pill>
        </div>
    </div>
</template>

<script>
    export default {
        name: "collection-tag-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { removeCollectionTag } from '@/services/Company';
import {useStore} from 'vuex';

const store = useStore()


const props = defineProps({
    company: [Object],
})

const emit = defineEmits(['refetch-companies'])

async function remove(collectionTagId) {
    const token = store.state.auth.token
    const result = await removeCollectionTag(collectionTagId, props.company.id, token)
    console.log(result)
    emit('refetch-companies')
}

</script>
import authMutations from './mutations.js';
import authActions from './actions.js';
import authGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            token: null,
            user: null,
            userFullName: null,
            userGroups: null,
            expiry: null,
            isLoggedIn: false,
            isManagement: false,
            isHourly: false,
            isResearch: false,
            isAdvancedResearch: false,
            isSuperuser: false,
            isAccountManager: false,
            isAccountStaff: false,
            isDev: false,
            isResearchLead: false,
        };
    },
    mutations: authMutations,
    actions: authActions,
    getters: authGetters
    // ...mapMutations['companyMutations'],
    // ...mapActions['companyActions'],
    // ...mapGetters['companyGetters'],
};
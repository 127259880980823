
<template>
  <div class="flex">
    <BaseButtonVue v-if="!isProjectAssignmentArchived" @click="openEditWindow" class="mr-1 text-sm">Edit</BaseButtonVue>
    <BaseButtonVue v-if="!isProjectAssignmentArchived" @click="openArchiveWindow" class="text-sm" >Archive</BaseButtonVue>
    <div v-if="isProjectAssignmentArchived">Archived</div>
  </div>
</template>

<script setup>
  import { defineProps, ref, defineEmits, watch } from "vue";
  import BaseButtonVue from "@/components/BaseButton.vue";
  const props = defineProps({
    projectAssignment: Object,
  });
  const emit = defineEmits(['open-edit-window', 'open-archive-window'])
  const isResearcherActive = ref(false);
  const isProjectAssignmentArchived = ref(false);

  function openEditWindow() {
    emit('open-edit-window')
  }

  function openArchiveWindow() {
    emit('open-archive-window')
  }

  function checkResearcherActive() {
    isResearcherActive.value = props.projectAssignment.researcher.is_active;
  }

  function checkProjectAssignmentArchived() {
    if (props.projectAssignment.status == "inactive")
      isProjectAssignmentArchived.value = true;
  }

  watch(props, () => {
    checkProjectAssignmentArchived();
  })
  checkProjectAssignmentArchived();
</script>


<template>
  <div class="inline-flex pr-3">
    <base-button class="rounded-r-sm" @click="expandAll"
      >Expand All</base-button
    >
    <base-button class="rounded-l-sm" @click="collapseAll"
      >Collapse All</base-button
    >
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["expand-all", "collapse-all"]);

function expandAll() {
    emit('expand-all');
}

function collapseAll() {
    emit('collapse-all');
}

</script>

<template>
    <!-- <div :style="pageStyling"> -->
<div :class="{'cursor-wait' : loadingCompany}">
    <transition
        enter-active-class="transition duration-400 ease-in"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-400 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="h-full"
    >
    </transition>
    <base-card>
        <PrimeContractFilters @change-prime-contract-filters="setPrimeContractFilters"></PrimeContractFilters>
    </base-card>
    <base-card>
        <PrimeContractCompanyBulkOps 
          :selectedCompanies="selectedCompanies"
          :userPermissions="userPermissions"
          @refetch-companies="loadPrimeContracts()"
          @clear-selected-companies="removeAllSelectedCompanies">
        </PrimeContractCompanyBulkOps>
    </base-card>
    <base-card>
    <div class="flex flex-col text-sm text-gray-900 h-[85vh]">
      <div class="h-full">
        
          <!-- <div v-if="isLoading" class="flex justify-center w-full p-2">
            <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="white"/>
              <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#2E9CCA"/>
            </svg>
            <span class="sr-only">Loading...</span>
          </div> -->
          <div class="flex sticky top-0">

            <div class="px-3 mb-2 flex w-full lg:w-fit flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center 0">
                <input type="checkbox" id="checkbox" class="align-middle" :checked="allSelected" @change="toggleSelectAll"/>
                <p class="align-middle whitespace-nowrap">
                  <span  class="pl-1 align-middle">Select All | </span>
                  <span
                    class="pl-1 align-middle text-accent-primary hover:text-dark-primary hover:cursor-pointer"
                    @click="showModal"
                  >{{selectedCompanies.length}} Selected
                  </span>
                  <span class="align-middle"> | </span>
                  <span class="hover:text-accent-primary hover:cursor-pointer align-middle" @click="removeAllSelectedCompanies">Clear All</span>
                  <base-modal-small 
                    v-if="isModalVisible"
                    @close="closeModal"
                  >
                  <template v-slot:header>
                    Selected Companies
                  </template>
                  <template v-slot:body>
                    <SelectedCompaniesTable :selectedCompanies="selectedCompanies" />
                  </template>
                  </base-modal-small>
                </p>
              </div>
              <div class="flex flex-col justify-start lg:flex-row w-full mb-2">
                <div class="px-3 h-full flex w-full lg:w-fit flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center 0">
                </div>
                <div class="flex flex-row items-center justify-between py-2 border-b border-light-primary lg:py-0 lg:border-none w-full h-full px-2 ">
                  <div v-if="isLoading" class="flex justify-center w-full p-2">
                    <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                      <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#2E9CCA"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
          </div>
          <div  v-if="primeContracts.length > 0" class="h-full overflow-y-auto">
            <base-table>
                <template #table-header>
                <base-table-header class="text-center" :key="header.id" :header="header" v-for="header in tableHeadersList"> {{
                    header
                    }}
                </base-table-header>
                </template>
    
                <template #table-body>
                <PrimeContractRow
                    :key="contract.id"
                    :contract="contract"
                    :campaigns="campaigns"
                    @loading-company="loadCompany"
                    @select-company="addSelectedCompany"
                    @remove-company="removeSelectedCompany"
                    :selected-companies="selectedCompanies"
                    v-for="contract in primeContracts"
                            />
                <base-table-row class="" v-if="next">
                  <base-table-cell :colspan="tableHeadersList.length" >
                    <div class="w-full flex justify-center">
                      <div v-if="isLoading" role="status" class="pl-3">
                      <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="white"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>
                      <base-button @click="loadMoreContracts">Load more</base-button>
                    </div>
                    </div>
                  </base-table-cell>
                </base-table-row>
                </template>
            </base-table>
          </div>
        </div>
    </div>
    </base-card>
</div>
</template>

<script>
export default {
name: 'prime-contracts-table'
}
</script>

<script setup>
import {getPrimeContracts} from '@/services/Contract.js'
import {useStore} from 'vuex';
import {ref, reactive, shallowReactive, computed, watch} from 'vue';
import PrimeContractRow from './PrimeContractRow.vue'
import PrimeContractFilters from './PrimeContractFilters.vue';
import { BASE_URL } from '@/services/api.js';
import { getCampaigns } from '@/services/Campaign';
import PrimeContractCompanyBulkOps from './PrimeContractCompanyBulkOps.vue';
import SelectedCompaniesTable from '../SelectedCompaniesTable.vue';

const store = useStore()
const isLoading = ref(true);
const awaitingSearch = ref(false);
const campaigns = ref([])
const isModalVisible = ref(false)
const selectedCompanies = ref([])
// const selectedCompanyIds = ref([])

function showModal(){
  isModalVisible.value = true
}

function closeModal(){
  isModalVisible.value = false
}

function addSelectedCompany(company) {
  if(!selectedCompanies.value.includes(company)){
    selectedCompanies.value.push(company)
    console.log('selected company ids', selectedCompanies.value)
  }
}

function removeSelectedCompany(company) {
  allSelected.value = false
  selectedCompanies.value.splice(selectedCompanies.value.indexOf(company), 1)
  console.log('selected company ids', selectedCompanies.value)
}

function removeAllSelectedCompanies(){
  allSelected.value = false
  selectedCompanies.value = []
}

const allSelected = ref(false)

function toggleSelectAll(){
  if(allSelected.value){
    removeAllSelectedCompanies()
  } else {
    selectAllCompanies()
  }
}

function selectAllCompanies(){
  allSelected.value = true
  const selectedIds = selectedCompanies.value.map(company => company.id)
  primeContracts.value.forEach((contract) => {
    if(contract.sam_gov_entity){
      if(!selectedIds.includes(contract.sam_gov_entity.company.id)){
        selectedCompanies.value.push(contract.sam_gov_entity.company)
      }
    }
  })
}

// function addSelectedCompany(companyId) {
//   if(!selectedCompanyIds.value.includes(companyId)){
//     selectedCompanyIds.value.push(companyId)
//     console.log('selected company ids', selectedCompanyIds.value)
//   }
// }

// function removeSelectedCompany(companyId) {
//   selectedCompanyIds.value.splice(selectedCompanyIds.value.indexOf(companyId), 1)
//   console.log('selected company ids', selectedCompanyIds.value)
// }

const tableHeadersList = ref([
'',
'Award ID',
'Recipient',
'Transaction Description',
'NAICS Code',
'Total Dollars Obligated',
'Start Date',
'End Date',
'Action Date',
'Awarding Agency',
'Awarding Sub Agency',
'Major Program',
'PSC',
'Expand'
])

const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
  });

  const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    // console.log('user permissions store', userPermissionsStore)
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies =  userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  
    // console.log('user permissions', userPermissions)
  }

  setUserPermissions()


const contractFilters = reactive({
  awardingSubAgencyName: null,
  awardingAgencyName: null,
  awardIdPIID: null,
  recipientUEI: null,
  cageCode: null,
  naicsCode: null,
  majorProgram: null,
  transactionDescription: null,
  psc: null
});

const pageSizeFilter = reactive({
pageSizeFilter: 50
})

const primeContracts = ref([])
const next = ref(null);
const prev = ref(null);
const loadingCompany = ref(false)

function loadCompany(isLoading){
  loadingCompany.value = isLoading
}

const fetchFilter = computed(function () {
  let query = new URLSearchParams({
  });
  if (contractFilters.awardingAgencyName) {
      query.append("awarding_agency_name", contractFilters.awardingAgencyName)
  }
  if (contractFilters.awardingSubAgencyName) {
      query.append("awarding_sub_agency_name", contractFilters.awardingSubAgencyName)
  }
  if (contractFilters.awardIdPIID) {
      query.append("award_id_piid", contractFilters.awardIdPIID)
  }
  if (contractFilters.recipientUEI) {
      query.append("recipient_uei", contractFilters.recipientUEI)
  }
  if (contractFilters.cageCode) {
      query.append("cage_code", contractFilters.cageCode)
  }
  if (contractFilters.naicsCode) {
      query.append("naics_code", contractFilters.naicsCode)
  }
  if (contractFilters.majorProgram) {
      query.append("major_program", contractFilters.majorProgram)
  }
  if (contractFilters.transactionDescription) {
      query.append("transaction_description", contractFilters.transactionDescription)
  }
  if (contractFilters.psc) {
    console.log('psc', contractFilters.psc)
      query.append("product_or_service_code", contractFilters.psc)
  }
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = `${BASE_URL}/prime_contracts/?` + query.toString();
  } else {
    url = `${BASE_URL}/prime_contracts/?`;
  }
  // console.log('url', url);
  return url
})

async function loadPrimeContracts(queryParams = fetchFilter.value) {
isLoading.value = true;
const token = store.state.auth.token 
const result = await getPrimeContracts(token, queryParams);
  primeContracts.value = result.contracts;
  next.value = result.next
  prev.value = result.previous
  isLoading.value = false;

}

function setPageSizeFilter(target=null){
if(target) {
  target == "clear" ? (pageSizeFilter.pageSizeFilter = 50) : (pageSizeFilter.pageSizeFilter = target);
  loadPrimeContracts(fetchFilter.value)
}
}

function setPrimeContractFilters(updatedFilters) {
    for(const filter in updatedFilters){
        contractFilters[filter] = updatedFilters[filter].value
    }
}

async function loadCampaigns() {
  const token  = store.state.auth.token
  const result = await getCampaigns(token)
  campaigns.value = result

}

// function getNext() {
// if (next.value) {
//   loadEngagements(fetchFilter.value + next.value)
// } else {
//   alert("No next page")
// }
// }

// function getPrev() {
// if (prev.value) {
//   loadEngagements(fetchFilter.value + prev.value)
// } else {
//   alert("No prev page")
// }
// }


watch(() => contractFilters, () => {
if(!awaitingSearch.value) {
      setTimeout(() => {
        loadPrimeContracts(fetchFilter.value);
        awaitingSearch.value = false;
      }, 2000); // 2.5 sec delay
    }
    awaitingSearch.value = true
}, {deep: true})

async function loadMoreContracts(){
  console.log('load more contracts')
  isLoading.value = true;
  const token = store.state.auth.token 
  const result = await getPrimeContracts(token, next.value);
  primeContracts.value = primeContracts.value.concat(result.contracts);
  next.value = result.next
  prev.value = result.previous
  isLoading.value = false;

}

loadPrimeContracts()



</script>
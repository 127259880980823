<template>
    <div>
        <div class="flex flex-col space-y-1 p-1" v-if="allClientPostsVisible">
            <div class="flex flex-col" v-for="(post, idx) in client.client_posts" :key="post.id">
                <div class="flex w-fit">
                    {{ formatDateTwoDigitYear(post.created_at) }}: {{ post.post_body }}
                    <div class="px-1">
                        <button v-if="client.client_posts.length > 1 && idx === 0" class="text-xs flex items-center  px-0.5 rounded-full h-fit hover:text-white hover:bg-dark-primary" @click="setClientPostsVisibility(false)">^</button>
                    </div>
                </div>
                <div class="w-full border-b border-b-black" v-if="idx < client.client_posts.length - 1"></div>
            </div>
        </div>
        <div v-else-if="client.client_posts.length > 0">
            <div class="flex w-fit" >
                    {{ formatDateTwoDigitYear(client.client_posts[0].created_at) }}: {{ client.client_posts[0].post_body }} 
                    <div class="px-1">
                        <button class="text-xs flex items-center px-0.5 rounded-full h-fit hover:text-white hover:bg-dark-primary" v-if="client.client_posts.length > 1" @click="setClientPostsVisibility(true)">⌄</button>
                    </div>
            </div>
        </div>
    </div>
    <div class="w-full text-center">
        <base-button @click="showPostsForm" class="w-1/2">+</base-button>
    </div>
    <base-modal :is-form-visible="postsFormVisible" @close="closePostsForm">
        <template #header>
            Client Posts 
        </template>
        <template #body>
            <ClientPostsForm :client="client" @refetch-clients="fetchNewClients" />
        </template>
    </base-modal>
</template>

<script>
export default {
    name: "client-posts-cell"
}
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { formatDateTwoDigitYear } from '@/composables/formatDate';
import ClientPostsForm from './ClientPostsForm.vue';

const allClientPostsVisible = ref(false)
const postsFormVisible = ref(false)

function showPostsForm() {
    postsFormVisible.value = true 
}

function closePostsForm() {
    postsFormVisible.value = false
}

const props = defineProps({
    client: Object
})

const emit = defineEmits(['refetch-clients'])

function setClientPostsVisibility(target) {
    allClientPostsVisible.value = target 
}

function fetchNewClients(){
    emit('refetch-clients')
}


</script>


import axios from 'axios';
import { BASE_URL } from './api';

export async function updateCompanyContact(contactId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/company_contacts/${contactId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function createCompanyContact(contactData, token) {
    return axios({
        method: "post",
        url: `${BASE_URL}/company_contacts/`,
        headers: {
            "Authorization": `Token ${token}`
        },
        data: contactData
    })
    .then(response => response.data)
    .catch(error => alert(error))
}

export async function deleteCompanyContact(contactId, token) {
    return axios({
        method: "delete",
        url: `${BASE_URL}/company_contacts/${contactId}/`,
        headers: {
            "Authorization": `Token ${token}`,
            'Content-Type': 'application/json'
        },
    })
    .then(response => response.data)
    .catch(error => alert(error))
}
<template>
    <div>
        {{ isComplete}}
        <br>
        <div
        v-if="
            permissions.isDev ||
            permissions.isAdvancedResearch ||
            permissions.isAccountManager ||
            permissions.isManagement ||
            permissions.isSuperuser
            "
        >
          <base-button v-if="company.research_complete == 1" @click="promoteOrDemote('demote')">Demote</base-button>
          <div v-else-if="company.research_complete == -1">
            <base-button  @click="promoteOrDemote('demote')">Resume</base-button>
            <base-button  @click="promoteOrDemote('promote')">Promote</base-button>
          </div>
          <base-button v-else-if="notURLVetted" class="pointer-events-none bg-gray-300 text-gray-600">Vet URL Before Changing</base-button>
          <div class="flex flex-col" v-else-if="company.research_complete == 0">
              <base-button @click="promoteOrDemote('promote')">Promote</base-button>
              <base-button @click="promoteOrDemote('halt')">Halt</base-button>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "research-complete-cell"
    }
</script>

<script setup>  
import { defineProps, defineEmits, computed } from 'vue';
import { useStore } from 'vuex';
import { promoteOrDemoteResearch } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        company: Object,
        permissions: Object,
    })

    const emit = defineEmits(["refetch-companies"])

    const isComplete = computed(() => {
        if(props.company.research_complete === 1){
            return "Complete"
        } else if (props.company.research_complete === 0) {
            return "Not Done"
        } else {
            return "Halted"
        }
    })

    async function promoteOrDemote(action) {
                const token = store.state.auth.token
                await promoteOrDemoteResearch(props.company.id, action, token)
                emit('refetch-companies')
            }

    const notURLVetted = computed(()=>{
        // console.log(props.company.url_vetted_by)
        if(props.company.url_vetted_by){
            return false
        } else {
            return true
        }
    })



</script>
<template>
    <tr class="w-full">
        <td class=" p-2">
            {{ company.company_name }}
        </td>
        <td class=" p-2">
            {{ company.website_url }}
        </td>
        <td class=" p-2">
            {{ company.city }}
        </td>
        <td class=" p-2">
            {{ company.state }}
        </td>
        <td class=" p-2">
            {{ company.country }}
        </td>
        <td class=" p-2">
            {{ company.revenue }}M
        </td>
        <td class=" p-2">{{ company.employee_headcount }} ppl</td>
    </tr>
    <tr class="w-full p-2 mb-1 border-b border-b-gray-300">
        <td colspan="7">
            <div class="flex">
                <span><base-pill>PC:</base-pill> {{ company.parent_company }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><base-pill>INV:</base-pill> {{ company.investors }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="flex-wrap"><base-pill>Notes:</base-pill> {{ company.account_manager_research_notes }}</span>
            </div>
        </td>
    </tr>
    <hr>
</template>

<script>
    export default {
        name: "pdf-table-row"
    }
</script>

<script setup>
import { defineProps, ref } from 'vue';

    const props = defineProps({
        company: Object
    })

</script>
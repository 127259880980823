<template>
    <div>
        <base-table>
            <template #table-header>
                <base-table-header-width-full class="pt-3 pb-3" v-for="(header, index) in tableHeaders" :key="index"
                    :header="header">
                    {{ header }}
                </base-table-header-width-full>
            </template>
            <template #table-body>
                <BusinessTypesSectorsSubSectorsRow v-for="(dataRow, index) in data" @refetch-table="refetchTable" :key="index" :row="dataRow"
                    :tableType="tableType" />
            </template>
        </base-table>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import BusinessTypesSectorsSubSectorsRow from './BusinessTypes+Sectors+SubSectorsRow.vue'

const props = defineProps({
    data: Array,
    tableHeaders: Array,
    tableType: String
})
const emit = defineEmits(['refetch-table'])

function refetchTable() {
    emit('refetch-table')
}

</script>
<template>
    <div class="pl-4 py-2  flex flex-col bg-gray-50 h-full w-full ">
            <div class="w-full h-full flex ">
                <div class="w-[20%] flex flex-col h-full">
                    <div class="font-semibold  tracking-widest flex justify-start items-center  py-3 ">
                        <div class="pr-1 text-gray-600">
                            CRM Summary:
                        </div>
                        <div class="h-6 w-6 p-0.5 rounded-full hover:bg-gray-300 cursor-pointer relative"
                            ref="helpBoundary"
                        >
                            <HelpSVG
                                @click="showHelpPopover"
                                stroke="#4B5563"
                            />
                            <base-popover-click
                                v-if="visibleHelpPopover"
                                :xPosition="xPosition"
                                :yPosition="yPosition"
                            >
                                <div class="grid grid-cols-2 tracking-normal font-normal relative z-50 pb-4">
                                    <div class="flex space-x-4 border-b col-span-2 tracking-wider font-semibold border-gray-200 p-1">
                                        <div>
                                            Hotkeys
                                        </div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 1</div>
                                        <div>Email 1</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 2</div>
                                        <div>Email 2</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 3</div>
                                        <div>Email 3</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 4</div>
                                        <div>Email 4</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 5</div>
                                        <div>Other</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 6</div>
                                        <div>Cold Call Attempt</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 7</div>
                                        <div>Cold Call Success</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 8</div>
                                        <div>LInkedIn DM</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 9</div>
                                        <div>Initial Response</div>
                                    </div>
                                    <div class="flex space-x-4 border-b border-gray-200 p-1">
                                        <div>CTRL + 0</div>
                                        <div>Additional Response</div>
                                    </div>    
                                </div>
                            </base-popover-click>
                        </div>
                    </div>
                    <div class="">
                        <textarea 
                        name="" 
                        v-model="companyCRMSummary" 
                        class="border border-gray-300 rounded-md p-1 w-full" 
                        id="" 
                        cols="30" 
                        rows="14"
                        @keydown.ctrl.1.exact="handleHotKey(2)"
                        @keydown.ctrl.2.exact="handleHotKey(3)"
                        @keydown.ctrl.3.exact="handleHotKey(4)"
                        @keydown.ctrl.4.exact="handleHotKey(21)"
                        @keydown.ctrl.5.exact="handleHotKey(19)"
                        @keydown.ctrl.6.exact="handleHotKey(5)"
                        @keydown.ctrl.7.exact="handleHotKey(6)"
                        @keydown.ctrl.8.exact="handleHotKey(7)"
                        @keydown.ctrl.9.exact="handleHotKey(8)"
                        @keydown.ctrl.0.exact="handleHotKey(22)"

                        @keydown.meta.z="resetSummary()"
                        ></textarea>
                    </div>
                    <div class="overflow-y-scroll pb-28 h-full">

                        <div class="py-3 font-semibold border-b border-gray-300 tracking-widest text-gray-600">
                            Calculated Summary:
                        </div>
                        <div class="flex flex-col space-y-1 text-gray-800  w-full">
                            <div 
                                v-for="row in validRows"
                                :key="row.id"
                                class="whitespace-pre-wrap px-1 py-2 border-b bg-gray-50 border-gray-300">
                                <div class="flex justify-between pb-1 mb-1 tracking-wider">
                                    <div class="flex justify-start ">
                                        <span class="font-semibold ">{{ formatDate(row.resolved_date) }}</span>
                                        <span class="font-semibold text-gray-800 truncate tracking-wide"> {{ rowUser(row.user_id, row.user) }}</span>
                                    </div>

                                    <span class="font-semibold">{{ rowActionType(row.action_id) }}</span>
                                </div>
                                <div>
                                    <div>{{ rowLabelValue('Notes', row.action_notes) }}</div>
                                    <div>{{ rowLabelValue('Details', row.action_details) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex w-[80%] flex-col justify-start h-full">
                    <div class="flex w-full  items-center py-3 ">
                        <div class="w-12  py-0.5 flex justify-end items-center">
                        </div>
                        <div class="flex space-x-5 w-full justify-start items-center">
                            <div class="pl-1 tracking-widest font-semibold text-gray-600">
                                Events:
                            </div>
                            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                                <div class="mr-1 w-5 h-5 rounded bg-emerald-200 border border-gray-300">

                                </div>
                                <div>
                                    Existing Event
                                </div>
                            </div>
                            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                                <div class="mr-1 w-5 h-5 rounded bg-sky-200 border border-gray-300">

                                </div>
                                <div>
                                    Valid Row
                                </div>
                            </div>
                            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                                <div class="mr-1 w-5 h-5 rounded bg-amber-100 border border-gray-300">

                                </div>
                                <div>
                                    Empty Row
                                </div>
                            </div>
                            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                                <div class="mr-1 w-5 h-5 rounded bg-red-400 border border-gray-300">

                                </div>
                                <div>
                                    Invalid Row
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end w-fit pr-1">
                            <button class="bg-dark-secondary text-white hover:bg-dark-primary px-2 py-1 rounded"
                                @click="resolve"
                            >
                                Resolve
                            </button>
                        </div>
                        <div class="w-12  py-0.5 flex justify-end items-center">
                        </div>
                    </div>
                    <div class='w-full flex'>
                        <div class="w-12  px-2 py-0.5 flex justify-end items-center">
                        </div>
                        <div class="w-full grid grid-cols-12 border-gray-300 rounded-t">
                            <div class="w-full bg-gray-50 border-y col-span-2 border-x rounded-tl border-gray-300 px-3 py-1.5 font-semibold">
                                Event*
                            </div>
                            <div class="w-full bg-gray-50 border-y border-r col-span-1 border-gray-300 px-3 py-1.5 font-semibold">
                                Event Date*
                            </div>
                            <div class="w-full bg-gray-50 border-y border-r col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                                User
                            </div>
                            <div class="w-full bg-gray-50 border-y border-r col-span-3 border-gray-300 px-3 py-1.5 font-semibold">
                                Event Notes
                            </div>
                            <div class="w-full bg-gray-50 border-y border-r col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                                Event Details
                            </div>
                            <div class="w-full bg-gray-50 border-y border-r col-span-2 rounded-tr border-gray-300 px-3 py-1.5 font-semibold">
                                Campaign
                            </div>
                        </div>
                        <div class="w-12  px-3 py-0.5 flex justify-end items-center">
                            <button class="bg-dark-secondary text-white hover:bg-dark-primary w-5 h-5 rounded-full"
                                @click="addRow" 
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div class="overflow-y-scroll h-[calc(100vh-13.5rem)] ">
                        <TransitionGroup name="actions">
                            <CRMActionRow 
                                v-for="crmAction in orderedRows"
                                :key="crmAction.id"
                                :crmAction="crmAction"
                                :crmActionTypeOptions="crmActionTypeOptions"
                                :userOptions="userOptions"
                                :campaignId="campaignId"
                                :campaignOptions="campaignOptions"
                                @update-action-row="updateRow"
                                @parse-selected-to-row="parseSelectedToRow"
                                @remove-action-row="removeRow"
                            />
                        </TransitionGroup>
                    </div>
                </div>
            </div>
    </div>
</template>

<script setup>
import { useClipboard } from '@vueuse/core'
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { formatDate } from '@/composables/formatDate';
import CRMActionRow from './CRMActionRow.vue';
import HelpSVG from '../svg-components/HelpSVG.vue';
import { useClickOutside } from '@/composables/useClickOutside';
import { parseCRMActionFromString } from '@/composables/Parser';

const props = defineProps(['rowData', 'selection', 'lastSelected', 'crmActionTypeOptions', 'userOptions'])
const emit = defineEmits(['resolve'])

const helpBoundary = ref(null)
const xPosition = ref(0)
const yPosition = ref(0)

const visibleHelpPopover = ref(false)

const rowStyle = ref('background-color: #FFF;')

watch(() => props.rowData, ()=> {
    companyCRMSummary.value = props.rowData.data.crm_summary
    crmActions.value = props.rowData.data.crm_actions.map(action => ({...action, type: 1})).concat(props.rowData.data.computed_crm_actions)
})

const companyCRMSummary = ref(props.rowData ? props.rowData.data.crm_summary : '')

const validRows = computed(() => {
    let validRows = []
    for(let actionRow of crmActions.value){
        if(actionRow.action_id) {
            if(actionRow.resolved_date) {
                // valid
                validRows.push(actionRow)
            }
        }
    }
    return validRows.sort((a, b) => {
        return new Date(b.resolved_date) - new Date(a.resolved_date);
    })
})

const { copy, isSupported } = useClipboard({ source: companyCRMSummary })


const crmActions = ref(props.rowData ? props.rowData.data.crm_actions.map(action => ({...action, type: 1})).concat(props.rowData.data.computed_crm_actions) : [])

watch(() => crmActions.value, () => {

}, {deep: true})

watch(() => props.lastSelected, () => {
    
})

const orderedRows = computed(() => {
    return crmActions.value.toSorted((a, b) => {
        return a.type - b.type || a.action_order - b.action_order
        // order by type
        // order by empty or not
        // order by action type
    })
})

const companyId = computed(() => {
    return props.rowData ? props.rowData.data.id : null
})

const campaignId = computed(() => {
    return props.rowData ? props.rowData.data.campaign.id : null
})

const campaignOptions = computed(() => {
    return props.rowData.data.all_campaigns ? props.rowData.data.all_campaigns : []
})



function showHelpPopover() {
    xPosition.value = helpBoundary.value.getBoundingClientRect().left
    yPosition.value = helpBoundary.value.getBoundingClientRect().bottom
    visibleHelpPopover.value = true
}

function hideHelpPopover() {
    visibleHelpPopover.value = false
    xPosition.value = 0
    yPosition.value = 0
}

useClickOutside(helpBoundary, ()=> {
  hideHelpPopover()
})

function rowUser(userId, userObject) {
    if(userId) {
        let userMatch = props.userOptions.find((user) => user.value == userId)
        if(userMatch) {
            return `: ${userMatch.label}`
        }
    }else if(userObject) {
        return `: ${userObject.first_name} ${userObject.last_name}`
    }
    return ''
}

function rowActionType(actionTypeId) {
    // console.log('rowActionType')
    let actionType = props.crmActionTypeOptions.find((actionType) => actionType.value == actionTypeId)
    // console.log(actionType)
    return actionType.label
}

function rowLabelValue(label, actionNotes) {
    if(actionNotes && actionNotes != ''){
        return `${label}: ${actionNotes}`
    }
    return ''
}


function removeRow(rowId) {
    console.log('removeRow', rowId)
    let rowIndex = crmActions.value.findIndex((row) => row.id == rowId)
    crmActions.value.splice(rowIndex, 1)
}

function checkIfActionRowEmpty(actionId) {
    for(let actionRow of orderedRows.value) {
        if(actionRow.action_id) {
            if(actionRow.action_id == actionId) {
                if(!actionRow.resolved_date && !actionRow.user && !actionRow.action_notes && !actionRow.action_details) {
                    console.log('found empty row')
                    return actionRow
                }
            }
        } else if (actionRow.action) {
            if(actionRow.action.id == actionId) {
                if(!actionRow.resolved_date && !actionRow.user && !actionRow.action_notes && !actionRow.action_details) {
                    console.log('found empty row')
                    return actionRow
                }
            }
        }

    }
    return null
}

async function handleHotKey(actionId) {
    // console.log('handleHotKey', actionID)
    // console.log('selection:')
    // console.log(selection.text.value)
    let parsedCRMAction = parseCRMActionFromString(props.selection.text.value)
    console.log('parsedCRMAction:')
    console.log(parsedCRMAction)
    if (isSupported.value) {
        await copy();
        companyCRMSummary.value = companyCRMSummary.value.replace(props.selection.text.value, '')
    }
    let emptyRow = checkIfActionRowEmpty(actionId)
    if(emptyRow) {
        // console.log('add to empty row')
        // console.log(emptyRow)
        emptyRow.resolved_date = parsedCRMAction[1]
        emptyRow.user_id = parsedCRMAction[2]
        emptyRow.action_notes = parsedCRMAction[4]
        console.log(emptyRow)
    } else {
        // console.log('add new row')
        emptyRow = addRow()
        emptyRow.action_id = actionId
        emptyRow.resolved_date = parsedCRMAction[1]
        emptyRow.user_id = parsedCRMAction[2]
        emptyRow.action_notes = parsedCRMAction[4]
    }
    
}

async function parseSelectedToRow(rowId) {
    // console.log('rowId', rowId)
    // console.log('parseSelectedToRow:')
    // console.log('lastSelected', props.lastSelected)
    let parsedCRMAction = parseCRMActionFromString(props.lastSelected)
    console.log('parsedCRMAction:')
    console.log(parsedCRMAction)
    if (isSupported.value) {
        await copy();
        companyCRMSummary.value = companyCRMSummary.value.replace(props.lastSelected, '')
    }
    let actionRow = orderedRows.value.find((row) => row.id == rowId)
    // console.log('found actionRow')
    // console.log(actionRow)
    actionRow.resolved_date = parsedCRMAction[1]
    actionRow.user_id = parsedCRMAction[2]
    actionRow.action_notes = parsedCRMAction[4]
}

function resetSummary() {
    props.rowData ? companyCRMSummary.value = props.rowData.data.crm_summary : companyCRMSummary.value = ''
    
}

function addRow() {
    const newActionRow = {
        id: Math.floor(Math.random() * -100000),
        // action_name: null,
        action_id: null,
        resolved_date: null,
        user_id: null,
        action_notes: null,
        action_details: null,
        type: 0,
        action_order: 22,
    }
    crmActions.value.splice(0, 0, newActionRow)
    return newActionRow
}

function updateRow(actionRow) {
    console.log('CRMViewPort: updateRow')
    console.log(actionRow)
    let existingRow = crmActions.value.find((row) => row.id == actionRow.id)
    // existingRow.action_name = actionRow.action_name
    existingRow.action_id = actionRow.action_id
    existingRow.resolved_date = actionRow.resolved_date 
    existingRow.user_id = actionRow.user_id 
    existingRow.action_notes = actionRow.action_notes 
    existingRow.action_details = actionRow.action_details 
    existingRow.action_order = actionRow.action_order
    existingRow.type = actionRow.type
    existingRow.campaign_id = actionRow.campaign_id
}

function resolve() {
    emit('resolve', companyId.value, crmActions.value)
}



</script>

<style scoped>
.actions-move,
.actions-enter-active {
    transition: all 0.1s ease;
}
.actions-leave-active {
  transition: all 0.08s ease;
}
.actions-enter-from,
.actions-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.actions-leave-active {
    position: relative;
    /* position: relative; */
}
</style>
<template>
    <div>
        <base-pill>ZI:</base-pill> {{fullZiAddress}}
    </div>

</template>

<script>
    export default {
        name: "zi-location-cell"
    }
</script>

<script setup>
    import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const fullZiAddress = computed(() => {
        if(props.company.zoom_info_company){
            return `${props.company.zoom_info_company.street ? props.company.zoom_info_company.street : "--"} ${props.company.zoom_info_company.city ? props.company.zoom_info_company.city : "--"}, ${props.company.zoom_info_company.state ? props.company.zoom_info_company.state : "--"} ${props.company.zoom_info_company.country ? props.company.zoom_info_company.country : "--"}`
        }else {
            return '--'
        }
    })
</script>
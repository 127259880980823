<template>
    <base-dblclick-input
        modelType="company"
        :modelId="company.id"
        fieldName="parent_company"
        :fieldValue="company.parent_company"
        inputType="text"
        @refetch="fetchNewCompanies"
    >
        <base-pill>PC: </base-pill>
    </base-dblclick-input>
</template>

<script>
  export default {
      name: "parent-company-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
export default {
    beforeMount: (el, binding) => {
        el.onDoubleClick = event => {
            binding.value(event);    
        }   
        document.addEventListener("dblclick", el.onDoubleClick)
    },
    unmounted: el => {
        document.removeEventListener("dblclick", el.onDoubleClick)
    },
}
<template>
    <div class="w-full">
        <h2 class="text-lg font-bold">
        <slot name="table-title"></slot>
        </h2>
        <table v-if="alignment === 'center'" class="text-center bg-white table-fixed w-full rounded-lg">
        <thead class="bg-amber-700">
            <slot name="table-header"></slot>
        </thead>
        <tbody class="">
            <slot name="table-body"></slot>
        </tbody>
        </table>
        <table v-else-if="alignment === 'right'" class="text-right bg-white table-fixed w-full rounded-lg">
        <thead class="bg-amber-700">
            <slot name="table-header"></slot>
        </thead>
        <tbody>
            <slot name="table-body"></slot>
        </tbody>
        </table>
        <table v-else class="text-left bg-white table-fixed w-full rounded-lg">
        <thead class="relative top-0 left-0 right-0 z-10">
            <slot name="table-header"></slot>
        </thead>
        <tbody class="overflow-y-auto">
            <slot name="table-body"></slot>
        </tbody>
        </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "BaseTableCondensed.vue",
    props: ['alignment']
  }
  </script>
  
  <style scoped>
  </style>
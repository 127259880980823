<template>
    <div @dblclick="toggleEditable">
         <span v-if="isEditable">
            <select class="border rounded-md" @change="handleInputChange" :value="companyContact.is_primary_contact" name="" id="">
                <option v-for="option in selectOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
        </span>
        <span v-else >{{ companyContact.is_primary_contact }}</span>
    </div>
</template>

<script>
  export default {
      name: "company-contact-is-primary-cell"
  }

</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";
import {updateContactCompanyRelationship} from '@/services/Contact.js'
import { useStore } from "vuex";

  const isEditable = ref(false)

  const store = useStore()

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

  function toggleEditable () {
      isEditable.value = !isEditable.value
  }

  const selectOptions = ref([
    {value: false, label: "False"},
    {value: true, label: "True"}
  ])

  const contactFirstName = computed(() => {
    return props.companyContact.first_name ? props.companyContact.first_name : "--"
  })

  async function handleInputChange(e) {
            let newValue = e.target.value
            const targetField = "is_primary_contact"
            let updatedData = {}
            updatedData[targetField] = newValue
            const data = JSON.stringify(updatedData);
            console.log('data', data)
            let token = store.state.auth.token
            console.log('new value', newValue)
            await updateContactCompanyRelationship(props.companyContact.id, data, token)
            emit('refetch-companies')
            isEditable.value = false

        }

</script>
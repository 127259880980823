<template>
    <CalculatedCRMSummary
        v-if="company.resolved_crm_summary"
        :crm-actions="company.crm_actions"
        :current-campaign="currentCampaign"
    />
    <base-dblclick-textarea
        v-else
        model-type="company"
        :model-id="company.id"
        field-name="crm_summary"
        :field-value="company.crm_summary"
        @refetch="fetchNewCompanies"
    ></base-dblclick-textarea>
</template>

<script>
    export default {
        name: "crm-summary-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { formatDate } from '@/composables/formatDate';
import CalculatedCRMSummary from '../company/CalculatedCRMSummary.vue';

    const props = defineProps({
        company: Object,
    })

    const currentCampaign = computed(() => {
        return props.company.campaign ? props.company.campaign.id : null
    })

    const emit  = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        // console.log('refetchCompanies CRMSummaryCell')
        emit('refetch-companies')
    }


</script>
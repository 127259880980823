import axios from "axios";
import { BASE_URL } from "./api";


// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     console.log('error found in interceptors')
//     return Promise.reject(error);
//   });

export async function getPageConfigs (token, filters) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/company_page_configurations/?${filters}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });
}

export async function createPageConfig (token, data) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/company_page_configurations/create_page_config/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response;
    }).catch((error) => {
        alert(error)
    });
}

export async function updatePageConfig (token, data, configId) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/company_page_configurations/${configId}/update_page_config/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response;
    }).catch((error) => {
        alert(error)
    });
}

export async function deletePageConfig(token, configId) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/company_page_configurations/${configId}/delete_config/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        alert(error)
    });
}

export async function getPageConfig (token, pageConfigId) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/company_page_configurations/${pageConfigId}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });
}
import axios from 'axios';
import { BASE_URL } from './api';

export async function createCompanyParent(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/parents_subsidiaries/create_parent_subsidiary/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        // console.log('response', response)
        return response
    }).catch((error) => {
        // console.log('error', error)
        return error
    })
}

export async function updateCompanyParent(parentSubsidiaryId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/parents_subsidiaries/${parentSubsidiaryId}/update_parent_subsidiary/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function markCompanyParentVerified(parentSubsidiaryId, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/parents_subsidiaries/${parentSubsidiaryId}/mark_verified/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function deleteCompanyParent(parentSubsidiaryId, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/parents_subsidiaries/${parentSubsidiaryId}/delete_parent_subsidiary/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
}

export async function updateCompanyParentOrder(data, companyId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/parents_subsidiaries/update_parent_subsidiary_order/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'acquisitions': data,
            'company_id': companyId
        }
    })
    .then(response => response)
    .catch(error => error)
}
<template>
    <div class="flex flex-col justify-center items-center">
        <div class="pb-2">
            Clear the LZ!
        </div>  
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="isolation:isolate" viewBox="0 0 90 90" width="90pt" height="90pt">
                <defs>
                    <clipPath id="_clipPath_ROEkzQM8k7jtg2tWyvjEDu4MQhiSVzXp">
                        <rect width="90" height="90"/>
                    </clipPath>
                </defs>
                <g clip-path="url(#_clipPath_ROEkzQM8k7jtg2tWyvjEDu4MQhiSVzXp)">
                    <path d=" M 29.753 31.874 L 4.94 27.705 L 4.94 23.713 L 4.94 23.713 L 4.94 23.713 L 4.94 23.713 L 4.94 23.713 L 4.94 23.713 L 4.94 23.713 L 0 15.406 L 4.94 15.406 L 10.58 22.069 L 35.905 22.069 L 35.905 32.907 L 35.905 32.907 L 35.905 39.866 L 33.03 39.81 L 29.753 31.874 L 29.753 31.874 L 29.753 31.874 Z  M 41.301 22.069 L 35.905 22.069 L 35.905 22.069 L 35.905 22.069 L 35.905 22.069 L 35.905 28.013 L 69.968 28.013 L 69.968 22.069 L 62.508 22.069 C 61.889 20.422 60.118 19.231 58.032 19.231 L 45.777 19.231 C 43.691 19.231 41.92 20.422 41.301 22.069 L 41.301 22.069 L 41.301 22.069 L 41.301 22.069 L 41.301 22.069 L 41.301 22.069 L 41.301 22.069 Z  M 69.968 22.069 L 69.968 22.069 C 76.791 22.069 82.33 26.044 82.33 30.94 C 82.33 35.835 76.791 39.81 69.968 39.81 L 69.968 39.81 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 L 69.968 22.069 Z  M 50.027 14.061 L 53.781 14.061 C 54.739 14.061 55.517 14.815 55.517 15.743 L 55.517 15.743 C 55.517 16.671 54.739 17.424 53.781 17.424 L 50.027 17.424 C 49.07 17.424 48.292 16.671 48.292 15.743 L 48.292 15.743 C 48.292 14.815 49.07 14.061 50.027 14.061 L 50.027 14.061 L 50.027 14.061 L 50.027 14.061 L 50.027 14.061 L 50.027 14.061 Z  M 14.451 14 L 45.417 14 L 45.417 16.811 L 14.451 16.811 L 14.451 14 L 14.451 14 L 14.451 14 L 14.451 14 L 14.451 14 Z  M 58.614 14 L 89.58 14 L 89.58 16.811 L 58.614 16.811 L 58.614 14 L 58.614 14 L 58.614 14 L 58.614 14 Z  M 32.403 43.995 C 32.403 42.856 33.328 41.931 34.468 41.931 C 35.607 41.931 36.532 42.856 36.532 43.995 C 36.532 45.135 35.607 46.06 34.468 46.06 C 33.328 46.06 32.403 45.135 32.403 43.995 L 32.403 43.995 Z  M 74.097 43.995 C 74.097 42.856 75.022 41.931 76.161 41.931 C 77.3 41.931 78.225 42.856 78.225 43.995 C 78.225 45.135 77.3 46.06 76.161 46.06 C 75.022 46.06 74.097 45.135 74.097 43.995 Z " fill-rule="evenodd" fill="rgb(37,39,77)"/>
                    <path d=" M 52.985 46.603 L 52.985 68.242 L 52.985 70 L 52.985 46.06 L 52.985 46.06 L 52.985 46.06 L 52.985 46.603 L 52.985 46.603 L 52.985 46.603 Z  M 39.97 27.488 L 45.97 42.488 L 45.97 42.488 L 39.97 27.488 L 39.97 27.488 L 39.97 27.488 L 39.97 27.488 L 39.97 27.488 Z  M 66 27.488 L 60 42.488 L 66 27.488 L 66 27.488 L 66 27.488 L 66 27.488 Z  M 45.97 42.488 L 45.97 42.488 L 60 42.488 L 60 42.488 L 60 46.603 L 52.985 46.603 L 45.97 46.603 L 45.97 42.488 L 45.97 42.488 L 45.97 42.488 Z  M 22.775 76.242 L 83.194 76.242 C 84.47 76.242 85.506 77.278 85.506 78.553 L 85.506 79.689 C 85.506 80.964 84.47 82 83.194 82 L 22.775 82 C 21.5 82 20.464 80.964 20.464 79.689 L 20.464 78.553 C 20.464 77.278 21.5 76.242 22.775 76.242 L 22.775 76.242 Z  M 22.775 68.242 L 52.985 68.242 L 83.194 68.242 C 84.47 68.242 85.506 69.278 85.506 70.553 L 85.506 71.689 C 85.506 72.964 84.47 74 83.194 74 L 22.775 74 C 21.5 74 20.464 72.964 20.464 71.689 L 20.464 70.553 C 20.464 69.278 21.5 68.242 22.775 68.242 Z " fill-rule="evenodd" fill="rgb(37,39,77)" vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(37,39,77)" stroke-linejoin="miter" stroke-linecap="square" stroke-miterlimit="3"/>
                </g>
            </svg>
        </div>
        <div class="pt-4">
            This area is currently under construction.
        </div>
    </div>
</template>
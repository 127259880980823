<template>
  <base-dialog-box @close="closeConfirmModal">
    <template #header>
      <AdminCloseButton @click="closeConfirmModal"> X </AdminCloseButton>
    </template>
    <template #body>
      <h2 class="pb-4 text-left pt-4">{{ message }}?</h2>
      <div class="flex justify-center mt-5">
        <base-button-affirmative
          type="button"
          class="mx-1"
          @click="confirmModal"
        >
          Confirm
        </base-button-affirmative>
        <base-button-negative
          type="button"
          class="mx-1"
          @click="closeConfirmModal"
        >
          Cancel
        </base-button-negative>
      </div>
    </template>
  </base-dialog-box>
</template>

<script setup>
// imports

import { defineEmits, defineProps } from "vue";

import AdminCloseButton from "./AdminCloseButton.vue";

// constants

const props = defineProps({
  message: String,
});
const emit = defineEmits(["close-confirm-modal", "confirm-modal"]);

// functions

function confirmModal() {
    emit("confirm-modal", props.message);
}

function closeConfirmModal() {
  emit("close-confirm-modal");
}
</script>

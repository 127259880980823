<template>
    <div>
        <span>
            <base-pill>ZI:</base-pill> 
            <a class="break-all text-accent-primary hover:text-dark-primary hover:underline hover:cursor-pointer" target="_blank" v-if="company.zi_website_url"
                :href="httpCompanyUrl">{{ company.zi_website_url }}</a>
            <span v-else>--</span>
        </span>
    </div>
</template>

<script>
    export default {
        name: "website-url-cell"
    }
</script>

<script setup>
import { computed, defineProps } from "vue";


    const props = defineProps({
        company: Object
    })


    const httpCompanyUrl = computed(() => {
        if(props.company.zi_website_url){
            if(props.company.zi_website_url.startsWith('http://') || props.company.zi_website_url.startsWith('https://')){
                return props.company.zi_website_url
            } else {
                return `http://${props.company.zi_website_url}`
            }
        } else {
            return "#"
        }
            })
</script>
<template>
    <base-dblclick-input
        modelType="company"
        :modelId="company.id"
        fieldName="square_footage"
        :fieldValue="company.square_footage"
        inputType="number"
        @refetch="fetchNewCompanies"
    >
    </base-dblclick-input>
</template>

<script>
    export default {
        name: "square-footage-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies () {
        emit('refetch-companies')
    }
</script>
<template>
    <div class="flex flex-col items-center">
        <CRMStatusCell :crm-statuses="crmStatuses" :company="company" @refetch-companies="fetchNewCompanies" />
        <div class="w-full mt-1">
            <base-pill><span>Summary: </span></base-pill> 
            <CRMSummaryCell :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
        <div class="w-full mt-1">
            <base-pill><span>Relevant Business: </span></base-pill> 
            <RelevantBusinessCell :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
    </div>

</template>

<script setup>
import CRMStatusCell from '../company-cell-components/CRMStatusCell.vue';
import CRMSummaryCell from '../company-cell-components/CRMSummaryCell.vue';
import RelevantBusinessCell from '../company-cell-components/RelevantBusinessCell.vue';
import {defineEmits, defineProps} from "vue"

    const props = defineProps({
        company: Object,
        crmStatuses: Array
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }


</script>
import { getCompanies, updateCompany, assignCampaign } from '../../../services/Company.js';

export default {
    async setCompanies (context, endpoint) {
        // console.log('setCompanies action, context = ', context)
        let payload = {
            token: context.rootGetters['auth/showToken'],
            endpoint: endpoint
        }
        const result = await getCompanies(payload)
        // console.log('getCompanies async ran')
        context.commit('setCompanies', result.companies)
        context.commit('setNext', result.next)
        context.commit('setPrev', result.prev)
        context.commit('setCount', result.count)
        context.commit('setPage', result.page)
        context.commit('setPageSize', result.pageSize)
    },
    async updateCompany (context, companyData) {
        // console.log('updateCompany action')
        await assignCampaign(companyData.campaignId, companyData.companyId, context.rootGetters['auth/showToken'])
        const company = await updateCompany(companyData.companyId, companyData.formData, context.rootGetters['auth/showToken'])
        // console.log('updateCompany async ran', company)
        context.dispatch('setCompanies')
    },
    setIsLoadingFalse(context) {
        context.commit('setIsLoadingFalse')
    },
    setIsLoadingTrue(context) {
        context.commit('setIsLoadingTrue')
    },
    setActiveFilters(context, activeFiltersArray) {
        context.commit('setActiveFilters', activeFiltersArray)
    },
    setShowCreateCompanyFormTrue(context) {
        context.commit('setShowCreateCompanyFormTrue')
    },
    setShowCreateCompanyFormFalse(context) {
        context.commit('setShowCreateCompanyFormFalse')
    },
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
    setShowTableFiltersFalse(context) {
        context.commit('setShowTableFiltersFalse')
    },
    setShowTableFiltersTrue(context) {
        context.commit('setShowTableFiltersTrue')
    },
    setShowActiveFiltersFalse(context) {
        context.commit('setShowActiveFiltersFalse')
    },
    setShowActiveFiltersTrue(context) {
        context.commit('setShowActiveFiltersTrue')
    },
    setShowBasicFiltersFalse(context) {
        context.commit('setShowBasicFiltersFalse')
    },
    setShowBasicFiltersTrue(context) {
        context.commit('setShowBasicFiltersTrue')
    },
    setShowCampaignFiltersFalse(context) {
        context.commit('setShowCampaignFiltersFalse')
    },
    setShowCampaignFiltersTrue(context) {
        context.commit('setShowCampaignFiltersTrue')
    },
    setShowResearchFiltersFalse(context) {
        context.commit('setShowResearchFiltersFalse')
    },
    setShowResearchFiltersTrue(context) {
        context.commit('setShowResearchFiltersTrue')
    },
    setShowBulkOperationsPaneFalse(context) {
        context.commit('setShowBulkOperationsPaneFalse')
    },
    setShowBulkOperationsPaneTrue(context) {
        context.commit('setShowBulkOperationsPaneTrue')
    },
    setShowStatusSummaryPaneFalse(context) {
        context.commit('setShowStatusSummaryPaneFalse')
    },
    setShowStatusSummaryPaneTrue(context) {
        context.commit('setShowStatusSummaryPaneTrue')
    },
    setShowCRMSummaryStagesFalse(context) {
        context.commit('setShowCRMSummaryStagesFalse')
    },
    setShowCRMSummaryStagesTrue(context) {
        context.commit('setShowCRMSummaryStagesTrue')
    },
    setShowMyOpenTasks(context, payload) {
        if(payload.show == true){
            context.commit('setShowMyOpenTasksTrue')
            if(payload.user){
                context.commit('setShowMyOpenTasksUser', payload.user)
            }
        } else {
            context.commit('setShowMyOpenTasksFalse')
        }
    },
    setVisibleFilters(context, payload){
        context.commit('setVisibleFilters', payload)
    },
    setVisibleFiltersAllTrue(context){
        context.commit('setVisibleFiltersAllTrue')
    },
    setRefetchCompaniesTrue(context){
        context.commit('setRefetchCompaniesTrue')
    },
    setRefetchCompaniesFalse(context){
        context.commit('setRefetchCompaniesFalse')
    },
    setCRMSummaryStageClient(context, payload) {
        context.commit('setCRMSummaryStagesClient', payload)
    }
};
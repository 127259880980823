<template>
    <form @submit.prevent>
        <input :type="inputType" class="border border-black rounded-sm p-1 w-full break-normal h-fit"  v-on:keyup.enter="handleInputChange" :value="inputText" name="inputName" >
    </form>
</template>

<script>
 export default {
    name: 'edit-crm-input'
 }
</script>

<script setup>
import { updateEngagement } from '@/services/Engagement';
import { defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
    inputType: String,
    inputText: [String, Number],
    fieldName: String,
    engagementId: Number
})

const emit = defineEmits(['refetch-engagements', 'toggle-editable'])

async function handleInputChange(e) {
            let newValue = e.target.value
            if(!newValue){
                newValue = null
            }
            const targetField = props.fieldName
            let updatedData = {}
            updatedData[targetField] = newValue
            const data = JSON.stringify(updatedData);
            // console.log('data', data)
            let token = store.state.auth.token
            await updateEngagement(props.engagementId, data, token)
            emit('toggle-editable')
            emit('refetch-engagements')
        }



</script>
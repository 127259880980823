<template>
    <div class="w-full h-full">
        <base-form class="h-full w-full">
            <template #body>
                <div class="grid grid-cols-5 w-full gap-2 mb-1 p-1 text-xs">
                    <div class="flex flex-col col-span-2">
                        <label>Head</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="editedTrainingDocument.head">
                    </div>
                    <div class="flex flex-col">
                        <label>Group</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="editedTrainingDocument.group">
                    </div>
                    <div class="flex flex-col">
                        <label>Sub Group</label>
                        <input type="text" class="p-1 border w-full inline" v-model="editedTrainingDocument.sub_group">
                    </div>
                    <div class="flex flex-col">
                        <label>Ordinal</label>
                        <input type="number" required class="p-1 border w-full inline" v-model="editedTrainingDocument.ordinal">
                    </div>
                    <div class="flex flex-col col-span-5">
                        <label>Body</label>
                        <textarea required class="p-1 border h-full w-full" rows="5" v-model="editedTrainingDocument.body"></textarea>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="flex flex-row space-x-2 justify-center w-full col-span-5 my-2">
                    <base-button-affirmative @click="editTrainingDocument(editedTrainingDocument)">Update</base-button-affirmative>
                    <base-button-negative @click="closeEdit">Cancel</base-button-negative>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, reactive } from 'vue';
import { useStore } from 'vuex';
import { updateTrainingDocument } from '@/services/TrainingDocuments.js';

const store = useStore()
const props = defineProps({
    trainingDocumentToEdit: Object
})
const emit = defineEmits(['refetch-training-documents', 'close-edit'])

const editedTrainingDocument = reactive({
    head: props.trainingDocumentToEdit.head,
    body: props.trainingDocumentToEdit.body,
    group: props.trainingDocumentToEdit.group,
    sub_group: props.trainingDocumentToEdit.sub_group,
    status: props.trainingDocumentToEdit.status,
    ordinal: props.trainingDocumentToEdit.ordinal,
})

function closeEdit() {
    emit('close-edit')
}

async function editTrainingDocument(editedTrainingDocument) {
    const token = store.state.auth.token
    let errorMessage = "Error: Missing "
    if (!editedTrainingDocument.head || !editedTrainingDocument.group || !editedTrainingDocument.ordinal || !editedTrainingDocument.body) {
        if (!editedTrainingDocument.head) {
            errorMessage += "head, "
        }
        if (!editedTrainingDocument.group) {
            errorMessage += "group, "
        }
        if (!editedTrainingDocument.ordinal) {
            errorMessage += "ordinal, "
        }
        if (!editedTrainingDocument.body) {
            errorMessage += "body, "
        }
        alert(errorMessage.slice(0, -2))
        return
    }

    await updateTrainingDocument(props.trainingDocumentToEdit.id, editedTrainingDocument, token)
    emit('refetch-training-documents')
    emit('close-edit')
}
</script>
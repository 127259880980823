<template>
    <div v-if="companies.length > 0">
        <base-accordion
            :startCollapsed="true"
            :isActive="!collapsed"
        >
            <template #title>
                {{ statusGroup }} ({{ companies.length }})
            </template>
            <template #accordian-body>
                <base-table-auto class="mb-5">
                    <!-- <template #table-title>
                    {{ statusGroup }} ({{ companies.length }})
                    </template> -->
                    <template #table-header>
                    <base-table-header :key="header.id" :header="header" v-for="header in tableHeaders" :style="formatHeader(header)">
                    {{ header }}
                    </base-table-header>
                    </template>

                    <template #table-body>
                    <CampaignCrmStatusSummaryRow
                        v-for="(item, index) in companies"
                        :company="item"
                        :key="item.id"
                        :index="index"
                        :tableHeaders="tableHeaders"
                        @refetch-companies="refetchCompanies"
                        />
                    </template>
                </base-table-auto>
            </template>
        </base-accordion>
    </div>
</template>

<script setup>
import { ref,defineProps, defineEmits, watch } from 'vue'
import CampaignCrmStatusSummaryRow from './CampaignCrmStatusSummaryRow.vue';

const props = defineProps(['statusGroup', 'companies', 'tableHeaders', 'collapsed'])

const emit = defineEmits(['refetch-companies'])

function formatHeader(header) {
    if(header === 'Account Manager Research Notes') {
        return 'min-width: 300px;'
    }
    if(header === '') {
        return 'max-width: 15px;'
    } else if (header === 'ID' || header === 'Revenue' || header === 'Full-Time Equivalent' || header === 'Square Footage') {
        return 'max-width: 90px;'
    } else {
        let numberOfHeaders = props.tableHeaders.length
        let width = 100 / numberOfHeaders
        return `width: ${width}%;`
    }
}

const refetchCompanies = () => {
    // console.log('refetchCompanies crm summary table')
    emit('refetch-companies')
}
</script>
<template>
    <div class="w-full h-full">
        <div class="w-full flex py-2 border-b border-gray-300 text-sm justify-between">
            <div>
                {{ company.company_name }} is assigned to {{ collections.length }} Collections.
            </div>
            <div v-if="isLoading" role="status" class="pl-3">
                <svg aria-hidden="true" class="mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <base-form class="w-full h-[90%]">
            <template #body>
                <div v-if="collections.length > 0" class="h-full">
                    <base-table-auto>
                    <template #table-header>
                        <base-table-header
                                class="whitespace-nowrap"
                                :key="header.id"
                                :header="header"
                                v-for="header in tableHeadersList"
                            >
                            <span class="text-xs">{{ header }}</span>
                        </base-table-header>
                    </template>
                    <template #table-body>
                        <base-table-row
                            v-for="collection in collections"
                            :key="collection.id"
                        >
                            <base-table-cell-small>
                                {{ collection.collection_tag.collection_tag_name }}
                            </base-table-cell-small>
                            <base-table-cell-small>
                                <span v-if="collection.collection_tag.created_by">
                                    {{ collection.collection_tag.created_by.first_name }} {{ collection.collection_tag.created_by.last_name }}
                                </span>
                                <span v-else> -- </span>
                            </base-table-cell-small>
                            <base-table-cell-small>
                                {{ formatDateTime(collection.created_at) }}
                            </base-table-cell-small>
                            <base-table-cell-small>
                                <div class="flex flex-col space-y-2 w-full items-center">
                                    <base-button @click="createPost(collection.collection_tag, 'Interesting')" class="w-3/4">Interesting</base-button>
                                    <base-button @click="createPost(collection.collection_tag, 'Not Interesting')"  class="w-3/4">Not Interesting</base-button>
                                </div>
                            </base-table-cell-small>
                            <base-table-cell-small class="flex justify-center">
                                <base-button-negative @click="removeFromCollection(collection.collection_tag.id)" >X</base-button-negative>
                            </base-table-cell-small>
                        </base-table-row>
                    </template>
                    </base-table-auto>
                </div>
            </template>
            <template #footer>
                <div class="flex w-full justify-between items-center space-x-2 py-2">
                    <input type="text" class="border border-gray-300 rounded-md w-full pl-2 py-1.5" required placeholder=' New / Existing Collection Name' v-model="collectionName">
                    <base-button-affirmative v-if="!isLoading" @click="assignToCollection">Submit</base-button-affirmative>
                    <base-button-disabled v-else>Submit</base-button-disabled>
                </div>
            </template>
        </base-form>
    </div>

</template>

<script setup>
import { defineProps, defineEmits, computed, ref} from 'vue';
import { removeCollectionTag } from '@/services/Company';
import { useStore } from 'vuex';
import { assignCollectionTag } from '@/services/Company';
import { useToast } from 'vue-toast-notification';
import { formatDateTime } from '@/composables/formatDateTime';
import { createResearchPost } from '@/services/ResearchPost';

const props = defineProps(['company'])

const emit = defineEmits(['refetch-companies'])

const store = useStore()

const $toast = useToast()

const isLoading = ref(false)

const collectionName = ref(null)

const tableHeadersList = [
    'Collection Name',
    'Created By',
    'Created At',
    'Review',
    'Remove From Collection'
]

const collections = computed(() => {
    if(props.company.collection_tags) {
        let collection_tags = props.company.collection_tags
        collection_tags = collection_tags.sort((a, b) => {return a.collection_tag.collection_tag_name - b.collection_tag.collection_tag_name})
        return collection_tags
    } else {
        return []
    }
})

async function removeFromCollection(collectionTagId) {
    isLoading.value = true
    const token = store.state.auth.token
    const result = await removeCollectionTag(collectionTagId, props.company.id, token)
    // console.log(result)
    if(result.status==200) {
        $toast.open({
            message: `${result.data.status} `,
            type: 'success',
            duration: 5000
        })
        emit('refetch-companies')
    } else {
        $toast.open({
            message: result.status,
            type: 'error',
            duration: 20000
        })
    }
    isLoading.value = false
}

async function assignToCollection() {
    if(collectionName.value && collectionName.value != '') {
        isLoading.value = true
        const token = store.state.auth.token
        const result = await assignCollectionTag(collectionName.value, props.company.id, token)
        // console.log('result')
        // console.log(result)
        if(result.status == 200) {
            $toast.open({
                message: `${result.data.status} `,
                type: 'success',
                duration: 5000
            })
            emit('refetch-companies')
            collectionName.value = null
        } else {
            $toast.open({
                message: result.status,
                type: 'error',
                duration: 20000
            })
        }
        isLoading.value = false
    }  else {
        alert("Collection name is required.")
    }
}

const postData = ref({
        company_id: props.company.id,
        post_type: 'collection_research',
        post_category: null,
        collection_tag: null,
        collection_tag_name: null,
        post_body: null,
        flag: null,
    })

async function createPost(collectionTag, category){
    const token = store.state.auth.token
    postData.value.post_category = category
    postData.value.collection_tag = collectionTag.id
    postData.value.collection_tag_name = collectionTag.collection_tag_name
    console.log('postData', postData)
    const result = await createResearchPost(postData, token)
    if(result.status == 200) {
            $toast.open({
                message: `${postData.value.post_category} Post Created`,
                type: 'success',
                duration: 5000
            })
            emit('refetch-companies')
            collectionName.value = null
        } else {
            $toast.open({
                message: 'Error, post not created',
                type: 'error',
                duration: 20000
            })
        }
}


</script>
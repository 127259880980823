<template>
    <base-dblclick-input
      modelType="contact"
      :model-id="contact.id"
      field-name="linkedin_profile"
      :field-value="contact.linkedin_profile"
      inputType="url"
      @refetch="fetchNewContacts"
    >
        <slot name="icon"></slot>
    </base-dblclick-input>
</template>

<script>
    export default {
        name: "contact-linked-in-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    contact: Object
})

const emit = defineEmits(['refetch-contacts'])

function fetchNewContacts () {
    emit('refetch-contacts')
}
</script>
import { getClients } from '../../../services/Client.js';
// import { updateClient } from '../../../services/Client.js';

export default {
    async setClients (context, endpoint) {
        // console.log('clients/actions.js -- setClients action')
        let payload = {
            token: context.rootGetters['auth/showToken'],
            endpoint: endpoint
        }
        const result = await getClients(payload)
        // console.log('getClients async ran', result)
        context.commit('setClients', result.clients)
        context.commit('setNext', result.next)
        context.commit('setPrev', result.prev)
        context.commit('setCount', result.count)
        context.commit('setPage', result.page)
        context.commit('setPageSize', result.pageSize)
    },
    // async updateClient (context, companyData) {
    //     console.log('updateClient action')
    //     await assignCampaign(companyData.campaignId, companyData.companyId)
    //     const company = await updateClient(companyData.companyId, companyData.formData)
    //     console.log('updateClient async ran', company)
    //     context.dispatch('setClients')
    // },
    setIsLoadingFalse(context) {
        context.commit('setIsLoadingFalse')
    },
    setIsLoadingTrue(context) {
        context.commit('setIsLoadingTrue')
    },
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
};
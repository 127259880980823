<template>
    <base-table-row>
        <base-table-cell>
            <div class=" whitespace-nowrap">
                {{ invoice.invoice_number }} ({{ invoice.id }})
            </div>
        </base-table-cell>
        <base-table-cell>
            {{ invoice.status }}
        </base-table-cell>
        <base-table-cell>
            <span >{{ invoice.engagement.client.client_name }}</span>
        </base-table-cell>
        <base-table-cell>
            <div class=" whitespace-nowrap flex justify-center">
                {{ returnDate(invoice.invoice_date) }}
            </div>
        </base-table-cell>
        <base-table-cell>
        <div class=" whitespace-nowrap flex justify-center">
            {{ returnDate(invoice.due_date) }}
        </div>
    </base-table-cell>
    <base-table-cell>
        <div class=" whitespace-nowrap flex justify-center" :class="{'bg-[#FFFF01]' : invoice.days_until_due_date <= 0, 'bg-red-500' : invoice.days_until_due_date > 0}">
           {{ invoice.days_until_due_date }}
        </div>
    </base-table-cell>
        <base-table-cell>
            {{ invoice.invoice_service }}
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end w-full">
                {{ invoiceAmount }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end w-full">
                {{ amountPaid }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end w-full" :class="[{'bg-[#FF0300]' : balanceDue != '$0.00' && isOverdue}, {'bg-[#FFFF01]' : balanceDue != '$0.00' && !isOverdue}]" >
                {{ balanceDue }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div v-if="invoice.sent_reminders.length > 0" class="flex justify-center w-full" >
                <base-button @click="showSentInvoiceReminders">
                    Reminders
                </base-button>
                <base-modal :is-form-visible="sentInvoiceRemindersVisible" @close="hideSentInvoiceReminders">
                    <template #header>
                        <div>
                            Invoice #{{ invoice.invoice_number }} Sent Reminders
                        </div>
                    </template>
                    <template #body>
                        <div class="max-h-[95vh] overflow-auto">
                            <SentInvoiceReminders :sent-reminders="invoice.sent_reminders" />
                        </div>
                    </template>
                </base-modal>
            </div>
            <div v-else>
                <div class="flex justify-center w-full">
                    --
                </div>
            </div>
        </base-table-cell>
        <!-- <base-table-cell>
            <base-button><a :href="`mailto:${invoice.engagement.client.accounts_payable_email}?cc=norman@sheddcapital.com&subject=${subjectLine}&body=${emailBody}`">Send Reminder</a> </base-button>
        </base-table-cell> -->
        <base-table-cell>
            <base-button @click="openForm">Send Reminder</base-button>
            <base-modal :is-form-visible="isFormVisible" @close="closeForm">
                <template #header>
                    <h2 class="text-lg">
                        Send Reminder for Invoice {{ invoice.invoice_number }}
                    </h2>
                </template>
                <template #body>
                    <InvoiceReminderForm :invoice="invoice" @refetch-invoices="fetchNewInvoices" @close="closeForm"/>
                </template>
            </base-modal>
        </base-table-cell>
    </base-table-row>

</template>

<script>
export default {
    name: 'invoice-needing-reminder-row'
}
</script>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import InvoiceReminderForm from './InvoiceReminderForm.vue';
import SentInvoiceReminders from './SentInvoiceReminders.vue'

const props = defineProps({
    invoice: Object,
    showPosts: Object
})

const emit = defineEmits(['refetch-invoices'])

function fetchNewInvoices() {
    emit('refetch-invoices')
}

const sentInvoiceRemindersVisible = ref(false)

function showSentInvoiceReminders() {
    sentInvoiceRemindersVisible.value = true
}

function hideSentInvoiceReminders() {
    sentInvoiceRemindersVisible.value = false
}


const xPosition = ref(0)
const yPosition = ref(0)

const isPopoverVisible = ref(false)


const itemCount = ref(null)

const isFormVisible = ref(false)

function openForm () {
    isFormVisible.value = true
}

function closeForm() {
    console.log('closing')
    isFormVisible.value = false
}

function showPopover(){
    if(props.invoice.items.length > 0){
        isPopoverVisible.value = true
        xPosition.value = itemCount.value.getBoundingClientRect().left - 500
        yPosition.value = itemCount.value.getBoundingClientRect().top
    }
}

function hidePopover(){
    isPopoverVisible.value = false
    xPosition.value = 0;
    yPosition.value = 0;
}

const itemsVisible = ref(true)
const allAccountingPostsVisible = ref(true)

// watch(props.showItems, () => {
//     if(props.showItems.value){
//         itemsVisible.value = true
//     } else {
//         itemsVisible.value = false
//     }
// })

const isOverdue = computed(() => {
    const overdueStatuses = ['Unpaid', 'unpaid']
    const dueDate = new Date(props.invoice.due_date).getTime()
    const today = new Date().getTime()
    return (today > dueDate) && overdueStatuses.includes(props.invoice.status)
})

const invoiceAmount = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.invoice.invoice_amount);

})
const amountPaid = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.invoice.amount_paid);
})

const balanceDue = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.invoice.invoice_amount - (props.invoice.amount_paid ? props.invoice.amount_paid : 0));
})

const dateDelta = computed(() => {
    const date1 = new Date(props.invoice.invoice_date);
    const date2 = new Date(props.invoice.due_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays
})

const subjectLine = ref("Reminder from Shedd Capital LLC")
const emailBody = computed(() => {
    if(props.invoice){
        return props.invoice.reminder_email_text
    } else {
        return ""
    }
})


function toggleItemVisibility() {
    itemsVisible.value = !itemsVisible.value
}
function setAccountingPostsVisibility(value) {
    allAccountingPostsVisible.value = value
}

const returnDate = (invoiceDate) => {
        let date = new Date(invoiceDate)
        date.setDate(date.getDate() + 1)
        return date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit'})
    }

const itemText = computed(() => {
    if(itemsVisible.value){
        return "-"
    } else {
        return "+"
    }
})

const accountingPostsText = computed(() => {
    if(allAccountingPostsVisible.value){
        return "-"
    } else {
        return "+"
    }
})

</script>

<style>
.itemsTable-enter-active,
.itemsTable-leave-active {
  transition: all 0.2s ease-in;
}
.itemsTable-enter-from {
    transform: scaleY(0);
    transform-origin: top;
}
.itemsTable-leave-to {
  transform: scaleY(0);
  transform-origin: top;
}
</style>
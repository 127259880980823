<template>
    <div class="flex flex-col items-center">
        <CRMNextActionCell :company="company" @refetch-companies="fetchNewCompanies" />
        <div class="flex mt-1">
            <div>
                <base-pill>Due date:</base-pill> 
            </div>
            <CRMNextActionDueDateCell class="mb-2" :company="company" @refetch-companies="fetchNewCompanies"/>
        </div>
        <MarkCRMNextActionDueDateButtonsCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>

</template>

<script>
    export default {
        name: 'crm-next-action-due-date-due-date-buttons-cell'
    }
</script>

<script setup>
import CRMNextActionCell from "../company-cell-components/CRMNextActionCell.vue";
import CRMNextActionDueDateCell from "../company-cell-components/CRMNextActionDueDateCell.vue";
import MarkCRMNextActionDueDateButtonsCell from "../company-cell-components/MarkCRMNextActionDueDateButtonsCell.vue";
import {defineEmits, defineProps} from "vue"

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }


</script>
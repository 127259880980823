<template>
    <div class="flex flex-col pt-1 pb-1 justify-center h-9 -ml-1 mr-1">
        <button type="button" @click="setOrActive" :class="{'w-full border-r border-t border-t-light-primary border-r-light-primary rounded-tr h-full p-1 bg-blue-500 text-white text-sm' : orActive, 'w-full border-r border-t border-t-light-primary border-r-light-primary rounded-tr-md p-1 h-full bg-gray-300 text-gray-500 text-sm' : andActive}" >Or</button>
        <button type="button" @click="setAndActive" :class="{'w-full rounded-br border-r border-b border-b-light-primary border-r-light-primary h-full p-1 bg-blue-500 text-white text-sm' : andActive, 'w-full border-r border-b border-b-light-primary border-r-light-primary rounded-br-md p-1 h-full bg-gray-300 text-gray-500 text-sm' : orActive}" >And</button>
    </div>
</template>

<script>
export default {
  name: "AndOrButtons.vue"
}
</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";

    const orActive = ref(false)
    const andActive = ref(false)

    const activeButton = ref('or')
    const props = defineProps({
        activeButton : String
    })

    function setOrActive() {
        andActive.value = false
        orActive.value = true
        activeButton.value = 'or'
        emit('updateActiveButton', 'or')
    }

    function setAndActive() {
        orActive.value = false
        andActive.value = true
        activeButton.value = 'and'
        emit('updateActiveButton', 'and')
    }

    function determineActive(){
        if(props.activeButton == 'or'){
            orActive.value = true
            andActive.value = false
        } else if (props.activeButton == 'and'){
            orActive.value = false
            andActive.value = true
        }
    }

    const emit = defineEmits(['updateActiveButton'])

    const activeClass = "rounded p-1 bg-blue-500 text-white text-sm"
    const inactiveClass = "rounded p-1 bg-gray-300 text-gray-800 text-sm"

    watch(() => props.activeButton, () => {
        console.log('activebutton', props.activeButton)
        determineActive()
        console.log(orActive.value)
        console.log(andActive.value)
    })

    determineActive()

</script>


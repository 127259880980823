export default {
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
    setShowActionsPaneFalse(context) {
        context.commit('setShowActionsPaneFalse')
    },
    setShowActionsPaneTrue(context) {
        context.commit('setShowActionsPaneTrue')
    },
    setShowTimecardUsersFalse(context) {
        context.commit('setShowTimecardUsersFalse')
    },
    setShowTimecardUsersTrue(context) {
        context.commit('setShowTimecardUsersTrue')
    },
}
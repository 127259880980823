<template>
<div v-if="isSelected" class="my-2 mx-auto p-1 border border-inherit rounded-lg shadow-xl bg-light-primary text-dark-primary">
    <slot></slot>
  </div>
  <div v-else class="my-2 mx-auto p-1 border border-inherit rounded-lg shadow-xl">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
  props: ['isSelected']
}
</script>
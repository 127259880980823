<template>
    <div >
        <div>
            {{ mostRecentStatus }}
        </div>
        <select name="" id="" class="p-1 border border-black w-fit rounded"  @change="addCrmStatus">
            <option v-for="option in statusOptions" :key="option.value" :value="option.value" >{{ option.label }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "crm-status-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits, watch, ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { addNewCrmStatus } from '@/services/Company.js';
import { getCrmStatuses } from '@/services/CrmStatus';

    const store = useStore()

    const mostRecentStatus = computed(()=>{
        if(props.company.campaign){
            if(props.company.crm_statuses.length > 0){
                let crmStatuses = props.company.crm_statuses.filter(status => status.campaign.id == props.company.campaign.id)
                if(crmStatuses.length < 1){
                    return '--'
                }
                let currentStatus = crmStatuses.reduce((accumulator, currentValue) => {
                    let accDate = new Date(accumulator.created_on).getTime()
                    let currDate = new Date(currentValue.created_on).getTime()
                    if(accDate > currDate){
                        return accumulator
                    } else {
                        return currentValue
                    }
                })
                return `${currentStatus.crm_status.status_name} ${new Date(currentStatus.created_on).toLocaleDateString()}`
            } else {
                return '--'
            }
        } else{
            return "--"
        }
    })

    const props = defineProps({
        company: Object,
        crmStatuses: Array, 
    })

    const emit = defineEmits(['refetch-companies'])
    

    const statusOptions = computed(() => {
        return [ {'value': null, label: '-----'}, 
            ...props.crmStatuses.map((status)=>{
                return {'value': status.id, 'label': status.status_name}
            })
        ]
    })



    async function addCrmStatus(event){
        const token = store.state.auth.token
        await addNewCrmStatus(props.company.id, event.target.value, token)
        emit('refetch-companies')
    }


    

</script>
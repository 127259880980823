<template>
  <div class="grid grid-cols-12 w-full">
    <div class="flex justify-start col-span-5 2xl:col-span-3 w-full">
      <div class="w-fit pr-3">
        <CompanyContactFullNameCell class="inline w-full" :company-contact="contact" @refetch-companies="fetchNewContacts" />
      </div>
    </div>
    <div class="flex flex-col 2xl:grid col-span-3 2xl:grid-cols-2 2xl:col-span-4 w-full">
      <div class="mb-1">
        <ContactPersonalEmailCell class="inline col-span-2" :contact="contact" @refetch-contacts="fetchNewContacts" />
      </div>
      <div>
        <ContactLinkedInCell class="inline col-span-2" :contact="contact" @refetch-contacts="fetchNewContacts">
          <template #icon>
            <span class="pr-1"><img id="linkedin" src="@/assets/images/icons8-linkedin-30.png" alt="linkedin" class="w-[16px] inline"/></span>
          </template>
        </ContactLinkedInCell>
      </div>
    </div>
    <div class="flex flex-col col-span-4 2xl:grid   2xl:grid-cols-2 2xl:col-span-4 w-full">
      <div class="mb-1">
        <ContactPersonalPhoneCell class="inline col-span-2" :contact="contact" @refetch-contacts="fetchNewContacts" />
      </div>
      <div>
        <ContactPersonalNotesTruncatedCell class="inline col-span-2" :contact="contact" @refetch-contacts="fetchNewContacts" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'contact-personal-info',
    data: () => ({
    }),
    methods: {
    }
  }

</script>


<script setup>
import CompanyContactFullNameCell from "@/components/company-cell-components/CompanyContactFullNameCell.vue"
import ContactPersonalPhoneCell from '../contact-cell-components/ContactPersonalPhoneCell.vue';
import ContactPersonalEmailCell from '../contact-cell-components/ContactPersonalEmailCell.vue';
import ContactLinkedInCell from "../contact-cell-components/ContactLinkedInCell.vue";
import ContactPersonalNotesTruncatedCell from "../contact-cell-components/ContactPersonalNotesTruncatedCell.vue";
import { defineProps,defineEmits } from 'vue';

    const emit = defineEmits(['refetch-contacts'])

    const fetchNewContacts = () => {
      emit('refetch-contacts')
    }

    const props = defineProps({
        contact: Object
    })

</script>
<template>
    <div class="flex flex-row justify-between items-center space-x-1 w-full">
        <div class="text-left whitespace-nowrap">Search Phrase</div>
        <div class="flex flex-row justify-between w-full space-x-1">
            <select class="border border-gray-400 rounded-md pl-2 py-1.5" required @change="setQueryPrefix" v-model="prefix">
                <option value="EXACTLY" selected>Is Exactly</option>
                <option value="LIKE">Is Like</option>
                <option value="STEM">Is Stem Of</option>
                <option value="NOT">Is Not</option>
                <!-- <option value="STARTS">Starts With</option>
                <option value="ENDS">Ends With</option> -->
            </select>
            <input type="text" class="border border-gray-400 rounded-md w-full pl-2 py-1.5" required placeholder='Search Phrase' @input="setQuerySearchPhrase" v-model="searchPhrase">
            <div class="flex flex-row items-center space-x-1">
                <input type="checkbox" :checked="caseSensitive" @change="setQueryCaseSensitive">
                <div class="whitespace-nowrap">
                    Case Sensitive
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
const props = defineProps(['index', 'conditionKey'])

const emit = defineEmits(['upsert-query-condition', 'remove-query-condition'])


const prefix = ref('EXACTLY')
const caseSensitive = ref(false)
const searchPhrase = ref(null)


const caseSensitiveString = computed(() => {
    return caseSensitive.value ? 'TRUE' : 'FALSE'
})
const queryString = computed(() => {
    return `${prefix.value}([${searchPhrase.value}]:CASE=${caseSensitiveString.value})`
})

function setQueryPrefix() {
    emitUpdateQueryConditions()
}

function setQuerySearchPhrase(event) {
    searchPhrase.value = event.target.value;
    emitUpdateQueryConditions()
}

function setQueryCaseSensitive() {
    caseSensitive.value = !caseSensitive.value
    emitUpdateQueryConditions()
}

// function removeQueryCondition(){
//     emit('remove-query-condition', props.conditionKey)
// }

function emitUpdateQueryConditions() {
    emit('upsert-query-condition', {
        conditionKey: props.conditionKey,
        queryString: queryString.value,
        searchPhrase: searchPhrase.value
    })
}

// watch(() => props.conjunction, () => {
//     emitUpdateQueryConditions()
// })
</script>
<template>
    <div @dblclick="toggleEditable">
        <div v-if="isEditable">
            <select name="" v-model="formData.is_retainer_fee" class="border border-black rounded-sm p-1 w-full break-normal h-fit" id="" @change="markIsRetainerFee">
                <option :value="null">--</option>
                <option :value="true">True</option>
                <option :value="false">False</option>
            </select>
        </div>
        <div v-else>
            {{ isRetainerFee }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'invoice-is-retainer-fee-cell'
}
</script>

<script setup>
import { defineProps, ref, defineEmits, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { updateInvoice } from '@/services/Invoice';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const props = defineProps({
    invoice: Object
})

const $toast = useToast()

const emit = defineEmits(['refetch-invoices'])

const formData = reactive({
    is_retainer_fee: props.invoice.is_retainer_fee
})

const isEditable = ref(false)

const isRetainerFee = computed(() => {
    if(props.invoice.is_retainer_fee){
        return "True" 
    } else if(props.invoice.is_retainer_fee === false){
        return "False"
    } else {
        return "--"
    }
})

function toggleEditable(){
    isEditable.value = !isEditable.value 
}

async function markIsRetainerFee(){
    const token = store.state.auth.token
    console.log('mark retainer fee')
    console.log('form data', formData)
    const result = await updateInvoice(props.invoice.id, formData, token)
    if(result.status === 200){
        $toast.success(`Successfully updated Invoice ${props.invoice.invoice_number}`)
        emit('refetch-invoices')
        isEditable.value = false
    } else {
        $toast.error('Error, could not update Invoice')
        isEditable.value = false
    }

}

</script>
<template> 
    <transition
            enter-active-class="transition duration-200 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-200 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0">
        <div
            v-if="isModalVisible"
            class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] flex justify-center items-center z-10"
            tabindex="-1" >
        </div>
    </transition>
    <transition
            enter-active-class="transition duration-200 ease-in"
            enter-from-class="scale-0"
            enter-to-class="scale-100"
            leave-active-class="transition duration-200 ease-out"
            leave-from-class="scale-100"
            leave-to-class="scale-0">
  <div v-if="isModalVisible" v-on-escape="close" class="fixed top-5 bottom-0 left-0 right-0 flex justify-center  items-center w-full z-10"
  id="exampleModalScrollable" tabindex="-1" aria-labelledby="exampleModalScrollableLabel">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable relative pointer-events-none  p-20 w-3/5 overscroll-none">
    <div
      class="modal-content border-none shadow-lg relative flex overflow-auto max-h-[40rem] flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <!-- @click="close" -->
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
          <slot name="header">
            This is the default title!
          </slot>
        </h5>
      </div>
      <div class="modal-body h-3/4 overflow-auto relative p-4 w-full">
        <slot name="body">
            This is the default body!
          </slot>
      </div>
      <!-- @click="close" -->
      <div 
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 border-t border-gray-200 rounded-b-md">
        <base-button-negative type="button"
          class="
                m-3"
                data-bs-dismiss="modal" @click="close">
          Close
        </base-button-negative>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
export default {
  name: 'info-modal'
};
</script>

<script setup>
import { defineEmits, defineProps } from "vue";

const props = defineProps(['isModalVisible'])


const emit = defineEmits(['close'])


function close () {
    emit('close')
}


</script>
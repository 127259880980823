<template>
    <div>
        <div class=" h-max-32">
            <div class="overscroll-y-auto space-y-1" v-if="events">
                <div class="flex items-center justify-between py-1 space-x-2 whitespace-nowrap" :class="{'border-b border-b-black ' : idx != events.length -1 && events.length > 1}" v-for="(event, idx) in events" :key="event">
                    <div>{{ event }}</div>
                    <!-- <div>{{ new Date(task.due_date).toLocaleDateString()  }}</div> -->
                </div>
            </div>
            <div v-else>
                --
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'all-events-cell'
    }
</script>

<script setup>
import { defineProps, ref, defineEmits, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { formatDateTwoDigitYear } from '@/composables/formatDate';

const store = useStore()

const props = defineProps({
    company: Object
})

const events = computed(() => {
    if(props.company.crm_actions.length > 0){
        return props.company.crm_actions.filter(action => action.campaign.id === props.company.campaign.id).sort(function(a,b) {
            return new Date(b.resolved_date) - new Date(a.resolved_date)
        }).map((crm_action) => {
            return `${formatDateTwoDigitYear(crm_action.resolved_date)}: ${crm_action.action.action_name} - ${crm_action.user.first_name} ${crm_action.user.last_name}`
        })
    } else {
        return null
    }
})



</script>
<template>
    <div v-on-escape="close" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] flex justify-center items-center z-40" tabindex="-1">
      <div class="flex-col bg-white shadow-lg-black p-3 w-2/4 rounded-md h-2/4">
        <header class="flex shrink-0 justify-between items-center relative p-15 pl-2 border-solid border-b border-gray-200">
          <slot name="header">
          </slot>
          <base-button-negative
            class="mb-3 px-2 font-bold"
            type="button"
            @click="close"
          >
            x
          </base-button-negative>
        </header>
        <section class="flex h-full overflow-hidden w-full">
          <slot name="body">
          </slot>
         </section>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'BaseModalSmall',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
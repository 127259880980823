<template>
    <div class="flex flex-col items-center">
        <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies" />
        <ZiUrlCell :company="company" />
        <base-pill>Vetted by:</base-pill>
        <UrlVettedCell class="inline" :company="company" @refetch-companies="fetchNewCompanies" :permissions="permissions" />
    </div>
</template>

<script>
    export default {
        name: "zi-url-url-url-vetted-cell"
    }
</script>

<script setup>
import ZiUrlCell from '../company-cell-components/ZiUrlCell.vue';
import WebsiteUrlCell from '../company-cell-components/WebsiteUrlCell.vue';
import UrlVettedCell from '../company-cell-components/UrlVettedCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object,
        permissions: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
<template>
    <base-table-row>
        <base-table-cell>
            <div class=" whitespace-nowrap">
                {{ engagement.id }}
            </div>
        </base-table-cell>
        <base-table-cell>
            {{ engagement.client.client_name }}
        </base-table-cell>
        <base-table-cell>
            <div class="whitespace-nowrap">
                <EngagementStartDateCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class=" whitespace-nowrap">
                <EngagementEndDateCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />
            </div>
        </base-table-cell>
        <!-- <base-table-cell>
            <div class=" whitespace-nowrap">
                <EngagementCompensationTypeCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />
            </div>
        </base-table-cell> -->
        <base-table-cell>
            <div class="whitespace-nowrap flex justify-end">
                <EngagementRetainerAmountCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />
            </div>
        </base-table-cell>
        <base-table-cell>
            <EngagementPaymentTermsCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />        
        </base-table-cell>
        <base-table-cell>           
            <EngagementBillingTimeframeCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />        
        </base-table-cell>
        <base-table-cell>
            <EngagementTargetInvoiceDateCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />                    
        </base-table-cell>
        <base-table-cell>
            <EngagementTargetInvoiceDayCell :engagement="engagement"/>                    
        </base-table-cell>
        <base-table-cell>
            <EngagementDaysUntilTargetInvoiceDayCell :engagement="engagement"/>                    
        </base-table-cell>
        <base-table-cell>
        <EngagementTargetDueDateCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />                    
        </base-table-cell>
        <base-table-cell>
            <EngagementMostRecentInvoiceCell :engagement="engagement"/>
        </base-table-cell>
        <base-table-cell>
            <EngagementMissingInvoiceCell :engagement="engagement"/>
        </base-table-cell>
        <base-table-cell>
            <EngagementTypicalPaydayCell :engagement="engagement" @refetch-engagements="fetchNewEngagements" />
        </base-table-cell>
        <!-- <base-table-cell>
            <EngagementMostRecentInvoiceDueDateCell :engagement="engagement"/>
        </base-table-cell> -->
        <base-table-cell>
            <EngagementAccountingPostsCell :engagement="engagement" @refetch-engagements="fetchNewEngagements"/>
            <!-- <div class="flex flex-col space-y-1 p-1" v-if="allAccountingPostsVisible">
                <div class="flex flex-col" v-for="(post, idx) in engagement.accounting_posts" :key="post.id">
                    <div class="flex w-max">
                        {{ new Date(post.created_at).toLocaleDateString() }}: {{ post.post_body }}
                    </div>
                    <div class="w-full border-b border-b-black" v-if="idx < engagement.accounting_posts.length - 1"></div>
                </div>
                <div>
                   <button v-if="engagement.accounting_posts.length > 0" class="text-xs border border-dark-primary px-0.5 rounded-full h-fit hover:text-white hover:bg-dark-primary" @click="setAccountingPostsVisibility(false)">-</button>
                </div>
            </div>
            <div v-else-if="engagement.accounting_posts.length > 0">
                <div class="flex w-max" >
                        {{ new Date(engagement.accounting_posts[0].created_at).toLocaleDateString() }}: {{ engagement.accounting_posts[0].post_body }} 
                </div>
                <div>
                    <button class="text-xs border border-dark-primary px-0.5 rounded-full h-fit hover:text-white hover:bg-dark-primary" @click="setAccountingPostsVisibility(true)">+</button>
                </div>
            </div> -->
        </base-table-cell>
    </base-table-row>

</template>

<script>
export default {
    name: 'engagement-row'
}
</script>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import EngagementStartDateCell from './EngagementStartDateCell.vue';
import EngagementEndDateCell from './EngagementEndDateCell.vue';
import EngagementCompensationTypeCell from './EngagementCompensationTypeCell.vue';
import EngagementRetainerAmountCell from './EngagementRetainerAmountCell.vue';
import EngagementPaymentTermsCell from './EngagementPaymentTermsCell.vue';
import EngagementBillingTimeframeCell from './EngagementBillingTimeframeCell.vue';
import EngagementTargetInvoiceDateCell from './EngagementTargetInvoiceDateCell.vue';
import EngagementTargetDueDateCell from './EngagementTargetDueDateCell.vue';
import EngagementTypicalPaydayCell from './EngagementTypicalPaydayCell.vue';
import EngagementMostRecentInvoiceCell from './EngagementMostRecentInvoiceCell.vue';
import EngagementMostRecentInvoiceDueDateCell from './EngagementMostRecentInvoiceDueDateCell.vue';
import EngagementTargetInvoiceDayCell from './EngagementTargetInvoiceDayCell.vue';
import EngagementAccountingPostsCell from './EngagementAccountingPostsCell.vue';
import EngagementMissingInvoiceCell from './EngagementMissingInvoiceCell.vue';
import EngagementDaysUntilTargetInvoiceDayCell from './EngagementDaysUntilTargetInvoiceDayCell.vue'

const props = defineProps({
    engagement: Object
})

const emit = defineEmits(['refetch-engagements'])

function fetchNewEngagements() {
    emit('refetch-engagements')
}

const itemsVisible = ref(true)
const allAccountingPostsVisible = ref(true)



function toggleItemVisibility() {
    itemsVisible.value = !itemsVisible.value
}
function setAccountingPostsVisibility(value) {
    allAccountingPostsVisible.value = value
}



const accountingPostsText = computed(() => {
    if(allAccountingPostsVisible.value){
        return "-"
    } else {
        return "+"
    }
})

</script>

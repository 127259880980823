<template>
  <div class="h-full bg-white">
    <Listbox
        :multiple="props.multiple"
        @update:modelValue="value => emit('update:modelValue', value)"
        :model-value="props.modelValue">
      <div class="relative h-full">
        <ListboxButton
          class="absolute w-full h-full cursor-default rounded-b-md bg-white text-gray-900 py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-xs"
        >
          <span class="block truncate w-full" v-if="label">{{ label }}</span>
          <span v-else class="text-gray-500 "> {{props.placeholder}}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="z-30 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="option in props.options"
              :key="option.label"
              :value="option.value"
              as="template"
              class=""
            >
              <li
                :class="[
                  active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-4 pr-2 lg:pl-6',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block',
                  ]"
                  >{{ option.label }}</span
                >
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center text-amber-600"
                >
                  <CheckIcon class="pl-1 h-3 w-3 lg:h-5 lg:w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
        <div class="text-xs text-red-400 mt-1" v-if="props.error"> {{ props.error }}

        </div>
      </div>
    </Listbox>
  </div>
</template>

<script>
export default {
  name: "BaseListBox.vue"
}
</script>

<script setup>
import {ref, computed, defineProps, defineEmits} from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  options: Array,
  modelValue: [String, Number, Array, Boolean],
  placeholder: {
    type: String,
    default: 'Select option'
  },
  multiple: Boolean,
  error: String
});

const emit = defineEmits(['update:modelValue'])

const form = ref({
  option_id: null,
  role_ids: []
});

const label = computed(()=> {
  // return props.options.find(option => option.value === props.modelValue)?.label;
  // console.log('base list box options', props.options)
  return props.options.filter(option => {
    if (Array.isArray(props.modelValue)) {
      // console.log('model value is array', props.modelValue)
      return props.modelValue.includes(option.value);
    }
    return props.modelValue === option.value;
  }).map(option => option.label).join(', ')
})


</script>

<style scoped>

</style>
<template>
    <div>
        <div @dblclick="toggleEditable" v-if="isEditable">
        <EditEngagementInput :engagement-id="engagement.id" :input-text="engagement.typical_payday" input-type="text" field-name="typical_payday" @refetch-engagements="fetchNewEngagements" @toggle-editable="toggleEditable" />
        </div>
        <div @dblclick="toggleEditable"  class="text-center" v-else>
            {{ typicalPayday }}
        </div>
    </div>
</template>

<script>
export default {
    name: "engagement-typical-payday-cell"
}
</script>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import EditEngagementInput from './EditEngagementInput.vue';

const props = defineProps({
    engagement: Object
})

const typicalPayday = computed(() => {
    return props.engagement.typical_payday ? props.engagement.typical_payday : "--"
})

const isEditable = ref(false)

const emit = defineEmits(['refetch-engagements'])

function fetchNewEngagements(){
    emit('refetch-engagements')
}

function toggleEditable(){
    isEditable.value = !isEditable.value
}

</script>


<template>
    <div class="w-full">
        <form @submit.prevent="addSource" @keydown.enter.prevent="" action="" class="w-full">
            <div class="flex w-full">
                <input type="text" placeholder="Source Name" required v-model="sourceName" class="p-1 text-xs rounded-l-md min-w-[260px] border border-collapse border-r-0 border-gray-400">
                <button class="rounded-r-md hover:bg-dark-primary hover:text-white p-1 text-xs border border-collapse border-gray-400" type="submit">Create Source</button>
            </div>
        </form>
        <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
            <template #header>
                <span>{{ sourceStatus }}</span>
            </template>
            <template #body>
                <span>{{ sourceMessage }}</span>
            </template>
        </HeadlessDialogBox>
    </div>
</template>

<script>
export default {
    name: "create-source"
}
</script>

<script setup>
import { createSource } from '@/services/Source';
import { useStore } from 'vuex';
import { ref, defineEmits } from 'vue';
import HeadlessDialogBox from './HeadlessDialogBox.vue';

const store = useStore()

const sourceName = ref("")

const token = store.state.auth.token

const openDialogBox = ref(false)

const sourceStatus = ref("")

const sourceMessage = ref("")

function closeDialogBox(){
    openDialogBox.value = false
}


async function addSource() {
    console.log(sourceName.value)
    const sourceData = JSON.stringify({
        "source_name": sourceName.value
    })
    console.log('source data', sourceData)
    const result = await createSource(sourceData, token)
    if (result.status == 201){
        sourceStatus.value = "Success"
        sourceMessage.value = "Source created"
    } else {
        sourceStatus.value = "Failure"
        sourceMessage.value = "Source Not Created"
    }
    openDialogBox.value = true
    sourceName.value = ""

}

</script>
<template>
    <ReferralBaseTableRow>
      <base-table-cell>
        {{ name }}
      </base-table-cell>
      <base-table-cell>
        {{ link }}
      </base-table-cell>
      <base-table-cell>
        {{ address }}
      </base-table-cell>
      <base-table-cell>
        {{ campaignName }}
      </base-table-cell>
      <base-table-cell>
        {{ clientName }}
      </base-table-cell>
      <base-table-cell>
        {{ formatDate(props.time) }}
      </base-table-cell>
    </ReferralBaseTableRow>
  </template>
  
  <script setup>
import { defineProps, toRaw, computed } from "vue";
import { formatDate } from "@/composables/formatDate";

import ReferralBaseTableRow from "./ReferralBaseTableRow.vue"

const props = defineProps({
  name: String,
  link: String,
  address: String,
  contacts: Array,
  campaign: Object,
  time: String,
});
const campaignObject = toRaw(props.campaign);

const campaignName = computed(function () {
  if (campaignObject) {
    return campaignObject.campaign_name;
  } else {
    return "";
  }
});

const clientName = computed(function () {
  if (campaignObject && campaignObject.client) {
    return campaignObject.client.client_name;
  } else {
    return "";
  }
});

</script>

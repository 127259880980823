<template>
    <base-dblclick-textarea
        :model-id="company.id"
        model-type="company"
        field-name="account_manager_research_notes"
        :field-value="company.account_manager_research_notes"
        @refetch="fetchNewCompanies"
    >
    </base-dblclick-textarea>
</template>

<script>
  export default {
      name: "account-manager-research-notes-cell"
  }
</script>

<script setup>
import {ref, computed, defineProps, defineEmits } from "vue";
const isEditable = ref(false)
const showFullNotes = ref(false)
const showTruncatedNotes = computed(() => {
if(props.company.account_manager_research_notes){
    return props.company.account_manager_research_notes.length > 50
}
return false
})

const props = defineProps({
    company: Object
})

const truncatedNotes = computed(() => {
if(props.company.account_manager_research_notes){
    if(props.company.account_manager_research_notes.length > 50){
        return `${props.company.account_manager_research_notes.substring(0, 50)}...`
    } else {
        return props.company.account_manager_research_notes
    }
} else {
    return "--"
}
})

const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies () {
    emit('refetch-companies')
}

function toggleEditable () {
    isEditable.value = !isEditable.value
}

function toggleShowNotes() {
showFullNotes.value = !showFullNotes.value
}

const notesExpanded = computed(() => {
if(showTruncatedNotes.value == true && showFullNotes.value == true){
    return true
} else {
    return false
}
})

const displayFullNotes = computed(() => {
if(showTruncatedNotes.value == true && showFullNotes.value == false){
    return false
} else {
    return true
}
})

</script>
<template>
  <tr v-if="isSelected" class="bg-light-primary text-dark-primary">
    <slot></slot>
  </tr>
  <tr v-else class="odd:bg-green-200 even:bg-green-100">
    <slot></slot>
  </tr>
</template>
<script>
export default {
  name: "BaseTableRow.vue",
  props: ["isSelected"],
};
</script>
<style scoped></style>

<template>
    <RawAddressCell
          :company="company" @refetch-companies="fetchNewCompanies"
        />
    <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies" />
</template>

<script>
    export default {
        name: "raw-address-url-cell"
    }
</script>

<script setup>
import RawAddressCell from '../company-cell-components/RawAddressCell.vue';
import WebsiteUrlCell from '../company-cell-components/WebsiteUrlCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }


</script>
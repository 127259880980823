<template>
    <div
        v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement || userPermissions.isSuperuser"
        class="w-full"
    >
        <div v-if="showFilterPane">
            <div class="py-3 pb-0.5 border-b mx-5 mb-5 border-b-gray-400 flex flex-row justify-between">
                <h1>Campaigns</h1>
                <div>
                    <base-button-affirmative @click="resetFilters" class="text-xs">
                        Reset
                    </base-button-affirmative>
                </div>
            </div>

            <div class="px-5 grid grid-cols-12">
                <base-filter class="col-span-2">
                    <template v-slot:label>Active Status</template>
                    <template v-slot:widget><base-list-box class="w-full rounded-b-md dark:text-black"
                            placeholder="Select Status" v-model="campaignFilters.active_status"
                            @update:modelValue="setActiveStatusFilter" :options="activeStatusOptions" /></template>
                </base-filter>
                <base-filter class="col-span-2">
                    <template v-slot:label>
                        Client
                    </template>
                    <template v-slot:widget>
                        <base-list-box class="w-full rounded-b-md" placeholder="Select Client" v-model="campaignFilters.clients"
                            :multiple="true" @update:modelValue="setClientFilter" :options="clientOptions" />
                    </template>
                </base-filter>
                <base-filter class="col-span-2">
                    <template #label>Workflow Status</template>
                    <template #widget>
                        <base-list-box class="w-full rounded-b-md dark:text-black" placeholder="Select Status"
                            v-model="campaignFilters.workflow_status" @update:modelValue="setWorkflowStatus"
                            :options="workflowStatusOptions"></base-list-box>
                    </template>
                </base-filter>
            </div>
        </div>
        <div>
            <div v-if="isLoading" role="status" class="h-screen flex justify-center items-center">
                <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA" />
                </svg>
                <span class="sr-only">Loading...</span>
            </div>


            <div v-else class="flex mt-3 mb-1 space-x-3 items-center">
                <base-button class="ml-6" @click="showCreateModal">
                    Create Campaign
                </base-button>
                <svg @click="loadCampaigns(fetchFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="#25274D" class="w-6 h-6 hover:cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                <div class="text-sm">
                    {{ numberOfCampaignsDisplayed }} Total Campaigns
                </div>
            </div>


        </div>

        <CampaignsTable @edit-campaign="showEditModal" @refetch-campaigns="loadCampaigns" :tableHeaders="tableHeaders"
            :campaigns="campaignsResults" class="mx-6" />

        <base-modal-small v-if="isCreateModalVisible" @close="closeCreateModal">
            <template #header>
                Create Campaign
            </template>
            <template #body>
                <NewCampaign class="h-[90%]" @close-create="closeCreateModal" @refetch-campaigns="loadCampaigns" />
            </template>
        </base-modal-small>

        <base-modal-small v-if="isEditModalVisible" @close="closeEditModal">
            <template #header>
                Edit
            </template>
            <template #body>
                <EditCampaign class="h-[90%]" @close-edit="closeEditModal" @refetch-campaigns="loadCampaigns"
                    :campaignToEdit="campaignToEdit" />
            </template>
        </base-modal-small>
    </div>
</template>

<script>
export default {
    name: 'aerocle-campaigns',
    data: () => ({
    }),
    methods: {
    }
}
</script>

<script setup>
import { ref, reactive, watch, computed, shallowReactive } from "vue";
import { useStore } from "vuex";
import { getCampaigns } from "@/services/Campaigns";
import CampaignsTable from './CampaignsTable.vue';
import NewCampaign from './NewCampaign.vue';
import EditCampaign from "./EditCampaign.vue";
import { getClients } from '@/services/Client';

const store = useStore();

const campaignsResults = ref([]);
const campaignToEdit = ref(null);
const numberOfCampaignsDisplayed = ref(null);

const showFilterPane = computed(() => store.getters["campaigns/showFilterPane"]);

const tableHeaders = ref([
    "ID", "Campaign", "Client", "Workflow Status", "Owners", "Actions"
]);
const isLoading = ref(false);
const isCreateModalVisible = ref(false);
const isEditModalVisible = ref(false);
const awaitingSearch = ref(false);
const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
});

const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent = userPermissionsStore.isStudent
    userPermissions.isResearch = userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch = userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser = userPermissionsStore.isSuperuser
    userPermissions.isAccountManager = userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff
    userPermissions.isDev = userPermissionsStore.isDev
    userPermissions.isResearchLead = userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies = userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard = userPermissionsStore.isGenerateTimecard

    // console.log('user permissions', userPermissions)
}

const campaignFilters = reactive({
    active_status: "Active",
    clients: [],
    workflow_status: null
})

const activeStatusOptions = ref([
    { value: "clear", label: "------------" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
]);

const clientOptions = ref([
    { value: "clear", label: "------------" }
])

const workflowStatusOptions = ref([
    { value: "clear", label: "------------" },
    { value: "None", label: "None" },
    { value: "Active Outreach", label: "Active Outreach" },
    { value: "Client Review", label: "Client Review" },
    { value: "On Hold", label: "On Hold" },
    { value: "Prospect", label: "Prospect" },
    { value: "Research", label: "Research" },
    { value: "Retired", label: "Retired" },
    { value: "Vetting Targets", label: "Vetting Targets" },
])

async function loadCampaigns(endpoint = fetchFilter.value) {
    isLoading.value = true;
    let payload = {
        token: store.state.auth.token,
        endpoint: endpoint
    }
    // console.log("endpoint: " + payload.endpoint)
    const result = await getCampaigns(payload);
    campaignsResults.value = result;
    numberOfCampaignsDisplayed.value = campaignsResults.value ? campaignsResults.value.length : 0
    isLoading.value = false;
}

function showCreateModal() {
    isCreateModalVisible.value = true;
}

function closeCreateModal() {
    isCreateModalVisible.value = false;
}

function showEditModal(campaign) {
    campaignToEdit.value = campaign;
    isEditModalVisible.value = true;
}

function closeEditModal() {
    isEditModalVisible.value = false;
}

async function setClientOptions() {
    const token = store.getters['auth/showToken']
    const result = await getClients({ token: token })
    clientOptions.value = [{ value: "clear", label: "------------" }, ...result.clients.map((client) => {
        return { value: client.id, label: client.client_name }
    })]
}

watch(
    () => campaignFilters,
    () => {
        if (!awaitingSearch.value) {
            setTimeout(() => {
                loadCampaigns(fetchFilter.value, false);
                awaitingSearch.value = false;
            }, 1000); // 1 sec delay
        }
        awaitingSearch.value = true;
    },
    { deep: true }
);

function setActiveStatusFilter(target) {
    target == "clear"
        ? (campaignFilters.active_status = "")
        : (campaignFilters.active_status = target)
}

function setClientFilter(target) {
    // console.log(target)
    target.includes("clear")
        ? (campaignFilters.clients = [])
        : (campaignFilters.clients = target)
}

function setWorkflowStatus(target) {
    target == "clear"
        ? (campaignFilters.workflow_status = "")
        : (campaignFilters.workflow_status = target)
}

const fetchFilter = computed(function () {
    let query = new URLSearchParams({})
    if (campaignFilters.active_status) {
        query.append("active_status", campaignFilters.active_status)
    }
    if (campaignFilters.clients.length > 0) {
        campaignFilters.clients.forEach((client) => {
            query.append("client", client)
        })
    }
    if (campaignFilters.workflow_status) {
        query.append("workflow_status", campaignFilters.workflow_status)
    }

    let url = "?"
    let queriesCount = 0
    for (let value of query.values()) {
        queriesCount = queriesCount + 1
    }
    if (queriesCount > 0) {
        url = url + query.toString()
    }
    return url
})

function resetFilters() {
    campaignFilters.active_status = ""
    campaignFilters.clients = []
    campaignFilters.workflow_status = ""
}

setUserPermissions()
loadCampaigns()
setClientOptions()
</script>
<template>
    <div class="grid grid-cols-12 space-x-2 pr-2 py-2 border-b border-gray-400 w-full">
        <div class="col-span-1 flex items-center">
            <div v-if="index===0" class="flex justify-start px-2">
                Where 
            </div>
            <div v-else-if="index===1" class="h-full w-full flex justify-end mt-5">
                <slot name="conjunctionSelector"></slot>
            </div>
            <div v-else class="w-full h-full mt-9 flex justify-end pr-3">
                {{ conjunction }}
            </div>
        </div>
        <div class="col-span-10 flex flex-row justify-between items-center space-x-1 w-full">
            <FTSCondition v-if="conditionType==='condition'"
                :conditionKey="conditionKey"
                :index="index"
                @upsert-query-condition="emitUpdateQueryConditions"
            />
            <FTSConditionGroup v-if="conditionType==='conditionGroup'" 
                :conditionKey="conditionKey"
                :nestingLevel="nestingLevel"
                :index="index"
                @upsert-query-condition="emitUpdateQueryConditions"
            />
        </div>
        <div class="col-span-1 flex justify-end">
            <base-button v-if="index>0" @click.prevent="removeQueryCondition">-</base-button>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import FTSCondition from './FTSCondition.vue';
import FTSConditionGroup from './FTSConditionGroup.vue';

const props = defineProps(['conjunction', 'index', 'conditionKey', 'conditionType', 'nestingLevel'])

const emit = defineEmits(['upsert-query-condition', 'remove-query-condition'])

const nestingLevel = computed(() => {
    return props.nestingLevel + 1
})


function removeQueryCondition(){
    emit('remove-query-condition', props.conditionKey)
}

function emitUpdateQueryConditions(queryCondition) {
    emit('upsert-query-condition', queryCondition)
}

</script>
<template>
    <div>
        <RevenueHeadcountSqFtCell :company="company" @refetch-companies="fetchNewCompanies"/>
        <ZiRevenueHeadcountCell :company="company" />
    </div>
</template>

<script>
    export default {
        name: "zi-revenue-headcount-revenue-headcount-sq-ft-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import ZiRevenueHeadcountCell from '../company-cell-components/ZiRevenueHeadcountCell.vue';
import RevenueHeadcountSqFtCell from '../company-cell-components/RevenueHeadcountSqFtCell.vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])
    function fetchNewCompanies(){
        emit('refetch-companies')
    }
</script>
<template>
    <base-table-row>
        <base-table-cell> {{ timecard.user.first_name  + " " + timecard.user.last_name}} </base-table-cell>
        <base-table-cell> {{ formatTimecardDates(addDays(timecard.timecard_start, 1)) + " - " + formatTimecardDates(addDays(timecard.timecard_start, 7)) }}</base-table-cell>
        <base-table-cell class="">
            <p>Sat: {{ timecard.sat_hours }},<span class="pl-1"></span> Sun: {{ timecard.sun_hours }},</p>
            <p>Mon: {{ timecard.mon_hours }},<span class="pl-1"></span> Tue: {{ timecard.tue_hours }},</p>
            <p>Wed: {{ timecard.wed_hours }},<span class="pl-1"></span> Thu: {{ timecard.thu_hours }},</p>
            <p>Fri: {{ timecard.fri_hours }}</p>
        </base-table-cell>
        <base-table-cell> {{ timecard.total_hours }} </base-table-cell>
        <base-table-cell> {{ timecard.status }}</base-table-cell>
        <base-table-cell> 
        <div class="grid grid-cols-2 mx-3 my-1">
            <base-button @click="emitOpenTimecard" class="mx-1 col-span-1"> View </base-button>

            <base-button
                v-if="status !== 'approved' && status !== 'paid' && status !== 'submitted'" 
                class="mx-1 col-span-1"
                @click="editTimecard"
            > Edit 
            </base-button>

            <base-button 
                v-if="status !== 'paid' && (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser)" 
                @click="setTimecardStatusPaid" 
                class="mx-1 col-span-1"
            > Mark Paid 
            </base-button>

            <base-button 
                v-if="status !== 'approved' && status !== 'submitted' && status !== 'paid'"
                @click="setTimecardStatusSubmitted"  
                class="mx-1 col-span-1"
            > Submit 
            </base-button>

            <base-button 
                v-if="status !== 'active' && status !== 'approved' && status !='paid' && (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountManager )"  
                @click="setTimecardStatusApproved"  
                class="mx-1 col-span-1"
            > Approve
            </base-button>

            <base-button 
                v-if="status !== 'active' && status !== 'paid' && (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountManager)" 
                @click="setTimecardStatusActive" 
                class="mx-1 col-span-1"
            > Mark Active 
            </base-button>   
            <base-button 
                v-if="status !== 'paid' && (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser)" 
                @click="removeTimecard" 
                class="mx-1 col-span-1"
            > Delete
            </base-button>   
            <base-button 
                v-else-if="status !== 'paid' && (userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountStaff)" 
                @click="removeTimecard" 
                class="mx-1 col-span-1"
            > Delete
            </base-button>   
            <base-button 
                v-else-if="(store.state.auth.user.id === timecard.user.id) && (status == 'active' || status == 'submitted')" 
                @click="removeTimecard" 
                class="mx-1 col-span-1"
            > Delete
            </base-button>   
        </div>
        </base-table-cell>
    </base-table-row>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits, onMounted, shallowReactive } from "vue";
import { createTimecardNextSaturday, createTimecardLastSaturday, markTimecardStatusPaid, resetTimecardActive, markTimecardStatusApproved, markTimecardStatusSubmitted, deleteTimecard } from '@/services/Timecard.js';
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

const props = defineProps({
    timecard: Object,
    isTimecardUser: Boolean
});
const emit = defineEmits(['reload', 'openTimecard', 'open-dialogue', 'editTimecard']);
const $toast = useToast()

const store = useStore();
const status = ref(props.timecard.status);

const userPermissions = shallowReactive({
  isManagement: null,
  isHourly: null,
  isSuperuser: null,
  isDev: null,
  isAccountManager: null,
  isAccountStaff: null
});

const setUserPermissions = function () {
  let userPermissionsStore = store.getters['auth/showUserPermissions']
  userPermissions.isManagement = userPermissionsStore.isManagement
  userPermissions.isHourly = userPermissionsStore.isHourly
  userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
  userPermissions.isDev =  userPermissionsStore.isDev
  userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
  userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
}
setUserPermissions();

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function formatTimecardDates(ts) {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let formatted = days[ts.getDay()] + ": " + (ts.getMonth() + 1) + '/' + ts.getDate() + '/' + ts.getFullYear();
    return formatted;
} 


function getPreviousSaturday() {
    const dateObject = new Date();
    const dayOfWeek = 6; //saturday
    let resultDate = new Date(dateObject.getTime());
    resultDate.setDate(dateObject.getDate() - (7 - dayOfWeek + dateObject.getDay()) % 7);
    const strToReturn = "(" + (resultDate.getMonth() + 1) + "/"  + resultDate.getDate() + ")";
    return strToReturn;
}

function getNextSaturday() {
    const dateObject = new Date();
    const dayOfWeek = 6; //saturday
    let resultDate = new Date(dateObject.getTime());
    resultDate.setDate(dateObject.getDate() + (7 + dayOfWeek - dateObject.getDay()) % 7);
    const strToReturn = "(" + (resultDate.getMonth() + 1) + "/"  + resultDate.getDate() + ")";
    return strToReturn;
}

async function genTimecardNextSat() {
    const data = JSON.stringify({
        user: props.timecard.user.id,
    });

    const output = await createTimecardNextSaturday(data, store.state.auth.token);
    if (output) {
        emit('open-dialogue', 'Success', 'Successfully created timecard for ' + props.timecard.user.first_name + " " + props.timecard.user.last_name + " for next Saturday " + getNextSaturday() + ".");
    } else {
        emit('open-dialogue', "Error", "Unexpected error while generating new timecard. Please retry.");
    }
    emit('reload');

}

async function genTimecardPrevSat() {
    const data = JSON.stringify({
        user: props.timecard.user.id,
    });

    const output = await createTimecardLastSaturday(data, store.state.auth.token);
    if (output) {
        emit('open-dialogue', 'Success', 'Successfully created timecard for ' + props.timecard.user.first_name + " " + props.timecard.user.last_name + " for last Saturday " + getPreviousSaturday() + ".");
    } else {
        emit('open-dialogue', "Error", "Unexpected error while generating new timecard. Please retry.");
    }
    emit('reload');
}

async function setTimecardStatusPaid() {
    const data = JSON.stringify({
        timecard: props.timecard.id
    });
    const result = await markTimecardStatusPaid(data, store.state.auth.token)
    if (result.status == 200) {
        $toast.open({
            message: `Marked timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as paid.`,
            type: "success",
            duration: 3000
        })
        // emit('open-dialogue', "Success","Marked timecard for " + props.timecard.user.first_name + " " + props.timecard.user.last_name + " as paid.")
        status.value = 'paid';
    } else {
        $toast.open({
            message: `Error, could not mark timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as paid.`,
            type: "error",
            duration: 3000
        })
        // emit('open-dialogue', "Error", "Unexpected error while setting timecard status. Please retry.");
    }
    emit('reload');
}

async function setTimecardStatusActive() {
    const data = JSON.stringify({
        timecard: props.timecard.id
    });
    const result = await resetTimecardActive(data, store.state.auth.token)
    if (result.status == 200) {
        $toast.open({
            message: `Marked timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as active.`,
            type: "success",
            duration: 3000
        })
        // emit('open-dialogue', 'Success', "Marked timecard for " + props.timecard.user.first_name + " " + props.timecard.user.last_name + " as active.")
        status.value = 'active';
    } else {
        $toast.open({
            message: `Error, could not mark timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as active.`,
            type: "error",
            duration: 3000
        })
        // emit('open-dialogue', "Error", "Unexpected error while setting timecard status. Please retry.");
    }
    emit('reload');
}

async function setTimecardStatusApproved() {
    const data = JSON.stringify({
        timecard: props.timecard.id
    });

    const result = await markTimecardStatusApproved(data, store.state.auth.token)
    if (result.status == 200) {
        $toast.open({
            message: `Marked timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as approved.`,
            type: "success",
            duration: 3000
        })
        // emit('open-dialogue', 'Success', "Marked timecard for " + props.timecard.user.first_name + " " + props.timecard.user.last_name + " as approved.")
        status.value = 'approved';
    } else {
        $toast.open({
            message: `Error, could not mark timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as approved.`,
            type: "error",
            duration: 3000
        })
        // emit('open-dialogue', "Error", "Unexpected error while setting timecard status. Please retry.");
    }
    emit('reload');
}

async function setTimecardStatusSubmitted() {
    const data = JSON.stringify({
        timecard: props.timecard.id
    });
    if(store.state.auth.user.id !== props.timecard.user.id){
        if(confirm("Are you sure you want to submit this timecard? It's preferred that the owner submit their own timecard, and you will be recorded as the user who submitted it.")){
            const result = await markTimecardStatusSubmitted(data, store.state.auth.token)
            if (result.status == 200) {
                $toast.open({
                    message: `Marked timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as submitted.`,
                    type: "success",
                    duration: 3000
                })
                // emit('open-dialogue', 'Success', "Marked timecard for " + props.timecard.user.first_name + " " + props.timecard.user.last_name + " as submitted.")
                status.value = 'submitted';
            } else {
                $toast.open({
                    message: `Error, could not mark timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as submitted.`,
                    type: "error",
                    duration: 3000
                })
                // emit('open-dialogue', "Error", "Unexpected error while setting timecard status. Please retry.");
            }
            emit('reload');
        }
    } else {
        const result = await markTimecardStatusSubmitted(data, store.state.auth.token)
        if (result.status == 200) {
            $toast.open({
                message: `Marked timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as submitted.`,
                type: "success",
                duration: 3000
            })
            // emit('open-dialogue', 'Success', "Marked timecard for " + props.timecard.user.first_name + " " + props.timecard.user.last_name + " as submitted.")
            status.value = 'submitted';
        } else {
            $toast.open({
                message: `Error, could not mark timecard for ${props.timecard.user.first_name} ${props.timecard.user.last_name} as submitted.`,
                type: "error",
                duration: 3000
            })
            // emit('open-dialogue', "Error", "Unexpected error while setting timecard status. Please retry.");
        }
        emit('reload');
    }
}

function editTimecard() {
    emit('editTimecard', props.timecard);
}

function emitOpenTimecard() {
    emit('openTimecard', props.timecard);
}

async function removeTimecard(){
    const token = store.state.auth.token
    if(confirm("Are you sure you want to delete this timecard?")){
        const result = await deleteTimecard(props.timecard.id, token)
        if(result.status === 204){
            $toast.open({
                message: `Successfully deleted Timecard`,
                type: 'success',
                duration: 3000
            })
        } else {
            $toast.open({
                message: result.response.data.detail,
                type: 'error',
                duration: 3000
            })
        }
        emit('reload')
    } else {
        return null
    }
}

</script>
<template>
    <div
        v-if="
        permissions.isDev ||
        permissions.isAdvancedResearch ||
        permissions.isAccountManager ||
        permissions.isManagement ||
        permissions.isSuperuser
        "
    >
        <base-button v-if="company.zoom_info_company" @click="transferZiBasic">Transfer ZI Basic</base-button>
    </div>
</template>

<script>
    export default {
        name: "transfer-zi-basic-cell"
    }
</script>

<script setup>
    import { defineProps, defineEmits } from 'vue';
    import { transferZoomInfoBasic } from '@/services/Company';
    import { useStore } from 'vuex';
    import { useToast } from 'vue-toast-notification';


    const store = useStore()
    const $toast = useToast()

    const props = defineProps({
        permissions: Object,
        company: Object,
    })

    const emit = defineEmits(['refetch-companies'])

    async function transferZiBasic() {
        const token = store.state.auth.token
        const result = await transferZoomInfoBasic(props.company.id, token)
        if(result.status == 200) {
        $toast.open({
            message: result.data.status,
            type: 'success',
            duration: 5000
        })
        } else {
            $toast.open({
                message: 'Error Transferring ZI Basic.',
                type: 'error',
                duration: 5000
            })
        }
        emit('refetch-companies')
    }


</script>
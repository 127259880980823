<template>
    <button class="
        rounded-md
        px-1.5
        py-1
        mx-1
        max-w-32
        my-1
        bg-dark-primary
        border
        border-dark-primary
        text-white
        hover:bg-white hover:shadow-lg
        focus:bg-white focus:shadow-lg
        focus:outline-none focus:ring-0
        focus:text-dark-primary
        active:text-dark-primary
        active:bg-white-400 active:shadow-lg
        hover:text-dark-primary
        hover:
        text-xs
        cursor-pointer
        sm:text-xs
        sm:h-fit
      ">
        <slot></slot>
    </button>
  
  </template>
  

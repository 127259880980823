<template>
    <form @submit.prevent>
      <div class="flex">
        <div class="m-1 w-full">
          <label class="bg-light-primary text-xs py-1 px-2 w-full rounded-t-md flex items-center justify-between" :class="{'bg-amber-300': active}" for="companyName">
            <slot name="label"></slot>
          </label>
          <div class="rounded-b-md h-8 text-xs
                      border text-gray-900
                      focus:ring-dark-primary focus:border-dark-primary">
            <slot name="widget"></slot>
          </div>
        </div>
      </div>
    </form>
  </template>
  
  <script>
  export default {
    name: "BaseFilterNoButton.vue",
  }
  </script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  active: Boolean
})

</script>
<template>
    <div class="w-full h-full">
        <base-form class="w-full h-full">
            <template #body>
                <div class="grid grid-cols-4 w-full gap-2 mb-1 p-1 text-xs">
                    <div class="flex flex-col">
                        <label>Campaign Name</label>
                        <input type="text" required class="p-1 border w-full inline" v-model="newCampaignData.campaign_name">
                    </div>
                    <div class="flex flex-col">
                        <label>Workflow Status</label>
                        <select class="border p-1 w-full" v-model="newCampaignData.workflow_status">
                            <option required v-for="(workflowStatus, index) in workflowStatusOptions" :key="index"
                                :value="workflowStatus.value">
                                {{ workflowStatus.label }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label>Has Activities</label>
                        <select class="border p-1 w-full" v-model="newCampaignData.has_activities">
                            <option v-for="(activitiesStatus, index) in activitiesStatusOptions" :key="index"
                                :value="activitiesStatus.value">
                                {{ activitiesStatus.label }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label>Client</label>
                        <select class="border p-1 w-full" v-model="newCampaignData.client_id">
                            <option v-for="(client, index) in clientsList" :key="index" :value="client.id">
                                {{ client.client_name }}
                            </option>
                        </select>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="flex flex-row space-x-2 justify-center w-full my-2">
                    <base-button-affirmative @click="addCampaign">Save</base-button-affirmative>
                    <base-button-negative @click="closeCreate">Cancel</base-button-negative>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getClients } from "@/services/Client";
import { createNewCampaign } from '@/services/Campaigns.js';

const store = useStore()

const emit = defineEmits(['refetch-campaigns', 'close-create'])

function closeCreate() {
    emit('close-create')
}
const newCampaignData = ref({
    campaign_name: null,
    status: "active",
    workflow_status: null,
    has_activities: null,
    client_id: null
})

const activitiesStatusOptions = ref([
    { value: null, label: "Unknown" },
    { value: true, label: "Yes" },
    { value: false, label: "No" },
]);
const workflowStatusOptions = ref([
    { value: "None", label: "None" },
    { value: "Active Outreach", label: "Active Outreach" },
    { value: "Client Review", label: "Client Review" },
    { value: "On Hold", label: "On Hold" },
    { value: "Prospect", label: "Prospect" },
    { value: "Research", label: "Research" },
    { value: "Retired", label: "Retired" },
    { value: "Vetting Targets", label: "Vetting Targets" },
])

const clientsList = ref([])

async function loadClients() {
    const result = await getClients({ token: store.state.auth.token });
    clientsList.value = result.clients;
}

async function addCampaign() {
    const token = store.state.auth.token
    let errorMessage = "Error: Missing "
    if (!newCampaignData.value.campaign_name || !newCampaignData.value.workflow_status) {
        if (!newCampaignData.value.campaign_name) {
            errorMessage += "campaign name, "
        }
        if (!newCampaignData.value.workflow_status) {
            errorMessage += "workflow status, "
        }
        alert(errorMessage.slice(0, -2))
        return
    }
    const data = newCampaignData.value
    const result = await createNewCampaign(data, token)
    emit('refetch-campaigns')
    emit('close-create')
}

onMounted(() => {
    loadClients();
});
</script>
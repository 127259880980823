<template>
    <base-table-row>
        <base-table-cell-condensed class="w-1/4">
            <div class="font-bold">
                {{ company.company_name }}
            </div>
            <div>
                {{ company.crm_summary ? company.crm_summary : '' }}
            </div>
        </base-table-cell-condensed>

        <base-table-cell-condensed class="w-1/5">
            {{ company.account_manager_research_notes ? company.account_manager_research_notes : '' }}
        </base-table-cell-condensed>

        <base-table-cell-condensed class="w-1/6">
            <div>
                {{ company.revenue ? company.revenue : '' }} / {{ company.employee_headcount ? company.employee_headcount :
                    '' }}
            </div>
            <div>
                {{ company.city ? company.city : '' }}, {{ company.state ? company.state : '' }}
            </div>
        </base-table-cell-condensed>
        <base-table-cell-condensed class="w-1/12">
            {{ calculateDaysSinceLastActivity() }}
        </base-table-cell-condensed>
        <base-table-cell-condensed class="w-1/12">
            {{ company.crm_next_action ? company.crm_next_action : 'None' }}
        </base-table-cell-condensed>

        <base-table-cell-condensed class="w-1/12">
            {{ company.crm_next_action_due_date ? formatDate(company.crm_next_action_due_date) : '' }}
        </base-table-cell-condensed>

        <base-table-cell-condensed class="w-1/12">
            {{ company.crm_next_action_due_date ? calculateDaysUntil(company.crm_next_action_due_date) : 'None' }}
        </base-table-cell-condensed>

    </base-table-row>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    company: Object
})

function calculateDaysUntil(date) {
    const targetTime = new Date(date).getTime()
    const currentTime = new Date().getTime()
    const timeDiff = targetTime - currentTime
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
    return daysRemaining;
}

function formatDate(date) {
    const dateParts = date.split('-')
    const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
    return formattedDate
}

function calculateDaysSinceLastActivity() {
    if (props.company.activities.length > 0) {
        const dates = props.company.activities.map((activity) => new Date(activity.activity_date))
        const mostRecentDate = new Date(Math.max(...dates))
        const currentDate = new Date()
        const timeDifference = currentDate.getTime() - mostRecentDate.getTime()
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        return daysDifference
    } else {
        return 'None'
    }

}
</script>
<template>
    <div>
        <CompanyNameCell :company="company" @refetch-companies="fetchNewCompanies" class="font-bold" />
        <CageCodeCell :company="company"></CageCodeCell>
    </div>

</template>

<script>
    export default {
        name: 'company-name-cage-code-cell'
    }
</script>

<script setup>
import CompanyNameCell from '../company-cell-components/CompanyNameCell.vue';
import CageCodeCell from '../company-cell-components/CageCodeCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
<template>
    <div class="flex-col">
        <div>
            <AcctMgrResearchNotesCell class="inline" :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "account-manager-notes-account-manager-research-notes-cell"
    }
</script>

<script setup>
import AcctMgrResearchNotesCell from '../company-cell-components/AcctMgrResearchNotesCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }

</script>
<template>
    <div class="bg-gray-200 overflow-x-hidden w-full">
    <h2 class="text-md border-b border-b-dark-primary p-1 my-3 pt-5 mx-5"> Create New Email Template </h2>
    <base-button 
        class="m-5"
        @click="toggleIsTemplateFieldsOpen"> 
            {{viewTemplateFieldsAction}} Email Template Fields
        </base-button>
    <form @submit.prevent="submitForm">
    <div class='flex flex-row h-[600px]'>
    <div class="w-full">
        <div class="grid grid-cols-3 gap-x-8 gap-y-2 ml-5 my-5 mr-1">
            <div class="flex flex-col">
                <label class="text-sm"> Email Template Name </label>
                <input
                    type="text"
                    class="pl-2 border w-full h-[25px] text-sm"
                    v-model="newTemplateInfo.template_name"
                />
            </div>
            <div class="flex flex-col">
                <label class="text-sm"> Status </label>
                <select 
                    class="border w-full h-[25px] text-sm"
                    v-model="newTemplateInfo.status">
                        <option> active </option>
                        <option> inactive </option>
                </select>
            </div>
            <div class="flex flex-col">
                <label class="text-sm"> Client </label>
                <select
                class="border w-full h-[25px] text-sm"
                v-model="newTemplateInfo.client">
                    <option
                        v-for="client in clientList"
                        :key="client.id"
                        :value="client.id"
                    >
                        {{ client.client_name }}
                    </option>
                </select>
            </div>
            <div class="flex flex-col">
                <label class="text-sm"> To </label>
                <select
                class="border pl-2 w-full h-[25px] text-sm"
                v-model="newTemplateInfo.to">
                    <option
                        v-for="email in emailToOptions"
                        :key="email.value"
                        :value="email.value"
                    >
                        {{ email.label }}
                    </option>
                </select>
            </div>
            <div class="flex flex-col">
                <label class="text-sm"> From </label>
                <select
                class="border w-full h-[25px] text-sm"
                v-model="newTemplateInfo.from">
                    <option
                        v-for="email in emailFromOptions"
                        :key="email.value"
                        :value="email.value"
                    >
                        {{ email.label }}
                    </option>
                </select>
            </div>
            <div class="flex flex-col">
                <label class="text-sm"> Email BCC - Injectable Field</label>
                <input
                    type="text"
                    class="pl-2 border w-full h-[25px] text-sm"
                    v-model="newTemplateInfo.email_bcc"
                />
            </div>
            <div class="flex flex-col col-span-2">
                <label class="text-sm"> Subject </label>
                <input
                    type="text"
                    class="pl-2 border w-full h-[25px] text-sm"
                    v-model="newTemplateInfo.subject"
                />
            </div>
            </div>
            <div class="ml-5 my-5 mr-1 w-100vh">
                <label class="text-sm"> Email Body </label>
                <textarea
                    class="text-sm p-2 border h-full w-full"
                    v-model="newTemplateInfo.email_body"
                    rows="13"
                ></textarea>
            </div>
       
        <div class="mt-10 space-y-4 flex flex-col w-full pb-5 border-b ml-5 my-5 mr-1">
            <div class="flex flex-row ">
                <label class="text-sm mr-5 w-[120px]"> Signature Image: </label>
                    <div class="flex flex-row justify-between">
                        <input
                        @change="processSignatureImage"
                        name="signatureFile"
                        id="signatureFile"
                        type="file"
                        accept=".png, .jpg, .jpeg, .svg"
                        class="text-sm"
                        />
                        
                    </div>
            </div>
            <div class="flex flex-row ">
                <label class="text-sm mr-5 w-[120px]"> Logo Image 1: </label>
                    <div class="flex flex-row justify-between">
                        <input
                        @change="processLogoImage1"
                        name="logoImage1"
                        id="logoImage1"
                        type="file"
                        accept=".png, .jpg, .jpeg, .svg"
                        class="text-sm"
                        />
                        
                    </div>
            </div>
            <div class="flex flex-row ">
                <label class="text-sm mr-5 w-[120px]"> Logo Image 2: </label>
                    <div class="flex flex-row justify-between">
                        <input
                        @change="processLogoImage2"
                        name="logoImage2"
                        id="logoImage2"
                        type="file"
                        accept=".png, .jpg, .jpeg, .svg"
                        class="text-sm"
                        />  
                    </div>
            </div>
        </div>
       
    </div>
        <email-template-fields-table 
        class="w-full"
        v-if="isTemplateFieldsOpen">
        </email-template-fields-table>
    </div>
    <div>
            <p class='text-red-500 text-sm font-bold mx-5' v-for="error in errors" :key="error">
                {{ error }}
            </p>
        </div>
        <div class="text-center space-x-3 mt-3 mb-5">
            <base-button-affirmative type="submit"> Submit </base-button-affirmative>
            <base-button-negative @click="goBackToMainPage"> Cancel </base-button-negative>
        </div>
</form>
</div>

</template>

<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { getAllClientsSimple } from '@/services/Client'
import { getEmailsSendToAddresses, createNewEmailTemplate } from '@/services/EmailTemplates'
import EmailTemplateFieldsTable from './EmailTemplateFieldsTable.vue';

const store = useStore();
const router = useRouter();

const newTemplateInfo = reactive({
    template_name: null,
    status: 'active',
    client: null,
    to: null,
    from: null,
    email_bcc: null,
    subject: null,
    email_body: null, 
    signature_image: null,
    logo_img1: null,
    logo_img2: null,
})

const clientList = ref([]);
const emailToOptions = ref([]);
const emailFromOptions = ref([
    {value: null, label:'------------'},
    {value:'dan.klawitter@sheddcapital.com', label: 'dan.klawitter@sheddcapital.com'},
    {value:'dklawitter@rtcaerospace.com', label: 'dklawitter@rtcaerospace.com'},
    {value:'matthew.strickler@sheddcapital.com', label: 'matthew.strickler@sheddcapital.com'},
    {value:'mstrickler@novariagroup.com', label: 'mstrickler@novariagroup.com'},
    {value:'mstrickler@sheddcapital.com', label: 'mstrickler@sheddcapital.com'},
    {value:'norman@sheddcapital.com', label: 'norman@sheddcapital.com'},
    {value:'scott.shedd@pcxaero.com', label: 'scott.shedd@pcxaero.com'},
    {value:'sshedd@novariagroup.com', label: 'sshedd@novariagroup.com'},
    {value:'sshedd@sheddcapital.com', label: 'sshedd@sheddcapital.com'},
    {value:'noah.grimsley@sheddcapital.com', label: 'noah.grimsley@sheddcapital.com'},
    {value:'brandon.ahearn@sheddcapital.com', label: 'brandon.ahearn@sheddcapital.com'},
    {value:'suzanne.strickler@sheddcapital.com', label: 'suzanne.strickler@sheddcapital.com'},
    {value:'scott@precise-metals.com', label: 'scott@precise-metals.com'},
    {value:'tony.patsatzis@sheddcapital.com', label: 'tony.patsatzis@sheddcapital.com'},
]);

const isTemplateFieldsOpen = ref(false)
const viewTemplateFieldsAction = ref('View');
const errors = ref([]);

function goBackToMainPage() {
    router.push({ path: 'email_templates'})
}

function toggleIsTemplateFieldsOpen() {
    isTemplateFieldsOpen.value = !isTemplateFieldsOpen.value;
    if(isTemplateFieldsOpen.value) {
        viewTemplateFieldsAction.value = 'Hide';
    } else {
        viewTemplateFieldsAction.value = 'View';
    }
}

async function loadClientOptions() {
    const output = await getAllClientsSimple({token: store.state.auth.token});
    clientList.value = output.data
}

async function loadSendToAddresses() {
    const output = await getEmailsSendToAddresses({token: store.state.auth.token})
    emailToOptions.value.push({value: null, label: '------------'})
    for (let i = 0; i < output.data.length; i++) {
        emailToOptions.value.push({value: i, label: output.data[i]})
    }
    
}

function processSignatureImage(event) {
    if (event.target.files && event.target.files[0]) {
        newTemplateInfo.signature_image = event.target.files[0]
    }
}

function processLogoImage1(event) {
    if (event.target.files && event.target.files[0]) {
        newTemplateInfo.logo_img1 = event.target.files[0]
    }
}

function processLogoImage2(event) {
    if (event.target.files && event.target.files[0]) {
        newTemplateInfo.logo_img2 = event.target.files[0]
    }
}

async function submitForm() {
    errors.value = [];
    if (!newTemplateInfo.template_name || newTemplateInfo.template_name == "") {
        errors.value.push("Please specify an email template name.");
    }
    if (!newTemplateInfo.to || newTemplateInfo.to == "") {
        errors.value.push("Please specify an email address to which this email is sent ('send to'). ")
    }
    if (!newTemplateInfo.from || newTemplateInfo.from == "") {
        errors.value.push("Please specify an email address from which this email is sent ('send from'). ")
    }
    if (!newTemplateInfo.subject || newTemplateInfo.subject == "") {
        errors.value.push("Please specify a subject for this email. ")
    }

    if (errors.value.length > 0) {
        return;
    }

    let to_email = null;
    if (newTemplateInfo.to) {
        to_email = emailToOptions.value.at(newTemplateInfo.to + 1).label
    }

    let form_data = new FormData();

    if (newTemplateInfo.signature_image) {
        form_data.append("signature_image", newTemplateInfo.signature_image, newTemplateInfo.signature_image.name);
    } 
    if (newTemplateInfo.logo_img1) {
        form_data.append("logo_image", newTemplateInfo.logo_img1, newTemplateInfo.logo_img1.name);
    } 
    if (newTemplateInfo.logo_img2) {
        form_data.append("logo_image_2", newTemplateInfo.logo_img2, newTemplateInfo.logo_img2.name);
    }
    if (newTemplateInfo.client) {
        form_data.append("client", newTemplateInfo.client);
    }
    if (newTemplateInfo.email_body) {
        form_data.append("email_body", newTemplateInfo.email_body)
    }
    if(newTemplateInfo.email_bcc) {
        form_data.append("email_bcc", newTemplateInfo.email_bcc)
    }

    form_data.append("email_template_name", newTemplateInfo.template_name);
    form_data.append("status", newTemplateInfo.status);
    form_data.append("subject", newTemplateInfo.subject);
    form_data.append("email_to", to_email);
    form_data.append("email_from", newTemplateInfo.from);

    pushNewEmailTemplate(form_data);
    goBackToMainPage();
}

async function pushNewEmailTemplate(submission) {
    const output = await createNewEmailTemplate(submission, store.state.auth.token);
}


loadClientOptions();
loadSendToAddresses();
</script>

<template>
     <!-- is-form-visible always true right now, needs to be modified -->
    <base-modal :is-form-visible=true @close="closeModal">
        <template #header>
          <h2 class="text-lg">View Timecard</h2>
        </template>
        <template #body>
            <div class="h-full w-full grid grid-cols-1 overflow-auto">
                <div class="h-full w-full">
                    <div class="m-5">
                        <p class="text-lg mb-2"> {{ timecard.user.first_name }} {{ timecard.user.last_name }}'s Timecard</p>
                        <p> Time Period: {{ formatTimecardDates(addDays(timecard.timecard_start, 1)) + " - " + formatTimecardDates(addDays(timecard.timecard_start, 7)) }}</p>
                        <p> Timecard Status: {{ timecard.status }}</p>
                        <p> Total Hours: {{ timecard.total_hours }} hours </p>
                    <br/>
                    </div>  
                    <div class="m-2 grid grid-cols-7 ">
                        <div class="flex-col ml-6 border-r">
                            <div class="font-bold mb-2"> Saturday </div>
                            <div> {{ timecard.sat_date }}</div>
                            <div> {{ timecard.sat_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.sat_notes ? timecard.sat_notes : "No notes" }}</div>
                        </div>
                        <div class="flex-col ml-6 border-r">
                            <div class="font-bold mb-2"> Sunday </div>
                            <div> {{ timecard.sun_date }}</div>
                            <div> {{ timecard.sun_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.sun_notes ? timecard.sun_notes : "No notes" }}</div>
                        </div>
                        <div class="flex-col ml-6 border-r">
                            <div class="font-bold mb-2"> Monday </div>
                            <div> {{ timecard.mon_date }}</div>
                            <div> {{ timecard.mon_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.mon_notes ? timecard.mon_notes : "No notes" }}</div>
                        </div>
                        <div class="flex-col ml-6 border-r">
                            <div class="font-bold mb-2"> Tuesday </div>
                            <div> {{ timecard.tue_date }}</div>
                            <div> {{ timecard.tue_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.tue_notes ? timecard.tue_notes : "No notes" }}</div>
                        </div>
                        <div class="flex-col ml-6 border-r">
                            <div class="font-bold mb-2"> Wednesday </div>
                            <div> {{ timecard.wed_date }}</div>
                            <div> {{ timecard.wed_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.wed_notes ? timecard.wed_notes : "No notes"}}</div>
                        </div>
                        <div class="flex-col ml-6 border-r">
                            <div class="font-bold mb-2 "> Thursday </div>
                            <div> {{ timecard.thu_date }}</div>
                            <div> {{ timecard.thu_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.thu_notes ? timecard.thu_notes : "No notes"}}</div>
                        </div>
                        <div class="flex-col ml-6">
                            <div class="font-bold mb-2"> Friday </div>
                            <div> {{ timecard.fri_date }}</div>
                            <div> {{ timecard.fri_hours }} hours</div>
                            <div class="mt-4 mb-1"> Notes: </div>
                            <div class="text-sm mr-4"> {{ timecard.fri_notes ? timecard.fri_notes : "No notes"}}</div>
                        </div>
                    </div>
                    <!-- <hr> -->
                    <div class="h-fit mb-2" v-if="showCalculatedHours">
                        <div class="flex items-center w-full border-b justify-center">
                            <span ref="calculatedHoursLabel">Calculated Hours:</span> <HelpSVG @mouseover="showCalculatedHoursPopover" @mouseleave="hideCalculatedHoursPopover" stroke="#4B5563" />
                        </div>
                        <base-popover-hover-small
                            class="w-fit"
                            v-if="isCalculatedHoursPopoverVisible"
                            :xPosition="xPosition"
                            :yPosition="yPosition"
                        >
                            <template #title>
                                <span class="text-sm">Calculated Hours:</span>
                            </template>
                            <template #body>
                                <div class="py-1 border-gray-300">
                                    Calculated hours are based on how many times a user uses the system to retrieve, edit, or create data in a given hour.
                                </div>
                            </template>
                        </base-popover-hover-small>
                        <div v-if="loadingCalculatedHours" role="status" class="w-full h-20 flex justify-center items-center">
                            <svg
                            aria-hidden="true"
                            class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="white"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#2E9CCA"
                            />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div  v-if="calculatedHours" class="m-2 grid grid-cols-7">
                            <div v-for="(hours, day) in calculatedHours" :key="day" class="flex-col ml-6 border-r">
                                <!-- <div class="font-bold mb-2"> {{ day }} </div> -->
                                <div>{{ hours.calculated_work_hours }} hours</div>
                                <div><span :class="{'text-red-700' : hours.reported_calculated_hours_difference > 0, 'text-blue-700': hours.reported_calculated_hours_difference < 0, 'text-green-700': hours.reported_calculated_hours_difference === 0}">{{ hours.reported_calculated_hours_difference }}</span> hours difference</div>
                                <div> First entry: {{ hours.min_row ? hours.min_row.substring(8) : '--' }}</div>
                                <div> Last entry: {{ hours.max_row ? hours.max_row.substring(8) : '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <TimecardHistory v-if="showEditHistory" class="mt-2" :timecard="timecard" />
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { getCalculatedWorkHours } from '@/services/Timecard';
import TimecardHistory from './TimecardHistory.vue';
import HelpSVG from '../svg-components/HelpSVG.vue';

const store = useStore()
const props = defineProps({
    timecard: Object,
    userPermissions: Object
})

const emit = defineEmits(['close']);

const loadingCalculatedHours = ref(false)
const isCalculatedHoursPopoverVisible = ref(false)

const xPosition = ref(null)
const yPosition = ref(null)

const calculatedHoursLabel = ref(null)

function showCalculatedHoursPopover(){
    isCalculatedHoursPopoverVisible.value = true
    xPosition.value = calculatedHoursLabel.value.getBoundingClientRect().left
    yPosition.value = calculatedHoursLabel.value.getBoundingClientRect().bottom
}

function hideCalculatedHoursPopover(){
    isCalculatedHoursPopoverVisible.value = false
}

const showCalculatedHours = computed(() => {
    return props.userPermissions.isAccountStaff || props.userPermissions.isDev || props.userPermissions.isManagement
})
const showEditHistory = computed(() => {
    return props.userPermissions.isAccountStaff || props.userPermissions.isDev || props.userPermissions.isManagement
})

const calculatedHours = ref(null)

async function loadCalculatedHours(){
    const token = store.state.auth.token
    if(showCalculatedHours.value) {
        loadingCalculatedHours.value = true
        const result = await getCalculatedWorkHours(token, props.timecard.id)
        if(result.status == 200){
            calculatedHours.value = result.data
        }
        loadingCalculatedHours.value = false
    }
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function formatTimecardDates(ts) {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let formatted = days[ts.getDay()] + " " + (ts.getMonth() + 1) + '/' + ts.getDate() + '/' + ts.getFullYear();
    return formatted;
} 

function closeModal() {
    emit('close');
}

loadCalculatedHours()

</script>

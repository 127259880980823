import axios from 'axios';
import { BASE_URL } from './api';

export async function getSources(token) {
    return axios({
        method: "get",
        url: `${BASE_URL}/sources`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error(error))

}

export async function createSource(data, token) {
    console.log('data in axios', data)
    return axios({
        method: "post",
        url: `${BASE_URL}/sources/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    })
    .then(response => response)
    .catch(error => console.error(error))
}
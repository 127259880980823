<template>
    <button class="
        rounded-md
        px-1
        py-1
        max-w-32
        my-1
        border-solid
        border
        border-gray-400
        text-gray-500
        bg-gray-200
        text-xs
        sm:h-fit
      "
      type="button"
      disabled
      >
        <slot></slot>
    </button>
  
  </template>
  
  <script>
  export default {
    name: "BaseButton.vue"
  }
  </script>
  <style scoped>
  </style>
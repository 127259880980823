<template>
    <div class="z-40 fixed w-fit" :style="popoverPosition">
        <div class="text-xs">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
const props = defineProps({
    xPosition: Number,
    yPosition: Number,
})

const emit = defineEmits(['close-popover'])

const popoverPosition = computed(function () {
    return `left: ${props.xPosition-6}px; top: ${props.yPosition+5}px;`
})


</script>
<template>
    <div>
        <RevenueHeadcountCell :company="company" />
        <LocationCell :company="company" />
        <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>
</template>

<script>
    export default {
        name: "revenue-headcount-location-url-cell"
    }
</script>

<script setup>
import RevenueHeadcountCell from '../company-cell-components/RevenueHeadcountCell.vue';
import LocationCell from '../company-cell-components/LocationCell.vue';
import WebsiteUrlCell from '../company-cell-components/WebsiteUrlCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }


</script>
import axios from 'axios';
import { BASE_URL } from './api';
import { saveAs } from 'file-saver';

export async function getContacts(payload) {
    console.log(payload.endpoint)
    return axios({
        method: 'get',
        url: `${BASE_URL}/contacts${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        return {
            "contacts": response.data.results,
            "count": response.data.count,
            "next": response.data.next,
            "prev": response.data.previous,
            "page": response.data.page,
            "pageSize": response.data.page_size,
            "num_pages": response.data.num_pages
        };
    }).catch((error) => {
        console.log(error)
    });

}

export async function updateContact(contactId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/contacts/${contactId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
       return error
    })
}


export async function createNewContact(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/contacts/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}


export async function createContactAndCompanyRelationship(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/contacts/create_contact_and_company_relationship/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}


export async function updateContactCompanyRelationship(contactCompanyRelationshipId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/contact_companies/${contactCompanyRelationshipId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function verifyContactEmail(contactId, action, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/contacts/${contactId}/verify_contact_email/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data:  {
            'action': action
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function verifyContactCompanyEmail(contactCompanyRelationshipId, action, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/contact_companies/${contactCompanyRelationshipId}/verify_contact_company_email_address/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data:  {
            'action': action
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}


export async function createContactCompanyRelationship(formData, token) {
    console.log(formData)
    return axios({
        method: 'post',
        url: `${BASE_URL}/contacts/create_company_relationship/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}


export async function assignCompanyRelationship (companyId, contactId, token) {
    console.log("company id", companyId)
    let data = JSON.stringify({
        payload: null
    })
    if(companyId){
        data = JSON.stringify({
            payload: companyId
        })
    }
    return axios({
        method: 'put',
        url: `${BASE_URL}/contacts/${contactId}/assign_company_relationship/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    })
    .then(response => console.log(response))
    .catch(error => alert(error))
}

export async function removeCompanyRelationship (companyId, contactId, token) {
    console.log("company id", companyId)
    let data = JSON.stringify({
        payload: null
    })
    if(companyId){
        data = JSON.stringify({
        payload: companyId
        })
    }
    return axios({
        method: 'put',
        url: `${BASE_URL}/contacts/${contactId}/remove_company_relationship/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    })
    .then(response => console.log(response))
    .catch(error => alert(error))
}

export async function exportToCSV(filters, token, fields=null) {
    console.log('field in axios call', fields)
    let contactFields = null
    if(fields){
        contactFields = JSON.stringify({
            fields: fields
        })
    }
    console.log('fields json', contactFields)
    return axios({
        method: 'post',
        url: `${BASE_URL}/contacts/export_to_csv/${filters}`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: contactFields,
        responseType: 'blob'
    }).then((response) => {
        console.log('response csv', response)
        saveAs(new Blob([response.data]), `downloaded_contacts_${new Date().toDateString()}.csv`)
        return {
            response
        }
    }).catch((error) =>{
        console.log(error)
    })
}

export async function performBulkAction(token, contactIds, endpoint, payload) {
    console.log(payload)
    console.log(payload)
    return axios({
        method: "put",
        url: `${BASE_URL}/contacts/${endpoint}/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'contact_ids': contactIds,
            'payload': payload
        }
    })
    .then(response => response)
    .catch(error => alert(error))

}

export async function getZoomInfoId(contactId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/contacts/${contactId}/get_zoom_info_id/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function clearZoomInfo(contactId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/contacts/${contactId}/clear_zoom_info/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function getZoomInfoBasic(contactId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/contacts/${contactId}/get_zi_basic/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function transferZoomInfoBasic(contactId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/contacts/${contactId}/transfer_zi_basic/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => alert(error))    
}


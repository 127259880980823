import axios from "axios";
import { BASE_URL } from "./api";


export async function getUsers(payload) {
  // console.log('getting companies', payload.token)
  // console.log(payload)
  return axios({
    method: "get",
    url: `${BASE_URL}/list_users${payload.endpoint}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      // console.log('getUsersByGroup', response)
      return {
        users: response.data,
        count: response.data.length,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}


export async function getUsersByGroup(payload) {
  // console.log('getting companies', payload.token)
  // console.log(payload)
  return axios({
    method: "get",
    url: `${BASE_URL}/${payload.endpoint}?group=${payload.group}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      // console.log('getUsersByGroup', response)
      return {
        users: response.data,
        count: response.data.length,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}


export async function getActiveAndInactiveUsersByGroup(payload) {
  // console.log('getting companies', payload.token)
  // console.log(payload)
  return axios({
    method: "get",
    url: `${BASE_URL}/${payload.endpoint}?active_and_inactive_group=${payload.group}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      console.log('getUsersByGroup', response)
      return {
        users: response.data,
        count: response.data.length,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getUsersWithTasks(token) {
  // console.log('getting companies', .token)
  // console.log()
  return axios({
    method: "get",
    url: `${BASE_URL}/list_users?has_task=True`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      // console.log('getUsersByGroup', response)
      return {
        users: response.data,
        count: response.data.length,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAllUsers(token) {
  // console.log('getting companies', payload.token)
  // console.log(payload)
  return axios({
    method: "get",
    url: `${BASE_URL}/list_users/`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      // console.log('getUsersByGroup', response)
      return {
        users: response.data,
        count: response.data.length,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAllCampaignOwners(token) {
  // console.log('getting companies', payload.token)
  // console.log(payload)
  return axios({
    method: "get",
    url: `${BASE_URL}/list_users?owns_campaigns=True`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      // console.log('getUsersByGroup', response)
      return {
        users: response.data,
        count: response.data.length,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

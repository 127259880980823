import axios from 'axios';
import { BASE_URL } from './api';
import { saveAs } from 'file-saver';

export async function getAsynchronousJobQueue(payload) {
    // console.log('payload')
    // console.log(payload)
    // console.log('endpoint')
    // console.log(payload.endpoint)
    return axios({
        method: "get",
        url: `${BASE_URL}/asynchronous_jobs${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        }
    })
    .then(response => {
        // console.log('response')
        // console.log(response)
        return {
            "jobs": response.data.results,
            "count": response.data.count,
            "next": response.data.next,
            "prev": response.data.previous,
            "page": response.data.page,
            "pageSize": response.data.page_size,
            "numPages": response.data.num_pages
        }
    })
    .catch(error => console.error(error))

}

export async function runAsynchronousJob(jobId, token) {
    return axios({
        method: "post",
        url: `${BASE_URL}/asynchronous_jobs/${jobId}/run_asynchronous_job/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => {
        return response
    })
    .catch(error => console.error(error))

}

export async function createAsynchronousJob(data, token) {
    console.log('data')
    console.log(data)
    return axios({
        method: "post",
        url: `${BASE_URL}/asynchronous_jobs/create_asynchronous_job/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: data
    })
    .then(response => {
        console.log(response)
        return response
    })
    .catch(error => console.error(error))
}

export async function getDownloadLinkFromS3(jobId, token){
    return axios({
        method: "get",
        url: `${BASE_URL}/asynchronous_jobs/${jobId}/get_csv_export_from_s3`,
        headers: {
            'Authorization': `Token ${token}`
        },
        // responseType: 'blob'
    })
    .then(async response => {
        // console.log(response)
        return response
    })
    .catch(error => console.error(error))
}

<template>
  <div class="w-full px-4">
    <div class="w-full">
      <!-- DROPDOWN FILTER -->
      <div class="pt-5" v-if="showFilterPane">
        <div class="flex w-full justify-between border-b border-gray-400 pb-1">
          <div>
            Project Assignments:
          </div>
          <base-button-affirmative
            @click="resetProjectAssignmentFilters"
            class=""
          >
            Reset
          </base-button-affirmative>
        </div>
        <div class="flex my-5">
          <base-filter class="w-full text-base">
            <template v-slot:label>
              <h1 class="font-bold">Researcher</h1>
            </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Select Researcher"
                :options="researcherOptions"
                v-model="projectAssignmentFilters.researcher"
                @update:modelValue="setResearcherFilter"
                @click="loadSelectResearchers"
              />
            </template>
          </base-filter>
          <base-filter class="w-full">
            <template v-slot:label>
              <h1 class="">Research Project</h1>
            </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Select Project"
                :options="projectOptions"
                v-model="projectAssignmentFilters.project"
                @update:modelValue="setProjectFilter"
                @click="loadSelectResearchProjects"
              />
            </template>
          </base-filter>
          <base-filter class="w-full">
            <template v-slot:label>
              <h1 class="">General Availability</h1>
            </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Select Availability"
                :options="generalAvailabilityOptions"
                v-model="projectAssignmentFilters.availability"
                @update:modelValue="setAvailabilityFilter"
              />
            </template>
          </base-filter>
          <base-filter class="w-full">
            <template v-slot:label> <h1 class="">Date</h1> </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Select Date"
                :options="dateOptions"
                v-model="projectAssignmentFilters.date"
                @update:modelValue="setDateFilter"
              />
            </template>
          </base-filter>
          <base-filter class="w-full">
            <template v-slot:label>
              <h1 class="">Hourly Capacity</h1>
            </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Select Time"
                :options="hourlyCapacityOptions"
                v-model="projectAssignmentFilters.hourlyCapacity"
                @update:modelValue="setHourlyCapacityFilter"
              />
            </template>
          </base-filter>
          <base-filter class="w-full">
            <template v-slot:label>
              <h1 class="">Status</h1>
            </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Select Time"
                :options="statusOptions"
                v-model="projectAssignmentFilters.status"
                @update:modelValue="setStatusFilter"
              />
            </template>
          </base-filter>
        </div>
      </div>

      <!-- DIVIDER -->
      <div class="h-px w-full bg-stone-400"></div>
      <!-- PROJECT ASSIGNMENT TABLE & BUTTON-->
      <div class="m">
        <base-button
          @click="showProjectAssignmentForm"
          class="text-xs my-3"
        >
          Create New Project Assignment
        </base-button>
        <table class="w-full text-sm text-left">
          <thead
            class="text-dark-primary border-b-2 border-dark-primary sticky top-[3.5rem]"
          >
            <tr>
              <th scope="col" class="p-1">Researcher</th>
              <th scope="col" class="p-1">Research Project</th>
              <th scope="col" class="p-1">Actions</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr
              v-for="currProjectAssignment of projectAssignmentResults"
              :key="currProjectAssignment.id"
              class="border-b border-stone-300"
              :class="[
                currProjectAssignment.status == 'inactive' ? 'bg-gray-200' : '',
              ]"
            >
              <AssignmentRow
                @open-archive-window="showArchivePAForm"
                @open-edit-window="showEditPAForm"
                :projectAssignment="currProjectAssignment"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- NEW PROJECT ASSIGNMENT POPUP -->
    <div>
      <base-modal
        :is-form-visible="isNewProjectAssignmentFormVisible"
        @close="closeProjectAssignmentForm"
      >
        <template #header>
          <h2 class="text-lg">Create New Project Assignment</h2>
        </template>
        <template #body>
          <NewProjectAssignmentForm
            @close-form="closeProjectAssignmentForm"
            :researchers="researcherResults"
            :researchProjects="researchProjectResults"
          />
        </template>
      </base-modal>
    </div>
    <!-- EDIT PROJECT ASSIGNMENT POPUP -->
    <div>
      <base-modal :is-form-visible="isEditPAFormVisible" @close="closeEditPAForm">
        <template #header>
          <h2 class="text-lg">Edit Project Assignment</h2>
        </template>
        <template #body>
          <EditProjectAssignmentForm
            @close-form="closeEditPAForm"
            :researchers="researcherResults"
            :researchProjects="researchProjectResults"
            :currProjectAssignment="projectAssignmentToEdit"
          />
        </template>
      </base-modal>
    </div>
    <!-- ARCHIVE CONFIRMATION POPUP -->
    <div
      v-if="isArchivePAFormVisible"
      class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] flex justify-center items-center z-10"
      tabindex="-1"
    >
      <div
        class="flex-col bg-white shadow-lg-black p-3 w-[40%] rounded-md h-[30%] overflow-x-auto"
      >
        <header
          class="flex justify-between items-center relative p-15 pl-2 border-solid border-b border-gray-200"
        >
          <h1 class="my-5">
            Are you sure you want to archive
            <b
              >{{ projectAssignmentToArchive.researcher.first_name }}
              {{ projectAssignmentToArchive.researcher.last_name }}</b
            >
            working on
            <b
              >{{
                projectAssignmentToArchive.research_project.project_name
              }}?</b
            >
          </h1>
          <base-button-negative
            class="mb-auto ml-1 px-2 font-bold"
            type="button"
            @click="closeArchivePAForm"
          >
            X
          </base-button-negative>
        </header>
        <section class="max-h-[80vh] overscroll-contain">
          <div
            class="flex flex-row space-x-2 justify-center w-full col-span-2 my-5"
          >
            <base-button-affirmative @click="archiveSelectedProjectAssignment"
              >Archive</base-button-affirmative
            >
            <base-button-negative @click="closeArchivePAForm"
              >Cancel</base-button-negative
            >
          </div>
        </section>

        <footer class=""></footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  getProjectAssignments,
  getAllProjectAssignments,
  getAllResearchers,
  getAllResearchProjects,
  archiveProjectAssignment,
} from "@/services/ProjectAssignment.js";
import { getUsersByGroup } from "@/services/User";
import AssignmentRow from "./AssignmentRow.vue";
import { ref, computed, watch, reactive } from "vue";
import { useStore } from "vuex";
import NewProjectAssignmentForm from "./NewProjectAssignmentForm.vue";
import EditProjectAssignmentForm from "./EditProjectAssignmentForm.vue";

const showFilterPane = computed(() => store.getters["research/showFilterPane"]);
const researcherOptions = ref([]);
const projectOptions = ref([]);

// const projectOptions = ref([
//   { value: "clear", label: "------------" },
//   { value: 6, label: "-Aeromed Consummables- 07-07-2022" },
//   { value: 4, label: "-Applegate- DACIS 07-11-2022" },
//   { value: 19, label: "AS9100 Europe" },
//   { value: 5, label: "-AS9100- Oasis 06-29-2022" },
//   { value: 17, label: "-Bandy- DACIS 08-03-2022 (Zip Codes & NAICS Code)" },
//   { value: 10, label: "Available" },
//   { value: 14, label: "De-Duplication Phase 1" },
//   { value: 24, label: "-Innovative Precision- Fab Prospects 08-15-2022" },
//   { value: 16, label: "New 9100 Companies & Identify Duplicates" },
//   { value: 3, label: "-PMA- FAA 12-15-2021" },
//   { value: 18, label: "VSE ASA FAA 08-04-2022" },
//   { value: 1, label: "-VSE FAA- 06-15-2022" },
//   { value: 2, label: "-VSE Rotables- 06-01-2022" },
//   { value: 7, label: "-VSE Rotables- 07-13-2022" },
// ]);

const generalAvailabilityOptions = ref([
  { value: "clear", label: "------------" },
  { value: "general_availability", label: "General Availability" },
  { value: "assigned", label: "Assigned to Project" },
]);

const dateOptions = ref([
  { value: "clear", label: "------------" },
  { value: 0, label: "Now" },
  { value: 7, label: "Next Week" },
  { value: 30, label: "Next Month" },
  { value: 120, label: "Next Quarter" },
]);

const hourlyCapacityOptions = ref([
  { value: "clear", label: "------------" },
  { value: "0", label: "0" },
  { value: "> 0", label: "> 0" },
]);

const statusOptions = ref([
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
]);

const store = useStore();
const isLoading = ref(false);
const allProjectAssignmentResults = ref([]);
const projectAssignmentResults = ref([]);
const researcherResults = ref([]);
const researchProjectResults = ref([]);
const awaitingSearch = ref(false);
const gotOptions = ref(false);
const projectAssignmentFilters = reactive({
  researcher: null,
  project: null,
  availability: null,
  date: null,
  hourlyCapacity: null,
  status: "all",
});

async function loadProjectAssignments(endpoint = fetchFilter.value) {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: endpoint,
  };
  const result = await getProjectAssignments(payload);
  // console.log("Before")
  // if (result.projectAssignments.length == 0) {
  //   resetProjectAssignmentFilters();
  // }

  projectAssignmentResults.value = result.projectAssignments;
  // console.log(projectAssignmentResults.value);
  // console.log("After")
  isLoading.value = false;
  // if (!gotOptions.value) {
  //   researcherOptions.value = getResearchers();
  //   projectOptions.value = getResearchProjects();
  //   gotOptions.value = true;
  // }
}

async function loadAllProjectAssignments() {
  let payload = {
    token: store.state.auth.token,
  };
  const result = await getAllProjectAssignments(payload);
  // console.log("Before")
  allProjectAssignmentResults.value = result.projectAssignments;
  // console.log(projectAssignmentResults.value);
  // console.log("After")
  researcherOptions.value = getResearchers();
  projectOptions.value = getResearchProjects();
}

async function loadSelectResearchers() {
  let payload = {
    token: store.state.auth.token,
  };
  const result = await getAllProjectAssignments(payload);
  allProjectAssignmentResults.value = result.projectAssignments;
  researcherOptions.value = getResearchers();
}

async function loadSelectResearchProjects() {
  let payload = {
    token: store.state.auth.token,
  };
  const result = await getAllProjectAssignments(payload);
  allProjectAssignmentResults.value = result.projectAssignments;
  researcherOptions.value = getResearchers();
}

function getResearchers() {
  const researcherSet = new Set([]);
  const researcherList = [{ value: "clear", label: "------------" }];
  // console.log(allProjectAssignmentResults.value);
  allProjectAssignmentResults.value.forEach((currProjectAssignment) => {
    if (!researcherSet.has(currProjectAssignment.researcher.id)) {
      researcherList.push({
        value: currProjectAssignment.researcher.id,
        label: `${currProjectAssignment.researcher.first_name} ${currProjectAssignment.researcher.last_name}`,
      });
      researcherSet.add(currProjectAssignment.researcher.id);
    }
  });
  // console.log(researcherList);
  return researcherList;
}

function getResearchProjects() {
  const researchProjectSet = new Set([]);
  const researchProjectList = [{ value: "clear", label: "------------" }];
  // console.log(allProjectAssignmentResults.value);
  allProjectAssignmentResults.value.forEach((currProjectAssignment) => {
    if (
      !researchProjectSet.has(
        currProjectAssignment.research_project.project_name
      )
    ) {
      researchProjectList.push({
        value: currProjectAssignment.research_project.id,
        label: currProjectAssignment.research_project.project_name,
      });
      researchProjectSet.add(
        currProjectAssignment.research_project.project_name
      );
    }
  });
  // console.log(researcherList);
  return researchProjectList;
}

watch(
  () => projectAssignmentFilters,
  () => {
    // console.log("Hit Watcher.", newValue)
    // console.log("companyFilters", companyFilters)
    // console.log("companyFilters.companyNameFilter", companyFilters.companyNameFilter)
    // console.log("companyFilters.companyNameFilter.value", companyFilters.companyNameFilter.value)
    console.log("FILTER UPDATE");
    if (!awaitingSearch.value) {
      setTimeout(() => {
        loadProjectAssignments();
        awaitingSearch.value = false;
      }, 1000); // 1 sec delay
    }
    awaitingSearch.value = true;
  },
  { deep: true }
);

function setResearcherFilter(target) {
  target == "clear"
    ? (projectAssignmentFilters.researcher = "")
    : (projectAssignmentFilters.researcher = target);
  // console.log(projectAssignmentFilters.researcher);
}

function setProjectFilter(target) {
  target == "clear"
    ? (projectAssignmentFilters.project = "")
    : (projectAssignmentFilters.project = target);
  // console.log(projectAssignmentFilters.project);
}

function setAvailabilityFilter(target) {
  target == "clear"
    ? (projectAssignmentFilters.availability = "")
    : (projectAssignmentFilters.availability = target);
  // console.log(projectAssignmentFilters.availability);
}

function setDateFilter(target) {
  target == "clear"
    ? (projectAssignmentFilters.date = "")
    : (projectAssignmentFilters.date = target);
  console.log(projectAssignmentFilters.date);
}

function setHourlyCapacityFilter(target) {
  target == "clear"
    ? (projectAssignmentFilters.hourlyCapacity = "")
    : (projectAssignmentFilters.hourlyCapacity = target);
  // console.log(projectAssignmentFilters.hourlyCapacity);
}

function setStatusFilter(target) {
  projectAssignmentFilters.status = target;
  // console.log(projectAssignmentFilters.hourlyCapacity);
}

function resetProjectAssignmentFilters() {
  projectAssignmentFilters.researcher = "";
  projectAssignmentFilters.project = "";
  projectAssignmentFilters.availability = "";
  projectAssignmentFilters.date = "";
  projectAssignmentFilters.hourlyCapacity = "";
  projectAssignmentFilters.status = "all";
}

const fetchFilter = computed(function () {
  // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
  let query = new URLSearchParams({
    // name: "John Doe",
    // email: "john@doe.com",
    // colors: JSON.stringify(["Red", "Green", "Blue"])
  });
  if (projectAssignmentFilters.researcher) {
    query.append("researcher", projectAssignmentFilters.researcher); // To append more data
  }
  if (projectAssignmentFilters.project) {
    query.append("research_project", projectAssignmentFilters.project); // To append more data
  }
  if (projectAssignmentFilters.availability) {
    query.append("general_availability", projectAssignmentFilters.availability); // To append more data
  }
  if (projectAssignmentFilters.date || projectAssignmentFilters.date == 0) {
    query.append("date", projectAssignmentFilters.date); // To append more data
  }
  if (projectAssignmentFilters.hourlyCapacity) {
    query.append("hourly_capacity", projectAssignmentFilters.hourlyCapacity); // To append more data
  }
  if (projectAssignmentFilters.status) {
    query.append("status", projectAssignmentFilters.status); // To append more data
  }
  // (B) CONVERT TO STRING, APPEND TO URL
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    // console.log(value)
    queriesCount = queriesCount + 1;
  }
  // console.log("queriesCount", queriesCount)
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  // console.log(url);
  return url;
  // (C) WHATEVER COMES NEXT...
  // REDIRECT OR AJAX CALL OR FETCH
  // window.location.href = url;
});

// -------------- NEW PROJECT ASSIGNMENT FORM --------------
const isNewProjectAssignmentFormVisible = ref(false);
function showProjectAssignmentForm() {
  isNewProjectAssignmentFormVisible.value = true;
  // console.log("SHOW")
}

function closeProjectAssignmentForm() {
  isNewProjectAssignmentFormVisible.value = false;
  // resetProjectAssignmentFilters();
  loadProjectAssignments();
}

async function loadAllResearchers() {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: "list_users",
    group: "advanced_research_group",
  };
  const result = await getUsersByGroup(payload);
  // console.log(result)
  // console.log("Before")
  researcherResults.value = result.users;
  // console.log(researcherResults.value);
  // console.log("After")
  isLoading.value = false;
}

async function loadAllResearchProjects() {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
  };
  const result = await getAllResearchProjects(payload);
  // console.log("Before")
  researchProjectResults.value = result.researchProjects.data;
  // console.log(researchProjectResults.value);
  // console.log("After")
  isLoading.value = false;
}

// -------------- EDIT PROJECT ASSIGNMENT FORM --------------
const isEditPAFormVisible = ref(false);
const projectAssignmentToEdit = ref();
function showEditPAForm(currProjectAssignment) {
  isEditPAFormVisible.value = true;
  // console.log(currProjectAssignment)
  projectAssignmentToEdit.value = currProjectAssignment;
}

function closeEditPAForm() {
  isEditPAFormVisible.value = false;
  // resetProjectAssignmentFilters();
  loadProjectAssignments();
}

// -------------- ARCHIVE PROJECT ASSIGNMENT FORM --------------
const isArchivePAFormVisible = ref(false);
const projectAssignmentToArchive = ref();
function showArchivePAForm(currProjectAssignment) {
  isArchivePAFormVisible.value = true;
  projectAssignmentToArchive.value = currProjectAssignment;
}

function closeArchivePAForm() {
  isArchivePAFormVisible.value = false;
  // resetProjectAssignmentFilters();
  loadProjectAssignments();
}

async function archiveSelectedProjectAssignment() {
  const formData = JSON.stringify({
    status: "inactive",
  });
  const payload = {
    token: store.state.auth.token,
  };
  // console.log(projectAssignmentToArchive.value.id)
  try {
    const result = await archiveProjectAssignment(
      projectAssignmentToArchive.value.id,
      formData,
      payload
    );
    closeArchivePAForm();
  } catch (error) {
    alert("Error, project assignment not edited.");
  }
}

loadProjectAssignments();
loadAllProjectAssignments();
loadAllResearchers();
loadAllResearchProjects();
</script>

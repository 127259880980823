<template>
    <transition
            enter-active-class="transition duration-700 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-500 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0">
        <slot></slot>
    </transition>
</template>

<script>
export default {
  name: "FilterTransition.vue",
}
</script>

<style scoped>

</style>
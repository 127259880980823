<template>
    <div class="flex items-center whitespace-nowrap">
      <span
        class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
        @click="goToFirst"
        >&lt;&lt;</span
      >
      <span
        class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
        @click="getPrev"
        >&lt;</span
      >
      <div class="flex flex-row">
        Page
        <span
          ><JumpToPage class="mx-1" :current-page="page" :max-page="numPages" @fetch-page="jumpToPage" v-model="inputtedPage"
        /></span>
            of {{ numPages }}
      </div>
      <span
        class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
        @click="getNext"
        >></span
      >
      <span
        class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
        @click="goToLast"
        >>></span
      >
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits, ref } from "vue";
  import JumpToPage from "./JumpToPage.vue"
  
  const props = defineProps(["page", "numPages"]);
  const emit = defineEmits(['go-to-first', 'go-to-last', 'get-prev', 'get-next', 'jump-to-page'])
  
  const inputtedPage = ref();
  
  function goToLast() {
      emit('go-to-last')
  }
  function goToFirst() {
      emit('go-to-first')
  }
  function getPrev() {
      emit('get-prev')
  }
  function getNext() {
      emit('get-next')
  }
  
  function jumpToPage(pageToJump) {
    emit('jump-to-page', pageToJump);
  }
  </script>
  
<template>
        <div class="border border-dark-primary rounded-md p-2 w-full flex flex-col">
            <div class="flex justify-between border-b py-1 items-center">
                <div class="flex space-x-2">
                    <div>
                        {{ formatDateTwoDigitYear(post.created_at) }}
                    </div>
                    <div>
                        {{ post.created_by.first_name }} {{ post.created_by.last_name }}:
                    </div>
                </div>
                <div>
                    <base-button v-if="isPostOwner(post.created_by.id)" @click.prevent="removePost">🗑</base-button>
                </div>
            </div>
            <div @dblclick="toggleEditable">
                <div v-if="isEditable">
                    <textarea :value="post.post_body" class="w-full p-1" rows="3" @keydown.enter.prevent="submitUpdate"></textarea>
                </div>
                <div v-else>
                    {{ post.post_body }}
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "invoice-accounting-post"
}
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { updateAccountingPost } from '@/services/AccountingPost';
import { formatDateTwoDigitYear } from '@/composables/formatDate';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const props = defineProps({
    post: Object
})

const emit = defineEmits(['refetch-invoices', 'remove-post'])

const isEditable = ref(false)

function toggleEditable() {
    if(props.post.created_by_id === store.state.auth.user.id){
        isEditable.value = !isEditable.value
    }
}

function removePost(){
    emit('remove-post', props.post.id)
}

function isPostOwner(postOwnerId) {
    return postOwnerId === store.state.auth.user.id
}

async function submitUpdate(e) {
    const data = JSON.stringify({
        post_body: e.target.value
    })
    const token = store.state.auth.token
    const result = await updateAccountingPost(token, props.post.id, data)
    if(result.status === 200) {
        $toast.open({
            message: "Successfully updated Accounting Post",
            type: 'success',
            duration: 5000
        })
        emit('refetch-invoices')
        isEditable.value = false
    } else {
        $toast.open({
            message: "Could not update Accounting Post",
            type: 'error',
            duration: 5000
        })
    }

}

</script>
<template>
  <base-table-row>
    <base-table-cell>
      <div
        class="flex-col text-black"
      >
        <div>
          {{ client.id }}
        </div>
        <div>
          {{ client.status }}
        </div>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ client.client_name }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ fullName }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="flex flex-col whitespace-nowrap space-y-1">
        <div>
          <base-pill>Start: </base-pill> {{ client.engagement_start ? formatDateTwoDigitYear(client.engagement_start) : '--' }}
        </div>
        <div>
          <base-pill>End:</base-pill> {{ client.engagement_end ? formatDateTwoDigitYear(client.engagement_end) : '--' }}
        </div>
      </div>
    </base-table-cell>
    <!-- <base-table-cell>
    </base-table-cell> -->
    <base-table-cell>
      <div>
        {{ client.retainer_frequency }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ invoiceReminderConfig }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ client.contact_notes }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        <ClientPostsCell :client="client" @refetch-clients="fetchNewClients" />
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ client.fee_structure }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div v-if="client.target_email">
        {{ client.target_email }}
      </div>
      <div v-else>
        <base-button @click="createBucketForSelected">
          Create Bucket
        </base-button>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        <base-button v-if="client.dropbox_url">
          <a target="_blank" :href="client.dropbox_url">PDF</a>
        </base-button>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        <base-button @click="openEdit">Edit</base-button>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        <base-button @click="deleteClient">Delete</base-button>
      </div>
    </base-table-cell>
    <base-modal :is-form-visible="editModalVisible" @close="closeEditModal">
      <template v-slot:header> Edit Client </template>
      <template v-slot:body>
        <ClientForm
          @submit-edit-form="submitEditForm"
          @close-edit-form="closeEditModal"
          :client="client"
          @refetch-clients="fetchNewClients"
          addOrEdit="edit"
        ></ClientForm>
      </template>
    </base-modal>
  </base-table-row>
</template>

<script setup>
// imports

import { defineProps, computed, defineEmits, ref } from "vue";
import { useStore } from "vuex";
import { formatDateTwoDigitYear } from "@/composables/formatDate";
import { createBucket } from "../../../services/Client.js";
import ClientPostsCell from "./ClientPostsCell.vue";
import ClientForm from "../ClientForm.vue"

// constants/variables

const store = useStore();

const props = defineProps({
  client: Object,
});
const emit = defineEmits([
  "reset-changed-company-filters",
  "open-edit",
  "delete-client",
  "load-clients",
  "submit-edit-form"
]);

const editModalVisible = ref(false)

function closeEditModal(){
  editModalVisible.value = false
}

function submitEditForm(id, formData){
  emit('submit-edit-form', id, formData)
}

// computed values

const fullName = computed(() => {
  if (props.client.account_manager) {
    return (
      props.client.account_manager.first_name +
      " " +
      props.client.account_manager.last_name
    );
  }
  return "";
});

const invoiceReminderConfig = computed(() => {
  if(props.client.invoice_reminder_configurations.length > 0){
    return props.client.invoice_reminder_configurations.map((reminderConfig) => {
      return reminderConfig.days_from_invoice_due_date
    }).join(", ")
  } else {
    return "--"
  }
})

// async functions

async function createBucketForSelected() {
  console.log("Create S3 Bucket");
  let payload = {
    id: props.client.id,
    token: store.state.auth.token,
  };
  const result = await createBucket(payload);
  if (result.status == 200) {
    alert("Successfully created S3 Bucket");
  } else {
    alert("Failed to create S3 Bucket");
  }
  emit("load-clients");
}

// functions

function openEdit() {
  editModalVisible.value = true
}

function deleteClient() {
  emit("delete-client", props.client.id, props.client);
}

function fetchNewClients(){
  emit('load-clients')
}

</script>

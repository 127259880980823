<template>
    <div>
        <div class=" h-max-32">
            <div class="overscroll-y-auto space-y-1">
                <div class="flex items-center justify-between border-b border-b-black py-1 space-x-2" v-for="task in openTasks" :key="task.id">
                    <div>{{ task.action_name }}</div>
                    <div>{{ new Date(task.due_date).toLocaleDateString()  }}</div>
                    <div class="flex space-x-2">
                        <button @click="updateStatus('Done', task.id)" class="border border-black rounded-md text-xs px-1 hover:text-white hover:bg-dark-primary">✓</button>
                        <button @click="updateStatus('Cancelled', task.id)" class="border border-black rounded-md text-xs px-1 hover:text-white hover:bg-dark-primary">X</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'all-tasks-cell'
    }
</script>

<script setup>
import { defineProps, ref, defineEmits, watch } from 'vue';
import { useStore } from 'vuex';
import { markStatus } from '@/services/CrmAction';

const store = useStore()

const props = defineProps({
    company: Object
})

const openTasks = ref([])

function setOpenTasks () {
    const user = store.state.auth.user
    let tasks = props.company.crm_actions.filter(task => task.status == 'Open').sort(function(a,b) {
            return new Date(a.due_date) - new Date(b.due_date)
    })
    if(store.state.company.showMyOpenTasks.show){
        if(store.state.company.showMyOpenTasks.user.length > 0){
            // console.log('show task user', store.state.company.showMyOpenTasks.user)
            tasks = tasks.filter(task => task.user && store.state.company.showMyOpenTasks.user.includes(task.user.id))
            // console.log('tasks with show task user', tasks)
        } else {
            tasks = tasks.filter(task => task.user && task.user.id == user.id)
        }
    }
    openTasks.value = tasks
}

watch(() => store.state.company.showMyOpenTasks, (newValue, oldValue) => {
    const user = store.state.auth.user
    let tasks = props.company.crm_actions.filter(task => task.status == 'Open').sort(function(a,b) {
            return new Date(a.due_date) - new Date(b.due_date)
    })
    if(newValue.show){
        if(newValue.user.length > 0){
            // console.log('changed show task opentasks before', openTasks.value)
            // console.log('changed show task user', newValue.user)
            openTasks.value = tasks.filter(task => task.user && newValue.user.includes(task.user.id))
        } else {
            openTasks.value = tasks.filter(task => task.user && task.user.id == user.id)
        }
    } else {
        setOpenTasks()
    }
}, {deep: true})

watch(props, () => {
    const user = store.state.auth.user
    setOpenTasks()
})



const emit = defineEmits(['refetch-companies'])

async function updateStatus(status, taskId) {
        const token = store.state.auth.token
        const newStatus = JSON.stringify({
            status: status
        })
        await markStatus(taskId, newStatus, token)
        emit('refetch-companies')
    }

setOpenTasks()



</script>
<template>
    <div>
        <span>{{revenueHeadcount}}</span>
    </div>
</template>

<script>
    export default {
        name: "revenue-headcount-cell"
    }
</script>

<script setup>
import {defineProps, computed} from "vue"

    const props = defineProps({
        company: Object
    })

    const revenueHeadcount = computed(() => {
        if(props.company.revenue && props.company.employee_headcount){
            return `${props.company.revenue} M / ${props.company.employee_headcount} ppl`
        }else if(props.company.revenue){
            return `${props.company.revenue} M`
        } else if (props.company.employee_headcount){
            return `${props.company.employee_headcount} ppl`
        } else {
            return '--'
        }
    })



</script>
<template>
    <div class="px-1 py-2">
        <div v-if="chatGPTResponses.length > 0">
            <div v-for="response in chatGPTResponses" :key="response.id">
                <div class="flex flex-col space-y-3 w-full">
                    <div class="flex space-x-1">
                        <div class="w-1/12 flex justify-end h-fit">
                            <base-pill class="min-w-full text-center">Description: </base-pill>
                        </div>
                        <div class="whitespace-pre-wrap break-before-auto w-11/12">
                            {{ response.description }}
                        </div>
                    </div>
                    <div class="flex space-x-1">
                        <div class="w-1/12 flex justify-end h-fit">
                            <base-pill class="min-w-full text-center">Capabilities: </base-pill>
                        </div>
                        <div class="w-11/12">
                            {{ response.capabilities.join(", ") }}
                        </div>
                    </div>
                    <div class="flex space-x-1">
                        <div class="w-1/12 flex justify-end h-fit">
                            <base-pill class="min-w-full text-center">Products: </base-pill>
                        </div>
                        <div class="w-11/12">
                            {{ response.products.join(", ") }}
                        </div>
                    </div>
                    <div class="flex space-x-1">
                        <div class="w-1/12 flex justify-end h-fit">
                            <base-pill class="min-w-full text-center">Customers: </base-pill>
                        </div>
                        <div class="w-11/12">
                            {{ response.customers.join(", ") }}
                        </div>
                    </div>
                    <div class="flex space-x-1">
                        <div class="w-1/12 flex justify-end h-fit">
                            <base-pill class="min-w-full text-center">Equipment: </base-pill>
                        </div>
                        <div class="w-11/12">
                            {{ response.equipment.join(", ") }}
                        </div>
                    </div>
                    <div class="flex space-x-1">
                        <div class="w-1/12 flex justify-end h-fit">
                            <base-pill class="min-w-full text-center">Certifications: </base-pill>
                        </div>
                        <div class="w-11/12">
                            {{ response.certifications.join(", ") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            No Chat GPT Responses
        </div>
    </div>
</template>

<script>
export default {
    name: 'company-chat-gpt-responses'
}
</script>

<script setup>
import { defineProps, ref, computed } from 'vue';

const props = defineProps({
    company: Object
})

const chatGPTResponses = computed(() =>{
    return props.company.chat_gpt_summaries
})

</script>
import contactMutations from './mutations.js';
import contactActions from './actions.js';
import contactGetters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            contacts: [],
            next: null,
            prev: null,
            count: null,
            isLoading: false,
            page: null,
            pageSize: null,
            showCreateContactForm: false,
            activeFilters: [],
            showFilterPane: true,
            showBasicContactFilters: true,
            showBasicCompanyFilters: true,
            showBulkOperationsPane: false,
            // visibleFilters: { 
            //     contactNameFilter: true,
            //     companyDescriptionFilter: true,
            //     companyWebsiteUrlFilter: true,
            //     companyAddressFilter: true,
            //     companySourcesFilter: true,
            //     companySourceOwnerFilter: true,
            //     companyCampaignFilter: true,
            //     companyTierFilter: true,
            //     companyClientFeedbackFilter: true,
            //     companyOnboardingStatusFilter: true,
            //     companyDNRReasonFilter: true,
            //     companyResearchStatusFilter: true,
            //     companyResearchStatusFilterTwo: true,
            //     companyRevenueFilter: true,
            //     companyHeadcountFilter: true,
            //     companyRevenueZiCombinedFilter: true,
            //     contactIdFilter: true,
            // }
        };
    },
    mutations: contactMutations,
    actions: contactActions,
    getters: contactGetters
};
<template>
  <div class="flex flex-wrap justify-start items-center w-full">
    <base-dblclick-input
      modelType="contact"
      :model-id="companyContact.id"
      field-name="personal_email"
      :field-value="companyContact.personal_email"
      :canCopy="true"
      inputType="email"
      @refetch="fetchNewCompanies"
    >
      <slot name="icon"></slot>
    </base-dblclick-input>
    <!-- <div v-if="companyContact.personal_email">
      <div class="text-xs h-2.5 w-2.5 ml-1.5 rounded-full bg-emerald-500" v-if="companyContact.is_verified">
      </div>
      <div class="text-xs h-2.5 w-2.5 ml-1.5 rounded-full bg-rose-500" v-else>
      </div>
    </div> -->
  </div>
</template>

<script>
  export default {
      name: "company-contact-personal-email-cell"
  }

</script>

<script setup>
import {computed, defineProps, defineEmits } from "vue";
import {formatDate} from '@/composables/formatDate';

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

  const formattedVerifiedDate = computed(() => {
      try {
        return formatDate(`${props.companyContact.verified_date}`)
      } catch {
        return '--'
      }
    })
</script>
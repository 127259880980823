<template>
<div class="bg-gray-200 overflow-x-hidden w-full ">
    <div class="w-full flex flex-row justify-between items-center border-b border-b-dark-primary">
        <h2 class="text-md p-1 my-3 mx-5"> Edit Email Template </h2>
        <base-button 
        class="m-5 w-fit"
        @click="toggleIsTemplateFieldsOpen"> 
            {{viewTemplateFieldsAction}} Email Template Fields
        </base-button>
    </div>
    <div class="flex flex-row w-full pr-5">
        <form @submit.prevent="submitForm" class="w-full" ref="templateForm">
            <div class='flex flex-row h-[600px]'>
                <div class="w-full">
                    <div class="grid grid-cols-3 gap-x-8 ml-5 my-5 mr-1">
                        <div class="flex flex-col">
                            <label class="text-sm"> Email Template Name </label>
                            <input
                                type="text"
                                class="pl-2 border w-full h-[25px] text-sm"
                                v-model="newTemplateInfo.template_name"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="text-sm"> Status </label>
                            <select 
                                class="border w-full h-[25px] text-sm"
                                v-model="newTemplateInfo.status">
                                    <option> active </option>
                                    <option> inactive </option>
                            </select>
                        </div>
                        <div class="flex flex-col">
                            <label class="text-sm"> Client </label>
                            <select
                            class="border w-full h-[25px] text-sm"
                            v-model="newTemplateInfo.client">
                                <option
                                    v-for="client in clientList"
                                    :key="client.id"
                                    :value="client.id"
                                >
                                    {{ client.client_name }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-col">
                            <label class="text-sm"> To </label>
                            <select
                            class="border pl-2 w-full h-[25px] text-sm"
                            v-model="newTemplateInfo.to">
                                <option
                                    v-for="email in emailToOptions"
                                    :key="email.value"
                                    :value="email.value"
                                >
                                    {{ email.label }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-col">
                            <label class="text-sm"> From </label>
                            <select
                            class="border w-full h-[25px] text-sm"
                            v-model="newTemplateInfo.from">
                                <option
                                    v-for="email in emailFromOptions"
                                    :key="email.value"
                                    :value="email.value"
                                >
                                    {{ email.label }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-col">
                            <label class="text-sm"> Email BCC - Injectable Field</label>
                            <input
                                type="text"
                                class="pl-2 border w-full h-[25px] text-sm"
                                v-model="newTemplateInfo.email_bcc"
                            />
                        </div>
                        <div class="flex flex-col col-span-2">
                            <label class="text-sm"> Subject </label>
                            <input
                                type="text"
                                class="pl-2 border w-full h-[25px] text-sm"
                                v-model="newTemplateInfo.subject"
                            />
                        </div>
                    </div>
                    <div class="ml-5 my-5 mr-1 w-100vh">
                        <label class="text-sm"> Email Body </label>
                        <textarea
                            class="text-sm p-2 border h-full w-full"
                            v-model="newTemplateInfo.email_body"
                            rows="13"
                        ></textarea>
                    </div>  
                    <div class="mt-10 space-y-4 flex flex-col w-full pb-5 border-b mx-5">
                        <div class="flex flex-row ">
                            <label class="text-sm mr-5 w-[120px]"> Signature Image: </label>
                                <div class="flex flex-row justify-between">
                                    <input
                                    @change="processSignatureImage"
                                    name="signatureFile"
                                    id="signatureFile"
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    class="text-sm"
                                    />
                                    
                                </div>
                        </div>
                        <div class="text-sm font-bold m-36"
                        v-if="signature_image_exists"> 
                            Previous Uploaded Signature Image: {{ signature_image_existing_string }}
                        </div>
                        <div class="flex flex-row ">
                            <label class="text-sm mr-5 w-[120px]"> Logo Image 1: </label>
                                <div class="flex flex-row justify-between">
                                    <input
                                    @change="processLogoImage1"
                                    name="logoImage1"
                                    id="logoImage1"
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    class="text-sm"
                                    />
                                    
                                </div>
                        </div>
                        <div 
                        class="text-sm font-bold m-36"
                        v-if="logo_image_1_exists"> 
                            Previous Uploaded Logo Image 1: {{ logo_image_1_existing_string }}
                        </div>
                        <div class="flex flex-row ">
                            <label class="text-sm mr-5 w-[120px]"> Logo Image 2: </label>
                                <div class="flex flex-row justify-between">
                                    <input
                                    @change="processLogoImage2"
                                    name="logoImage2"
                                    id="logoImage2"
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .svg"
                                    class="text-sm"
                                    />  
                                </div>
                        </div>
                        <div 
                        class="text-sm font-bold m-36"
                        v-if="logo_image_2_exists"> 
                            Previous Uploaded Logo Image 2: {{ logo_image_2_existing_string }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class='text-red-500 text-sm font-bold mx-5' v-for="error in errors" :key="error">
                    {{ error }}
                </p>
            </div>
            <div class="text-center space-x-3 mt-3 mb-5">
                <base-button-affirmative type="submit"> Submit </base-button-affirmative>
                <base-button-negative @click="goBackToMainPage"> Cancel </base-button-negative>
            </div>
        </form>
        <email-template-fields-table 
            class="w-full"
            v-if="isTemplateFieldsOpen">
        </email-template-fields-table>
    </div>
</div>

</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import { getAllClientsSimple } from '@/services/Client'
import { getEmailsSendToAddresses, getEmailTemplate, editEmailTemplate } from '@/services/EmailTemplates'
import EmailTemplateFieldsTable from './EmailTemplateFieldsTable.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();

const routeTemplate = ref(route.params.templateId)

const signature_image_exists = ref(false);
const signature_image_existing_string = ref("");
const signature_image_modified = ref(false);

const logo_image_1_exists = ref(false);
const logo_image_1_existing_string = ref("");
const logo_image_1_modified = ref(false);

const logo_image_2_exists = ref(false);
const logo_image_2_existing_string = ref("");
const logo_image_2_modified = ref(false);

const templateForm = ref(null);

const templateFormWidth = computed(()=> {
    return templateForm.value.offsetWidth
})


const newTemplateInfo = reactive({
    template_name: null,
    status: null,
    client: null,
    to: null,
    from: null,
    email_bcc: null,
    subject: null,
    email_body: null, 
    signature_image: null,
    logo_img1: null,
    logo_img2: null
})

const clientList = ref([]);
const emailToOptions = ref([]);
const emailFromOptions = ref([
    {value: null, label:'------------'},
    {value:'dan.klawitter@sheddcapital.com', label: 'dan.klawitter@sheddcapital.com'},
    {value:'dklawitter@rtcaerospace.com', label: 'dklawitter@rtcaerospace.com'},
    {value:'matthew.strickler@sheddcapital.com', label: 'matthew.strickler@sheddcapital.com'},
    {value:'mstrickler@novariagroup.com', label: 'mstrickler@novariagroup.com'},
    {value:'norman@sheddcapital.com', label: 'norman@sheddcapital.com'},
    {value:'scott.shedd@pcxaero.com', label: 'scott.shedd@pcxaero.com'},
    {value:'sshedd@novariagroup.com', label: 'sshedd@novariagroup.com'},
    {value:'sshedd@sheddcapital.com', label: 'sshedd@sheddcapital.com'},
    {value:'noah.grimsley@sheddcapital.com', label: 'noah.grimsley@sheddcapital.com'},
    {value:'brandon.ahearn@sheddcapital.com', label: 'brandon.ahearn@sheddcapital.com'},
    {value:'suzanne.strickler@sheddcapital.com', label: 'suzanne.strickler@sheddcapital.com'},
    {value:'scott@precise-metals.com', label: 'scott@precise-metals.com'},
    {value:'tony.patsatzis@sheddcapital.com', label: 'tony.patsatzis@sheddcapital.com'},
]);

const isTemplateFieldsOpen = ref(false)
const viewTemplateFieldsAction = ref('View');

function toggleIsTemplateFieldsOpen() {
    isTemplateFieldsOpen.value = !isTemplateFieldsOpen.value;
    if(isTemplateFieldsOpen.value) {
        viewTemplateFieldsAction.value = 'Hide';
    } else {
        viewTemplateFieldsAction.value = 'View';
    }
}

function goBackToMainPage() {
    router.push({ path: '/email_templates', replace: true })
}
async function loadClientOptions() {
    const output = await getAllClientsSimple({token: store.state.auth.token});
    clientList.value = output.data
}

async function loadTemplate() {
    if (routeTemplate.value) {
        await getTemplate(routeTemplate.value);
    }
}

async function getTemplate(id) {
    const token = store.state.auth.token;
    const response = await getEmailTemplate(id, token);
    console.log(response)

    for (let i = 0; i < emailToOptions.value.length; i++) {
        if (response.email_to.email_address == emailToOptions.value[i].label) {
            newTemplateInfo.to = emailToOptions.value[i].value
        } 
    }
    newTemplateInfo.template_name = response.email_template_name
    newTemplateInfo.status = response.status.toLowerCase()
    if (response.client) {
        newTemplateInfo.client = response.client.id
    }
    newTemplateInfo.from = response.email_from
    newTemplateInfo.email_bcc = response.email_bcc
    newTemplateInfo.subject = response.subject
    newTemplateInfo.email_body = response.email_body
    newTemplateInfo.signature_image = response.signature_image
    newTemplateInfo.logo_img1 = response.logo_image
    newTemplateInfo.logo_img2 = response.logo_image_2

    if (newTemplateInfo.signature_image) {
        signature_image_exists.value = true;
        signature_image_existing_string.value = parseImageString(newTemplateInfo.signature_image)
    }
    if (newTemplateInfo.logo_img1) {
        logo_image_1_exists.value = true;
        logo_image_1_existing_string.value = parseImageString(newTemplateInfo.logo_img1)
    }
    if (newTemplateInfo.logo_img2) {
        logo_image_2_exists.value = true;
        logo_image_2_existing_string.value = parseImageString(newTemplateInfo.logo_img2)
    }
}

function parseImageString(str) {
    let newString = str.replace('https://aerocle-static.s3.us-east-2.amazonaws.com/template_media/', '');
    let index = -1;
    if (newString.includes('.jpg')) {
        index = newString.indexOf('.jpg') + 4;
    } else if (newString.includes('.png')) {
        index = newString.indexOf('.png') + 4;
    } else if (newString.includes('.jpeg')) {
        index = newString.indexOf('.jpeg') + 5;
    } else if (newString.includes('.svg')) {
        index = newString.indexOf('.svg') + 4;
    }
    return newString.substring(0, index);
}

async function loadSendToAddresses() {
    const output = await getEmailsSendToAddresses({token: store.state.auth.token})
    emailToOptions.value.push({value: null, label: '------------'})
    for (let i = 0; i < output.data.length; i++) {
        emailToOptions.value.push({value: i, label: output.data[i]})
    }
}

function processSignatureImage(event) {
    if (event.target.files && event.target.files[0]) {
        newTemplateInfo.signature_image = event.target.files[0]
        signature_image_modified.value = true;
    }
}

function processLogoImage1(event) {
    if (event.target.files && event.target.files[0]) {
        newTemplateInfo.logo_img1 = event.target.files[0]
        logo_image_1_modified.value = true;
    }
}

function processLogoImage2(event) {
    if (event.target.files && event.target.files[0]) {
        newTemplateInfo.logo_img2 = event.target.files[0]
        logo_image_2_modified.value = true;
    }
}

const errors = ref([]);

async function submitForm() {
    errors.value = [];
    if (!newTemplateInfo.template_name || newTemplateInfo.template_name == "") {
        errors.value.push("Please specify an email template name.");
    }
    if (!newTemplateInfo.to || newTemplateInfo.to == "") {
        errors.value.push("Please specify an email address to which this email is sent ('send to'). ")
    }
    if (!newTemplateInfo.from || newTemplateInfo.from == "") {
        errors.value.push("Please specify an email address from which this email is sent ('send from'). ")
    }
    if (!newTemplateInfo.subject || newTemplateInfo.subject == "") {
        errors.value.push("Please specify a subject for this email. ")
    }

    if (errors.value.length > 0) {
        return;
    }

    let to_email = null;
    if (newTemplateInfo.to) {
        to_email = emailToOptions.value.at(newTemplateInfo.to + 1).label
    }

    let form_data = new FormData();

    if (newTemplateInfo.signature_image && signature_image_modified.value) {
        console.log(signature_image_modified)
        form_data.append("signature_image", newTemplateInfo.signature_image, newTemplateInfo.signature_image.name);
    } 
    if (newTemplateInfo.logo_img1 && logo_image_1_modified.value) {
        form_data.append("logo_image", newTemplateInfo.logo_img1, newTemplateInfo.logo_img1.name);
    } 
    if (newTemplateInfo.logo_img2 && logo_image_2_modified.value) {
        form_data.append("logo_image_2", newTemplateInfo.logo_img2, newTemplateInfo.logo_img2.name);
    }
    if (newTemplateInfo.client) {
        form_data.append("client", newTemplateInfo.client);
    }
    if (newTemplateInfo.email_body) {
        form_data.append("email_body", newTemplateInfo.email_body)
    }
    if(newTemplateInfo.email_bcc) {
        form_data.append("email_bcc", newTemplateInfo.email_bcc)
    }

    form_data.append("email_template_name", newTemplateInfo.template_name);
    form_data.append("status", newTemplateInfo.status);
    form_data.append("subject", newTemplateInfo.subject);
    form_data.append("email_to", to_email);
    form_data.append("email_from", newTemplateInfo.from);

    console.log(form_data);
    sendEdits(form_data)
}

async function sendEdits(formData) {
    const payload = {
      'token': store.state.auth.token
    }
    const templateId = routeTemplate.value

    const result = await editEmailTemplate(templateId, formData, payload);
    if (result.status == 201) {
        goBackToMainPage();
    } else {
        alert("Error, template not edited.")
    }
}

loadClientOptions();
loadSendToAddresses();
loadTemplate();
</script>

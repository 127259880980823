<template>
  <div>
    <base-table class="w-full">
      <template #table-header>
        <base-table-header
          :key="header"
          :header="header"
          v-for="header in tableHeaders"
          class="border-b border-b-gray-400 overflow-y-auto sticky top-[55px]"
        >
          {{ header }}
        </base-table-header>
      </template>
      <template #table-body>
        <template v-for="email in content" :key="email.id">
          <EmailsReceivedRow
            :email="email"
            @modify-email="modifyEmail"
            @delete-email="deleteEmail"
          ></EmailsReceivedRow>
        </template>
      </template>
    </base-table>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits} from 'vue';
  import EmailsReceivedRow from './EmailsReceivedRow.vue'
  const props = defineProps({
    content: Array,
    tableHeaders: Array,
    expand: Array,
    isExpandAll: Boolean,
  })
  const emit = defineEmits(['modify-email', 'delete-email']);

  function modifyEmail(emailToModify){
    emit('modify-email', emailToModify);
  }

  function deleteEmail(emailToDelete){
    emit('delete-email', emailToDelete);
  }
</script>
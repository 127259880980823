export default {
    showFilterPane(state){
        return state.showFilterPane;
    },
    showActionsPane(state){
        return state.showActionsPane;
    },
    showTimecardUsers(state){
        return state.showTimecardUsers;
    },
}
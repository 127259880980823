<template>
    <div>
        <base-table>
            <template #table-header>
                <base-table-header v-for="(header, index) in tableHeaders" class="pt-2 pb-1" :key="index" :header="header">
                    {{ header }}
                </base-table-header>
            </template>
            <template #table-body>
                <CampaignsRow @edit-campaign="editCampaign" @refetch-campaigns="refetchCampaigns" class="text-2xl"
                    v-for="(campaign, index) in campaigns" :key="index" :campaign="campaign" />
            </template>
        </base-table>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import CampaignsRow from './CampaignsRow.vue'

const props = defineProps({
    tableHeaders: Array,
    campaigns: Object
})

const emit = defineEmits(['edit-campaign', 'refetch-campaigns'])

function editCampaign(campaign) {
    emit('edit-campaign', campaign)
}

function refetchCampaigns() {
    emit('refetch-campaigns')
}


</script>
<template>
    <!-- <div :style="pageStyling"> -->
    <div>
      <transition
        enter-active-class="transition duration-400 ease-in"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-400 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="h-full"
      >
        <div v-if="pageConfigLoading" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.7] flex flex-col justify-center items-center z-40"
                  tabindex="-1" >
            <div class="flex flex-col justify-center items-center p-4 rounded-md">
              <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#d3d3d3"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
              </svg>
              <!-- <div class="text-white p-4 whitespace-nowrap">
                Please Wait While We Retrieve Page Configurations.
              </div> -->
            </div>
        </div>
      </transition>
      <base-card v-show="showFilterPane">
        <!-- Page Config Loading: {{ pageConfigLoading }} -->
        <CompanyFilters @change-company-filters="setCompanyFilters" />
      </base-card>
      <base-card v-show="showBulkOperationsPane">
        <CompanyBulkOperations 
          :selectedCompanies="selectedCompanies"
          :userPermissions="userPermissions"
          :filters="fetchFilter"
          @refetch-companies="loadCompCompanies(fetchFilter, true)"
          @clear-selected-companies="clearSelectedCompanies"
          @reset-select-all="resetSelectAll">
        </CompanyBulkOperations>
      </base-card>
      <!-- <base-card v-if="activeFilters">
        <ActiveFilters :filters="activeFilters"></ActiveFilters>
      </base-card> -->
    <!-- </base-card> -->
    <base-card>
      <div class="flex flex-col text-sm text-gray-900" :class="[{'h-[60vh]': showBulkOperationsPane && showFilterPane}, {'h-[65vh]': !showBulkOperationsPane && showFilterPane}, {'h-[85vh]': !showFilterPane}, {'h-[90vh]': !showFilterPane && !showBulkOperationsPane}]">
        <div class="flex flex-col justify-start lg:flex-row w-full mb-2">
            <div class="px-3 h-full flex w-full lg:w-fit flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center 0">
              <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllOnPage" @change="toggleSelectAllOnPage"/>
              <p class="align-middle whitespace-nowrap">
                <span v-if="!selectAllOnPage" class="pl-1 align-middle">Select All On Page | </span>
                <span v-else class="pl-1 align-middle">Deselect All On Page | </span>
                <span
                  class="pl-1 align-middle text-accent-primary hover:text-dark-primary hover:cursor-pointer"
                  @click="showModal"
                >{{numSelectedCompanies}} Selected
                </span>
                <span class="align-middle"> | </span>
                <span class="hover:text-accent-primary hover:cursor-pointer align-middle" @click="resetSelectAllClearSelectedCompanies">Clear All</span>
                <span class="align-middle"> (</span>
                <span class="align-middle">{{ numSelectedCompaniesOnPage }} On Pg</span>
                <span class="align-middle"> | </span> 
                <span class="align-middle">{{ numSelectedCompaniesOffPage }} Off Pg</span>
                <span class="align-middle">) </span>
                <base-modal-small 
                  v-if="isModalVisible"
                  @close="closeModal"
                >
                <template v-slot:header>
                  Selected Companies
                </template>
                <template v-slot:body>
                  <SelectedCompaniesTable :selectedCompanies="selectedCompanies" />
                </template>
                </base-modal-small>
              </p>
            </div>
            <div class="flex flex-row items-center justify-between py-2 border-b border-light-primary lg:py-0 lg:border-none w-full h-full px-2 ">
                <base-pagination-header
                  class="w-full"
                  @load-data="loadCompCompanies(fetchFilter, true)"
                  @get-next="getNext"
                  @go-to-first="goToFirst"
                  @get-prev="getPrev"
                  @go-to-last="goToLast"
                  @jump-to-page="jumpToPage"
                  @set-page-size-filter="setCompanyPageSizeFilter"
                  :page_filter="pageSizeFilter"
                  :page="page"
                  :numPages="totalPages"
                  :isLoading="isLoading"
                >
                  <template v-slot:message>
                    <div class="pl-3 pt-1 whitespace-nowrap">
                      {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Companies
                    </div>
                  </template>
                </base-pagination-header>
                <div class="flex space-x-3 items-center ">
                  <div  v-if="userPermissions.isAccountManager || userPermissions.isDev || userPermissions.isManagement" ref="csvElement">
                    <div v-if="csvLoading">
                      <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#d3d3d3"/>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#2E9CCA"/>
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                    <base-button v-else @click="getCSVWithCurrentFields" @click.right.prevent="getCSVWithCurrentFields" class="underline ml-2">⬇</base-button>
                  </div>
                  <!-- <base-button @click="openCreateMultipleCompaniesForm" class=" whitespace-nowrap">Add Companies</base-button> -->
                </div>
                <base-popover-hover 
                  class="max-w-fit"
                  v-if="isPopoverVisible"
                  :xPosition="xPosition"
                  :yPosition="yPosition"
                >
                  <template v-slot:title>
                  </template>
                  <template v-slot:body>
                      <span class="">{{ exportCSVText }}</span>
                  </template>
                </base-popover-hover>
              <!-- </div> -->
            </div>
        </div>
        <div class="mx-3">
          <CRMStatusSummary v-if="showStatusSummaryPane" :query="fetchFilter" :company-count="count" :companies="companyResults" />
        </div>
        <div class="mx-3">
          <CRMSummaryStages v-if="showCRMSummaryStages" :query="fetchFilter" :company-count="count" :companies="companyResults" />
        </div>
        <div v-if="companyResults.length > 0" class="h-full overflow-y-auto">
          <base-table>
            <!--      <base-table @refetch-companies="fetchNewCompanies">-->
            <template #table-header>
              <base-table-header :key="header.id" :header="header" v-for="header in tableHeadersList"> {{
                  header
                }}
              </base-table-header>
            </template>
  
            <template #table-body>
              <ConfigurableCompanyRow @add-remove-selected-companies="addRemoveSelectedCompanies"
                @add-selected-company="addSelectedCompany"
                @remove-selected-company="removeSelectedCompany"
                @refetch-companies="loadCompCompanies(fetchFilter, true)"
                @reset-changed-company-filters="resetSelectedCompaniesOnChange"
                @reset-cleared-selected-companies="resetClearedSelectedCompanies"
                @reset-cleared-selected-companies-on-page="resetClearedSelectedCompaniesOnPage"
                :selectAll="selectAll"
                :selectAllOnPage="selectAllOnPage"
                :selectedCompaniesOnPageMap="selectedCompaniesOnPageMap"
                :key="company.id"
                :company="company"
                :campaigns="campaigns"
                :clearedSelectedCompanies="clearedSelectedCompanies"
                :clearedSelectedCompaniesOnPage="clearedSelectedCompaniesOnPage"
                :changedCompanyFilters="changedCompanyFilters"
                :permissions="userPermissions"
                :page-config="selectedPageConfig"
                :crm-statuses="crmStatuses"
                :crm-action-types="crmActionTypes"
                v-for="company in companyResults"
                          />
            </template>
          </base-table>
        </div>
        <!-- <base-modal :is-form-visible="showCreateCompanyForm" @close="closeCreateCompanyForm">
            <template #header>
              <h2 class="text-lg">Create Company</h2>
            </template>
            <template #body>
              <CreateCompany
                :campaigns="campaigns"
                @close="closeCreateCompanyForm"
                @refetch-companies="loadCompCompanies(fetchFilter, true)"
              />
            </template>
          </base-modal>
        <base-modal :is-form-visible="showCreateMultipleCompaniesForm" @close="closeAddMultipleCompaniesForm">
            <template #header>
              <h2 class="text-lg">Create Multiple Companies</h2>
            </template>
            <template #body>
              <AddMultipleCompanies
                @close="closeAddMultipleCompaniesForm"
                @refetch-companies="loadCompCompanies(fetchFilter, true)"
              />
            </template>
          </base-modal> -->
      </div>
    </base-card>
  <!-- </transition-group> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'configurable-companies',
    data: () => ({
    }),
    methods: {
    }
  }
  </script>
  
  <script setup>
  import {ref, reactive, shallowReactive, computed, watch} from 'vue';
  import {useStore} from 'vuex';
  import ActiveFilters from './ActiveFilters.vue';
  import CompanyFilters from './CompanyFilters.vue';
  import ConfigurableCompanyRow from './ConfigurableCompanyRow.vue';
  import BaseCard from "@/components/BaseCard";
  import CompanyBulkOperations from "@/components/CompanyBulkOperations";
  import SelectedCompaniesTable from './SelectedCompaniesTable.vue';
  import {getCompanies} from '../services/Company.js';
  import { getCampaigns } from '@/services/Campaign';
  import { getCrmStatuses } from '@/services/CrmStatus';
  import { exportToCSV } from '../services/Company.js';
  import { getActions } from '@/services/CrmAction';
  import CRMStatusSummary from './CRMStatusSummary.vue';
  import CRMSummaryStages from './CRMSummaryStages.vue';
  import { useToast } from 'vue-toast-notification'
  
  const store = useStore()
  const $toast = useToast()
  
  const isLoading = ref(false);
//   const tableHeadersList = [
//     ' ',
//     'ID',
//     'Name',
//     'Campaign',
//     'ZI',
//     'Location',
//     'URL',
//     'URL Vetted',
//     'Source / Owner',
//     'Rev / HC / Sq.Ft.',
//     'Research Complete',
//     // 'Edit'
//   ];

  const tableHeadersList = computed(() => {
    const headers = ['', 'ID', ...selectedPageConfig.value.cells.map(cell => cell.company_cell.column_header)]
    // console.log('headers', headers)
    return headers
  })
  const companyResults = ref([]);
  const csvLoading = ref(false)
  const campaigns = ref([])
  const crmStatuses = ref([])
  const next = ref(null);
  const prev = ref(null);
  const shouldLoadCompanies = ref(true)
  const count = ref(null);
  const page = ref(1);
  const pageSize = ref(null);
  const companyFilters = reactive({
    companyNameFilter: null,
    companyDescriptionFilter: null,
    companyWebsiteUrlFilter: null,
    companyAddressFilter: null,
    companySourcesFilter: null,
    companySourceOwnerFilter: null,
    companyCampaignFilter: null,
    companyCampaignExcludeFilter: null,
    companyTierFilter: null,
    companyClientFeedbackFilter: null,
    companyOnboardingStatusFilter: null,
    companyDNRReasonFilter: null,
    companyResearchStatusFilter: null,
    companyResearchStatusExpressionFilter: null,
    companyResearchStatusFilterTwo: null,
    companyResearchStatusExpressionFilterTwo: null,
    companyMinRevenueFilter: null,
    companyMaxRevenueFilter: null,
    companyMinHeadcountFilter: null,
    companyMaxHeadcountFilter: null,
    companyMinRevenueZiCombinedFilter: null,
    companyMaxRevenueZiCombinedFilter: null,
    companyClientFilter: null,
    // pageSizeFilter: store.state.pageConfigs.selectedPageConfig ? store.state.pageConfigs.selectedPageConfig.page_size : 50 ,
    // pageSizeFilter: 50,
    companyBusinessTypeFilter: null,
    companySectorFilter: null,
    companySubSectorFilter: null,
    companyHasCampaignFilter: null,
    companyCRMStatusFilter: null,
    companyCRMNextActionFilter: null,
    // companyDueTimeRangeFilter: null,
    compayDueDateStartFilter: null,
    companyDueDateEndFilter: null,
    companyCollectionTagsFilter: null,
    companyCollectionTagSourcesFilter: null,
    companyStateFilter: null,
    companyNAICSFilter: null,
    companyStateExpressionFilter: 'or',
    companyDrivingTimeFocusFilter: null,
    companyDrivingTimeFilter: null,
    companyDrivingTimeExpressionFilter: 'mins',
    companyShowResearchHaltedFilter: 'False',
    companyParentCompanyStatusFilter: null,
    companyInvestorsStatusFilter: null,
    companyProblemStatusFilter: null,
    companyAccountManagerResearchNotesFilter: null,
    companyTaskUserFilter:null,
    companyIdFilter: null,
    companyCategoryFilter: null,
    companyRelevantBusinessFilter: null,
    companyReferralSourceFilter: null,
    companyReferralStatusFilter: null,
    companyProductServiceCodeFilter: null,
    companyContractPSCKeywordFilter: null,
    companyContractNAICSKeywordFilter: null,
    companyContractAwardingSubAgencyFilter: null,
    companyEveraxisPillarFilter: null,
    companyAccountManagerFilter: null,
    companyCRMActionsFilter: null,
    companyCollectionTagExcludeFilter: null,
    companyHasCRMActionsFilter: null,
    companyCapabilitiesFilter: null,
    companyHasConflictsFilter: null,
    companyParentTypeFilter: null,
    companyCollectionReviewFilter: null,
    companyPreviousCampaignFilter: null,
    companyCageCodeFilter: null,
    companyUeiSamFilter: null,
    companyIgpCategoryFilter: null,
    companyCountryFilter: null
  });

  const pageSizeFilter = reactive({
    pageSizeFilter: 50
  })

  const showCreateMultipleCompaniesForm = ref(false)
  function openCreateMultipleCompaniesForm(){
    showCreateMultipleCompaniesForm.value = true
  }
  
  const changedCompanyFilters = ref(false);
  const isModalVisible = ref(false);
  const selectedCompanies = ref([]);
  const selectAll = ref(false);
  const selectAllOnPage = ref(false);
  const clearedSelectedCompanies = ref(false);
  const clearedSelectedCompaniesOnPage = ref(false);
  const awaitingSearch = ref(false);
  const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
  });
  
  const pageSizeOptions = ref([
      {value: "clear", label: "----"},
      {value: 10, label: "10"},
      {value: 25, label: "25"},
      {value: 50, label: "50"},
      {value: 100, label: "100"},
      {value: 200, label: "200"},
    ])

    
    const pageConfigLoading = computed(()=> store.state.pageConfigs.pageConfigLoading)
    const pageConfigs = ref([])
    const pageConfigOptions = ref([{value: null, label: '----'}])
    const selectedPageConfig = computed(() => {

        return store.state.pageConfigs.selectedPageConfig
    })

    const pageStyling = computed(() => {
      if(pageConfigLoading.value) {
        return 'cursor: wait;'
      } else {
        return ''
      }
    })

  
  const crmActionTypes = ref([])

  watch(() => companyFilters, (newValue, oldValue) => {
        shouldLoadCompanies.value = false
        if (!awaitingSearch.value) {
          setTimeout(() => {
            loadCompCompanies(fetchFilter.value, true);
            awaitingSearch.value = false;
          }, 2500); // 2.5 sec delay
        }
        awaitingSearch.value = true;
        // console.log('awaiting serach value', awaitingSearch.value)
      },
      {deep: true}
  );

  watch(selectedPageConfig, () => {
    // console.log('page config changed')
    if(selectedPageConfig.value){
      if(selectedPageConfig.value.default_filters.length === 0){
        loadCompCompanies()
      }
        pageSizeFilter.pageSizeFilter = selectedPageConfig.value.page_size
    }
  })



  function setPageSizeFilter(target=null){
    if(target) {
      target == "clear" ? (pageSizeFilter.pageSizeFilter = 50) : (pageSizeFilter.pageSizeFilter = target);
      loadCompCompanies(fetchFilter.value, true)
    }
    if(selectedPageConfig.value){
      pageSizeFilter.pageSizeFilter = selectedPageConfig.value.page_size
    }
  }
  
  const totalPages = ref(0)
  
  const activeFilters = computed(() => store.getters['company/showActiveFilters'].join(', '))
  const showFilterPane = computed(() => store.getters['company/showFilterPane'])
  const showBulkOperationsPane = computed(() => store.getters['company/showBulkOperationsPane'])
  const showStatusSummaryPane = computed(() => store.getters['company/showStatusSummaryPane'])
  const showCRMSummaryStages = computed(() => store.getters['company/showCRMSummaryStages'])
  const refetchCompanies = computed(() => store.getters['company/refetchCompanies'])
  // console.log('show status pane', showStatusSummaryPane.value)

  watch(refetchCompanies, () => {
    if(refetchCompanies.value) {
      loadCompCompanies(fetchFilter.value ? fetchFilter.value : '?', true)
      store.dispatch('company/setRefetchCompaniesFalse')
    }
  })
  
  async function loadCompCompanies(endpoint = fetchFilter.value, retainSpot) {
    isLoading.value = true;
    shouldLoadCompanies.value = true;
    let payload = {
      token: store.state.auth.token,
      endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint 
    }
    const result = await getCompanies(payload);
    if(shouldLoadCompanies.value){
      companyResults.value = result.companies;
      next.value = result.next ? `&page=${result.page + 1}` : null
      prev.value = result.prev ? `&page=${result.page - 1}` : null
      count.value = result.count
      page.value = result.page
      pageSize.value = result.companies.length
      totalPages.value = result.num_pages
      isLoading.value = false;
      resetSelectedCompaniesOnChange()
    } else {
      return
    }
  
  }

  async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
  }
  
  async function loadCrmStatuses(){
    const token = store.state.auth.token
    const result = await getCrmStatuses(token)
    crmStatuses.value = result
  }
  
  function showModal() {
      isModalVisible.value = true;
  }
    
  function closeCreateCompanyForm() {
    store.dispatch('company/setShowCreateCompanyFormFalse')
  }
  function closeAddMultipleCompaniesForm() {
    showCreateMultipleCompaniesForm.value = false
  }
  
  function closeModal() {
    isModalVisible.value = false;
  }
  
  function resetSelectAllClearSelectedCompanies() {
    resetSelectAll()
    clearSelectedCompanies()
    // resetClearedSelectedCompanies()
  }
  
  function setChangedCompanyFilters(){
    changedCompanyFilters.value = true;
  }
  
  function resetSelectedCompaniesOnChange(){
    // console.log('companies resetChangedCompanyFilters')
    if(changedCompanyFilters.value){
      changedCompanyFilters.value = false;
      resetSelectAllClearSelectedCompanies()
    }
  
  }
  
  function clearSelectedCompanies() {
    selectedCompanies.value = [];
    clearedSelectedCompanies.value = true;
  }
  
  function resetClearedSelectedCompanies() {
    // console.log('resetClearedSelectedCompanies')
    clearedSelectedCompanies.value = false;
  }
  
  function clearSelectedCompaniesOnPage() {
    // logic here to keep selected companies off page and only clear companies on page
    selectedCompanies.value = selectedCompanies.value.filter(company => selectedCompaniesOnPage.value.indexOf(company.id) === -1);
    console.log('clear selected companies on page')
    clearedSelectedCompaniesOnPage.value = true;
  }
  
  function resetClearedSelectedCompaniesOnPage() {
    clearedSelectedCompaniesOnPage.value = true;
  }
  
  function toggleSelectAllOnPage() {
    clearSelectedCompaniesOnPage()
    resetClearedSelectedCompaniesOnPage()
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }else{
      selectAllOnPage.value = true;
    }
  }
  
  function resetSelectAll() {
    if(selectAll.value){
      selectAll.value = false;
    }
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }
  }
  
  function getNext() {
    if (next.value) {
      loadCompCompanies(fetchFilter.value + next.value)
      resetSelectAllClearSelectedCompanies()
    } else {
      alert("No next page")
    }
  }
  
  function getPrev() {
    if (prev.value) {
      loadCompCompanies(fetchFilter.value + prev.value)
      resetSelectAllClearSelectedCompanies()
    } else {
      alert("No prev page")
    }
  }

  async function getCSV(){
    const token = store.state.auth.token
    csvLoading.value = true
    // console.log('filters', fetchFilter.value)
    // console.log('page config cells', selectedPageConfig.value.cells)
    showPopover("Export default CSV fields")
    await exportToCSV(fetchFilter.value, token)
    hidePopover()
    csvLoading.value = false
  }

  async function getCSVWithCurrentFields(){
    const token = store.state.auth.token
    csvLoading.value = true
    // showPopover("Export company fields on page")
    // console.log('filters', fetchFilter.value)
    // console.log('page config cells', selectedPageConfig.value.cells)
    const result = await exportToCSV(fetchFilter.value, token, selectedPageConfig.value.cells)
    // hidePopover()
    if(result.status === 201){
      $toast.open({
        message: result.data,
        type: "success",
        duration: 50000
      })
    }
    csvLoading.value = false
  }
  
  const selectedCompanyIds = computed(function() {
    if(selectedCompanies.value.length > 0){
      return selectedCompanies.value.map(({id}) => {
        return id
      })
  
    } else {
      return []
    }
  })
  
  const companyResultsIds = computed(function() {
    if(companyResults.value.length > 0){
      return companyResults.value.map(({id}) => {
        return id
      })
    } else {
      return []
    }
  })
  
  const numSelectedCompanies = computed(function() {
    return selectedCompanies.value.length
  })
  
  const selectedCompaniesOnPage = computed(function() {
    if(numSelectedCompanies.value > 0){
      return selectedCompanyIds.value.filter((companyId) => {
        return companyResultsIds.value.includes(companyId)
    })
    } else {
      return []
    }
  })
  
  const selectedCompaniesOnPageMap = computed(function() {
    return new Map(selectedCompaniesOnPage.value.map((companyId) => [companyId, companyId]))
  })
  
  const numSelectedCompaniesOnPage = computed(function() {
    return selectedCompaniesOnPage.value.length
    
  })
  
  const selectedCompaniesOffPage = computed(function(){
    return selectedCompanyIds.value.filter((companyId) => {
      return !companyResultsIds.value.includes(companyId)
    })
  })
  
  const numSelectedCompaniesOffPage = computed(function() {
    return selectedCompaniesOffPage.value.length
  })
  
  const viewingFromNbr = computed(function () {
    return (page.value * pageSize.value + 1) - pageSize.value
  })
  
  
  const viewingToNbr = computed(function () {
    if (page.value === totalPages.value) {
      return count.value;
    } else {
      return page.value * pageSize.value
    }
  })

  const isPopoverVisible = ref(false)
  const xPosition = ref(0)
  const yPosition = ref(0)
  const csvElement = ref(null)
  const exportCSVText = ref("")

  function showPopover(popoverText) {
              exportCSVText.value = popoverText
              isPopoverVisible.value = true;
              xPosition.value = csvElement.value.getBoundingClientRect().right
              yPosition.value = csvElement.value.getBoundingClientRect().top
            }

    function hidePopover() {
              isPopoverVisible.value = false;
              xPosition.value = 0;
              yPosition.value = 0;
            }
  
  
  // const totalPages = computed(function () {
  //   return Math.ceil(count.value / pageSize.value )
  // })
  
  const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    // console.log('user permissions store', userPermissionsStore)
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies =  userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  
    // console.log('user permissions', userPermissions)
  }
  
  const RenderBulkOperations = computed(function () {
    if(userPermissions.isAccountManager){
      return true
    }
    if(userPermissions.isAccountStaff){
      return true
    }
    if(userPermissions.isDev){
      return true
    }
    if(userPermissions.isManagement){
      return true
    }
    if(userPermissions.isResearchLead){
      return true
    }
    if(userPermissions.isSuperuser){
      return true
    }
    return false
  })
  
  
  function addSelectedCompany(company) {
    let foundIndex = selectedCompanyIds.value.indexOf(company.id);
    if (foundIndex === -1) {
      selectedCompanies.value.push(company)
    }
  }
  
  function removeSelectedCompany(company) {
    let foundIndex = selectedCompanyIds.value.indexOf(company.id);
    if (foundIndex !== -1) {
      selectedCompanies.value.splice(foundIndex, 1)
    }
  }
  
  function addRemoveSelectedCompanies(company) {
    let foundIndex = selectedCompanies.value.indexOf(company);
    if (foundIndex === -1) {
      selectedCompanies.value.push(company)
    } else {
        selectedCompanies.value.splice(foundIndex, 1)
      }
    }
  
  function setCompanyFilters(updatedFilters) {
    // console.log("setCompanyFilters", updatedFilters);
    companyFilters.companyNameFilter = updatedFilters.companyNameFilter.value;
    companyFilters.companyDescriptionFilter = updatedFilters.companyDescriptionFilter.value;
    companyFilters.companyWebsiteUrlFilter = updatedFilters.companyWebsiteUrlFilter.value;
    companyFilters.companyAddressFilter = updatedFilters.companyAddressFilter.value;
    companyFilters.companySourcesFilter = updatedFilters.companySourcesFilter.value;
    companyFilters.companySourceOwnerFilter = updatedFilters.companySourceOwnerFilter.value;
    companyFilters.companyCampaignFilter = updatedFilters.companyCampaignFilter.value;
    companyFilters.companyCampaignExcludeFilter = updatedFilters.companyCampaignExcludeFilter.value;
    companyFilters.companyTierFilter = updatedFilters.companyTierFilter.value;
    companyFilters.companyClientFeedbackFilter = updatedFilters.companyClientFeedbackFilter.value;
    companyFilters.companyOnboardingStatusFilter = updatedFilters.companyOnboardingStatusFilter.value;
    companyFilters.companyDNRReasonFilter = updatedFilters.companyDNRReasonFilter.value;
    companyFilters.companyResearchStatusFilter = updatedFilters.companyResearchStatusFilter.value;
    companyFilters.companyResearchStatusExpressionFilter = updatedFilters.companyResearchStatusExpressionFilter.value;
    companyFilters.companyResearchStatusFilterTwo = updatedFilters.companyResearchStatusFilterTwo.value;
    companyFilters.companyResearchStatusExpressionFilterTwo = updatedFilters.companyResearchStatusExpressionFilterTwo.value;
    companyFilters.companyMinRevenueFilter = updatedFilters.companyMinRevenueFilter.value;
    companyFilters.companyMaxRevenueFilter = updatedFilters.companyMaxRevenueFilter.value;
    companyFilters.companyMinHeadcountFilter = updatedFilters.companyMinHeadcountFilter.value;
    companyFilters.companyMaxHeadcountFilter = updatedFilters.companyMaxHeadcountFilter.value;
    companyFilters.companyMinRevenueZiCombinedFilter = updatedFilters.companyMinRevenueZiCombinedFilter.value;
    companyFilters.companyMaxRevenueZiCombinedFilter = updatedFilters.companyMaxRevenueZiCombinedFilter.value;
    companyFilters.companyClientFilter = updatedFilters.companyClientFilter.value;
    // companyFilters.pageSizeFilter = updatedFilters.pageSizeFilter.value;
    companyFilters.companyBusinessTypeFilter = updatedFilters.companyBusinessTypeFilter.value;
    companyFilters.companySectorFilter = updatedFilters.companySectorFilter.value;
    companyFilters.companySubSectorFilter = updatedFilters.companySubSectorFilter.value;
    companyFilters.companyCRMStatusFilter = updatedFilters.companyCRMStatusFilter.value;
    companyFilters.companyCRMNextActionFilter = updatedFilters.companyCRMNextActionFilter.value;
    // companyFilters.companyDueTimeRangeFilter = updatedFilters.companyDueTimeRangeFilter.value;
    companyFilters.companyDueDateStartFilter = updatedFilters.companyDueDateStartFilter.value;
    companyFilters.companyDueDateEndFilter = updatedFilters.companyDueDateEndFilter.value;
    companyFilters.companyCollectionTagsFilter = updatedFilters.companyCollectionTagsFilter.value;
    companyFilters.companyCollectionTagSourcesFilter = updatedFilters.companyCollectionTagSourcesFilter.value;
    companyFilters.companyStateFilter = updatedFilters.companyStateFilter.value;
    companyFilters.companyNAICSFilter = updatedFilters.companyNAICSFilter.value;
    companyFilters.companyDrivingTimeFocusFilter = updatedFilters.companyDrivingTimeFocusFilter.value;
    companyFilters.companyDrivingTimeFilter = updatedFilters.companyDrivingTimeFilter.value;
    companyFilters.companyDrivingTimeExpressionFilter = updatedFilters.companyDrivingTimeExpressionFilter.value;
    companyFilters.companyShowResearchHaltedFilter = updatedFilters.companyShowResearchHaltedFilter.value;
    companyFilters.companyParentCompanyStatusFilter = updatedFilters.companyParentCompanyStatusFilter.value;
    companyFilters.companyInvestorsStatusFilter = updatedFilters.companyInvestorsStatusFilter.value;
    companyFilters.companyProblemStatusFilter = updatedFilters.companyProblemStatusFilter.value;
    companyFilters.companyAccountManagerResearchNotesFilter = updatedFilters.companyAccountManagerResearchNotesFilter.value;
    companyFilters.companyTaskUserFilter = updatedFilters.companyTaskUserFilter.value;
    companyFilters.companyIdFilter = updatedFilters.companyIdFilter.value;
    companyFilters.companyCategoryFilter = updatedFilters.companyCategoryFilter.value;
    companyFilters.companyRelevantBusinessFilter = updatedFilters.companyRelevantBusinessFilter.value;
    companyFilters.companyReferralSourceFilter = updatedFilters.companyReferralSourceFilter.value;
    companyFilters.companyReferralStatusFilter = updatedFilters.companyReferralStatusFilter.value;
    companyFilters.companyProductServiceCodeFilter = updatedFilters.companyProductServiceCodeFilter.value;
    // companyFilters.companyContractPSCKeywordFilter = updatedFilters.companyContractPSCKeywordFilter.value;
    // companyFilters.companyContractNAICSKeywordFilter = updatedFilters.companyContractNAICSKeywordFilter.value;
    // companyFilters.companyContractAwardingSubAgencyFilter = updatedFilters.companyContractAwardingSubAgencyFilter.value;
    companyFilters.companyEveraxisPillarFilter = updatedFilters.companyEveraxisPillarFilter.value;
    companyFilters.companyAccountManagerFilter = updatedFilters.companyAccountManagerFilter.value;
    companyFilters.companyCRMActionsFilter = updatedFilters.companyCRMActionsFilter.value;
    companyFilters.companyCollectionTagExcludeFilter = updatedFilters.companyCollectionTagExcludeFilter.value;
    companyFilters.companyHasCRMActionsFilter = updatedFilters.companyHasCRMActionsFilter.value;
    companyFilters.companyCapabilitiesFilter = updatedFilters.companyCapabilitiesFilter.value;
    companyFilters.companyHasConflictsFilter = updatedFilters.companyHasConflictsFilter.value;
    companyFilters.companyParentTypeFilter = updatedFilters.companyParentTypeFilter.value;
    companyFilters.companyCollectionReviewFilter = updatedFilters.companyCollectionReviewFilter.value;
    companyFilters.companyPreviousCampaignFilter = updatedFilters.companyPreviousCampaignFilter.value;
    companyFilters.companyCageCodeFilter = updatedFilters.companyCageCodeFilter.value;
    companyFilters.companyUeiSamFilter = updatedFilters.companyUeiSamFilter.value;
    companyFilters.companyIgpCategoryFilter = updatedFilters.companyIgpCategoryFilter.value;
    companyFilters.companyCountryFilter = updatedFilters.companyCountryFilter.value;
  }

  
  function setCompanyPageSizeFilter(target) {
      target == "clear" ? pageSizeFilter.pageSizeFilter = null : pageSizeFilter.pageSizeFilter = target
      resetSelectAllClearSelectedCompanies()
      loadCompCompanies()
  }
  
  
  const fetchFilter = computed(function () {
    // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
    let query = new URLSearchParams({
      // name: "John Doe",
      // email: "john@doe.com",
      // colors: JSON.stringify(["Red", "Green", "Blue"])
    });
    query.append("has_campaign", companyFilters.companyHasCampaignFilter)
    if (companyFilters.companyNameFilter) {
      query.append("company_name", companyFilters.companyNameFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueFilter) {
      query.append("min_revenue", companyFilters.companyMinRevenueFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueFilter) {
      query.append("max_revenue", companyFilters.companyMaxRevenueFilter); // To append more data
    }
    if (companyFilters.companyMinHeadcountFilter) {
      query.append("min_headcount", companyFilters.companyMinHeadcountFilter); // To append more data
    }
    if (companyFilters.companyMaxHeadcountFilter) {
      query.append("max_headcount", companyFilters.companyMaxHeadcountFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueZiCombinedFilter) {
      query.append("min_revenue_combined", companyFilters.companyMinRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueZiCombinedFilter) {
      query.append("max_revenue_combined", companyFilters.companyMaxRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyDescriptionFilter) {
      query.append("description", companyFilters.companyDescriptionFilter); // To append more data
    }
    if (companyFilters.companyCategoryFilter) {
      query.append("category_search", companyFilters.companyCategoryFilter); // To append more data
    }
    if (companyFilters.companyRelevantBusinessFilter) {
      query.append("relevant_business", companyFilters.companyRelevantBusinessFilter); // To append more data
    }
    if (companyFilters.companyWebsiteUrlFilter) {
      query.append("URL", companyFilters.companyWebsiteUrlFilter); // To append more data
    }
    if (companyFilters.companyAddressFilter) {
      query.append("address_search", companyFilters.companyAddressFilter); // To append more data
    }
    if (companyFilters.companyProductServiceCodeFilter) {
      query.append("product_service_code", companyFilters.companyProductServiceCodeFilter); // To append more data
    }
    if (companyFilters.companyContractPSCKeywordFilter) {
      query.append("contract_psc_keyword", companyFilters.companyContractPSCKeywordFilter); // To append more data
    }
    if (companyFilters.companyContractNAICSKeywordFilter) {
      query.append("contract_naics_keyword", companyFilters.companyContractNAICSKeywordFilter); // To append more data
    }
    if (companyFilters.companyIdFilter) {
      query.append("id_number", companyFilters.companyIdFilter); // To append more data
    }
    if (companyFilters.companyHasConflictsFilter) {
      query.append("has_conflict", companyFilters.companyHasConflictsFilter); // To append more data
    }
    if (companyFilters.companyCollectionReviewFilter) {
      query.append("collection_interest", companyFilters.companyCollectionReviewFilter);
    }
    if (companyFilters.companyOnboardingStatusFilter) {
      query.append("onboarding_status", companyFilters.companyOnboardingStatusFilter); // To append more data
    }
    if (companyFilters.companyDNRReasonFilter) {
      query.append("do_not_research_reason", companyFilters.companyDNRReasonFilter); // To append more data
    }
    if (companyFilters.companyHasCRMActionsFilter) {
      query.append("has_crm_actions", companyFilters.companyHasCRMActionsFilter); // To append more data
    }
    if (companyFilters.companyCageCodeFilter) {
      query.append("cage_code", companyFilters.companyCageCodeFilter); // To append more data
    }
    if (companyFilters.companyUeiSamFilter) {
      query.append("unique_entity_identifier_sam", companyFilters.companyUeiSamFilter); // To append more data
    }
    if (companyFilters.companyClientFilter) {
      companyFilters.companyClientFilter.forEach(client => {
          query.append("client_drf", client)
        })
    }
    if (companyFilters.companyPreviousCampaignFilter) {
      companyFilters.companyPreviousCampaignFilter.forEach(campaign => {
          query.append("previous_campaign", campaign)
        })
    }
    if (pageSizeFilter.pageSizeFilter) {
      query.append("page_size", pageSizeFilter.pageSizeFilter); // To append more data
    }
    if (companyFilters.companySourcesFilter) {
        companyFilters.companySourcesFilter.forEach(source => {
          query.append("source_object", source)
        })
  
    }
    if (companyFilters.companyNAICSFilter) {
        companyFilters.companyNAICSFilter.forEach(naics => {
          query.append("naics_code", naics)
        })
  
    }
    if (companyFilters.companySourceOwnerFilter) {
        companyFilters.companySourceOwnerFilter.forEach(owner => {
          query.append("source_owners", owner)
        })
    }
    if (companyFilters.companyAccountManagerFilter) {
        companyFilters.companyAccountManagerFilter.forEach(account_manager => {
          query.append("account_manager", account_manager)
        })
    }
    if (companyFilters.companyCRMActionsFilter) {
        companyFilters.companyCRMActionsFilter.forEach(crm_action => {
          query.append("crm_action", crm_action)
        })
    }
    if (companyFilters.companyTaskUserFilter) {
        companyFilters.companyTaskUserFilter.forEach(user => {
          query.append("task_user", user)
        })
    }
    if (companyFilters.companyCampaignFilter) {
      if(Array.isArray(companyFilters.companyCampaignFilter)){
        companyFilters.companyCampaignFilter.forEach(campaign => {
          query.append("campaign", campaign)
        })
      } else {
        query.append("campaign", companyFilters.companyCampaignFilter)
      }
    }
    if (companyFilters.companyCampaignExcludeFilter) {
      if(Array.isArray(companyFilters.companyCampaignExcludeFilter)){
        companyFilters.companyCampaignExcludeFilter.forEach(campaign => {
          query.append("campaign_exclude", campaign)
        })
      } else {
        query.append("campaign_exclude", companyFilters.companyCampaignExcludeFilter)
      }
    }
    if (companyFilters.companyTierFilter) {
        companyFilters.companyTierFilter.forEach(tier => {
          query.append("tier_selection", tier)
        })
    }
    if (companyFilters.companyClientFeedbackFilter) {
        companyFilters.companyClientFeedbackFilter.forEach(feedback => {
          query.append("client_feedback", feedback)
        })
    }
    if (companyFilters.companyResearchStatusFilter) {
        companyFilters.companyResearchStatusFilter.forEach(status => {
          query.append("research_statuses", status)
        })
        query.append("research_statuses_lookup", companyFilters.companyResearchStatusExpressionFilter)
    }
    if (companyFilters.companyResearchStatusFilterTwo) {
        companyFilters.companyResearchStatusFilterTwo.forEach(status => {
          query.append("research_statuses_2", status)
        })
        query.append("research_statuses_2_lookup", companyFilters.companyResearchStatusExpressionFilterTwo)
    }
    if (companyFilters.companyBusinessTypeFilter) {
        companyFilters.companyBusinessTypeFilter.forEach(businessType => {
          query.append("business_type", businessType)
        })
    }
    if (companyFilters.companySectorFilter) {
        companyFilters.companySectorFilter.forEach(sector => {
          query.append("sector", sector)
        })
    }
    if (companyFilters.companySubSectorFilter) {
        companyFilters.companySubSectorFilter.forEach(subSector => {
          query.append("sub_sector", subSector)
        })
    }
    if (companyFilters.companyCRMStatusFilter) {
        companyFilters.companyCRMStatusFilter.forEach(status => {
          query.append("crm_status", status)
        })
    }
    if (companyFilters.companyCRMNextActionFilter) {
        companyFilters.companyCRMNextActionFilter.forEach(action => {
          query.append("next_action", action)
        })
    }
    if (companyFilters.companyCountryFilter) {
        companyFilters.companyCountryFilter.forEach(country => {
          query.append("country", country)
        })
    }
    // if (companyFilters.companyDueTimeRangeFilter) {
    //     companyFilters.companyDueTimeRangeFilter.forEach(time => {
    //       query.append("due_date", time)
    //     })
    // }
    if (companyFilters.companyDueDateStartFilter) {
        query.append("due_date_start", companyFilters.companyDueDateStartFilter)
    }
    if (companyFilters.companyDueDateEndFilter) {
        query.append("due_date_end", companyFilters.companyDueDateEndFilter)
    }
    if (companyFilters.companyCollectionTagsFilter) {
        companyFilters.companyCollectionTagsFilter.forEach(collectionTag => {
          query.append("collection_tag", collectionTag)
        })
    }
    if (companyFilters.companyCollectionTagSourcesFilter) {
        companyFilters.companyCollectionTagSourcesFilter.forEach(collectionTagSource => {
          query.append("collection_tag_async_job", collectionTagSource)
        })
    }
    if (companyFilters.companyStateFilter) {
        companyFilters.companyStateFilter.forEach(state => {
          query.append("state", state)
        })
        query.append("state_lookup", companyFilters.companyStateExpressionFilter)
    }
    if (companyFilters.companyDrivingTimeFocusFilter && companyFilters.companyDrivingTimeFilter) {
      query.append("driving_distance_focus", companyFilters.companyDrivingTimeFocusFilter);
      query.append('driving_time', companyFilters.companyDrivingTimeFilter)
      query.append('driving_time_lookup', companyFilters.companyDrivingTimeExpressionFilter)
    }
    if (companyFilters.companyShowResearchHaltedFilter) {
      query.append('show_research_halted', companyFilters.companyShowResearchHaltedFilter)
    }
    if (companyFilters.companyAccountManagerResearchNotesFilter) {
      query.append("account_manager_research_notes", companyFilters.companyAccountManagerResearchNotesFilter);
    }
    if (companyFilters.companyParentCompanyStatusFilter) {
      query.append("parent_company_status", companyFilters.companyParentCompanyStatusFilter);
    }
    if (companyFilters.companyInvestorsStatusFilter) {
      query.append("investors_status", companyFilters.companyInvestorsStatusFilter);
    }
    if (companyFilters.companyProblemStatusFilter) {
      query.append("problem_status", companyFilters.companyProblemStatusFilter);
    }
    if (companyFilters.companyReferralSourceFilter) {
        companyFilters.companyReferralSourceFilter.forEach(referralSource => {
          query.append("referrer", referralSource)
        })
    }
    if (companyFilters.companyCapabilitiesFilter) {
        companyFilters.companyCapabilitiesFilter.forEach(capability => {
          query.append("capabilities", capability)
        })
    }
    if (companyFilters.companyContractAwardingSubAgencyFilter) {
        companyFilters.companyContractAwardingSubAgencyFilter.forEach(awardingSubAgency => {
          query.append("contract_awarding_sub_agency", awardingSubAgency)
        })
    }
    if (companyFilters.companyReferralStatusFilter) {
      query.append("referrer_status", companyFilters.companyReferralStatusFilter);
    }
    if (companyFilters.companyEveraxisPillarFilter) {
        companyFilters.companyEveraxisPillarFilter.forEach(everaxisPillar => {
          query.append("everaxis_pillar", everaxisPillar)
        })
    }
    if (companyFilters.companyIgpCategoryFilter) {
        companyFilters.companyIgpCategoryFilter.forEach(igpCategory => {
          query.append("igp_category", igpCategory)
        })
    }
    if (companyFilters.companyParentTypeFilter) {
      companyFilters.companyParentTypeFilter.forEach(parentType => {
        query.append("parent_type", parentType)
      })
    }
    if (companyFilters.companyCollectionTagExcludeFilter) {
        companyFilters.companyCollectionTagExcludeFilter.forEach(collectionTag => {
          query.append("collection_tag_exclude", collectionTag)
        })
    }
    
    // TO APPEND  MORE DATA
  
  // (B) CONVERT TO STRING, APPEND TO URL
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      // console.log(value)
      queriesCount = queriesCount + 1;
    }
    // console.log("queriesCount", queriesCount)
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    // console.log(url);
    return url
  // (C) WHATEVER COMES NEXT...
  // REDIRECT OR AJAX CALL OR FETCH
  // window.location.href = url;
  })
  
  function jumpToPage(targetPage) {
    loadCompCompanies(fetchFilter.value + `&page=${targetPage}`)
    resetSelectAllClearSelectedCompanies()
  }
  
  function goToFirst(){
    loadCompCompanies(fetchFilter.value + `&page=${1}`)
    resetSelectAllClearSelectedCompanies()
  }
  
  function goToLast(){
    loadCompCompanies(fetchFilter.value + `&page=${totalPages.value}`)
    resetSelectAllClearSelectedCompanies()
  }

  function loadNoDefaultFiltersPageConfigCompanies(){
    if(selectedPageConfig.value && selectedPageConfig.value.default_filters.length === 0){
      loadCompCompanies()
    }
  }

  async function loadActions() {
    const token = store.state.auth.token
    const response = await getActions(token)
    // console.log('response', response.data)
    crmActionTypes.value = response.data
}
  
  setUserPermissions()
  // loadCompCompanies()
  loadNoDefaultFiltersPageConfigCompanies()
  loadCampaigns()
  loadCrmStatuses()
  closeCreateCompanyForm()
  setPageSizeFilter()
  loadActions()
  </script>
  
  <style scoped>
  </style>
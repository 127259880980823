export default {
    showCompanies(state) {
        return state.companies;
    },
    showLoading(state) {
        return state.isLoading;
    },
    showCount(state){
        return state.count
    },
    showNext(state){
        return state.next
    },
    showPrev(state){
        return state.prev
    },
    showPage(state){
        return state.page
    },
    showPageSize(state){
        return state.pageSize
    },
    showActiveFilters(state){
        return state.activeFilters
    },
    showCreateCompanyForm(state) {
        return state.showCreateCompanyForm
    },
    showFilterPane(state){
        return state.showFilterPane
    },
    showTableFilters(state) {
        return state.showTableFilters
    },
    showBasicFilters(state){
        return state.showBasicFilters
    },
    showCampaignFilters(state){
        return state.showCampaignFilters
    },
    showResearchFilters(state){
        return state.showResearchFilters
    },
    showBulkOperationsPane(state){
        return state.showBulkOperationsPane
    },
    showStatusSummaryPane(state){
        return state.showStatusSummaryPane
    },
    showCRMSummaryStages(state){
        return state.showCRMSummaryStages
    },
    showMyOpenTasks(state){
        return state.showMyOpenTasks
    },
    showVisibleFilters(state){
        return state.visibleFilters
    },
    refetchCompanies(state){
        return state.refetchCompanies
    },
    showActiveFiltersVisible(state){
        return state.activeFiltersVisible
    },
    showCRMSummaryStagesClient(state) {
        return state.crmSummaryStagesClient
    }
}
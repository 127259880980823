<template>
    <div class="h-full">
        <div class="pt-1 w-full h-fit">
            <BaseTabBar 
                :tabList="tabList"
                :selectedColor="selectedColor"
                :notSelectedColor="notSelectedColor"
                :hoverColor="hoverColor"
                @set-page="setView"
            />
        </div>
        <div v-show="displayZoomInfo()" class="h-full">
            <CompanyZoomInfoForm
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <div v-show="displayChatGPT()" class="h-full">
            <CompanyChatGPTResponses
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'company-third-party-data'
}
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import BaseTabBar from '@/components/BaseTabBar.vue';
import CompanyZoomInfoForm from './zoom-info/CompanyZoomInfoForm.vue';
import CompanyChatGPTResponses from './chat-gpt/CompanyChatGPTResponses.vue';

const props = defineProps(['company'])
const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies() {
    emit('refetch-companies')
}

const tabList = ref([
    {tabName: 'Zoom Info', isSelected: true},
    {tabName: 'Chat GPT', isSelected: false}
])

const selectedColor = 'white';
const notSelectedColor = 'gray-100';
const hoverColor = 'slate-50';

function setView(tabName) {
    if (tabName == 'Zoom Info') {
        showZoomInfo();
    } else if (tabName == 'Chat GPT') {
        showChatGPT();
    }
}

const isZoomInfoVisible = ref(true)
const isChatGPTResearchVisible = ref(false)

function showZoomInfo() {
    isZoomInfoVisible.value = true;
    isChatGPTResearchVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Zoom Info"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showChatGPT() {
    isZoomInfoVisible.value = false;
    isChatGPTResearchVisible.value = true;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Chat GPT"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}


function displayZoomInfo(){
    if(
        isZoomInfoVisible.value && 
        !isChatGPTResearchVisible.value
        ) {
        return true
    } else {
        return false
    }
}

function displayChatGPT(){
    if(
        !isZoomInfoVisible.value && 
        isChatGPTResearchVisible.value
        ) {
        return true
    } else {
        return false
    }
}


</script>
<template>
    <div>
        <div @dblclick="toggleEditable" v-if="isEditable">
        <EditEngagementInput :engagement-id="engagement.id" :input-text="engagement.end_date" input-type="date" field-name="end_date" @refetch-engagements="fetchNewEngagements" @toggle-editable="toggleEditable" />
        </div>
        <div @dblclick="toggleEditable"  class="text-center" v-else>
            {{ endDate }}
        </div>
    </div>
</template>

<script>
export default {
    name: "engagement-end-date-cell"
}
</script>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import EditEngagementInput from './EditEngagementInput.vue';

const props = defineProps({
    engagement: Object
})

const endDate = computed(() => {
    if(props.engagement.end_date){
        let date = new Date(props.engagement.end_date)
        date.setDate(date.getDate() + 1)
        return date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit'})
    } else {
        return '--'
    }
})

const isEditable = ref(false)

const emit = defineEmits(['refetch-engagements'])

function fetchNewEngagements(){
    emit('refetch-engagements')
}

function toggleEditable(){
    isEditable.value = !isEditable.value
}

</script>


<template>
    <div>
        <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies" />
        <ZiUrlCell :company="company" />
    </div>
</template>

<script>
    export default {
        name: "zi-url-url-cell"
    }
</script>

<script setup>
import ZiUrlCell from '../company-cell-components/ZiUrlCell.vue';
import WebsiteUrlCell from '../company-cell-components/WebsiteUrlCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
<template>
    <form v-if="index == startingIndex"  @submit.prevent
      class="w-52 bg-gray-100 hover:bg-gray-200 cursor-pointer"
      :class="filterClass"
    >
      <div class="flex">
        <div class="group w-full">
            <label class=" text-xs w-full p-1.5 rounded-tl-md  border-gray-300 flex items-center justify-between" :class="{'bg-amber-300': active}" for="companyName">
            <slot name="label"></slot>
            <!-- <button type="button" @click="close" class="text-xs w-4 h-4 bg-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle">-</button> -->
            <slot name="button"></slot>
          </label>
          <div class="rounded-bl-md h-8 text-xs
                      border-t border-b text-gray-800
                      focus:ring-dark-primary focus:border-dark-primary">
            <slot name="widget"></slot>
          </div>
        </div>

      </div>
    </form>
    <form v-else-if="index == endingIndex"  @submit.prevent class="w-52 bg-gray-100 hover:bg-gray-200 rounded-r-md cursor-pointer">
      <div class="flex">
        <div class="group w-full">
            <label class=" text-xs w-full p-1.5 rounded-tr-md  border-l  border-gray-300 flex items-center justify-between" :class="{'bg-amber-300': active}" for="companyName">
            <slot name="label"></slot>
            <!-- <button type="button" @click="close" class="text-xs w-4 h-4 bg-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle">-</button> -->
            <slot name="button"></slot>
          </label>
          <div class="rounded-br-md h-8 text-xs
                      border-t border-l border-b text-gray-800
                      focus:ring-dark-primary focus:border-dark-primary">
            <slot name="widget"></slot>
          </div>
        </div>
      </div>
    </form>
    <form  v-else @submit.prevent class="w-52 bg-gray-100 hover:bg-gray-200 cursor-pointer">
      <div class="flex">
        <div class="group w-full">
          <label class=" text-xs w-full p-1.5  border-l  border-gray-300 flex items-center justify-between" :class="{'bg-amber-300': active}" for="companyName">
            <slot name="label"></slot>
              <!-- <button type="button" @click="close" class="text-xs w-4 h-4 bg-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle">-</button> -->
            <slot name="button"></slot>
        </label>
        <div class="h-8 text-xs
                      border-t border-l border-b text-gray-800
                      focus:ring-dark-primary focus:border-dark-primary">
            <slot name="widget"></slot>
          </div>
        </div>
      </div>
    </form>
  </template>
  
  <script setup>
  import { defineEmits, defineProps, computed } from 'vue';
  
  const emit = defineEmits(['close'])
  const props = defineProps({
    active: Boolean,
    index: Number,
    startingIndex: Number,
    endingIndex: Number
  })
  
  const filterClass = computed(() => {
    switch(props.endingIndex) {
      case 0:
        return 'rounded-md'
      case 1:
        switch(props.index) {
          case 0:
            return 'rounded-t-md sm:rounded-l-md'
          case 1:
            return 'rounded-b-md sm:rounded-r-md'
          default:
            return ''
        }
      case 2:
        switch(props.index) {
          case 0:
            return 'rounded-t-md sm:rounded-tl-md'
          case 1:
            return 'sm:rounded-tr-md md:rounded-none'
          case 2:
            return 'rounded-b-md sm:rounded-br-none md:rounded-bl-none md:rounded-r-md'
          default:
            return ''
        }
      case 3:
        switch(props.index) {
          case 0:
            return 'rounded-t-md sm:rounded-tl-md'
          case 1:
            return 'sm:rounded-tr-md md:rounded-none'
          case 2:
            return 'sm:rounded-bl-md md:rounded-bl-none md:rounded-tr-md lg:rounded-none'
          case 3:
            return 'rounded-b-md sm:rounded-bl-none md:rounded-bl-md md:rounded-br-none'
          default:
            return ''
        }
      case 4:
        switch(props.index) {
          case 0:
            return 'rounded-t-md sm:rounded-tl-md'
          case 1:
            return 'sm:rounded-tr-md md:rounded-none'
          case 2:
            return 'sm:rounded-tr-md lg:rounded-none'
          case 3:
            return 'md:rounded-tr-md lg:rounded-none'
          case 4:
            return 'rounded-b-md sm:rounded-bl-md md:rounded-r-md'
          default:
            return ''
          }
      case 5:
        switch(props.index) {
            case 0:
              return 'rounded-t-md'
            case props.endingIndex:
              return 'rounded-b-md'
            default:
              return ''
          }
      case 6:
        switch(props.index) {
            case 0:
              return 'rounded-t-md'
            case props.endingIndex:
              return 'rounded-b-md'
            default:
              return ''
          }
      case 7:
        switch(props.index) {
            case 0:
              return 'rounded-t-md'
            case props.endingIndex:
              return 'rounded-b-md'
            default:
              return ''
          }
      case 8:
        switch(props.index) {
            case 0:
              return 'rounded-t-md'
            case props.endingIndex:
              return 'rounded-b-md'
            default:
              return ''
        }
      case 9:
        switch(props.index) {
            case 0:
              return 'rounded-t-md'
            case props.endingIndex:
              return 'rounded-b-md'
            default:
              return ''
        }
      default:
        // take remainder divisible by 10
        switch(props.index) {
          case 0:
            return 'rounded-t-md'
          case props.endingIndex:
            return 'rounded-b-md'
          default:
            return ''
        }
        // const overflowIndex = props.endingIndex % 10
        // console.log(overflowIndex)
        // if(overflowIndex==0) {
        //   return ''
        // } else {

        // }
    }
  })

  const lableClass = computed(() => {
    return ''
    // switch(props.endingIndex) {
    //   case 0:
    //     return ''
    //   case 1:

    //   case 2:

    //   case 3:

    //   case 4:

    //   case 5:

    //   case 6:

    //   case 7:

    //   case 8:

    //   case 9:

    //   default:
        // take remainder divisible by 10
        // const overflowIndex = props.endingIndex % 10
        // console.log(overflowIndex)
        // if(overflowIndex==0) {
        //   return ''
        // } else {

        // }
    // }
  })

  const widgetClass = computed(() => {
    return ''
    // switch(props.endingIndex) {
    //   case 0:
    //     return ''
    //   case 1:

    //   case 2:

    //   case 3:

    //   case 4:

    //   case 5:

    //   case 6:

    //   case 7:

    //   case 8:

    //   case 9:

    //   default:
        // take remainder divisible by 10
        // const overflowIndex = props.endingIndex % 10
        // console.log(overflowIndex)
        // if(overflowIndex==0) {
        //   return ''
        // } else {

        // }
    // }
  })

  </script>
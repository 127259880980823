
import axios from 'axios';
import { BASE_URL } from './api';

export async function getSectors(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/sectors/get_sectors/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function createNewSector(data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/sectors/create_sector/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function deleteSector(sectorID, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/sectors/${sectorID}/delete_sector/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function updateSector(sectorID, data, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/sectors/${sectorID}/edit_sector/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
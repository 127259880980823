import asynchronousJobMutations from './mutations.js';
import asynchronousJobActions from './actions.js';
import asynchronousJobGetters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            showFilterPane: true,
            activeFilters: [],
        }
    },
    mutations: asynchronousJobMutations,
    actions: asynchronousJobActions,
    getters: asynchronousJobGetters,
}
<template>
    <div class="w-full max-w-[1200px] h-full flex space-x-2">
        <base-card class="w-full max-w-[1200px] text-gray-900 shadow-md bg-white" :style="postStyle">
            <div class="w-full h-full space-y-2 p-2">
                <div class="w-full border-b border-gray-300 pb-2 flex justify-between">
                    <div class="whitespace-nowrap text-xs space-y-0.5 flex lg:grid lg:grid-cols-3 space-x-2 items-center w-full">
                        <div class="flex justify-start w-full lg:justify-start">
                            <div class="whitespace-nowrap text-xs inline lg:pl-1">
                                {{ formatDateTime(acquisition.verified_at) }}
                            </div>
                            <div v-if="formatDateTime(acquisition.verified_at) !== formatDateTime(acquisition.updated_at)" class="whitespace-nowrap text-xs inline text-gray-800 pl-1">
                                (Edited: {{ formatDateTime(acquisition.updated_at) }} )
                            </div>
                        </div>
                        <div class="w-full flex justify-end items-start">
                            {{ verifiedBy }}
                        </div>
                        <div class="text-gray-800 flex justify-end items-center min-w-[50px]">
                            <div v-if="isEditable" @click="cancelEditable" class="hover:cursor-pointer hover:text-accent-primary">
                                Cancel
                            </div>
                            <div v-else @click="makeEditable" class="hover:cursor-pointer hover:text-accent-primary">
                                Edit
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isEditable" class="grid grid-cols-3 space-x-2 px-1 w-full">
                    <div class="grid grid-cols-3 space-x-2">
                        <div class="col-span-2 ">
                            <div>Parent ID:</div>
                            <input class="p-1 border mt-1 flex-col border-gray-400 rounded-md w-full" v-model="acquisitionPostData.parent_id" type="tel"/>
                        </div>
                        <div class="flex flex-col justify-end">
                            <base-button @click="setSelfOwned">Self-Owned</base-button>
                        </div>
                    </div>
                    <div>
                        <div>Parent Ownership Type:</div>
                        <select class="border border-gray-400 mt-1 rounded-md p-1 w-full" v-model="ownershipType" name="" id="">
                            <option v-for="ownershipType in ownershipTypeOptions" :key="ownershipType.value" :value="ownershipType.value">
                                {{ ownershipType.label }}
                            </option>
                        </select>
                    </div>
                    <div v-if="isEditable" class="">
                        <div v-if="!isSelfOwned" class="w-full">
                            <div>Acquisition Date:</div>
                            <input class="p-1 border mt-1 border-gray-400 rounded-md w-full" v-model="acquisitionPostData.acquisition_date" type="date"/>
                        </div>
                        <div v-else class="w-full">
                            <div class="text-gray-400">Acquisition Date:</div>
                            <input disabled class="p-1 border bg-gray-200 text-gray-500 mt-1 border-gray-400 rounded-md w-full" v-model="acquisitionPostData.acquisition_date" type="date"/>
                        </div>
                    </div>
                </div>
                <div v-if="isEditable" class="flex w-full justify-between items-center space-x-2">
                    <div v-if="isEditable">
                        <div v-if="isLoading" role="status" class="flex justify-start items-center pt-2">
                            <svg aria-hidden="true" class="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="white"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#2E9CCA"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div v-else class="pl-2">
                            {{ parentCompanyName }}
                        </div>
                    </div>
                    <div class="flex items-center space-x-2">
                        <base-button class="-my-1" @click="verifyAcquisitionPost">Mark Verified</base-button>
                        <base-button-affirmative @click="updateAcquisitionPost">Update</base-button-affirmative>
                        <base-button-negative @click="deleteAcquisitionPost">Delete</base-button-negative>
                    </div>
                </div>
                <div v-else class="flex justify-start items-start space-x-2 w-full">
                    {{ acquisitionSummary }}
                </div>
            </div>
        </base-card>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { formatDateTime } from '@/composables/formatDateTime';
import { formatDate } from '@/composables/formatDate';
import { getCompanyByID } from '@/services/Company';
import { updateCompany } from '@/services/Company';
import { updateCompanyParent } from '@/services/CompanyParentSubsidiary';
import { deleteCompanyParent } from '@/services/CompanyParentSubsidiary';
import { markCompanyParentVerified } from '@/services/CompanyParentSubsidiary';
import { useToast } from 'vue-toast-notification';

const props = defineProps(['company', 'acquisition', 'currentUser', 'recentVerification'])

const emit = defineEmits(['refetch-companies'])

const store = useStore()

const $toast = useToast()

const isEditable = ref(false)

const parentCompanyName = ref(props.acquisition.parent ? props.acquisition.parent.company_name : 'unknown company')
const awaitingSearch = ref(false)
const shouldLoadCompany = ref(true)
const isLoading = ref(false)
const parentOwnershipType = ref(null)
const ownershipType = ref(props.acquisition.parent ? props.acquisition.parent.ownership : 'unknown type')

const acquisitionPostData = ref({
    parent_id: props.acquisition.parent ? props.acquisition.parent.id : null,
    acquisition_date: props.acquisition.acquisition_date,
})

watch(() => acquisitionPostData.value.parent_id, ()=> {
    shouldLoadCompany.value = false
    if(!awaitingSearch.value) {
        setTimeout(() => {
            setParentCompanyName(acquisitionPostData.value.parent_id)
            awaitingSearch.value = false
        }, 1500)
    }
    awaitingSearch.value = true

})

const isSelfOwned = computed(()=> {
    if(acquisitionPostData.value.parent_id) {
        if(acquisitionPostData.value.parent_id == props.company.id) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }

})

watch(()=> isSelfOwned.value, ()=> {
    if(isSelfOwned.value){
        acquisitionPostData.value.acquisition_date = null
    }
})


const verifiedBy = computed(() => {
    if(props.acquisition.verified_by) {
        return `${props.acquisition.verified_by.first_name} ${props.acquisition.verified_by.last_name}`
    } else {
        return '--'
    }
})

const displayedOwnershipType = computed(() => {
    if(ownershipType.value) {
        return ` , a ${ownershipType.value.toLowerCase()} company`
    } else {
        return ''
    }
})

// const verifiedById = computed(() => {
//     if(props.acquisition.verified_by) {
//         return props.acquisition.verified_by.id
//     } else {
//         return null
//     }
// })

const postStyle = computed(() => {
    if(props.acquisition.is_current && !props.recentVerification) {
        return 'background-color: #fde68a; border: 1px solid #92400e;'
    }
    else if(props.acquisition.is_current) {
        return 'background-color: #a7f3d0; border: 1px solid #065f46;'
    } else {
        return ''
    }
})

const acquisitionSummary = computed(() => {
    if(props.acquisition.parent) {
        if(props.acquisition.parent.id == props.company.id) {
            return `${props.company.company_name} (${props.company.id})${displayedOwnershipType.value} is confirmed to be self owned.`
        }
    }
    return `${acquisitionDate.value} - ${props.company.company_name} (${props.company.id}) acquired by ${parentCompanyName.value} (${acquisitionPostData.value.parent_id})${displayedOwnershipType.value}.`
        // } else {
        //     return `${props.company.company_name} (${props.company.id}) acquired by ${ownershipType.value} ${parentCompanyName.value} (${acquisitionPostData.value.parent_id}) on ${acquisitionDate.value}.`
        // }
    // } else {
    //     return `${props.company.company_name} (${props.company.id}) acquired by ${ownershipType.value} ${parentCompanyName.value}`
    // }

})

const acquisitionDate = computed(() => {
    if(props.acquisition.acquisition_date) {
        return formatDate(props.acquisition.acquisition_date)
    } else {
        return 'unknown date'
    }
})


function makeEditable () {
    isEditable.value = true
}

function cancelEditable () {
    isEditable.value = false
    acquisitionPostData.value.parent_id = props.acquisition.parent.id
    acquisitionPostData.value.acquisition_date = props.acquisition.acquisition_date
}

const ownershipTypeOptions = computed(() => {
    return [
        {value: null, label: "-------"},
        {value: "Government Owned", label: "Government Owned"},
        {value: "Private Equity Owned", label: "Private Equity Owned"},
        {value: "Proprietor Owned", label: "Proprietor Owned"},
        {value: "Public", label: "Public"},
    ]
})

function setSelfOwned() {
    acquisitionPostData.value.parent_id = props.company.id
}

async function setParentCompanyName(companyId) {
    if(acquisitionPostData.value.parent_id){
        isLoading.value = true;
        shouldLoadCompany.value = true;
        console.log('setParentCompanyName')
        let payload = {
            token: store.state.auth.token,
            companyId: companyId
        }
        const result = await getCompanyByID(payload)
        if(shouldLoadCompany.value) {
            console.log(result)
            console.log(result.response)
            if(result.response.status == 200) {
                parentCompanyName.value = result.response.data.company_name
                ownershipType.value = result.response.data.ownership
                parentOwnershipType.value = result.response.data.ownership
            } else {
                parentCompanyName.value = 'No Company Found'
                ownershipType.value = '--'
            }
            isLoading.value = false;
        } else {
            return
        }
    } else {
        parentCompanyName.value = 'Please Enter Company ID'
        ownershipType.value = '--'
    }
}

async function updateAcquisitionPost() {
    console.log('update')
    const token = store.state.auth.token
    const result = await updateCompanyParent(props.acquisition.id, acquisitionPostData.value, token)
    if(ownershipType.value != parentOwnershipType.value) {
        const data = JSON.stringify({
            ownership: ownershipType.value
        })
        await updateCompany(acquisitionPostData.value.parent_id, data, token)
    }
    console.log(result)
    if(result.status==200) {
        $toast.open({
            message: `Successfully Updated Acquisition.`,
            type: 'success',
            duration: 5000
        })
        isEditable.value = false
        emit('refetch-companies')
    } else {
        $toast.open({
            message: 'Error Updating Acquisition.',
            type: 'error',
            duration: 20000
        })
    }
}


async function deleteAcquisitionPost() {
    console.log('delete')
    const token = store.state.auth.token
    const result = await deleteCompanyParent(props.acquisition.id, token)
    if(result.status==200) {
        $toast.open({
            message: `${result.data.status} `,
            type: 'success',
            duration: 5000
        })
        isEditable.value = false
        emit('refetch-companies')
    } else {
        $toast.open({
            message: result.status,
            type: 'error',
            duration: 20000
        })
    }
}

async function verifyAcquisitionPost() {
    const token = store.state.auth.token
    const result = await markCompanyParentVerified(props.acquisition.id, token)
    if(result.status==200) {
        $toast.open({
            message: `Successfully verified acquisition.`,
            type: 'success',
            duration: 5000
        })
        isEditable.value = false
        emit('refetch-companies')
    } else {
        $toast.open({
            message: `Error verifying acquisition.`,
            type: 'error',
            duration: 20000
        })
    }
}


</script>
<template>
    <base-dblclick-textarea
        model-type="contact"
        :model-id="contact.id"
        field-name="personal_notes"
        :field-value="contact.personal_notes"
        @refetch="fetchNewContacts"
    >
        <span class="pr-1"><img id="notes" src="@/assets/images/icons8-notes-50.png" alt="notes" class="w-[16px] inline"/></span>
    </base-dblclick-textarea>
</template>

<script>
    export default {
        name: "contact-personal-notes-truncated-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    contact: Object
})

const emit = defineEmits(['refetch-contacts'])

function fetchNewContacts () {
    emit('refetch-contacts')
}
</script>
<template>
    <TablePage
        class="px-0 2xl:px-32 pb-2"
        :module-name="moduleName"
        :response="response"
        :isLoading="isLoading"
        :tableHeaders="tableHeaders"
        :filters="serviceRecordFilters"
        :bulkOperations="serviceRecordBulkOperations"
        :menuOptions="menuOptions"
        :defaultOrdering="defaultOrdering"
        @refetch-results="loadServiceRecords"
        @clear-filter="clearFilter"
        @change-filters="setFilters"
        @set-filter-options="setFilterOptions"
    >
        <template #bulk-operations="{ events: { hideBulkActionMenu, hideBulkOperations, clearSelectAllClearSelectedResults, refetchResults }, bulkAction, fetchFilter, selectedResults }">
            <ServiceRecordBulkOperations
                :bulkAction="bulkAction"
                :selectedResults="selectedResults"
                @set-bulk-operation-options="setFilterOptions"
                @cancel-bulk-operation="hideBulkActionMenu"
                @close-bulk-operation="hideBulkOperations"
                @clear-selected-results="clearSelectAllClearSelectedResults"
                @refetch-results="refetchResults(fetchFilter)"
            />
        </template>
        <template #row-form="{ events: { closeFormRow, refetchResults }, index, fetchFilter, userPermissions }">
            <ServiceRecordRowForm
                :index="index"
                :userPermissions="userPermissions"
                :engagementOptions="serviceRecordFilters.engagement.options"
                :userOptions="serviceRecordFilters.user.options"
                @refetch-results="refetchResults(fetchFilter)"
                @close-form="closeFormRow(index)"
            /> 
        </template>
        <template #table-row="{ events: { addSelectedResult, removeSelectedResult, refetchResults }, selectAll, fetchFilter, userPermissions }">
            <ServiceRecordRow
                v-for="serviceRecord in response.data.results"
                :key="serviceRecord.id"
                :serviceRecord="serviceRecord"
                :selectAll="selectAll"
                :userPermissions="userPermissions"
                :is-loading="isLoading"
                :engagementOptions="serviceRecordFilters.engagement.options"
                :userOptions="serviceRecordFilters.user.options"
                @refetch-results="refetchResults(fetchFilter)"
                @add-selected-service-record="addSelectedResult"
                @remove-selected-service-record="removeSelectedResult"
            />
        </template>
        <template #table-footer="{ viewingFromNbr, viewingToNbr, count, selectedResults }">
            <ServiceRecordFooter
                :viewingFromNbr="viewingFromNbr"
                :viewingToNbr="viewingToNbr"
                :count="count"
                :selectedResults="selectedResults"
                :totalHours="totalHours"
                :totalAmountDue="totalAmountDue"
            />
        </template>
    </TablePage>
</template>

<script setup>
import { ref, reactive, } from 'vue';
import { useStore } from 'vuex';
import { getServiceRecords } from '@/services/ServiceRecord';
import TablePage from "@/components/base-components/TablePage";
import ServiceRecordRow from './ServiceRecordRow.vue';
import ServiceRecordRowForm from './ServiceRecordRowForm.vue';
import ServiceRecordBulkOperations from './ServiceRecordBulkOperations.vue';
import ServiceRecordFooter from './ServiceRecordFooter.vue';
import { getClients } from '@/services/Client';
import { getEngagements } from '@/services/Engagement';
import { getInvoices } from '@/services/Invoice';
import { getUsersByGroup } from '@/services/User';
import { formatDate } from '@/composables/formatDate';

const store = useStore()
const isLoading = ref(false)
const moduleName = ref('Service Records')
const response = ref(null)
const totalHours = ref(null)
const totalAmountDue = ref(null)
const tableHeaders = ref([
    {
        value: 'type',
        label: 'Type',
       // class: 'p-0 w-24 lg:w-28',
        class: 'p-0 w-[10%] ',
        orderFieldName: "type"
    },
    {
        value: 'title',
        label: 'Title',
       // class: 'p-0 w-20 lg:w-40 ',
        class: 'p-0 w-[12%] ',
        orderFieldName: "title"
    },
    {
        value: 'description',
        label: 'Description',
       // class: 'p-0 w-28 sm:w-56 md:w-60 ',
        class: 'p-0 w-[15%] ',
    },
    {
        value: 'date',
        label: 'Date',
       // class: 'p-0 w-24',
        class: 'p-0 w-[8%] ',
        orderFieldName: "date"
    },
    {
        value: 'hours',
        label: 'Hours',
       // class: 'p-0 w-12 ',
        class: 'p-0 w-[5%] ',
    },
    {
        value: 'hourly_rate',
        label: 'Rate',
       // class: 'p-0 w-12  whitespace-nowrap',
        class: 'p-0 w-[5%] whitespace-nowrap',
    },
    {
        value: 'amount_due',
        label: 'Amt. Due',
       // class: 'p-0 w-12  whitespace-nowrap',
        class: 'p-0 w-[6%] whitespace-nowrap',
    },
    {
        value: 'user',
        label: 'User',
       // class: 'p-0 w-24 ',
        class: 'p-0 w-[12%]',
        orderFieldName: "user"
    },
    {
        value: 'engagement',
        label: 'Engagement',
       // class: 'p-0 w-24 lg:w-40 ',
        class: 'p-0 w-[12%]',
        orderFieldName: "engagement"
    },
    {
        value: 'invoice',
        label: 'Invoice',
       // class: 'p-0 w-16 ',
        class: 'p-0 w-[7%]',
        orderFieldName: "invoice"
    },
    {
        value: 'action',
        label: 'Actions',
       // class: 'p-0 w-8 lg:w-16 ',
        class: 'p-0 w-[5%]',
    },
])
const defaultOrdering = ref(['-date'])

const serviceRecordFilters = reactive({
    type: {
        name: "type",
        label: "Type",
        value: null,
        defaultValue: 'Billed Services',
        options: [
            {value: null, label: "------------"},
            {value: 'Billed Services', label: 'Billed Services'},
            {value: 'PTO', label: 'PTO'},
            {value: 'Sick Leave', label: 'Sick Leave'},  
        ],
        widget: 'list-box',
        multiSelect: false
    },
    title: {
        name: "title",
        label: "Title",
        value: null,
        defaultValue: null,
        type: 'text',
        widget: 'input',
        multiSelect: false
    },
    calendarYear: {
        name: "calendar_year",
        label: "Calendar Year",
        value: null,
        defaultValue: null,
        options: [
            {value: null, label: "------------"},
            {value: '2023', label: '2023'},
            {value: '2024', label: '2024'},
        ],
        widget: 'list-box',
        multiSelect: false 
    },
    client: {
        name: "client",
        label: "Client",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setClientOptions.bind(null),
        awaitingOptions: false
    },
    engagement: {
        name: "engagement",
        label: "Engagement",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setEngagementOptions.bind(null),
        awaitingOptions: false
    },
    invoice: {
        name: "invoice",
        label: "Invoice",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setInvoiceOptions.bind(null),
        awaitingOptions: false
    },
    user: {
        name: "user",
        label: "User",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setUserOptions.bind(null),
        awaitingOptions: false
    },
    hasEngagement: {
        name: "has_engagement",
        label: "Has Engagement",
        value: null,
        defaultValue: null,
        options: [
            {value: null, label: "------------"},
            {value: 'has_engagement', label: "Has Engagement"},
            {value: 'no_engagement', label: "No Engagement"},
        ],
        widget: 'list-box',
        multiSelect: false
    },
    markedInvoiced: {
        name: "has_invoice",
        label: "Marked Invoiced",
        value: null,
        defaultValue: 'Not Invoiced',
        options: [
            {value: null, label: "------------"},
            {value: 'Not Invoiced', label: "Not Invoiced"},
            {value: 'In Progress', label: "In Progress"},
            {value: 'Invoiced', label: "Invoiced"},
        ],
        widget: 'list-box',
        multiSelect: false
    },
})

const serviceRecordBulkOperations = reactive({
    markInvoicingInProgress: {
        label: 'Mark Invoicing In Progress',
        modelPath: 'service_records',
        endpoint: 'bulk_mark_service_record_invoicing_in_progress',
        message: 'Click continue to mark invoicing in progress for selected sercice records.'
    },
    generateInvoice: {
        label: 'Generate Invoice Template',
        modelPath: 'service_records',
        endpoint: 'bulk_generate_invoice_template_from_service_records',
        message: 'Click continue to generate an invoice template for service records marked invoicing in progress.',
        filters: {
            client: serviceRecordFilters.client,
            engagement: serviceRecordFilters.engagement,
        },
        custom: true,
    },
    attachToInvoice: {
        label: 'Attach to Invoice',
        modelPath: 'service_records',
        endpoint: 'bulk_add_service_records_to_invoice',
        message: 'Select a client invoice to attach service records:',
        filters: {
            client: serviceRecordFilters.client,
            invoice: serviceRecordFilters.invoice,
        },
        custom: true,
    }
})


const menuOptions = reactive({
    selectAll: true,
    paginationArrows: true,
    totalResults: false,
    pageSize: true,
    refreshButton: true,
    filters: true,
    bulkOperations: true,
    addFormRows: true,
})

function setFilters(updatedFilters) {
    serviceRecordFilters.client = updatedFilters.client;
    serviceRecordFilters.engagement = updatedFilters.engagement;
    serviceRecordFilters.invoice = updatedFilters.invoice;
    serviceRecordFilters.user = updatedFilters.user;
    serviceRecordFilters.markedInvoiced = updatedFilters.markedInvoiced;
}

async function loadServiceRecords(fetchFilter='') {
    isLoading.value = true;
    const token = store.state.auth.token
    response.value = await getServiceRecords(token, fetchFilter);
    if(response.value.data) {
        totalHours.value = response.value.data.total_hours
        totalAmountDue.value = response.value.data.total_amount_due
    }
    isLoading.value = false;
}

async function setFilterOptions(filterName, queryParams='') {
    console.log('setFilterOptions', filterName)
    if(!serviceRecordFilters[filterName].awaitingOptions){
        if(serviceRecordFilters[filterName].setOptionsMethod){
            serviceRecordFilters[filterName].awaitingOptions = true
            serviceRecordFilters[filterName].setOptionsMethod(queryParams)
            setTimeout(() => {
                serviceRecordFilters[filterName].awaitingOptions = false;
            }, 30000)
        }
    }
}

function clearFilter(filterName) {
    if(Array.isArray(serviceRecordFilters[filterName].value)){
        serviceRecordFilters[filterName].value = []
    } else {
        serviceRecordFilters[filterName].value = null
    }
}

async function setClientOptions() {
    const token = store.state.auth.token
    const result = await getClients({token: token})
    serviceRecordFilters.client.options = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
            return {value: client.id, label: client.client_name}
        }
    )]
}

async function setEngagementOptions(clientId=null) {
    console.log('setEngagementOptions')
    let queryParams = '?compensation_type=consulting'
    if(clientId) {
        queryParams += `&client=${clientId}`
    }
    const token = store.state.auth.token
    const result = await getEngagements(token, queryParams)
    serviceRecordFilters.engagement.options = [{value: "clear", label: "------------"}, ...result.results.map((engagement) => {
            return {value: engagement.id, label: engagement.title}
        }
    )].sort((a, b) => {return a.label - b.label})
}   

async function setInvoiceOptions(clientId=null) {
    let queryParams = ''
    if(clientId) {
        queryParams = `?client=${clientId}`
    }
    const token = store.state.auth.token
    const result = await getInvoices(token, queryParams)
    serviceRecordFilters.invoice.options = [{value: "clear", label: "------------"}, ...result.results.map((invoice) => {
        return {value: invoice.id, label: `${invoice.engagement.client.client_name}: Invoice # ${invoice.invoice_number}`}
        }
    )].sort((a, b) => {return a.label - b.label})
}

async function setUserOptions() {
    const payload = {
        endpoint: 'list_users',
        group: 'management_group&group=dev_group',
        token: store.state.auth.token
    }
    const result = await getUsersByGroup(payload)
    serviceRecordFilters.user.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name}`}
            }
        )]    
}

setEngagementOptions()
setUserOptions()
</script>
<template>
  <div class="md:flex w-full">
    <div class="space-y-4 text-blue-600 md:sticky md:top-14 w-1/2 md:w-1/5 md:h-1/5 md:ml-5 md:mt-5 pl-5 break-words">
      <div class="cursor-pointer hover:underline">
        <a @click="scrollToSection('getting-started')">Getting Started</a>
      </div>
      <div class="cursor-pointer hover:underline">
        <a @click="scrollToSection('training-documents')">Training Documents</a>
      </div>
      <div class="cursor-pointer hover:underline">
        <a @click="scrollToSection('project-specific-instructions')">Project-Specific Instructions</a>
      </div>
      <div class="cursor-pointer hover:underline">
        <a @click="scrollToSection('faqs')">FAQs</a>
      </div>
    </div>

    <div>
      <div id="getting-started">
        <div class="ml-5 mr-20 mt-3 mb-3 font-bold">
          Getting Started
        </div>
        <research-guide-card v-for="(infoBlock, index) in gettingStartedInfoBlocks" :key="index" class="mb-3">
          <template v-slot:header>
            <div v-html="infoBlock.head"></div>
          </template>

          <template v-slot:body>
            <div v-html="infoBlock.body"></div>
          </template>
        </research-guide-card>
      </div>

      <div id="training-documents">
        <div class="ml-5 mr-20 mt-3 mb-3 font-bold">
          Training Documents
        </div>
        <research-guide-card v-for="(infoBlock, index) in trainingDocumentsInfoBlocks" :key="index" class="mb-3">
          <template v-slot:header>
            <div v-html="infoBlock.head"></div>
          </template>

          <template v-slot:body>
            <div v-html="infoBlock.body"></div>
          </template>
        </research-guide-card>
      </div>

      <div id="project-specific-instructions">
        <div class="ml-5 mr-20 mt-3 mb-3 font-bold">
          Project-Specific Instructions
        </div>
        <research-guide-card v-for="(infoBlock, index) in projectSpecificInstructionsInfoBlocks" :key="index"
          class="mb-3">
          <template v-slot:header>
            <div v-html="infoBlock.head"></div>
          </template>

          <template v-slot:body>
            <div v-html="infoBlock.body"></div>
          </template>
        </research-guide-card>
      </div>

      <div id="faqs">
        <div class="ml-5 mr-20 mt-3 mb-3 font-bold">FAQs</div>
        <research-guide-card v-for="(infoBlock, index) in faqsInfoBlocks" :key="index" class="mb-3">
          <template v-slot:header>
            <div v-html="infoBlock.head"></div>
          </template>

          <template v-slot:body>
            <div v-html="infoBlock.body"></div>
          </template>
        </research-guide-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ResearchGuideCard from "./ResearchGuideCard.vue";
import { getResearchGuide } from "@/services/ResearchGuide";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const researchGuideResults = ref([]);

async function loadResearchGuide() {
  let token = store.state.auth.token;
  const result = await getResearchGuide(token);
  researchGuideResults.value = result;

  sortIntoInfoBlocks();
  alpahetizedWithinOrdinals();
}

const gettingStartedInfoBlocks = ref([]);
const trainingDocumentsInfoBlocks = ref([]);
const projectSpecificInstructionsInfoBlocks = ref([]);
const faqsInfoBlocks = ref([]);

function sortIntoInfoBlocks() {
  for (let i = 0; i < researchGuideResults.value.length; i++) {
    let infoBlock = researchGuideResults.value[i];
    if (infoBlock.group == "getting_started") {
      gettingStartedInfoBlocks.value.push(infoBlock);
    } else if (infoBlock.group == "training") {
      trainingDocumentsInfoBlocks.value.push(infoBlock);
    } else if (infoBlock.group == "project") {
      projectSpecificInstructionsInfoBlocks.value.push(infoBlock);
    } else if (infoBlock.group == "faq") {
      faqsInfoBlocks.value.push(infoBlock);
    }
  }
}

const infoBlocksContainer = [
  gettingStartedInfoBlocks,
  trainingDocumentsInfoBlocks,
  projectSpecificInstructionsInfoBlocks,
  faqsInfoBlocks,
];

function alpahetizedWithinOrdinals() {
  for (let i = 0; i < infoBlocksContainer.length; i++) {
    let infoBlocks = infoBlocksContainer[i].value;

    for (let j = 0; j < infoBlocks.length - 1; j++) {
      const curInfoBlock = infoBlocks[j];
      const nextInfoBlock = infoBlocks[j + 1];
      if (curInfoBlock.ordinal === nextInfoBlock.ordinal) {
        const curHeader = removeHtmlTags(curInfoBlock.head);
        const nextHeader = removeHtmlTags(nextInfoBlock.head);
        if (curHeader.localeCompare(nextHeader) > 0) {
          [infoBlocks[j], infoBlocks[j + 1]] = [
            infoBlocks[j + 1],
            infoBlocks[j],
          ];
        }
      }
    }
  }
}

function removeHtmlTags(str) {
  return str.replace(/(<([^>]+)>)/gi, "");
}

loadResearchGuide();

function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId);
  const yOffset = -70;
  const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}
</script>
<template>
    <div>
        {{ crmActivityOutcome }}
    </div>
</template>

<script>
    export default {
        name: "crm-activity-outcome-cell"
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const crmActivityOutcome = computed(()=>{
        return props.company.crm_activity_outcome ? props.company.crm_activity_outcome : "--"
    })

</script>
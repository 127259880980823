<template>
  <div>
    <div class="ml-[15px]">
      <div
        class="flex flex-row justify-between items-center pb-0.5 border-b border-b-gray-400"
      >
        <h3 class="text-sm">Find and Merge Duplicates:</h3>
      </div>
      <div class="flex pt-2 pb-2 gap-3">
        <base-pill-button @click="setFindMergeText('Remove All UIUDS')"
          >Remove All UIUDS</base-pill-button
        >
        <base-pill-button
          @click="setFindMergeText('Find and Mark UUIDs via URL')"
          >Find and Mark UUIDs via URL</base-pill-button
        >
        <base-pill-button
          @click="setFindMergeText('Find and Mark UUIDs via OIN')"
          >Find and Mark UUIDs via OIN</base-pill-button
        >
        <base-pill-button @click="setFindMergeText('Batch Merge Duplicates')"
          >Batch Merge Duplicates</base-pill-button
        >
      </div>
    </div>
  </div>

  <ConfirmScreen
    v-if="isConfirmModalVisible"
    :message="confirmText"
    @close-confirm-modal="closeConfirmModal"
    @confirm-modal="confirmActionDispatch"
  ></ConfirmScreen>
</template>

<script setup>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import ConfirmScreen from "../../admin_page/UI/AdminConfirmScreen.vue";

  const store = useStore();
  const isConfirmModalVisible = ref(false);
  const confirmText = ref("");


  function setFindMergeText(text) {
    confirmText.value = text;
    isConfirmModalVisible.value = true;
  }

  function closeConfirmModal() {
    isConfirmModalVisible.value = false;
  }

  async function removeAllUIUDSCall() {
    const token = store.state.auth.token;
    alert("removeAllUIUDSCall");
    //   const result = await removeAllUUIDS(token);
    //   if (result.status == 200) {
    //     alert("Remove all UIUDS succeeded");
    //   } else {
    //     alert("Remove all UUIDS failed");
    //   }
  }

  async function findAndMarkUUIDSViaURLCall() {
    const token = store.state.auth.token;
    alert("findAndMarkUUIDSViaURLCall");
    //   const result = await findAndMarkUUIDSViaUrl(token);
    //   if (result.status == 200) {
    //     alert("Find and Mark UUIDS via URL succeeded");
    //   } else {
    //     alert("Find and Mark UUIDS via URL failed");
    //   }
  }

  async function findAndMarkUUIDSViaOINCall() {
    const token = store.state.auth.token;
    alert("findAndMarkUUIDSViaOINCall");
    //   const result = await findAndMarkUUIDSViaOin(token);
    //   if (result.status == 200) {
    //     alert("Find and Mark UUIDS via OIN succeeded");
    //   } else {
    //     alert("Find and Mark UUIDS via OIN failed");
    //   }
  }

  async function batchMergeDuplicatesCall() {
    const token = store.state.auth.token;
    alert("batchMergeDuplicatesCall");
    //   const result = await batchMergeDuplicates(token);
    //   if (result.status == 200) {
    //     alert("Batch Merge Duplicates succeeded");
    //   } else {
    //     alert("Batch Merge Duplicates failed");
    //   }
  }


  function confirmActionDispatch(message) {
  if (message === "Remove All UIUDS") {
    removeAllUIUDSCall();
  } else if (message === "Find and Mark UUIDs via URL") {
    findAndMarkUUIDSViaURLCall();
  } else if (message === "Find and Mark UUIDs via OIN") {
    findAndMarkUUIDSViaOINCall();
  } else {
    batchMergeDuplicatesCall();
  }
  closeConfirmModal();
}
</script>
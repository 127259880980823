import axios from 'axios';
import { BASE_URL } from './api';


export async function getCompanyCampaignChangeRequests(token, queryParams) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/company_campaign_change_requests/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function approveCompanyCampaignChangeRequest(token, id) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/company_campaign_change_requests/${id}/approve_request/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function rejectCompanyCampaignChangeRequest(token, id, data) {
    console.log('data in services', data)
    return axios({
        method: 'put',
        url: `${BASE_URL}/company_campaign_change_requests/${id}/reject_request/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function performBulkAction(endpoint, data, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/company_campaign_change_requests/${endpoint}/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        },
        data: data
    })
    .then(response => response)
    .catch(error => error)
}
import axios from 'axios';
import { BASE_URL } from './api';

export async function getPrimeContracts(token, url) {
    // console.log('getting contracts', payload)
    return axios({
        method: 'get',
        url: `${url}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return {
            "next": response.data.next,
            "previous": response.data.previous,
            "contracts": response.data.results
        };
    }).catch((error) => {
        console.log(error)
    });
}

export async function getSubContracts(token, url) {
    // console.log('getting contracts', payload)
    return axios({
        method: 'get',
        url: `${url}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return {
            "next": response.data.next,
            "previous": response.data.previous,
            "contracts": response.data.results
        };
    }).catch((error) => {
        console.log(error)
    });
}

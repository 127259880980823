<template>
    <div>
        {{acctMgrNotes}}
    </div>
</template>

<script>
    export default {
        name: "account-manager-notes-cell"
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const acctMgrNotes = computed(() => {
        return props.company.account_manager_notes ? props.company.account_manager_notes : "--"
    })

</script>
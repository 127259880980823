<template>
    <base-table-row>
        <base-table-cell class="w-1/8">{{ document.ordinal }}</base-table-cell>
        <base-table-cell class="w-1/8">{{ document.group }}</base-table-cell>
        <base-table-cell class="w-1/4">{{ document.head }}</base-table-cell>
        <base-table-cell>{{ bodyPreview(document.body) }}</base-table-cell>
        <base-table-cell>
            <base-button @click="editTrainingDocument">Edit</base-button>
            <base-button @click="checkArchiveTrainingDocument(document)">Archive</base-button>
        </base-table-cell>
    </base-table-row>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import { archiveTrainingDocument } from "@/services/TrainingDocuments";

const store = useStore()
const props = defineProps({
    document: Object
})
const emit = defineEmits(['edit-training-document', 'refetch-training-documents'])

function editTrainingDocument() {
    emit('edit-training-document', props.document)
}



function checkArchiveTrainingDocument(trainingDocument) {
    const trainingDocumentHeadNoHtml = removeHtmlTags(trainingDocument.head)
    if (!confirm("You are about to archive the ''" +
        trainingDocumentHeadNoHtml +
        "'' training document. Are you sure you want to proceed?")) {
        return;
    } else {
        archiveCurrentTrainingDocument(trainingDocument)
    }
}

async function archiveCurrentTrainingDocument(trainingDocument) {
    let token = store.state.auth.token
    trainingDocument.status = 'inactive'
    await archiveTrainingDocument(trainingDocument.id, trainingDocument, token)
    emit("refetch-training-documents")
}

function removeHtmlTags(str) {
    return str.replace(/(<([^>]+)>)/gi, "")
}

function bodyPreview(body) {
    const maxLength = 225
    if (body.length > maxLength) {
        return body.substring(0, maxLength) + "..."
    } else {
        return body
    }
}

</script>
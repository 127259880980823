<template>
    <div 
    class="flex flex-col space-y-1 text-gray-800  w-full border-t border-gray-400 my-1">
        <CalculatedCRMSummaryRow 
            v-for="crmAction in currentCampaignActions"
            :key="crmAction.id"
            :crmAction="crmAction"
        />
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import CalculatedCRMSummaryRow from './CalculatedCRMSummaryRow.vue';

const props = defineProps(['crmActions', 'currentCampaign'])

const currentCampaignActions = computed(() => {
    if(props.currentCampaign) {
        if(props.crmActions) {
            return props.crmActions.filter(action => {return action.campaign_id == props.currentCampaign}).sort(function(a,b) {
        return new Date(b.resolved_date) - new Date(a.resolved_date)
    })
        }
    } 
    return []
})

</script>
<template>
  <div class="ml-5 mr-20">
    <div class="bg-[#f8f8f8] py-2 px-2 font-medium rounded-t-lg">
      <slot name="header"></slot>
    </div>
    <div class="bg-white pt-4 pb-2 px-2 rounded-b-lg">
      <slot name="body"></slot>
    </div>
  </div>
</template>


<script setup>
</script>
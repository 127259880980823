<template>
  <div class="">
    <div class="tab__header">
      <a href="#"
         class="tab__link p-2 rounded-lg bg-dark-primary
                hover:text-white hover:bg-dark-secondary no-underline text-white
                border-b-2 border-white flex justify-between"
         @click.prevent="active = !active">
        <strong>
          <slot name="title"></slot>
        </strong>
        <span class="down-Arrow" v-show="!active">&#9660;</span>
        <span class="up-Arrow" v-show="active">&#9650;</span>
      </a>
    </div>
    <transition
      v-on:before-enter="beforeEnter" v-on:enter="enter"
      v-on:before-leave="beforeLeave" v-on:leave="leave"
      >
      <div class="tab__content p-2 pb-6 text-justify transition-all duration-200 ease-out overflow-hidden rounded-lg"  v-show="active">
            <slot name="accordian-body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseAccordian.vue",
  props: ['startCollapsed', 'isActive'],
  data() {
    return {
      active: true,
    }
  },
  methods: {
    beforeEnter: function(el) {
      el.style.height = '0';
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave: function(el) {
      el.style.height = '0';
    }
  },
  created() {
    if(this.startCollapsed){
      this.active = false
    }
  },
  watch: {
    isActive(){
      if(this.isActive){
        this.active = true
      } else if (!this.isActive) {
        this.active = false
      }
    }
  }

}
</script>

<style scoped>
  body{
    background-color:#f1f5f8;
  }
</style>

<!--Source-->
<!--https://vuejsexamples.com/an-accordion-using-vuejs-and-tailwind-css/-->
<!--https://codepen.io/duaneblake/pen/XOorrj-->

<!--
SAMPLE USAGE
      <base-accordian>
        <template #title>
           <div>Test</div>
        </template>
        <template #accordian-body>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid assumenda autem, consequuntur debitis
            dicta dignissimos distinctio eos facilis hic ipsum non nostrum optio porro possimus praesentium quis
            recusandae repellendus tempore?
          </div>
        </template>
      </base-accordian>


-->
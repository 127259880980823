<template>
    <div class="w-full h-[70vh] overflow-scroll z-30">
      <h2 class="text-lg font-bold">
        <slot name="table-title"></slot>
      </h2>
      <div class="">
        <table
          v-if="alignment === 'center'"
          class="w-full text-center bg-white table-auto overflow-scroll"
        >
          ">
          <thead class="bg-blue-700 top-0">
            <slot name="table-header"></slot>
          </thead>
          <tbody class="overflow-y-scroll">
            <slot name="table-body"></slot>
          </tbody>
        </table>
        <table
          v-else-if="alignment === 'right'"
          class="w-full text-right bg-white table-auto"
        >
          <thead class="bg-amber-700 sticky top-0">
            <slot name="table-header"></slot>
          </thead>
          <tbody class="overflow-y-scroll">
            <slot name="table-body"></slot>
          </tbody>
        </table>
        <table v-else class="w-full text-left bg-white table-auto">
          <thead class="relative top-0 left-0 right-0">
            <slot name="table-header"></slot>
          </thead>
          <tbody class="overflow-y-scroll">
            <slot name="table-body"></slot>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ReferralTable.vue",
    props: ["alignment"],
    mounted() {
      var thElm;
      var startOffset;
  
      Array.prototype.forEach.call(
        document.querySelectorAll("table th"),
        function (th) {
          var grip = document.createElement("div");
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });
  
          th.appendChild(grip);
        }
      );
  
      document.addEventListener("mousemove", function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + "px";
        }
      });
  
      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    },
  };
  </script>
  
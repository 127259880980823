<template>
    <div class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] w-full flex justify-center items-center z-[100]">
      <div class="flex-col bg-white shadow-lg-black p-3 max-w-3xl rounded-md min-h-1/4  overflow-x-auto text-center ">
        <header class="flex justify-between items-center relative p-15 pl-2 border-solid border-b border-gray-200">
          <slot name="header">
          </slot>
        </header>
        <section class="p-2 pt-3 max-h-[75vh]">
          <slot name="body">
          </slot>
         </section>
        <footer class="flex p-15 h-5 justify-center border-solid border-t border-gray-200">
        </footer>
      </div>
    </div>
  </template>

<script>

export default {
    name: 'base-dialog-box',
    setup() {
    }
};
</script>
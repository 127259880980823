<template>
    <tr class="text-xs sticky bottom-0 bg-gray-100  text-[0.72rem]">
        <td colspan="2" class="bg-gray-200 p-0 h-8">
            <div class="bg-gray-100 rounded-bl-xl border-l border-b border-gray-300 py-2 h-full w-full">
                <div class="w-full pl-5 h-full">
                    <div v-if="numSelectedResults">
                        {{  numSelectedResults }} Selected
                    </div>
                </div>
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full h-full bg-gray-100 border-b border-gray-300">
                
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full h-full bg-gray-100 border-b border-gray-300">
                
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full h-full bg-gray-100 border-b border-gray-300 py-2 flex justify-end pr-0.5">
                Total Hours: 
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full border-gray-300 border-b h-full p-2 bg-gray-100">
                <div v-if="aggregateTotalHours">
                    {{ aggregateTotalHours }}
                </div>
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full h-full bg-gray-100 border-b border-gray-300 py-2 flex justify-end pr-0.5">
                Total Due: 
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full  border-gray-300 border-b h-full p-2 bg-gray-100">
                <div v-if="aggregateTotalAmountDue">
                    {{ aggregateTotalAmountDue }}
                </div>
            </div>
        </td>
        <td colspan="1" class="bg-gray-200 p-0 h-8 w-full">
            <div class="w-full h-full bg-gray-100 border-b border-gray-300">
                
            </div>
        </td>
        <td colspan="3" class="bg-gray-200 p-0 h-8 w-full">
            <div class="bg-gray-100 rounded-br-xl border-b border-r border-gray-300 py-2 pr-3 h-full w-full flex justify-end">
                <div v-if="viewingFromNbr==viewingToNbr" class="whitespace-nowrap">
                    Showing {{ viewingFromNbr }} Results ({{ count }} Total Results)
                </div>
                <div v-else class="whitespace-nowrap">
                    Showing {{ viewingFromNbr }}-{{ viewingToNbr }} Results ({{ count }} Total Results)
                </div>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps([
    'viewingFromNbr',
    'viewingToNbr',
    'count',
    'selectedResults',
    'totalHours',
    'totalAmountDue',
])


const numSelectedResults = computed(() => {
    if(props.selectedResults) {
        return props.selectedResults.length
    } else {
        return null
    }
})

const aggregateTotalHours = computed(() => {
    if(props.totalHours) {
        return props.totalHours.toFixed(2)
    } else {
        return null
    }
})

const aggregateTotalAmountDue = computed(() => {
    if(props.totalAmountDue) {
        return `$${props.totalAmountDue.toFixed(2)}`
    } else {
        return null
    }
})

</script>
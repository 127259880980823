import axios from "axios";
import { BASE_URL } from "./api";

export async function getLogs(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/admin/${payload.endpoint ? payload.endpoint : ""}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      return {
        logs: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        page_size: response.data.page_size,
        num_pages: response.data.num_pages,
      };
    })
    .catch((error) => {
      throw error;
    });
}

export async function removeAllUUIDS(token) {
  return axios({
    method: "post",
    url: `${BASE_URL}/dev/remove_all_uuids`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function findAndMarkUUIDSViaUrl(token) {
  return axios({
    method: "post",
    url: `${BASE_URL}/dev/find_and_mark_uuids_via_url`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function findAndMarkUUIDSViaOin(token) {
  return axios({
    method: "post",
    url: `${BASE_URL}/dev/find_and_mark_uuids_via_oin`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function batchMergeDuplicates(token) {
  return axios({
    method: "post",
    url: `${BASE_URL}/dev/batch_merge_duplicates`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}




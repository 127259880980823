<template>
    <base-dblclick-input
    modelType="contact"
    :model-id="companyContact.id"
    field-name="personal_phone"
    :field-value="companyContact.personal_phone"
    inputType="tel"
    @refetch="fetchNewCompanies"
  >
    <slot name="icon"></slot>
  </base-dblclick-input>
</template>

<script>
  export default {
      name: "company-contact-phone-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
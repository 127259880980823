import axios from 'axios';
import { BASE_URL } from './api';


export async function createClientPost(token, data) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/client_posts/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}


export async function deleteClientPost(token, postId) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/client_posts/${postId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
<template>
    <div class="h-full">
        <div class="flex flex-row h-fit justify-between items-center">
                <CompanyQuickLinks :company="company" class="pl-1" />
                <HeadlessPopoverClick :hide-chevron="true" class="h-fit">
                    <template #popover-title> <div class="-m-2 border bg-white bg-opacity-40 text-black px-1 rounded-lg text-xs">?</div></template>
                    <template #popover-body>
                        <div class="bg-gray-100 p-2 border border-gray-300 rounded-b-lg w-96">
                            <div class="border-b border-gray-400 pb-1 mb-1.5">
                                Helpful Tips:
                            </div>
                            <div class="flex flex-col space-y-2 h-[400px] overflow-y-scroll">
                                <div class="flex">
                                    How to use this form:
                                </div>
                                <div class="flex">
                                    To begin, click 'Add Collection Research Note' to create a new collection research note form.
                                </div>
                                <div class="flex">
                                    If a form already exists, the fields will be displayed below. Double-click each field to edit.
                                </div>
                                <div class="flex">
                                    Each phase of the contact research process has it's own field. Fill in these fields as you are researching contacts to record pertinent information.
                                </div>
                                <div class="flex">
                                    Do not delete information in these fields. Record new information by adding your initials and date after each note.
                                </div>
                                <div class="flex">
                                    For example, if I find two owners in ZoomInfo and I'm not sure which is the current owner, I would mark 'John Smith and Jane Doe both listed as owner in ZoomInfo - DM 10/01/23' to the ZoomInfo Research Notes field. Then, 2 days later Patrick discovers that Jane is the current owner, he would update the field with his own note 'Jane Doe confirmed as owner, pulled ZI data. - PA 10/03/23' directly after my note. This is to keep a running history of contact information as we find and validate new data.
                                </div>

                            </div>
                        </div>
                    </template>
                </HeadlessPopoverClick>
        </div>
        <div class="pt-1 w-full h-fit">
            <BaseTabBar 
                :tabList="tabList"
                :selectedColor="selectedColor"
                :notSelectedColor="notSelectedColor"
                :hoverColor="hoverColor"
                @set-page="setView"
            />
        </div>
        <div v-show="displayContactResearchPosts()" class="h-full">
            <CompanyResearchPosts
                :company="company"
                post-type="contact_research"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <div v-show="displayAcquisitionResearchPosts()" class="h-full">
            <CompanyAcquisitionResearch
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <div v-show="displayCollectionResearchPosts()" class="h-full">
            <CompanyResearchPosts
                :company="company"
                post-type="collection_research"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import BaseTabBar from '@/components/BaseTabBar.vue';
import CompanyResearchPosts from '@/components/research-post/CompanyResearchPosts.vue';
import CompanyAcquisitionResearch from '../research-post/CompanyAcquisitionResearch.vue';
import HeadlessPopoverClick from "@/components/HeadlessPopoverClick.vue";
import CompanyQuickLinks from '@/components/CompanyQuickLinks.vue';

const props = defineProps(['company'])
const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies() {
    emit('refetch-companies')
}

const tabList = ref([
    {tabName: 'Contact Research', isSelected: true},
    {tabName: 'Acquisition Research', isSelected: false},
    {tabName: 'Collection Review', isSelected: false},
])

const selectedColor = 'white';
const notSelectedColor = 'gray-100';
const hoverColor = 'slate-50';

function setView(tabName) {
    if (tabName == 'Contact Research') {
        showContactResearchPosts();
    } else if (tabName == 'Collection Review') {
        showCollectionResearchPosts();
    } else if (tabName == 'Acquisition Research') {
        showAcquisitionResearchPosts();
    } else {
        console.log("Error")
    }
}

const isContactResearchVisible = ref(true)
const isCollectionResearchVisible = ref(false)
const isAcquisitionResearchVisible = ref(false)

function showContactResearchPosts() {
    isContactResearchVisible.value = true;
    isCollectionResearchVisible.value = false;
    isAcquisitionResearchVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Contact Research"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showCollectionResearchPosts() {
    isContactResearchVisible.value = false;
    isCollectionResearchVisible.value = true;
    isAcquisitionResearchVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Collection Review"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showAcquisitionResearchPosts() {
    isContactResearchVisible.value = false;
    isCollectionResearchVisible.value = false;
    isAcquisitionResearchVisible.value = true;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Acquisition Research"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function displayContactResearchPosts(){
    if(
        isContactResearchVisible.value && 
        !isCollectionResearchVisible.value &&
        !isAcquisitionResearchVisible.value
        ) {
        return true
    } else {
        return false
    }
}

function displayCollectionResearchPosts(){
    if(
        !isContactResearchVisible.value && 
        isCollectionResearchVisible.value &&
        !isAcquisitionResearchVisible.value
        ) {
        return true
    } else {
        return false
    }
}

function displayAcquisitionResearchPosts(){
    if(
        !isContactResearchVisible.value && 
        !isCollectionResearchVisible.value &&
        isAcquisitionResearchVisible.value
        ) {
        return true
    } else {
        return false
    }
}

</script>
<template>
    <div>
        <SourceCell :company="company" />
        <SourceOwnerCell :company="company" />
    </div>
</template>

<script>
    export default {
        name: "source-source-owner-cell"
    }
</script>

<script setup>
import { defineProps } from 'vue';
import SourceCell from '../company-cell-components/SourceCell.vue';
import SourceOwnerCell from '../company-cell-components/SourceOwnerCell.vue';

    const props = defineProps({
        company: Object
    })
</script>
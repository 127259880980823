<template>
    <div class="text-base items-center pr-2.5 pl-2 mb-1 whitespace-nowrap truncate text-accent-primary  hover:text-dark-primary hover:underline" ref="naicsCodesElement" @mouseover="showPopover" @mouseleave="hidePopover">
       NAICS Codes
        <!-- <span  class="text-sm">{{ companySources ? companySources: "(None)" }}</span> -->
    </div>
    <base-popover-hover-small
        class="w-fit"
        v-if="isPopoverVisible"
        :xPosition="xPosition"
        :yPosition="yPosition"
    >
        <template #title>
           <span class="text-sm">NAICS Codes:</span>
        </template>
        <template #body>
            <div v-if="naicsCodes" class="w-full">
                <div v-for="naicsCode in naicsCodes" :key="naicsCode.id" class="py-1 border-b border-gray-300 w-full">
                    {{ naicsCode.code }} - {{ naicsCode.title }}
                </div>
            </div>
            <div v-else class="w-full">
                <div class="py-1 border-gray-300 w-full">
                    None
                </div>
            </div>
        </template>
    </base-popover-hover-small>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';

const props = defineProps(['company'])

const isPopoverVisible = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const naicsCodesElement = ref(null)

const naicsCodes = computed(() => {
    if(props.company.naics_codes.length > 0){
        return props.company.naics_codes
    } else {
        return null
    }
})

function showPopover() {
    isPopoverVisible.value = true;
    xPosition.value = naicsCodesElement.value.getBoundingClientRect().left
    yPosition.value = naicsCodesElement.value.getBoundingClientRect().bottom
}

function hidePopover() {
    isPopoverVisible.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}


</script>
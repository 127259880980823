<template>
  <div
    v-on-escape="close"
    class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] h-screen min-h-min flex justify-center items-start z-10 overflow-x-auto overscroll-none"
    tabindex="-1"
  >
    <div
      class="flex-col min-w-[1100px] bg-white shadow-lg-black p-3 lg:w-4/5 min-h-[75vh] rounded-lg mt-[7rem] mb-[3.5rem] overscroll-none"
    >
      <div>
        <header
          class="flex relative justify-between items-center p-15 pl-2 border-solid border-b border-gray-200 mb-0"
        >
          <slot name="header"> </slot>
          <base-button-negative
            class="mb-3 px-2 font-bold"
            type="button"
            @click="close"
          >
            X
          </base-button-negative>
        </header>
      </div>
      <section class="overscroll-none">
        <slot name="body"> </slot>
      </section>

      <footer class=""></footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageModal.vue",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template>
    <div class="modal-body relative  w-full flex flex-col h-full px-2" :class="{'cursor-wait' : isLoading || postsLoading}">
        <form @submit.prevent="submitForm">
            <div class="flex flex-col items-center">
                <div class="w-full">
                    <!-- <h3 class="underline text-base">Invoice Info:</h3> -->
                    <div class="grid grid-cols-3 gap-3 p-2 w-full">
                        <div class="flex flex-col">
                            <label for="emailTo">
                                Email To:
                            </label>
                            <input type="email" multiple v-model="invoiceReminderData.email_to" class="border border-dark-primary rounded-md p-1 w-full">
                        </div>
                        <div class="flex flex-col">
                            <label for="emailCC">
                                CC:
                            </label>
                            <input type="email" multiple v-model="invoiceReminderData.cc" class="border border-dark-primary rounded-md p-1 w-full">
                        </div>
                        <div class="flex flex-col">
                            <label for="emailSubject">
                                Subject:
                            </label>
                            <input type="text" v-model="invoiceReminderData.subject" class="border border-dark-primary rounded-md p-1 w-full">
                        </div>
                        <div class="flex flex-col col-span-3">
                            <label for="emailBody">
                                Email Body:
                            </label>
                            <div class="flex items-center">
                                <textarea class="border border-dark-primary rounded-md p-1 w-full" v-model="invoiceReminderData.email_body" name="" id="" cols="30" rows="10"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center w-full p-2">
                        <div v-if="isLoading" role="status" class="pl-3">
                            <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#d3d3d3"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#2E9CCA"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <base-button-affirmative v-else>Send</base-button-affirmative>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'invoice-form'
}
</script>

<script setup>
import { useStore } from 'vuex';
import {ref, defineProps, defineEmits, reactive} from 'vue';
import { sendInvoiceReminder } from '@/services/Invoice';
import { useToast } from 'vue-toast-notification';
import { deleteAccountingPost } from '@/services/AccountingPost'
import InvoiceAccountingPost from './InvoiceAccountingPost.vue';

const store = useStore()

const $toast = useToast()

const props = defineProps({
    invoice: Object
})

function isPostOwner(postOwnerId) {
    return postOwnerId === store.state.auth.user.id
}

const emit = defineEmits(['refetch-invoices', 'close'])


const invoiceReminderData = reactive({
    cc: ["norman@sheddcapital.com", "scott.admin@sheddcapital.com", ...props.invoice.engagement.client.accounts_payable_ccs],
    subject: "Reminder from Shedd Capital LLC",
    email_body: props.invoice.reminder_email_text,
    email_to: props.invoice.engagement.client.accounts_payable_emails
})


const isLoading = ref(false)
const postsLoading = ref(false)

async function submitForm() {
    isLoading.value = true
    const token = store.state.auth.token
    if(!Array.isArray(invoiceReminderData.email_to)){
        invoiceReminderData.email_to = invoiceReminderData.email_to.split(",")
    }
    if(!Array.isArray(invoiceReminderData.cc)){
        invoiceReminderData.cc = invoiceReminderData.cc.split(",")
    }
    const data = JSON.stringify(invoiceReminderData)
    const result = await sendInvoiceReminder(props.invoice.id, data, token)
    if(result.status === 201){
        $toast.open({
                    message: `Successfully sent reminder for ${props.invoice.invoice_number}`,
                    type: 'success',
                    duration: 5000
                })
    } else {
        $toast.open({
                    message: `Error, could not send reminder for ${props.invoice.invoice_number}`,
                    type: 'error',
                    duration: 5000
                })
    }
    emit('refetch-invoices')
    isLoading.value = false


}



const returnDate = (inputDate) => {
        let date = new Date(inputDate)
        date.setDate(date.getDate() + 1)
        return date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit'})
    }


</script>

<template>
    <base-dblclick-input
      class="inline"
      model-type="contact"
      :model-id="companyContact.id"
      field-name="preferred_name"
      :field-value="companyContact.preferred_name"
      input-type="text"
      @refetch="fetchNewCompanies"
    ></base-dblclick-input>
  </template>
  
  <script>
    export default {
        name: "company-contact-preferred-name-cell"
    }
  
  </script>
  
  <script setup>
  import {defineProps, defineEmits} from "vue";
  
    const props = defineProps({
        companyContact: Object
    })
  
    const emit = defineEmits(['refetch-companies'])
  
    function fetchNewCompanies () {
        emit('refetch-companies')
    }
  
  </script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getTrainingDocuments(token) {
    return axios({
        method: "get",
        url: `${BASE_URL}/training_documents`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => {
        return response.data
    })
    .catch(error => console.error(error))

}

export async function createNewTrainingDocument(data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/training_documents/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function updateTrainingDocument(trainingDocumentID, data, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/training_documents/${trainingDocumentID}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function archiveTrainingDocument(trainingDocumentID, data, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/training_documents/${trainingDocumentID}/`,
        headers: { 
          'Authorization': `Token ${token}`, 
          'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
  }
<template>  
    <div class="flex flex-col items-center">
        <ClientFeedbackCell :company="company" class="mb-1" @refetch-companies="fetchNewCompanies"/>
        <MarkClientFeedbackButtonCell :company="company" @refetch-companies="fetchNewCompanies"/>
        <base-pill>Notes:</base-pill> <ClientMeetingNotesCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>
</template>

<script>
    export default {
        name: "client-feedback-client-feedback-buttons-cell"
    }
</script>

<script setup>  
import { defineProps, defineEmits } from 'vue';
import ClientFeedbackCell from '../company-cell-components/ClientFeedbackCell.vue';
import MarkClientFeedbackButtonCell from '../company-cell-components/MarkClientFeedbackButtonCell.vue';
import ClientMeetingNotesCell from '../company-cell-components/ClientMeetingNotesCell.vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
<template>
  <div class="h-full bg-white">
    <div class="relative h-full">
      <div
        class="absolute w-full h-full cursor-default rounded-b-md bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-xs"
      >
        <input
          v-model="search"
          class="absolute inset-0 opacity-0"
          type="text"
          placeholder="---------"
        />
        <div>
          <span class="block truncate w-full" v-if="label"> {{ label }}</span>
          <span v-else class="text-gray-500"> {{ props.placeholder }} </span>
        </div>
      </div>
    </div>
    <div class="shadow-md">
      <base-infinite-scroll
        class="rounded-b-md"
        v-if="dropDownVisibleObject.visible"
        @selected-item="setItemFilter"
        :options="list"
        :selected="selected"
        :inputSearch="textObject"
      ></base-infinite-scroll>
    </div>
  </div>
</template>

<script setup>
// imports

import { defineProps, ref, defineEmits, watch } from "vue";

// constants/variables

const props = defineProps([
  "list",
  "dropDownVisibleObject",
  "placeholder",
  "resetObject",
]);
const emit = defineEmits(["set-item-filter"]);

const label = ref();
const search = ref("");
const selected = ref("");
const textObject = ref({
  text: search,
});

// watchers

watch(props.dropDownVisibleObject, () => {
  search.value = "";
});

watch(props.resetObject, () => {
  label.value = "";
  selected.value = "";
});

// functions

function setItemFilter(inputLabel) {
  label.value = inputLabel;
  selected.value = inputLabel;
  search.value = inputLabel;
  emit("set-item-filter", inputLabel);
}
</script>

<template>
    <div class="flex flex-col justify-between  w-full">
        <div class="overflow-y-scroll flex-1  w-full">
            <slot name="body"></slot>
        </div>
        <div class="shrink-0  w-full border-t border-gray-300">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script setup>
</script>
<template>
    <div>
        <base-table>
            <template #table-header>
                <base-table-header v-for="(header, index) in tableHeaders" :key="index" :header="header" class="py-3 text-lg">
                    {{ header }}
                </base-table-header>
            </template>
            <template #table-body>
                <TrainingDocumentsRow class="text-xl" v-for="(document, index) in documents" :key="index" :document="document"
                    @edit-training-document="editTrainingDocument" @refetch-training-documents="refetchTrainingDocuments" />
            </template>
        </base-table>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import TrainingDocumentsRow from "./TrainingDocumentsRow.vue";

const props = defineProps({
    tableHeaders: Array,
    documents: Array
})
const emit = defineEmits(['edit-training-document', 'refetch-training-documents'])

function editTrainingDocument(document) {
    emit('edit-training-document', document)
}

function refetchTrainingDocuments() {
    emit('refetch-training-documents')
}
</script>
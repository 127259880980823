import axios from "axios";
import { BASE_URL } from "./api";

export async function getCapabilities(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/capabilities/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function addCompanyCapability(payload) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/capabilities/${payload.capability_id}/add_company_capability/`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
        data: payload.data
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });    
}

export async function removeCompanyCapability(payload) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/capabilities/${payload.capability_id}/remove_company_capability/`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
        data: payload.data
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });    
}
<template>

    <form @submit.prevent="fetchPage">
        <input
            v-model="pageToSet"
            class="h-full pl-1 w-10 rounded-md bg-white border border-gray-400"
            type="number" :max="maxPage">
    </form>

</template>

<script setup>
import {defineProps, defineEmits, computed, ref, watch} from "vue"


    const props = defineProps({
        maxPage: Number,
        currentPage: Number
    })

    const pageToSet = ref('')

    const emit = defineEmits(['fetch-page'])

    // function setPage(){
    //     pageToSet.value = props.currentPage
    // }


    function fetchPage(event){
        // console.log('event', props.currentPage)
        if(pageToSet.value > 0){
            emit('fetch-page', pageToSet.value)
        }
        else {
            emit('fetch-page', 1)
        }
    }

    // setPage()
    watch(props, () => {
        pageToSet.value = props.currentPage
    })


</script>
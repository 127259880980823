<template>
    <div class="w-full h-full">
        <div class="w-full my-2 mx-auto px-2 rounded-b-lg max-w-[1200px] bg-white">
            <div class="w-full h-full space-y-2 p-2">
                <div class="grid grid-cols-2 space-x-2 pb-2 py-0.5">
                    <div class="flex space-x-2 items-center">
                        <div class="">
                            Category:
                        </div>
                        <BaseListBoxRounded
                            class="h-8 border border-gray-400 rounded-md w-full"
                            placeholder=""
                            :modelValue="researchPostData.post_category"
                            :options="postCategoryOptions"
                            :multiple="false"
                            @update:modelValue="setPostCategory"
                            >
                        </BaseListBoxRounded>
                    </div>
                    <div class="flex space-x-2 items-center" v-if="postType=='collection_research'">
                        <div class="">
                            Collection:
                        </div>
                        <BaseListBoxRounded
                            class="h-8 border border-gray-400 rounded-md w-full"
                            placeholder=""
                            :modelValue="researchPostData.collection_tag"
                            :options="collectionOptions"
                            :multiple="false"
                            @update:modelValue="setCollection"
                            >
                        </BaseListBoxRounded>
                    </div>
                </div>
                <div class="flex w-full">
                    <textarea type="text" required class="p-1 border h-full w-full inline border-gray-400 rounded-md" rows="4" v-model="researchPostData.post_body"></textarea>
                </div>
                <div class="grid grid-cols-3 w-full justify-between space-x-2 px-2">
                    <div class="w-full "></div>
                    <div class="flex w-full justify-center items-center space-x-2">
                        <input type="checkbox" id="flag" class="align-middle" :checked="flagged" @change="flagAsDone"/>
                        <div class="whitespace-nowrap">
                            Flag Done 
                        </div>
                    </div>
                    <div class="flex w-full justify-end space-x-2">
                        <base-button-affirmative @click="addContactResearchPost">Post</base-button-affirmative>
                        <base-button-negative @click="closeForm">Cancel</base-button-negative>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import BaseListBoxRounded from '@/components/BaseListBoxRounded.vue';
import { createResearchPost } from '@/services/ResearchPost';
import { useStore } from 'vuex';

const props = defineProps(['company', 'postType', 'postCategory'])
const emit = defineEmits(['refetch-research-posts', 'refetch-companies', 'close-form'])

const store = useStore()

const researchPostData = ref({
    company_id: props.company.id,
    post_type: props.postType,
    post_category: props.postCategory,
    collection_tag: null,
    collection_tag_name: null,
    post_body: null,
    flag: null,
    image_attachment: null,
})

function closeForm(){
    emit('close-form')
}

const flagged = computed(()=> {
    if(researchPostData.value.flag) {
        return true
    } else {
        return false
    }
})

const postCategoryOptions = computed(() => {
    if(props.postType == 'contact_research') {
        return [
            {value: null, label: "-------"},
            {value: "Website Research", label: "Website Research"},
            {value: "Zoominfo Research", label: "Zoominfo Research"},
            {value: "LinkedIn Research", label: "LinkedIn Research"},
            {value: "Buzzfile Research", label: "Buzzfile Research"},
            {value: "CAGE Research", label: "CAGE Research"},
            {value: "Open Corporates Research", label: "Open Corporates Research"},
            {value: "USA Spending Research", label: "USA Spending Research"},
            {value: "DACIS Research", label: "DACIS Research"},
            {value: "Hunter.io Research", label: "Hunter.io Research"},
            {value: "Rocket Reach Research", label: "Rocket Reach Research"},
            {value: "Signal Hire Research", label: "Signal Hire Research"},
            {value: "Whois Research", label: "Whois Research"},
            {value: "D&B Research", label: "D&B Research"},
            {value: "Other Research", label: "Other Research"},
            {value: "Get Cell Phone Number", label: "Get Cell Phone Number"},
            {value: "Biographical Info", label: "Biographical Info"},
            {value: "Email Verification", label: "Email Verification"},
            {value: "Candidate Contacts", label: "Candidate Contacts"},
            {value: "Need Help", label: "Need Help"},
        ]
    } else if (props.postType == 'collection_research') {
        return [
            {value: null, label: "-------"},
            {value: "Interesting", label: "Interesting"},
            {value: "Not Interesting", label: "Not Interesting"},
        ]
    } else {
        return []
    } 
})

const collectionOptions = computed(() => {
    if(props.company.collection_tags) {
        let collectionTags = props.company.collection_tags
        return [{value:null, label: "------------"}, ...collectionTags.map((collectionTag) => {
            return {value: collectionTag.collection_tag.id, label: collectionTag.collection_tag.collection_tag_name}
        })]
    } else {
        return [{value: null, label: "------------"}]
    }
})

function setPostCategory(value, label=null) {
    researchPostData.value.post_category = value;
}

function setCollection(value) {
    researchPostData.value.collection_tag = value;
    setCollectionName(value)
}

function setCollectionName(collectionId) {
    for(let collectionOption of collectionOptions.value) {
        if(collectionId == collectionOption.value) {
            researchPostData.value.collection_tag_name = collectionOption.label
        }
    }
}

function flagAsDone() {
    if(researchPostData.value.flag) {
        researchPostData.value.flag = null
    } else {
        researchPostData.value.flag = 'Done'
    }
}

async function addContactResearchPost(){
    const token = store.state.auth.token
    await createResearchPost(researchPostData, token)
    emit('refetch-research-posts')
    emit('refetch-companies')
    emit('close-form')
}

</script>
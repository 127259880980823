<template>
    <base-dblclick-textarea
        model-type="company"
        :model-id="company.id"
        field-name="out_reach_notes"
        :field-value="company.out_reach_notes"
        @refetch="fetchNewCompanies"
    ></base-dblclick-textarea>
</template>

<script>
export default {
    name: "outreach-notes-simple-cell"
}

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
    company: Object
})

const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies () {
    emit('refetch-companies')
}
</script>
<template>
    <div class="bg-dark-primary print:hidden z-50 w-[60px] flex flex-row items-center">
        <RightSideBarSVG class="h-10" @click="toggleRightSideBar"/>
    </div>

</template>

<script setup>
import { defineEmits } from 'vue';
import { useStore } from 'vuex';
import RightSideBarSVG from '@/components/svg-components/RightSideBarSVG.vue';

const store = useStore()

const toggleRightSideBar = () => {
    if(!store.getters['showRightSideBar']) {
        store.dispatch('setShowRightSideBarTrue');
    } else {
        store.dispatch('setShowRightSideBarFalse');
    }
}
</script>
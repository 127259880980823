<template>
    <div>
        <div v-if="noCompaniesLoaded" class="flex-col mb-2 lg:border-t lg:border-light-primary lg:pt-2">
            Load Companies To View CRM Summary Stage Counts.
        </div>
        <div v-else class="flex-col mb-2 lg:border-t lg:border-light-primary lg:pt-2">
            <div v-if="loadingCrmSummaryStages">
                Loading CRM Summary Stage Counts <div class="inline text-base"><div  class="animate-[pulse_1s_infinite_50ms] inline-block">.</div><div  class="animate-[pulse_1s_infinite_100ms] inline-block ">.</div><div  class="animate-[pulse_1s_infinite_150ms] inline-block">.</div></div>
            </div>
            <div v-else>
                {{ crmSummaryStages }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "crm-summary-stages"
    }
</script>

<script setup>
import { defineProps, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { getCRMSummaryStageCounts } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        query: String,
        companyCount: Number,
        companies: Array
    })

    const crmSummaryStageClient = computed(() => store.getters["company/showCRMSummaryStagesClient"])

    const crmSummaryStages = ref('')
    const loadingCrmSummaryStages = ref(true)

    const companiesCount = computed(() => props.companyCount)
    const companies = computed(() => props.companies)
    const noCompaniesLoaded = computed(() => props.companyCount > 0 ? false : true)

    async function setSummaryStages(){
        loadingCrmSummaryStages.value = true
        const token = store.state.auth.token
        let searchQuery = props.query
        searchQuery = searchQuery.replace('companies', '')
        console.log('search query', searchQuery)
        if(!props.companyCount){
            console.log('Cannot get CRM Summary Stage Counts: No companies loaded.')
            store.dispatch('company/setShowCRMSummaryStagesFalse')
        }else 
        if(props.companyCount > 1000 && props.companyCount < 3000){
            if(confirm(`Are you sure you want the CRM Summary Stage Counts for ${props.companyCount} companies? Loading this data might take some time.`)){
                const result = await getCRMSummaryStageCounts(searchQuery, token, crmSummaryStageClient.value)
                crmSummaryStages.value = result.data
                loadingCrmSummaryStages.value = false
            } else  {
                store.dispatch('company/setShowCRMSummaryStagesFalse')
            }
        }else if(props.companyCount > 3000){
            alert('Cannot get CRM Summary Stage Counts: Exceeds 3000 Company Limit')
            store.dispatch('company/setShowCRMSummaryStagesFalse')
        } else {
            const result = await getCRMSummaryStageCounts(searchQuery, token, crmSummaryStageClient.value)
            crmSummaryStages.value = result.data
            loadingCrmSummaryStages.value = false
        }
    }

    watch(companies, ()=>{
        setSummaryStages()
    })

    watch(crmSummaryStageClient, () => {
        setSummaryStages()
    })



    setSummaryStages()



</script>

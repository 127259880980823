<template>
    <form @submit.prevent>
      <div class="flex flex-row h-full w-full items-center">
            <div class="h-full">
                <label class="text-xs py-0.5 px-1 w-full flex justify-center items-center rounded-l-md h-full text-center border-t border-l border-b border-gray-400 whitespace-nowrap" for="companyName">
                    <slot name="label"></slot>
                </label>
            </div>
            <div class="rounded-r-md h-full w-full text-xs
                        border border-gray-400 text-gray-900
                        focus:ring-dark-primary focus:border-dark-primary">
                <slot name="widget"></slot>
            </div>
        </div>
    </form>
  </template>
  
  <style scoped>
  
  </style>
// import Client from './api';
import axios from 'axios';
import { BASE_URL } from './api';
import { saveAs } from 'file-saver';


// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     console.log('error found in interceptors')
//     return Promise.reject(error);
//   });
// export function getCompanies() {
//     const response = Client.get('companies/')
//     console.log(response)
//     return response.data.results
// }

export async function getCompanies(payload) {
    // console.log('getting companies', payload.token)
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        // console.log('Finished Running: getCompanies async call', response);
        return {
            "companies": response.data.results,
            "count": response.data.count,
            "next": response.data.next,
            "prev": response.data.previous,
            "page": response.data.page,
            "pageSize": response.data.page_size,
            "num_pages": response.data.num_pages
        };
    }).catch((error) => {
        console.log(error)
    });
}

export async function getCompanyByID(payload) {
    // console.log('getting companies', payload.token)
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/${payload.companyId}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        // console.log('Finished Running: getCompanies async call', response);
        return {
            response
        };
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function getCompaniesForClientAppendices(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/get_companies_for_client_appendices${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        alert(error)
    });
}

export async function getCompaniesForClientAppendicesPaginated(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/get_companies_for_client_appendices_paginated${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        }
    }).then((response) => {
        return {
            "companies": response.data.results,
            "count": response.data.count,
            "next": response.data.next,
            "prev": response.data.previous,
            "page": response.data.page,
            "pageSize": response.data.page_size,
            "num_pages": response.data.num_pages
        }
    }).catch((error) => {
        alert(error)
    });
}

export async function getDLACompanies(payload) {
    // console.log('getting companies', payload.token)
    return axios({
        method: 'get',
        url: `${BASE_URL}/dla_companies${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        // console.log('Finished Running: getDLACompanies async call', response);
        return {
            "companies": response.data.results,
            "count": response.data.count,
            "next": response.data.next,
            "prev": response.data.previous,
            "page": response.data.page,
            "pageSize": response.data.page_size,
            "num_pages": response.data.num_pages
        };
    }).catch((error) => {
        console.log(error)
    });

}

export async function getCRMSummary(filters, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/get_crm_summary${filters}`,
        headers: {
            'Authorization': `Token ${token}`
        }
    }).then(response => {
        return {
            "crmStatuses": response.data.crm_selections_dict,
            "crmNextActions": response.data.crm_next_actions_dict
        }
    }).catch((error) =>{
        console.log(error)
    })
}

export async function exportToCSV(filters, token, fields=null) {
    // console.log('field in axios call', fields)
    let jobData = null
    if(fields){
        jobData = JSON.stringify({
            data: {
                parameter_data: [
                    {
                        id: 9,
                        parameter_value_string: fields
                    }
                ]
            }
        })
    }
    // console.log('job data', jobData)
    return axios({
        method: 'post',
        url: `${BASE_URL}/companies/export_to_csv/${filters}`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: jobData,
        // responseType: 'blob'
    }).then((response) => {
        console.log('response csv', response)
        return response
    }).catch(async (error) =>{
        console.log(error)
        alert(error.response.data.detail)
        return error
    })
}
// export async function exportToCSV(filters, token, fields=null) {
//     console.log('field in axios call', fields)
//     let companyFields = null
//     if(fields){
//         companyFields = JSON.stringify({
//             fields: fields
//         })
//     }
//     console.log('fields json', companyFields)
//     return axios({
//         method: 'post',
//         url: `${BASE_URL}/companies/export_to_csv/${filters}`,
//         headers: {
//             'Authorization': `Token ${token}`,
//             'Content-Type': 'application/json'
//         },
//         data: companyFields,
//         responseType: 'blob'
//     }).then((response) => {
//         console.log('response csv', response)
//         if(response.status === 200){
//             saveAs(new Blob([response.data]), `downloaded_companies_${new Date().toDateString()}.csv`)
//         }
//         return {
//             response
//         }
//     }).catch(async (error) =>{
//         console.log(error)
//         let errorResponse = await error.response.data.text()
//         errorResponse = JSON.parse(errorResponse)
//         alert(errorResponse.detail)
//         return error
//     })
// }

export async function getPDFTierView(filters, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/pdf_tier_view${filters}`,
        headers: {
            'Authorization': `Token ${token}`
        }
    }).then(response => {
        return response.data
    }).catch((error) =>{
        console.log(error)
    })
}

export async function getCampaignActivitiesSummary(filters, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/campaign_activities_summary${filters}`,
        headers: {
            'Authorization': `Token ${token}`
        }
    }).then(response => {
        return response.data
    }).catch((error) => {
        console.log(error)
    })
}

export async function updateCompany(companyId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/companies/${companyId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}


export async function createCompany(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/companies/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        // console.log('response', response)
        return response
    }).catch((error) => {
        // console.log('error', error)
        return error
    })
}

export async function assignCampaign (campaignId, campaignChangeRequestReason, companyId, token) {
    // console.log("campaign id", campaignId)
    let data = JSON.stringify({
        campaign: null
    })
    if(campaignId){
        data = JSON.stringify({
        campaign: campaignId,
        change_request_reason: campaignChangeRequestReason
        })
    }
    return axios({
        method: 'put',
        url: `${BASE_URL}/companies/${companyId}/assign_to_campaign/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function assignCollectionTag (collectionTagName, companyId, token) {
    console.log("collection tag name", collectionTagName)
    let data = JSON.stringify({
        payload: collectionTagName
    })
    // if(collectionTagName){
    //     data = JSON.stringify({
    //         payload: collectionTagName
    //     })
    // }
    return axios({
        method: 'put',
        url: `${BASE_URL}/companies/${companyId}/assign_collection_tag/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function removeCollectionTag (collectionTagId, companyId, token) {
    console.log("collection tag id", collectionTagId)
    let data = JSON.stringify({
        payload: null
    })
    if(collectionTagId){
        data = JSON.stringify({
        payload: collectionTagId
        })
    }
    return axios({
        method: 'put',
        url: `${BASE_URL}/companies/${companyId}/remove_collection_tag/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function transferCollectionTag (currentCollection, newCollection, companyId, token) {
    console.log("collection tag id", currentCollection)
    let data = JSON.stringify({
        payload: null
    })
    if(currentCollection && newCollection){
        data = JSON.stringify({
            current_collection_name: currentCollection,
            new_collection_name: newCollection
        })
    }
    return axios({
        method: 'put',
        url: `${BASE_URL}/companies/${companyId}/transfer_collection_tag/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function vetURL(companyId, action, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/vet_url/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'action': action
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function performBulkAction(token, companyIds, endpoint, payload) {
    console.log(payload)
    console.log(payload)
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${endpoint}/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'company_ids': companyIds,
            'payload': payload
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function bulkAssignQueryResultsToCollectionTag(collectionTagName, filters, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/bulk_assign_query_results_to_collection_tag/${filters}`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'collection_tag_name': collectionTagName,
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function bulkAssignQueryResultsToCollectionTagAndDeletePreviousCollections(collectionTagName, filters, collectionsToDelete, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/bulk_assign_query_results_to_collection_tag_and_delete_previous_collections/${filters}`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'collection_tag_name': collectionTagName,
            'collections_to_delete': collectionsToDelete,
        }
    })
    .then(response => response)
    .catch(error => alert(error))    
}

export async function promoteOrDemoteResearch(companyId, action, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/promote_or_demote_research/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'action': action
        }
    })
    .then(response => response)
    .catch((error) => {
        console.log('error', error)
        alert(error.response.data.detail)
    })
}

export async function getZoomInfoId(companyId, token) {
    console.log('getZoomInfoId')
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/get_zoom_info_id/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    })
    .then(response => response)
    .catch(error => {
        console.log(error)
        alert(error)
    })
}

export async function clearZoomInfo(companyId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/clear_zoom_info/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => error)
}

export async function getZoomInfoBasic(companyId, token) {
    console.log('getZoomInfoBasic service')
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/enrich_zoom_info_company/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => error)
}

export async function transferZoomInfoBasic(companyId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/transfer_zi_basic/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response)
    .catch(error => alert(error))    
}

export async function assignZoomInfoID(companyId, zoomInfoId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/assign_zoom_info_id/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'zoom_info_id': zoomInfoId
        }
    })
    .then(response => response)
    .catch(error => alert(error))  
}

export async function markTier(companyId, tierId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/mark_tier/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'tier_id': tierId
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function markClientFeedback(companyId, clientFeedback, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/mark_client_feedback/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: {
            'client_feedback': clientFeedback
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function getMostRecentCrmStatus(filters, token){
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/get_current_crm_statuses${filters}`,
        headers: {
            "Authorization": `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error)
}

export async function addNewCrmStatus(companyId, crmStatusId, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/assign_new_crm_status/`,
        headers: {
            "Authorization": `Token ${token}`
        },
        data: {
            'crm_id': crmStatusId
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function addNewCrmAction(companyId, actionData, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/companies/${companyId}/add_new_crm_action/`,
        headers: {
            "Authorization": `Token ${token}`
        },
        data: {
            "crm_action": actionData
        }
    })
    .then(response => response.data)
    .catch(error => alert(error))
}

export async function createMultipleCompanies(data, token){
    return axios({
        method: "post",
        url: `${BASE_URL}/companies/create_multiple_companies/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function getCampaignValidCompanies(token, clientId){
    // console.log(clientId);
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/get_campaign_valid_companies/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        params: {
            client_id: clientId
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function getCompanyHistory(token, companyId, numberOfChanges){
    // console.log(clientId);
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/${companyId}/get_company_history/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        params: {
            'count': numberOfChanges
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function getContractsAndSubContracts(token, companyId){
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/${companyId}/get_contracts_and_subcontracts/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function getCampaignChangeReport(token){
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/campaign_change_report`,
        headers: {
            "Authorization": `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error)
}

export async function getCRMSummaryStageCounts(filters, token, client_id=null){
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/get_crm_summary_stages${filters}`,
        headers: {
            "Authorization": `Token ${token}`
        },
        params: {
            'client_id': client_id
        }
    })
    .then(response => response)
    .catch(error => console.error)
}

export async function getDescriptions(token, companyId) {
    return axios({
        method: "get",
        url: `${BASE_URL}/companies/${companyId}/get_descriptions`,
        headers: {
            "Authorization": `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error)
}

export async function getCRMResolutionCompanies(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/crm_resolution_companies/${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        console.log(response)
        return response
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function resolveCRMActions(payload) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/crm_resolution_companies/${payload.companyId}/update_crm_actions/`,
        headers: {
            'Authorization': `Token ${payload.token}`,
            'Content-Type': 'application/json'
        },
        data: payload.formData
    }).then((response) => {
        console.log(response)
        return response
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function getEmptyCRMActions(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/${payload.companyId}/get_empty_crm_actions`,
        headers: {
            'Authorization': `Token ${payload.token}`,
            'Content-Type': 'application/json'
        },
    }).then((response) => {
        console.log(response)
        return response
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function updateCompanyCRMActions(payload) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/companies/${payload.companyId}/update_crm_actions/`,
        headers: {
            'Authorization': `Token ${payload.token}`,
            'Content-Type': 'application/json'
        },
        data: payload.formData
    }).then((response) => {
        console.log(response)
        return response
    }).catch((error) => {
        console.log(error)
        return error
    });
}
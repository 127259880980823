<template>
    <div>
        <base-button v-if="companyContact.is_verified" @click="markEmailVerified('remove_email_verified')" class="">Remove Company Email Verified</base-button>
        <base-button v-else @click="markEmailVerified('mark_email_verified')" class="">Mark Company Email Verified</base-button>
    </div>
</template>

<script>
  export default {
      name: "company-contact-mark-email-verified-cell"
  }

</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";
import { verifyContactCompanyEmail } from '@/services/Contact.js'
import { useStore } from "vuex";

  const isEditable = ref(false)

  const store = useStore()

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  async function markEmailVerified(action) {
    const token = store.state.auth.token
    await verifyContactCompanyEmail(props.companyContact.id, action, token)
    emit('refetch-companies')
  }

</script>
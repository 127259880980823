<template>
  <div class="pl-1.5 pt-0.5 grid grid-cols-2 gap-2 w-full">
    <div class="w-full">
      <div class="mb-1.5 flex flex-col">
        <span class="font-bold pr-1"
          >PMA Approved Replacement for Part Number:</span
        >
        <div>
          {{ approvedReplacementForPartNumbercfrPartReference }}
        </div>
      </div>
    </div>
    <div class="w-full flex flex-col">
      <div class="w-full">
        <span class="font-bold pr-1">CFR Subpart Appendix Reference:</span>
        {{ cfrSubpartAppendixReference }}
      </div>
      <div class="w-full my-1.5">
        <span class="font-bold pr-1">CFR Section Reference:</span>
        {{ cfrSectionReference }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";


const props = defineProps({
  approval: Object,
});

const approvedReplacementForPartNumbercfrPartReference = computed(() => {
  return props.approval.approved_replacement_for_part_number
    ? props.approval.approved_replacement_for_part_number
    : "--";
});

const cfrSubpartAppendixReference = computed(() => {
  return props.approval.cfr_subpart_appendix_reference
    ? props.approval.cfr_subpart_appendix_reference
    : "--";
});

const cfrSectionReference = computed(() => {
  return props.approval.cfr_section_reference
    ? props.approval.cfr_section_reference
    : "--";
});
</script>

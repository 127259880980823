<template>
    <base-dblclick-input
      modelType="company"
      :modelId="company.id"
      fieldName="problem_company_notes"
      :fieldValue="company.problem_company_notes"
      inputType="text"
      @refetch="fetchNewCompanies"
    >
    </base-dblclick-input>
</template>

<script>
  export default {
      name: "problem-company-notes-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object,
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
import referralsMutations from './mutations.js';
import referralsActions from './actions.js';
import referralsGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            showFilterPane: true,
        };

    },
    mutations: referralsMutations,
    actions: referralsActions,
    getters: referralsGetters

};
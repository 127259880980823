<template>
    <div class="fixed border border-solid border-gray-300 bg-white rounded-md" :style="popoverPosition">
        <div class="text-xs px-2 py-1 ">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "BasePopoverClick"
}
</script>
<script setup>
import { defineProps, defineEmits, computed } from "vue";


const props = defineProps({
    xPosition: Number,
    yPosition: Number,
})

const emit = defineEmits(['close-popover'])

const popoverPosition = computed(function () {
    return `left: ${props.xPosition-6}px; top: ${props.yPosition+5}px;`
})


</script>
<template>
  <div v-bind="containerProps" class="h-[250px] bg-white">
    <div v-bind="wrapperProps" class="max-w-sm mx-auto">
      <ul
        v-for="{ index, data } in list"
        :key="index"
        class="hover:bg-amber-100"
        @click="emitSelected(data)"
      >
        <li
          @click="emitSelected(data)"
          class="relative cursor-default select-none pr-2 pl-6 pt-6 pb-6 text-gray-900 text-xs"
        >
          <span
            :class="[
              data === selected ? 'font-medium' : 'font-normal',
              'block',
            ]"
            >{{ data }}</span
          >
          <span
            v-if="data === selected"
            class="absolute inset-y-0 left-0 flex items-center text-amber-600"
          >
            <CheckIcon class="h-5 w-5 pl-1 lg:h-5 lg:w-5" aria-hidden="true" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
// imports

import { ref, defineEmits, computed, defineProps, watch } from "vue";
import { CheckIcon } from "@heroicons/vue/20/solid";
import { useVirtualList } from "@vueuse/core";

// constants/variables

const emit = defineEmits(["selected-item"]);
const props = defineProps(["options", "selected", "inputSearch"]);

const search = ref("");

// computed values

const filteredItems = computed(() => {
  return props.options.filter((option) =>
    option.toLowerCase().startsWith(search.value.toLowerCase())
  );
});

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  filteredItems,
  {
    itemHeight: 64,
    overscan: 10,
  }
);

// watchers

watch(props.inputSearch, () => {
  search.value = props.inputSearch.text;
  handleScrollTo();
});

// functions

function emitSelected(label) {
  search.value = "";
  emit("selected-item", label);
}

function handleScrollTo() {
  scrollTo(search.value);
}
</script>

<template>
  <div class="w-full min-h-screen bg-gray-200">
    <div class>
      <div
      class="pt-3 pb-0.5 border-b mx-5 mb-5 border-b-gray-400 flex flex-row justify-between"
      >
        <h1 v-if="client != null">
          Emails Received for {{ client.client_name }}
        </h1>
      </div>
      <!-- GENERAL CONTENT (NAVIGATION AND TABLE) -->
      <div class="mt-10">
        <!-- NAVIGATION -->
        <div class="pl-5">
          <div class="flex flex-row items-center ml-3">
            <div class="flex items-center">
              <span
              class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
              @click="goToFirst"
              >&lt;&lt;</span
              >
              <span
                class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
                @click="getPrev"
                >&lt;</span
              >
              <div class="flex text-md flex-row">
                Page {{ page }} of {{ totalPages }}
              </div>
              <span
                class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
                @click="getNext"
                >></span
              >
              <span
                class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline"
                @click="goToLast"
                >>></span
              >
            </div>
            <div class="flex flex-row items-center">
              <div v-if="isLoading" role="status" class="pl-3">
                <svg
                  aria-hidden="true"
                  class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="white"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#2E9CCA"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <div v-else class="pl-3">
                <svg
                  @click="loadClientEmails(fetchFilter, true)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#25274D"
                  class="mx-1 w-6 h-6 hover:cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </div>
            </div>
            <div
            class="flex flex-row items-center text-md align-middle lg:ml-3 lg:col-span-1 lg:justify-start"
            >
              {{ count }} Total Emails
            </div>
          </div>
        </div>
        <!-- TABLE -->
        <EmailsReceivedTable
          class="mt-2 px-3"
          :tableHeaders = tableHeaderList
          :content = emails
          @modify-email="showCreateForm"
          @delete-email="showDeleteForm"
        />
      </div>
    </div>
      <!-- CREATE CAMPAIGN ACTIVITY POPUP -->
    <div>
      <base-modal 
        :is-form-visible="isCreateFormVisible"
        @close="closeCreateForm"
      >
        <template #header>
          <h2 class="text-lg">Create Email History</h2>
        </template>
        <template #body>
          <CreateCampaignActivityForm
            class=""
            @close-form="closeCreateForm"
            @reload-emails="loadClientEmails(fetchFilter, true)"
            :companyList="companyList"
            :activityEmail="activityEmail"
          />
        </template>
      </base-modal>
    </div>
    <!-- DELETE CONFIRMATION POPUP -->
    <div
      v-if="isDeleteFormVisible"
      class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] flex justify-center items-center z-10"
      tabindex="-1"
    >
      <div
        class="flex-col bg-white shadow-lg-black p-3 w-[50%] rounded-md h-min overflow-x-auto"
      >
        <header
          class="flex justify-between items-center relative p-15 pl-2 border-solid border-b border-gray-200"
        >
          <h1 class="my-5 break-words">
            Are you sure you want to delete the email with the subject
            <b>{{ emailToDelete.email_subject }}</b> from 
            <b>{{ emailToDelete.email_from }}</b> to 
            <b>{{ emailToDelete.email_to }}</b>
            ?
          </h1>
          <base-button-negative
            class="mb-auto ml-1 px-2 font-bold"
            type="button"
            @click="closeDeleteForm"
          >
            X
          </base-button-negative>
        </header>
        <section class="max-h-[80vh] overscroll-contain">
          <div
            class="flex flex-row space-x-2 justify-center w-full col-span-2 my-5"
          >
            <base-button-affirmative @click="deleteSelectedEmail"
              >Delete</base-button-affirmative
            >
            <base-button-negative @click="closeDeleteForm"
              >Cancel</base-button-negative
            >
          </div>
        </section>
      </div>
    </div>
    <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
        <template #header>
            <span>{{ sourceStatus }}</span>
        </template>
        <template #body>
            <span>{{ sourceMessage }}</span>
        </template>
    </HeadlessDialogBox>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getClientEmails, getClientById } from "@/services/ClientEmail";
import EmailsReceivedTable from "./EmailsReceivedTable.vue";
import HeadlessDialogBox from '../HeadlessDialogBox.vue';
import CreateCampaignActivityForm from "./CreateCampaignActivityForm.vue"
import { getCampaignValidCompanies } from "@/services/Company"
import { deleteEmail } from '@/services/ClientEmail';


const route = useRoute();
const store = useStore();

const client = ref(null);
const emails = ref(null);

const next = ref(null);
const prev = ref(null);
const count = ref(null);
const page = ref(1);
const pageSize = ref(null);
const totalPages = ref(null);
const isLoading = ref(false);

const isCreateFormVisible = ref(false);
const isDeleteFormVisible = ref(false);
const activityEmail = ref(null);
const emailToDelete = ref(null);

const tableHeaderList = ref([
  "Expand",
  "Subject",
  "From/To",
  "Key",
  "Last Modified",
  "Size",
  "Actions"
])

const companyList = ref([{ value: "clear", label: "------------" }]);

const fetchFilter = computed(function () {
  // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
  const url = `?client_id=${route.params.clientId}`
  return url;
});

const sourceStatus = ref("")
const sourceMessage = ref("")
const openDialogBox = ref(false)
const success = ref(false);

async function loadClientEmails(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  const payload = {
    token: store.state.auth.token,
    clientId: route.params.clientId,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  // console.log('payload', payload)
  const result = await getClientEmails(payload);
  next.value = result.data.next ? `&page=${result.data.page + 1}` : null;
  prev.value = result.data.prev ? `&page=${result.data.page - 1}` : null;
  count.value = result.data.count;
  page.value = result.data.page;
  pageSize.value = result.data.results.length;
  totalPages.value = result.data.numPages;

  // console.log(result.data.results);
  emails.value = result.data.results;
  isLoading.value = false;
  // return result.data.results;
}

async function loadClientById() {
  const payload = {
    token: store.state.auth.token,
    clientId: route.params.clientId,
  };
  const result = await getClientById(payload);
  client.value = result.client;
  // console.log(client.value)
}

async function loadCampaignValidCompanies(){
  const token = store.state.auth.token;
  const clientId = route.params.clientId
  // console.log(client)
  const result = await getCampaignValidCompanies(token, clientId)
  const companies = result.data.companies
  // console.log(result.data.companies)
  companies.forEach((company) => {
    companyList.value.push({
      value: company.id,
      label: `${company.company_name} URL:${company.website_url}`
    })
  });
  // console.log(companyList.value)
}

async function deleteSelectedEmail(){
  const payload = {
    token: store.state.auth.token,
    emailId: emailToDelete.value.id
  };
  // console.log(payload)
  await deleteEmail(payload);
  success.value = true;
  sourceStatus.value = "Success";
  sourceMessage.value = "Email deleted.";
  openDialogBox.value = true;
  loadClientEmails();
}

function closeDialogBox(){
    openDialogBox.value = false
    if (success.value) {
      closeDeleteForm();
      success.value = false
    }
}

function getNext() {
  if (next.value) {
    loadClientEmails(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadClientEmails(fetchFilter.value + prev.value);
  } else {
    alert("No prev page");
  }
}

function goToFirst() {
  loadClientEmails(fetchFilter.value + `&page=${1}`);
}

function goToLast() {
  loadClientEmails(fetchFilter.value + `&page=${totalPages.value}`);
}

// --------- Modification Form ---------

function showCreateForm(email) {
  // console.log(activityEmail)
  activityEmail.value = email
  isCreateFormVisible.value = true;
}

function closeCreateForm() {
  isCreateFormVisible.value = false;
}

// --------- Delete Form ---------

function showDeleteForm(email) {
  console.log("delete email");
  emailToDelete.value = email
  isDeleteFormVisible.value = true;
}

function closeDeleteForm() {
  isDeleteFormVisible.value = false;
  emailToDelete.value = null;
}

loadClientById();
loadClientEmails();
loadCampaignValidCompanies();
</script>

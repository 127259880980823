<template>
    <div class="flex flex-wrap justify-start items-center w-full">
      <base-dblclick-input
        modelType="contactCompanyRelationship"
        :model-id="companyContact.id"
        field-name="email_address"
        :field-value="companyContact.email_address"
        :canCopy="true"
        inputType="text"
        @refetch="fetchNewCompanies"
      >

      
      <slot name="icon">
      </slot>
      </base-dblclick-input>
      <div v-if="companyContact.email_address">
        <div class="text-xs h-2.5 w-2.5 ml-1.5 rounded-full bg-emerald-400 border border-emerald-500" v-if="companyContact.is_verified">
          <!-- Email Verified: {{ formattedVerifiedDate}} -->
        </div>
        <div class="text-xs h-2.5 w-2.5 ml-1.5 rounded-full bg-amber-400 border border-amber-500" v-else>
          <!-- * Email Not Verified -->
        </div>
      </div>

    </div>
</template>

<script>
  export default {
      name: "company-contact-business-email-cell"
  }

</script>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import {formatDate} from '@/composables/formatDate';

const props = defineProps({
    companyContact: Object
})

const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies () {
    emit('refetch-companies')
}

const formattedVerifiedDate = computed(() => {
    try {
      return formatDate(`${props.companyContact.verified_date}`)
    } catch {
      return '--'
    }
  })

</script>
<template>
    <div class="w-full h-full">
        <div class="flex flex-col w-full items-center py-1 border-b border-gray-400">
            <!-- <div class="flex justify-between w-full text-left mb-1">
                <div>Create Query From Previous Search:</div> -->
            <div class="flex justify-end w-full text-left mb-1">
                <div class="hover:cursor-pointer text-accent-primary hover:text-dark-primary pr-1" @click="clearFields">Clear Fields</div>
            </div>
            <!-- <div @click="setJobOptions" class="w-full">
                <base-list-box
                    class="rounded-b-md h-8 w-full"
                    placeholder="Select Search Query"
                    :options="jobOptions"
                    :modelValue="job.id"
                    :multiple="false"
                    @update:modelValue="setJob"
                />
            </div> -->
        </div>
        <form @submit.prevent="submitForm" action="" class="w-full h-full">
            <base-form class="h-[85%] max-h-[85%] w-full">
                <template #body>
                    <div class="flex flex-col h-full">
                        <div>
                            <div class="py-1 flex flex-col w-full items-center border-b border-gray-400">
                                <div class="flex flex-col w-full ">
                                    <div class="flex flex-col items-center w-full">
                                        <div class="text-left w-full whitespace-nowrap">
                                            Search Title*
                                        </div>
                                        <input type="text" class="border border-gray-400 rounded-md p-2 w-full" required v-model="jobTitle">
                                    </div>
                                    <div class="flex flex-col items-center w-full pt-2">
                                        <div class="text-left w-full whitespace-nowrap">
                                            Search Details
                                        </div>
                                        <input type="text" class="border border-gray-400 rounded-md p-2 w-full" v-model="jobDetails">
                                    </div> 
                                </div>
                            </div>
                            <div class="py-3 flex justify-start border-b border-gray-400">
                                Choose Search Phrase(s) and Collection Name
                                <HeadlessPopoverClick :hide-chevron="true" class="h-fit">
                                    <template #popover-title> <div class="-m-2 border bg-white bg-opacity-40 text-black px-1 rounded-lg text-xs">?</div></template>
                                    <template #popover-body>
                                        <div class="bg-gray-100 p-2 border border-gray-400 rounded-b-lg w-96">
                                            <div class="border-b border-gray-400 pb-1 mb-1.5">
                                                Helpful Tips:
                                            </div>
                                            <div class="flex flex-col space-y-2 h-[400px] overflow-y-scroll">
                                                <div class="grid grid-cols-5">
                                                    <div>
                                                        AND / OR
                                                    </div>
                                                    <div class="col-span-4">
                                                        Selecting OR checks for each matching condition independently, and returns all results. AND only returns results that meet all conditions.
                                                        <br>
                                                        - 'blue' OR 'green' returns web text containing 'blue' along with web texts containing 'green'.
                                                        <br>
                                                        - 'blue' AND 'green' returns web texts containing both 'blue' and 'green'.
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-5">
                                                    <div>
                                                        Is Exactly
                                                    </div>
                                                    <div class="col-span-4">
                                                        Searches for the exact phrase anywhere within the text. It does not matter if surrounding characters are spaces or if the phrase is part of a word.
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-5">
                                                    <div>
                                                        Is Like
                                                    </div>
                                                    <div class="col-span-4">
                                                        Searches for a like phrase using multiple queries. For multi-word phrases, insert '.?' between each word as a wildcard character.
                                                        <br>
                                                        - Supported wildcard characters include: 
                                                        <br>
                                                          no character, space, hyphen, underscore
                                                        <br>
                                                        - For example, searching 'long.?line' returns web texts containing 'longline', 'long line', 'long-line', or 'long_line'.
                                                        <br>
                                                        - Contact the tech team for additional character support.
                                                    </div>
                                                </div>
                                                <!-- <div class="grid grid-cols-5">
                                                    <div>
                                                        Starts With
                                                    </div>
                                                    <div class="col-span-4">
                                                        Searches for the phrase only as the start (or whole) of a word.
                                                        <br>
                                                        - Searching for 'plane' returns web texts containing 'planet', but would not return texts containing 'airplane'.
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-5">
                                                    <div>
                                                        Ends With
                                                    </div>
                                                    <div class="col-span-4">
                                                        Searches for the phrase only as the end (or whole) of a word.
                                                        <br>
                                                        - Searching for 'plane' returns web texts containing 'airplane' but would not return texts containing 'planet'.
                                                    </div>
                                                </div> -->
                                                <div class="grid grid-cols-5">
                                                    <div>
                                                        Is Not
                                                    </div>
                                                    <div class="col-span-4">
                                                        Returns web texts that do not contain the given search phrase. 
                                                        <br>
                                                        - Is Not is best used with the 'AND' conjunction, as OR would return a high volume of web texts.
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-5">
                                                    <div>
                                                        Is Stem Of
                                                    </div>
                                                    <div class="col-span-4">
                                                        Stems the phrase and searches for any words containing the root.
                                                        <br>
                                                        - Searching 'runner' would stem the root to 'run' and return web texts containing 'run', 'runner', 'ran', 'running', etc.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </HeadlessPopoverClick>
                            </div>
                            <FTSQueries
                                :conditionsCleared="conditionsCleared"
                                @change-fts-queries="setFTSQueryString"
                                @set-conditions-cleared-false="setConditionsClearedFalse"
                            />
                            <div class="flex flex-col justify-between items-center px-2 py-2 border-b border-gray-400">
                                <div class="text-left w-full whitespace-nowrap pl-2">Collection Tag Name* </div>
                                <input type="text" class="border border-gray-400 rounded-md w-full pl-2 py-2" required v-model="collectionTagName">     
                            </div>
                        </div>
                        <div></div>
                    </div>
                </template>
                <template #footer>
                    <div class="flex flex-col items-center my-2">
                        <base-button-affirmative type="submit">Start Search</base-button-affirmative>
                    </div>  
                </template>
            </base-form>
        </form>
    </div>
</template>

<script setup>
import {ref, reactive,defineProps, defineEmits, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { createAsynchronousJob } from "@/services/AsynchronousJobs";
import { getAsynchronousJobQueue } from '@/services/AsynchronousJobs';
import { formatDateTime } from '@/composables/formatDateTime';
import FTSQueries from './FTSQueries.vue';
import HeadlessPopoverClick from '@/components/HeadlessPopoverClick.vue';


const responseStatus = ref('');
const responseMessage = ref('');

const store = useStore()

const props = defineProps(['formCleared'])

const emit = defineEmits(['submit-fts', 'refetch-jobs', 'set-form-cleared-false', 'set-is-loading-true', 'set-is-loading-false'])

// const jobType = ref('')

const job = reactive({
    id: null,
    jobTitle: '',
    query: {}

})
const jobTitle = ref('')
const jobDetails = ref('')

const conditionsCleared = ref(false)

watch(() => props.formCleared, ()=> {
    if(props.formCleared){
        clearFields()
        emit('set-form-cleared-false')
    }
})

watch(() => job.id, () => {
        if(job.id) {
            jobTitle.value = job.jobTitle
            if(job.parameters.length > 0) {
                query.value = []
                job.parameters.forEach((parameter) => {
                    if(parameter.parameter.parameter_name === 'search_phrase') {
                        query.value.push({
                            key: Math.random(),
                            id: 1,
                            searchPhrase: parameter.parameter_value_string
                        })
                    } else if (parameter.parameter.parameter_name === 'collection_tag_name') {
                        collectionTagName.value = parameter.parameter_value_string
                    }
                })
            }
        } else {
            jobTitle.value = ''
            collectionTagName.value = null
            query.value = [{
                key: Math.random(),
                id: 1,
                conjunction: 'OR',
                prefix: 'CONTAINS',
                searchPhrase: null    
            }]
        }
    })

const jobOptions = ref([])


function clearFields() {
    setJob('clear')
    jobTitle.value = ''
    jobDetails.value = ''
    collectionTagName.value = null
    query.value = [{
        key: Math.random(),
        id: 1,
        searchPhrase: null    
    }]
    conditionsCleared.value = true
}

function setConditionsClearedFalse(){
    conditionsCleared.value = false
}

function setJob(target) {
if (target === "clear") {
    job.id = null,
    job.jobTitle = ''
    job.query = []
  } else {
    job.id = target.id;
    job.jobTitle = target.job_title;
    job.query = target.parameters;
  }
}

const collectionTagName = ref(null)

const ftsQueryString = ref(null)

const query = ref(null)


function setFTSQueryString(queryString) {
    ftsQueryString.value = queryString
}

async function submitForm(){
    const token = store.state.auth.token
    const job_data = {
        job_title: jobTitle.value,
        job_details: jobDetails.value,
        job_type: 'full_text_search'
    }
    let parameter_data = [
        {
            id: 7,
            parameter_value_string: ftsQueryString.value
        },
        {
            id: 2,
           parameter_value_string: collectionTagName.value
        }
    ]
    const data = {
        data: {
            job_data: job_data,
            parameter_data: parameter_data
        }
    }
    console.log('data', data)
    emit('set-is-loading-true')
    const response = await createAsynchronousJob(data, token)
    console.log('response')
    console.log(response)
    if(response.status == 201){
        emit('submit-fts', {
            responseStatus: "Success",
            responseMessage: response.data,
        })
    } else {
        emit('submit-fts', {
            responseStatus: "Error",
            responseMessage: response.data,
        })
    }
    emit('set-is-loading-false')
}

</script>
<template>
    <div v-if="governmentDesignations">
        <div v-for="governmentDesignation in governmentDesignations" :key="governmentDesignation.id">
            <span class="font-bold">{{ governmentDesignation.government_designation }}</span> ({{ governmentDesignation.governing_specification.qpl_number }})
        </div>
    </div>
    <div v-else>
        --
    </div>

</template>

<script>
    export default {
        name: "dla-government-designation-cell"
    }
</script>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        governmentDesignations: Array
    })
</script>
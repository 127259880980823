import { BASE_URL } from "./api";
import axios from "axios";

export async function getNAICSCodes(token) {
    return axios({
        method: "get",
        url: `${BASE_URL}/naics_codes`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error(error))
} 
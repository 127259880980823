export default {
    showClients(state) {
        return state.clients;
    },
    showLoading(state) {
        return state.isLoading;
    },
    showCount(state){
        return state.count
    },
    showNext(state){
        return state.next
    },
    showPrev(state){
        return state.prev
    },
    showPage(state){
        return state.page
    },
    showPageSize(state){
        return state.pageSize
    },
    showFilterPane(state){
        return state.showFilterPane
    },
}
export default {
    beforeMount: (el, binding) => {
        el.onEscape = event => {
            if(event.keyCode === 27){
                binding.value(event);
            }
        }   
        document.addEventListener("keyup", el.onEscape)
    },
    unmounted: el => {
        document.removeEventListener("keyup", el.onEscape)
    },
}
<template>
    <base-dblclick-input
      modelType="contact"
      :model-id="companyContact.id"
      field-name="linkedin_profile"
      :field-value="companyContact.linkedin_profile"
      :canCopy="true"
      inputType="text"
      @refetch="fetchNewCompanies"
    >
      <slot name="icon"></slot>
    </base-dblclick-input>
</template>

<script>
  export default {
      name: "company-contact-linkedin-profile-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
<template>
    <div class="text-center">
        Export data to view
    </div>
</template>

<script>
export default {
    name: "event-summary-cell"
}
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    company: Object
})

</script>
<template>
    <div class="text-center" :class="dateStyling">
       {{ engagement.days_until_target_invoice_day }}
    </div>
</template>

<script>
export default {
    name: "engagement-days-until-target-invoice-day-cell"
}
</script>

<script setup>
import { computed, ref, defineProps } from 'vue';

const props = defineProps({
    engagement: Object
})

const dateStyling = computed(() => {
    if(props.engagement.target_invoice_day_due_status === "good") {
        return "bg-[#8EA9DA]"
    } else if(props.engagement.target_invoice_day_due_status === "overdue") {
        return "bg-[#FF0300]"
    } else if(props.engagement.target_invoice_day_due_status === "due_soon")  {
        return "bg-[#FFFF01]"
    }
    return ""
})




</script>
<template>
    <div>
        <base-button-disabled @click="clearZi" disabled>Clear ZI ID & Data</base-button-disabled>
    </div>
</template>

<script>
    export default {
        name: "clear-zi-button"
    }
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { clearZoomInfo } from '@/services/Company'
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

    const store = useStore()
    const $toast = useToast()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    async function clearZi () {
        const token = store.state.auth.token
        const result = await clearZoomInfo(props.company.id, token)
        console.log(result)
        if(result.status == 200) {
            $toast.open({
                message: result.data.status,
                type: 'success',
                duration: 5000
            })
        } else {
            $toast.open({
                message: 'Error Clearing ZI.',
                type: 'error',
                duration: 5000
            })
        }
        emit('refetch-companies')
    }

</script>
<template>
    <div v-if="showForm">
        <CompanyContactFormVertical :company="company" @refetch-companies="fetchNewCompanies" @close-form="closeForm"/>
    </div>
    <div v-else>
        <div v-if="primaryContact.length > 0">
            <div v-for="contact in primaryContact" class="border-b border-gray-300 mb-2" :key="contact.id">
                <div class="flex flex-wrap">
                    <CompanyContactFullNameCell :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                </div>
                <!-- <div class="max-w-62 truncate">
                    <base-pill>Preferred Name: </base-pill>
                    <CompanyContactPreferredNameCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                </div> -->
                <div class="flex flex-wrap">
                    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Title: </div>
                    <CompanyContactTitleCell class="inline" :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" />
                </div>
                <div class="border-b border-t mt-1 py-0.5 border-gray-300 tracking-wide font-semibold text-gray-800">
                    <span>{{ contact.preferred_name ? `${contact.preferred_name}'s Company Lines:` : contact.first_name ? `${contact.first_name}'s Company Lines:` : 'Company Lines' }}</span>
                </div>
                <div>
                    <CompanyContactBusinessEmailCell class="inline" :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="font-bold pr-1"><img id="email" src="@/assets/images/icons8-email-30.png" alt="email" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactBusinessEmailCell>
                </div>
                <div>
                    <CompanyContactBusinessPhoneCell class="inline" :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="font-bold pr-1"><img id="phone" src="@/assets/images/icons8-phone-30.png" alt="phone" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactBusinessPhoneCell>
                </div>
                <div class="max-w-62">
                    <CompanyContactBusinessNotesCell class="inline" :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="pr-1"><img id="notes" src="@/assets/images/icons8-notes-50.png" alt="notes" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactBusinessNotesCell>
                </div>
                <div class="border-b border-t mt-1 py-0.5 border-gray-300 tracking-wide font-semibold text-gray-8">
                    {{ contact.preferred_name ? `${contact.preferred_name}'s Personal Lines:` : contact.first_name ? `${contact.first_name}'s Personal Lines:` : 'Personal Lines' }}
                </div>
                <div>
                    <CompanyContactPersonalEmailCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="font-bold pr-1"><img id="email" src="@/assets/images/icons8-email-30.png" alt="email" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactPersonalEmailCell>
                </div>
                <div>
                    <CompanyContactPhoneCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="font-bold pr-1"><img id="phone" src="@/assets/images/icons8-phone-30.png" alt="phone" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactPhoneCell>
                </div>

                <div>
                   <CompanyContactLinkedinProfileCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="pr-1"><img id="linkedin" src="@/assets/images/icons8-linkedin-30.png" alt="linkedin" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactLinkedinProfileCell>
                </div>
                <!-- <div class="max-w-62">
                    <CompanyContactPersonalNotesCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies">
                        <template #icon>
                            <span class="pr-1"><img id="notes" src="@/assets/images/icons8-notes-50.png" alt="notes" class="w-[16px] inline"/></span>
                        </template>
                    </CompanyContactPersonalNotesCell>
                </div> -->
                <!-- <div class="border-t mt-1 py-1 border-gray-400">
                    <base-pill>Is Primary: </base-pill>
                    <CompanyContactIsPrimaryCell class="inline" :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" />
                </div> -->
            </div>
        </div>
        <div v-else>
            --
        </div>
        <div>
            <base-button @click="openForm">Add</base-button>
        </div>
    </div>
</template>

<script>
    export default {
    name: "primary-contacts-cell",
    // components: { CompanyContactPersonalNotesCell }
}
</script>

<script setup>
import { ref, defineProps, computed, defineEmits } from 'vue'
import CompanyContactFullNameCell from './CompanyContactFullNameCell.vue';
import CompanyContactTitleCell from './CompanyContactTitleCell.vue';
import CompanyContactPersonalEmailCell from './CompanyContactPersonalEmailCell.vue';
import CompanyContactPhoneCell from './CompanyContactPhoneCell.vue';
import CompanyContactBusinessPhoneCell from './CompanyContactBusinessPhoneCell.vue';
import CompanyContactBusinessEmailCell from './CompanyContactBusinessEmailCell.vue';
import CompanyContactBusinessNotesCell from './CompanyContactBusinessNotesCell.vue';
import CompanyContactLinkedinProfileCell from './CompanyContactLinkedinProfileCell.vue';
import CompanyContactPersonalNotesCell from './CompanyContactPersonalNotesCell.vue';
import CompanyContactIsPrimaryCell from './CompanyContactIsPrimaryCell.vue';
import CompanyContactFormVertical from '../CompanyContactFormVertical.vue';
import CompanyContactPreferredNameCell from './CompanyContactPreferredNameCell.vue'

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    const primaryContact = computed(() => {
        if(props.company.contacts){
            let filteredCompanies = []
            props.company.contacts.forEach((contact) => {
                for(let i = 0; i < contact.company_relationships.length; i++){
                    if(contact.company_relationships[i].company_id == props.company.id && contact.company_relationships[i].is_primary_contact){
                        filteredCompanies.push(contact)
                    }
                }
            })
            return filteredCompanies
        } else {
            return []
        }
    })

    const showForm = ref(false)

    function openForm(){
        showForm.value = true
    }
    function closeForm(){
        showForm.value = false
    }


    function contactCompanyRelationships (contact) {
        return contact.company_relationships.filter(relationship => relationship.company_id == props.company.id)[0]
    }

    function fetchNewCompanies() {
        emit('refetch-companies')
    }

</script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getCompanyPreviousCampaigns(payload){
  // console.log(payload.companyId);
  return axios({
      method: "get",
      url: `${BASE_URL}/campaign_histories/get_company_campaign_history/`,
      headers: { 
          'Authorization': `Token ${payload.token}`, 
          'Content-Type': 'application/json'
      },
      params: {
        'company_id': payload.companyId
      }
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}
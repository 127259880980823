<template>
    <base-card-select :isSelected="isSelected"  class="text-xs">
        <section class="flex flex-row justify-start items-center">
            <div class="pr-1 w-[70px]">
                {{ contact.id }}
            </div>
            <div class="w-5/6 flex flex-row items-center">
                <ContactPersonalInfo class="w-full" :contact="contact" @refetch-contacts="fetchNewContacts" />
            </div>
            <div class="w-1/6 flex flex-row items-center justify-end pr-2">
                <base-button v-if="showDetails" class="flex items-center" @click="toggleShowDetails"><div class="flex flex-row items-center justify-center">Details &#8964;</div></base-button>
                <base-button v-else class="flex items-center" @click="toggleShowDetails"><div class="flex flex-row items-center justify-center">Details &gt;</div></base-button>
            </div>
        </section>
        <section v-if="showDetails" class="flex flex-col justify-start items-start pr-2 pl-[66px] border-0 border-light-primary mt-6">
            <div class="flex flex-row border-t justify-between items-center border-b border-light-primary w-full py-1 text-[0.8rem]">
                <div>
                    Company Relationships:  
                </div>
                <base-button class="" @click="addCompanyRelationship">Add Company Relationship</base-button>
            </div>
            <ContactDetailCard
                class="my-1"
                :contactName="contact.first_name"
                :companyRelationship="companyRelationship"
                :key="companyRelationship.id"
                @refetch-contacts="fetchNewContacts"
            v-for="companyRelationship in contact.company_relationships.filter(companyRelationship => companyRelationship.is_primary_contact == true && companyRelationship.status == 'active')"
            />
            <ContactDetailCard
                class="my-1"
                :contactName="contact.first_name"
                :companyRelationship="companyRelationship"
                :key="companyRelationship.id"
                @refetch-contacts="fetchNewContacts"
                v-for="companyRelationship in contact.company_relationships.filter(companyRelationship => companyRelationship.is_primary_contact == false && companyRelationship.status == 'active')"
            />
            <CompanyRelationshipForm v-for="(row, index) in formRows" :key="row.key" :contact="row.contact" @refetch-contacts="fetchNewContacts" @close-form="closeContactRow(index)"/>
        </section>
    </base-card-select>
</template>

<script>
  export default {
    name: 'contact-card',
    data: () => ({
    }),
    methods: {
    }
  }
</script>

<script setup>
import { ref, computed, defineProps, defineEmits, watch, onMounted } from "vue";
import ContactPersonalInfo from "./contact-card-components/ContactPersonalInfo.vue";
import ContactDetailCard from "./ContactDetailCard.vue";
import CompanyRelationshipForm from "./CompanyRelationshipForm.vue"

const props = defineProps(['contact', 'selectedContactId', 'changedContactFilters', 'clearedSelectedContacts', 'clearedSelectedContactsOnPage', 'selectAll', 'selectAllOnPage', 'permissions', 'selectedContactsOnPageMap'])

const emit = defineEmits(['refetch-contacts', 'select-contact', 'deselect-contact'])


const fetchNewContacts = () => {
    emit('refetch-contacts')
}

const formRows = ref([])

const isSelected = ref(false)

const showDetails = ref(false)

onMounted(() => {
    if(props.contact.id == props.selectedContactId) {
        isSelected.value = true
    } else {
        isSelected.value = false
    }
})

watch(() => props.selectedContactId, () => {
    if(props.contact.id == props.selectedContactId) {
        isSelected.value = true
    } else {
        isSelected.value = false
    }
})

const toggleShowDetails = () => {
    if(showDetails.value) {
        showDetails.value = false
    } else {
        showDetails.value = true
    }
}
function addCompanyRelationship(){
    formRows.value.push(
        {key: Math.random(), contact: props.contact}
    )
}

function closeContactRow(index) {
        formRows.value.splice(index, 1)
    }


const contactName = computed(() => {
    return `${props.contact.first_name} ${props.contact.last_name}`
})

const selectContact = () => {
    emit('select-contact', props.contact.id)
}

const deselectContact = () => {
    isSelected.value = false
    emit('deselect-contact', props.contact.id)
}

</script>
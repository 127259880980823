<template>
    <div>
          <span  @mouseover="showPopover" @mouseleave="hidePopover"  ref="nameElement">{{ tierName }}</span>
          <base-popover-hover-small 
            v-if="isPopoverVisible"
            :xPosition="xPosition"
            :yPosition="yPosition"
          >
            <template v-slot:title>
              Previous Tier
            </template>
            <template v-slot:body>
                <div class="flex flex-col">
                    <span>Previous tier: {{company.previous_tier}}</span>
                    <span>Tier last updated: {{tierLastUpdated}}</span>
                </div>
            </template>
          </base-popover-hover-small>
    </div>
</template>

<script>
  export default {
      name: "tier-cell"
  }

</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";

  const isPopoverVisible = ref(false)
  const xPosition = ref(0)
  const yPosition = ref(0)
  const nameElement = ref(null)

  const props = defineProps({
      company: Object
  })

//   const emit = defineEmits(['refetch-companies'])

//   function fetchNewCompanies () {
//       emit('refetch-companies')
//   }


  function showPopover() {
            isPopoverVisible.value = true;
            xPosition.value = nameElement.value.getBoundingClientRect().right
            yPosition.value = nameElement.value.getBoundingClientRect().top
          }

  function hidePopover() {
            isPopoverVisible.value = false;
            xPosition.value = 0;
            yPosition.value = 0;
          }

    const tierName = computed(() => {
        return props.company.tier_object ? props.company.tier_object.tier_name : "--"
    })

    const tierLastUpdated = computed(() => {
        if(props.company.tier_last_updated_date){
            const updatedDate = new Date(props.company.tier_last_updated_date).toLocaleDateString("en-US")
            return updatedDate
        } else {
            return "--"
        }
    })

</script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getResearchGuide(token) {
    return axios({
        method: "get",
        url: `${BASE_URL}/research_guide`,
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => console.error(error))

}
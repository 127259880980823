<template>
    <div @dblclick="toggleEditable">
         <span v-if="isEditable">
            <select class="border rounded-md" @change="handleInputChange" :value="company.everaxis_pillar" name="" id="">
                <option v-for="option in selectOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
        </span>
        <span v-else >{{ company.everaxis_pillar ? company.everaxis_pillar : '--' }}</span>
    </div>
</template>

<script>
    export default {
        name: "everaxis-pillar-cell"
    }
</script>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { updateCompany } from '@/services/Company.js'
import { useStore } from "vuex";

    const isEditable = ref(false)

    const store = useStore()

    const props = defineProps({
        company:  Object
    })

    const emit = defineEmits(['refetch-companies'])

    function toggleEditable () {
      isEditable.value = !isEditable.value
  }

  const selectOptions = ref([
    {value: '', label: 'None'},
    {value: 'P1: Peers', label: 'P1: Peers'},
    {value: 'P2: EverAxis in Motion', label: 'P2: EverAxis in Motion'},
    {value: 'P3: Forever Connecting', label: 'P3: Forever Connecting'},    
  ])


  async function handleInputChange(e) {
    let newValue = e.target.value
    const targetField = "everaxis_pillar"
    let updatedData = {}
    updatedData[targetField] = newValue
    const data = JSON.stringify(updatedData);
    console.log('data', data)
    let token = store.state.auth.token
    console.log('new value', newValue)
    await updateCompany(props.company.id, data, token)
    emit('refetch-companies')
    isEditable.value = false
}
    

</script>
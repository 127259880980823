<template>
    <th v-if="header===' '" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-4 rounded-tl-lg">
        <slot></slot>
    </th>
    <th v-else-if="header==='ID'" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-14">
      <slot></slot>
    </th>
    <th v-else-if="header==='Name / Cage Code'" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-60">
      <slot></slot>
    </th>
    <th v-else-if="header==='Cage Code'" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-14">
      <slot></slot>
    </th>
    <th v-else-if="header==='Location / URL'" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-60">
      <slot></slot>
    </th>
    <th v-else-if="header==='Rev / HC'" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-32">
      <slot></slot>
    </th>
    <th v-else-if="header==='Governing Specifications'" class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5 w-32">
      <slot></slot>
    </th>
    <th v-else class="bg-gray-100 border h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5">
      <slot></slot>
    </th>
  </template>
  
  <script>
  export default {
    name: "base-table-header-condensed",
    props: ['header', 'alignment'],
  }
  </script>
  
  <style scoped>
  </style>
<template>
  <div class="main h-screen bg-[#AAABB8] flex flex-col place-content-center">
    <div class="flex flex-row place-content-center">
      <form class=" w-border-r-white border-r-2" @submit.prevent="submitForm">
        <div class="text-white text-3xl font-bold mx-4 mr-16"> The Aerocle at Delphli</div>

        <div class="flex flex-row h-12 m-2">
          <div class="w-14 h-12 bg-white flex flex-row justify-evenly items-center rounded-l-md">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 class="w-10 h-10 p-1 bg-white">
              <path fill-rule="evenodd"
                    d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
          <input type="text" id="username" class="rounded-r-md mr-12 border-l-2 border-gray-300 w-10/12 px-4 focus:outline-0" placeholder="Username" v-model.trim="username"/>
        </div>

        <div class="flex flex-row h-12 m-2 ">
          <div class="w-14 h-12 bg-white flex flex-row justify-evenly items-center rounded-l-md" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 class="w-9 h-9 p-1 bg-white">
              <path fill-rule="evenodd"
                    d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                    clip-rule="evenodd"/>
            </svg>
          </div>

          <input type="password" id="password" class="rounded-r-md mr-12 border-l-2 border-gray-300 w-10/12 px-4 focus:outline-0" placeholder="Password" v-model.trim="password"/>
        </div>

        <div class="flex flex-row justify-between pr-2 mr-12">
          <div class="mt-1">
            <a class="text-white font-bold mx-2" href="#">Forgot password?</a>
          </div>
            <button
                class="px-2 py-2 bg-black text-white font-bold leading-tight uppercase
                rounded shadow-md
                focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg
                transition duration-150 ease-in-out">
              Sign In
            </button>
        </div>
        <!--      <div id="login-error">-->
        <!--        {{ error }}-->
        <!--      </div>-->
        <div class="h-24"></div>
      </form>
      <div class="logo my-auto">
        <img id="img-gray-jet" src="../assets/images/gray_jet.png" alt="gray jet" class="w-96 "/>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        formIsValid: true,
        mode: 'login',
        isLoading: false,
        error: null,
      };
    },
    computed: {
      submitButtonCaption() {
        if (this.mode === 'login') {
          return 'Login';
        } else {
          return 'Signup';
        }
      },
      switchModeButtonCaption() {
        if (this.mode === 'login') {
          return 'Signup instead';
        } else {
          return 'Login instead';
        }
      },
    },
    methods: {
      async submitForm() {
        this.formIsValid = true;
        console.log('submit form')
        if (
          this.username === '' ||
          this.password.length < 6
        ) {
          this.formIsValid = false;
          return;
        }
  
        this.isLoading = true;
  
        const userInfo = {
          username: this.username,
          password: this.password,
        };
  
        try {
          if (this.mode === 'login') {
            await this.$store.dispatch('auth/getToken', userInfo);
          }
        //   } else {
        //     await this.$store.dispatch('signup', actionPayload);
        //   }
          const redirectUrl = '/';
          this.$router.replace(redirectUrl);
        } catch (err) {
          this.error = err.message || 'Failed to authenticate, try later.';
        }
  
        this.isLoading = false;
      },
      switchAuthMode() {
        if (this.mode === 'login') {
          this.mode = 'signup';
        } else {
          this.mode = 'login';
        }
      },
      handleError() {
        this.error = null;
      },
    },
  };
  </script>

  <style scoped>

  div.main {
    background: #AAABB8; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #AAABB8 1%, #25274D 100%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%, #AAABB8), color-stop(100%, #25274D)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover, #AAABB8 1%, #25274D 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover, #AAABB8 1%, #25274D 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover, #AAABB8 1%, #25274D 100%); /* IE10+ */
    background: radial-gradient(ellipse at center, #AAABB8 1%, #25274D 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#AAABB8', endColorstr='#25274D', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    height: calc(100vh);
    width: 100%;
  }

  /*form {*/
  /*  margin: 1rem;*/
  /*  padding: 1rem;*/
  /*}*/
  
  /*label {*/
  /*  font-weight: bold;*/
  /*  margin-bottom: 0.5rem;*/
  /*  display: block;*/
  /*}*/
  
  /*input,*/
  /*textarea {*/
  /*  display: block;*/
  /*  width: 100%;*/
  /*  font: inherit;*/
  /*  border: 1px solid #ccc;*/
  /*  padding: 0.15rem;*/
  /*}*/
  
  /*input:focus,*/
  /*textarea:focus {*/
  /*  border-color: #3d008d;*/
  /*  background-color: #faf6ff;*/
  /*  outline: none;*/
  /*}*/
  </style>
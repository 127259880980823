import { getContacts, updateContact } from '../../../services/Contact.js';

export default {
    async setContacts (context, endpoint) {
        console.log('setCompanies action, context = ', context)
        let payload = {
            token: context.rootGetters['auth/showToken'],
            endpoint: endpoint
        }
        const result = await getContacts(payload)
        console.log('getContacts async ran')
        context.commit('setContacts', result.contacts)
        context.commit('setNext', result.next)
        context.commit('setPrev', result.prev)
        context.commit('setCount', result.count)
        context.commit('setPage', result.page)
        context.commit('setPageSize', result.pageSize)
    },
    async updateContact (context, contactData) {
        console.log('updateContact action')
    },
    setIsLoadingFalse(context) {
        context.commit('setIsLoadingFalse')
    },
    setIsLoadingTrue(context) {
        context.commit('setIsLoadingTrue')
    },
    setActiveFilters(context, activeFiltersArray) {
        context.commit('setActiveFilters', activeFiltersArray)
    },
    setShowCreateContactFormTrue(context) {
        context.commit('setShowCreateContactFormTrue')
    },
    setShowCreateContactFormFalse(context) {
        context.commit('setShowCreateContactFormFalse')
    },
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
    setShowBasicContactFiltersFalse(context) {
        context.commit('setShowBasicContactFiltersFalse')
    },
    setShowBasicContactFiltersTrue(context) {
        context.commit('setShowBasicContactFiltersTrue')
    },
    setShowBasicCompanyFiltersFalse(context) {
        context.commit('setShowBasicCompanyFiltersFalse')
    },
    setShowBasicCompanyFiltersTrue(context) {
        context.commit('setShowBasicCompanyFiltersTrue')
    },
    setShowBulkOperationsPaneFalse(context) {
        context.commit('setShowBulkOperationsPaneFalse')
    },
    setShowBulkOperationsPaneTrue(context) {
        context.commit('setShowBulkOperationsPaneTrue')
    },
    setVisibleFilters(context, payload){
        context.commit('setVisibleFilters', payload)
    },
    setVisibleFiltersAllTrue(context){
        context.commit('setVisibleFiltersAllTrue')
    }
};
<template>
    <transition-group tag="base-card"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <base-card key="filter" class="transition-all duration-300 ease-out" v-show="showFilter">
        <CompanyFilters v-if="showFilterPane" @change-company-filters="setCompanyFilters" @set-changed-company-filters="setChangedCompanyFilters"></CompanyFilters>
        <div v-show="showTableFilters" class="">
          <div class="ml-2.5 pt-3 pb-0.5 border-b mb-1 border-b-gray-400 flex flex-col justify-between text-sm">
            <h1 class="">CRM Status Summary Table Columns:</h1>
          </div>
          <div class="ml-1.5 w-1/2 mb-2">
            <base-filter-no-button>
                <template v-slot:label> Columns </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md dark:text-black text-xs"
                        placeholder="Select Columns"
                        v-model="tableColsSelected"
                        :options="tableColsOptions"
                        @update:modelValue="setTableHeaders"
                        :multiple="true"
                    > </base-list-box>
                </template>
            </base-filter-no-button>
          </div>
        </div>
      </base-card>
      <base-card key="companyTable" class="transition-all duration-300 ease-out overflow-hidden">
        <div class="flex flex-col ml-3">
          <div class="flex flex-row justify-between mb-4">
            <div class="flex flex-row items-center text-sm ">
                <div class="flex flex-col justify-center">
                    <div>
                        <span>Summary: </span>
                    </div>
                    <div class="flex">
                        <span>Company Count: {{ companyCount }}</span>
                    </div>

                </div>
              <div class="mx-1 print:hidden">
                <div v-if="isLoading" role="status" class="pl-3">
                  <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
                <div  v-else class="pl-3">
                  <svg @click="loadCompCompanies(fetchFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="#25274D" class="mx-1 w-8 h-8 hover:cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                  </svg>
                </div>
              </div>
              <div class="text-sm flex ml-6 print:hidden">
                <base-menu-button @click="toggleFilter">{{ showFilterText }}</base-menu-button>
                <base-menu-button v-if="collapsed" @click="expandAll">Expand All</base-menu-button>
                <base-menu-button v-else @click="collapseAll">Collapse All</base-menu-button>
              </div>
            </div>
          </div>
          <div class="h-full" >
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Under LOI']"
              :statusGroup="'Under LOI'"
              :companies="groupedByStatusCompanies['Under LOI']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['IOI Submitted']"
              :statusGroup="'IOI Submitted'"
              :companies="groupedByStatusCompanies['IOI Submitted']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Active - In Dialogue']"
              :statusGroup="'Active - In Dialogue'"
              :companies="groupedByStatusCompanies['Active - In Dialogue']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Revisit / Cut']"
              :statusGroup="'Revisit / Cut'"
              :companies="groupedByStatusCompanies['Revisit / Cut']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Responded / Coverage']"
              :statusGroup="'Responded / Coverage'"
              :companies="groupedByStatusCompanies['Responded / Coverage']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Not Now/Keep Warm']"
              :statusGroup="'Not Now/Keep Warm'"
              :companies="groupedByStatusCompanies['Not Now/Keep Warm']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Hold']"
              :statusGroup="'Hold'"
              :companies="groupedByStatusCompanies['Hold']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Hard No']"
              :statusGroup="'Hard No'"
              :companies="groupedByStatusCompanies['Hard No']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Client Pass']"
              :statusGroup="'Client Pass'"
              :companies="groupedByStatusCompanies['Client Pass']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Dead - Acquired by Other Party']"
              :statusGroup="'Dead - Acquired by Other Party'"
              :companies="groupedByStatusCompanies['Dead - Acquired by Other Party']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Closed']"
              :statusGroup="'Closed'"
              :companies="groupedByStatusCompanies['Closed']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['No Response']"
              :statusGroup="'No Response'"
              :companies="groupedByStatusCompanies['No Response']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Not Contacted']"
              :statusGroup="'Not Contacted'"
              :companies="groupedByStatusCompanies['Not Contacted']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['Research Further']"
              :statusGroup="'Research Further'"
              :companies="groupedByStatusCompanies['Research Further']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <CampaignCrmStatusSummaryTable
              v-if="groupedByStatusCompanies['None']"
              :statusGroup="'None'"
              :companies="groupedByStatusCompanies['None']"
              :tableHeaders="tableHeaders"
              :collapsed="collapsed"
              @refetch-companies="loadCompCompanies(fetchFilter)"
            />
            <!-- <base-table v-for="(value, key) in groupedByStatusCompanies" :key="key" class="mb-5">
              <template #table-title>
                {{ key }} ({{ value.length }})
              </template>
              <template #table-header>
                <base-table-header :class="header.width" :key="header.id" :header="header" v-for="header in tableHeaders"> {{
                    header.label
                  }}
                </base-table-header>
              </template>
    
              <template #table-body>
                <CampaignCrmStatusSummaryRow v-for="(item, index) in value" :company="item" :key="item.id" :index="index" />
              </template>
            </base-table> -->        
          </div>  
        </div>
      </base-card>
      <div class="flex justify-center bg-white p-5" v-if="noFilters">
                <span class="text-lg font-bold">Add a filter to load companies</span>
      </div>
    </transition-group>
</template>

<script>
    export default {
    name: 'campaign-crm-status-summary',
    data: () => ({
    }),
    methods: {
    }
    }
</script>
  
<script setup>
  import {ref, reactive, shallowReactive, computed, watch} from 'vue';
  import {useStore} from 'vuex';
  import CompanyFilters from './CompanyFilters.vue';
  import BaseCard from "@/components/BaseCard";
  import {getCampaignActivitiesSummary} from '../services/Company.js';
  import CampaignCrmStatusSummaryTable from './CampaignCrmStatusSummaryTable.vue';

  

  
  const showFilterPane = computed(() => store.getters["company/showFilterPane"]);

  const isLoading = ref(false);
  const groupedByStatusCompanies = ref({});
//   const tierCount = ref({})
  const companyCount = ref(null)
  const campaigns = ref([])
  const next = ref(null);
  const prev = ref(null);
  const count = ref(null);
  const page = ref(null);
  const pageSize = ref(null);
  const companyFilters = reactive({
    companyNameFilter: null,
    companyDescriptionFilter: null,
    companyAddressFilter: null,
    companySourcesFilter: null,
    companySourceOwnerFilter: null,
    companyCampaignFilter: null,
    companyTierFilter: null,
    companyClientFeedbackFilter: null,
    companyOnboardingStatusFilter: null,
    companyDNRReasonFilter: null,
    companyResearchStatusFilter: null,
    companyResearchStatusExpressionFilter: null,
    companyResearchStatusFilterTwo: null,
    companyResearchStatusExpressionFilterTwo: null,
    companyMinRevenueFilter: null,
    companyMaxRevenueFilter: null,
    companyMinRevenueZiCombinedFilter: null,
    companyMaxRevenueZiCombinedFilter: null,
    companyClientFilter: null,
    companyBusinessTypeFilter: null,
    companySectorFilter: null,
    companySubSectorFilter: null,
    companyHasCampaignFilter: null,
    companyCRMStatusFilter: null,
    companyCRMNextActionFilter: null,
    companyDueTimeRangeFilter: null,
    companyCollectionTagsFilter: null,
    companyStateFilter: null,
    companyProductServiceCodeFilter: null,
  });
  const showFilter = ref(true);
  const showFilterText = ref('Hide Filter');
  const changedCompanyFilters = ref(false);
  const showBulk = ref(true);
  const showBulkText = ref('Hide Bulk Ops');
  const selectedCompanies = ref([]);
  const selectAll = ref(false);
  const clearedSelectedCompanies = ref(false);
  const awaitingSearch = ref(false);
  const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
  });

  const store = useStore()
  const noFilters = ref(false)

  const collapsed = ref(true)
  
  const pageSizeOptions = ref([
    {value: "clear", label: "----"},
    {value: 10, label: "10"},
    {value: 25, label: "25"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 200, label: "200"},
  ])

  const tableHeaders = ref(['', 'Company Name']);

  const tableColsSelected = ref([]);

  const tableColsOptions = ref([
      {value: 'ID', label: 'ID'},
      {value: 'Revenue', label: 'Revenue'},
      {value: 'Full-Time Equivalent', label: 'Full-Time Equivalent'},
      {value: 'Square Footage', label: 'Square Footage'},
      {value: 'Location', label: 'Location'},
      {value: 'Account Manager Research Notes', label: 'Account Manager Research Notes'},
      {value: 'Primary Contacts', label: 'Primary Contacts'},
      {value: 'Client Feedback Notes', label: 'Client Feedback Notes'},
      {value: 'Client Update Notes', label: 'Client Update Notes'},
      {value: 'CRM Summary', label: 'CRM Summary'},

  ]);

  const showTableFilters = computed(() => store.getters['company/showTableFilters'])
  
  watch(() => companyFilters, () => {
        if (!awaitingSearch.value) {
          setTimeout(() => {
            loadCompCompanies();
            awaitingSearch.value = false;
          }, 2500); // 2.5 sec delay
        }
        awaitingSearch.value = true;
      },
      {deep: true}
  );
  
  function setTableHeaders(target) {
      if (target.length == 0) {
          tableColsSelected.value = [];
      } else {
          tableColsSelected.value = target;
      }
      tableHeaders.value = [];
      tableHeaders.value.push('');
      if(tableColsSelected.value.includes('ID')) {
          tableHeaders.value.push('ID');
      }
      tableHeaders.value.push('Company Name');
      if(tableColsSelected.value.includes('Revenue')) {
          tableHeaders.value.push('Revenue');
      }
      if (tableColsSelected.value.includes('Full-Time Equivalent')) {
          tableHeaders.value.push('Full-Time Equivalent');
      }
      if (tableColsSelected.value.includes('Square Footage')) {
          tableHeaders.value.push('Square Footage');
      }
      if (tableColsSelected.value.includes('Location')) {
          tableHeaders.value.push('Location');
      }
      if (tableColsSelected.value.includes('Account Manager Research Notes')) {
          tableHeaders.value.push('Account Manager Research Notes');
      } 
      if (tableColsSelected.value.includes('Primary Contacts')) {
          tableHeaders.value.push('Primary Contacts');
      }
      if (tableColsSelected.value.includes('Client Feedback Notes')) {
          tableHeaders.value.push('Client Feedback Notes');
      }
      if (tableColsSelected.value.includes('Client Update Notes')) {
          tableHeaders.value.push('Client Update Notes');
      }
      if (tableColsSelected.value.includes('CRM Summary')) {
          tableHeaders.value.push('CRM Summary');
      }
  }

  function expandAll () {
    collapsed.value = false
  }
  function collapseAll() {
    collapsed.value = true
  }
  
  async function loadCompCompanies(filter = fetchFilter.value) {
    // console.log('loadCompCompanies summary page')
    if(fetchFilter.value === "?" || fetchFilter.value == "?research_statuses_lookup=&research_statuses_2_lookup=&state_lookup=or"){
        noFilters.value = true
        groupedByStatusCompanies.value = null
        companyCount.value = null
    } else {
        noFilters.value = false
        isLoading.value = true;
        let token = store.state.auth.token
        const result = await getCampaignActivitiesSummary(filter, token);
        console.log("result after await", result)
        groupedByStatusCompanies.value = result.grouped_by_status_companies
        // tierCount.value = result.tier_count
        // console.log('tierCount', tierCount)
        companyCount.value = result.company_count
        isLoading.value = false
    }
  }
  
  function setChangedCompanyFilters(){
    changedCompanyFilters.value = true;
  }
  
  const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    // console.log('user permissions store', userPermissionsStore)
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies =  userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  
    // console.log('user permissions', userPermissions)
  }
  
  const RenderBulkOperations = computed(function () {
    if(userPermissions.isAccountManager){
      return true
    }
    if(userPermissions.isAccountStaff){
      return true
    }
    if(userPermissions.isDev){
      return true
    }
    if(userPermissions.isManagement){
      return true
    }
    if(userPermissions.isResearchLead){
      return true
    }
    if(userPermissions.isSuperuser){
      return true
    }
    return false
  })
  
  
  function toggleFilter() {
    showFilter.value = !showFilter.value;
    if (showFilterText.value === 'Hide Filter') {
      showFilterText.value = 'Show Filter'
    } else {
      showFilterText.value = 'Hide Filter'
    }
  }

  

  function setCompanyFilters(updatedFilters) {
    companyFilters.companyNameFilter = updatedFilters.value.companyNameFilter;
    companyFilters.companyDescriptionFilter = updatedFilters.value.companyDescriptionFilter;
    companyFilters.companyAddressFilter = updatedFilters.value.companyAddressFilter;
    companyFilters.companySourcesFilter = updatedFilters.value.companySourcesFilter;
    companyFilters.companySourceOwnerFilter = updatedFilters.value.companySourceOwnerFilter;
    companyFilters.companyCampaignFilter = updatedFilters.value.companyCampaignFilter;
    companyFilters.companyTierFilter = updatedFilters.value.companyTierFilter;
    companyFilters.companyClientFeedbackFilter = updatedFilters.value.companyClientFeedbackFilter;
    companyFilters.companyOnboardingStatusFilter = updatedFilters.value.companyOnboardingStatusFilter;
    companyFilters.companyDNRReasonFilter = updatedFilters.value.companyDNRReasonFilter;
    companyFilters.companyResearchStatusFilter = updatedFilters.value.companyResearchStatusFilter;
    companyFilters.companyResearchStatusExpressionFilter = updatedFilters.value.companyResearchStatusExpressionFilter;
    companyFilters.companyResearchStatusFilterTwo = updatedFilters.value.companyResearchStatusFilterTwo;
    companyFilters.companyResearchStatusExpressionFilterTwo = updatedFilters.value.companyResearchStatusExpressionFilterTwo;
    companyFilters.companyMinRevenueFilter = updatedFilters.value.companyMinRevenueFilter;
    companyFilters.companyMaxRevenueFilter = updatedFilters.value.companyMaxRevenueFilter;
    companyFilters.companyMinRevenueZiCombinedFilter = updatedFilters.value.companyMinRevenueZiCombinedFilter;
    companyFilters.companyMaxRevenueZiCombinedFilter = updatedFilters.value.companyMaxRevenueZiCombinedFilter;
    companyFilters.companyClientFilter = updatedFilters.value.companyClientFilter;
    companyFilters.companyBusinessTypeFilter = updatedFilters.value.companyBusinessTypeFilter;
    companyFilters.companySectorFilter = updatedFilters.value.companySectorFilter;
    companyFilters.companySubSectorFilter = updatedFilters.value.companySubSectorFilter;
    companyFilters.companyCRMStatusFilter = updatedFilters.value.companyCRMStatusFilter;
    companyFilters.companyCRMNextActionFilter = updatedFilters.value.companyCRMNextActionFilter;
    companyFilters.companyDueTimeRangeFilter = updatedFilters.value.companyDueTimeRangeFilter;
    companyFilters.companyCollectionTagsFilter = updatedFilters.value.companyCollectionTagsFilter;
    companyFilters.companyStateFilter = updatedFilters.value.companyStateFilter;
    companyFilters.companyProductServiceCodeFilter = updatedFilters.value.companyProductServiceCodeFilter;
  }
  
  
  const fetchFilter = computed(function () {
    // (A) URL SEARCH PARAMS OBJECT TO QUICKLY BUILD QUERY STRING
    let query = new URLSearchParams({
      // name: "John Doe",
      // email: "john@doe.com",
      // colors: JSON.stringify(["Red", "Green", "Blue"])
    });
    if (companyFilters.companyNameFilter) {
      query.append("company_name", companyFilters.companyNameFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueFilter) {
      query.append("min_revenue", companyFilters.companyMinRevenueFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueFilter) {
      query.append("max_revenue", companyFilters.companyMaxRevenueFilter); // To append more data
    }
    if (companyFilters.companyMinRevenueZiCombinedFilter) {
      query.append("min_revenue_combined", companyFilters.companyMinRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyMaxRevenueZiCombinedFilter) {
      query.append("max_revenue_combined", companyFilters.companyMaxRevenueZiCombinedFilter); // To append more data
    }
    if (companyFilters.companyDescriptionFilter) {
      query.append("description", companyFilters.companyDescriptionFilter); // To append more data
    }
    if (companyFilters.companyAddressFilter) {
      query.append("address_search", companyFilters.companyAddressFilter); // To append more data
    }
    if (companyFilters.companyOnboardingStatusFilter) {
      query.append("onboarding_status", companyFilters.companyOnboardingStatusFilter); // To append more data
    }
    if (companyFilters.companyDNRReasonFilter) {
      query.append("do_not_research_reason", companyFilters.companyDNRReasonFilter); // To append more data
    }
    if (companyFilters.companyClientFilter) {
      companyFilters.companyClientFilter.forEach(client => {
          query.append("client_drf", client)
        })
    }
    if (companyFilters.companySourcesFilter) {
        companyFilters.companySourcesFilter.forEach(source => {
          query.append("source_object", source)
        })
  
    }
    if (companyFilters.companySourceOwnerFilter) {
        companyFilters.companySourceOwnerFilter.forEach(owner => {
          query.append("source_owners", owner)
        })
    }
    if (companyFilters.companyCampaignFilter) {
        companyFilters.companyCampaignFilter.forEach(campaign => {
          query.append("campaign", campaign)
        })
    }
    if (companyFilters.companyTierFilter) {
        companyFilters.companyTierFilter.forEach(tier => {
          query.append("tier_selection", tier)
        })
    }
    if (companyFilters.companyClientFeedbackFilter) {
        companyFilters.companyClientFeedbackFilter.forEach(feedback => {
          query.append("client_feedback", feedback)
        })
    }
    if (companyFilters.companyResearchStatusFilter) {
        companyFilters.companyResearchStatusFilter.forEach(status => {
          query.append("research_statuses", status)
        })
        query.append("research_statuses_lookup", companyFilters.companyResearchStatusExpressionFilter)
    }
    if (companyFilters.companyResearchStatusFilterTwo) {
        companyFilters.companyResearchStatusFilterTwo.forEach(status => {
          query.append("research_statuses_2", status)
        })
        query.append("research_statuses_2_lookup", companyFilters.companyResearchStatusExpressionFilterTwo)
    }
    if (companyFilters.companyBusinessTypeFilter) {
        companyFilters.companyBusinessTypeFilter.forEach(businessType => {
          query.append("business_type", businessType)
        })
    }
    if (companyFilters.companySectorFilter) {
        companyFilters.companySectorFilter.forEach(sector => {
          query.append("sector", sector)
        })
    }
    if (companyFilters.companySubSectorFilter) {
        companyFilters.companySubSectorFilter.forEach(subSector => {
          query.append("sub_sector", subSector)
        })
    }
    if (companyFilters.companyCRMStatusFilter) {
        companyFilters.companyCRMStatusFilter.forEach(status => {
          query.append("crm_status", status)
        })
    }
    if (companyFilters.companyCRMNextActionFilter) {
        companyFilters.companyCRMNextActionFilter.forEach(action => {
          query.append("next_action", action)
        })
    }
    if (companyFilters.companyDueTimeRangeFilter) {
        companyFilters.companyDueTimeRangeFilter.forEach(time => {
          query.append("due_date", time)
        })
    }
    if (companyFilters.companyCollectionTagsFilter) {
        companyFilters.companyCollectionTagsFilter.forEach(collectionTag => {
          query.append("collection_tag", collectionTag)
        })
    }
    if (companyFilters.companyStateFilter) {
        companyFilters.companyStateFilter.forEach(state => {
            query.append("state", state)
        })
        query.append("state_lookup", 'or')
    }
    if (companyFilters.companyProductServiceCodeFilter) {
      query.append("product_service_code", companyFilters.companyProductServiceCodeFilter); // To append more data
    }
  
  // (B) CONVERT TO STRING, APPEND TO URL
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      // console.log(value)
      queriesCount = queriesCount + 1;
    }
    // console.log("queriesCount", queriesCount)
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    console.log(url);
    return url
  // (C) WHATEVER COMES NEXT...
  // REDIRECT OR AJAX CALL OR FETCH
  // window.location.href = url;
  })
  
  
  setUserPermissions()
  // loadCompCompanies()
</script>

<style scoped>
</style>
  
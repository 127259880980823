<template>
    <div class="fixed border border-gray-300 rounded-md z-30 w-fit text-left" :style="popoverPosition">
        <div class="bg-gray-100 px-2 py-1 text-[0.8rem] font-semibold w-full block rounded-t-md border-b border-gray-300">
            <slot name="title"></slot>
        </div>
        <div class="w-full text-xs p-2 bg-white rounded-b-md">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script setup>
import { defineProps, computed } from "vue"; 

const props = defineProps({
    xPosition: Number,
    yPosition: Number,
})

const popoverPosition = computed(function () {
    return `left: ${props.xPosition + 5}px; top: ${props.yPosition}px;`
})


</script>
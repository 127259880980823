<template>
    <base-dblclick-input
        modelType="company"
        :modelId="company.id"
        fieldName="phone"
        :fieldValue="company.phone"
        inputType="text"
        @refetch="fetchNewCompanies"
    >
    <div class="whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">Company General Line: </div>
    </base-dblclick-input>
</template>

<script>
  export default {
      name: "company-phone-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object,
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>
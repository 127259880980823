<template>
    <div>
        <div v-if="noCompaniesLoaded" class="flex-col mb-2 lg:border-t lg:border-light-primary lg:pt-2">
            Load Companies To View CRM Status And Next Action Summary.
        </div>
        <div v-else class="flex-col mb-2 lg:border-t lg:border-light-primary lg:pt-2">
            <div v-if="loadingCrmStatuses">
                Loading CRM Status Summary <div class="inline text-base"><div  class="animate-[pulse_1s_infinite_50ms] inline-block">.</div><div  class="animate-[pulse_1s_infinite_100ms] inline-block ">.</div><div  class="animate-[pulse_1s_infinite_150ms] inline-block">.</div></div>
            </div>
            <div v-else>
                {{ crmStatuses }}
            </div>
            <div v-if="loadingCrmActions">
                Loading CRM Action Summary <div class="inline text-base"><div  class="animate-[pulse_1s_infinite_50ms] inline-block">.</div><div  class="animate-[pulse_1s_infinite_100ms] inline-block ">.</div><div  class="animate-[pulse_1s_infinite_150ms] inline-block">.</div></div>
            </div>
            <div v-else>
                {{ crmNextActions }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "crm-status-summary"
    }
</script>

<script setup>
import { defineProps, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { getCRMSummary } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        query: String,
        companyCount: Number,
        companies: Array
    })

    const crmStatuses = ref('')
    const crmNextActions = ref('')
    const loadingCrmStatuses = ref(true)
    const loadingCrmActions = ref(true)

    const companiesCount = computed(() => props.companyCount)
    const companies = computed(() => props.companies)
    const noCompaniesLoaded = computed(() => props.companyCount > 0 ? false : true)

    async function setSummaries(){
        loadingCrmStatuses.value = true
        loadingCrmActions.value = true
        const token = store.state.auth.token
        let searchQuery = props.query
        searchQuery = searchQuery.replace('companies', '')
        console.log('search query', searchQuery)
        if(!props.companyCount){
            console.log('Cannot get CRM Status and Next Action Summary: No companies loaded.')
            // store.dispatch('company/setShowStatusSummaryPaneFalse')
        }else 
        if(props.companyCount > 1000 && props.companyCount < 3000){
            if(confirm(`Are you sure you want the CRM Status and Next Action Summary for ${props.companyCount} companies? Loading this data might take some time.`)){
                const result = await getCRMSummary(searchQuery, token)
                crmStatuses.value = result.crmStatuses
                crmNextActions.value = result.crmNextActions
                loadingCrmStatuses.value = false
                loadingCrmActions.value = false
            } else  {
                store.dispatch('company/setShowStatusSummaryPaneFalse')
            }
        }else if(props.companyCount > 3000){
            alert('Cannot get CRM Status and Next Action Summary: Exceeds 3000 Company Limit')
            store.dispatch('company/setShowStatusSummaryPaneFalse')
        } else {
            const result = await getCRMSummary(searchQuery, token)
            crmStatuses.value = result.crmStatuses
            crmNextActions.value = result.crmNextActions
            loadingCrmStatuses.value = false
            loadingCrmActions.value = false
        }
    }

    watch(companies, ()=>{
        setSummaries()
    })



    setSummaries()



</script>

<template>
    <div @dblclick="toggleEditable" class="w-full cursor-pointer">
        <slot></slot>
        <span v-if="isEditable" class="inline w-full">
            <BaseEditTextArea
                :modelId="modelId"
                :modelType="modelType" 
                :inputText="fieldValue" 
                :fieldName="fieldName"
                @toggle-editable="toggleEditable"
                @refetch="refetch"
                @update-field-value="setField"
                @close-modal="close"
                class="w-full"
            />
        </span>
        <span v-else
            class="inline whitespace-pre-wrap"
            :style="fieldStyle"
        >
            {{ displayedField }}
        </span>
    </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, ref, watch} from 'vue';
import BaseEditTextArea from './BaseEditTextArea.vue';

const props = defineProps([
    'modelId',
    'modelType',
    'fieldName',
    'fieldValue',
    'fieldStyle',
])

const emit = defineEmits(['refetch', 'close', 'is-editable', 'not-editable'])

const field = ref()
const isEditable = ref(false)

const displayedField = computed(() => {
    if(field.value) {
        return field.value
    } else if (props.fieldValue) {
        return props.fieldValue
    } else {
        return '--'
    }
})

watch(props, ()=> {
    field.value = null
})

const displayedFieldStyle = computed(() => {
    if(props.fieldStyle) {
        return props.fieldStyle
    } else {
        return ''
    }
})

function toggleEditable () {
    isEditable.value = !isEditable.value
    if(isEditable.value) {
        emit('is-editable')
    } else {
        emit('not-editable')
    }
}

function setField(newValue) {
    field.value = newValue
}

function refetch() {
    emit('refetch')
}

function close() {
    emit('close')
}

</script>
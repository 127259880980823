<template>
    <div>
        <span>{{revenueHeadcount}}</span> <SquareFootageCell class="inline" :company="company" @refetch-companies="fetchNewCompanies" />
    </div>
    <div>
        <span><base-pill>Rev. Last Upd:</base-pill>{{ formattedRevenueDate }}</span>
    </div>
    <div>
        <span><base-pill>Rev. Source:</base-pill>{{ formattedRevenueSource }}</span>
    </div>
</template>

<script>
    export default {
        name: "revenue-headcount-square-footage-revenue-last-updated-cell"
    }
</script>

<script setup>
import {defineProps, computed, defineEmits} from "vue"
import {formatDate} from '@/composables/formatDate'
import SquareFootageCell from "./SquareFootageCell.vue";


    const props = defineProps({
        company: Object
    })

    const revenueHeadcount = computed(() => {
        let revenue = props.company.revenue ? `${props.company.revenue} M` : '--'
        let headcount = props.company.employee_headcount ? `${props.company.employee_headcount} ppl` : '--'
        // let square_footage = props.company.square_footage ?  `${props.company.square_footage} K` : '--'
        return `${revenue} / ${headcount} / `
    })

    const formattedRevenueSource = computed(() => {
        if(props.company.revenue_source) {
            return props.company.revenue_source
        } else {
            return '--'
        }
    })

    const emit = defineEmits(['refetch-companies'])

    const formattedRevenueDate = computed(() => {
        // console.log(props.revenueLastUpdatedDate)
        try {
            return formatDate(props.company.revenue_last_updated_date)
        } catch {
            return '--'
        }
    })

    function fetchNewCompanies(){
        emit('refetch-companies')
    }



</script>
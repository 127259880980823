<template>
    <div class="flex flex-col pt-1 pb-1 justify-center h-9 -ml-1 mr-1">
        <button type="button" @click="setAllActive" :class="{'w-full border-r border-t border-t-light-primary border-r-light-primary rounded-tr h-full p-1 bg-blue-500 text-white text-sm' : allActive, 'w-full border-r border-t border-t-light-primary border-r-light-primary rounded-tr-md p-1 h-full bg-gray-300 text-gray-500 text-sm' : mineActive}" >All</button>
        <button type="button" @click="setMineActive" :class="{'w-full rounded-br border-r border-b border-b-light-primary border-r-light-primary h-full p-1 bg-blue-500 text-white text-sm' : mineActive, 'w-full border-r border-b border-b-light-primary border-r-light-primary rounded-br-md p-1 h-full bg-gray-300 text-gray-500 text-sm' : allActive}" >Mine</button>
    </div>
</template>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";

    const allActive = ref(false)
    const mineActive = ref(false)

    const activeButton = ref('all')
    const props = defineProps({
        activeButton : String
    })

    function setAllActive() {
        mineActive.value = false
        allActive.value = true
        activeButton.value = 'all'
        emit('updateActiveButton', 'all')
    }

    function setMineActive() {
        allActive.value = false
        mineActive.value = true
        activeButton.value = 'mine'
        emit('updateActiveButton', 'mine')
    }

    function determineActive(){
        if(props.activeButton == 'all'){
            allActive.value = true
            mineActive.value = false
        } else if (props.activeButton == 'mine'){
            allActive.value = false
            mineActive.value = true
        }
    }

    const emit = defineEmits(['updateActiveButton'])

    const activeClass = "rounded p-1 bg-blue-500 text-white text-sm"
    const inactiveClass = "rounded p-1 bg-gray-300 text-gray-800 text-sm"

    watch(() => props.activeButton, () => {
        console.log('activebutton', props.activeButton)
        determineActive()
        console.log(allActive.value)
        console.log(mineActive.value)
    })

    determineActive()

</script>
<template>
    <div class="print:hidden mx-1">
        <div>
            <div v-show="showBasicContactFilters" key="contact-info" >
                <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
                    <h3 class="text-sm">Contact Info:</h3>
                    <base-button-affirmative @click="resetFilters" class="text-xs -py-2">Reset</base-button-affirmative>
                </div>
                <div class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8" id="contact-info">
                    <base-filter>
                        <template v-slot:label>Combined Name Search</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactFullNameFilter"
                                @input="setContactFullNameFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>First Name</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactFirstNameFilter"
                                @input="setContactFirstNameFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Last Name</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactLastNameFilter"
                                @input="setContactLastNameFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Contact ID</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactIdFilter"
                                @input="setContactIdFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="number"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>LinkedIn</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactLinkedInFilter"
                                @input="setContactLinkedInFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Email</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactEmailFilter"
                                @input="setContactEmailFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Phone</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactPhoneFilter"
                                @input="setContactPhoneFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Title</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactTitleFilter"
                                @input="setContactTitleFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Personal Notes</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactPersonalNotesFilter"
                                @input="setContactPersonalNotesFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>Contact Notes</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactContactNotesFilter"
                                @input="setContactContactNotesFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>All Notes Search</template>
                        <template v-slot:widget>
                            <input
                                v-model="contactAllNotesFilter"
                                @input="setContactAllNotesFilter"
                                class="h-full px-2 w-full rounded-b-md"
                                type="text"
                            >
                        </template>
                    </base-filter>
                </div>
            </div>
        </div>
        <div v-show="showBasicCompanyFilters" key="company-info" >
            <div>
                <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
                    <h3 class="text-sm">Company Info:</h3>  
                </div>
            </div>
            <div class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8" id="company-info">
                <base-filter>
                    <template v-slot:label>Company Name</template>
                    <template v-slot:widget>
                        <input
                            v-model="contactCompanyNameFilter"
                            @input="setContactCompanyNameFilter"
                            class="h-full px-2 w-full rounded-b-md"
                            type="text"
                        >
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Company ID</template>
                    <template v-slot:widget>
                        <input
                            v-model="contactCompanyIdFilter"
                            @input="setContactCompanyIdFilter"
                            class="h-full px-2 w-full rounded-b-md"
                            type="text"
                        >
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Website URL</template>
                    <template v-slot:widget>
                        <input
                            v-model="contactCompanyWebsiteUrlFilter"
                            @input="setContactCompanyWebsiteUrlFilter"
                            class="h-full px-2 w-full rounded-b-md"
                            type="text"
                        >
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Address Search</template>
                    <template v-slot:widget>
                        <input
                            v-model="contactCompanyAddressFilter"
                            @input="setContactCompanyAddressFilter"
                            class="h-full px-2 w-full rounded-b-md"
                            type="text"
                        >
                    </template>
                </base-filter>
                <base-filter @click="setCampaignOptions">
                    <template v-slot:label>
                    Campaign
                    </template>
                    <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder="Select Campaign"
                        :options="campaignOptions"
                        :modelValue="contactCompanyCampaignFilter"
                        :multiple="true"
                        @update:modelValue="setContactCompanyCampaignFilter"
                    />
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Revenue</template>              
                    <template v-slot:widget>
                    <div class="flex h-full">
                        <input @input="setContactCompanyMinRevenueFilter" placeholder="min" v-model="contactCompanyMinRevenueFilter" class="h-full px-2 w-1/2 rounded-b-md border" type="number">
                        <input @input="setContactCompanyMaxRevenueFilter" placeholder="max" v-model="contactCompanyMaxRevenueFilter" class="h-full px-2 w-1/2 rounded-b-md border" type="number">
                    </div>
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Revenue - ZI Combined</template>              
                    <template v-slot:widget>
                    <div class="flex h-full">
                        <input @input="setContactCompanyMinRevenueZiCombinedFilter" placeholder="min" v-model="contactCompanyMinRevenueZiCombinedFilter" class="h-full px-2 w-1/2 rounded-b-md border" type="number">
                        <input @input="setContactCompanyMaxRevenueZiCombinedFilter" placeholder="max" v-model="contactCompanyMaxRevenueZiCombinedFilter" class="h-full px-2 w-1/2 rounded-b-md border" type="number">
                    </div>
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Headcount</template>              
                    <template v-slot:widget>
                    <div class="flex h-full">
                        <input @input="setContactCompanyMinHeadcountFilter" placeholder="min" v-model="contactCompanyMinHeadcountFilter" class="h-full px-2 w-1/2 rounded-b-md border" type="number">
                        <input @input="setContactCompanyMaxHeadcountFilter" placeholder="max" v-model="contactCompanyMaxHeadcountFilter" class="h-full px-2 w-1/2 rounded-b-md border" type="number">
                    </div>
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Company Outreach Notes</template>
                    <template v-slot:widget>
                        <input
                            v-model="contactCompanyOutreachNotesFilter"
                            @input="setContactCompanyOutreachNotesFilter"
                            class="h-full px-2 w-full rounded-b-md"
                            type="text"
                        >
                    </template>
                </base-filter>
                <base-filter>
                    <template v-slot:label>Account Manager Research Notes</template>
                    <template v-slot:widget>
                        <input
                            v-model="contactCompanyAccountManagerResearchNotesFilter"
                            @input="setContactCompanyAccountManagerResearchNotesFilter"
                            class="h-full px-2 w-full rounded-b-md"
                            type="text"
                        >
                    </template>
                </base-filter>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'contact-filters',
  data: () => ({
  }),
  methods: {
  }
}
</script>

<script setup>
import { ref, computed, watch, defineEmits } from 'vue';
import store from '@/store';
import { useStore } from 'vuex';
import { getCampaigns } from '@/services/Campaign';

const contactIdFilter = ref(null);
const contactFirstNameFilter = ref('');
const contactLastNameFilter = ref('');
const contactFullNameFilter = ref('');
const contactLinkedInFilter = ref('');
const contactEmailFilter = ref('');
const contactPhoneFilter = ref('');
const contactTitleFilter = ref('');
const contactPersonalNotesFilter = ref('');
const contactContactNotesFilter = ref('');
const contactAllNotesFilter = ref('');
const contactCompanyNameFilter = ref('');
const contactCompanyIdFilter = ref(null);
const contactCompanyWebsiteUrlFilter = ref('');
const contactCompanyAddressFilter = ref('');
const contactCompanyCampaignFilter = ref([])
const contactCompanyMinRevenueFilter = ref(null);
const contactCompanyMaxRevenueFilter = ref(null);
const contactCompanyMinHeadcountFilter = ref(null);
const contactCompanyMaxHeadcountFilter = ref(null);
const contactCompanyMinRevenueZiCombinedFilter = ref(null);
const contactCompanyMaxRevenueZiCombinedFilter = ref(null);
const contactCompanyOutreachNotesFilter = ref('');
const contactCompanyAccountManagerResearchNotesFilter = ref('');

const campaignOptions = ref([
    {value: "clear", label: "------------"}
])

const emit = defineEmits(['change-contact-filters', 'set-changed-contact-filters'])

const globalStore = useStore()
const showBasicContactFilters = computed(() => globalStore.getters['contact/showBasicContactFilters'])
const showBasicCompanyFilters = computed(() => globalStore.getters['contact/showBasicCompanyFilters'])
// const visibleFilters = computed(() => globalStore.getters['contact/showVisibleFilters'])

// const selectedConfig = computed(() => {
//     return globalStore.getters['pageConfigs/showSelectedPageConfig']
// })

function emitChangeContactFiltersAndSetChanged(){
    emit('change-contact-filters', filters)
    emit('set-changed-contact-filters')
  store.dispatch('contact/setActiveFilters', activeFilters)
}

async function setCampaignOptions() {
    const token = store.getters['auth/showToken']
    const result = await getCampaigns(token)
    campaignOptions.value = [{value: "clear", label: "------------"}, {value: "null", label: "Unassigned"}, ...result.map((campaign) => {
    return {value: campaign.id, label: campaign.campaign_name}
    })]
}


function setContactIdFilter(event) {
    contactIdFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactFirstNameFilter(event) {
    contactFirstNameFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactLastNameFilter(event) {
    contactLastNameFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactFullNameFilter(event) {
    contactFullNameFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactLinkedInFilter(event) {
    contactLinkedInFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactEmailFilter(event) {
    contactEmailFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactPhoneFilter(event) {
    contactPhoneFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactTitleFilter(event) {
    contactTitleFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactPersonalNotesFilter(event) {
    contactPersonalNotesFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactContactNotesFilter(event) {
    contactContactNotesFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactAllNotesFilter(event) {
    contactAllNotesFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyNameFilter(event) {
    contactCompanyNameFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyIdFilter(event) {
    contactCompanyIdFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyWebsiteUrlFilter(event) {
    contactCompanyWebsiteUrlFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyAddressFilter(event) {
    contactCompanyAddressFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyCampaignFilter(target) {
      if(target.includes("clear")){
        contactCompanyCampaignFilter.value = []
      } else {
        contactCompanyCampaignFilter.value = target;
      }
      emitChangeContactFiltersAndSetChanged()
    }


function setContactCompanyMinRevenueFilter(event) {
    contactCompanyMinRevenueFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyMaxRevenueFilter(event) {
    contactCompanyMaxRevenueFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyMinHeadcountFilter(event) {
    contactCompanyMinHeadcountFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyMaxHeadcountFilter(event) {
    contactCompanyMaxHeadcountFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyMinRevenueZiCombinedFilter(event) {
    contactCompanyMinRevenueZiCombinedFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyMaxRevenueZiCombinedFilter(event) {
    contactCompanyMaxRevenueZiCombinedFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyOutreachNotesFilter(event) {
    contactCompanyOutreachNotesFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}

function setContactCompanyAccountManagerResearchNotesFilter(event) {
    contactCompanyAccountManagerResearchNotesFilter.value = event.target.value;
    emitChangeContactFiltersAndSetChanged()
}


const activeFilters = computed(() => {
    let activeFiltersList = []
    if(contactIdFilter.value){
        activeFiltersList.push('Contact ID')
    }
    if(contactFirstNameFilter.value){
        activeFiltersList.push('First Name')
    }
    if(contactLastNameFilter.value){
        activeFiltersList.push('Last Name')
    }
    if(contactFullNameFilter.value){
        activeFiltersList.push('Combined Name')
    }
    if(contactLinkedInFilter.value){
        activeFiltersList.push('LinkedIn')
    }
    if(contactEmailFilter.value){
        activeFiltersList.push('Email')
    }
    if(contactPhoneFilter.value){
        activeFiltersList.push('Phone')
    }
    if(contactTitleFilter.value){
        activeFiltersList.push('Title')
    }
    if(contactPersonalNotesFilter.value){
        activeFiltersList.push('Personal Notes')
    }
    if(contactContactNotesFilter.value){
        activeFiltersList.push('Contact Notes')
    }
    if(contactAllNotesFilter.value){
        activeFiltersList.push('All Notes')
    }
    if(contactCompanyNameFilter.value){
        activeFiltersList.push('Company Name')
    }
    if(contactCompanyIdFilter.value){
        activeFiltersList.push('Company ID')
    }
    if(contactCompanyWebsiteUrlFilter.value){
        activeFiltersList.push('Company Name')
    }
    if(contactCompanyAddressFilter.value){
        activeFiltersList.push('Company Address')
    }
    if(contactCompanyCampaignFilter.value){
        activeFiltersList.push('Company Campaign')
    }
    if(contactCompanyMinRevenueFilter.value){
        activeFiltersList.push('Company Min Revenue')
    }
    if(contactCompanyMaxRevenueFilter.value){
        activeFiltersList.push('Company Max Revenue')
    }
    if(contactCompanyMinHeadcountFilter.value){
        activeFiltersList.push('Company Min Headcount')
    }
    if(contactCompanyMaxHeadcountFilter.value){
        activeFiltersList.push('Company Max Headcount')
    }
    if(contactCompanyMinRevenueZiCombinedFilter.value){
        activeFiltersList.push('Company Min Revenue ZI Combined')
    }
    if(contactCompanyMaxRevenueZiCombinedFilter.value){
        activeFiltersList.push('Company Max Revenue ZI Combined')
    }
    if(contactCompanyOutreachNotesFilter.value){
        activeFiltersList.push('Company Outreach Notes')
    }
    if(contactCompanyAccountManagerResearchNotesFilter.value){
        activeFiltersList.push('Company Outreach Notes')
    }
    return activeFiltersList
})

function resetFilters() {
    contactIdFilter.value = ''
    contactFirstNameFilter.value = ''
    contactLastNameFilter.value = ''
    contactFullNameFilter.value = ''
    contactLinkedInFilter.value = ''
    contactEmailFilter.value = ''
    contactPhoneFilter.value = ''
    contactTitleFilter.value = ''
    contactPersonalNotesFilter.value = ''
    contactContactNotesFilter.value = ''
    contactAllNotesFilter.value = ''
    contactCompanyNameFilter.value = ''
    contactCompanyIdFilter.value = null
    contactCompanyWebsiteUrlFilter.value = ''
    contactCompanyAddressFilter.value = ''
    contactCompanyCampaignFilter.value = []
    contactCompanyMinRevenueFilter.value = null
    contactCompanyMaxRevenueFilter.value = null
    contactCompanyMinHeadcountFilter.value = null
    contactCompanyMaxHeadcountFilter.value = null
    contactCompanyMinRevenueZiCombinedFilter.value = null
    contactCompanyMaxRevenueZiCombinedFilter.value = null
    contactCompanyOutreachNotesFilter.value = ''
    contactCompanyAccountManagerResearchNotesFilter.value = ''
}

const filters = computed(() => {
    return {
        contactIdFilter: contactIdFilter,
        contactFirstNameFilter: contactFirstNameFilter,
        contactLastNameFilter: contactLastNameFilter,
        contactFullNameFilter: contactFullNameFilter,
        contactLinkedInFilter: contactLinkedInFilter,
        contactEmailFilter: contactEmailFilter,
        contactPhoneFilter: contactPhoneFilter,
        contactTitleFilter: contactTitleFilter,
        contactPersonalNotesFilter: contactPersonalNotesFilter,
        contactContactNotesFilter: contactContactNotesFilter,
        contactAllNotesFilter: contactAllNotesFilter,
        contactCompanyNameFilter: contactCompanyNameFilter,
        contactCompanyIdFilter: contactCompanyIdFilter,
        contactCompanyWebsiteUrlFilter: contactCompanyWebsiteUrlFilter,
        contactCompanyAddressFilter: contactCompanyAddressFilter,
        contactCompanyCampaignFilter: contactCompanyCampaignFilter,
        contactCompanyMinRevenueFilter: contactCompanyMinRevenueFilter,
        contactCompanyMaxRevenueFilter: contactCompanyMaxRevenueFilter,
        contactCompanyMinHeadcountFilter: contactCompanyMinHeadcountFilter,
        contactCompanyMaxHeadcountFilter: contactCompanyMaxHeadcountFilter,
        contactCompanyMinRevenueZiCombinedFilter: contactCompanyMinRevenueZiCombinedFilter,
        contactCompanyMaxRevenueZiCombinedFilter: contactCompanyMaxRevenueZiCombinedFilter,
        contactCompanyOutreachNotesFilter: contactCompanyOutreachNotesFilter,
        contactCompanyAccountManagerResearchNotesFilter: contactCompanyAccountManagerResearchNotesFilter,
    }
})

</script>

<style scoped>
  @import "vue-select/dist/vue-select.css";
</style>
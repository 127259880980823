<template>
    <div>
      <base-button @click="showModal">Platforms</base-button>
      <InfoModal
      :is-modal-visible="isModalVisible"
          @close="closeModal"
      >
        <template #header>
          Platforms
        </template>
        <template #body>
          <base-dblclick-textarea
            modelType="company"
            :modelId="company.id"
            fieldName="platforms"
            :fieldValue="company.platforms"
            @refetch="fetchNewCompanies"
            @close="closeModal"
          >
          </base-dblclick-textarea>
        </template>
      </InfoModal>
    </div>
  
  </template>
  
  <script>
      export default {
          name: 'platform-notes-cell'
      }
  </script>
  
  <script setup>
  import {ref, defineProps, defineEmits} from "vue";
  import InfoModal from "../InfoModal.vue"
  
    const isEditable = ref(false)
    const isModalVisible = ref(false)
  
    const props = defineProps({
        company: Object
    })
  
    const emit = defineEmits(['refetch-companies'])
  
    function fetchNewCompanies () {
        emit('refetch-companies')
    }
  
    function showModal () {
      isModalVisible.value = true
    }
  
    function closeModal () {
      isEditable.value = false
      isModalVisible.value = false
    }
  </script>
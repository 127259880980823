<template>
      <base-dblclick-input
        modelType="company"
        :modelId="company.id"
        fieldName="further_research_needed"
        :fieldValue="company.further_research_needed"
        inputType="text"
        @refetch="fetchNewCompanies"
    ></base-dblclick-input>
</template>

<script>
  export default {
      name: "further-research-needed-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

</script>
<template>
    <div class="flex flex-col items-center">
        <CampaignCell :company="company" />
        <TierCell :company="company" class="mb-1" />
        <MarkTierButtonsCell :company="company" @refetch-companies="fetchNewCompanies"/>
        <FurtherResearchNeededCell :company="company" @refetch-companies="fetchNewCompanies" />
        <RemoveFromCampaignCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>

</template>

<script>
    export default {
        name: "campaign-tier-tier-buttons-research-needed-remove-from-campaign-cell"
    }
</script>

<script setup>
import CampaignCell from "../company-cell-components/CampaignCell.vue";
import TierCell from "../company-cell-components/TierCell.vue";
import MarkTierButtonsCell from "../company-cell-components/MarkTierButtonsCell.vue";
import FurtherResearchNeededCell from "../company-cell-components/FurtherResearchNeededCell.vue";
import RemoveFromCampaignCell from "../company-cell-components/RemoveFromCampaignCell.vue";
import {ref, computed, defineEmits, defineProps} from "vue"

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }


</script>
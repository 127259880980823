<template>
  <div class="w-full">
    <base-card>
      <ReferralFilters
        @change-referral-filters="setReferralFilters"
        @set-changed-referral-filters="setChangedReferralFilters"
      ></ReferralFilters>
    </base-card>

    <!-- Modals Component -->

    <base-modal-small v-if="isAddModalVisible" @close="closeAddModal">
      <template v-slot:header> Add Referrer </template>
      <template v-slot:body>
        <ReferrerForm
          class="h-[90%]"
          @submit-form="submitAddForm"
          @close="closeAddModal"
        ></ReferrerForm>
      </template>
    </base-modal-small>

    <base-modal-small v-if="isEditModalVisible" @close="closeEditModal">
      <template v-slot:header> Edit Referrer </template>
      <template v-slot:body>
        <EditReferrerForm
         class="h-[90%]"
          @submit-edit-form="submitEditForm"
          @close-edit="closeEditModal"
          :prefill="editObject"
        ></EditReferrerForm>
      </template>
    </base-modal-small>

    <!-- Table Component -->

    <base-card>
      <!-- Header Component -->

      <div class="flex pt-1 pl-3.5">
        <DoubleButton class="pt-1" @expand-all="expandAll" @collapse-all="collapseAll" />
        <base-pagination-header
          @load-data="loadReferrers(fetchFilter.value, true)"
          @get-next="getNext"
          @go-to-first="goToFirst"
          @get-prev="getPrev"
          @go-to-last="goToLast"
          @jump-to-page="jumpToPage"
          @set-page-size-filter="setPageSizeFilter"
          :page_filter="referralFilters"
          :page="page"
          :numPages="num_pages"
          :isLoading="isLoading"
        >
          <template v-slot:message>
            <div class="pl-3 pt-1">
              {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total
              Referrers
            </div>
            <div class="ml-5">
              <base-button @click="openAddModal">New Referrer</base-button>
            </div>
          </template>
        </base-pagination-header>
      </div>

      <div class="h-full overflow-y-auto border z-30">
        <base-table-full class="table-auto">
          <template #table-header>
            <base-table-header
              :key="header.id"
              :header="header"
              class="pt-3 pb-3"
              v-for="header in tableHeadersList"
            >
              {{ header }}
            </base-table-header>
          </template>

          <template #table-body>
            <ReferralRow
              v-for="r in results"
              @open-edit="openEditModal"
              @archive-referrer="archiveReferrer"
              :key="r.id"
              :referral="r"
              :expanded-object="expandedObject"
              :changedReferralFilters="changedReferralFilters"
            ></ReferralRow>
          </template>
        </base-table-full>
      </div>
    </base-card>
  </div>
</template>

<script setup>
import {
  getReferralSources,
  updateReferrer,
  createReferrer,
} from "../services/Referrer";
import { ref, onMounted, reactive, computed } from "vue";
import { useStore } from "vuex";

import ReferralRow from "./referrers/table/ReferralRow.vue";
import BaseCard from "@/components/BaseCard";
import ReferralFilters from "./referrers/ReferralFilters.vue";
import ReferrerForm from "./referrers/ReferrerForm.vue";
import EditReferrerForm from "./referrers/EditReferrerForm.vue";
import DoubleButton from "./referrers/UI/DoubleButton.vue";

const next = ref();
const prev = ref();
const count = ref();
const page = ref(1);
const num_pages = ref();
const page_size = ref();
const results = ref([]);
const changedReferralFilters = ref(false);
const isAddModalVisible = ref(false);
const isEditModalVisible = ref(false);
const isLoading = ref(false);
const editObject = ref();
const store = useStore();
const tableHeadersList = ref([
  "ID",
  "Name",
  "Email",
  "Address",
  "Phone",
  "Edit",
  "Archive",
]);
const referralFilters = reactive({
  statusFilter: "active",
  pageSizeFilter: 50,
});
const expandedObject = reactive({
  companiesVisible: false,
  objectHasChanged: true,
});

// async functions

async function loadReferrers(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const referralsObject = await getReferralSources(payload);
  next.value = referralsObject.next
    ? `&page=${referralsObject.page + 1}`
    : null;
  prev.value = referralsObject.prev
    ? `&page=${referralsObject.page - 1}`
    : null;
  count.value = referralsObject.count;
  page.value = referralsObject.page;
  num_pages.value = referralsObject.num_pages;
  results.value = referralsObject.referrals;
  page_size.value = referralsObject.referrals.length;
  isLoading.value = false;
}

async function archiveReferrer(id, formData) {
  const token = store.state.auth.token;
  await updateReferrer(id, formData, token);
  loadReferrers();
}

async function submitAddForm(formData) {
  const token = store.state.auth.token;
  const result = await createReferrer(formData, token);
  if (result.status == 201) {
    alert("Referrer created successfully.");
  } else {
    alert("Error, referrer not created.");
  }
  loadReferrers();
  closeAddModal();
}

async function submitEditForm(id, formData) {
  const token = store.state.auth.token;
  const result = await updateReferrer(id, formData, token);
  if (result.status == 200) {
    alert("Referrer updated successfully.");
  } else {
    alert("Error, referrer not updated.");
  }
  loadReferrers();
  closeEditModal();
}

async function setReferralFilters(updatedFilters) {
  referralFilters.statusFilter = updatedFilters.value.statusFilter;
  await loadReferrers(fetchFilter.value);
  collapseAll();
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (referralFilters.pageSizeFilter = 50)
    : (referralFilters.pageSizeFilter = target);
  await loadReferrers(fetchFilter.value);
}

// functions

function getNext() {
  if (next.value) {
    loadReferrers(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadReferrers(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadReferrers(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (num_pages.value != page.value) {
    loadReferrers(fetchFilter.value + `&page=${num_pages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadReferrers(fetchFilter.value + `&page=${targetPage}`);
}

function openAddModal() {
  isAddModalVisible.value = true;
}

function openEditModal(values) {
  isEditModalVisible.value = true;
  editObject.value = values;
  console.log("Edit Object", editObject.value);
}

function closeAddModal() {
  isAddModalVisible.value = false;
}

function closeEditModal() {
  isEditModalVisible.value = false;
}

function expandAll() {
  expandedObject.objectHasChanged = !expandedObject.objectHasChanged;
  expandedObject.companiesVisible = true;
}

function collapseAll() {
  expandedObject.objectHasChanged = !expandedObject.objectHasChanged;
  expandedObject.companiesVisible = false;
}

function setChangedReferralFilters() {
  changedReferralFilters.value = true;
}

// computed values

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});
  if (referralFilters.statusFilter) {
    query.append("status", referralFilters.statusFilter);
  }
  if (referralFilters.pageSizeFilter) {
    query.append("page_size", referralFilters.pageSizeFilter); // To append more data
  }
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

const viewingFromNbr = computed(function () {
  return (
    page.value * referralFilters.pageSizeFilter +
    1 -
    referralFilters.pageSizeFilter
  );
});

const viewingToNbr = computed(function () {
  if (page.value === num_pages.value) {
    return count.value;
  } else {
    return page.value * referralFilters.pageSizeFilter;
  }
});

onMounted(() => {
  loadReferrers();
});
</script>

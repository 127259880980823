<template>
    <div>
      <base-button @click="showModal">Outreach Notes</base-button>
      <InfoModal
      :is-modal-visible="isModalVisible"
          @close="closeModal"
      >
        <template #header>
          Outreach Notes
        </template>
        <template #body>
          <base-dblclick-textarea
            model-type="company"
            :model-id="company.id"
            field-name="out_reach_notes"
            :field-value="company.out_reach_notes"
            @refetch="fetchNewCompanies"
            @close="closeModal"
        ></base-dblclick-textarea>
        </template>
      </InfoModal>
    </div>
  
  </template>
  
  <script>
      export default {
          name: 'outreach-notes-cell'
      }
  </script>
  
  <script setup>
  import {ref, defineProps, defineEmits } from "vue";
  import InfoModal from "../InfoModal.vue"
  
    const isModalVisible = ref(false)
  
    const props = defineProps({
        company: Object
    })
  
    const emit = defineEmits(['refetch-companies'])
  
    function fetchNewCompanies () {
        emit('refetch-companies')
    }
  
    function showModal () {
      isModalVisible.value = true
    }
  
    function closeModal () {
      isModalVisible.value = false
    }
  </script>
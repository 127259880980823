<template>
  <div ref="filterContainer" class="w-full">
    <!-- Filter Component -->

    <base-card>
      <PMAFilters
        @change-referral-filters="setApprovalFilters"
        @set-changed-referral-filters="setChangedApprovalsFilters"
        :companyOptions="companyFilterOptions"
        :visibleObject="isDropDownVisible"
      ></PMAFilters>
    </base-card>

    <!-- Header Component -->

    <base-card>
      <base-pagination-header
        @load-data="loadApprovals(fetchFilter.value, true)"
        @get-next="getNext"
        @go-to-first="goToFirst"
        @get-prev="getPrev"
        @go-to-last="goToLast"
        @jump-to-page="jumpToPage"
        @set-page-size-filter="setPageSizeFilter"
        :page_filter="approvalsFilters"
        :page="page"
        :numPages="num_pages"
        :isLoading="isLoading"
      >
        <template v-slot:message>
          <div class="pl-3 pt-1">
            {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Tasks
          </div>
        </template>
      </base-pagination-header>

      <!-- Table Component -->

      <div class="h-full overflow-y-auto border">
        <base-table-full class="table-auto">
          <template #table-header>
            <base-table-header
              :key="header.id"
              :header="header"
              class="pt-3 pb-3"
              v-for="header in tableHeadersList"
            >
              {{ header }}
            </base-table-header>
          </template>

          <template #table-body>
            <PMARow
              v-for="approval in approvals"
              :key="approval.id"
              :approval="approval"
              :resetObject="resetFilters"
            ></PMARow>
          </template>
        </base-table-full>
      </div>
    </base-card>
  </div>
</template>

<script setup>
// imports

import { ref, computed, onMounted, onUnmounted, reactive } from "vue";
import { getPartsManufacturerApprovals } from "../services/PMAList.js";
import { useStore } from "vuex";
import { getCompanyNameFilterChoices } from "@/services/PMAList.js";

import PMARow from "./pma_page/table/PMARow.vue";
import PMAFilters from "./pma_page/PMAFilters.vue";

// constants/variables

const store = useStore();

const filterContainer = ref(null);
const tableHeadersList = ref([
  "Company Name",
  "Supplement Number / Date",
  "Part Name",
  "Make",
  "Model",
  "FAA Approval Basis",
  "Location",
]);

const next = ref();
const prev = ref();
const count = ref();
const num_pages = ref();
const page_size = ref();
const page = ref(1);
const approvals = ref([]);

const companyFilterOptions = ref([]);
const isLoading = ref(false);
const changedApprovalsFilters = ref(false);

const approvalsFilters = reactive({
  companyFilter: "",
  makeFilter: "",
  modelFilter: "",
  partNameFilter: "",
  supplimentDateFilter: "",
  pageSizeFilter: 50,
});
const isDropDownVisible = reactive({
  dropdownVisible: false,
  objectHasChanged: true,
});
const resetFilters = reactive({
  buttonHit: false,
});

// computed values

// const viewingFromNbr = computed(function () {
//   return page.value * page_size.value + 1 - page_size.value;
// });

// const viewingToNbr = computed(function () {
//   if (page.value === num_pages.value) {
//     return count.value;
//   } else {
//     return page.value * page_size.value;
//   }
// });

const viewingFromNbr = computed(function () {
  // return page.value * page_size.value + 1 - page_size.value;
  return (
    page.value * approvalsFilters.pageSizeFilter +
    1 -
    approvalsFilters.pageSizeFilter
  );
});

const viewingToNbr = computed(function () {
  if (page.value === num_pages.value) {
    return count.value;
  } else {
    // return page.value * page_size.value;
    return page.value * approvalsFilters.pageSizeFilter;
  }
});

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});

  if (approvalsFilters.supplimentDateFilter) {
    query.append("date", approvalsFilters.supplimentDateFilter);
  }
  if (approvalsFilters.companyFilter) {
    query.append("company_name", approvalsFilters.companyFilter);
  }
  if (approvalsFilters.makeFilter) {
    query.append("make", approvalsFilters.makeFilter); // To append more data
  }
  if (approvalsFilters.modelFilter) {
    query.append("model", approvalsFilters.modelFilter); // To append more data
  }
  if (approvalsFilters.partNameFilter) {
    query.append("part_name", approvalsFilters.partNameFilter); // To append more data
  }
  if (approvalsFilters.pageSizeFilter) {
    query.append("page_size", approvalsFilters.pageSizeFilter); // To append more data
  }

  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

// async functions

async function loadApprovals(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  resetFilters.buttonHit = !resetFilters.buttonHit;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const response = await getPartsManufacturerApprovals(payload);
  next.value = response.next ? `&page=${response.page + 1}` : null;
  prev.value = response.prev ? `&page=${response.page - 1}` : null;
  count.value = response.count;
  page.value = response.page;
  num_pages.value = response.num_pages;
  approvals.value = response.approvals;
  page_size.value = response.page_size;
  isLoading.value = false;
}

async function setApprovalFilters(updatedFilters) {
  approvalsFilters.companyFilter = updatedFilters.value.companyFilter;
  approvalsFilters.makeFilter = updatedFilters.value.makeFilter;
  approvalsFilters.modelFilter = updatedFilters.value.modelFilter;
  approvalsFilters.partNameFilter = updatedFilters.value.partNameFilter;
  approvalsFilters.supplimentDateFilter =
    updatedFilters.value.supplimentDateFilter;
  await loadApprovals(fetchFilter.value);
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (approvalsFilters.pageSizeFilter = 50)
    : (approvalsFilters.pageSizeFilter = target);
  await loadApprovals(fetchFilter.value);
}

async function setCompanyFilterOptions() {
  let payload = {
    token: store.state.auth.token,
    endpoint: "",
  };
  const result = await getCompanyNameFilterChoices(payload);
  companyFilterOptions.value = ["---------"];
  result.company_names.forEach((company) => {
    companyFilterOptions.value.push(company);
  });
}

// functions

function getNext() {
  if (next.value) {
    loadApprovals(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadApprovals(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadApprovals(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (num_pages.value != page.value) {
    loadApprovals(fetchFilter.value + `&page=${num_pages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadApprovals(fetchFilter.value + `&page=${targetPage}`);
}

function setChangedApprovalsFilters() {
  changedApprovalsFilters.value = true;
}

function handleClickOutside(event) {
  if (filterContainer.value && filterContainer.value.contains(event.target)) {
    isDropDownVisible.dropdownVisible = false;
    isDropDownVisible.objectHasChanged = !isDropDownVisible.objectHasChanged;
  }
}

// life-cycle

onMounted(() => {
  loadApprovals();
  setCompanyFilterOptions();
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

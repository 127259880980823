<template>
    <div v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement || userPermissions.isSuperuser"
        class="mx-4 w-full">
        <div class="pt-3 pb-0.5 border-b border-b-gray-400 flex flex-row justify-between">
            <h1 class="">Business Types, Sectors, & Sub Sectors</h1>
        </div>
        <div class="flex flex-col lg:grid lg:grid-cols-3 gap-x-2 h-screen">

            <div>
                <BusinessTypesSectorsSubSectorsAddButton class="items-center" :tableType="'businessTable'"
                    @refetch-table="loadBusinessTypes" />
                <div class="max-h-[90vh] overflow-auto">
                    <BusinessTypesSectorsSubSectorsTable :data="businessTypeResults" :tableHeaders="businessTypeHeaders"
                        :tableType="'businessTable'" @refetch-table="loadBusinessTypes" />
                </div>
            </div>
            <div>
                <BusinessTypesSectorsSubSectorsAddButton class="items-center" :tableType="'sectorsTable'"
                    @refetch-table="loadSectors" />
                <div class="max-h-[90vh] overflow-auto">
                    <BusinessTypesSectorsSubSectorsTable :data="sectorsResults" :tableHeaders="sectorsHeaders"
                        :tableType="'sectorsTable'" @refetch-table="loadSectors" />
                </div>
            </div>
            <div>
                <BusinessTypesSectorsSubSectorsAddButton class="items-center" :tableType="'subSectorsTable'"
                    @refetch-table="loadSubSectors" />
                <div class="max-h-[15vh] lg:max-h-[90vh] overflow-auto">
                    <BusinessTypesSectorsSubSectorsTable class="overflow-auto" :data="subSectorsResults" :tableHeaders="subSectorsHeaders"
                        :tableType="'subSectorsTable'" @refetch-table="loadSubSectors" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, shallowReactive } from 'vue'
import { useStore } from "vuex"
import { getBusinessTypes } from '@/services/BusinessType'
import { getSectors } from '@/services/Sector'
import { getSubSectors } from '@/services/SubSector'
import BusinessTypesSectorsSubSectorsAddButton from './BusinessTypes+Sectors+SubSectorsAddButton.vue'
import BusinessTypesSectorsSubSectorsTable from './BusinessTypes+Sectors+SubSectorsTable.vue'

const store = useStore()
const isLoading = ref(false)

const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
});

const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent = userPermissionsStore.isStudent
    userPermissions.isResearch = userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch = userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser = userPermissionsStore.isSuperuser
    userPermissions.isAccountManager = userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff
    userPermissions.isDev = userPermissionsStore.isDev
    userPermissions.isResearchLead = userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies = userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard = userPermissionsStore.isGenerateTimecard

    // console.log('user permissions', userPermissions)
}

const businessTypeResults = ref([])
const businessTypeHeaders = ref(["Business Types", "Action"])
const sectorsResults = ref([])
const sectorsHeaders = ref(["Sectors", "Action"])
const subSectorsResults = ref([])
const subSectorsHeaders = ref(["Sub Sectors", "Action"])

async function loadBusinessTypes() {
    isLoading.value = true
    let token = store.state.auth.token
    const result = await getBusinessTypes(token)
    businessTypeResults.value = result
    isLoading.value = false
}

async function loadSectors() {
    isLoading.value = true
    let token = store.state.auth.token
    const result = await getSectors(token)
    sectorsResults.value = result
    isLoading.value = false
}
async function loadSubSectors() {
    isLoading.value = true
    let token = store.state.auth.token
    const result = await getSubSectors(token)
    subSectorsResults.value = result
    isLoading.value = false
}

setUserPermissions()
loadBusinessTypes()
loadSectors()
loadSubSectors()
</script>
import axios from 'axios';
// import { useRouter } from 'vue-router';
import router from '@/router';
import store from '@/store';

export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://aerocle-v1.sheddcapital.com/rest_api' : 'http://127.0.0.1:8282/rest_api';
export const BASE_AUTH_URL = process.env.NODE_ENV === 'production' ? 'https://aerocle-v1.sheddcapital.com/api' : 'http://127.0.0.1:8282/api'


axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.statusText == "App down for maintenance"){
        alert("App is currently down for maintenance")
        store.dispatch("auth/logout")
        router.push('/maintenance_page')
        // useRouter().push('maintenance-page')
    }
    return Promise.reject(error);
  });

const Client = axios.create({ baseUrl: 'http://127.0.0.1:8282/rest_api/'});

Client.interceptors.request.use(
    (config) => {
    //     const token = localStorage.getItem('token')
    //     if (token) {
    //         config.headers['authorization'] = `Bearer ${token}`
        config.headers['Authorization'] = 'Basic cGF0cmlja19hbHZhcmV6OmZpbG0udGFibGUuY3ljbGU='
        return config
    //    }
    },
    (error) => Promise.reject(error)
)

export default Client
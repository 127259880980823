<template>
    <div>
        <CampaignCell :company="company" />
        <div class="w-full mt-1">
            <base-pill>Tier:</base-pill> <TierCell :company="company" class="inline"/>
        </div>
        <div class="w-full mt-1">
            <base-pill>Category:</base-pill> <CategoryCell :company="company"  @refetch-companies="fetchNewCompanies" class="inline"/>
        </div>
        <div class="w-full mt-1">
            <SourcesModalCell :company="company" />
        </div>
    </div>

</template>

<script>
    export default {
        name: "campaign-tier-category-sources-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import CampaignCell from '../company-cell-components/CampaignCell.vue';
import TierCell from '../company-cell-components/TierCell.vue';
import CategoryCell from '../company-cell-components/CategoryCell.vue';
import SourcesModalCell from '../company-cell-components/SourcesModalCell.vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }

</script>
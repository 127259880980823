<template>
    <div class="h-full" :class="{'cursor-wait' : isLoading }">
        <base-form class="h-[90%] w-full bg-gray-100 border rounded-md border-gray-300">
            <template #body>
                <!-- <div class="space-y-4 mx-2.5"></div> -->
                <div class="grid grid-cols-3 mt-4" :class="{ 'cursor-wait' : isLoading}">
                    <div class="flex flex-col text-xs px-2 h-full" v-if="undefinedCapabilities.length > 0">
                        <div class="flex justify-center">
                            <h2 class="underline font-bold text-center">To Be Researched</h2>
                            <HeadlessPopoverClick :hide-chevron="true" class="h-fit">
                                <template #popover-title> <div class="-m-2 border border-black bg-white bg-opacity-40 text-black px-1 rounded-lg text-xs">?</div></template>
                                <template #popover-body>
                                        <div class="bg-gray-100 p-2 border border-gray-300 rounded-b-lg w-96 2xl:w-[30rem]">
                                            <div class="border-b border-gray-400 pb-1 mb-1.5">
                                                Capability Descriptions:
                                            </div>
                                            <div class="flex flex-col space-y-3 w-fit">
                                                <div v-for="capability in capabilities" :key="capability.id">
                                                    <div class="inline-block pl-4 -indent-4"><span class="text-bold underline">{{ capability.capability_name }}:</span> {{ capability.capability_description }}</div>
                                                </div>
                                            </div>
                                        </div>
                                </template>
                            </HeadlessPopoverClick>
                        </div>
                        <transition-group name="capabilityButton">
                            <div v-for="capability in undefinedCapabilities" :key="capability.id">
                                <div>
                                    <base-button class="w-full" :class="{'cursor-wait' : isLoading}" @click="addCompanyCapabilityTrue(capability.id, capability.capability_name)" @click.right.prevent="addCompanyCapabilityFalse(capability.id, capability.capability_name)">{{ capability.capability_name }}</base-button>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    <div class="flex flex-col text-xs px-2 ">
                        <h2 class="underline font-bold text-center">Capabilities</h2>
                        <transition-group name="capabilityButton">
                            <div v-for="capability in companyTrueCapabilities" :key="capability.id" >
                                <base-button @click="removeCapability(capability.capability.id, capability.capability.capability_name)" :class="{'cursor-wait' : isLoading}" class="bg-green-300 w-full">{{ capability.capability.capability_name }}</base-button>
                            </div>
                        </transition-group>
                    </div>
                    <div class="flex flex-col text-xs px-2 ">
                        <h2 class="underline font-bold text-center">Not Applicable</h2>
                        <transition-group name="capabilityButton">
                            <div v-for="capability in companyFalseCapabilities" :key="capability.id" >
                                <base-button @click="removeCapability(capability.capability.id, capability.capability.capability_name)" :class="{'cursor-wait' : isLoading}" class="bg-amber-200 w-full">{{ capability.capability.capability_name }}</base-button>
                            </div>
                        </transition-group>
                    </div>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script>
export default {
    name: 'company-capability-form'
}
</script>

<script setup>
import { useStore } from 'vuex';
import { getCapabilities, addCompanyCapability, removeCompanyCapability } from '@/services/Capability';
import { defineProps, defineEmits, ref, watch, computed } from 'vue';
import { useToast } from 'vue-toast-notification';
import HeadlessPopoverClick from './HeadlessPopoverClick.vue';

const store = useStore()

const $toast = useToast()

const isLoading = ref(false)

const props = defineProps({
    company: Object
})

const capabilities = ref([])

const emit = defineEmits(['refetch-companies'])

function hasCapability(capabilityId){
    if(props.company.capabilities.filter((companyCapability) => {
        return companyCapability.capability.id === capabilityId
    }).length == 0){
        return "null"
    }
    if(props.company.capabilities.filter((companyCapability) => {
        return companyCapability.capability.id === capabilityId && companyCapability.company_has_capability
    }).length > 0){
        return true
    }else if(props.company.capabilities.filter((companyCapability) => {
        return companyCapability.capability.id === capabilityId && companyCapability.company_has_capability === false
    }).length > 0){
        return false
    }
}

const companyTrueCapabilities = computed(() => {
    if(props.company){
    return props.company.capabilities.filter((capability) => {
        return capability.company_has_capability
    })        
    }else {
        return null
    }

})

const companyFalseCapabilities = computed(() => {
    if(props.company){
        return props.company.capabilities.filter((capability) => {
            return !capability.company_has_capability
        })
    } else {
        return null
    }
})

const undefinedCapabilities = computed(() => {
    return capabilities.value.filter((capability) => {
        if(props.company.capabilities.filter((companyCapability) => {
        return companyCapability.capability.id === capability.id
    }).length == 0){
        return true
    }
    if(props.company.capabilities.filter((companyCapability) => {
        return companyCapability.capability.id === capability.id && companyCapability.company_has_capability
    }).length > 0){
        return false
    }else if(props.company.capabilities.filter((companyCapability) => {
        return companyCapability.capability.id === capability.id && companyCapability.company_has_capability === false
    }).length > 0){
        return false
    }
    })
})

async function loadCapabilities(){ 
    const token = store.state.auth.token
    const result = await getCapabilities(token)
    capabilities.value = result
}

async function addCompanyCapabilityTrue(capabilityId, capabilityName) {
    isLoading.value = true
    const token = store.state.auth.token
    const payload = {
        token: token,
        capability_id: capabilityId,
        data: {
            company_id: props.company.id,
            true_or_false: true           
        }
    }
    const result = await addCompanyCapability(payload)
    if(result.status === 200){
        $toast.open({
                    message: `Successfully added Capability: ${capabilityName} = True to ${props.company.company_name}`,
                    type: 'success',
                    duration: 5000
                })
    } else {
        console.log('error', result)
        $toast.open({
            message: result.response.data.detail,
            type: 'error',
            duration: 5000 
        })
    }
    emit("refetch-companies")
    // isLoading.value = false
}

async function addCompanyCapabilityFalse(capabilityId, capabilityName) {
    isLoading.value = true
    const token = store.state.auth.token
    const payload = {
        token: token,
        capability_id: capabilityId,
        data: {
            company_id: props.company.id,
            true_or_false: false           
        }
    }
    const result = await addCompanyCapability(payload)
    if(result.status === 200){
        $toast.open({
                    message: `Successfully added Capability: ${capabilityName} = False to ${props.company.company_name}`,
                    type: 'success',
                    duration: 5000
                })
    } else {
        console.log('error', result)
        $toast.open({
            message: result.response.data.detail,
            type: 'error',
            duration: 5000 
        })
    }
    emit("refetch-companies")
    // isLoading.value = false
}

async function removeCapability(capabilityId, capabilityName) {
    isLoading.value = true
    const token = store.state.auth.token 
    const payload = {
        token: token,
        capability_id: capabilityId,
        data: {
            company_id: props.company.id
        }
    }
    const result = await removeCompanyCapability(payload)
    if(result.status === 200){
        $toast.open({
                    message: `Successfully removed Capability: ${capabilityName} from ${props.company.company_name}`,
                    type: 'success',
                    duration: 5000
                })
    } else {
        console.log('error', result)
        $toast.open({
            message: result.response.data.detail,
            type: 'error',
            duration: 5000 
        })
    }
    emit("refetch-companies")

}

watch(props, () => {
    isLoading.value = false
} )

loadCapabilities()

</script>

<style>
.capabilityButton-enter-active,
.capabilityButton-leave-active {
  transition: all 0.5s ease;
}
.capabilityButton-enter-from,
.capabilityButton-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
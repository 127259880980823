import axios from 'axios';
import { BASE_URL } from './api';


export async function getZoomInfoCompanyByCompany(companyId, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/zoom_info_companies/?company=${companyId}`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}
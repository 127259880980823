<template>
    <div v-if="activeFilters.length > 0" class="flex flex-row items-center space-x-2 w-full">
    <base-pill class="bg-amber-300 border-amber-400 flex items-center justify-between space-x-0.5 p-0.5" v-for="filter in activeFilters" :key="filter.name">
        <div>
            {{ filter.name }}: {{ filter.value }}
        </div>
        <button @click="clearFilter(filter.filter)">✕</button> 
    </base-pill>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps(['activeFilters'])

const emit = defineEmits(['clear-filter'])

watch(props.activeFilters, ()=> {
    console.log('active filters changed')
}, {deep: true})

function clearFilter(filterName) {
    emit('clear-filter', filterName)
}

</script>
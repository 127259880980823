<template>
    <div class="text-base items-center pr-2.5 pl-2 mb-1 whitespace-nowrap truncate text-accent-primary  hover:text-dark-primary hover:underline" ref="sourcesElement" @mouseover="showPopover" @mouseleave="hidePopover">
       Sources
        <!-- <span  class="text-sm">{{ companySources ? companySources: "(None)" }}</span> -->
    </div>
    <base-popover-hover-small
        class="w-fit"
        v-if="isPopoverVisible"
        :xPosition="xPosition"
        :yPosition="yPosition"
    >
        <template #title>
           <span class="text-sm">Sources:</span>
        </template>
        <template #body>
            <div v-for="source in company.sources" :key="source.id" class="py-1 border-b border-gray-300 w-full">
                {{ source.source_name }}
            </div>
        </template>
    </base-popover-hover-small>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';

const props = defineProps(['company'])

const isPopoverVisible = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const sourcesElement = ref(null)

const companySources = computed(() => {
    if(props.company.sources) {
        let sources = ''
        for(const source of props.company.sources) {
            sources += `${source.source_name}, `
        }
        sources = sources.substring(0, sources.length - 2);
        return sources
    } else {
        return '--'
    }
})

function showPopover() {
    isPopoverVisible.value = true;
    xPosition.value = sourcesElement.value.getBoundingClientRect().left
    yPosition.value = sourcesElement.value.getBoundingClientRect().bottom
}

function hidePopover() {
    isPopoverVisible.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}


</script>
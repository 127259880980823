import { useStore } from "vuex";
import {ref, watch, isRef} from "vue"

export function resetFiltersVisiblity(pageConfig) {
    const store = useStore()
    if(isRef(pageConfig)){
        watch(pageConfig, (newValue, oldValue) => {
            if(!oldValue){
              store.dispatch('company/setVisibleFiltersAllTrue')
              store.dispatch('company/setShowFilterPaneTrue')
              store.dispatch('company/setShowBasicFiltersTrue')
              store.dispatch('company/setShowCampaignFiltersTrue')
              store.dispatch('company/setShowResearchFiltersTrue')
              store.dispatch('company/setShowBulkOperationsPaneTrue')
            }
          })
    }
}
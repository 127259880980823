<template>
  <div>
    <base-table>
      <template #table-header>
        <base-table-header
          :key="header"
          :header="header"
          v-for="header in tableHeaders"
          class="border-b border-b-gray-400 overflow-y-auto sticky top-[55px]"
        >
          {{ header }}
        </base-table-header>
      </template>
      <template #table-body>
        <template v-for="project in content" :key="project.id">
          <ResearchProjectsRow
            :project="project"
            :is-expanded-all="isExpandAll"
            v-on:reload_page="reloadPage"
            v-on:edit_project="editProject"
          ></ResearchProjectsRow>
        </template>
      </template>
    </base-table>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import ResearchProjectsRow from "../research-projects/ResearchProjectsRow.vue";
const props = defineProps({
  content: Array,
  tableHeaders: Array,
  expand: Array,
  isExpandAll: Boolean,
});

const emits = defineEmits(["reload-page", "edit-project"]);

function reloadPage() {
  emits("reload-page");
}

function editProject(project) {
  emits("edit-project", project);
}
</script>

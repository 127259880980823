<template>
    <div class="flex flex-col items-center">
          {{ urlVetted }}
        <div
          class="flex flex-col items-center"
          v-if="
          permissions.isDev ||
          permissions.isAdvancedResearch ||
          permissions.isAccountManager ||
          permissions.isManagement ||
          permissions.isSuperuser
          "
        >
          <base-button v-if="!company.website_url" @click="vetURLAction('mark_no_url_vetted')">Mark No URL</base-button>
          <base-button v-if="company.website_url && !company.url_vetted_by" @click="vetURLAction('mark_url_vetted')">Mark
            URL Vetted
          </base-button>
          <base-button v-else-if="company.website_url && company.url_vetted_by"
                      @click="vetURLAction('remove_url_vetted')">
            Remove URL Vetted
          </base-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "url-vetted-cell"
    }
</script>

<script setup>
    import {ref, defineProps, defineEmits, computed} from "vue"
    import { vetURL } from "@/services/Company";
    import { useStore } from "vuex";

    const store = useStore()
    const props = defineProps({
        company: Object,
        permissions: Object,
    })

    const emit = defineEmits(['refetch-companies'])

    const urlVetted = computed(() => {
        return props.company.url_vetted_by ? props.company.url_vetted_by.first_name + " " + props.company.url_vetted_by.last_name : "--"
    })

    async function vetURLAction(action) {
                const token = store.state.auth.token
                await vetURL(props.company.id, action, token)
                emit('refetch-companies')
            }

</script>
<template>
    <td class="border pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top">
      <slot></slot>
    </td>
  </template>
  
  <script>
  export default {
    name: "Base-table-cell-condensed",
  }
  </script>
  <style scoped>
  </style>
<template>
    <div class="w-full mt-2">
        <div class="grid grid-cols-2 p-1 border-b border-light-primary">
            <div class="flex flex-col xl:grid xl:gap-1 xl:grid-cols-2 2xl:grid-cols-4 items-start border-r border-dotted border-light-primary px-1.5">
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Prefix:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input type="text" required class="p-1 border h-full w-full inline" v-model="contactData.prefix">
                </div>
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">First Name:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input type="text" required class="p-1 border h-full w-full inline" v-model="contactData.first_name">
                </div>
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Middle Name:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input type="text" required class="p-1 border h-full w-full inline" v-model="contactData.middle_name">
                </div>
                    <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Last Name:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input type="text" required class="p-1 border h-full w-full inline" v-model="contactData.last_name">
                </div>
                    <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Suffix:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input type="text" required class="p-1 border h-full w-full inline" v-model="contactData.suffix">
                </div>
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Personal Email:</base-pill>
                </div>
                <div class="flex space-x-1  w-full 2xl:col-span-3">
                    <input type="email" class="p-1 border h-full w-full" v-model="contactData.personal_email">
                </div>
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Contact Personal Cell Phone:</base-pill>
                </div>
                <div class="flex space-x-1 w-full 2xl:col-span-3">
                    <input type="email" class="p-1 border h-full w-full" v-model="contactData.personal_phone">
                </div>
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Linkedin:</base-pill>

                </div>
                <div class="flex space-x-1  w-full 2xl:col-span-3">
                    <input inputType="url" class="p-1 border h-full w-full" v-model="contactData.linkedin_profile">
                </div>
                <div class="flex justify-end py-0.5">
                    <base-pill class="h-fit">Personal Notes:</base-pill>
                </div>
                <div class="w-full 2xl:col-span-3">
                    <textarea class="p-1 border h-full w-full" v-model="contactData.personal_notes"></textarea>
                </div>
            </div>
            <div class="flex flex-col xl:grid xl:gap-1 xl:grid-cols-2 2xl:grid-cols-4 items-start px-1.5">
                <div class="flex justify-end  py-0.5">
                    <base-pill class="h-fit">Title:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input type="text" required class="p-1 border h-full w-full" v-model="companyRelationshipData.title">
                </div>
                <div class="flex justify-end  py-0.5">
                    <base-pill class="h-fit">Contact Direct Line at Company:</base-pill>
                </div>
                <div class="flex space-x-1  2xl:col-span-3 w-full">
                    <input inputType="tel" required class="p-1 border h-full w-full" v-model="companyRelationshipData.phone_number">
                </div>
                <div class="flex justify-end  py-0.5 ">
                    <base-pill class="h-fit">Outreach Email:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <input inputType="email" required class="p-1 border h-full w-full" v-model="companyRelationshipData.email_address">
                </div>
                <div class="flex justify-end  py-0.5">
                    <base-pill class="h-fit">Contact Notes:</base-pill>
                </div>
                <div class="2xl:col-span-3 w-full">
                    <textarea class="p-1 border h-full w-full" v-model="companyRelationshipData.contact_notes"></textarea>
                </div>
                <div class="flex justify-end  py-0.5">
                    <base-pill class="h-fit">Is Primary:</base-pill>
                </div>
                <div class="flex space-x-1 2xl:col-span-3 w-full">
                    <select name="isPrimaryContact" v-model="companyRelationshipData.is_primary_contact" class="p-1 border h-full w-full" id="">
                    <option :value="true">True</option>
                    <option :value="false" selected>False</option>
                </select>
                </div>
                <div class="flex flex-row space-x-2 col-span-4 my-1.5 justify-end w-full">
                    <base-button-affirmative @click="addContact">Save</base-button-affirmative>
                    <base-button-negative @click="closeForm">Cancel</base-button-negative>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "company-contact-form"
    }
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { createContactAndCompanyRelationship } from '@/services/Contact';
import { useStore } from 'vuex';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies', 'close-form'])

    const firstName = ref('')
    const lastName = ref('')
    const personalEemail = ref('')
    const emailAddress = ref('')
    const linkedIn = ref('')
    const contactNotes = ref('')
    const title = ref('')
    const personalPhone = ref('')
    const phoneNumber = ref('')
    const isPrimaryContact = ref('')
    

    function setFirstName(event) {
        contactData.value.first_name = event.target.value
    }

    function setLastName(event) {
        contactData.value.last_name = event.target.value
    }

    function setEmail(event) {
        contactData.value.email = event.target.value
    }

    function setLinkedIn(event) {
        contactData.value.linkedin_profile = event.target.value
    }

    function setContactNotes(event) {
        contactData.value.notes = event.target.value
    }

    function setTitle(event) {
        contactData.value.title = event.target.value
    }

    function setPhone(event) {
        contactData.value.phone = event.target.value
    }

    function setPrimaryContact(event) {
        contactData.value.is_primary_contact = event.target.value
    }

    const contactData = ref({
        prefix: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        suffix: null,
        personal_phone: null,
        personal_email: null,
        linkedin_profile: null,
        personal_notes: null,
    })

    const companyRelationshipData = ref({
        email_address: null,
        contact_notes: null,
        title: null,
        phone_number: null,
        is_primary_contact: false,
        status: "active",
        company_id: props.company.id
    })

    function closeForm(){
        emit('close-form')
    }

    async function addContact(){
        const token = store.state.auth.token
        let errorMessage = "Error, missing "
        if(!contactData.value.first_name || !contactData.value.last_name){
            if(!contactData.value.action_name){
                errorMessage += "first name "
            }
            if(!contactData.value.last_name){
                errorMessage += "last name "
            }
            alert(errorMessage)
            return
        }
        const data = {
            "contact": contactData.value,
            "company_relationship": companyRelationshipData.value
        }
        console.log('form data', data)
        await createContactAndCompanyRelationship(data, token)
        emit('refetch-companies')
        emit('close-form')
    }


</script>
<template>
    <td class="border px-2 py-1 text-xs align-top">
      <slot></slot>
    </td>
  </template>
  
  <script>
  export default {
    name: "Base-table-cell-small",
  }
  </script>
  <style scoped>
  </style>
<template>
    <div class="w-full flex flex-row justify-center space-x-2">
        <div v-for="actionButton in actionButtons" :key="actionButton.id" class="inline">
            <base-button-affirmative v-if="actionButton.type==='affirmative'" @click="selectAction(actionButton.id)">
                {{ actionButton.label }}
            </base-button-affirmative>
            <base-button-negative v-else-if="actionButton.type==='negative'" @click="selectAction(actionButton.id)">
                {{ actionButton.label }}
            </base-button-negative>
            <base-button-disabled v-else-if="actionButton.type==='disabled'" @click="selectAction(actionButton.id)">
                {{ actionButton.label }}
            </base-button-disabled>
            <base-button v-else @click="selectAction(actionButton.id)">
                {{ actionButton.label }}
            </base-button>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps(['actionButtons'])
const emit = defineEmits(['select-action'])

function selectAction(actionButtonId) {
    emit('select-action', actionButtonId)
}

</script>
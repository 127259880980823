<template>
    <div v-if="currentPath === '/companies'" class="pl-1 flex items-center w-fit text-sm xl:text-base">
            {{ showSelectedPageConfigName }}
    </div>

    <div v-else class="pl-1 flex items-center w-fit text-sm xl:text-base">
        {{ currentTitle }}
    </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore()
const route = useRoute()

// watch(() => showSelectedPageConfig, () => {
//         console.log('selecte page config changed')
//     })


const showSelectedPageConfigName = computed(() => store.getters['pageConfigs/showSelectedPageConfigName'])

const currentPath = computed(() => route.path)
const currentTitle = computed(() => route.meta.pageTitle);
</script>
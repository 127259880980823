<template>
  <base-table-row>
    <base-table-cell>
      <div>
        {{ log.id }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ log.log_title }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ log.log_detail }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ user }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div>
        {{ time }}
      </div>
    </base-table-cell>
  </base-table-row>
</template>

<script setup>
// imports

import { defineProps, computed } from "vue";
import { formatDateWithMinutes } from "@/composables/formatDate";

// constants/variables

const props = defineProps({
  log: Object,
});

// computed values

const user = computed(() => {
  if (props.log.user) {
    return props.log.user.first_name + " " + props.log.user.last_name;
  } else {
    return "-----";
  }
});

const time = computed(() => {
  return formatDateWithMinutes(props.log.created_at);
});

</script>

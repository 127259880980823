import { createStore } from "vuex";
import rootMutations from './mutations.js';
import rootActions from './actions.js';
import rootGetters from './getters.js';
import companyModule from './modules/companies/index.js';
import campaignModule from './modules/campaigns/index.js';
import contactModule from './modules/contacts/index.js';
import clientModule from './modules/clients/index.js'
import authModule from './modules/auth/index.js'
import pageConfigModule from './modules/page_configs/index.js'
import researchModule from './modules/research/index.js';
import timecardsModule from './modules/timecards/index.js';
import referralsModule from "./modules/referrals/index.js";
import asynchronousJobModule from './modules/asynchronous_jobs/index.js'

const store = createStore({
    modules: {
        company: companyModule,
        campaigns: campaignModule,
        contact: contactModule,
        client: clientModule,
        auth: authModule,
        pageConfigs: pageConfigModule,
        research: researchModule,
        timecards: timecardsModule,
        referrals: referralsModule,
        asynchronousJobs: asynchronousJobModule,
    },
    state() {
        return {

        }    
    },
    mutations: rootMutations,
    actions: rootActions,
    getters: rootGetters
})

export default store;
<template>
    <div>
        <div v-if="lastCompletedTask">
            <div>
                {{ lastCompletedTask.action_name }}
            </div>
            <div>
                <base-pill>Done on: </base-pill> {{ new Date(lastCompletedTask.resolved_date).toLocaleDateString()  }}
            </div>
            <div>
                <base-pill>Notes: </base-pill> <span>{{ lastCompletedTask.action_notes }}</span> 
            </div>
        </div>
        <div v-else>
            --
        </div>
    </div>
</template>

<script>
    export default {
        name: "last-completed-task-cell"
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const lastCompletedTask = computed(() => {
        let completedTasks = props.company.crm_actions.filter(action => action.campaign.id == props.company.campaign.id && action.status == 'Done')
        if(completedTasks.length == 1){
            return completedTasks[0]
        } else if(completedTasks.length == 0) {
            return null
        }
        let mostRecentCompleted = completedTasks.reduce((accumulator, currentValue) => {
            let accDate = new Date(accumulator.resolved_date).getTime()
            let currDate = new Date(currentValue.resolved_date).getTime()
            if(accDate > currDate){
                return accumulator
            } else {
                return currentValue
            }
        })
        return mostRecentCompleted
    })


</script>
<template>
    <!-- <div
      class="pt-3 pb-0.5 border-b mx-5 mb-5 border-b-gray-400 flex flex-row justify-between"
    >
      <h1 class="text-sm">Timecards Actions:</h1>

    </div> -->
    <div>
        <div >
            <base-button @click="openGenerateTimecardModal">
                Generate Timecard
            </base-button>
        </div>
        <base-modal :is-form-visible="generateModalVisible" @close="closeGenerateTimecardModal">
              <template #header>
                <h2 class="text-lg">Generate Timecard</h2>
              </template>
              <template #body>
                <form action="" @submit.prevent="generateTimecardFromDate" class="w-full bg-neutral-50">
                    <div class="grid grid-cols-2 gap-2 p-2 w-full bg-neutral-50">
                        <div v-if="userPermissions.isDev || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountStaff" class="flex flex-col">
                                <label for="selectedEmployee">User</label>
                                <select required id="selectedEmployee" v-model="selectedEmployee"
                                class="border p-1 border-dark-primary rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full placeholder-gray-400">
                                  <!-- <option value=null>-------</option> -->
                                  <option v-for="user in teamMemberOptions" :key="user.value" :value="user.value">{{ user.label }}</option>
                              </select>
                        </div>
                        <div v-else class="flex flex-col">
                                <label for="selectedEmployee">User</label>
                                <span class="bg-gray-200 p-1 border mt-1 w-full border-gray-400 rounded-md">{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
                        </div>
                        <div class="flex flex-col">
                                <label class="flex items-center" ref="dateLabel" for="timecardDate">Date <HelpSVG @mouseover="showDateHelpPopover" @mouseleave="hideDateHelpPopover" stroke="#4B5563" /></label>
                                <input required :max="today" id="timecardDate" v-model="timecardDate"
                                  type="date"  class="border p-1 border-dark-primary rounded-md w-full" />
                        </div>
                        <base-popover-hover-small
                            class="w-fit"
                            v-if="isDateHelpPopoverVisible"
                            :xPosition="xPosition"
                            :yPosition="yPosition"
                        >
                            <template #title>
                                <span class="text-sm">Choosing a date:</span>
                            </template>
                            <template #body>
                                <div class="py-1 border-gray-300 w-full">
                                    Select a date (no later than today) and the system will create a timecard around that date starting on Saturday and ending on Friday.
                                </div>
                            </template>
                        </base-popover-hover-small>
                        <div class="col-span-2 flex justify-center mt-2">
                            <base-button-affirmative>Submit</base-button-affirmative>
                        </div>
                    </div>
                </form>
              </template>
            </base-modal>
    </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted, watch, computed} from 'vue';
import HelpSVG from '../svg-components/HelpSVG.vue';
import { createTimecardNextSaturday, createTimecardLastSaturday, addUserToTimecardGroup, createTimecardFromDate } from '@/services/Timecard.js';
import { getUsersByGroup } from '@/services/User';
import { useStore } from "vuex";
import { useToast } from 'vue-toast-notification';

const store = useStore();
const $toast = useToast()

const selectedEmployee = ref(null);
const timecardDate = ref(null)
const timecardUsers = ref([]);
const props = defineProps({
    teamMemberOptions: Array,
    timecardUsers: Array,
    userPermissions: Object,
})
const emit = defineEmits(['reload', 'open-dialogue']);

const generateModalVisible = ref(false)

function openGenerateTimecardModal(){
    generateModalVisible.value = true
}

function closeGenerateTimecardModal(){
    generateModalVisible.value = false
}

const isDateHelpPopoverVisible = ref(false)

const dateLabel = ref(null)

const xPosition = ref(null)

const yPosition = ref(null)

function showDateHelpPopover(){
    isDateHelpPopoverVisible.value = true
    xPosition.value = dateLabel.value.getBoundingClientRect().left
    yPosition.value = dateLabel.value.getBoundingClientRect().bottom
}

function hideDateHelpPopover(){
    isDateHelpPopoverVisible.value = false
}

function getPreviousSaturday() {
    const dateObject = new Date();
    const dayOfWeek = 6; //saturday
    let resultDate = new Date(dateObject.getTime());
    resultDate.setDate(dateObject.getDate() - (7 - dayOfWeek + dateObject.getDay()) % 7);
    const strToReturn = "(" + (resultDate.getMonth() + 1) + "/"  + resultDate.getDate() + ")";
    return strToReturn;
}

function getNextSaturday() {
    const dateObject = new Date();
    const dayOfWeek = 6; //saturday
    let resultDate = new Date(dateObject.getTime());
    resultDate.setDate(dateObject.getDate() + (7 + dayOfWeek - dateObject.getDay()) % 7);
    const strToReturn = "(" + (resultDate.getMonth() + 1) + "/"  + resultDate.getDate() + ")";
    return strToReturn;
}

async function genTimecardNextSat() {
    if (!selectedEmployee.value || selectedEmployee.value == "") {
        emit('open-dialogue', 'Action Required', "Please choose an hourly employee to proceed.");
        return;
    } 
    if (!props.timecardUsers.includes(selectedEmployee.value)) {
        emit('open-dialogue', 'Action Required', "To generate a timecard, please add user to the Timecard Group first.");
        return;
    } 
    const data = JSON.stringify({
        user: selectedEmployee.value,
    });

    const output = await createTimecardNextSaturday(data, store.state.auth.token);
    emit('open-dialogue', 'Success', "Successfully created timecard for user with ID " + selectedEmployee.value + " for next Saturday " + getNextSaturday() + ".");
    emit('reload');
}

const currentUser = computed(() => {
    return store.state.auth.user
})

async function generateTimecardFromDate(){
    const token = store.state.auth.token
    if(!selectedEmployee.value){
        selectedEmployee.value = store.state.auth.user.id
    }
    const timecardData = {
        date: timecardDate.value,
        user: selectedEmployee.value
    }
    const result = await createTimecardFromDate(timecardData, token)
    if(result.status == 201){
        $toast.open({
            message: "Successfully created timecard",
            type: "success",
            duration: 3000
        })
        emit('reload')
        closeGenerateTimecardModal()
    } else {
        console.log('error', result)
        $toast.open({
            message: result.response.data,
            type: "error",
            duration: 3000
        })
    }
}

async function genTimecardPrevSat() {
    if (!selectedEmployee.value || selectedEmployee.value == "") {
        emit('open-dialogue', 'Action Required', "Please choose an hourly employee to proceed.");
        return;
    } 
    if (!props.timecardUsers.includes(selectedEmployee.value)) {
        emit('open-dialogue', 'Action Required', "To generate a timecard, please add user to the Timecard Group first.");
        return;
    }
    const data = JSON.stringify({
        user: selectedEmployee.value,
    });

    const output = await createTimecardLastSaturday(data, store.state.auth.token);
    emit('open-dialogue', 'Success', "Successfully created timecard for user with ID " + selectedEmployee.value + " for last Saturday " + getPreviousSaturday() + ".");
    emit('reload');
}

async function addHourlyUserToTimecardGroup() {
    if (!selectedEmployee.value || selectedEmployee.value == "") {
        emit('open-dialogue', 'Action Required', "Please choose an hourly employee to proceed.");
        return;
    } 

    if (props.timecardUsers.includes(selectedEmployee.value)) {
        emit('open-dialogue', 'No Action Taken', "User with ID " + selectedEmployee.value + " is already a member of the Timecard Group.")
        return;
    }
   
    const data = JSON.stringify({
        user: selectedEmployee.value,
    });

    const output = await addUserToTimecardGroup(data, store.state.auth.token);
    emit('open-dialogue', 'Success', "Successfully added user with ID " + selectedEmployee.value + " to the Timecard group.");
    emit('reload');
}

const today = computed(() => {
    const date = new Date()
    return date.toISOString().split('T')[0]
})

// onMounted(() => {
//  loadTimecardUsers();
// });

// async function loadTimecardUsers() {
//     let payload = {
//         token: store.state.auth.token,
//         group: 'generate_timecard_group',
//         endpoint: "list_users",
//     }
//     const output = await getUsersByGroup(payload);
//     timecardUsers.value = output.users;

//     // console.log("Get users by group ", output.users);
// }
</script>
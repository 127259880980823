<template>
    <button class="
        rounded-md
        px-1.5
        py-1
        mx-1
        max-w-32
        my-1
        border-solid
        border
        border-light-primary
        bg-light-primary
        hover:bg-gray-300 hover:shadow-lg
        focus:bg-gray-300 focus:shadow-lg
        focus:outline-none focus:ring-0
        active:bg-gray-400 active:shadow-lg
        text-dark=primary
        text-xs
        cursor-pointer
        sm:text-xs
        sm:h-fit
      ">
        <slot></slot>
    </button>
  
  </template>
  
  <script>
  export default {
    name: "BaseMenuButton.vue"
  }
  </script>
  <style scoped>
  </style>
import axios from 'axios';
import { BASE_URL } from './api';



export async function getEmailTemplates(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/email_templates/get_simple_email_templates`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        console.log("Email template results:" , response.data)
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function getEmailTemplatesFullDetail(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/email_templates/?${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        return {
            results: response.data.results,
            count: response.data.count,
            next: response.data.next,
            prev: response.data.previous,
            page: response.data.page,
            pageSize: response.data.page_size,
            numPages: response.data.num_pages,
        };
    }).catch((error) => {
        throw error
    });

}

export async function getEmailsSendToAddresses(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/email_templates/get_email_send_to_addresses`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    });
}

export async function createNewEmailTemplate(data, token) {
    return axios({
        method: "post",
        url: `${BASE_URL}/email_templates/create_new_email_template/`,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
        .then((response) => response.data)
        .catch((error) => alert(error));
}

export async function getEmailTemplate(emailTemplateId, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/email_templates/get_email_template_by_id/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        params: {templateId: emailTemplateId}
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });
}

export async function editEmailTemplate(templateId, formData, payload) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/email_templates/${templateId}/edit_email_template/`,
        headers: { 
          'Authorization': `Token ${payload.token}`, 
          'Content-Type': 'mulitpar/form-data'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
  }

export default {
    setContacts(state, contacts) {
        console.log('setContacts Mutation')
        state.contacts = contacts;
        console.log("contacts list: ", contacts)
    },
    setIsLoadingTrue(state) {
        state.isLoading = true;
    },
    setIsLoadingFalse(state) {
        state.isLoading = false;
    },
    setNext(state, next) {
        state.next = next
    },
    setPrev(state, prev){
        state.prev = prev
    },
    setCount(state, count){
        state.count = count
    },
    setPage(state, page){
        state.page = page
    },
    setPageSize(state, pageSize){
        state.pageSize = pageSize
    },
    setActiveFilters(state, activeFilters){
        state.activeFilters = activeFilters
    },
    setShowCreateContactFormTrue(state) {
        state.showCreateContactForm = true;
    },
    setShowCreateContactFormFalse(state) {
        state.showCreateContactForm = false;
    },
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
    setShowBasicContactFiltersTrue(state) {
        state.showBasicContactFilters = true;
    },
    setShowBasicContactFiltersFalse(state) {
        state.showBasicContactFilters = false;
    },
    setShowBasicCompanyFiltersTrue(state) {
        state.showBasicCompanyFilters = true;
    },
    setShowBasicCompanyFiltersFalse(state) {
        state.showBasicCompanyFilters = false;
    },
    setShowBulkOperationsPaneTrue(state) {
        state.showBulkOperationsPane = true;
    },
    setShowBulkOperationsPaneFalse(state) {
        state.showBulkOperationsPane = false;
    },
}
import { login, verifyToken } from "@/services/Auth";

function setUserPermissionsByGroup(user) {
    let userPermissions = {}
    let groups = user["groups"]
    userPermissions.isManagement = false
    userPermissions.isHourly = false
    userPermissions.isStudent = false
    userPermissions.isResearch = false
    userPermissions.isAdvancedResearch = false
    userPermissions.isSuperuser = false
    userPermissions.isAccountManager = false
    userPermissions.isAccountStaff = false
    userPermissions.isDev = false
    userPermissions.isResearchLead = false
    userPermissions.isAllCompanies = false
    userPermissions.isGenerateTimecard = false
    for(let i=0; i < groups.length; i++){
        if(groups.some(group => group.name === "management_group")){
            userPermissions.isManagement = true;
        }
        if(groups.some(group => group.name === "hourly_group")){
            userPermissions.isHourly = true;
        }
        if(groups.some(group => group.name === "student_group")){
            userPermissions.isStudent = true;
        }
        if(groups.some(group => group.name === "research_group")){
            userPermissions.isResearch = true;
        }
        if(groups.some(group => group.name === "advanced_research_group")){
            userPermissions.isAdvancedResearch= true;
        }
        if(groups.some(group => group.name === "superuser_group")){
            userPermissions.isSuperuser = true;
        }
        if(groups.some(group => group.name === "account_manager_group")){
            userPermissions.isAccountManager = true;
        }
        if(groups.some(group => group.name === "account_staff_group")){
            userPermissions.isAccountStaff = true;
        }
        if(groups.some(group => group.name === "dev_group")) {
            userPermissions.isDev = true;
        }
        if(groups.some(group => group.name === "research_lead_group")){
            userPermissions.isResearchLead = true;
        }
        if(groups.some(group => group.name === "all_companies_group")) {
            userPermissions.isAllCompanies = true;
        }
        if(groups.some(group => group.name === "generate_timecard_group")){
            userPermissions.isGenerateTimecard = true;
        }
    }
    return userPermissions
}

export default {
    async getToken(context, payload) {
        let userName = payload.username
        let fullName = ""
        let groups = null
        let userPermissions
        const result = await login(userName, payload.password)
        if (result.user) {
            if (result.user["first_name"] === undefined | result.user["last_name"] === undefined) {
              fullName = "User Name Not Defined"
            } else {
              fullName = result.user["first_name"] + ' ' + result.user["last_name"]
            }
            if (result.user["groups"] === []) {
                groups = "User Groups Not Defined"
            } else {
                groups = result.user["groups"]
                userPermissions = setUserPermissionsByGroup(result.user)
            }
        }
        context.commit('setUser', {
            token: result.token,
            user: result.user,
            userGroups: groups,
            userPermissions: userPermissions,
            userFullName: fullName,
            expiry: result.expiry
        })
        localStorage.setItem('token', result.token)
        localStorage.setItem('expiration', result.expiry)

    },
    async tryLogin(context) {
        // console.log('trylogin')
        const token = localStorage.getItem('token');
        const tokenExpiration = localStorage.getItem('expiration');
        const expiresIn = new Date(tokenExpiration).getTime() - new Date().getTime();
        if (expiresIn < 0) {
            console.log('expired token')
          return;
        }
        if(token){
            const response = await verifyToken(token)
            const responseBool = !!response
            // console.log('verify token response', response)
            if(responseBool) {
                let user = response.data["user"]
                let groups = user["groups"]
                let userPermissions
                let fullName = ''
                if (user["first_name"] === undefined | user["last_name"] === undefined) {
                    fullName = "User Name Not Defined"
                  } else {
                    fullName = user["first_name"] + ' ' + user["last_name"]
                  }
                  if (user["groups"] === []) {
                      groups = "User Groups Not Defined"
                  } else {
                    userPermissions = setUserPermissionsByGroup(user)
                  }
                context.commit('setUser', {
                    token: token,
                    user: user,
                    userGroups: groups,
                    userPermissions: userPermissions,
                    userFullName: fullName,
                    expiry: tokenExpiration
                })
            } else {
                console.log('inactive token')
                return
            }
        }
    },
    logout(context) {
        localStorage.removeItem('token')
        localStorage.removeItem('expiration')
        context.commit('setUser',{
            token: null,
            user: null,
            userGroups: null,
            userPermissions: null,
            userFullName: null,
            tokenExpiration: null
        })
        // if global timer set for expires in then clear it HERE
    }
}
<template>
    <div  class="flex flex-col space-y-0.5">
        <CompanyNameCell :company="company" @refetch-companies="fetchNewCompanies" class="font-bold" />
        <DBACell :company="company" @refetch-companies="fetchNewCompanies" />
        <div class="flex flex-col space-y-0.5">
            <base-pill class="max-h-5">Campaign: </base-pill><CampaignCell :company="company" class="inline"/>
        </div>
        <div class="flex space-x-0.5">
            <base-pill>Tier: </base-pill> <TierCell :company="company" class="inline"/>
        </div>
        <div>
            <base-pill>Category: </base-pill> <CategoryCell class="inline" :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
        <ParentCompanyCell :company="company" @refetch-companies="fetchNewCompanies" />
        <InvestorsCell :company="company" @refetch-companies="fetchNewCompanies" />
        <LocationCityStateCell :company="company" />
        <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>

</template>

<script>
    export default {
        name: 'company-name-dba-campaign-tier-category-pc-inv-city-state-url-cell'
    }
</script>

<script setup>
import CompanyNameCell from '../company-cell-components/CompanyNameCell.vue';
import DBACell from '../company-cell-components/DBACell.vue';
import TierCell from '../company-cell-components/TierCell.vue';
import ParentCompanyCell from '../company-cell-components/ParentCompanyCell.vue';
import InvestorsCell from '../company-cell-components/InvestorsCell.vue';
import LocationCityStateCell from '../company-cell-components/LocationCityStateCell.vue';
import WebsiteUrlCell from '../company-cell-components/WebsiteUrlCell.vue';
import CampaignCell from '../company-cell-components/CampaignCell.vue';
import CategoryCell from '../company-cell-components/CategoryCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>

<template>
    <div class="">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton
            class="flex"
          >
            <slot name="label"></slot>
            <ChevronDownIcon
              class="ml-2 -mr-1 h-5 w-5 text-violet-200"
              aria-hidden="true"
            />
          </MenuButton>
        </div>
  
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute left-0 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 max-h-[36rem] focus:outline-none overflow-auto z-30"
          >
          <div v-for="link in links" :key="link.href" class="px-1 py-1 w-full bg-white">
            <MenuItem v-slot="{ active, close }">
                <router-link @click="close" v-if="link.devOnly && userPermissions.isDev" class="max-w-fit" :to="{path: link.href}" >  
                  <button class="flex justify-start w-full flex-nowrap" :class="[
                  active ? 'bg-dark-primary text-white' : 'text-gray-900', 'rounded-md px-2 py-2 text-sm  w-full flex-nowrap']">
                    {{link.label}}
                  </button>
                </router-link>
                <router-link @click="close" v-else-if="link.devOrMgmt && (userPermissions.isDev || userPermissions.isManagement)" class="max-w-fit" :to="{path: link.href}" >        
                  <button class="flex justify-start w-full flex-nowrap" :class="[
                    active ? 'bg-dark-primary text-white' : 'text-gray-900', 'rounded-md px-2 py-2 text-sm  w-full flex-nowrap']">
                    {{link.label}}
                  </button>
                </router-link>
                <router-link @click="close" v-else-if="!link.devOnly" class="max-w-fit" :to="{path: link.href}" >
                  <button class="flex justify-start w-full flex-nowrap" :class="[
                  active ? 'bg-dark-primary text-white' : 'text-gray-900', 'rounded-md px-2 py-2 text-sm  w-full flex-nowrap']">
                    {{link.label}}
                  </button>
                </router-link>
            </MenuItem>
          </div>
            <div v-for="config in publicConfigs" :key="config.id" class="px-1 py-1 w-full">
              <MenuItem   v-slot="{ active }">
                <div class="flex justify-between w-full items-center">
                  <button class="flex justify-start w-full flex-nowrap" @click="setPageConfig(config)"
                    :class="[
                      active  ? 'bg-dark-primary text-white' : isSelectedConfig(config) ? 'bg-dark-primary text-white' : 'text-gray-900',
                      'rounded-md px-2 py-2 text-sm  w-full flex-nowrap '
                    ]" 
  
                  >
                    {{config.configuration_name}}
                  </button>
                  <router-link v-if="config.user && config.user.id == user.id" target="_blank" class="hover:cursor-pointer rounded-lg max-w-fit ml-1 hover:text-dark-secondary text-dark-primary underline" :to="{path: '/manage_page_config/' + config.id}" >Edit</router-link>
                </div>
              </MenuItem>
            </div>
            <div v-for="config in privateConfigs" :key="config.id" class="px-1 py-1 w-full">
              <MenuItem   v-slot="{ active }">
                <div class="flex justify-between w-full items-center">
                  <button class="flex justify-start w-full flex-nowrap" @click="setPageConfig(config)"
                    :class="[
                      active  ? 'bg-dark-primary text-white' : isSelectedConfig(config) ? 'bg-dark-primary text-white' : 'text-gray-900',
                      'rounded-md px-2 py-2 text-sm  w-full flex-nowrap '
                    ]" 
  
                  >
                    {{config.configuration_name}}
                  </button>
                  <router-link v-if="config.user && config.user.id == user.id" target="_blank" class="hover:cursor-pointer rounded-lg max-w-fit ml-1 hover:text-dark-secondary text-dark-primary underline" :to="{path: '/manage_page_config/' + config.id}" >Edit</router-link>
                </div>
              </MenuItem>
            </div>
            <div v-if="companyAdditionalLinks">
              <div class="border border-t-dark-primary">
                <div v-for="link in companyAdditionalLinks" :key="link.href" class="px-1 py-1 w-fit">
                  <MenuItem v-slot="{ active, close }">
                    <router-link @click="close" class="w-full" :to="{path: link.href}" >
                  <button class="flex justify-start w-full flex-nowrap"
                    :class="[
                      active ? 'bg-dark-primary text-white' : 'text-gray-900',
                      'rounded-md px-2 py-2 text-sm  w-full flex-nowrap',
                    ]"
                  >
                      {{link.label}}
                    </button>
                  </router-link>
                </MenuItem>
                <!-- <MenuItem :href="link.href" as="a" v-slot="{ active }">
                  <button class="flex justify-start w-full flex-nowrap"
                    :class="[
                      active ? 'bg-dark-primary text-white' : 'text-gray-900',
                      'rounded-md px-2 py-2 text-sm  w-full flex-nowrap',
                    ]"
                  >
                    {{link.label}}
                  </button>
                </MenuItem> -->
              </div>
            </div>
          </div>
          </MenuItems>
          
        </transition>
      </Menu>
    </div>
  </template>
  
  <script setup>
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/20/solid'
  import { defineProps, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';

  const props = defineProps({
    links: Array,
    companyAdditionalLinks: Array,
    pageConfigs: Array,
    userPermissions: Object
    // selectedConfig: Object
  })

  const route = useRoute()
  const router = useRouter()

  const store = useStore()

  const selectedConfig = computed(() => {
    return store.getters['pageConfigs/showSelectedPageConfig']
  })

  const publicConfigs = computed(()=> {
    return props.pageConfigs.filter(config => config.is_public)
  })

  const privateConfigs = computed(()=> {
    return props.pageConfigs.filter(config => !config.is_public)
  })


  function setPageConfig(config){
    if(route.name != 'companies'){
      router.push({path:'/companies'})
        }
    store.dispatch('pageConfigs/setSelectedPageConfig', config)
    }

  function isSelectedConfig(config){
    if(!selectedConfig.value){
      return false
    }
    return selectedConfig.value.id == config.id
  }

  const user = ref(store.state.auth.user)


  </script>
  
<template>
    <div class="print:hidden mx-1">
        <transition-group
        tag="div"
        enter-active-class="duration-400 ease-out"
        enter-from-class="transform opacity-0 -translate-y-10"
        enter-to-class="opacity-100"
        leave-active-class="duration-400 ease-out absolute"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0 -translate-y-10"
        move="transition-all duration-400 ease"
        >
            <div class="transition-all duration-300 ease-out" key="contract-filters">
                <div
                class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400"
                >
                <!-- <h3 class="text-sm">Client Info:</h3> -->
                <base-button-affirmative @click="resetFilters" class="text-xs -py-2"
                    >Reset</base-button-affirmative
                >
                </div>
                <div
                class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"
                >
                <base-filter :active="!!primeContractFilters.awardingAgencyName.value">
                    <template v-slot:label> Awarding Agency </template>
                    <template v-slot:widget>
                    <BaseListBox
                        class="w-full rounded-b-md text-black"
                        v-model="primeContractFilters.awardingAgencyName.value"
                        :options="primeContractFilters.awardingAgencyName.options"
                        :multiple="false"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.awardingSubAgencyName.value">
                    <template v-slot:label> Awarding Sub Agency </template>
                    <template v-slot:widget>
                    <BaseListBox
                        class="w-full rounded-b-md text-black"
                        v-model="primeContractFilters.awardingSubAgencyName.value"
                        :options="primeContractFilters.awardingSubAgencyName.options"
                        :multiple="false"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.awardIdPIID.value">
                    <template v-slot:label> Award ID PIID </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.awardIdPIID.value"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.recipientUEI.value">
                    <template v-slot:label> Recipient UEI </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.recipientUEI.value"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.cageCode.value">
                    <template v-slot:label> Recipient CAGE Code </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.cageCode.value"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.naicsCode.value">
                    <template v-slot:label> NAICS Code </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.naicsCode.value"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.majorProgram.value">
                    <template v-slot:label> Major Program </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.majorProgram.value"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.transactionDescription.value">
                    <template v-slot:label> Transaction Description </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.transactionDescription.value"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!primeContractFilters.psc.value">
                    <template v-slot:label> PSC </template>
                    <template v-slot:widget>
                    <input
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                        v-model="primeContractFilters.psc.value"
                    />
                    </template>
                </base-filter>
                </div>
            </div>
        </transition-group>
        <div>
        <div class="flex flex-wrap items-center space-x-2 mx-1.5">
          <div class="group flex justify-start items-center space-x-2">
            <h3 class="text-sm">Active Filters:</h3>
          </div>
          <base-pill class="bg-amber-300 flex items-center justify-between space-x-2 p-2 mb-0.5 " v-for="filter in activeFilters" :key="filter.name">
          <div> {{ filter.name }}: {{ filter.value }} </div><button @click="clearFilter(filter.filter)">✕</button> 
          </base-pill>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'prime-contract-filters'
}
</script>

<script setup>
import { ref, watch, reactive, defineEmits, computed } from 'vue';
import {useStore} from 'vuex'
import BaseComboBox from '../BaseComboBox.vue'
import BaseListBox from '../BaseListBox.vue';

const store = useStore()

const emit = defineEmits([
  "change-prime-contract-filters",
]);

const primeContractFilters = reactive({
    awardingSubAgencyName: {
        name: "Awarding Sub Agency",
        value: null,
        defaultValue: null,
        options: [
            {value: null, label: "-----"},
            {value: "Defense Advanced Research Projects Agency", label: "Defense Advanced Research Projects Agency"},
            {value: "Defense Commissary Agency", label: "Defense Commissary Agency"},
            {value: "Defense Contract Management Agency", label: "Defense Contract Management Agency"},
            {value: "Defense Counterintelligence and Security Agency", label: "Defense Counterintelligence and Security Agency"},
            {value: "Defense Finance and Accounting Service", label: "Defense Finance and Accounting Service"},
            {value: "Defense Health Agency", label: "Defense Health Agency"},
            {value: "Defense Human Resources Activity", label: "Defense Human Resources Activity"},
            {value: "Defense Information Systems Agency", label: "Defense Information Systems Agency"},
            {value: "Defense Logistics Agency", label: "Defense Logistics Agency"},
            {value: "Defense Media Activity", label: "Defense Media Activity"},
            {value: "Defense Microelectronics Activity", label: "Defense Microelectronics Activity"},
            {value: "Defense Security Cooperation Agency", label: "Defense Security Cooperation Agency"},
            {value: "Defense Threat Reduction Agency", label: "Defense Threat Reduction Agency"},
            {value: "Department of Defense Education Activity", label: "Department of Defense Education Activity"},
            {value: "Department of Energy", label: "Department of Energy"},
            {value: "Department of State", label: "Department of State"},
            {value: "Department of the Air Force", label: "Department of the Air Force"},
            {value: "Department of the Army", label: "Department of the Army"},
            {value: "Department of the Navy", label: "Department of the Navy"},
            {value: "Federal Energy Regulatory Commission", label: "Federal Energy Regulatory Commission"},
            {value: "International Boundary and Water Commission: U.S.-Mexico", label: "International Boundary and Water Commission: U.S.-Mexico"},
            {value: "Missile Defense Agency", label: "Missile Defense Agency"},
            {value: "National Aeronautics and Space Administration", label: "National Aeronautics and Space Administration"},
            {value: "Office of the Inspector General", label: "Office of the Inspector General"},
            {value: "Space Development Agency", label: "Space Development Agency"},
            {value: "Uniformed Services University of the Health Sciences", label: "Uniformed Services University of the Health Sciences"},
            {value: "U.S. Cyber Command", label: "U.S. Cyber Command"},
            {value: "U.S. Special Operations Command", label: "U.S. Special Operations Command"},
            {value: "USTRANSCOM", label: "USTRANSCOM"},
            {value: "Washington Headquarters Services", label: "Washington Headquarters Services"}
        ]
    },
    awardingAgencyName: {
        name: "Awarding Agency",
        value: null,
        defaultValue: null,
        options: [
            {value: null, label: "-----"},
            {value: "Department of Defense", label: "Department of Defense"},
            {value: "Department of Energy", label: "Department of Energy"},
            {value: "Department of State", label: "Department of State"},
            {value: "National Aeronautics and Space Administration", label: "National Aeronautics and Space Administration"},
        ]
    },
    awardIdPIID: {
        name: "Award ID PIID",
        value: null,
        defaultValue: null
    },
    recipientUEI: {
        name: "Recipient UEI",
        value: null,
        defaultValue: null
    },
    cageCode: {
        name: "Recipient CAGE Code",
        value: null,
        defaultValue: null
    },
    naicsCode: {
        name: "NAICS Code",
        value: null,
        defaultValue: null
    },
    majorProgram: {
        name: "Major Program",
        value: null,
        defaultValue: null
    },
    transactionDescription: {
        name: "Transaction Description",
        value: null,
        defaultValue: null
    },
    psc: {
        name: "PSC",
        value: null,
        defaultValue: null
    },
})

const filterValues = computed(() => {
    let values = []
    for(const filter in primeContractFilters){
        values.push(primeContractFilters[filter].value)
    }
    return values
})



watch(filterValues, () => {
    console.log('filters changed')
    emitFiltersChanged()
}, {deep: true})

function emitFiltersChanged(){
    emit("change-prime-contract-filters", primeContractFilters)
}

function resetFilters() {
    for(const filter in primeContractFilters){
        primeContractFilters[filter].value = primeContractFilters[filter].defaultValue
    }
}

function clearFilter(filterName) {
    if(Array.isArray(primeContractFilters[filterName].value)){
        primeContractFilters[filterName].value = []
    } else {
        primeContractFilters[filterName].value = null
    }
}

const activeFilters = computed(() => {
    let activeFilterArr = []
    for(const filter in primeContractFilters) {
        if(Array.isArray(primeContractFilters[filter].value) && primeContractFilters[filter].value.length > 0){
            console.log('found array')
            activeFilterArr.push({filter: filter, name: primeContractFilters[filter].name, value: primeContractFilters[filter].options.filter(option => primeContractFilters[filter].value.includes(option.value)).map(option => option.label)})
        } else if (!Array.isArray(primeContractFilters[filter].value) && primeContractFilters[filter].value) {
            activeFilterArr.push({filter: filter, name: primeContractFilters[filter].name, value: primeContractFilters[filter].value})
        }
    }
    return activeFilterArr
})


</script>
<template>
    <div>
        <div v-for="source in company.sources" :key="source.id">{{source.source_name}}</div>
    </div>
</template>

<script>
    export default {
        name: "source-cell"
    }
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    company: Object
})

</script>
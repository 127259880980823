<template>
    <div class="print:hidden mx-1">
        <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
            <h3 class="text-sm">Job Info:</h3>
            <base-button-affirmative @click="resetFilters" class="text-xs -py-2">Reset</base-button-affirmative>
        </div>
        <div class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8" id="job-info">
            <base-filter>
                <template v-slot:label>Job ID</template>
                <template v-slot:widget>
                    <input
                        v-model="jobIdFilter"
                        @input="setJobIdFilter"
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="number"
                    >
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>Job Title</template>
                <template v-slot:widget>
                    <input
                        v-model="jobTitleFilter"
                        @input="setJobTitleFilter"
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                    >
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>Job Status</template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder="Select Job Status"
                        :options="jobStatusOptions"
                        :modelValue="jobStatusFilter"
                        :multiple="true"
                        @update:modelValue="setJobStatusFilter"
                    />
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>Job Details</template>
                <template v-slot:widget>
                    <input
                        v-model="jobDetailsFilter"
                        @input="setJobDetailsFilter"
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text"
                    >
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>Job Type</template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder="Select Job Type"
                        :options="jobTypeOptions"
                        :modelValue="jobTypeFilter"
                        :multiple="true"
                        @update:modelValue="setJobTypeFilter"
                    />
                </template>
            </base-filter>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue';
// import store from "@/store";

const jobIdFilter = ref(null);
const jobTitleFilter = ref('');
const jobDetailsFilter = ref('');
const jobTypeFilter = ref([]);
const jobStatusFilter = ref([]);

const emit = defineEmits(['change-job-filters'])

function emitChangeJobFilters(){
    emit('change-job-filters', filters)
    // store.dispatch('asynchronousJobs/setActiveFilters', activeFilters)
}

function setJobIdFilter(event) {
    jobIdFilter.value = event.target.value;
    emitChangeJobFilters()
}

function setJobTitleFilter(event) {
    jobTitleFilter.value = event.target.value;
    emitChangeJobFilters()
}

function setJobDetailsFilter(event) {
    jobDetailsFilter.value = event.target.value;
    emitChangeJobFilters()
}

function setJobTypeFilter(target) {
    target.includes("clear") ? jobTypeFilter.value = [] : jobTypeFilter.value = target
    emitChangeJobFilters()
}

const jobTypeOptions = ref([
    {value: "clear", label: "------------"},
    {value: "full_text_search", label: "Full Text Search"},
])

function setJobStatusFilter(target) {
    target.includes("clear") ? jobStatusFilter.value = [] : jobStatusFilter.value = target
    emitChangeJobFilters()
}

const jobStatusOptions = ref([
    {value: "clear", label: "------------"},
    {value: "queued", label: "Queued"},
    {value: "running", label: "Running"},
    {value: "halted", label: "Halted"},
    {value: "complete", label: "Complete"},
])

const activeFilters = computed(() => {
    let activeFiltersList = []
    if(jobIdFilter.value){
        activeFiltersList.push('Job ID')
    }
    if(jobTitleFilter.value){
        activeFiltersList.push('Job Title')
    }
    if(jobDetailsFilter.value){
        activeFiltersList.push('Job Details')
    }
    if(jobTypeFilter.value){
        activeFiltersList.push('Job Type')
    }
    if(jobStatusFilter.value){
        activeFiltersList.push('Job Status')
    }
    return activeFiltersList
})

function resetFilters() {
    jobIdFilter.value = null
    jobTitleFilter.value = ''
    jobDetailsFilter.value = ''
    jobTypeFilter.value = []
    jobStatusFilter.value = []
}

const filters = computed(() => {
    return {
        jobIdFilter: jobIdFilter,
        jobTitleFilter: jobTitleFilter,
        jobDetailsFilter: jobDetailsFilter,
        jobTypeFilter: jobTypeFilter,
        jobStatusFilter: jobStatusFilter,
    }
})
</script>
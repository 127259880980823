<template>
  <button class="
      rounded-md
      px-1
      py-1
      max-w-32
      my-1
      border-solid
      border
      border-dark-primary
      hover:bg-dark-primary hover:shadow-lg
      active:bg-dark-secondary active:shadow-lg
      text-dark-primary
      hover:text-white
      text-xs
      cursor-pointer
      sm:h-fit
    ">
      <slot></slot>
  </button>

</template>

<script>
export default {
  name: "BaseButton.vue"
}
</script>
<style scoped>
</style>
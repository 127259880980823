<template>
  <button
      class="
        rounded-md
        max-h-8
        text-xs
        px-2
        py-1
        border-solid
        border-1
        border-light-primary
        bg-light-primary
        hover:bg-accent-primary
        focus:bg-accent-primary
        active:bg-accent-secondary
        hover:text-white
        focus:text-white
        active:text-white
        text-primary-dark
        cursor-pointer
      ">
    <slot></slot>
  </button>

</template>

<script>
export default {
  name: "BasePillButton.vue",
  props: ['type', 'mode']
}
</script>
<style scoped>
button {
  font-family: inherit;
}
/* dark blue would be bg-[#3a0061] */
</style>
<template>
  <div class="print:hidden mx-1">
    <transition-group
      tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" key="company-info">
        <div
          class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400"
        >
          <h3 class="text-sm">Task Info:</h3>
          <base-button-affirmative @click="resetFilters" class="text-xs -py-2"
            >Reset</base-button-affirmative
          >
        </div>
        <div
          class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"
          id="company-info"
        >
          <base-filter>
            <template v-slot:label>Status</template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="---------"
                v-model="statusFilter"
                :options="statusOptions"
                @update:modelValue="setStatusFilter"
                :multiple="false"
              />
            </template>
          </base-filter>

          <base-filter>
            <template v-slot:label>Task Type</template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="---------"
                v-model="taskTypeFilter"
                :options="taskTypeOptions"
                @update:modelValue="setTaskTypeFilter"
                :multiple="false"
              />
            </template>
          </base-filter>

          <base-filter>
            <template v-slot:label>Task Title</template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="---------"
                v-model="taskTitleFilter"
                :options="taskTitleOptions"
                @update:modelValue="setTaskTitleFilter"
                :multiple="false"
              />
            </template>
          </base-filter>

          <base-filter @click="setSourceFilterOptions">
            <template v-slot:label> Source </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="---------"
                v-model="sourceFilter"
                :options="sourceOptions"
                @update:modelValue="setSourceFilter"
                :multiple="false"
              />
            </template>
          </base-filter>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
// imports

import { ref, computed, defineEmits, defineProps, watch, reactive } from "vue";
import { useStore } from "vuex";
import { getSources } from "../../services/Source.js";

import BaseListBox from "../BaseListBox.vue";

// constants/variables

const store = useStore();

const emit = defineEmits([
  "change-all-tasks-filters",
  "set-changed-all-tasks-filters",
]);

const taskTitleFilter = ref([]);
const statusFilter = ref([]);
const taskTypeFilter = ref([]);
const sourceFilter = ref([]);
const sourceOptions = ref([]);

const allTaskTitleOptions = ref([
  { value: "clear", label: "------------" },
  { value: "Find and Vet URL", label: "Find and Vet URL" },
  { value: "Further Research Needed", label: "Further Research Needed" },
  { value: "Get Outreach Info", label: "Get Outreach Info" },
  { value: "Mark Tier", label: "Mark Tier" },
  { value: "Review Campaign Assignment", label: "Review Campaign Assignment" },
  { value: "Run De-Duper", label: "Run De-Duper" },
  { value: "Run Initial Zoominfo", label: "Run Initial Zoominfo" },
  { value: "Run Another Zoominfo", label: "Run Another Zoominfo" },
  {
    value: "Stage 2: Discover and Enrich Company",
    label: "Stage 2: Discover and Enrich Company",
  },
]);

const researchTasks = ref([
  { value: "clear", label: "------------" },
  { value: "Find and Vet URL", label: "Find and Vet URL" },
  { value: "Further Research Needed", label: "Further Research Needed" },
  { value: "Run De-Duper", label: "Run De-Duper" },
  { value: "Run Initial Zoominfo", label: "Run Initial Zoominfo" },
  { value: "Run Another Zoominfo", label: "Run Another Zoominfo" },
  {
    value: "Stage 2: Discover and Enrich Company",
    label: "Stage 2: Discover and Enrich Company",
  },
]);

const accountManagerTasks = ref([
  { value: "clear", label: "------------" },
  { value: "Get Outreach Info", label: "Get Outreach Info" },
  { value: "Mark Tier", label: "Mark Tier" },
  { value: "Review Campaign Assignment", label: "Review Campaign Assignment" },
]);

const taskTitleOptions = computed(() => {
  if (taskTypeFilter.value === "research_task") {
    return researchTasks.value;
  } else if (taskTypeFilter.value === "account_manager_task") {
    return accountManagerTasks.value;
  }
  return allTaskTitleOptions.value;
});

const statusOptions = ref([
  { value: "clear", label: "------------" },
  { value: "active", label: "Active" },
  { value: "complete", label: "Complete" },
  { value: "cancelled", label: "Cancelled" },
]);

const taskTypeOptions = ref([
  { value: "clear", label: "------------" },
  { value: "research_task", label: "research_task" },
  { value: "account_manager_task", label: "account_manager_task" },
]);

// watch

watch(taskTypeFilter, () => {
  taskTitleFilter.value = [];
  emitChangeApprovalFiltersAndSetChanged();
});

// computed values

const filters = computed(() => {
  return {
    taskTitleFilter: taskTitleFilter,
    statusFilter: statusFilter,
    taskTypeFilter: taskTypeFilter,
    sourceFilter: sourceFilter,
  };
});

// async functions

async function setSourceFilterOptions() {
  let token = store.state.auth.token;
  const result = await getSources(token);
  sourceOptions.value = [
    { value: "clear", label: "------------" },
    ...result.map((source) => {
      return {
        value: source.id,
        label: source.source_name,
      };
    }),
  ];
}

// functions

function setTaskTitleFilter(target) {
  if (target === "clear") {
    taskTitleFilter.value = [];
  } else {
    taskTitleFilter.value = target;
  }
  emitChangeApprovalFiltersAndSetChanged();
}

function setStatusFilter(target) {
  if (target === "clear") {
    statusFilter.value = [];
  } else {
    statusFilter.value = target;
  }
  emitChangeApprovalFiltersAndSetChanged();
}

function setTaskTypeFilter(target) {
  if (target === "clear") {
    taskTypeFilter.value = "";
  } else {
    taskTypeFilter.value = target;
  }
  emitChangeApprovalFiltersAndSetChanged();
}

function setSourceFilter(target) {
  if (target === "clear") {
    sourceFilter.value = [];
  } else {
    sourceFilter.value = target;
  }
  emitChangeApprovalFiltersAndSetChanged();
}

function emitChangeApprovalFiltersAndSetChanged() {
  emit("change-all-tasks-filters", filters);
  emit("set-changed-all-tasks-filters");
}

function resetFilters() {
  taskTitleFilter.value = [];
  statusFilter.value = [];
  taskTypeFilter.value = [];
  sourceFilter.value = [];
  emitChangeApprovalFiltersAndSetChanged();
}
</script>

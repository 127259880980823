<template>
    <div class="flex flex-col space-y-1">
        <!-- <base-button-affirmative class="rounded-full" @click="transferToCollection('Machining Forming Candidates - Vetted')">Interesting</base-button-affirmative>
        <base-button-negative class="rounded-full" @click="transferToCollection('Machining Forming Candidates - Removed')">Not Interesting</base-button-negative> -->
        <base-button-affirmative class="rounded-full" @click="createPost('Interesting')">Interesting</base-button-affirmative>
        <base-button-negative class="rounded-full" @click="createPost('Not Interesting')">Not Interesting</base-button-negative>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import { transferCollectionTag } from '@/services/Company';
import { createResearchPost } from '@/services/ResearchPost';

const store = useStore()
const $toast = useToast()

const props = defineProps(['company'])
const emit = defineEmits(['refetch-companies'])

const currentCollectionName = ref('Machining Forming Candidates')

async function transferToCollection(newCollectionName) {
    console.log(newCollectionName)
    const token = store.state.auth.token
    const result = await transferCollectionTag(currentCollectionName.value, newCollectionName, props.company.id, token)
    if(result.status == 200) {
        $toast.open({
            message: `Successfully transferred ${props.company.company_name} (id-${props.company.id}) from ${currentCollectionName.value} to ${newCollectionName}.`,
            type: 'success',
            duration: 5000
        })
        emit('refetch-companies')
    } else {
        let errorMessage = result.response.data
        $toast.open({
            message: `Unable to transfer ${props.company.company_name} (id-${props.company.id}). \n ${errorMessage}`,
            type: 'error',
            duration: 20000
        })
}
}

const postData = ref({
        company_id: props.company.id,
        post_type: 'collection_research',
        post_category: null,
        collection_tag: null,
        collection_tag_name: null,
        post_body: null,
        flag: null,
    })

async function createPost(category){
    const token = store.state.auth.token
    postData.value.collection_tag = 618
    postData.value.collection_tag_name = 'P&I: All Products Capabilities & Metals'
    postData.value.post_category = category
    const result = await createResearchPost(postData, token)
    if(result.status == 200) {
            $toast.open({
                message: `${postData.value.post_category} Post Created`,
                type: 'success',
                duration: 5000
            })
            emit('refetch-companies')
        } else {
            $toast.open({
                message: 'Error, post not created',
                type: 'error',
                duration: 20000
            })
        }
}
</script>
<!--./src/components/NavBar.vue -->

<template>
  <div class="flex flex-wrap items-center print:hidden w-full" ref="navBoundary">
    <section class="relative mx-auto w-full">
      <!-- navbar -->
      <nav
        class="flex justify-between pl-3.5 items-center bg-dark-primary text-white w-full h-14 z-50">
        <div class="flex items-center -mt-1">
          <router-link to="/">
            <img class="h-8 object-fit" src="../assets/images/jet_upward.png" alt="logo">
          </router-link>
          <!-- <a class="text-xl font-bold font-heading pl-2" href="/">
            Aerocle&nbsp;v2
          </a> -->
          <BreadCrumb v-if="windowWidth > navBreakpoint" class="-mt-0.5" />
          <MenuBar :userPermissions="userPermissions" />
        </div>
        <!-- Nav Links -->
        <div v-if="windowWidth < navBreakpoint" class="flex justify-between">
          <div class="hover:bg-dark-secondary p-1 rounded-md focus:bg-dark-secondary cursor-pointer">
            <svg @click="toggleNavDropdown" class="fill-white" viewBox="0 0 100 80" width="25" height="25">
              <rect width="100" height="10" rx="5" ry="5"></rect>
              <rect y="30" width="100" height="10" rx="5" ry="5"></rect>
              <rect y="60" width="100" height="10" rx="5" ry="5"></rect>
            </svg>
          </div>
          <base-card v-if="isNavDropdownVisible"
            class="fixed top-12 left-0 w-full bg-white text-dark-primary rounded-t-none">
            <ul class="text-left">
              <li class="my-1 p-1">
                <CompanyResearchLinks :userPermissions="userPermissions" :links="companyLinks"
                :companyAdditionalLinks="companyAdditionalLinks" :pageConfigs="researchPageConfigs" @fetch-page-configs="fetchPageConfigs" />
              </li>
              <li class="my-1 p-1">
                <CampaignLinks :userPermissions="userPermissions" :links="campaignLinks" :pageConfigs="crmPageConfigs"
                  @fetch-page-configs="fetchPageConfigs" />
              </li>
              <li v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement"
                class="my-1 p-1">
                <CompanyCRMLinks :userPermissions="userPermissions" :links="links" :pageConfigs="crmPageConfigs"
                  @fetch-page-configs="fetchPageConfigs" />
              </li>
              <!-- <li v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement"
                class="my-1 p-1">
                <CompanyNewCRMLinks :userPermissions="userPermissions" :links="newCrmLinks" :pageConfigs="crmPageConfigs"
                  @fetch-page-configs="fetchPageConfigs" />
              </li> -->
              <li>
                <ResearchLinks :userPermissions="userPermissions" :links="researchLinks" :pageConfigs="crmPageConfigs"
                  @fetch-page-configs="fetchPageConfigs" />
              </li>
              <li v-if="userPermissions.isDev" class="my-1 p-1">
                <ContactLinks :userPermissions="userPermissions" />
              </li>
              <li v-if="userPermissions.isDev" class="my-1 p-1">
                <router-link to="/clients"
                  class="p-1 rounded-lg hover:text-white hover:bg-dark-primary">Clients</router-link>
              </li>
              <li v-if="userPermissions.isDev" class="my-1 p-1"><a href="#"
                  class="p-1 rounded-lg hover:text-white hover:bg-dark-primary">Campaigns</a></li>
              <li v-if="userPermissions.isDev" class="my-1 p-1"><a href="#"
                  class="p-1 rounded-lg hover:text-white hover:bg-dark-primary">Referrals</a></li>
              <li v-if="userPermissions.isDev" class="my-1 p-1"><a href="#"
                  class="p-1 rounded-lg hover:text-white hover:bg-dark-primary">Timecards</a></li>
              <li v-if="userPermissions.isDev" class="my-1 p-1"><a href="#"
                  class="p-1 rounded-lg hover:text-white hover:bg-dark-primary">Guides</a></li>
              <li v-if="userPermissions.isDev" class="my-1 p-1"><a href="#"
                  class="p-1 rounded-lg hover:text-white hover:bg-dark-primary">Admin</a></li>
              <li v-if="isLoggedIn" @click="logout" class="my-1 p-1">
                <router-link class="-ml-2 p-1 rounded-lg hover:text-white hover:bg-dark-secondary"
                  to="/auth">Logout</router-link>
              </li>
            </ul>
          </base-card>
        </div>
        <div v-else class="flex justify-between grow items-center">
          <div class="flex flex-row justify-start mx-auto font-semibold font-heading items-center space-x-4
             text-xs">
            <CompanyResearchLinks :userPermissions="userPermissions" :links="companyLinks" :companyAdditionalLinks="companyAdditionalLinks"
              :pageConfigs="researchPageConfigs" @fetch-page-configs="fetchPageConfigs" />
            <ClientLinks :userPermissions="userPermissions" :links="clientLinks" :pageConfigs="researchPageConfigs"
              @fetch-page-configs="fetchPageConfigs">
            </ClientLinks>
            <CampaignLinks :userPermissions="userPermissions" :links="campaignLinks" :pageConfigs="crmPageConfigs"
              @fetch-page-configs="fetchPageConfigs" />
            <CompanyCRMLinks :userPermissions="userPermissions" :links="links" :pageConfigs="crmPageConfigs"
              @fetch-page-configs="fetchPageConfigs" />
            <!-- <CompanyNewCRMLinks :userPermissions="userPermissions" :links="newCrmLinks" :pageConfigs="crmPageConfigs"
              @fetch-page-configs="fetchPageConfigs" /> -->
            <ResearchLinks :userPermissions="userPermissions" :links="researchLinks" :pageConfigs="crmPageConfigs"
              @fetch-page-configs="fetchPageConfigs" />
            <ContactLinks :userPermissions="userPermissions" :links="contactLinks" :pageConfigs="crmPageConfigs"
              @fetch-page-configs="fetchPageConfigs"></ContactLinks>
            <a v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement"
              class="hover:text-gray-200 shrink" href="/create_page_config">Create New Page</a>
          </div>
          <div class="flex flex-row justify-end items-center">
            <HeadlessPopoverClick>
              <template #popover-title>
                <div class="text-sm w-full  whitespace-nowrap">
                  {{ loggedInUserFullName }}
                </div>
                <!-- <span ref="profileIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-8 h-8 hover:text-gray-200">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                    </svg>
                  </span> -->
              </template>
              <template #popover-body>
                <div v-if="userPermissions.isDev" class="bg-white rounded-t font-semibold font-heading text-dark-primary p-2 w-full">
                  <router-link  class="hover:text-gray-200 shrink"
                    to="/admin">Admin Tools</router-link>
                </div>
                <div v-if="userPermissions.isDev" class="bg-white rounded-t font-semibold font-heading text-dark-primary p-2 w-full">
                  <router-link  class="hover:text-gray-200 shrink"
                    to="/admin_import_tools">Admin - Import Tools</router-link>
                </div>
                <div class="bg-white rounded-b font-semibold font-heading text-dark-primary p-2 w-full">
                  <router-link v-if="isLoggedIn" @click="logout" class="hover:text-gray-200 shrink"
                    to="/auth">Logout</router-link>
                </div>
                
              </template>
            </HeadlessPopoverClick>
            <!-- <a class="flex items-center hover:text-gray-200" href="#" @click="showPopover">
            </a> -->
          </div>
        </div>

      </nav>
    </section>
  </div>
</template>

<script>
export default {
  name: "NavBar",
}
</script>

<script setup>
import { computed, shallowReactive, ref, defineProps, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import BreadCrumb from '@/components/BreadCrumb.vue';
import MenuBar from '@/components/menu-bar-components/MenuBar.vue';
import { useClickOutside } from '@/composables/useClickOutside';
import { getCampaigns } from '@/services/Campaign';
import { getClientsWithBuckets } from '@/services/Client';
import CompanyResearchLinks from './nav-bar-link-components/CompanyResearchLinks.vue';
import CampaignLinks from './nav-bar-link-components/CampaignLinks.vue';
import CompanyCRMLinks from './nav-bar-link-components/CompanyCRMLinks.vue';
import CompanyNewCRMLinks from './nav-bar-link-components/CompanyNewCRMLinks.vue';
import ContactLinks from './nav-bar-link-components/ContactLinks.vue';
import ResearchLinks from './nav-bar-link-components/ResearchLinks.vue';
// import ReferralLinks from './nav-bar-link-components/ReferralLinks.vue';
// import TimecardLinks from './nav-bar-link-components/TimecardLinks.vue';
import ClientLinks from '../components/nav-bar-link-components/ClientLinks.vue'
import HeadlessPopoverClick from '../components/HeadlessPopoverClick.vue'

const store = useStore()
const router = useRouter();
const isLoggedIn = computed(function () {
  return store.getters['auth/isAuthenticated']
})
onMounted(() => {
  configureResearchLinks();
});

const links = ref([
  { href: '/campaign_activities/16', label: 'Enercon outreach activitites' },
  { href: '/campaign_activities/17', label: 'Test campaign activities' },
])

const newCrmLinks = ref([
  { href: '/new_crm/16', label: 'Enercon outreach activitites' },
  { href: '/new_crm/17', label: 'Test campaign activities' },
])

const companyLinks = ref([
  { href: '/pdf_tier_view', label: 'PDF Tier View' },
  { href: '/client_appendices', label: 'Client Appendices'},
  { href: '/dla_qpl_companies', label: 'DLA QPL Companies' },
])

const companyAdditionalLinks = ref([
  { href: '/upload_companies', label: 'Upload Companies' },
  { href: '/businesstypes_sectors_subsectors', label: 'Business Types, Sectors, & Sub Sectors' }
])

const researchLinks = ref([
  // {href: '/instructional_videos', label: 'Instructional Videos'},
  // {href: '/research_guide', label: 'Research Guide'},
  // {href: '/training_documents', label: 'Training Documents'},
  // {href: '/project_assignments', label: 'Project Assignments'},
  // {href: '/research_projects', label: 'Research Projects'},
  // {href: '/pma_list', label: 'All Parts Manufacturer Approvals'},
  // {href: '/all_tasks', label: 'All Tasks'},
  // {href:'/job_queue', label: 'Job Queue'},
  // { href: '/timecards', label: 'Timecards' },
])


function configureResearchLinks() {
  if ( userPermissions.isSuperuser || userPermissions.isDev || userPermissions.isManagement || userPermissions.isHourly || userPermissions.isResearch || userPermissions.isResearchLead || userPermissions.isAccountManager || userPermissions.isAccountStaff) {
    researchLinks.value.push({href: '/instructional_videos', label: 'Instructional Videos'});
    researchLinks.value.push({href: '/research_guide', label: 'Research Guide'});
  }
  if ( userPermissions.isSuperuser || userPermissions.isDev || userPermissions.isManagement) {
    researchLinks.value.push({href: '/training_documents', label: 'Training Documents'});
  }
  if (userPermissions.isHourly || userPermissions.isDev || userPermissions.isSuperuser || userPermissions.isManagement || userPermissions.isAccountManager) {
    researchLinks.value.push({ href: '/timecards', label: 'Timecards' });
  }
  if ( userPermissions.isSuperuser || userPermissions.isDev || userPermissions.isManagement || userPermissions.isAccountManager || userPermissions.isAdvancedResearch) {
    researchLinks.value.push({href: '/job_queue', label: 'Job Queue'});
  }
  if ( userPermissions.isSuperuser || userPermissions.isDev || userPermissions.isManagement || userPermissions.isAccountManager) {
    researchLinks.value.push({href: '/project_assignments', label: 'Project Assignments'});
    researchLinks.value.push({href: '/research_projects', label: 'Research Projects'});
    researchLinks.value.push({href: '/pma_list', label: 'All Parts Manufacturer Approvals'});
    researchLinks.value.push({href: '/prime_contracts', label: 'Prime Contracts'});
  }

  if (userPermissions.isDev) {
    researchLinks.value.push({href: '/all_tasks', label: 'All Tasks'});
  }
}

const contactLinks = ref([
  { href: '/contacts', label: 'Contacts'},
  { href: '/referrers', label: 'Referral Sources'}
])

const campaignLinks = ref([
  { href: '/crm_for_pdf', label: 'CRM For PDF' },
  { href: '/quick_file_client_emails', label: 'Quick File Client Emails' },
  { href: '/campaigns', label: 'Manage Campaigns' },
  { href: '/company-campaign-change-requests', label: 'Company Campaign Change Requests'},
  { href: '/email_templates', label: 'Email Templates'},
  { href: '/campaign_change_report', label: 'Campaign Change Report'},
  { href: '/crm-resolution', label: 'CRM Resolution'},
])

const clientLinks = ref([
  { href: '/clients', label: 'Clients' },
  { href: '/engagements', label: 'Engagements', devOrMgmt: true },
  { href: '/invoices', label: 'Invoices', devOrMgmt: true },
  { href: '/invoice_reminders', label: 'Invoice Reminders', devOrMgmt: true },
  { href: '/service-records', label: 'Service Records', devOrMgmt: true},
])

const props = defineProps({
  windowWidth: {
    type: Number,
    required: true
  }
})


const profileIcon = ref(null)
const xPosition = ref(0)
const yPosition = ref(0)
const isPopoverVisible = ref(false)
const isNavDropdownVisible = ref(false)
const navBreakpoint = 1024
const navBoundary = ref(null)

watch(() => props.windowWidth, (currentWidth) => {
  if (currentWidth > navBreakpoint) {
    // console.log('window width is bigger than breakpoint')
    hideNavDropdown()
  }
})

useClickOutside(navBoundary, () => {
  hideNavDropdown()
})


function toggleNavDropdown() {
  isNavDropdownVisible.value = !isNavDropdownVisible.value
}

function showNavDropdown() {
  isNavDropdownVisible.value = true
}

function hideNavDropdown() {
  isNavDropdownVisible.value = false
}

function showPopover() {
  isPopoverVisible.value = true;
  xPosition.value = profileIcon.value.getBoundingClientRect().right
  yPosition.value = 10
  console.log(xPosition.value, yPosition.value)
  console.log(isPopoverVisible)
}

function hidePopover() {
  isPopoverVisible.value = false;
  xPosition.value = 0;
  yPosition.value = 0;
}

const userPermissions = shallowReactive({
  isManagement: null,
  isHourly: null,
  isStudent: null,
  isResearch: null,
  isAdvancedResearch: null,
  isSuperuser: null,
  isAccountManager: null,
  isAccountStaff: null,
  isDev: null,
  isResearchLead: null,
  isAllCompanies: null,
  isGenerateTimecard: null,
});

const setUserPermissions = function () {
  let userPermissionsStore = store.getters['auth/showUserPermissions']
  // console.log('user permissions store', userPermissionsStore)
  userPermissions.isManagement = userPermissionsStore.isManagement
  userPermissions.isHourly = userPermissionsStore.isHourly
  userPermissions.isStudent = userPermissionsStore.isStudent
  userPermissions.isResearch = userPermissionsStore.isResearch
  userPermissions.isAdvancedResearch = userPermissionsStore.isAdvancedResearch
  userPermissions.isSuperuser = userPermissionsStore.isSuperuser
  userPermissions.isAccountManager = userPermissionsStore.isAccountManager
  userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff
  userPermissions.isDev = userPermissionsStore.isDev
  userPermissions.isResearchLead = userPermissionsStore.isResearchLead
  userPermissions.isAllCompanies = userPermissionsStore.isAllCompanies
  userPermissions.isGenerateTimecard = userPermissionsStore.isGenerateTimecard

  // console.log('user permissions', userPermissions)
}

const loggedInUserFullName = computed(function () {
  if (store.getters['auth/showUserFullName']) {
    if (store.getters['auth/showUserFullName']) {
      return store.getters['auth/showUserFullName']
    } else {
      return "User Name Not Defined"
    }
  }
  return "No User Logged In"
  // if (store.getters['auth/showUser']) {
  //   if (store.getters['auth/showUser']["first_name"] === undefined | store.getters['auth/showUser']["last_name"] === undefined) {
  //     return "User Name Not Defined"
  //   } else {
  //     return store.getters['auth/showUser']["first_name"] + ' ' + store.getters['auth/showUser']["last_name"]
  //   }
  // }
  // return "No User Logged In"
})


const loggedInUser = computed(function () {
  return store.getters['auth/showUser']
})




// console.log(JSON.stringify(store.getters['auth/showUser']))
// console.log(JSON.stringify(store.getters['auth/showToken']))
// console.log(JSON.stringify(store.getters['auth/isAuthenticated']))
// console.log("store?", store)
// console.log("store auth/isAuthenticated?", store.getters['auth/isAuthenticated'])
// console.log("isLoggedIn". isLoggedIn)
// console.log(store.getters['auth/showUser'])
// console.log(store.getters['auth/showToken'])

function logout() {
  store.dispatch('auth/logout')
  router.push({ name: 'login' });
}

setUserPermissions()

const researchPageConfigs = computed(() => {
  const pageConfigs = store.state.pageConfigs.pageConfigs
  return pageConfigs.filter(config => config.menu_location == 0)
})

const crmPageConfigs = computed(() => {
  const pageConfigs = store.state.pageConfigs.pageConfigs
  return pageConfigs.filter(config => config.menu_location == 1)
})

async function setCampaignActivityLinks() {
  const token = store.state.auth.token
  const result = await getCampaigns(token)
  // console.log('campaign result', result)
  links.value = [{ href: '/campaign_activities_summary', label: 'Campaign Activities Summary' }, ...result.map(campaign => {
    return { href: `/campaign_activities/${campaign.id}`, label: `${campaign.campaign_name} Activities` }
  })]
  newCrmLinks.value = [...result.map(campaign => {
    return { href: `/new_crm/${campaign.id}`, label: `${campaign.campaign_name} Activities` }
  })]
}

setCampaignActivityLinks();

async function setCampaignLinks() {
  const payload = {
    token: store.state.auth.token
  }
  const result = await getClientsWithBuckets(payload);

  let emails = []
  result.clients.forEach(function (client) {
    emails.push({ href: `/emails_received/${client.id}`, label: `Emails Received for ${client.client_name}` })
    // campaignLinks.value.unshift({href: `/emails_received/${client.id}`, label: `Emails Received for ${client.client_name}`})
  });
  campaignLinks.value = emails.concat(campaignLinks.value)

  // campaignLinks.value
  // console.log(result.clients)
}

setCampaignLinks();

function fetchPageConfigs() {
  // console.log('fetch page configs ran')
  // store.dispatch('pageConfigs/setPageConfigs')
}

async function fetchAndLoadPageConfigs() {
  // console.log('fetch and load page configs ran')
  await store.dispatch('pageConfigs/setPageConfigs')
  store.dispatch('pageConfigs/tryToSetPageConfig')
}
fetchAndLoadPageConfigs()
</script>

<style>
/*header {*/
/*  font-size: medium;*/
/*}*/
</style>
<template>
    <div v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement || userPermissions.isSuperuser"
        class="h-screen flex flex-col space-x-2 lg:flex-row mx-6 mt-44 text-center justify-between w-full">
        <div class="w-1/4 space-y-2">
            <div class=" break-words text-2xl">Step 1: Download Companies Template</div>
            <div class="flex justify-center items-center">
                <base-button @click="getTemplate">Download Template (.xlsx)</base-button>
            </div>
        </div>

        <div class="w-1/4 space-y-2">
            <div class="text-2xl">Step 2: Fill In Template</div>
            <div>1. Fill out respective fields</div>
            <div>2. Make sure format is not changed</div>
            <div>2. Download completed file as a .xlsx</div>
        </div>

        <div class="w-1/4 space-y-2">
            <div class="text-2xl">Step 3: Create or Set Source</div>
            <div class="flex justify-center items-center">
                <button class="w-16 border border-dark-primary p-1 rounded-l-md "
                    :class="{ 'bg-accent-primary shadow-lg text-white': isCreateActive }"
                    @click="setCreateActive">Create</button>
                <button class="w-16 border border-dark-primary p-1 rounded-r-md"
                    :class="{ 'bg-accent-primary shadow-lg text-white': isSetActive }" @click="setSetActive">Set</button>
            </div>
            <div v-if="isCreateActive">
                <input type="text" placeholder="New Source Name" class="p-1 border w-full inline rounded-md"
                    v-model="newSourceName">
            </div>
            <div v-if="isSetActive">
                <select required class="p-1 border border-dark-primary rounded-md w-full" v-model="existingSourceID">
                    <option v-for="source in sourceOptions" :key="source.value" :value="source.value">{{ source.label }}
                    </option>
                </select>
            </div>

        </div>

        <div class="w-1/4 space-y-2">
            <div class="text-2xl">Step 4: Submit Template and Source</div>
            <div class="mt-4 flex justify-center flex-col items-center">
                <label for="companiesFile"
                    class="rounded-md px-1 py-1 max-w-32 my-1 border-solid border border-dark-primary hover:bg-dark-primary hover:shadow-lg active:bg-dark-secondary active:shadow-lg text-dark-primary hover:text-white text-xs cursor-pointer sm:h-fit">
                    Choose File
                </label>
                <input @change="processCompaniesFile" name="companiesFile" id="companiesFile" type="file" accept=".xlsx"
                    class="hidden" />
                <div>
                    <div v-if="uploadedFileName" class="break-all">{{ uploadedFileName }}</div>
                    <div v-else>No File Chosen</div>
                </div>
                <base-button-affirmative class="w-16" @click="submitCompaniesFile">Submit</base-button-affirmative>

            </div>
        </div>
    </div>
</template>
  
<script setup>
import { onMounted, ref, shallowReactive } from 'vue'
import { useStore } from 'vuex'
import { getSources, createSource } from '@/services/Source'
import { checkCompaniesTemplateValid, createCompaniesFromXLSX, downloadTemplate } from '@/services/UploadCompanies'

const store = useStore()
const uploadedFile = ref(null)
const uploadedFileName = ref(null)
const newSourceName = ref(null)
const existingSourceID = ref(null)
const isCreateActive = ref(true)
const isSetActive = ref(false)

const sourceOptions = ref([])
const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
});
const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent = userPermissionsStore.isStudent
    userPermissions.isResearch = userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch = userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser = userPermissionsStore.isSuperuser
    userPermissions.isAccountManager = userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff
    userPermissions.isDev = userPermissionsStore.isDev
    userPermissions.isResearchLead = userPermissionsStore.isResearchLead
    userPermissions.isAllCompanies = userPermissionsStore.isAllCompanies
    userPermissions.isGenerateTimecard = userPermissionsStore.isGenerateTimecard

    // console.log('user permissions', userPermissions)
}

function processCompaniesFile(event) {
    if (event.target.files && event.target.files[0]) {
        uploadedFile.value = event.target.files[0]
        uploadedFileName.value = uploadedFile.value ? uploadedFile.value.name : null
    }
}

async function submitCompaniesFile() {
    const token = store.state.auth.token

    let missingInfoMessage = "Error: missing "
    if (!uploadedFile.value || (!newSourceName.value && !existingSourceID.value)) {
        if (!uploadedFile.value) {
            missingInfoMessage += "file, "
        }
        if (!newSourceName.value && !existingSourceID.value) {
            missingInfoMessage += "source "
        }
        alert(missingInfoMessage)
        return
    }
    if (sourceOptions.value.find((source) => source.label === newSourceName.value)) {
        alert('Error: source already exists')
        return
    }

    let formData = new FormData()
    formData.append('companiesFile', uploadedFile.value)

    const checkTemplateValid = await checkCompaniesTemplateValid(formData, token)
    if (!checkTemplateValid.data.ok) {
        const errors = checkTemplateValid.data.errors
        let errorMessage = "Error: Template "
        if (errors.company_names || errors.cities || errors.countries) {
            errorMessage += 'missing '
            if (errors.company_names) {
                const fieldName = errors.company_names == 1 ? ' company, ' : ' companies, '
                errorMessage += errors.company_names + fieldName
            }
            if (errors.cities) {
                const fieldName = errors.cities == 1 ? ' city, ' : ' cities, '
                errorMessage += errors.cities + fieldName
            }
            if (errors.countries) {
                const fieldName = errors.countries == 1 ? ' country, ' : ' countries, '
                errorMessage += errors.countries + fieldName
            }
        }
        if (errors.campaigns) {
            const fieldName = errors.campaigns == 1 ? ' invalid campaign, ' : ' invalid campaigns, '
            errorMessage += 'has ' + errors.campaigns + fieldName
        }
        alert(errorMessage.slice(0, -2))
        return
    } 

    if (newSourceName.value) {
        const addSourceResult = await addSource()
        const newSourceID = addSourceResult.data.id
        // console.log('newSourceID:', newSourceID)
        formData.append('sourceID', JSON.stringify(newSourceID))

        const result = await createCompaniesFromXLSX(formData, token)
        // console.log(result)
        alert(result.data.status)

    } else if (existingSourceID.value) {
        formData.append('sourceID', JSON.stringify(existingSourceID.value))
        const result = await createCompaniesFromXLSX(formData, token)
        // console.log('result:', result)
        alert(result.data.status)
    }
    if (checkTemplateValid.data.ok) {
        uploadedFile.value = null
        uploadedFileName.value = null
        newSourceName.value = null
        existingSourceID.value = null
    }

}

async function addSource() {
    const token = store.state.auth.token
    const sourceData = JSON.stringify({
        "source_name": newSourceName.value
    })
    // console.log('source data', sourceData)
    const result = await createSource(sourceData, token)
    // console.log(result)
    newSourceName.value = null
    return result

}

async function getTemplate() {
    const token = store.state.auth.token
    await downloadTemplate(token)
}

async function setSourceOptions() {
    const token = store.getters['auth/showToken']
    const result = await getSources(token)
    sourceOptions.value = [...result.map((source) => {
        return { value: source.id, label: source.source_name }
    })]
}

function setCreateActive() {
    existingSourceID.value = null
    isCreateActive.value = true
    isSetActive.value = false
}
function setSetActive() {
    newSourceName.value = null
    isSetActive.value = true
    isCreateActive.value = false
}

onMounted(() => {
    setSourceOptions()
    setUserPermissions()
})
</script>
  
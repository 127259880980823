<template>
    <div class="text-dark-primary flex flex-col">
        <p class="underline">
            Status Summary Options
        </p>
        <div class="flex flex-col items-start  ml-1 mb-3" >
            <div class="mt-1">
                <input type="checkbox" id="checkbox" class="align-middle" v-model="checkedValue" :checked="showStatusSummaryPane" @click="toggleShowStatusSummaryPane"/>
                <span class="ml-1 mr-2">Status Summary Pane</span>
            </div>
        </div>

    </div>
</template>

<script setup>
import { computed, watch, ref, defineProps } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
    module: String
});
const checked = ref(null)

const showStatusSummaryPane = computed(() => store.getters[`${props.module}/showStatusSummaryPane`])

const checkedValue = ref(showStatusSummaryPane.value)

watch(checkedValue, () => {
    if(showStatusSummaryPane.value){
        checkedValue.value = true
    } else {
        checkedValue.value = false
    }
}, {
    flush: 'post'
})

const toggleShowStatusSummaryPane = () => {
    if(showStatusSummaryPane.value) {
        store.dispatch(`${props.module}/setShowStatusSummaryPaneFalse`)
    } else {
        store.dispatch(`${props.module}/setShowStatusSummaryPaneTrue`)
    }
}
</script>
<template>
    <div class="flex flex-row fixed top-0 right-0 h-screen" :style="{ 'width': sideBarWidthClass}" >
        <div id="draggable-container"
                @mousedown="startDrag()"
                class="min-w-[10px] bg-dark-primary h-full cursor-col-resize">
        </div>
        <div class=" bg-dark-primary w-full">
            <div id="resizable-container" ref="sideBar" class="flex flex-row justify-start items-center h-14 mt-2 -ml-4 w-full">
                <RightSideBarCloseWidget/>
                <slot name="title" class="text-white"></slot>
            </div>
            <div class="flex flex-row h-full">
                <div class="sticky top-14 right-0 pl-3 pr-4 bg-white h-full w-full" >
                    <div class=" ">
                        <base-list-box
                                class="rounded-b-md border-gray-900 mt-2 h-8 text-sm"
                                :class="sideBarWidthClass"
                                placeholder="Select Panel"
                                :options="panelOptions"
                                :modelValue="panel"
                                :multiple="false"
                                @update:modelValue="setPanel"
                            />
                    </div>
                    <div v-if="panel==='full_text_search'" class="ml-3 mt-2 h-full flex flex-col space-y-1 justify-start">
                        <FullTextSearchPanel
                            :userId="userId"
                        />
                    </div>
                    <div v-else-if="panel==='companies'" class="ml-2 mt-2 h-full flex flex-col space-y-1 justify-start">
                        <div class="flex flex-row justify-center text-xs pt-4 h-full">
                            <CompanyPanel class="h-full" />
                        </div>
                    </div>
                    <div v-else-if="panel==='collections'" class="ml-3 mt-2 h-full flex flex-col space-y-1 justify-start">
                        <div class="flex flex-row justify-center text-xs pt-4 h-full">
                            <CollectionPanel 
                                class="h-full"
                                :userId="userId"
                            />      
                        </div>
                    </div>
                    <div v-else-if="panel==='zoom_info'" class="ml-3 mt-2 h-full flex flex-col space-y-1 justify-start">
                        <div class="flex flex-row justify-center text-xs pt-4">
                            <under-construction></under-construction> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import FullTextSearchPanel from '@/components/full-text-search/FullTextSearchPanel.vue';
import RightSideBarCloseWidget from './RightSideBarCloseWidget.vue';
import CompanyPanel from '@/components/company/CompanyPanel.vue';
import CollectionPanel from '@/components/collection/CollectionPanel.vue';

const panel = ref('full_text_search')

const props = defineProps(['userId'])

const emit = defineEmits(['change-sidebar-width'])

const  panelOptions =  ref([
    { value: 'collections', label: "Collections" },
    { value: 'companies', label: "Companies"},
    { value: 'full_text_search', label: "Full Text Search" },
    { value: 'zoom_info', label: "ZoomInfo" },
])

function setPanel(target) {
    panel.value = target;
}

const sideBar = ref(null)

const draggable = ref(false)

const sideBarWidth = ref(650)


watch(sideBarWidth, ()=>{
    emit('change-sidebar-width', sideBarWidth.value)
})

const sideBarWidthClass = computed(()=> {
    return `${parseInt(sideBarWidth.value)}px`
})

function startDrag() {
    draggable.value = true
    document.addEventListener("mousemove", handleDrag)
    document.addEventListener("mouseup", stopDrag)
}

function handleDrag(event) {
    if (draggable.value) {
        const draggableWidth = -(event.clientX - sideBar.value.getBoundingClientRect().left);
        const width = sideBar.value.offsetWidth;
        const newWidth =  width + ((draggableWidth/ width) * 100);
        sideBarWidth.value = newWidth.toFixed(0);
    }
}

function stopDrag() {
    draggable.value = false
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", stopDrag);
}

</script>
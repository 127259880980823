<template>
    <div>
      <form @submit.prevent="submitForm">
        <div class="">
            <div class="text-lg bg-dark-primary text-white p-3 rounded">
               <h2 class="">Page Features</h2>
            </div>
            <div class="grid p-2 grid-cols-5 gap-1 mb-2">
                <div class="col-span-2 mr-2">
                    <div class="flex justify-start items-center">
                        <label for="configurationName" class="whitespace-nowrap">Page Name: </label>
                        <input required type="text" class="p-1 border ml-1 rounded block w-full" v-model="configName" name="configurationName">
                    </div>
                </div>
                <div class="flex justify-start items-center">
                    <label for="pageSize">Page Size: </label>
                    <select name="pageSize" class="border rounded-md w-[100px]" v-model="pageSize" id="">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                </div>
                <div class="flex justify-start items-center col-span-2">
                    <label for="menuLocation">Menu: </label>
                    <select name="menuLocation" class="border rounded-md w-fit" v-model="menuLocation" id="">
                        <option value=0>Companies</option>
                        <option value=1>Campaign Activities</option>
                    </select>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showFilters" class="p-1 border mr-1" name="showFilters">
                    <label for="configurationName">Show Filter Pane</label>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showBasicFilters" class="p-1 border mr-1" name="showBasicFilters">
                    <label for="configurationName">Show Basic Filters</label>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showCampaignFilters" class="p-1 border mr-1" name="showCampaignFilters">
                    <label for="configurationName">Show Campaign Filters</label>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showResearchFilters" class="p-1 border mr-1" name="showResearchFilters">
                    <label for="configurationName">Show Research Filters</label>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showActiveFilters" class="p-1 border mr-1" name="showActiveFilters">
                    <label for="configurationName">Show Active Filters</label>
                </div>
                <div class="flex items-center justify-start mr-2">
                    <input type="checkbox" v-model="makePublic" class="p-1 border mr-1" name="makePublic">
                    <label for="configurationName">Make Public</label>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showBulkActions" class="p-1 border mr-1" name="showBulk">
                    <label for="configurationName">Show Bulk Actions Pane</label>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" v-model="showCrmStatusSummary" class="p-1 border mr-1" name="showCrmStatusSummary">
                    <label for="configurationName">Show CRM Summary</label>
                </div>
            </div>
            <div class="text-lg bg-dark-primary text-white p-3 rounded">
               <h2 class="">Default Data</h2>
            </div>
            <div class="p-3 mb-2">
                <div class="flex space-x-2">
                    <base-button @click.prevent="setAllFiltersVisibility(true)">All Visible</base-button>
                    <base-button @click.prevent="setAllFiltersVisibility(false)">All Hidden</base-button>
                </div>
                <div class="border-b border-b-light-primary">
                    <h3 class="text-md">Company Info:</h3>
                </div>
                <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 text-xs"> 
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Company
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.company_name.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.company_name.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Company ID
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.id_number.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="number" v-model="defaultFilters.id_number.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Description
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.description.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.description.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Website URL
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.URL.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.URL.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Address Search
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.address_search.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.address_search.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                State
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.state.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.state.value"
                            :options="stateOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultStateFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setBusinessTypeOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Business Type
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.business_type.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.business_type.value"
                            :options="businessTypeOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultBusinessTypeFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setSectorOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Sector
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.sector.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.sector.value"
                            :options="sectorOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultSectorFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setSubSectorOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Sub Sector
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.sub_sector.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.sub_sector.value"
                            :options="subSectorOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultSubSectorFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Category
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.category_search.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultCategory" type="text" v-model="defaultFilters.category_search.value">
                        </template>
                    </base-filter>
                    <base-filter @click="setCollectionTagOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Collection Tag
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.collection_tag.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.collection_tag.value"
                            :options="collectionTagOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCollectionTagFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setCollectionTagSourceOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Collection Tag Source
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.collection_tag_source.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.collection_tag_source.value"
                            :options="collectionTagSourceOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCollectionTagSourceFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Collection Review
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.collection_review.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.collection_review.value"
                            :options="collectionReviewOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCollectionReviewFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setCollectionTagOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Collection Tag Exclude
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.collection_tag_exclude.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            :model-value="defaultFilters.collection_tag_exclude.value"
                            :options="collectionTagOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCollectionTagExcludeFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setNAICSOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                NAICS Code
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.naics_code.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.naics_code.value"
                            :options="naicsOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultNaicsCodeFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Product Service Code
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.product_service_code.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" type="text" v-model="defaultFilters.product_service_code.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Revenue
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.min_revenue.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <div class="flex h-full">
                                <input class="h-full px-2 w-full border rounded-b-md" placeholder="min" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.min_revenue.value">
                                <input class="h-full px-2 w-full border rounded-b-md" placeholder="max" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.max_revenue.value">
                            </div>
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Revenue - ZI Combined
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.min_revenue_combined.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <div class="flex h-full">
                                <input class="h-full px-2 w-full border rounded-b-md" placeholder="min" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.min_revenue_combined.value">
                                <input class="h-full px-2 w-full border rounded-b-md" placeholder="max" id="defaultAccountManagerResearchNotes" type="text" v-model="defaultFilters.max_revenue_combined.value">
                            </div>
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Parent Company Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.parent_company_status.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.parent_company_status.value"
                            :options="parentCompanyStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultParentCompanyStatusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Investors Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.investors_status.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.investors_status.value"
                            :options="investorsStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultInvestorsStatusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Contract PSC Keyword
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.contract_psc_keyword.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultContractPSCKeyword" type="text" @input="setDefaultContractPSCKeywordFilter">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Contract NAICS Keyword
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.contract_naics_keyword.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultContractNAICSKeyword" type="text" @input="setDefaultContractNAICSKeywordFilter">
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Contract Awd. Sub Agcy.
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.contract_awarding_sub_agency.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.contract_awarding_sub_agency.value"
                            :options="awardingSubAgencyOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultContractAwardingSubAgencyFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Parent Type
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.parent_type.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.parent_type.value"
                            :options="parentTypeOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultParentTypeFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Capabilities
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.capabilities.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.capabilities.value"
                            :options="capabilitiesOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCapabilitiesFilter"
                        />
                        </template>
                    </base-filter>
                </div>
                <div class="border-b border-b-light-primary">
                    <h3 class="text-md">Campaign Info:</h3>
                </div>
                <!-- <div class="flex flex-wrap text-xs"> -->
                <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 text-xs">
                    <base-filter @click="setSourceOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Sources
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.source_object.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.source_object.value"
                            :options="sourceOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultSourceFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setCampaignOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Campaign
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.campaign.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.campaign.value"
                            :options="campaignOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCampaignFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setCampaignOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Campaign Exclude
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.campaign_exclude.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.campaign_exclude.value"
                            :options="campaignOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCampaignExcludeFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setTierOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Tier
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.tier_selection.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.tier_selection.value"
                            :options="tierOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultTierFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Acct. Mgr. Notes
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.account_manager_research_notes.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="text" @input="setDefaultAccountManagerResearchNotesFilter">
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Client Feedback
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.client_feedback.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.client_feedback.value"
                            :options="clientFeedbackOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultClientFeedbackFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setClientOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Client
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.client_drf.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.client_drf.value"
                            :options="clientOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultClientFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                CRM Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.crm_status.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.crm_status.value"
                            :options="crmStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCrmStatusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                CRM Next Action
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.next_action.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.next_action.value"
                            :options="crmNextActionOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCrmNextActionFilter"
                        />
                        </template>
                    </base-filter>
                    
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Due Time Range
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.due_date.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.due_date.value"
                            :options="dueTimeRangeOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultDueTimeRangeFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setDrivingTimeFocusOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Driving Time Focus
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.driving_distance_focus.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.driving_distance_focus.value"
                            :options="drivingTimeFocusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultDrivingTimeFocusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Driving Time
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.driving_time.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultAccountManagerResearchNotes" type="text" @input="setDefaultDrivingTimeFilter">
                        </template>
                    </base-filter>
                    <base-filter @click="setTaskUserOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                CRM Task User
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.task_user.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.task_user.value"
                            :options="taskUserOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultTaskUserFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Show User Tasks
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.show_my_tasks.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.show_my_tasks.value"
                            :options="showMyTasksOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultShowMyTasksFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Relevant Business
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.relevant_business.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                            <input class="h-full px-2 w-full rounded-b-md" id="defaultRelevantBusiness" type="text" v-model="defaultFilters.relevant_business.value">
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                EverAxis Pillar
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.everaxis_pillar.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.everaxis_pillar.value"
                            :options="everaxisPillarOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultEverAxisPillarFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                IGP Category
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.igp_category.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.igp_category.value"
                            :options="igpCategoryOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultIgpCategoryFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setCRMActionOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                CRM Event
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.crm_actions.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.crm_actions.value"
                            :options="crmActionOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultCRMActionFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Has Conflicts
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.has_conflicts.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.has_conflicts.value"
                            :options="hasConflictsOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultHasConflictsFilter"
                        />
                        </template>
                    </base-filter>
                </div>
                <div class="border-b border-b-light-primary">
                    <h3 class="text-md">Research Info:</h3>
                </div>
                <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 text-xs">
                    <base-filter @click="setSourceOwnerOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Source Owner
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.source_owners.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.source_owners.value"
                            :options="sourceOwnerOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultSourceOwnerFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Onboarding Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.onboarding_status.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.onboarding_status.value"
                            :options="onboardingStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultOnboardingStatusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Problem Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.problem_status.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.problem_status.value"
                            :options="problemStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultProblemStatusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter @click="setTaskUserOptions">
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Do Not Research Reason
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.do_not_research_reason.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.do_not_research_reason.value"
                            :options="dnrReasonOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultDnrReasonFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Research Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.research_statuses.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.research_statuses.value"
                            :options="researchStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultResearchStatusFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter >
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Research Status 2
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.research_statuses_2.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.research_statuses_2.value"
                            :options="researchStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultResearchStatus2Filter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Show Research Halted
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.show_research_halted.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.show_research_halted.value"
                            :options="showResearchHaltedOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultShowResearchHaltedFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Referral Source
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.referrer.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.referrer.value"
                            :options="referralSourceOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultReferralSourceFilter"
                        />
                        </template>
                    </base-filter>
                    <base-filter>
                        <template v-slot:label>
                            <div class="flex justify-between rounded-t-md bg-light-primary p-1">
                            <div>
                                Referral Status
                            </div>
                            <div class="flex items-center space-x-1">
                                <label for="">Visible: </label>
                                <input v-model="defaultFilters.referrer_status.is_visible" type="checkbox">
                            </div>
                        </div>
                        </template>
                        <template v-slot:widget>
                        <base-list-box
                            class="w-full rounded-b-md"
                            placeholder=""
                            :model-value="defaultFilters.referrer_status.value"
                            :options="referralStatusOptions"
                            :multiple="false"
                            @update:modelValue="setDefaultReferralStatusFilter"
                        />
                        </template>
                    </base-filter>
                </div>
            <div class="text-lg bg-dark-primary text-white p-3 rounded">
               <h2 class="">Cells</h2>
            </div>
            <div class="flex flex-col justify-evenly items-center p-2">
                <div v-for="(cellBox, index) in createdCells" :key="cellBox.key" class="flex p-2">
                    <div class="flex flex-col">
                        <label for="">Cell:</label>
                        <select name="" id="" class="border rounded-md w-min p-1" :value="cellBox.id" @change.prevent="setCellId($event, index)" >
                            <option v-for="cell in cellOptions" :key="cell.value" :value="cell.value">{{ cell.label }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col ml-1">
                        <label for="">Ordinal: </label>
                        <input @input.prevent="setCellOrdinal($event, index)" :value="cellBox.ordinal" required type="number" min="0" class="p-1 w-min rounded-md border">
                    </div>
                    <div class="ml-2 flex flex-col ">
                        <label for="">Remove</label>
                        <div class="h-full w-full p-1">
                            <base-button-negative class="h-full w-full" @click="removeCell(index)">X</base-button-negative>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-2 flex justify-center">
                <base-button class="mr-2" @click.prevent="addCellBox">Add Cell</base-button>
                <base-button @click.prevent="sortCellsByOrdinal">Sort Cells</base-button>
            </div>
  
        </div>
        </div>
        <hr class="pb-2">
        <div class="flex flex-row justify-evenly">
          <base-button-affirmative v-if="pageConfig && user.id == pageConfig.user.id"
          class="mx-1 mt-2"
              type="submit" >Update
          </base-button-affirmative>
          <base-button-negative v-if="pageConfig && user.id == pageConfig.user.id" class="mx=1 mt-2 0-2" @click.prevent="deletePageConfig"> 
            Delete
          </base-button-negative>
        </div>
      </form>
    </div>
  </template>

<script>
    export default {
        name: 'edit-page-config-form'
    }
</script>

<script setup>
import { useStore } from 'vuex';
import { defineProps, ref, reactive, computed, watch, defineEmits } from 'vue';
import { getCompanyCells } from '@/services/CompanyCell';
import { getCampaigns } from '@/services/Campaign';
import { getSources } from '@/services/Source';
import { getUsersByGroup } from '@/services/User';
import { getSourceOwners } from '@/services/SourceOwner';
import { getTiers } from '@/services/Tiers';
import { getClients } from '@/services/Client';
import {getBusinessTypes} from '@/services/BusinessType'
import {getSectors} from '@/services/Sector'
import {getSubSectors} from '@/services/SubSector'
import {getGeoLocations} from '@/services/GeoLocation';
import { getCollectionTags } from '@/services/CollectionTag';
import { getAsynchronousJobQueue } from '@/services/AsynchronousJobs';
import { getNAICSCodes } from '@/services/NAICSCode';
import { getReferralSources } from '@/services/Referrer';
import { formatDateTime } from '@/composables/formatDateTime';
import { getActions } from '@/services/CrmAction';
import { getCrmStatuses } from '@/services/CrmStatus';
import {getCapabilities} from '@/services/Capability'

const store = useStore()

const user = computed(() => {
    return store.state.auth.user
})

const props = defineProps({
    pageConfig: Object
})

const campaignOptions = ref([
    {value: null, label: "------------"}
])
const capabilitiesOptions = ref([
    {value: null, label: "------------"}
])
const taskUserOptions = ref([
    {value: null, label: "------------"}
])
const sourceOptions = ref([
    {value: null, label: "------------"}
])
const sourceOwnerOptions = ref([
      {value: null, label: "------------"}
    ])
const tierOptions = ref([
      {value: null, label: "------------"},
      {value: "unmarked", label: "None"}
    ])
const clientOptions = ref([
      {value: null, label: "------------"}
    ])
const businessTypeOptions = ref([
      {value: null, label: "------------"}
    ])
const sectorOptions = ref([
      {value: null, label: "------------"}
    ])
const subSectorOptions = ref([
      {value: null, label: "------------"}
    ])
const collectionTagOptions = ref([
      {value: null, label: "------------"}
    ])
const collectionTagSourceOptions = ref([
      {value: null, label: "------------"}
    ])
const naicsOptions = ref([
      {value: null, label: "------------"}
    ])
const showMyTasksOptions = ref([
      {value: null, label: "------------"},
      {value: true, label: "True"},
      {value: false, label: "False"}
    ])
const hasConflictsOptions = ref([
      {value: null, label: "------------"},
      {value: true, label: "True"},
      {value: false, label: "False"}
    ])
const collectionReviewOptions = ref([
    {value: null, label: "------------"},
    {value: "None", label: "None"},
    {value: "Interesting", label: "Interesting"},
    {value: "Not Interesting", label: "Not Interesting"}
])
const clientFeedbackOptions = ref([
      {value: null, label: "------------"},
      {value: "None", label: "None"},
      {value: "approved", label: "Approved"},
      {value: "excluded", label: "Excluded"},
      {value: "hold", label: "Hold"},
      {value: "pass", label: "Pass"},
      {value: "research_further", label: "Research Further"},
    ])
const stateOptions = ref([
{value: "clear", label: "------------"},
      {value: 'alabama', label: 'Alabama'},
      {value: 'alaska', label: 'Alaska'},
      {value: 'arizona', label: 'Arizona'},
      {value: 'arkansas', label: 'Arkansas'},
      {value: 'california', label: 'California'},
      {value: 'colorado', label: 'Colorado'},
      {value: 'connecticut', label: 'Connecticut'},
      {value: 'delaware', label: 'Delaware'},
      {value: 'florida', label: 'Florida'},
      {value: 'georgia', label: 'Georgia'},
      {value: 'hawaii', label: 'Hawaii'},
      {value: 'idaho', label: 'Idaho'},
      {value: 'illinois', label: 'Illinois'},
      {value: 'indiana', label: 'Indiana'},
      {value: 'iowa', label: 'Iowa'},
      {value: 'kansas', label: 'Kansas'},
      {value: 'kentucky', label: 'Kentucky'},
      {value: 'louisiana', label: 'Louisiana'},
      {value: 'maine', label: 'Maine'},
      {value: 'maryland', label: 'Maryland'},
      {value: 'massachusetts', label: 'Massachusetts'},
      {value: 'michigan', label: 'Michigan'},
      {value: 'minnesota', label: 'Minnesota'},
      {value: 'mississippi', label: 'Mississippi'},
      {value: 'missouri', label: 'Missouri'},
      {value: 'montana', label: 'Montana'},
      {value: 'nebraska', label: 'Nebraska'},
      {value: 'nevada', label: 'Nevada'},
      {value: 'new_hampshire', label: 'New Hampshire'},
      {value: 'new_jersey', label: 'New Jersey'},
      {value: 'new_mexico', label: 'New Mexico'},
      {value: 'new_york', label: 'New York'},
      {value: 'north_carolina', label: 'North Carolina'},
      {value: 'north_dakota', label: 'North Dakota'},
      {value: 'ohio', label: 'Ohio'},
      {value: 'oklahoma', label: 'Oklahoma'},
      {value: 'oregon', label: 'Oregon'},
      {value: 'pennsylvania', label: 'Pennsylvania'},
      {value: 'rhode_island', label: 'Rhode Island'},
      {value: 'south_carolina', label: 'South Carolina'},
      {value: 'south_dakota', label: 'South Dakota'},
      {value: 'tennessee', label: 'Tennessee'},
      {value: 'texas', label: 'Texas'},
      {value: 'utah', label: 'Utah'},
      {value: 'vermont', label: 'Vermont'},
      {value: 'virginia', label: 'Virginia'},
      {value: 'washington', label: 'Washington'},
      {value: 'west_virginia', label: 'West Virginia'},
      {value: 'wisconsin', label: 'Wisconsin'},
      {value: 'wyoming', label: 'Wyoming'},
    ])
const drivingTimeFocusOptions = ref([
      {value: null, label: "------------"}
    ])
const onboardingStatusOptions = ref([
      {value: null, label: "------------"},
      {value: "imported", label: "Imported"},
      {value: "url_enriched", label: "URL Enriched"},
      {value: "verified_unique", label: "Verified Unique"},
      {value: "research_complete", label: "Research Complete"}
    ])
const dnrReasonOptions = ref([
      {value: null, label: "------------"},
      {value: "Duplicate", label: "Duplicate"},
      {value: "Duplicate Assimilated", label: "Duplicate Assimilated"},
      {value: "Already Known", label: "Already Known"},
      {value: "No Website", label: "No Website"},
      {value: "Address Not Valid", label: "Address Not Valid"},
      {value: "Permanently Closed", label: "Permanently Closed"},
      {value: "Acquired", label: "Acquired"},
      {value: "Public/Large PE Owned", label: "Public/Large PE Owned"},
    ])
const researchStatusOptions = ref([
      {value: null, label: "------------"},
      {value: "research_not_complete", label: "Research Not Complete"},
      {value: "research_complete", label: "Research Complete"},
      {value: "research_halted", label: "Research Halted"},
      {value: "url_found", label: "URLs Found"},
      {value: "urls_missing", label: "URLs Missing"},
      {value: "urls_vetted", label: "URLs Vetted"},
      {value: "marked_no_url", label: "Marked No URL"},
      {value: "urls_found_not_vetted", label: "URLs Found Not Vetted"},
      {value: "urls_not_vetted", label: "URLs Not Vetted"},
      {value: "contact_email_missing", label: "Contact Email Missing"},
      {value: "has_primary_contact", label: "Has Primary Contact"},
      {value: "no_primary_contact", label: "No Primary Contact"},
      {value: "no_contact", label: "No Contacts"}, 
      {value: "multiple_zoom_info_ids", label: "Multiple Zoom Info Ids"},
      {value: "no_zoom_info_id", label: "No Zoom Info ID"},
      {value: "one_zoom_info_id", label: "One Zoom Info ID"},
      {value: "revenue_found", label: "Revenue Found"},
      {value: "revenue_missing", label: "Revenue Missing"},
      {value: "do_not_research", label: "Do Not Research"},
      {value: "do_research", label: "Do Research"},
      {value: "further_research_needed", label: "Research Further"},
    ])
    const parentTypeOptions = ref([
      {value: "clear", label: "------------"},
      {value: "None", label: "No Parent Type"},
      {value: "Government Owned", label: "Government Owned"},
      {value: "Private Equity Owned", label: "Private Equity Owned"},
      {value: "Proprietor Owned", label: "Proprietor Owned"},
      {value: "Public", label: "Public"},
    ])
const crmStatusOptions = ref([
        {value: null, label: "------------"},
        {value: "None", label: 'None'},
        {value: 'Revisit / Cut', label: 'Revisit / Cut'},
        {value: 'Client Pass', label: 'Client Pass'},
        {value: 'Not Contacted', label: 'Not Contacted'},
        {value: 'No Response', label: 'No Response'},
        {value: 'Hard No', label: 'Hard No'},
        {value: 'Responded / Coverage', label: 'Responded / Coverage'},
        {value: 'Active - In Dialogue', label: 'Active - In Dialogue'},
        {value: 'IOI Submitted', label: 'IOI Submitted'},
        {value: 'Under LOI', label: 'Under LOI'},
        {value: 'Research Further', label: 'Research Further'},
        {value: 'Hold', label: 'Hold'},
        {value: 'Closed', label: 'Closed'},
        {value: 'Dead - Acquired by Other Party', label: 'Dead - Acquired by Other Party'}
    ])

const crmNextActionOptions = ref([
        {value: null, label: "------------"},
        {value: 'None', label: 'None'},
        {value: 'Determine Next Action', label: 'Determine Next Action'},
        {value: 'Scott to Follow-Up', label: 'Scott to Follow-Up'},
        {value: 'Send Initial Email', label:'Send Initial Email' },
        {value: 'Send Another Email', label: 'Send Another Email'},
        {value: 'Send Email on Acquisition', label: 'Send Email on Acquisition'},
        {value: 'Research Correct Email', label: 'Research Correct Email'},
        {value: 'Call', label: 'Call'},
        {value: 'Schedule Meeting', label: 'Schedule Meeting'},
        {value: 'Wait on Response', label: 'Wait on Response'},
        {value: 'Wait for Client Feedback', label: 'Wait for Client Feedback'},
        {value: 'Meet', label: 'Meet'},
        {value: 'Not Now', label: 'Not Now'},
        {value: 'Leave Alone', label: 'Leave Alone'},
        {value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out'},
        {value: "Send 2nd Email", label: "Send 2nd Email"},
        {value: "Send 3rd Email", label: "Send 3rd Email"},
        {value: "Send 4th Email", label: "Send 4th Email"},
        {value: "Cold Call 1", label: "Cold Call 1"},
        {value: "Cold Call 2", label: "Cold Call 2"},
        {value: "Call again", label: "Call again"},
        {value: "Periodic Check In", label: "Periodic Check In"},
    ])

const dueTimeRangeOptions = ref([
      {value: null, label: "------------"},
      {value: 'In 2 Weeks', label: 'In 2 Weeks'},
      {value: 'In 1 Month', label: 'In 1 Month'},
      {value: 'Overdue', label: 'Overdue'}
    ]
    )

const showResearchHaltedOptions = ref([
      {value: null, label: "------------"},
      {value: "True", label: "Show Research Halted"},
      {value: "False", label: "Don't Show Research Halted"}
    ])

const parentCompanyStatusOptions = ref([
      {value: null, label: "------------"},
      {value: 'has_parent_company', label: 'Has Parent Company'},
      {value: 'no_parent_company', label: 'No Parent Company'},
    ])

const investorsStatusOptions = ref([
      {value: null, label: "------------"},
      {value: 'has_investors', label: 'Has Investors'},
      {value: 'no_investors', label: 'No Investors'},
    ])

const problemStatusOptions = ref([
      {value: null, label: "------------"},
      {value: 'has_problem_company_notes', label: 'Has Problem Company Notes'},
      {value: 'no_problem_company_notes', label: 'No Problem Company Notes'},
    ])

const referralSourceOptions = ref([
    {value: "clear", label: "------------"} 
])

const referralStatusOptions = ref([
    {value: "clear", label: "------------"},
    {value: 'has_referrer', label: 'Has Referrer'},
    {value: 'no_referrer', label: 'No Referrer'}
])
const everaxisPillarOptions = ref([
      {value: "clear", label: "------------"},
      {value: 'P1: Peers', label: 'P1: Peers'},
      {value: 'P2: EverAxis in Motion', label: 'P2: EverAxis in Motion'},
      {value: 'P3: Forever Connecting', label: 'P3: Forever Connecting'},  
    ])
const awardingSubAgencyOptions = ref([
      {value: "clear", label: "------------"},
      {value: "Defense Information Systems Agency", label: "Defense Information Systems Agency"},
      {value: "Department of the Air Force", label: "Department of the Air Force"},
      {value: "Department of the Army", label: "Department of the Army"},
      {value: "Department of the Navy", label: "Department of the Navy"},
    ])
const crmActionOptions = ref([
      {value: null, label: "------------"}
    ])

const igpCategoryOptions = ref([
        {value: "clear", label: "------------"},
        {value: 'Microwave/RF', label: 'Microwave/RF'},
        {value: 'Elastomer', label: 'Elastomer'},
        {value: 'Photonics/Optics', label: 'Photonics/Optics'},
        {value: 'M&I', label: 'M&I'},
        {value: 'HMI', label: 'HMI'},
        {value: 'Valves, Actuators, Pumps', label: 'Valves, Actuators, Pumps'}
    ])

const companyCells = ref([])
const configName = ref(null)
const pageSize = ref(null)
const showFilters = ref(null)
const showBulkActions = ref(null)
const showBasicFilters = ref(null)
const showActiveFilters = ref(null)
const showCampaignFilters = ref(null)
const showResearchFilters = ref(null)
const showCrmStatusSummary = ref(null)
const defaultCampaign = ref(null)
const defaultFilters = ref({
    source_object: {value: null, is_visible: true, filter: 8},
    campaign: {value: null, is_visible: true, filter: 1},
    collection_tag: {value: null, is_visible: false, filter: 3},
    collection_tag_source: {value: null, is_visible: false, filter: 45}, 
    task_user: {value: null, is_visible: false, filter: 2},
    company_name: {value: null, is_visible: false, filter: 4},
    description: {value: null, is_visible: false, filter: 5},
    URL: {value: null, is_visible: false, filter: 6},
    address_search: {value: null, is_visible: false, filter: 7},
    source_owners: {value: null, is_visible: false, filter: 9},
    tier_selection: {value: null, is_visible: false, filter: 10},
    client_feedback: {value: null, is_visible: false, filter: 11},
    onboarding_status: {value: null, is_visible: false, filter: 12},
    do_not_research_reason: {value: null, is_visible: false, filter: 13},
    research_statuses: {value: null, is_visible: false, filter: 14},
    research_statuses_2: {value: null, is_visible: false, filter: 15},
    min_revenue: {value: null, is_visible: true, filter: 16},
    max_revenue: {value: null, is_visible: true, filter: 17},
    min_headcount: {value: null, is_visible: false, filter: 18},
    max_headcount: {value: null, is_visible: false, filter: 19},
    min_revenue_combined: {value: null, is_visible: false, filter: 20},
    max_revenue_combined: {value: null, is_visible: false, filter: 21},
    client_drf: {value: null, is_visible: false, filter: 22},
    business_type: {value: null, is_visible: false, filter: 23},
    sector: {value: null, is_visible: false, filter: 24},
    sub_sector: {value: null, is_visible: false, filter: 25},
    crm_status: {value: null, is_visible: false, filter: 26},
    next_action: {value: null, is_visible: false, filter: 27},
    due_date: {value: null, is_visible: false, filter: 28},
    state: {value: null, is_visible: false, filter: 29},
    driving_distance_focus: {value: null, is_visible: false, filter: 30},
    driving_time: {value: null, is_visible: false, filter: 31},
    naics_code: {value: null, is_visible: false, filter: 32},
    show_research_halted: {value: null, is_visible: false, filter: 34},
    parent_company_status: {value: null, is_visible: false, filter: 35},
    investors_status: {value: null, is_visible: false, filter: 36},
    problem_status: {value: null, is_visible: false, filter: 37},
    account_manager_research_notes: {value: null, is_visible: false, filter: 38},
    id_number: {value: null, is_visible: false, filter: 39},
    show_my_tasks: {value: null, is_visible: false, filter: 40},
    category_search: {value: null, is_visible: false, filter: 41},
    relevant_business: {value: null, is_visible: false, filter: 42},
    referrer: {value: null, is_visible: false, filter: 43},
    referrer_status: {value: null, is_visible: false, filter: 44},
    product_service_code: {value: null, is_visible: false, filter: 46},
    everaxis_pillar: {value: null, is_visible: false, filter: 47},
    contract_psc_keyword: {value: null, is_visible: false, filter: 48},
    contract_awarding_sub_agency: {value: null, is_visible: false, filter: 49},
    contract_naics_keyword: {value: null, is_visible: false, filter: 50},
    crm_actions: {value: null, is_visible: false, filter: 51},
    collection_tag_exclude: {value: null, is_visible: false, filter: 52},
    has_crm_actions: {value: null, is_visible: false, filter: 53},
    campaign_exclude: {value: null, is_visible: false, filter: 54},
    capabilities: {value: null, is_visible: false, filter: 55},
    has_conflicts: {value: null, is_visible: false, filter: 56},
    parent_type: {value: null, is_visible: false, filter: 57},
    collection_review:  {value: null, is_visible: false, filter: 58},
    igp_category:  {value: null, is_visible: false, filter: 59},
})
const makePublic = ref(null)
const menuLocation = ref(null)
const previewCells = ref(null)

const createdCells = ref([])
const createdCellIds = ref([])

watch(() => props.pageConfig, () => {
    if(props.pageConfig){
        configName.value = props.pageConfig.configuration_name
        pageSize.value = props.pageConfig.page_size
        showFilters.value = props.pageConfig.filter_visibility
        showBulkActions.value = props.pageConfig.bulk_visibility
        showBasicFilters.value = props.pageConfig.basic_filters_visibility
        showActiveFilters.value = props.pageConfig.active_filters_visibility
        showCampaignFilters.value = props.pageConfig.campaign_filters_visibility
        showResearchFilters.value = props.pageConfig.research_filters_visibility
        showCrmStatusSummary.value = props.pageConfig.status_summary_visibility
        makePublic.value = props.pageConfig.is_public
        menuLocation.value = props.pageConfig.menu_location
        createdCells.value = props.pageConfig.cells.map((cell) => {
            return {id: cell.company_cell.id, ordinal: parseInt(cell.ordinal), key: Math.random()}
        }).sort((a, b) => a.ordinal - b.ordinal)
        if(props.pageConfig.default_filters.length > 0){
            // console.log('default filters')
            props.pageConfig.default_filters.forEach((filter) => {
                if(Number.isInteger(parseInt(filter.value))){
                    defaultFilters.value[filter.filter_field.field_name].value = parseInt(filter.value)
                } else {
                    defaultFilters.value[filter.filter_field.field_name].value = filter.value
                }
                defaultFilters.value[filter.filter_field.field_name].is_visible = filter.is_visible
            })
            console.log('default filters', defaultFilters.value)
        }
    }     
})

function addCellBox() {
    createdCells.value.push({id: null, ordinal: null, key: Math.random()})
}

const cellOptions = ref([
    {value: null, label: '----'}
])


async function setCampaignOptions(){
    const token = store.getters['auth/showToken']
      const result = await getCampaigns(token)
      campaignOptions.value = [{value: null, label: "--------"}, ...result.map((campaign) => {
        return {value: campaign.id, label: campaign.campaign_name}
      })]
}
async function setCapabilitiesOptions(){
    const token = store.getters['auth/showToken']
      const result = await getCapabilities(token)
      capabilitiesOptions.value = [{value: null, label: "--------"}, ...result.map((capability) => {
        return {value: capability.id, label: capability.capability_name}
      })]
}
async function setTaskUserOptions(){
    let payload = {
        token: store.state.auth.token,
        endpoint: 'list_users',
        group: 'account_manager_group'
      }
      const result = await getUsersByGroup(payload)
      taskUserOptions.value = [{value: null, label: "--------"}, {value: 3, label: "Scott Shedd"}, ...result.users.map((user) => {
        return {value: user.id, label: user.first_name + " " + user.last_name}
      })]
}
async function setSourceOptions() {
      const token = store.getters['auth/showToken']
      const result = await getSources(token)
      sourceOptions.value = [{value: null, label: '-------'}, {value: "null", label: "Unassigned"}, ...result.map((source) => {
        return {value: source.id, label: source.source_name }
      })]
    }
async function setSourceOwnerOptions() {
    const token = store.getters['auth/showToken']
    const result = await getSourceOwners(token)
    sourceOwnerOptions.value = [{value: "clear", label: "------------"}, {value: "None", label: "None"}, ...result.map((sourceOwner) => {
    return {value: sourceOwner, label: sourceOwner}
    })]
}

async function setTierOptions() {
    const token = store.getters['auth/showToken']
    const result = await getTiers(token)
    tierOptions.value = [{value: "clear", label: "------------"}, {value: "unmarked", label: "None"}, ...result.map((tier) => {
    return {value: tier.id, label: tier.tier_name}
    })]
}

async function setClientOptions() {
    const token = store.getters['auth/showToken']
    const result = await getClients({token: token})
    clientOptions.value = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
    return {value: client.id, label: client.client_name}
    })]
}

async function setBusinessTypeOptions() {
    const token = store.getters['auth/showToken']
    const result = await getBusinessTypes(token)
    businessTypeOptions.value = [{value: "clear", label: "------------"}, ...result.map((businessType) => {
    return {value: businessType.id, label: businessType.business_type_name}
    })]
}

async function setSectorOptions() {
    const token = store.getters['auth/showToken']
    const result = await getSectors(token)
    sectorOptions.value = [{value: "clear", label: "------------"}, ...result.map((sector) => {
    return {value: sector.id, label: sector.sector_name}
    })]
}

async function setSubSectorOptions() {
    const token = store.getters['auth/showToken']
    const result = await getSubSectors(token)
    subSectorOptions.value = [{value: "clear", label: "------------"}, ...result.map((subSector) => {
    return {value: subSector.id, label: subSector.sub_sector_name}
    })]
}

async function setCollectionTagOptions() {
    const token = store.getters['auth/showToken']
    const result = await getCollectionTags(token)
    collectionTagOptions.value = [{value: "clear", label: "------------"}, ...result.map((collectionTag) => {
    return {value: collectionTag.id, label: collectionTag.collection_tag_name }
    })]
}

async function setCollectionTagSourceOptions() {
    let payload = {
    token: store.getters['auth/showToken'],
    endpoint: '?is_collection_tag_source=True'
    }
    const result = await getAsynchronousJobQueue(payload)
    collectionTagSourceOptions.value = [{value: "clear", label: "------------"}, ...result.jobs.map((job) => {
    return {value: job.id, label: `${job.job_title} - ${formatDateTime(job.created_at)}`}
    })]
}


async function setNAICSOptions() {
    const token = store.getters['auth/showToken']
    const result = await getNAICSCodes(token)
    naicsOptions.value = [{value: "clear", label: "------------"}, ...result.map((naics) => {
    return {value: naics.id, label: `${naics.code} - ${naics.title}` }
    })]
}

async function setDrivingTimeFocusOptions() {
    const token = store.getters['auth/showToken']
    const result = await getGeoLocations(token)
    drivingTimeFocusOptions.value = [{value: "clear", label: "------------"},...result.map((drivingTimeFocus) => {
    return {value: drivingTimeFocus.id, label: drivingTimeFocus.geo_location_name }
    })]
}
async function setCRMStatusOptions() {
      const token = store.getters['auth/showToken']
      const result = await getCrmStatuses(token)
      crmStatusOptions.value = [{value: null, label: "------------"}, ...result.map((status) => {
        return {value: status.id, label: status.status_name }
      })]
    }

async function setCRMActionOptions() {
    const token = store.getters['auth/showToken']
    const result = await getActions(token)
    crmActionOptions.value = [{value: null, label: "------------"}, ...result.data.map((action) => {
    return {value: action.id, label: action.action_name }
    })]
}

function setDefaultCampaignFilter(event) {
    defaultFilters.value.campaign.value = event
    console.log(defaultFilters.value)
}
function setDefaultCapabilitiesFilter(event) {
    defaultFilters.value.capabilities.value = event
}
function setDefaultCampaignExcludeFilter(event) {
    defaultFilters.value.campaign_exclude.value = event
    console.log(defaultFilters.value)
}
function setDefaultSourceFilter(event) {
    defaultFilters.value.source_object.value = event
    console.log(defaultFilters.value)
}
function setDefaultTierFilter(event) {
    defaultFilters.value.tier_selection.value = event
    console.log(defaultFilters.value)
}
function setDefaultAccountManagerResearchNotesFilter(event) {
    defaultFilters.value.account_manager_research_notes.value = event.target.value
    console.log(defaultFilters.value)
}
function setDefaultClientFeedbackFilter(event) {
    defaultFilters.value.client_feedback.value = event
    console.log(defaultFilters.value)
}
function setDefaultClientFilter(event) {
    defaultFilters.value.client_drf.value = event
    console.log(defaultFilters.value)
}
function setDefaultCrmStatusFilter(event) {
    defaultFilters.value.crm_status.value = event
    console.log(defaultFilters.value)
}
function setDefaultCrmNextActionFilter(event) {
    defaultFilters.value.next_action.value = event
    console.log(defaultFilters.value)
}
function setDefaultDueTimeRangeFilter(event) {
    defaultFilters.value.due_date.value = event
    console.log(defaultFilters.value)
}
function setDefaultDrivingTimeFocusFilter(event) {
    defaultFilters.value.driving_distance_focus.value = event
    console.log(defaultFilters.value)
}
function setDefaultDrivingTimeFilter(event) {
    defaultFilters.value.driving_time.value = event.target.value
    console.log(defaultFilters.value)
}
function setDefaultShowMyTasksFilter(event) {
    defaultFilters.value.show_my_tasks.value = event
    console.log(defaultFilters.value)
}
function setDefaultTaskUserFilter(event) {
    defaultFilters.value.task_user.value = event
    console.log(defaultFilters.value)
}
function setDefaultStateFilter(event) {
    defaultFilters.value.state.value = event
    console.log(defaultFilters.value)
}
function setDefaultBusinessTypeFilter(event) {
    defaultFilters.value.business_type.value = event
    console.log(defaultFilters.value)
}
function setDefaultSectorFilter(event) {
    defaultFilters.value.sector.value = event
    console.log(defaultFilters.value)
}
function setDefaultSubSectorFilter(event) {
    defaultFilters.value.sub_sector.value = event
    console.log(defaultFilters.value)
}
function setDefaultCollectionTagFilter(event) {
    defaultFilters.value.collection_tag.value = event
    console.log(defaultFilters.value)
}
function setDefaultCollectionTagExcludeFilter(event) {
    defaultFilters.value.collection_tag_exclude.value = event
    console.log(defaultFilters.value)
}
function setDefaultHasConflictsFilter(event) {
    defaultFilters.value.has_conflicts.value = event
    console.log(defaultFilters.value)
}
function setDefaultCollectionReviewFilter(event) {
    defaultFilters.value.collection_review.value = event
    console.log(defaultFilters.value)
}
function setDefaultCollectionTagSourceFilter(event) {
    defaultFilters.value.collection_tag_source.value = event
    console.log(defaultFilters.value)
}
function setDefaultNaicsCodeFilter(event) {
    defaultFilters.value.naics_code.value = event
    console.log(defaultFilters.value)
}
function setDefaultParentCompanyStatusFilter(event) {
    defaultFilters.value.parent_company_status.value = event
    console.log(defaultFilters.value)
}
function setDefaultInvestorsStatusFilter(event) {
    defaultFilters.value.investors_status.value = event
    console.log(defaultFilters.value)
}
function setDefaultSourceOwnerFilter(event) {
    defaultFilters.value.source_owners.value = event
    console.log(defaultFilters.value)
}
function setDefaultOnboardingStatusFilter(event) {
    defaultFilters.value.onboarding_status.value = event
    console.log(defaultFilters.value)
}
function setDefaultProblemStatusFilter(event) {
    defaultFilters.value.problem_status.value = event
    console.log(defaultFilters.value)
}
function setDefaultDnrReasonFilter(event) {
    defaultFilters.value.do_not_research_reason.value = event
    console.log(defaultFilters.value)
}
function setDefaultResearchStatusFilter(event) {
    defaultFilters.value.research_statuses.value = event
    console.log(defaultFilters.value)
}
function setDefaultResearchStatus2Filter(event) {
    defaultFilters.value.research_statuses_2.value = event
    console.log(defaultFilters.value)
}
function setDefaultShowResearchHaltedFilter(event) {
    defaultFilters.value.show_research_halted.value = event
    console.log(defaultFilters.value)
}

function setDefaultReferralSourceFilter(event) {
    defaultFilters.value.referrer.value = event
    console.log(defaultFilters.value)
}
function setDefaultContractPSCKeywordFilter(event) {
    defaultFilters.value.contract_psc_keyword.value = event
    console.log(defaultFilters.value)
}

function setDefaultContractNAICSKeywordFilter(event) {
    defaultFilters.value.contract_naics_keyword.value = event
    console.log(defaultFilters.value)
}

function setDefaultContractAwardingSubAgencyFilter(event) {
    defaultFilters.value.contract_awarding_sub_agency.value = event
    console.log(defaultFilters.value)
}
function setDefaultCRMActionFilter(event) {
    defaultFilters.value.crm_actions.value = event
    console.log(defaultFilters.value)
}

function setDefaultReferralStatusFilter(event) {
    defaultFilters.value.referrer_status.value = event
    console.log(defaultFilters.value)
}

function setDefaultParentTypeFilter(event) {
    defaultFilters.value.parent_type.value = event
    console.log(defaultFilters.value)
}

function setDefaultIgpCategoryFilter(event) {
    defaultFilters.value.igp_category.value = event
    console.log(defaultFilters.value)
}

function setDefaultEverAxisPillarFilter(event) {
    defaultFilters.value.everaxis_pillar.value = event
    console.log(defaultFilters.value)
}


async function loadCells() {
    const token = store.state.auth.token
    const cells = await getCompanyCells(token)
    companyCells.value = cells
    cellOptions.value = [
    {value: null, label: '----'}, ...cells.map((cell) => {
        return {value: cell.id, label: cell.cell_label}
    })
    ]
}

async function setReferralSourceOptions() {
      const payload = {
        token: store.getters['auth/showToken'],
        endpoint: '?status=active'
      }
      const result = await getReferralSources(payload)
      referralSourceOptions.value = [{value: "clear", label: "------------"},...result.referrals.map((referralSource) => {
        return {value: referralSource.id, label: `${referralSource.referrer_first_name} ${referralSource.referrer_last_name} - ${referralSource.referrer_email}`}
      })]  
    }

function setAllFiltersVisibility(status){
    for (const filter in defaultFilters.value){
        // console.log('filter', filter)
        defaultFilters.value[filter].is_visible = status
    }
}

function removeCell(index) {
    // cellBoxes.value.splice(index, 1)
    createdCells.value.splice(index, 1)
}

const emit = defineEmits(['submit-form', 'delete-config'])

function setCellId(event, index){
    createdCells.value[index].id = event.target.value
    createdCellIds.value.push(event.target.value)
    console.log('created ids', createdCellIds.value)
    previewCells.value = companyCells.value.filter((cell) => {
       return createdCellIds.value.includes(cell.id.toString())
    })
}


function setCellOrdinal(event, index){
    createdCells.value[index].ordinal = event.target.value
}

function sortCellsByOrdinal() {
    createdCells.value.sort((a, b) => a.ordinal - b.ordinal)
    createdCells.value.forEach((cell, index) => {
        cell.ordinal = 10 + (index * 10)
    })

}

loadCells()

function deletePageConfig(){
    if(!user.value.id == props.pageConfig.user.id){
        alert("Error, you can only delete configurations you own")
        return
    }
    if(confirm("Are you sure you want to delete this page configuration?")){
        emit('delete-config')
    }
}

function submitForm(){
    if(!user.value.id == props.pageConfig.user.id){
        alert("Error, you can only edit page configurations you own")
        return
    }
    let ordinals = createdCells.value.map(cell => cell.ordinal)
    if(new Set(ordinals).size !== ordinals.length){
        alert("All ordinals must be different")
        return
    }
    if(createdCells.value.length == 0){
        alert("Must choose at least one cell")
        return
    }
    const data = JSON.stringify({
        updated_data: {
            config_data: {
                configuration_name: configName.value,
                page_size: pageSize.value,
                filter_visibility: showFilters.value,
                bulk_visibility: showBulkActions.value,
                basic_filters_visibility: showBasicFilters.value,
                active_filters_visibility: showActiveFilters.value,
                campaign_filters_visibility: showCampaignFilters.value,
                research_filters_visibility: showResearchFilters.value,
                status_summary_visibility: showCrmStatusSummary.value,
                is_public: makePublic.value,
                menu_location: menuLocation.value
            },
            cells: createdCells.value.map(cell => {
                return {company_cell_id: cell.id, ordinal: cell.ordinal}
            }),
            default_filters: defaultFilters.value
        }
    })
    // console.log('form data', data)
    emit('submit-form', data)
}

setCampaignOptions()
setTaskUserOptions()
setSourceOptions()
setSourceOwnerOptions()
setTierOptions()
setClientOptions()
setBusinessTypeOptions()
setSectorOptions()
setSubSectorOptions()
setCollectionTagOptions()
setNAICSOptions()
setDrivingTimeFocusOptions()
setReferralSourceOptions()
setCRMActionOptions()
setCRMStatusOptions()
setCapabilitiesOptions()

</script>
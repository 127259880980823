<template>
  <div class="w-full min-h-screen bg-gray-200">
    <div
      class="pt-3 pb-0.5 mx-5 mb-5 flex flex-row justify-start"
    >
      <h1 class="">Admin - Import Tools</h1>
    </div>
    <div class="px-5 w-full">
      <div class="w-full">
        <BaseTabBar 
          :selectedColor="selectedColor"
          :notSelectedColor="notSelectedColor"
          :hoverColor="hoverColor"
          :tabList="tabList"
          @set-page="setPage"
        />
      </div> 
    </div>
    <div v-if="visibility['Discover URLS']" class="w-full mt-5">
      <DiscoverUrlsView />
    </div>
    <div v-if="visibility['Get Basic Info']" class="w-full mt-5">
      <BasicInfoView />
    </div>
    <div v-if="visibility['Managing Duplicates']" class="w-full mt-5">
      <ManagingDuplicatesView />
    </div>
  </div>
</template>
<script setup>
  import { ref } from 'vue'
  import DiscoverUrlsView from './subpages/DiscoverUrlsView.vue'
  import BasicInfoView from './subpages/BasicInfoView.vue'
  import ManagingDuplicatesView from './subpages/ManagingDuplicatesView'
  import BaseTabBar from '../BaseTabBar.vue'

  // Navigation
  const visibility = ref({
    'Discover URLS' : true,
    'Get Basic Info' : false,
    'Managing Duplicates': false
  })

  function setPage(pageName) {
    for (const [key, value] of Object.entries(visibility.value)) {
      visibility.value[key] = false
    }
    visibility.value[pageName] = true
  }

  // Tab Bar
  const selectedColor = 'gray-200'
  const notSelectedColor = 'gray-300'
  const hoverColor = 'gray-100'

  const tabList = ref([
    {tabName: "Discover URLS", isSelected: true},
    {tabName: "Get Basic Info", isSelected: false},
    {tabName: "Managing Duplicates", isSelected: false},
  ])

</script>
export default {
    showToken (state) {
        return state.token
    },
    isAuthenticated (state){
        return !!state.token
    },
    showUser (state){
        return state.user
    },
    showUserGroups (state){
        return state.userGroups
    },
    showUserFullName (state){
        return state.userFullName
    },
    showUserPermissions (state){
        return state.userPermissions
    }
}
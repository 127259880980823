<template>
    <div>
        <span><base-pill>ZI: </base-pill> {{ziRevenueHeadcount}}</span>
    </div>
</template>

<script>
    export default {
        name: "zi-revenue-headcount-cell"
    }
</script>

<script setup>
import {defineProps, computed} from "vue"

    const props = defineProps({
        company: Object
    })

    const ziRevenueHeadcount = computed(() => {
        if(props.company.zoom_info_company){
            if(props.company.zoom_info_company.revenue && props.company.zoom_info_company.employee_count){
                return `${props.company.zoom_info_company.revenue/1000} M / ${props.company.zoom_info_company.employee_count} ppl`
            }else if(props.company.zoom_info_company.revenue){
                return `${props.company.zoom_info_company.revenue/1000} M / null ppl`
            } else if (props.company.zoom_info_company.employee_count){
                return `null / ${props.company.zoom_info_company.employee_count} ppl`
            } else {
                return '--'
            }
        } else {
            return '--'
        }


    })



</script>
<template>
    <div class="grid grid-cols-3">
        <div class="mt-1 mb-3">
           <span class="font-bold">Request Id:</span> {{ job.ecs_request_id }}
        </div>
        <div class="mt-1 mb-3">
           <span class="font-bold">Task Id:</span> {{ job.ecs_task_id }}
        </div>
        <div class="mt-1 mb-3">
           <span class="font-bold">Launch Type:</span> {{ job.ecs_launch_type }}
        </div>
        <div class="mb-3">
           <span class="font-bold">Request Time:</span> {{ requestTime}}
        </div>
        <div class="mb-3">
           <span class="font-bold">Group Name:</span> {{ job.ecs_group}}
        </div>
        <div class="mb-3">
           <span class="font-bold">CPU:</span> {{ job.ecs_cpu }}
        </div>
        <div class="mb-3">
           <span class="font-bold">HTTP Status Code:</span> {{ job.ecs_https_status_code }}
        </div>
        <div class="mb-3">
           <span class="font-bold">Container Name:</span> {{ job.ecs_container_name }}
        </div>
        <div class="mb-3">
           <span class="font-bold">Memory:</span> {{ job.ecs_memory }}
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { formatDateTime } from '@/composables/formatDateTime';

const requestTime  = computed(()=> {
    try {
        return formatDateTime(props.job.ecs_request_time)
    } catch {
        return 'None'
    }
})

const props = defineProps(['job'])

</script>
<template>
    <div v-if="isSelected" class="my-2 mx-auto p-3 border border-inherit rounded-lg shadow-xl bg-light-primary text-dark-primary">
        <slot></slot>
      </div>
      <div v-else class="my-2 mx-auto p-3 border border-inherit rounded-lg shadow-xl bg-[#F3F4F6]">
        <slot></slot>
      </div>
    </template>
    
    <script>
    export default {
      name: "base-card-select",
      props: ['isSelected']
    }
    </script>

<template>
    <div>
        <base-button-disabled @click="getZiId" disabled>Get ZoomInfo ID</base-button-disabled>
    </div>
</template>

<script>
    export default {
        name: "get-zi-id-button"
    }
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { getZoomInfoId } from '@/services/Company'
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

    const store = useStore()
    const $toast = useToast()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    async function getZiId () {
        console.log('getZiId')
        const token = store.state.auth.token
        const result = await getZoomInfoId(props.company.id, token)
        console.log(result)
        console.log('op detail')
        console.log(result.data.status)
        if(result.status == 200) {
            $toast.open({
                message: `Get ZI ID for ${props.company.company_name} - ${props.company.id}: ${result.data.status}`,
                type: 'success',
                duration: 5000
            })
        } else {
            $toast.open({
                message: `Get ZI ID for ${props.company.company_name} - ${props.company.id}: Error Getting ZI ID.`,
                type: 'error',
                duration: 5000
            })
        }
        emit('refetch-companies')
    }

</script>
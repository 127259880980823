<template>
    <div class="w-full rounded-md border border-gray-400" :class="groupStyling">
        <FTSQuery
            :key="condition.conditionKey"
            v-for="condition, index in conditions"
            :index="index"
            :conditionKey="condition.conditionKey"
            :conditionType="condition.conditionType"
            :conjunction="conjunction"
            :nestingLevel="nestingLevel"
            @upsert-query-condition="updateQueryConditions"
            @remove-query-condition="removeQueryCondition"
        >
        <template #conjunctionSelector>
                <select class="border border-gray-400 rounded-md h-8 w-fit -ml-1" required v-model="conjunction">
                    <option value="AND" selected>AND</option>
                    <option value="OR">OR</option>
                    <!-- <option value="NOT">NOT</option> -->
                </select>
            </template>
        </FTSQuery>
        <div class="w-full">
            <div class="flex flex-row justify-start items-center py-1 w-full space-x-1 pl-2">
                <base-button class="py-0" @click.prevent="addCondition('condition')">+ Condition</base-button>
                <base-button v-if="nestingLevel < 4" class="py-0" @click.prevent="addCondition('conditionGroup')">+ Condition Group</base-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import FTSQuery from './FTSQuery.vue';

import { ref, defineProps, defineEmits, computed, watch } from 'vue';

const props = defineProps(['index', 'conditionKey', 'nestingLevel'])

const emit = defineEmits(['upsert-query-condition', 'remove-query-condition'])


const nestingLevel = computed(() => {
    return props.nestingLevel + 1
})

const conjunction = ref('AND')

const groupStyling = computed(() => {
    let intensity = (props.nestingLevel) * 100
    console.log(intensity)
    return `bg-gray-${intensity}`
})

const queryString = computed(()=> {
    let returnString = ''
    let conditionString = ''
    for(let condition of conditions.value){
        if(condition.searchPhrase){
            conditionString += `${condition.queryString},`
        }
    }
    if(conditionString.length > 0){
        conditionString = conditionString.slice(0, -1)
        returnString = `${conjunction.value}(${conditionString})`
    }
    return returnString
})

const conditions = ref([
    {
        conditionKey: Math.random(),
        conditionType: 'condition'
    }
])

function updateQueryConditions(queryCondition) {
    console.log('updateQueryConditions', queryCondition)
    console.log('condition', conditions.value.find(condition => condition.conditionKey === queryCondition.conditionKey))
    if(queryCondition.conditionKey){
        let existingCondition = conditions.value.find(condition => condition.conditionKey === queryCondition.conditionKey)
        if(existingCondition){
            console.log('condition has conditionKey')
            existingCondition.queryString = queryCondition.queryString
            existingCondition.searchPhrase = queryCondition.searchPhrase
        } else {
            conditions.value.push(queryCondition)
        }
        
    }
    emitUpdateQueryConditions()
}

function removeQueryCondition(conditionKey) {
    let existingCondition = conditions.value.find(condition => condition.conditionKey === conditionKey)
    if(existingCondition){
        conditions.value.splice(conditions.value.findIndex(condition => condition.conditionKey === conditionKey), 1)
    }  
}

function addCondition(conditionType) {
    conditions.value.push({
        conditionKey: Math.random(),
        conditionType: conditionType
    })
}

function emitUpdateQueryConditions() {
    emit('upsert-query-condition', {
        conditionKey: props.conditionKey,
        queryString: queryString.value,
        searchPhrase: queryString.value ? 'multiple' : null
    })
}

watch(()=> queryString.value, () => {
    emitUpdateQueryConditions()
})
</script>
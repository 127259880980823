<template>
    <base-table-row>
        <base-table-cell-small>
        <base-dblclick-input
            modelType="collection"
            :model-id="collection.id"
            field-name="collection_tag_name"
            :field-value="collection.collection_tag_name"
            inputType="text"
            @refetch="refetchCollections"
        ></base-dblclick-input>
        </base-table-cell-small>
        <base-table-cell-small>
        {{ collection.number_of_companies }}
        </base-table-cell-small>
        <base-table-cell-small>
            {{ createdBy }}
        </base-table-cell-small>
        <base-table-cell-small>
            <div v-if="isCollectionOwner" class="w-full flex justify-center items-center">
                <base-button-negative @click="deleteCollection">X</base-button-negative>
            </div>
        </base-table-cell-small>
    </base-table-row>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch} from 'vue';
import { removeAllCompaniesAndDeleteCollectionTag } from '@/services/CollectionTag';
import { useToast } from 'vue-toast-notification';
import store from '@/store';

const props = defineProps(['collection', 'userId'])

const emit = defineEmits(['refetch-collections'])

const $toast = useToast()

const isEditable = ref(false)

const collectionTagName = ref(null)

// const displayedCollectionName = computed(() => {
//     if(collectionTagName.value) {
//         return collectionTagName.value
//     } else if (props.collection) {
//         return props.collection.collection_tag_name
//     } else {
//         return '--'
//     }
// })

watch(props, ()=> {
    collectionTagName.value = null
})

const createdBy = computed(()=> {
    if(props.collection.created_by) {
        return `${props.collection.created_by.first_name} ${props.collection.created_by.last_name}`
    } else {
        return '--'
    }
})

const isCollectionOwner = computed(() => {
    if(props.collection.created_by) {
        if(props.collection.created_by.id==props.userId) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
})

// function toggleEditable () {
//     isEditable.value = !isEditable.value
// }

// function setCollectionTagName(newValue) {
//     collectionTagName.value = newValue
// }

async function deleteCollection() {
    // console.log('delete collection')
    if (!confirm(`You are about to delete collection: ${props.collection.collection_tag_name} (id-${props.collection.id}) containing ${props.collection.number_of_companies} companies. Do you wish to continue?`)) {
        return;
    } else {
        const token = store.state.auth.token
        const result = await removeAllCompaniesAndDeleteCollectionTag(props.collection.id, token)
        if(result.status == 200) {
            $toast.open({
                message: `Successfully Deleted Collection: ${props.collection.collection_tag_name} (id-${props.collection.id}).`,
                type: 'success',
                duration: 5000
            })
            emit('refetch-collections')
        } else {
            $toast.open({
                message: `Unable to Delete Collection: ${props.collection.collection_tag_name} (id-${props.collection.id}).`,
                type: 'error',
                duration: 20000
            })
        }
    }
}


function refetchCollections() {
    emit('refetch-collections')
}
</script>
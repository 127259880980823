<template>
    <div @dblclick="toggleEditable">
         <span v-if="isEditable">
            <select class="border rounded-md" @change="handleInputChange" :value="company.igp_category" name="" id="">
                <option v-for="option in selectOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
        </span>
        <span v-else >{{ company.igp_category ? company.igp_category : '--' }}</span>
    </div>
</template>

<script>
    export default {
        name: "igp-category-cell"
    }
</script>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { updateCompany } from '@/services/Company.js'
import { useStore } from "vuex";

    const isEditable = ref(false)

    const store = useStore()

    const props = defineProps({
        company:  Object
    })

    const emit = defineEmits(['refetch-companies'])

    function toggleEditable () {
      isEditable.value = !isEditable.value
  }

  const selectOptions = ref([
    {value: null, label: '--'},
    {value: 'Microwave/RF', label: 'Microwave/RF'},
    {value: 'Elastomer', label: 'Elastomer'},
    {value: 'Photonics/Optics', label: 'Photonics/Optics'},
    {value: 'M&I', label: 'M&I'},
    {value: 'HMI', label: 'HMI'},
    {value: 'Valves, Actuators, Pumps', label: 'Valves, Actuators, Pumps'}
  ])


  async function handleInputChange(e) {
    let newValue = e.target.value
    const targetField = "igp_category"
    let updatedData = {}
    updatedData[targetField] = newValue
    const data = JSON.stringify(updatedData);
    console.log('data', data)
    let token = store.state.auth.token
    console.log('new value', newValue)
    await updateCompany(props.company.id, data, token)
    emit('refetch-companies')
    isEditable.value = false
}
    

</script>
<template>  
    <div @dblclick="toggleEditable">
        <div v-if="isEditable">
            <select name="" id="" class="border border-dark-primary rounded p-1" @change="updateFeedback"  :value="company.client_feedback">
                <option value="">------</option>
                <option value="approved">Approved</option>
                <option value="excluded">Excluded</option>
                <option value="hold">Hold</option>
                <option value="pass">Pass</option>
                <option value="research_further">Research Further</option>
            </select>
        </div>
        <div v-else>
            <span>{{ clientFeedback }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "client-feedback-cell"
    }
</script>

<script setup>
import { defineProps, computed, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';
import { updateCompany } from '@/services/Company';

    const store = useStore()

    const isEditable = ref(false)

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function toggleEditable(){
        isEditable.value = !isEditable.value
    }

    const clientFeedback = computed(() => {
        return props.company.client_feedback ? props.company.client_feedback : "--"
    })

    async function updateFeedback(e) {
        const token = store.state.auth.token
        // console.log(e.target.value)
        const data = JSON.stringify({
            'client_feedback': e.target.value
        })
        console.log(data)
        await updateCompany(props.company.id, data, token)
        emit('refetch-companies')
        isEditable.value = false
    }

</script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getDLAGovernmentDesignationsByGoverningSpec(payload) {
    return axios({
        method: "get",
        url: `${BASE_URL}/dla_government_designations${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        }
    })
    .then((response) => {
        console.log(response)
        return response.data
    })
    .catch(error => console.error(error))

}
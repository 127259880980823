<template>
    <base-table-row>
        <base-table-cell>
        {{ parameter.parameter.parameter_name }}
        </base-table-cell>
        <base-table-cell>
        {{ parameterValue }}
        </base-table-cell>
        <base-table-cell>
        {{ parameterType }}
        </base-table-cell>
        <base-table-cell>
            <div v-if="parameter.metrics.length > 0">
                <div v-for="metric in parameter.metrics" :key="metric.id">
                    <span v-if="metric.metric_value_integer">
                        {{ metric.metric_value_integer }} {{ metric.metric_name }} 
                    </span>
                    <div v-if="metric.metric_value_string">
                        <span>
                          {{ metric.metric_value_string }} {{ metric.metric_name }}   
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                --
            </div>
        </base-table-cell>
    </base-table-row>
</template>


<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';


const props = defineProps(['parameter'])

const parameterValue = computed(() => {
    if(props.parameter.parameter_value_integer !== null && !props.parameter.parameter_value_string) {
        return props.parameter.parameter_value_integer
    } else if(!props.parameter.parameter_value_integer && props.parameter.parameter_value_string !== null) {
        return props.parameter.parameter_value_string
    } else {
        return 'None'
    }
})


const parameterType = computed((parameter) => {
    if(props.parameter.parameter_value_integer && !props.parameter.parameter_value_string) {
        return 'Integer'
    } else if(!props.parameter.parameter_value_integer && props.parameter.parameter_value_string) {
        return 'String'
    } else {
        return 'None'
    }
})


</script>
import { getPageConfigs } from "@/services/PageConfig";

export default {
    async setPageConfigs(context, filters) {
        const token = context.rootGetters['auth/showToken']
        const user = context.rootGetters['auth/showUser']
        context.commit('setPageConfigLoadingTrue')
        // console.log('user in vuex', user)
        const pageConfigs = await getPageConfigs(token, `user=${user.id}`)
        context.commit('setPageConfigs', pageConfigs)
        context.commit('setPageConfigLoadingFalse')
        // context.dispatch('tryToSetPageConfig')

    },
    setSelectedPageConfig(context, pageConfig) {
        context.commit('setSelectedPageConfig', pageConfig)
        sessionStorage.setItem('selectedPageConfig', JSON.stringify(pageConfig))
        if(pageConfig.filter_visibility){
            context.commit('company/setShowFilterPaneTrue', null, {root: true})
        } else {
            context.commit('company/setShowFilterPaneFalse', null, {root: true})
        }
        if(pageConfig.bulk_visibility){
            context.commit('company/setShowBulkOperationsPaneTrue', null, {root: true})
        } else {
            context.commit('company/setShowBulkOperationsPaneFalse', null, {root: true})
        }
        if(pageConfig.basic_filters_visibility){
            context.commit('company/setShowBasicFiltersTrue', null, {root: true})
        } else {
            context.commit('company/setShowBasicFiltersFalse', null, {root: true})
        }
        if(pageConfig.campaign_filters_visibility){
            context.commit('company/setShowCampaignFiltersTrue', null, {root: true})
        } else {
            context.commit('company/setShowCampaignFiltersFalse', null, {root: true})
        }
        if(pageConfig.research_filters_visibility){
            context.commit('company/setShowResearchFiltersTrue', null, {root: true})
        } else {
            context.commit('company/setShowResearchFiltersFalse', null, {root: true})
        }
        if(pageConfig.active_filters_visibility){
            context.commit('company/setShowActiveFiltersTrue', null, {root: true})
        } else {
            context.commit('company/setShowActiveFiltersFalse', null, {root: true})
        }
        if(pageConfig.status_summary_visibility){
            context.commit('company/setShowStatusSummaryPaneTrue', null, {root: true})
        } else {
            context.commit('company/setShowStatusSummaryPaneFalse', null, {root: true})
        }
        if(pageConfig.default_filters.length > 0 ){
            pageConfig.default_filters.forEach((filter) => {
                context.commit('company/setVisibleFilters', {filterName: filter.filter_field.vue_filter_name, status: filter.is_visible}, {root: true})
            })
            if(!pageConfig.default_filters.find(filter => filter.filter_field.id == 16).is_visible &&  !pageConfig.default_filters.find(filter => filter.filter_field.id == 17).is_visible){
                context.commit('company/setVisibleFilters', {filterName: "companyRevenueFilter", status: false}, {root: true})
            }
            if(!pageConfig.default_filters.find(filter => filter.filter_field.id == 18).is_visible &&  !pageConfig.default_filters.find(filter => filter.filter_field.id == 19).is_visible){
                context.commit('company/setVisibleFilters', {filterName: "companyHeadcountFilter", status: false}, {root: true})
            }
            if(!pageConfig.default_filters.find(filter => filter.filter_field.id == 20).is_visible &&  !pageConfig.default_filters.find(filter => filter.filter_field.id == 21).is_visible){
                context.commit('company/setVisibleFilters', {filterName: "companyRevenueZiCombinedFilter", status: false}, {root: true})
            }
        } else {
            // console.log('no filters')
            context.commit('company/setVisibleFiltersAllTrue', null, {root: true})
        }
        // const selectedConfig = sessionStorage.getItem('selectedPageConfig')
        // console.log('config in storage', JSON.parse(selectedConfig))
    },
    tryToSetPageConfig(context){
        // console.log('try to set page config ran')
        let selectedConfig = sessionStorage.getItem('selectedPageConfig')
        if(selectedConfig && selectedConfig != "undefined"){
            selectedConfig = JSON.parse(selectedConfig)
            let pageConfigs = context.rootGetters['pageConfigs/showPageConfigs']
            let currentConfig = pageConfigs.filter(config => config.id == selectedConfig.id)[0]
            if(currentConfig){
                context.dispatch('setSelectedPageConfig', currentConfig)
            } else {
                let pageConfigs = context.rootGetters['pageConfigs/showPageConfigs']
                let companies = pageConfigs.filter(config => config.id == 1)[0]
                context.dispatch('setSelectedPageConfig', companies)
            }
        } else {
            let pageConfigs = context.rootGetters['pageConfigs/showPageConfigs']
            let companies = pageConfigs.filter(config => config.id == 1)[0]
            context.dispatch('setSelectedPageConfig', companies)
        }
    }

}
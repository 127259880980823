<template>
    <div>
        {{campaignName}}
    </div>
</template>

<script>
    export default {
        name: 'campaign-cell'
    }
</script>

<script setup>
    import {computed, defineProps} from "vue"

    const props = defineProps({
        company: Object
    })

    const campaignName = computed(() => {
        if(props.company.campaign){
            return props.company.campaign.campaign_name
        } else {
            return "--"
        }
    })


</script>
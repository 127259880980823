<template>
  <div class="w-full">
    <h2 class="text-lg font-bold">
      <slot name="table-title"></slot>
    </h2>
    <table v-if="alignment === 'center'" class="text-center bg-white table-fixed">
      <thead class="bg-amber-700">
        <slot name="table-header"></slot>
      </thead>
      <tbody class="">
        <slot name="table-body"></slot>
      </tbody>
    </table>
    <table v-else-if="alignment === 'right'" class="text-right bg-white table-fixed">
      <thead class="bg-amber-700">
        <slot name="table-header"></slot>
      </thead>
      <tbody class="">
        <slot name="table-body"></slot>
      </tbody>
    </table>
    <table v-else class="text-left bg-white table-fixed">
      <thead class="relative -top-0.5 left-0 right-0 z-10">
        <slot name="table-header"></slot>
      </thead>
      <tbody class="overflow-y-auto">
        <slot name="table-body"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "BaseTable.vue",
  props: ['alignment'],
  mounted () {
    var thElm;
    var startOffset;

    Array.prototype.forEach.call(
    document.querySelectorAll("table th"),
    function (th) {
      
      var grip = document.createElement('div');
      grip.innerHTML = "&nbsp;";
      grip.style.top = 0;
      grip.style.right = 0;
      grip.style.bottom = 0;
      grip.style.width = '5px';
      grip.style.position = 'absolute';
      grip.style.cursor = 'col-resize';
      grip.addEventListener('mousedown', function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
    });

    document.addEventListener('mousemove', function (e) {
        if (thElm) {
            thElm.style.width = startOffset + e.pageX + 'px';
        }
    });

    document.addEventListener('mouseup', function () {
        thElm = undefined;

    });
}

}
</script>

<style scoped>
</style>
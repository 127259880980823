<template>
    <base-table-row>
        <base-table-cell>
            <div class="flex items-center h-full" @click="selectOrRemoveCompany">
                <input :checked="isSelected" :disabled="!contract.sam_gov_entity" type="checkbox">
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class=" whitespace-nowrap">
               <a class="underline text-blue-500" :href="contract.usaspending_permalink" target="_blank">{{ contract.award_id_piid }} ({{ contract.id }})</a> 
            </div>
        </base-table-cell>
        <base-table-cell>
            <PrimeContractRecipientCell :contract="contract" :campaigns="campaigns" @loading-company="loadingCompany" />
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ contract.transaction_description }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ contract.naics_code }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end">
                {{ totalDollarsObligated }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ formatDateTwoDigitYear(contract.period_of_performance_start_date) }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ formatDateTwoDigitYear(contract.period_of_performance_current_end_date) }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ formatDateTwoDigitYear(contract.action_date) }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ contract.awarding_agency_name }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ contract.awarding_sub_agency_name }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ contract.major_program }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="">
                {{ psc }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <PrimeContractDetailsCell :contract="contract" />
        </base-table-cell>
    </base-table-row>

</template>

<script>
export default {
    name: 'prime-contract-row'
}
</script>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { formatDateTwoDigitYear } from '@/composables/formatDate';
import PrimeContractRecipientCell from './PrimeContractRecipientCell.vue';
import PrimeContractDetailsCell from './PrimeContractDetailsCell.vue';

const props = defineProps({
    contract: Object,
    campaigns: Array,
    selectedCompanies: Array
})

const isSelected = computed(() => {
    if(props.contract.sam_gov_entity){
        return props.selectedCompanies.map(company => company.id).includes(props.contract.sam_gov_entity.company.id)
    } else {
        return false
    }
})

const emit = defineEmits(['loading-company', 'select-company', 'remove-company'])

function loadingCompany(isLoading){
    emit('loading-company', isLoading)
}

function selectOrRemoveCompany() {
    if(!isSelected.value){
        // isSelected.value = true
        emit('select-company', props.contract.sam_gov_entity.company)
    } else {
        // isSelected.value = false
        emit('remove-company', props.contract.sam_gov_entity.company)
    }
}

const totalDollarsObligated = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.contract.total_dollars_obligated);

})

const psc = computed(() => {
    if(!props.contract.product_or_service_code){
        return '--'
    } else {
        return `${props.contract.product_or_service_code} - ${props.contract.product_or_service_code_description}`
    }
})

// const emit = defineEmits(['refetch-engagements'])

// function fetchNewEngagements() {
//     emit('refetch-engagements')
// }


</script>

<template>
  <tr>
    <base-table-cell v-if="isExpanded">
      <div class="w-full h-full">
        <BaseButton
          @click="toggleExpand"
          class="text-base font-bold ml-2 w-[35px] h-[35px] px-[10px]">
          -
        </BaseButton>
      </div>
    </base-table-cell>
    <base-table-cell v-if="!isExpanded">
      <div class="w-full h-full">
        <BaseButton
          @click="toggleExpand"
          class="text-base font-bold ml-2 w-[35px] h-[35px] px-[10px]">
          +
        </BaseButton>
      </div>
    </base-table-cell>
    <base-table-cell>
      {{ email.email_subject }}
    </base-table-cell>
    <base-table-cell>
      {{ fromToText }}
    </base-table-cell>
    <base-table-cell>
      <div class="">
        {{ email.email_key }}
      </div>
    </base-table-cell>
    <base-table-cell>
      {{ email.last_modified }}
    </base-table-cell>
    <base-table-cell>
      {{ email.size }}
    </base-table-cell>
    <base-table-cell>
      <div class="flex flex-col">
        <BaseButton
          @click="modifyEmail"
        >
          Create Email History
        </BaseButton>
        <BaseButton
          @click="deleteEmail"
        >
          Delete
        </BaseButton>
      </div>
    </base-table-cell>
  </tr>
  <!-- EMAIL BODY EXPANSION -->
  <template v-if="isExpanded">
    <base-table-cell></base-table-cell>
    <base-table-cell 
      colspan="6"
      class="break-words bg-gray-100"
    >
      <div class="xl:max-w-[100%-104px] max-w-[1200px]">
        <!-- <p> -->
          <!-- {{ email.email_body ? email.email_body : '(No email body)'}} -->
          <!-- {{ emailBody ? emailBody : '(No email body)' }} -->
          <!-- {{ emailBody }} -->
        <!-- </p> -->
        <p v-if="email.email_body" v-html="emailBody"/>
        <p v-if="!email.email_body">
          (No email body)
        </p>
      </div>
    </base-table-cell>
  </template>
</template>

<script setup>
  import { defineProps, ref, defineEmits} from 'vue';
  import BaseButton from '../BaseButton.vue'

  const props = defineProps({
    expanded: Boolean,
    email: Object
  });

  const isExpanded = ref(props.expanded)
  const emit = defineEmits(['toggle-expand', 'modify-email', 'delete-email'])

  let fromToText = ref('');
  let emailBody = ref('')

  function modifyEmail() {
    emit('modify-email', props.email)
  }

  function deleteEmail() {
    emit('delete-email', props.email)
  }

  function createfromToText() {
    if(props.email.email_from){
      fromToText.value += `FROM: ${props.email.email_from}`
    }
    if(props.email.email_from){
      fromToText.value += ` TO: ${props.email.email_from}`
    }
  }

  function toggleExpand() {
    isExpanded.value = !isExpanded.value
    // console.log(JSON.stringify(props.email.email_body))
  }

  function formatEmailBody() {
    if (props.email.email_body) {
      // emailBody.value = props.email.email_body.replace(/\r\n/g, "<br>");
      emailBody.value = props.email.email_body.replace(/(\r\n|\r|\n)/g, '<br>')
      // console.log(JSON.stringify(emailBody.value))
    }
  }

  formatEmailBody();
  createfromToText();
</script>
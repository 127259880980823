<template>
  <div class="print:hidden mx-1">
    <transition-group
      tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" key="company-info">
        <div
          class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400"
        >
          <h3 class="text-sm">Client Info:</h3>
          <base-button-affirmative @click="resetFilters" class="text-xs -py-2"
            >Reset</base-button-affirmative
          >
        </div>
        <div
          class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"
          id="company-info"
        >
          <base-filter>
            <template v-slot:label> Status </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Status"
                v-model="statusFilter"
                :options="statusOptions"
                @update:modelValue="setStatusFilter"
                :multiple="false"
              />
            </template>
          </base-filter>
          <base-filter @click="setAccountManagerOptions">
            <template v-slot:label> Account Manager </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="---------"
                v-model="accountManagerFilter"
                :options="accountManagerOptions"
                @update:modelValue="setAccountManagerFilter"
                :multiple="true"
              />
            </template>
          </base-filter>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>

// imports

import { ref, computed, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { getUsersByGroup } from "../../services/User.js";

import BaseListBox from "../BaseListBox.vue";

// constants/variables

const store = useStore();

const props = defineProps(["accountManager"]);
const emit = defineEmits([
  "change-referral-filters",
  "set-changed-referral-filters",
]);

const statusFilter = ref([]);
const accountManagerFilter = ref([]);
const accountManagerOptions = ref([]);
const statusOptions = ref([
  { value: "clear", label: "------------" },
  { value: "active", label: "Active" },

  { value: "inactive", label: "Inactive" },
  { value: "prospect", label: "Prospect" },
])

// computed values

const filters = computed(() => {
  return {
    statusFilter: statusFilter,
    accountManagerFilter: accountManagerFilter,
  };
});

// async functions

async function setAccountManagerOptions() {
  let payload = {
    token: store.state.auth.token,
    endpoint: "users",
    group: "account_manager_group&group=management_group",
  };
  const result = await getUsersByGroup(payload);
  accountManagerOptions.value = [
    { value: "clear", label: "------------" },
    ...result.users
      .filter((accountManager) => ![2, 10, 16, 22].includes(accountManager.id))
      .map((accountManager) => {
        return {
          value: accountManager.id,
          label: accountManager.first_name + " " + accountManager.last_name,
        };
      }),
  ];
}

// functions

function setStatusFilter(target) {
  if (target.includes("clear")) {
    statusFilter.value = [];
  } else {
    statusFilter.value = target;
  }
  emitChangeCompanyFiltersAndSetChanged();
}

function setAccountManagerFilter(target) {
  if (target.includes("clear")) {
    accountManagerFilter.value = [];
  } else {
    accountManagerFilter.value = target;
  }
  emitChangeCompanyFiltersAndSetChanged();
}

function emitChangeCompanyFiltersAndSetChanged() {
  emit("change-referral-filters", filters);
  emit("set-changed-referral-filters");
}

function resetFilters() {
  statusFilter.value = "";
  accountManagerFilter.value = [];
  emitChangeCompanyFiltersAndSetChanged();
}

</script>

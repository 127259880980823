<template>
  <div>
    <div class="w-full border-b border-dark-secondary mb-2">
      <div class="w-full flex">
        <div
          class="grid grid-cols-6 w-full gap-2 mb-1 p-1 text-[0.8rem]"
        >
          <div v-if="!missingDate" class="flex flex-col my-auto col-span-2">
            <label>Activity Date*</label>
            <input
              type="date"
              class="p-1 border w-full inline"
              v-model="createData.activity_date"
            />
          </div>
          <div v-if="missingDate" class="flex flex-col my-auto col-span-2">
            <label class="text-red-600">Activity Date*</label>
            <input
              type="date"
              class="p-1 w-full inline border-red-400 border"
              v-model="createData.activity_date"
            />
          </div>
          <div v-if="!missingName" class="flex flex-col my-auto col-span-2">
            <label>Activity Name* (Subject)</label>
            <input
              class="p-1 border w-full inline"
              v-model="createData.activity_name"
            />
          </div>
          <div v-if="missingName" class="flex flex-col my-auto col-span-2">
            <label class="text-red-600">Activity Name* (Subject)</label>
            <input
              class="p-1 w-full inline border-red-400 border"
              v-model="createData.activity_name"
            />
          </div>
          <div class="flex flex-col my-auto col-span-1">
            <label>Activity Type</label>
            <input
              class="p-1 border w-full inline"
              v-model="createData.activity_type"
            />
          </div>
          <div class="flex flex-col my-auto col-span-1">
            <label>Sequence</label>
            <input
              class="p-1 border w-full inline"
              v-model="createData.activity_seq"
            />
          </div>
          <div class="flex flex-col my-auto col-span-2">
            <label>From (if email, otherwise leave blank)</label>
            <input
              class="p-1 border w-full inline"
              v-model="createData.email_from"
            />
          </div>
          <div class="flex flex-col my-auto col-span-2">
            <label>To (if email, otherwise leave blank)</label>
            <input
              class="p-1 border w-full inline"
              v-model="createData.email_to"
            />
          </div>
          <div class="flex flex-col my-auto col-span-2">
            <label>CC (if email, otherwise leave blank)</label>
            <input
              class="p-1 border w-full inline"
              v-model="createData.email_cc"
            />
          </div>
          <div
            v-if="missingDate || missingName"
            class="text-center col-span-6 text-red-500 font-semibold"
          >
            Please complete all required fields.
          </div>
          <div class="flex flex-col h-[25vh] mt-auto col-span-6 row-span-2 mb-5">
            <label>Activity Note</label>
            <textarea
              class="p-1 border h-full w-full"
              v-model="createData.activity_note"
            />
          </div>
        </div>
        <base-button-negative
        class="px-2 rounded-xl ml-3 my-auto"
        @click="closeForm"
        > 
          X 
        </base-button-negative>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, defineProps, watch} from "vue";
  import { createActivity } from "@/services/Activity";
  import { useStore } from "vuex";
  import { useToast } from 'vue-toast-notification';

  const $toast = useToast()
  const store = useStore()
  const emit = defineEmits([
    'close-form', 
    'failed-create', 
    'tried-create', 
    'is-valid', 
    'not-valid',
    'highlighted'
  ])
  const props = defineProps({
    submitted: Boolean,
    companyId: Number,
    highlight: Boolean
    // index: Number
  })
  // const dateFormat = /\d{4}-0\d{2}-\d{2}g/


  // camel case here as this will be pushed as json
  const createData = ref({
    activity_date: null,
    activity_name: null,
    activity_type: null,
    email_from: null,
    email_to: null,
    email_cc: null,
    activity_seq: 0,
    activity_note: null,
    company: props.companyId,
  })

  const missingDate = ref(false)
  const missingName = ref(false)

  watch(
    () => props.submitted,
    () => {
      if (props.submitted) {
        // if (!createData.value.activity_date || !createData.value.activity_name) {
        //   emit('failed-create');
        //   emit('tried-create');
        // } else {
        //   createNewActivity();
        // }
        createNewActivity();
      }
    }
  );

  watch(
    () => props.highlight,
    () => {
      if (props.highlight) {
        // console.log("HERE")
        if (!createData.value.activity_date || !createData.value.activity_name) {
          if (!createData.value.activity_date) {
            missingDate.value = true;
          }
          if (!createData.value.activity_name) {
            missingName.value = true;
          }
        }
        emit('highlighted')
      }
    }
  );

  watch(
    () => createData,
    () => {
      if (createData.value.activity_date && createData.value.activity_name) {
        emit('is-valid')
      } else {
        emit('not-valid')
      }
    },
    {deep: true}
  );

  async function createNewActivity() {
    const data = JSON.stringify(createData.value)
    const payload = {
      token: store.state.auth.token,
      activity: data
    }
    try {
      const result =  await createActivity(payload);
      if (result.status == 201) {
        $toast.open(
          {
            message: `Activity Created: '${createData.value.activity_name}'`,
            type: 'success',
            duration: 20000
          }
        )
        emit('close-form')
      } else {
        // console.log("FIRST CHECKPOINT")
        emit('failed-create')
        $toast.open(
          {
            message: `${result.status} - could not create '${createData.value.activity_name}'`,
            type: 'error',
            duration: 20000
          }
        )
      }
      // console.log(props.submitted)
    } catch(exception) {
      emit('failed-create', props.index)
      $toast.open(
        {
          message: `${exception.response.status} - could not create '${createData.value.activity_name}'`,
          type: 'error',
          duration: 20000
        }
      )
    }
    emit('tried-create');
  }

  function closeForm(){
    emit('close-form')
  }

</script>

<template>
    <div v-if="positionDeltas.length > 0">
        <div v-for="positionDelta in positionDeltas" :key="positionDelta.id" class="w-full">
            <div class="border-b border-gray-300 py-2 space-y-1">
                <div>
                    <base-pill>Focus:</base-pill>{{ positionDelta.geo_location_name }}
                </div>
                <div>
                    <base-pill>Drive Time:</base-pill> {{ drivingTime(positionDelta.driving_time) }}
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        --
    </div>
</template>

<script>
    export default {
        name: "driving-time-cell"
    }
</script>

<script setup>
import {defineProps, computed} from "vue"

    const props = defineProps({
        company: Object
    })

    const positionDeltas = computed(() => {
        if(props.company.positiondelta_set){
            let positionDeltas = props.company.positiondelta_set
            return positionDeltas.sort((a, b) => a.driving_time - b.driving_time)
        } else {
            return []
        }
    })

    const drivingTime = (positionDeltaDriveTime) => {
        if(positionDeltaDriveTime > 60) {
            return `${(positionDeltaDriveTime / 60).toFixed(1)} hrs`
        } else if (positionDeltaDriveTime > 0 && positionDeltaDriveTime < 60) {
            return `${Math.round(positionDeltaDriveTime)} mins`
        } else {
            return '--'
        }
    }
</script>

import { formatHTMLDate } from '@/composables/formatDate'

function getDateStartOfString(dateString) {
    let foundDate = dateString.match('^\\d{2}/\\d{2}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{2}/\\d{1}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}/\\d{2}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}/\\d{1}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{2}/\\d{2}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{2}/\\d{1}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}/\\d{2}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}/\\d{1}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
        foundDate = dateString.match('^\\d{2}-\\d{2}-\\d{4}')

    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{2}-\\d{1}-\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}-\\d{2}-\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}-\\d{1}-\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{2}-\\d{2}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{2}-\\d{1}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}-\\d{2}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('^\\d{1}-\\d{1}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    return {
        datestring: null,
        date: null
    }
}

function getDateEndOfString(dateString) {
    let foundDate = dateString.match('\\d{2}/\\d{2}/\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}/\\d{1}/\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{2}/\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{1}/\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}/\\d{2}/\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}/\\d{1}/\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{2}/\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{1}/\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
        foundDate = dateString.match('\\d{2}-\\d{2}-\\d{4}$')

    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}-\\d{1}-\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{2}-\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{1}-\\d{4}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}-\\d{2}-\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}-\\d{1}-\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{2}-\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{1}-\\d{2}$')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    return {
        datestring: null,
        date: null
    }
}

function getDateMiddleOfString(dateString) {
    let foundDate = dateString.match('\\d{2}/\\d{2}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}/\\d{1}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{2}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{1}/\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}/\\d{2}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}/\\d{1}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{2}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}/\\d{1}/\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
        foundDate = dateString.match('\\d{2}-\\d{2}-\\d{4}')

    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}-\\d{1}-\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{2}-\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{1}-\\d{4}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}-\\d{2}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{2}-\\d{1}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{2}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    foundDate = dateString.match('\\d{1}-\\d{1}-\\d{2}')
    if(foundDate) {
        console.log('foundDate')
        console.log(foundDate, foundDate[0])
        console.log(formatHTMLDate(foundDate[0]))
        return {
            datestring: foundDate[0],
            date: formatHTMLDate(foundDate[0])
        }
    }
    return {
        datestring: null,
        date: null
    }
}

function getDateFromAction(action) {
    action = action.trim()
    let dateObject = getDateStartOfString(action)
    if(dateObject.date && dateObject.datestring) {
        return dateObject
    }
    dateObject = getDateEndOfString(action)
    if(dateObject.date && dateObject.datestring) {
        return dateObject
    }
    dateObject = getDateMiddleOfString(action)
    if(dateObject.date && dateObject.datestring) {
        return dateObject
    }
    return {
        date: null,
        datestring: null
    }

}

function getUserIDFromAction(action) {
    if(action.startsWith(': ')){
        action = action.replace(': ', '').trim()
    }
    let user_id = null
    let matchingString = null
    if(action.startsWith('BA')) {
        user_id = 55
        matchingString = 'BA'
    } else if (action.startsWith('SS')) {
        user_id = 3
        matchingString = 'SS'
    } else if (action.startsWith('Scott')) {
        user_id = 3
        matchingString = 'Scott'
    } else if (action.startsWith('DK')) {
        user_id = 37
        matchingString = 'DK'
    } else if (action.startsWith('Dan')) {
        user_id = 37
        matchingString = 'Dan'
    } else if (action.startsWith('DAN')) {
        user_id = 37
        matchingString = 'DAN'
    } else if (action.startsWith('Dan Klawitter')) {
        user_id = 37
        matchingString = 'Dan Klawitter'
    } else if (action.startsWith('Suz')) {
        user_id = 10
        matchingString = 'Suz'
    } else if (action.startsWith('MN')) {
        user_id = 14
        matchingString = 'MN'
    }
    return {
        user_id: user_id,
        matchingString: matchingString
    }
}


function getActionIDFromAction(action) {
    if(action.includes(': ')){
        action = action.split(': ')[1]
    }
    let action_id = null
    if(action.includes('sent NDA')) {
        // action = 'NDA Sent for Review'
        action_id = 12
    } else if (action.includes('initial email')) {
        // action = 'Email 1'
        action_id = 2
    } else if (action.includes('responded')) {
        // action = 'Initial Response'
        action_id = 8
    } else if (action.includes('2nd email')) {
        // action = 'Email 2'
        action_id = 3
    } else if (action.includes('3rd email')) {
        // action = 'Email 3'
        action_id = 4
    } else if (action.includes('4th email')) {
        // action = 'Email 4'
        action_id = 21
    } else if (action.includes('email')) {
        // action = 'Email Sent'
        action_id = 1
    } else if (action.includes('call')) {
        // action = 'Client Call/Zoom'
        action_id = 10
    } else {
        // action = 'Other'
        action_id = 19
    }
    return action_id

}

function getNotesFromAction(action) {
    let notes
    if(action.includes(': ')){
        let note_list = action.split(': ')
        console.log('split notes', note_list.length)
        console.log(note_list)
        note_list.splice(0, 1)
        console.log('split notes', note_list.length)
        console.log(note_list)
        notes = note_list.join(' ').trim()
        console.log('joined notes')
        console.log(notes)
    } else {
        notes = action
    }
    return notes
}

export function parseCRMActionFromString(actionString) {
    let action = actionString.trim()
    if(action.length > 0) {
        let dateObject = getDateFromAction(action)
        let date = dateObject.date
        let dateString = dateObject.datestring
        if(dateString && date) {
            action = action.replace(dateString, '').trim()
        }
        let userObject = getUserIDFromAction(action)
        let user_id = userObject.user_id
        let matchingString = userObject.matchingString
        if(matchingString) {
            action = action.replace(userObject.matchingString, '').trim()
        }
        let action_id = getActionIDFromAction(action) 
        let notes = getNotesFromAction(action)
        let action_row = [
            action, date, user_id, action_id, notes
        ]
        // console.log('action row')
        // console.log(action_row)
        return action_row
    }
    return null
}
import { getCampaigns } from "@/services/Campaign";

export default {
    async setCampaigns (context) {
        console.log('setCampaigns action')
        const campaigns = await getCampaigns()
        context.commit('setCampaigns', campaigns)
    }, 
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
}
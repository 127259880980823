
import axios from 'axios';
import { BASE_URL } from './api';

export async function getBusinessTypes(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/business_types/get_business_types/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function createNewBusinessType(data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/business_types/create_business_type/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function deleteBusinessType(businessTypeID, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/business_types/${businessTypeID}/delete_business_type/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function updateBusinessType(businessTypeID, data, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/business_types/${businessTypeID}/edit_business_type/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
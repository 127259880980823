<template>
    <base-dblclick-textarea
        model-type="company"
        :model-id="company.id"
        field-name="client_update_notes"
        :field-value="company.client_update_notes"
        @refetch="fetchNewCompanies"
    ></base-dblclick-textarea>
</template>

<script>
export default {
    name: "client-update-notes-cell"
}

</script>

<script setup>
import {ref,  defineProps, defineEmits } from "vue";

const isEditable = ref(false)

const props = defineProps({
    company: Object
})


const emit = defineEmits(['refetch-companies'])

function fetchNewCompanies () {
    emit('refetch-companies')
}

function toggleEditable () {
    isEditable.value = !isEditable.value
}


</script>
<template>
    <div class="flex flex-col space-y-0.5">
        <CompanyNameCell :company="company"  @refetch-companies="fetchNewCompanies" />
        <DBACell :company="company" @refetch-companies="fetchNewCompanies" />
        <ParentCompanyCell :company="company" @refetch-companies="fetchNewCompanies" />
        <InvestorsCell :company="company" @refetch-companies="fetchNewCompanies" />
        <div>
            <base-pill>Category: </base-pill> <CategoryCell class="inline" :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
        <base-pill>Notes: </base-pill>
        <AcctMgrResearchNotesCell :company="company" @refetch-companies="fetchNewCompanies"  class="inline"/>
        <DescriptionCell :company="company" @refetch-companies="fetchNewCompanies"/>
        <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>

</template>

<script>
    export default {
        name: "name-dba-pc-inv-category-notes-description-url-cell"
    }
</script>

<script setup>
import CompanyNameCell from '../company-cell-components/CompanyNameCell.vue'
import DBACell from '../company-cell-components/DBACell.vue';
import DescriptionCell from '../company-cell-components/DescriptionCell.vue';
import ParentCompanyCell from '../company-cell-components/ParentCompanyCell.vue';
import InvestorsCell from '../company-cell-components/InvestorsCell.vue';
import AcctMgrResearchNotesCell from '../company-cell-components/AcctMgrResearchNotesCell.vue';
import CategoryCell from '../company-cell-components/CategoryCell.vue';
import WebsiteUrlCell from '@/components/company-cell-components/WebsiteUrlCell.vue';
import {ref, computed, defineEmits, defineProps} from "vue"

    const props = defineProps({
        company: Object,
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }


</script>
<template>
    <th class="bg-gray-100 border-t-2 border-b border-r border-gray-300 h-full text-xs sticky -top-[1px] first-of-type:border-t-0 first-of-type:border-r-0 first-of-type:bg-gray-200 last-of-type:border-t-0 last-of-type:border-r-0 last-of-type:bg-gray-200">
      <slot></slot>
    </th>
  </template>
  
  <script>
  export default {
    name: "base-table-header",
    props: ['header', 'alignment'],
  }
  </script>
<template>
    <div
        @click="selectCompany"
        class="cursor-pointer flex justify-start w-full"
        @mouseenter="setRowStyleDark"
        @mouseleave="setRowStyleLight"
    >
        <div class="border-b border-l border-gray-300 px-2 py-1.5 w-[30%]"
            :style="rowStyle"
        >
            {{  companyId }}
        </div>
        <div class="border-b border-r border-gray-300 pr-2 py-1.5 w-[70%] whitespace-nowrap truncate"
                :style="rowStyle"
        >
            {{  companyName }}
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';

const props = defineProps(['rowData', 'index',])
const emit = defineEmits(['select-company'])


const rowStyle = computed(() => {
    if(props.rowData.isSelected) {
        return 'background-color: #EEF2FF;'
    } else {
        return 'background-color: #FFF;'
    }
})

const companyId = computed(() => {
    return props.rowData ? props.rowData.data.id : null
})

const companyName = computed(() => {
    return props.rowData ? props.rowData.data.company_name : null
})


function selectCompany() {
    emit('select-company', props.rowData)
}

</script>
<template>
    <base-dblclick-textarea
        model-type="contactCompanyRelationship"
        :model-id="companyContact.id"
        field-name="contact_notes"
        :field-value="companyContact.contact_notes"
        @refetch="fetchNewCompanies"
    >
        <slot name="icon"></slot>
    </base-dblclick-textarea>
</template>

<script>
  export default {
      name: "company-contact-business-notes-cell"
  }

</script>

<script setup>
import {defineProps, defineEmits } from "vue";

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

//   function toggleEditable () {
//       isEditable.value = !isEditable.value
//   }

//   const contactNotes = computed(() => {
//     return props.contactCompany.contact_notes ? props.contactCompany.contact_notes : "--"
//   })

//   async function handleInputChange(e) {
//         let newValue = e.target.value
//         const targetField = "contact_notes"
//         let updatedData = {}
//         updatedData[targetField] = newValue
//         const data = JSON.stringify(updatedData);
//         console.log('data', data)
//         let token = store.state.auth.token
//         console.log('new name', newValue)
//         await updateContactCompanyRelationship(props.contactCompany.id, data, token)
//         emit('refetch-companies')
//         isEditable.value = false

//     }

</script>
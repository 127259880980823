<template>
    <div class="text-dark-primary flex flex-col">
        <p class="underline">
            Bulk Operations Options
        </p>
        <div class="flex flex-col items-start ml-1 mb-3">
            <div class="mt-1">
                <input type="checkbox" id="checkbox" class="align-middle" :checked="showBulkOperationsPane" @change="toggleShowBulkOperationsPane"/>
                <span class="ml-1 mr-2">Bulk Operations Pane</span>
            </div>
        </div>

    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
    module: String
});

const showBulkOperationsPane = computed(() => store.getters[`${props.module}/showBulkOperationsPane`])


const toggleShowBulkOperationsPane = () => {
    if(showBulkOperationsPane.value) {
        store.dispatch(`${props.module}/setShowBulkOperationsPaneFalse`)
    } else {
        store.dispatch(`${props.module}/setShowBulkOperationsPaneTrue`)
    }
}
</script>
<template>
    <div>
        <LocationCell :company="company" />
        <ZiLocationCell :company="company" />
        <TransferZiBasicCell :company="company" :permissions="permissions" @refetch-companies="fetchNewCompanies"/>
    </div>
</template>

<script>
    export default {
        name: "zi-location-location-transfer-zi-cell"
    }
</script>

<script setup>
import ZiLocationCell from '../company-cell-components/ZiLocationCell.vue';
import LocationCell from '../company-cell-components/LocationCell.vue';
import TransferZiBasicCell from '../company-cell-components/TransferZiBasicCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object,
        permissions: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }


</script>
<template>
    <div class="w-fit bg-white rounded-md">
        <div v-if="visibleBulkActionMenu">
            <div class="flex items-center space-between p-1.5 space-x-2 border-b bg-gray-50 border-gray-300 rounded-t-md">
                <div class="w-full">
                    <button
                        @click="hideBulkActionMenu"
                        class="rounded-full p-1 hover:bg-gray-300"
                    >
                        <BackArrowSVG />
                    </button>
                </div>
                <div class="text-[0.8rem] font-semibold flex w-full justify-center items-center whitespace-nowrap">
                    Bulk {{ bulkAction.label }}
                </div>
                <div class="w-full"></div>
            </div>
            <!-- <div v-if="selectedResults.length > 0"> -->
            <div>
                <slot name="bulk-operations"
                    :selectedResults="selectedResults"
                    :bulkAction="bulkAction"
                    :events="{ hideBulkActionMenu }"
                >
            
                </slot>
                <!-- <div>
                    <button
                        @click="close"
                    >
                        Close
                    </button>
                </div> -->
            </div>
            <!-- <div v-else class="p-2">
               Please select one or more rows to perform a bulk operation.
            </div> -->
        </div>
        <div v-else
            :class="bulkOperationsClass"
        >
            <BulkOperationButton
                v-for="(bulkOperation, key, index) in bulkOperations"
                :key="key"
                :index="index"
                :starting-index="0"
                :ending-index="Object.keys(bulkOperations).length - 1"
                :bulk-operation="bulkOperation"
                @show-bulk-action-menu="showBulkActionMenu"
            >
            </BulkOperationButton>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, reactive, defineProps, defineEmits, computed } from 'vue';
import BackArrowSVG from '@/components/svg-components/BackArrowSVG.vue';
import BulkOperationButton from './BulkOperationButton.vue';

const props = defineProps([
    'selectedResults', 
    'bulkOperations'
])

const emit = defineEmits(['close'])
const visibleBulkActionMenu = ref(false)
const bulkAction = reactive({
    label: null,
    message: null,
    modelPath: null,
    endpoint: null,
    options: null,
    data: null,
})

function showBulkActionMenu(bulkOperation) {
    visibleBulkActionMenu.value = true
    bulkAction.label = bulkOperation.label
    bulkAction.message = bulkOperation.message
    bulkAction.modelPath = bulkOperation.modelPath
    bulkAction.endpoint = bulkOperation.endpoint
    bulkAction.filters = bulkOperation.filters
}

function hideBulkActionMenu() {
    console.log('hideBulkActionMenu')
    visibleBulkActionMenu.value = false
    bulkAction.value = null
}

const bulkOperationsClass = computed(() => {
    const bulkOperationsLength = Object.keys(props.bulkOperations).length
    // console.log('bulk ops length')
    // console.log(bulkOperationsLength)
    switch (bulkOperationsLength) {
        case 0:
            return 'grid grid-cols-1';   
        case 1:
            return 'grid grid-cols-1';
        case 2:
            return 'grid grid-cols-1 sm:grid-cols-2';
        case 3:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3';
        case 4:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4';
        case 5:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5';
        case 6:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6';
        case 7:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-7';
        case 8:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-8';
        case 9:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-9';
        default:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-10';
    }
})

</script>
<template>
   <div>
    <base-table-condensed class="w-full">
      <template #table-header>
        <template v-if="userPermissions.isManagement || userPermissions.isDev || userPermissions.isSuperuser || userPermissions.isAccountManager">
          <base-table-header
            :key="header"
            :header="header"
            v-for="header in tableHeaders"
            class="border-b border-b-gray-400 overflow-y-auto sticky top-[55px]"
          >
            {{ header }}
          </base-table-header>
        </template>
        <template v-else>
          <base-table-header
            :key="header"
            :header="header"
            v-for="header in modifiedHeadersForNonAdmin"
            class="border-b border-b-gray-400 overflow-y-auto sticky top-[55px]"
          >
            {{ header }}
          </base-table-header>
        </template>
      </template>
      <template #table-body>
        <template v-for="timecard in content" :key="timecard.id">
          <TimecardsRow
          :timecard="timecard"
          :isTimecardUser="isUserInTimecardsGroup(timecard)"
          v-on:open-dialogue="openDialogue"
          v-on:reload="reload"
          v-on:openTimecard="openTimecard"
          v-on:editTimecard="editTimecard"
          > 
          </TimecardsRow>
        </template>
      </template>

      
    </base-table-condensed>
  </div> 
</template>

<script setup>
import { defineProps, defineEmits, ref, shallowReactive } from "vue";
import { useStore } from 'vuex';
import TimecardsRow from "./TimecardsRow.vue";

const props = defineProps({
  content: Array,
  tableHeaders: Array,
  timecardUsers: Array
}); 

const store = useStore();
const modifiedHeadersForNonAdmin = ref(props.tableHeaders)

const emit = defineEmits(['reload', 'openTimecard', 'open-dialogue', 'editTimecard']);

function reload() {
    emit('reload');
}

const userPermissions = shallowReactive({
  isManagement: null,
  isHourly: null,
  isSuperuser: null,
  isDev: null,
  isAccountManager: null,
});

const setUserPermissions = function () {
  let userPermissionsStore = store.getters['auth/showUserPermissions']
  userPermissions.isManagement = userPermissionsStore.isManagement
  userPermissions.isHourly = userPermissionsStore.isHourly
  userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
  userPermissions.isDev =  userPermissionsStore.isDev
  userPermissions.isAccountManager = userPermissionsStore.isAccountManager
}
setUserPermissions();

function openTimecard(timecard) {
  emit('openTimecard', timecard)
}

function editTimecard(timecard) {
  emit('editTimecard', timecard);
}

function openDialogue(header, body) {
  emit('open-dialogue', header, body);
}

function isUserInTimecardsGroup(timecard) {
  return props.timecardUsers.includes(timecard.user.id);
}
</script>
<template>
    <div class="pl-1.5 pt-0.5 grid grid-cols-2 gap-2 w-full">
      <div class="w-full">
        <div class="mb-1.5">
          <img id="company" src="@/assets/images/icons8-company-24.png" alt="company" class="w-[24px] inline pr-1"/> {{company.id}} | {{ company.company_name }} | {{ company.website_url }}
        </div>
        <div class="mb-1.5">
            <base-pill>Address:</base-pill><LocationCell class="inline" :company="company" @refetch-companies="fetchNewContacts" />
        </div>
        <div class="mb-1.5">
            <ParentCompanyCell class="inline" :company="company" @refetch-companies="fetchNewContacts" />
        </div>
        <div class="mb-1.5">
            <InvestorsCell class="inline" :company="company" @refetch-companies="fetchNewContacts" />
        </div>
      </div>
      <div class="w-full flex flex-col">
        <div class="w-full">
          <base-pill>Rev / HC:</base-pill><RevenueHeadcountCell class="inline" :company="company" @refetch-companies="fetchNewContacts" />
        </div>
        <div class="w-full my-1.5">
            <base-pill>Campaign:</base-pill> {{ companyCampaign }}
        </div>
        <div class="w-full my-1.5">
          <base-pill>Acct. Mgr. Research Notes:</base-pill><AcctMgrResearchNotesCell class="inline" :company="company" @refetch-companies="fetchNewContacts" />
        </div>
        <div class="w-full flex items-center h-full">
          <OutreachNotesCell class="inline" :company="company" @refetch-companies="fetchNewContacts" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'contact-personal-info',
      data: () => ({
      }),
      methods: {
      }
    }
  
  </script>
  
  
  <script setup>

  import { ref, computed, defineProps,defineEmits } from 'vue';
  import LocationCell from '@/components/company-cell-components/LocationCell.vue';
  import ParentCompanyCell from '@/components/company-cell-components/ParentCompanyCell.vue';
  import InvestorsCell from '@/components/company-cell-components/InvestorsCell.vue';
  import RevenueHeadcountCell from '@/components/company-cell-components/RevenueHeadcountCell.vue';
  import OutreachNotesCell from "@/components/company-cell-components/OutreachNotesCell.vue";
  import AcctMgrResearchNotesCell from '@/components/company-cell-components/AcctMgrResearchNotesCell.vue';
  
  
      const emit = defineEmits(['refetch-contacts'])
  
      const fetchNewContacts = () => {
        emit('refetch-contacts')
      }
  
      const props = defineProps({
          company: Object
      })

      const companyCampaign = computed(()=> {
        return props.company.campaign ? props.company.campaign.campaign_name : '--'
      })
  
  </script>
// import Client from './api';
import axios from "axios";
import { BASE_URL } from "./api";

export async function getProjectAssignments(payload) {
  // console.log('getting projects', payload.endpoint)
  return axios({
    method: "get",
    url: `${BASE_URL}/project_assignments${payload.endpoint}`,
    headers: {
      'Authorization': `Token ${payload.token}`,
    },
  })
    .then((response) => {
      // console.log('Finished Running: getCompanies async call', response);
      return {
        'projectAssignments': response.data,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAllProjectAssignments(payload) {
  // console.log('getting projects', payload.endpoint)
  return axios({
    method: "get",
    url: `${BASE_URL}/project_assignments/`,
    headers: {
      'Authorization': `Token ${payload.token}`,
    },
  })
    .then((response) => {
      // console.log('Finished Running: getCompanies async call', response);
      return {
        'projectAssignments': response.data,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAllResearchers(payload) {
  // console.log('getting all researchers')
  return axios({
    method: "get",
    url: `${BASE_URL}/list_users`,
    headers: {
      'Authorization': `Token ${payload.token}`,
    },
  })
    .then((response) => {
      // console.log('Finished Running: getCompanies async call', response);
      return {
        'researchers': response.data,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getAllResearchProjects(payload) {
  // console.log('getting all research projects')
  return axios({
    method: "get",
    url: `${BASE_URL}/research_projects/get_active_research_projects_simple/`,
    headers: {
      'Authorization': `Token ${payload.token}`,
    },
  })
    .then((response) => {
      // console.log('Finished Running: getCompanies async call', response);
      return {
        'researchProjects': response.data,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function createProjectAssignment(formData, payload) {
  // console.log(formData)
  // console.log(payload)
  return axios({
    method: 'post',
    url: `${BASE_URL}/project_assignments/create_project_assignment/`,
    headers: { 
      'Authorization': `Token ${payload.token}`, 
      'Content-Type': 'application/json'
    },
    data: formData
    })
      .then((response) => {
          return response
      }).catch((error) => {
          throw error
      })
}

export async function archiveProjectAssignment(projectAssignmentID, formData, payload) {
  // console.log(formData)
  // console.log(payload)
  return axios({
      method: 'put',
      url: `${BASE_URL}/project_assignments/${projectAssignmentID}/`,
      headers: { 
        'Authorization': `Token ${payload.token}`, 
        'Content-Type': 'application/json'
      },
      data: formData
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

export async function editProjectAssignment(projectAssignmentID, formData, payload) {
  // console.log(formData)
  // console.log(payload)
  return axios({
      method: 'put',
      url: `${BASE_URL}/project_assignments/${projectAssignmentID}/edit_project_assignment/`,
      headers: { 
        'Authorization': `Token ${payload.token}`, 
        'Content-Type': 'application/json'
      },
      data: formData
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

<template>
    <div class="flex flex-col items-center">
        <CRMNextActionDueDateCell class="mb-2" :company="company" @refetch-companies="fetchNewCompanies"/>
        <MarkCRMNextActionDueDateButtonsCell :company="company" @refetch-companies="fetchNewCompanies" />
    </div>

</template>

<script setup>
import CRMNextActionDueDateCell from "../company-cell-components/CRMNextActionDueDateCell.vue";
import MarkCRMNextActionDueDateButtonsCell from "../company-cell-components/MarkCRMNextActionDueDateButtonsCell.vue";
import {defineEmits, defineProps} from "vue"

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }


</script>
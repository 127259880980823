<template>
  <div class="w-full h-full">
    <div class="flex flex-row justify-start">
      <div class="text-base items-end ml-2 mb-2 my-auto mt-[10px]">
        Previous Campaign
      </div>
    </div>
    <div v-if="!noPreviousCampaigns" class="mt-3">
      <div class="w-full overflow-auto overscroll-none justify-center">
        <!-- <div v-for="history in previousCampaigns" :key="JSON.stringify(history)" class="w-[98%] flex flex-row justify-between py-3 mx-auto border-b border-dark-secondary text-lg" nowrap>
          <div class="ml-3">
            {{ parseDate(history.created_at) }} | Campaign: {{ history.campaign ? history.campaign.campaign_name : "None" }} | Tier: {{ history.tier ? history.tier : "None"}}
          </div>
        </div> -->
        <table class="w-full">
          <thead>
            <tr class="bg-gray-100 border drop-shadow-md h-full w-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5">
              <th class="border pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top">
                Date/Time
              </th>
              <th class="border pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top">
                Campaign
              </th>
              <th class="border pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top">
                Tier
              </th>
              <th class="border pt-1 pr-3 pb-2 pl-2 text-[0.8rem] align-top">
                User
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(history, index) in previousCampaigns" :key="JSON.stringify(history)">
              <tr :class="{'bg-gray-100': index % 2}">
                <base-table-cell>{{ parseDate(history.created_at) }}</base-table-cell>
                <base-table-cell>{{ history.campaign ? history.campaign.campaign_name : "None" }}</base-table-cell>
                <base-table-cell>{{ history.tier ? history.tier : "None"}}</base-table-cell>
                <base-table-cell>{{ history.user ? `${history.user.first_name} ${history.user.last_name}`  : "None"}}</base-table-cell>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="noPreviousCampaigns" class="flex w-full h-[40vh] items-center">
      <div class="text-left mx-auto my-auto text-red-500 text-lg font-bold">
        No Campaign History
      </div>
    </div>
    
  </div>
</template>

<script setup>
  import { ref, defineEmits, defineProps, onMounted, watch} from 'vue';
  
  const props = defineProps({
    company: Object,
    previousCampaigns: Array
  })
  
  const previousCampaigns = ref([])
  const noPreviousCampaigns = ref(false)

  function parseDate(datestring) {
    const date = new Date(datestring)
    const formattedDate = date.toLocaleString()
    return formattedDate;
  }

  watch(props, 
    (newValue, oldValue) => {
      previousCampaigns.value = props.previousCampaigns
      if (previousCampaigns.value.length != 0) {
        noPreviousCampaigns.value = false;
      }
    })

  onMounted(() => {
    previousCampaigns.value = props.previousCampaigns
    // console.log(props.previousCampaigns)
    if (previousCampaigns.value.length == 0) {
      noPreviousCampaigns.value = true;
    }
  });
</script>
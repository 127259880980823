<template>
  <base-dialog-box @close="closeDeleteModal">
    <template #header>
      <h2>Delete Client</h2>
      <ClientCloseButton class="mb-2" @click="closeDeleteModal"> X </ClientCloseButton>
    </template>
    <template #body>
      <h2 class="pb-4 text-left">
        Are you sure you want to delete
        <span class="font-bold">{{ client.client_name }}</span
        >?
      </h2>
      <div class="pb-4" v-if="client.campaigns.length != 0">
        <h2 class="pb-4 text-left">
          The following campaign(s) are associated with {{ client.client_name }}
        </h2>
        <ul
          class="list-disc pl-3"
          v-for="campaign in client.campaigns"
          :key="campaign.id"
        >
          <li class="text-left">
            {{ campaign.campaign_name }}
          </li>
        </ul>
      </div>
      <div v-else class="pb-4">
        <h2>No campaigns are associated with {{ client.client_name }}</h2>
      </div>
      <div class="flex justify-center">
        <base-button-affirmative
          type="button"
          class="mx-1"
          @click="deleteSelectedClient"
        >
          Delete
        </base-button-affirmative>
        <base-button-negative type="button" class="mx-1" @click="closeDeleteModal">
          Cancel
        </base-button-negative>
      </div>
    </template>
  </base-dialog-box>
</template>

<script setup>

// imports

import { defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { deleteClient } from "../../services/Client.js";

import ClientCloseButton from "./UI/ClientCloseButton.vue";

// constants/variables

const store = useStore();

const props = defineProps({
  client: Object,
  id: Number,
});
const emit = defineEmits(["close-delete-modal"]);

// async functions

async function deleteSelectedClient() {
  let payload = {
    id: props.client.id,
    token: store.state.auth.token,
  };
  const result = await deleteClient(payload);
  if (result.status == 200) {
    alert("Successfully deleted Client");
  } else if (result.status == 403) {
    alert("You do not have permission to delete Clients");
  } else {
    alert("Failed to delete Client");
  }
  emit("close-delete-modal");
}

// functions

function closeDeleteModal() {
  emit("close-delete-modal");
}

</script>

<template>
    <div>
        {{ clientName }}
    </div>
</template>

<script>
    export default {
        name: "client-cell"
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const clientName = computed(() => {
        if(props.company.campaign){
            return props.company.campaign.client ? props.company.campaign.client.client_name : "--"
        } else {
            return '--'
        }
    })

</script>
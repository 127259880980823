<template>
    <base-table-row>
        <base-table-cell-small>
            {{ query.job_title }} 
        </base-table-cell-small>
        <base-table-cell-small :class="statusCellFormatting">
            {{ query.status }} <span v-if="timeDelta">({{ timeDelta }} s)</span>
        </base-table-cell-small>
        <base-table-cell-small>
            {{ formatDateTime(query.created_at) }}    
        </base-table-cell-small>
    </base-table-row>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps, watch } from 'vue';
import { formatDateTime } from '@/composables/formatDateTime';

const props = defineProps(['query','statusCellFormatting'])

const timeDelta = ref(null)
const interval = ref(null)
const status = ref(props.query.status)

onBeforeUnmount(() => {
    if(interval.value) {
        clearInterval(interval.value)
    }
})

// watch(() => status, (newValue, oldValue) => {
//     console.log('query status changed', status)
//     if(newValue === 'running') {
//         console.log('switched to running, clearing interval', interval.value)
//         clearInterval(interval.value)
//         timeSinceCreated(props.query.created_at, status)
//     }
//     if(oldValue === 'running' && newValue !== 'running') {
//         console.log('finished running, clearing interval', interval.value)
//         clearInterval(interval.value)
//     }
// })

const returnTimeDelta = (createdAt) => {
    let timeCreated = new Date(createdAt)
    let currentDateTime = new Date(Date())
    return Math.round(Math.abs(timeCreated - currentDateTime) / 1000)
}

const timeSinceCreated = (createdAt, status) => {
    if(status === 'queued' || status === 'running'){
        if(interval.value) {
            clearInterval(interval.value)
        }
        interval.value = setInterval( function() {
            // console.log('interval', interval.value)
            timeDelta.value = returnTimeDelta(createdAt)
        }, 1000)
        return interval.value
    } else {
        clearInterval(interval.value)
        return null
    }
}

timeSinceCreated(props.query.created_at, props.query.status)

</script>
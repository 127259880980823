<template>
  <div>
    <div class="pt-2 ml-[15px]">
        <div
          class="flex flex-row justify-between items-center pb-1.5 border-b border-b-gray-400"
        >
          <h3 class="text-sm align-middle">Company Info:</h3>
        </div>
        <div class="flex mt-2 rounded-sm pb-1">
          <div
            class="text-sm text-dark-primary pr-4 pl-1 border-b border-l border-t border-gray-400 rounded-l-md py-1"
          >
            Source
          </div>
          <div class="w-1/4 border border-gray-400">
            <DevAdminListBox
              class="w-full h-full text-black"
              placeholder="------------"
              v-model="source"
              :options="sourceOptions"
              :multiple="false"
            />
          </div>

          <button
            @click="enrichWithZoomInfo"
            class="text-sm align-middle hover:bg-dark-primary hover:text-white pr-10 border-r pl-2 border-b border-t border-gray-400 rounded-r-md"
          >
            Get ZI IDs and ZI Basic for Companies in Source
          </button>
        </div>
      </div>
  </div>
</template>

<script setup>
  import { getSources } from "@/services/Source.js";
  import DevAdminRow from "../../admin_page/table/AdminRow.vue";
  import DevAdminListBox from "../../admin_page/UI/AdminListBox.vue";
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const source = ref("");
  const sourceOptions = ref([]);

  function enrichWithZoomInfo() {
    if (source.value !== "" && source.value !== "clear") {
      alert(source.value);
    }
  }

  async function setSourceOptions() {
    let token = store.state.auth.token;
    const result = await getSources(token);
    sourceOptions.value = [
      { value: "clear", label: "------------" },
      ...result.map((source) => {
        return {
          value: source.id,
          label: source.source_name,
        };
      }),
    ];
  }

  onMounted(() => {
    setSourceOptions();
  });
</script>
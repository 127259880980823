export default {
    setClients(state, clients) {
        console.log('setClients Mutation')
        state.clients = clients;
        console.log("clients list:", clients)
    },
    setIsLoadingTrue(state) {
        state.isLoading = true;
    },
    setIsLoadingFalse(state) {
        state.isLoading = false;
    },
    setNext(state, next) {
        state.next = next
    },
    setPrev(state, prev){
        state.prev = prev
    },
    setCount(state, count){
        state.count = count
    },
    setPage(state, page){
        state.page = page
    },
    setPageSize(state, pageSize){
        state.pageSize = pageSize
    }, 
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },

}
<template>
<base-card-select class="w-full">
    <div class="grid grid-cols-6 gap-1">
        <div class="flex flex-col">
            <label>Company ID</label>
            <input type="number" required class="p-1 border w-full" v-model="companyRelationshipData.company_id">
        </div>
        <div class="flex flex-col">
            <label>Title</label>
            <input type="text" required class="p-1 border w-full" v-model="companyRelationshipData.title">
        </div>
        <div class="flex flex-col">
            <label>Outreach Email</label>
            <input inputType="email" required class="p-1 border w-full" v-model="companyRelationshipData.email_address">
        </div>
        <div class="flex flex-col">
            <label>Outreach Phone</label>
            <input inputType="tel" required class="p-1 border w-full" v-model="companyRelationshipData.phone_number">
        </div>
        <div class="flex flex-col">
            <label>Is Primary</label>
            <select name="isPrimaryContact" v-model="companyRelationshipData.is_primary_contact" class="p-1 border w-full" id="">
                <option :value="true">True</option>
                <option :value="false" selected>False</option>
            </select>
        </div>
        <div class="flex flex-col">
            <label>Contact Notes</label>
            <textarea class="p-1 border w-full" v-model="companyRelationshipData.contact_notes"></textarea>
        </div>
        <div class="flex flex-row col-span-6 justify-end space-x-1 mt-2">
            <base-button-affirmative @click="addCompanyRelationship">Save</base-button-affirmative>
            <base-button-negative @click="closeForm">Cancel</base-button-negative>
        </div>
    </div>
</base-card-select>
</template>

<script>
export default {
    name: "company-relationship-form"
}
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import {createContactCompanyRelationship} from '@/services/Contact';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
    contact: Object
})

const companyRelationshipData = ref({
        email_address: null,
        contact_notes: null,
        title: null,
        phone_number: null,
        is_primary_contact: false,
        status: "active",
        contact_id: props.contact.id,
        company_id: null
    })


const emit = defineEmits(['refetch-contacts', 'close-form'])

function closeForm(){
    emit('close-form')
}

async function addCompanyRelationship(){
    const token = store.state.auth.token
    let errorMessage = "Error, missing "
    console.log(companyRelationshipData.value.contact_id)
    console.log(companyRelationshipData.value.company_id)
    const data = companyRelationshipData.value
    await createContactCompanyRelationship(data, token)
    emit('refetch-contacts')
    emit('close-form')
}

</script>
<template>
    <div class="h-[87%] py-1">
        <div v-if="containerType==='rightSideBar'" class="h-full">
            <div v-if="openDialogBox" class="break-normal text-left align-middle py-6 border-b border-gray-400 w-full">
                <div class=" break-normal text-sm leading-4 text-gray-900">{{ responseStatus }}</div>
                <div class="mt-2 break-normal text-xs text-gray-500">{{ responseMessage }}</div>
                <div class="mt-4 flex justiyf-center">
                    <base-button-affirmative type="button" @click="clearSideBar">Okay</base-button-affirmative>
                </div>
            </div>
            <div v-else class="h-full">
                <CreateSource class="mt-1 mb-2" 
                />
                <CompanyForm
                    @submit-form="createNewCompany"
                    @refetch-companies="fetchNewCompanies"
                    @close="close"
                    :campaigns="campaigns"
                    :sourceOwners="sourceOwners"
                    :formCleared="formCleared"
                    @set-form-cleared-false="setFormClearedFalse"
                />
            </div>
        </div>
        <div v-else class="h-full">
            <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
                <template #header>
                    <span>{{ responseStatus }}</span>
                </template>
                <template #body>
                    <span>{{ responseMessage }}</span>
                </template>
            </HeadlessDialogBox>
            <CreateSource class="mt-1 mb-2" />
            <CompanyForm
                @submit-form="createNewCompany"
                @refetch-companies="fetchNewCompanies"
                @close="close"
                :campaigns="campaigns"
                :sourceOwners="sourceOwners"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "create-company"
    }
</script>

<script setup>
import CompanyForm from './CompanyForm.vue';
import CreateSource from './CreateSource.vue';
import { ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { createCompany } from '@/services/Company';
import { getCampaigns } from '@/services/Campaign';
import { getSourceOwners } from '@/services/SourceOwner';
import HeadlessDialogBox from './HeadlessDialogBox.vue';

const store = useStore()
const campaigns = ref([])
const sourceOwners = ref([])

const openDialogBox = ref(false)
const responseStatus = ref("")
const responseMessage = ref("")

const formCleared = ref(false)

function setFormClearedFalse(){
    formCleared.value = false
}

const props = defineProps(['containerType'])
const emit = defineEmits(['close', 'refetch-companies'])

function closeDialogBox(){
    openDialogBox.value = false
    emit('refetch-companies')
    emit('close')
}

async function createNewCompany(data) {
    const token = store.state.auth.token
    const result = await createCompany(data, token)
    // console.log('result', result)
    if(result.status == 201) {
        responseStatus.value = "Success"
        responseMessage.value = `Company with name ${result.data.company_name} created successfully. `
        openDialogBox.value = true
    } else {
        responseStatus.value = "Error"
        responseMessage.value = `Error, company not created. ${result.response.data}`
        openDialogBox.value = true
    }
}

function clearSideBar() {
    emit('refetch-companies')
    openDialogBox.value = false
    formCleared.value = true
}

async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
}

async function loadSourceOwners(){
    const token = store.state.auth.token
    const result = await getSourceOwners(token)
    sourceOwners.value = result
}

function close() {
    emit('close')
}

function fetchNewCompanies() {
    emit('refetch-companies')
}

loadCampaigns()
loadSourceOwners()
</script>

<template>
    <!-- <td colspan="9" class="bg-red-500">Select</td> -->
    <td class="border-l border-b border-gray-300 pl-1 pr-0.5 text-xs">        
        <base-pill  class="bg-amber-300 border-none text-dark-primary p-0">Unsaved</base-pill>
    </td>
    <!-- <td class="p-1 text-xs align-top">

    </td> -->
    <td class="text-xs border-b border-r border-gray-300 pl-1">
        <select type="text" class="p-1.5  h-full w-full cursor-pointer" v-model="serviceRecordData.type" placeholder="Type">
            <option value="Billed Services" selected>Billed Services</option>
            <option value="PTO">PTO</option>
            <option value="Sick Leave">Sick Leave</option>
        </select>
    </td>
    <td class="text-xs border-b border-r border-gray-300 pl-1">
        <input type="text" class="p-1.5  h-full w-full cursor-pointer" v-model="serviceRecordData.title" placeholder="Title">
    </td>
    <td class="text-xs border-b border-r border-gray-300 p-0 pl-0.5">
        <textarea class="p-1.5 h-full w-full cursor-pointer" rows="1" v-model="serviceRecordData.description" placeholder="Description"></textarea>
    </td>
    <td class="text-xs border-b border-r border-gray-300 ">
        <input type="date" class="p-1.5  h-full w-full cursor-pointer" v-model="serviceRecordData.date">
    </td>
    <td class="text-xs border-b border-r border-gray-300 ">
        <input type="number" class="p-1.5  h-full w-full cursor-pointer" v-model="serviceRecordData.hours" placeholder="0.00">
    </td>
    <td class=" text-xs border-b border-r border-gray-300 p-2">
        --
    </td>
    <td class=" text-xs border-b border-r border-gray-300 p-2">
        --
    </td>
    <td
    class=" text-xs border-b border-r border-gray-300 p-2"
        >
        <select type="text" class="p-1.5  h-full w-full cursor-pointer" v-model="serviceRecordData.user_id" placeholder="User">
            <option v-for="option in userOptions.slice(1)" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
    </td>
    <td class="text-xs border-b border-r border-gray-300 pl-1">
        <select type="text" class="p-1.5  h-full w-full cursor-pointer" v-model="serviceRecordData.engagement_id" placeholder="Engagement">
            <option v-for="option in engagementOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
    </td>
    <td
    class=" border-gray-300 text-xs border-b border-r p-2"
        >
    </td>
    <td class="py-1.5 px-2.5 text-xs border-gray-300 border-b border-r ">
        <div class="flex justify-between">
            <button
            @click="addServiceRecord"
            class="bg-accent-primary text-white rounded-md py-0.5 px-1 hover:shadow-md"
        >
            ✓
        </button>
        <button
            class="bg-dark-secondary text-white rounded-md py-0.5 px-1 hover:shadow-md"
            @click="close"
        >
            X    
        </button>
        </div>
    </td>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, computed } from 'vue';
import { useStore } from 'vuex';
import { createServiceRecord } from '@/services/ServiceRecord';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const  props = defineProps(['index', 'engagementOptions', 'userOptions'])

const user = computed(() => store.getters['auth/showUser'])

const userName = computed(()=> {
    return `${user.value.first_name} ${user.value.last_name}`
})

const userId = computed(() => {
    return user.value.id
})
const serviceRecordData = reactive({
    type: "Billed Services",
    title: null,
    hours: null,
    date: null,
    description: null,
    engagement_id: null,
    user_id: userId.value,
})

const emit = defineEmits(['close-form', 'save-form', 'refetch-results'])

function close() {
    emit('close-form', props.index)

}

async function addServiceRecord() {
    const token = store.state.auth.token
    const data = serviceRecordData
    const result = await createServiceRecord(data, token)
    if(result.status == 200) {
        $toast.open({
            message: `Successfully Created New Service Record.`,
            type: 'success',
            duration: 5000
        })
        emit('refetch-results')
        emit('close-form', props.index)
    } else {
        $toast.open({
            message: `Error Creating Service Record.`,
            type: 'error',
            duration: 20000
        })
    }


}
</script>
<template>
    <base-card-select :isSelected="isSelected" class="w-full border border-dark-primary">
        <div class="flex flex-row">
            <div class="flex-none pt-[1px] items-center">
                <input class="mx-1" type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
            </div>
            <div class="flex-auto">
                <section class="flex justify-between items-center">
                    <div class="flex justify-start">
                        <div class="mr-2">
                            <span class="font-bold">{{ contactName }}</span> <span class="">{{ companyRelationship.title ? ` is ${companyRelationship.title} at ` : 'works for'  }}</span>  <span class="font-bold">{{ companyRelationship.company.company_name }}</span>
                        </div>
                        <div v-if="companyRelationship.is_primary_contact">
                            <base-pill class="bg-accent-primary text-white border-accent-secondary">Primary Contact</base-pill>
                        </div>
                        <div v-if="companyRelationship.is_verified">
                            <base-pill class="bg-accent-secondary text-white border-dark-secondary">Verified Email</base-pill>
                        </div>
                    </div>
                    <div class="space-x-1">
                        <CompanyContactMarkEmailVerifiedCell class="inline"  :company-contact="companyRelationship" @refetch-companies="fetchNewContacts"  />
                        <base-button-negative @click="removeCompanyContact(contactName, companyRelationship)">Archive</base-button-negative>
                    </div>
                </section>
                <section class="flex flex-col justify-start items-start py-2 text-[0.8rem]  border-b border-gray-400">
                    <ContactDetailContactInfo :companyRelationship="companyRelationship" @refetch-contacts="fetchNewContacts" />
                </section>
                <section class="flex flex-col justify-start items-start py-2 text-[0.8rem]">
                    <ContactDetailCompanyInfo :company="companyRelationship.company" @refetch-contacts="fetchNewContacts" />
                </section>
            </div>
        </div>
    </base-card-select>
</template>

<script>
  export default {
    name: 'contact-detail-card',
    data: () => ({
    }),
    methods: {
    }
  }
</script>

<script setup>
import { ref, computed, defineProps, defineEmits, watch, onMounted } from "vue";
import CompanyContactTitleCell from "../company-cell-components/CompanyContactTitleCell.vue";
import ContactDetailCompanyInfo from "./contact-card-components/ContactDetailCompanyInfo.vue";
import ContactDetailContactInfo from "./contact-card-components/ContactDetailContactInfo.vue";
import CompanyContactMarkEmailVerifiedCell from "../company-cell-components/CompanyContactMarkEmailVerifiedCell.vue";
import { useStore } from "vuex";
import {updateContactCompanyRelationship} from '@/services/Contact.js'

const props = defineProps(['contactName','companyRelationship', 'selectedContactId', 'changedContactFilters', 'clearedSelectedContacts', 'clearedSelectedContactsOnPage', 'selectAll', 'selectAllOnPage', 'permissions', 'selectedContactsOnPageMap'])

const emit = defineEmits(['refetch-contacts', 'select-contact', 'deselect-contact'])

const store = useStore()

const fetchNewContacts = () => {
    emit('refetch-contacts')
}

const isSelected = ref(false)

// onMounted(() => {
//     if(props.contact.id == props.selectedContactId) {
//         isSelected.value = true
//     } else {
//         isSelected.value = false
//     }
// })

// watch(() => props.selectedContactId, () => {
//     if(props.contact.id == props.selectedContactId) {
//         isSelected.value = true
//     } else {
//         isSelected.value = false
//     }
// })

const toggleSelected = () => {
    if(isSelected.value) {
        isSelected.value = false
        emit('deselect-contact')
    } else {
        isSelected.value = true
        emit('select-contact')
    }
}

async function removeCompanyContact(contactName, contactRelationship){
    const confirm_message = `You are about to archive ${contactName}, ${contactRelationship.title} at ${contactRelationship.company.company_name}.\n Do you wish to proceed?`
    // console.log('removeCompanyContact ran')
    if(window.confirm(confirm_message)){
        const token = store.state.auth.token
        const data = JSON.stringify({
            "status": "inactive"
        })
        await updateContactCompanyRelationship(contactRelationship.id, data, token)
        emit('refetch-contacts')
    }

}

// async function removeCompanyContact(contactRelationship){
//         const token = store.state.auth.token
//         const data = JSON.stringify({
//             "status": "inactive"
//         })
//         await updateContactCompanyRelationship(contactRelationship.id, data, token)
//         emit('refetch-contacts')
//     }

const companyCampaign = computed(()=> {
    return props.company.campaign ? props.company.campaign : '--'
})


</script>
<template>
  <div>
    <base-button @click="showModal">Description</base-button>
    <InfoModal
    :is-modal-visible="isModalVisible"
        @close="closeModal"
    >
      <template #header>
        Company Description <CompanyDescriptionAggregator :company="company" @refetch-companies="fetchNewCompanies" />
      </template>
      <template #body>
        <base-dblclick-textarea
            model-type="company"
            :model-id="company.id"
            field-name="description"
            :field-value="company.description"
            @refetch="fetchNewCompanies"
            @close="closeModal"
        >
        </base-dblclick-textarea>
      </template>
    </InfoModal>
  </div>

</template>

<script>
    export default {
        name: 'description-cell'
    }
</script>

<script setup>
import {ref, defineProps, defineEmits } from "vue";
import InfoModal from "../InfoModal.vue"
import CompanyDescriptionAggregator from "../company/CompanyDescriptionAggregator.vue";

  const isModalVisible = ref(false)

  const props = defineProps({
      company: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

  function showModal () {
    isModalVisible.value = true
  }

  function closeModal () {
    isModalVisible.value = false
  }

</script>
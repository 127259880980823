<template>
    <div>
         <ClientFeedbackCell :company="company" @refetch-companies="fetchNewCompanies"/>
         <base-pill> Notes:</base-pill> <ClientMeetingNotesCell :company="company" @refetch-companies="fetchNewCompanies" class="inline" />
    </div>
</template>

<script>
    export default {
        name: "client-feedback-client-meeting-notes-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import ClientFeedbackCell from '../company-cell-components/ClientFeedbackCell.vue';
import ClientMeetingNotesCell from '../company-cell-components/ClientMeetingNotesCell.vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
<template>
    <base-table-row :isSelected="isSelected">
      <base-table-cell-condensed>
        <input type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
      </base-table-cell-condensed>
      <base-table-cell-condensed>
        <div @click="showForm" class="text-accent-primary hover:text-dark-primary hover:cursor-pointer hover:underline">
          {{ company.id }}
          {{ company.status }}
        </div>
        <base-modal :is-form-visible="isFormVisible" @close="closeForm">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="company"
              :campaigns="campaigns"
              @close="closeForm"
              @refetch-companies="fetchNewCompanies"
            />
          </template>
        </base-modal>
      </base-table-cell-condensed>
      <base-table-cell-condensed > 
        <CompanyNameCell :company="company" @refetch-companies="fetchNewCompanies"
        />
      </base-table-cell-condensed>
      <base-table-cell-condensed > 
        <CampaignTierCell :company="company" @refetch-companies="fetchNewCompanies"
        />
      </base-table-cell-condensed>
      <base-table-cell-condensed>
            <CageCodeCell :company="company"></CageCodeCell>
        </base-table-cell-condensed>
      <base-table-cell-condensed class="max-w-md">
        <RawAddressCell class="truncate ..."
          :company="company" @refetch-companies="fetchNewCompanies"
        />
      </base-table-cell-condensed>
      <base-table-cell-condensed>
        <WebsiteUrlCell :company="company" @refetch-companies="fetchNewCompanies"/>
      </base-table-cell-condensed>
      <base-table-cell-condensed>
        <RevenueHeadcountCell :company="company"/>
      </base-table-cell-condensed>
    <base-table-cell-condensed>
        <DLAGoverningSpecificationCell :governingSpecifications="governingSpecifications"></DLAGoverningSpecificationCell>
    </base-table-cell-condensed>
    <base-table-cell-condensed>
        <DLAGovernmentDesignationCell :governmentDesignations="governmentDesignations"></DLAGovernmentDesignationCell>
    </base-table-cell-condensed>
    </base-table-row>
  </template>
  
<script>
import EditCompany from './EditCompany.vue';
import CompanyNameCell from './company-cell-components/CompanyNameCell.vue';
import CageCodeCell from './company-cell-components/CageCodeCell.vue';
import RawAddressCell from './company-cell-components/RawAddressCell.vue';
import WebsiteUrlCell from './company-cell-components/WebsiteUrlCell.vue';
import RevenueHeadcountCell from './company-cell-components/RevenueHeadcountCell.vue';
import DLAGoverningSpecificationCell from './company-cell-components/DLAGoverningSpecificationCell.vue';
import DLAGovernmentDesignationCell from './company-cell-components/DLAGovernmentDesignationCell.vue';
import CampaignTierCell from './company-compound-cells/Campaign+TierCell.vue';
import { vetURL, promoteOrDemoteResearch, getZoomInfoId, clearZoomInfo, getZoomInfoBasic, transferZoomInfoBasic } from '@/services/Company';

  
export default {
    name: 'dla-company-row',
    props: ['company', 'campaigns', 'changedCompanyFilters', 'clearedSelectedCompanies', 'selectAll', 'selectAllOnPage', 'permissions', 'selectedCompaniesOnPageMap'],
    components: {
    EditCompany,
    CompanyNameCell,
    RawAddressCell,
    WebsiteUrlCell,
    RevenueHeadcountCell,
    CageCodeCell,
    DLAGoverningSpecificationCell,
    DLAGovernmentDesignationCell,
    CampaignTierCell
},
          data() {
              return {
                  isEditable: false,
                  isModalVisible: false,
                  isPopoverVisible: false,
                  isFormVisible: false,
                  reLoadFormData: true,
                  isSelected: this.selectAll,
                  showFullNotes: this.company.account_manager_research_notes && this.company.account_manager_research_notes.length < 50,
                  xPosition: 0,
                  yPosition: 0,
                  companyProductRowIds: [],
                  governmentDesignationIds: [],
                  governingSpecificationIds: [],
                  governingSpecifications: null,
                  governmentDesignations: null,
              }
          },
          mounted() {
            if(this.onPageAndSelected){
                this.isSelected = true
              }
              let governmentDesignationList = []
              this.companyProductRows.forEach(companyProductRow => {
                  let governmentDesignation = companyProductRow.government_designation
                  if(!this.governmentDesignationIds.includes(governmentDesignation.id)) {
                      governmentDesignationList.push(governmentDesignation)
                      this.governmentDesignationIds.push(governmentDesignation.id)
                  }
              })
              this.governmentDesignations = governmentDesignationList
              let governingSpecificationList = []
              this.governmentDesignations.forEach(governmentDesignation => {
                  let governingSpecification = governmentDesignation.governing_specification
                  if(!this.governingSpecificationIds.includes(governingSpecification.id)) {
                      governingSpecificationList.push(governingSpecification)
                      this.governingSpecificationIds.push(governingSpecification.id)
                  }
              })
              this.governingSpecifications = governingSpecificationList
          },
          methods: {
              toggleEditable() {
                  return this.isEditable = !this.isEditable
              },
              fetchNewCompanies() {
                  this.$emit('refetch-companies')
              },
              showModal() {
                  this.isModalVisible = true
              },
              closeModal() {
                  this.isModalVisible = false
              },
              showPopover() {
                this.isPopoverVisible = true;
                this.xPosition = this.$refs.companyName.getBoundingClientRect().right
                this.yPosition = this.$refs.companyName.getBoundingClientRect().top
              },
              hidePopover() {
                this.isPopoverVisible = false;
                this.xPosition = 0;
                this.yPosition = 0;
              },
              showForm() {
                  this.$emit('refetch-form-data')
                  this.isFormVisible = true
              },
              closeForm() {
                  this.isFormVisible = false
              },
              toggleShowNotes(){
                  this.showFullNotes = !this.showFullNotes
              },
              toggleSelected() {
                if(this.isSelected) {
                  this.isSelected = false
                  // console.log('company row - emitted remove-selected-company')
                  return this.$emit('remove-selected-company', this.company)
                } else if(!this.isSelected) {
                  this.isSelected = true
                  // console.log('company row - emitted add-selected-company')
                  return this.$emit('add-selected-company', this.company)
                }
              },
              resetSelected() {
                  this.isSelected = false
                  this.$emit('reset-cleared-selected-companies')
              },
              async vetURLAction(action) {
                  const token = this.$store.getters['auth/showToken']
                  await vetURL(this.company.id, action, token)
                  this.fetchNewCompanies()
              },
              async promoteOrDemote(action) {
                  const token = this.$store.getters['auth/showToken']
                  await promoteOrDemoteResearch(this.company.id, action, token)
                  this.fetchNewCompanies()
              },
              async getZiId() {
                  const token = this.$store.getters['auth/showToken']
                  await getZoomInfoId(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async clearZi() {
                  const token = this.$store.getters['auth/showToken']
                  await clearZoomInfo(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async getZiBasic() {
                  const token = this.$store.getters['auth/showToken']
                  await getZoomInfoBasic(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async transferZiBasic() {
                  const token = this.$store.getters['auth/showToken']
                  await transferZoomInfoBasic(this.company.id, token)
                  this.fetchNewCompanies() 
              }
          },
          watch: {
            changedCompanyFilters() {
                if(this.changedCompanyFilters){
                  this.isSelected = false
                  this.$emit('reset-changed-company-filters')
                }
                this.resetSelected()
            },
            clearedSelectedCompanies() {
              if(this.clearedSelectedCompanies){
                this.isSelected = false
                this.$emit('reset-cleared-selected-companies')
              }
              this.resetSelected()
            },
            clearedSelectedCompaniesOnPage() {
              if(this.clearedSelectedCompaniesOnPage){
                this.isSelected = false
                this.$emit('reset-cleared-selected-companies-on-page')
              }
              this.resetSelected()
            },
            selectAll() {
              if(this.selectAll){
                this.isSelected = true;
                console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
              }else if(!this.selectAll){
                this.isSelected = false;
                console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
              }
            },
            selectAllOnPage() {
              if(this.selectAllOnPage){
                this.isSelected = true;
                // console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
              }else if(!this.selectAllOnPage){
                this.isSelected = false;
                // console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
              }
            }
          },
          computed: {
              fullAddress(){
                  return `${this.company.address ? this.company.address : "None"} ${this.company.city ? this.company.city : "None"}, ${this.company.state ? this.company.state : "None"} ${this.company.country ? this.company.country : "None"}`
              },
              fullZiAddress() {
                  return `${this.company.zi_address ? this.company.zi_address : "None"} ${this.company.zi_city ? this.company.zi_city : "None"}, ${this.company.zi_state ? this.company.zi_state : "None"} ${this.company.zi_country ? this.company.zi_country : "None"}`
              },
              truncatedNotes(){
                  if(this.company.account_manager_research_notes && this.company.account_manager_research_notes.length > 50){
                      return this.company.account_manager_research_notes.substring(0,51)
                  } else if (this.company.account_manager_research_notes){
                      return this.company.account_manager_research_notes
                  } else {
                      return "None"
                  }
              },
              httpCompanyUrl(){
                  if(this.company.website_url.startsWith('http://') || this.company.website_url.startsWith('https://')){
                      return this.company.website_url
                  } else {
                      return `http://${this.company.website_url}`
                  }
              },
              ziRevenueDisplay(){
                if(this.company.zi_revenue){
                  let ziRevenue = this.company.zi_revenue / 1000
                  return ziRevenue.toFixed(2)
                } else {
                  return null
                }
              },
              onPageAndSelected(){
                if(this.selectedCompaniesOnPageMap.size > 0){
                  return this.selectedCompaniesOnPageMap.has(this.company.id)
                } else {
                  return false
                }
              },
              companyProductRows(){
                let companyProductRowsList = []
                this.company.dla_company.forEach(dlaCompany => {
                    let companyProductRows = dlaCompany.raw_company_product_rows;
                    companyProductRows.forEach(companyProductRow => {
                        // console.log(dlaCompany.id, companyProductRow)
                        if(!this.companyProductRowIds.includes(companyProductRow.id)) {
                            companyProductRowsList.push(companyProductRow);
                            this.companyProductRowIds.push(companyProductRow.id);
                        }
                    });
                })
                // console.log(companyProductRowsList)
                return companyProductRowsList
              },
            //   governmentDesignations(){
            //     let governmentDesignationList = []
            //     this.companyProductRows.forEach(companyProductRow => {
            //         let governmentDesignation = companyProductRow.government_designation
            //         if(!this.governmentDesignationIds.includes(governmentDesignation.id)) {
            //             governmentDesignationList.push(governmentDesignation)
            //             this.governmentDesignationIds.push(governmentDesignation.id)
            //         }
            //     })
            //     // console.log(governmentDesignationList)
            //     return governmentDesignationList
            // },
            //   governingSpecifications(){
            //     let governingSpecificationList = []
            //     this.governmentDesignations.forEach(governmentDesignation => {
            //         let governingSpecification = governmentDesignation.governing_specification
            //         if(!this.governingSpecificationIds.includes(governingSpecification.id)) {
            //             governingSpecificationList.push(governingSpecification)
            //             this.governingSpecificationIds.push(governingSpecification.id)
            //         }
            //     })
            //     // console.log('gov spec ids', this.governingSpecificationIds)
            //     // console.log('gov spec list', governingSpecificationList)
            //     return governingSpecificationList
            // }
          },
      }
  </script>
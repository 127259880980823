export default {
    showPageConfigs(state) {
        return state.pageConfigs
    },
    showSelectedPageConfig(state) {
        return state.selectedPageConfig
    },
    showSelectedPageConfigName(state) {
        if (state.selectedPageConfig) {
            return state.selectedPageConfig.configuration_name
        } else {
            return null
        }
    },
    showPageConfigLoading(state) {
        return state.pageConfigLoading
    }
}
<template>
    <div
        class="h-full flex flex-row items-center align-middle text-center whitespace-nowrap"
        >
        <input
            type="checkbox"
            id="checkbox"
            class="align-middle hover:cursor-pointer"
            :checked="selectAll"
            @change="toggleSelectAll"
        />
        <!-- <p class="align-middle">
            <span
            class="pl-1 align-middle  hover:text-accent-primary hover:cursor-pointer"
            @click="showModal"
            >{{ numSelectedCompanies }} Selected
            </span>
            <span class="align-middle"> | </span>
            <span
            class="hover:text-accent-primary hover:cursor-pointer align-middle"
            @click="resetSelectAllClearSelectedCompanies"
            >Clear All</span
            >
            <span class="align-middle"> (</span>
            <span class="align-middle"
            >{{ numSelectedCompaniesOnPage }} On Pg</span
            >
            <span class="align-middle"> | </span>
            <span class="align-middle"
            >{{ numSelectedCompaniesOffPage }} Off Pg</span
            >
            <span class="align-middle">) </span>
            <base-modal-small v-if="isModalVisible" @close="closeModal">
            <template v-slot:header> Selected Companies </template>
            <template v-slot:body>
                <SelectedCompaniesTable
                :selectedCompanies="selectedCompanies"
                />
            </template>
            </base-modal-small>
        </p> -->
        </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, computed } from 'vue';

const props = defineProps(['selectAll']);

const emit = defineEmits(['toggle-select-all'])


function toggleSelectAll() {
  emit('toggle-select-all')
}

</script>
<template>
    <div class="whitespace-nowrap">
        {{ targetDueDay }}
    </div>
</template>

<script>
export default {
    name: "engagement-target-invoice-day-cell"
}
</script>

<script setup>
import { computed, ref, defineProps } from 'vue';
import { formatDateWithWeekday } from '@/composables/formatDate';

const props = defineProps({
    engagement: Object
})

const targetDueDay = computed(() => {
    if(props.engagement.target_invoice_day){
        const dueDay = new Date(props.engagement.target_invoice_day)
        dueDay.setDate(dueDay.getDate() + 1)
        return formatDateWithWeekday(dueDay)
    } else {
        return "--"
    }
})

// const dateStyling = computed(() => {
//     if(props.engagement.target_invoice_day_due_status === "good") {
//         return "bg-green-200"
//     } else if(props.engagement.target_invoice_day_due_status === "overdue") {
//         return "bg-red-200"
//     } else if(props.engagement.target_invoice_day_due_status === "due_soon")  {
//         return "bg-yellow-200"
//     }
//     return ""
// })




</script>
<template>
  <div class="print:hidden mx-1" v-if="showFilterPane">
    <transition-group
      tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" key="company-info">
        <div
          class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400"
        >
          <h3 class="text-sm">Approval Info:</h3>
          <base-button-affirmative @click="resetFilters" class="text-xs -py-2"
            >Reset</base-button-affirmative
          >
        </div>
        <div
          class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"
          id="company-info"
        >
          <base-filter class="z-20">
            <template v-slot:label> Company </template>
            <template v-slot:widget>
              <base-infinite-dropdown
                @click="toggleVisibility"
                @set-item-filter="setCompanyFilter"
                :list="companyOptions"
                :dropDownVisibleObject="dropDownVisibleObject"
                :resetObject="resetObject"
                :visibleObject="visibleObject"
                :class="!dropDownVisibleObject.visible ? 'rounded-md' : ''"
                v-model="companyFilter"
                placeholder="---------"
              ></base-infinite-dropdown>
            </template>
          </base-filter>

          <base-filter>
            <template v-slot:label>Part Name</template>
            <template v-slot:widget>
              <input
                v-model="partNameFilter"
                @input="setPartNameFilter"
                class="h-full px-2 w-full rounded-b-md shadow-md"
                type="text"
              />
            </template>
          </base-filter>
          <base-filter>
            <template v-slot:label>Make</template>
            <template v-slot:widget>
              <input
                v-model="makeFilter"
                @input="setMakeFilter"
                class="h-full px-2 w-full rounded-b-md shadow-md"
                type="text"
              />
            </template>
          </base-filter>
          <base-filter>
            <template v-slot:label>Model</template>
            <template v-slot:widget>
              <input
                v-model="modelFilter"
                @input="setModelFilter"
                class="h-full px-2 w-full rounded-b-md shadow-md"
                type="text"
              />
            </template>
          </base-filter>

          <base-filter>
            <template v-slot:label> Supplement Date </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="---------"
                v-model="supplimentDateFilter"
                :options="dateOptions"
                @update:modelValue="setSupplimentDateFilter"
                :multiple="false"
              />
            </template>
          </base-filter>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
// imports

import { ref, computed, defineEmits, defineProps, watch, reactive } from "vue";
import { useStore } from "vuex";

import BaseListBox from "../BaseListBox.vue";

// constants/variables
const store = useStore();
const showFilterPane = computed(() => store.getters['research/showFilterPane'])

const props = defineProps(["companyOptions", "visibleObject"]);
const emit = defineEmits([
  "change-referral-filters",
  "set-changed-referral-filters",
  "reset-info",
]);

const companyFilter = ref("");
const makeFilter = ref("");
const modelFilter = ref("");
const partNameFilter = ref("");
const supplimentDateFilter = ref([]);
const dropDownVisibleObject = reactive({
  visible: false,
});

const clickedCompanyFilter = ref(false);

const dateOptions = ref([
  { value: "clear", label: "------------" },
  { value: 365, label: "Last Year" },
  { value: 1095, label: "Last 3 Years" },
  { value: 1825, label: "Last 5 Years" },
  { value: 3650, label: "Last 10 Years" },
]);

const resetObject = reactive({
  buttonHit: false,
});

// watch

watch(props.visibleObject, () => {
  if (dropDownVisibleObject.visible && !clickedCompanyFilter.value) {
    dropDownVisibleObject.visible = false;
  }
  clickedCompanyFilter.value = false;
});

// computed values

const filters = computed(() => {
  return {
    companyFilter: companyFilter,
    makeFilter: makeFilter,
    modelFilter: modelFilter,
    partNameFilter: partNameFilter,
    supplimentDateFilter: supplimentDateFilter,
  };
});

// functions

function toggleVisibility() {
  clickedCompanyFilter.value = true;
  dropDownVisibleObject.visible = !dropDownVisibleObject.visible;
}

function setCompanyFilter(target) {
  if (target === "---------") {
    companyFilter.value = "";
  } else {
    companyFilter.value = target;
  }
  emitChangeApprovalFiltersAndSetChanged();
}

function setMakeFilter(event) {
  makeFilter.value = event.target.value;
  emitChangeApprovalFiltersAndSetChanged();
}

function setModelFilter(event) {
  modelFilter.value = event.target.value;
  emitChangeApprovalFiltersAndSetChanged();
}

function setPartNameFilter(event) {
  partNameFilter.value = event.target.value;
  emitChangeApprovalFiltersAndSetChanged();
}

function setSupplimentDateFilter(target) {
  if (target === "clear") {
    supplimentDateFilter.value = [];
  } else {
    supplimentDateFilter.value = target;
  }
  emitChangeApprovalFiltersAndSetChanged();
}

function emitChangeApprovalFiltersAndSetChanged() {
  clickedCompanyFilter.value = false;
  emit("change-referral-filters", filters);
  emit("set-changed-referral-filters");
}

function resetFilters() {
  companyFilter.value = "";
  modelFilter.value = "";
  makeFilter.value = "";
  partNameFilter.value = "";
  supplimentDateFilter.value = [];
  resetObject.buttonHit = !resetObject.buttonHit;
  emit("reset-info");
  emitChangeApprovalFiltersAndSetChanged();
}
</script>

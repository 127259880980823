import axios from "axios";
import { BASE_URL } from "./api";

export async function getAllTimecards(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/timecards/${payload.endpoint}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      console.log(response);
      return {
        results: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        pageSize: response.data.page_size,
        numPages: response.data.num_pages,
      };
    })
    .catch((error) => console.error(error));
}

export async function getTimecardDates(payload) {
    return axios({
        method: "get",
        url: `${BASE_URL}/timecards/get_all_timecard_dates/`,
        headers: {
          Authorization: `Token ${payload.token}`,
        },
    }).then((response) => {
        return {
          results: response.data,
        };
      })
      .catch((error) => console.error(error));
}

export async function createTimecardFromDate(timecardData, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/timecards/create_timecard_from_date/`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: timecardData,
    })
      .then((response) => response)
      .catch((error) => {
        // console.log(error)
        return error
      });
}

export async function createTimecardNextSaturday(timecardData, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/timecards/create_timecard_next_saturday/`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: timecardData,
    })
      .then((response) => response.data)
      .catch((error) => alert(error));
}

export async function createTimecardLastSaturday(timecardData, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/timecards/create_timecard_last_saturday/`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: timecardData,
    })
      .then((response) => response.data)
      .catch((error) => alert(error));
}

export async function addUserToTimecardGroup(timecardData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/timecards/add_user_to_timecard_group/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: timecardData,
  })
    .then((response) => response.data)
    .catch((error) => alert(error));
}

export async function removeUserFromTimecardGroup(timecardData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/timecards/remove_user_from_timecard_group/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: timecardData,
  })
    .then((response) => response.data)
    .catch((error) => alert(error));
}

export async function markTimecardStatusPaid(timecardData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/timecards/mark_paid_timecard/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: timecardData,
  })
    .then((response) => response)
    .catch((error) => alert(error));
}

export async function resetTimecardActive(timecardData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/timecards/reset_active_timecard/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: timecardData,
  })
    .then((response) => response)
    .catch((error) => alert(error));
}

export async function markTimecardStatusApproved(timecardData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/timecards/mark_timecard_status_approved/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: timecardData,
  })
    .then((response) => response)
    .catch((error) => alert(error));
}
export async function markTimecardStatusSubmitted(timecardData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/timecards/mark_timecard_status_submitted/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: timecardData,
  })
    .then((response) => response)
    .catch((error) => alert(error));
}

export async function editTimecard(timecardID, formData, payload) {
  // console.log(formData)
  // console.log(payload)
  return axios({
      method: 'put',
      url: `${BASE_URL}/timecards/${timecardID}/edit_timecard/`,
      headers: { 
        'Authorization': `Token ${payload.token}`, 
        'Content-Type': 'application/json'
      },
      data: formData
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

export async function deleteTimecard(timecardID, token) {
  return axios({
      method: 'delete',
      url: `${BASE_URL}/timecards/${timecardID}/`,
      headers: { 
        'Authorization': `Token ${token}`, 
        'Content-Type': 'application/json'
      }
  }).then((response) => {
      return response
  }).catch((error) => {
      return error
  })
}

export async function getTimecardsByUser(payload, userId) {
  return axios({
    method: "get",
    url: `${BASE_URL}/timecards/get_timecards_by_user/${payload.endpoint}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
    data: userId
}).then((response) => {
    console.log(response)
    return {
      results: response.data.results,
      count: response.data.count,
      next: response.data.next,
      prev: response.data.previous,
      page: response.data.page,
      pageSize: response.data.page_size,
      numPages: response.data.num_pages,
    };
  })
  .catch((error) => console.error(error));
}

export async function getTimecardHistory(token, timecardId, numberOfChanges){
  return axios({
      method: "get",
      url: `${BASE_URL}/timecards/${timecardId}/get_timecard_history/`,
      headers: { 
          'Authorization': `Token ${token}`, 
          'Content-Type': 'application/json'
      },
      params: {
          'count': numberOfChanges
      }
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

export async function getCalculatedWorkHours(token, timecardId){
  return axios({
      method: "get",
      url: `${BASE_URL}/timecards/${timecardId}/get_calculated_work_hours_for_timecard/`,
      headers: { 
          'Authorization': `Token ${token}`, 
          'Content-Type': 'application/json'
      }
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

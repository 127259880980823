<template>
  <div class="flex flex-row w-full">
    <div class="pl-1.5 pt-0.5 flex flex-col w-2/4">
      <div class="flex flex-row justify-start w-full mb-1">
        <span class="font-bold pr-1">Status:</span> {{ status }}
      </div>
      <div class="flex flex-row justify-start w-ful mb-1">
        <span class="font-bold pr-1">PMA Holder Number:</span>
        {{ pmaHolderNumber }}
      </div>
    </div>
    <div class="pl-1.5 pt-0.5 flex flex-col w-2/4">
      <div class="flex flex-row justify-start w-full mb-1">
        <span class="font-bold pr-1">CFR Part Reference:</span>
        {{ cfrPartReference }}
      </div>
      <div class="flex flex-row justify-start w-full mb-1">
        <span class="font-bold pr-1"
          >PMA Office of Primary Responsibility:</span
        >
        {{ pmaOfficeOfPrimaryResponsibility }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps(["approval"]);
const status = computed(() => {
  return props.approval.status ? props.approval.status : "--";
});

const pmaHolderNumber = computed(() => {
  return props.approval.pma_holder_number
    ? props.approval.pma_holder_number
    : "--";
});

const cfrPartReference = computed(() => {
  return props.approval.cfr_part_reference
    ? props.approval.cfr_part_reference
    : "--";
});

const pmaOfficeOfPrimaryResponsibility = computed(() => {
  return props.approval.office_of_primary_responsibility
    ? props.approval.office_of_primary_responsibility
    : "--";
});
</script>

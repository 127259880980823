<template>
    <div class="modal-body relative  w-full flex flex-col h-full px-2" :class="{'cursor-wait' : isLoading || postsLoading}">
        <form @submit.prevent="submitForm">
            <!-- <base-form>
                <template #body> -->
                    <div class="flex flex-col items-center">
                        <div class="w-full">
                            <h3 class="underline text-base">Invoice Info:</h3>
                            <div class="grid grid-cols-4 gap-3 p-2 w-full">
                                <div class="flex flex-col">
                                    <label for="status">
                                        Status:
                                    </label>
                                    <select class="border border-dark-primary rounded-md p-1 w-full" name="status" id="status" v-model="invoiceData.status">
                                        <option value="Paid">Paid</option>
                                        <option value="Unpaid">Unpaid</option>
                                        <!-- <option value="SENT">SENT</option>
                                        <option value="VIEWED">VIEWED</option>
                                        <option value="OVERDUE">OVERDUE</option> -->
                                    </select>
                                </div>
                                <div class="flex flex-col">
                                    <label for="datePaid">
                                        Date Paid:
                                    </label>
                                    <input type="date" v-model="invoiceData.date_paid" class="border border-dark-primary rounded-md p-1 w-full">
                                </div>
                                <div class="flex flex-col">
                                    <label for="amountPaid">
                                        Amount Paid:
                                    </label>
                                    <div class="flex items-center">
                                        $<input type="number" min="0" step="0.01" v-model="invoiceData.amount_paid" class="border border-dark-primary rounded-md p-1 w-full">
                                    </div>
                                </div>
                                <div class="flex flex-col">
                                    <label for="paymentType">
                                        Payment Type:
                                    </label>
                                    <input type="text" v-model="invoiceData.payment_type" class="border border-dark-primary rounded-md p-1 w-full">
                                </div>
                            </div>
                            <div class="flex justify-center w-full p-2">
                                <div v-if="isLoading" role="status" class="pl-3">
                                    <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#d3d3d3"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="#2E9CCA"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <base-button-affirmative v-else>Save</base-button-affirmative>
                            </div>
                        </div>
                        <div class="w-full mb-2">
                            <h3 class="underline text-base">Accounting Posts:</h3>
                            <div class="flex flex-col items-center p-2 space-y-2 max-h-96 overflow-auto">
                                <div class="flex flex-col items-center w-full rounded-md">
                                    <textarea class="border border-dark-primary rounded-md p-2 w-full" name="" id=""  rows="2" placeholder="New accounting post..." v-model="accountingPostData.post_body"></textarea>
                                    <div v-if="postsLoading" role="status" class="p-3">
                                        <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#d3d3d3"/>
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="#2E9CCA"/>
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <base-button-affirmative class="mt-1" v-else @click.prevent="submitAccountingPost">Add Post</base-button-affirmative>
                                </div>
                                <transition-group name="accountingPosts">
                                    <div v-for="post in invoice.accounting_posts" class="w-full"  :key="post.id">
                                        <InvoiceAccountingPost :post="post" @remove-post="removePost" @refetch-invoices="fetchNewInvoices"/>
                                    </div>
                                    <!-- <div v-for="post in invoice.accounting_posts" :key="post.id" class="border border-dark-primary rounded-md p-2 w-full flex flex-col">
                                        <div class="flex justify-between border-b py-1 items-center">
                                            <div class="flex space-x-2">
                                                <div>
                                                    {{ returnDate(post.created_at) }}
                                                </div>
                                                <div>
                                                    {{ post.created_by.first_name }} {{ post.created_by.last_name }}:
                                                </div>
                                            </div>
                                            <div>
                                                <base-button v-if="isPostOwner(post.created_by.id)" @click.prevent="removePost(post.id)">🗑</base-button>
                                            </div>
                                        </div>
                                        {{ post.post_body }}
                                    </div> -->
                                </transition-group>
                            </div>
                        </div>
                    </div>
                <!-- </template> -->
                <!-- <template #footer>
                    <div class="flex justify-center w-full p-2">
                        <div v-if="isLoading" role="status" class="pl-3">
                            <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#d3d3d3"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#2E9CCA"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <base-button-affirmative v-else>Save</base-button-affirmative>
                    </div>
                </template> -->
            <!-- </base-form> -->
        </form>
    </div>
</template>

<script>
export default {
    name: 'invoice-form'
}
</script>

<script setup>
import { useStore } from 'vuex';
import {ref, defineProps, defineEmits, reactive} from 'vue';
import { updateInvoice, addInvoiceAccountingNote } from '@/services/Invoice';
import { useToast } from 'vue-toast-notification';
import { deleteAccountingPost } from '@/services/AccountingPost'
import InvoiceAccountingPost from './InvoiceAccountingPost.vue';

const store = useStore()

const $toast = useToast()

const props = defineProps({
    invoice: Object
})

function isPostOwner(postOwnerId) {
    return postOwnerId === store.state.auth.user.id
}

const emit = defineEmits(['refetch-invoices', 'close'])


const invoiceData = reactive({
    status: "Paid",
    date_paid: props.invoice.date_paid ? props.invoice.date_paid : new Date().toJSON().substring(0,10),
    amount_paid: props.invoice.amount_paid ? props.invoice.amount_paid : 0.00,
    payment_type: props.invoice.payment_type ? props.invoice.payment_type : null
})

const accountingPostData = reactive({
    post_body: null
})

const isLoading = ref(false)
const postsLoading = ref(false)

async function submitForm() {
    isLoading.value = true
    const token = store.state.auth.token
    const data = JSON.stringify(invoiceData)
    const result = await updateInvoice(props.invoice.id, data, token)
    if(result.status === 200){
        $toast.open({
                    message: `Successfully updated ${props.invoice.invoice_number}`,
                    type: 'success',
                    duration: 5000
                })
    } else {
        $toast.open({
                    message: `Error, could not update ${props.invoice.invoice_number}`,
                    type: 'error',
                    duration: 5000
                })
    }
    emit('refetch-invoices')
    isLoading.value = false


}

async function submitAccountingPost() {
    postsLoading.value = true
    const token = store.state.auth.token
    const data = JSON.stringify(accountingPostData)
    const result = await addInvoiceAccountingNote(props.invoice.id, data, token)
    if(result.status === 200){
        $toast.open({
                    message: `Successfully added Accounting Post to Invoice ${props.invoice.invoice_number}`,
                    type: 'success',
                    duration: 5000
                })
        accountingPostData.post_body = ''
    } else {
        $toast.open({
                    message: `Error, could not add Accounting Post to Invoice ${props.invoice.invoice_number}`,
                    type: 'error',
                    duration: 5000
                })
    }
    emit('refetch-invoices')
    postsLoading.value = false
}

function fetchNewInvoices(){
    emit('refetch-invoices')
}

async function removePost(postId) {
    const token = store.state.auth.token
    postsLoading.value = true 
    const response = await deleteAccountingPost(token, postId)
    emit('refetch-invoices')
    postsLoading.value = false
}

const returnDate = (inputDate) => {
        let date = new Date(inputDate)
        date.setDate(date.getDate() + 1)
        return date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit'})
    }


</script>

<style>
.accountingPosts-enter-active,
.accountingPosts-leave-active {
  transition: all 0.5s ease;
}
.accountingPosts-enter-from,
.accountingPosts-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
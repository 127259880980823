<template>
  <div class="print:hidden mx-1 space-y-2">
    <transition-group tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" v-show="showBasicFilters" key="company-info" >
        <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
          <div class="group flex justify-start items-center space-x-2">
            <h3 class="text-sm">Basic Filters:</h3>
            <button type="button" @click="hideBasicFilters" class="text-xs w-4 h-4 bg-light-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle mt-0.5">-</button>
          </div>
          <base-button-affirmative @click="resetFilters" class="text-xs -py-2">Reset</base-button-affirmative>
        </div>
        <div class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8" id="company-info">
            <filter-transition>
                <base-filter v-if="visibleFilters.companyNameFilter" @close="toggleShowCompanyFilter('companyNameFilter')" :active="!!companyFilters.companyNameFilter.value">
                    <template v-slot:label>Company</template>
                    <template v-slot:widget><input v-model="companyFilters.companyNameFilter.value" class="h-full px-2 w-full rounded-b-md shadow-md" type="text"></template>
                </base-filter>
            </filter-transition>
            <filter-transition>
                <base-filter v-if="visibleFilters.companyIdFilter" @close="toggleShowCompanyFilter('companyIdFilter')"  :active="!!companyFilters.companyIdFilter.value">
                    <template v-slot:label>Company ID</template>
                    <template v-slot:widget><input v-model="companyFilters.companyIdFilter.value" class="h-full px-2 w-full rounded-b-md shadow-md" type="number"></template>
                </base-filter>
            </filter-transition>
            <filter-transition>
                <base-filter v-if="visibleFilters.companyCageCodeFilter" @close="toggleShowCompanyFilter('companyCageCodeFilter')"  :active="!!companyFilters.companyCageCodeFilter.value">
                    <template v-slot:label>CAGE Code</template>
                    <template v-slot:widget><input v-model="companyFilters.companyCageCodeFilter.value" class="h-full px-2 w-full rounded-b-md shadow-md" type="text"></template>
                </base-filter>
            </filter-transition>
            <filter-transition>
                <base-filter v-if="visibleFilters.companyUeiSamFilter" @close="toggleShowCompanyFilter('companyUeiSamFilter')"  :active="!!companyFilters.companyUeiSamFilter.value">
                    <template v-slot:label>UEI</template>
                    <template v-slot:widget><input v-model="companyFilters.companyUeiSamFilter.value" class="h-full px-2 w-full rounded-b-md shadow-md" type="text"></template>
                </base-filter>
            </filter-transition>
            <filter-transition>
                <base-filter v-if="visibleFilters.companyDescriptionFilter" @close="toggleShowCompanyFilter('companyDescriptionFilter')"  :active="!!companyFilters.companyDescriptionFilter.value">
                    <template v-slot:label>Description</template>
                    <template v-slot:widget>
                        <input
                            v-model="companyFilters.companyDescriptionFilter.value"
                            class="h-full px-2 w-full rounded-b-md shadow-md"
                            type="text">
                    </template>
                </base-filter>
            </filter-transition>
            <filter-transition>
                <base-filter v-if="visibleFilters.companyWebsiteUrlFilter" @close="toggleShowCompanyFilter('companyWebsiteUrlFilter')"  :active="!!companyFilters.companyWebsiteUrlFilter.value">
                    <template v-slot:label>Website URL</template>
                    <template v-slot:widget>
                        <input
                            v-model="companyFilters.companyWebsiteUrlFilter.value"
                            class="h-full px-2 w-full rounded-b-md shadow-md"
                            type="text">
                    </template>
                </base-filter>
            </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyAddressFilter" @close="toggleShowCompanyFilter('companyAddressFilter')"  :active="!!companyFilters.companyAddressFilter.value">
                <template v-slot:label>
                    Address Search
                </template>
                <template v-slot:widget>
                    <input v-model="companyFilters.companyAddressFilter.value" class="h-full px-2 w-full rounded-b-md shadow-md" type="text">
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyStateFilter" @close="toggleShowCompanyFilter('companyStateFilter')"  :active="companyFilters.companyStateFilter.value.length > 0">
                <template v-slot:label>
                    State
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companyStateFilter.value"
                        :options="companyFilters.companyStateFilter.options"
                        @update:modelValue="setCompanyStateFilter"
                        :multiple="true"
                        />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyCountryFilter" @close="toggleShowCompanyFilter('companyCountryFilter')"  :active="companyFilters.companyCountryFilter.value.length > 0">
                <template v-slot:label>
                    Country
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companyCountryFilter.value"
                        :options="companyFilters.companyCountryFilter.options"
                        @update:modelValue="setCompanyCountryFilter"
                        :multiple="true"
                        />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyBusinessTypeFilter" @close="toggleShowCompanyFilter('companyBusinessTypeFilter')" @click="setBusinessTypeOptions"  :active="companyFilters.companyBusinessTypeFilter.value.length > 0">
                <template v-slot:label>
                    Business Type
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyBusinessTypeFilter.options"
                    :modelValue="companyFilters.companyBusinessTypeFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyBusinessTypeFilter"
                    />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companySectorFilter" @close="toggleShowCompanyFilter('companySectorFilter')" @click="setSectorOptions"  :active="companyFilters.companySectorFilter.value.length > 0">
                <template v-slot:label>
                    Sector
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companySectorFilter.options"
                    :modelValue="companyFilters.companySectorFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanySectorFilter"
                    />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companySubSectorFilter" @close="toggleShowCompanyFilter('companySubSectorFilter')" @click="setSubSectorOptions"  :active="companyFilters.companySubSectorFilter.value.length > 0">
                <template v-slot:label>
                    Sub Sector
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companySubSectorFilter.options"
                    :modelValue="companyFilters.companySubSectorFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanySubSectorFilter"
                    />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyCategoryFilter" @close="toggleShowCompanyFilter('companyCategoryFilter')"  :active="!!companyFilters.companyCategoryFilter.value">
                <template v-slot:label>Category</template>
                <template v-slot:widget>
                    <input
                        v-model="companyFilters.companyCategoryFilter.value"
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text">
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter-add-on v-if="visibleFilters.companyCollectionTagsFilter" @close="toggleShowCompanyFilter('companyCollectionTagsFilter')" @click="setCollectionTagOptions"  :active="companyFilters.companyCollectionTagsFilter.value.length > 0">
                <template v-slot:label>
                    Collection Tags
                </template>
                <template v-slot:widget>
                      <BaseComboBox
                        :options="companyFilters.companyCollectionTagsFilter.options"
                        v-model="companyFilters.companyCollectionTagsFilter.value"
                        @update:modelValue="setCompanyCollectionTagsFilter"
                      />
                </template>
                <template v-slot:addOn>
                  <AllMineButtons :activeButton="companyFilters.companyCollectionTagsExpressionFilter.value" @updateActiveButton="setCollectionTagsExpression"  />
                </template>
            </base-filter-add-on>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyCollectionTagSourcesFilter" @close="toggleShowCompanyFilter('companyCollectionTagSourcesFilter')" @click="setCollectionTagSourceOptions(companyCollectionTagsFilter)"  :active="companyFilters.companyCollectionTagSourcesFilter.value.length > 0">
                <template v-slot:label>
                    Collection Tag Sources
                </template>
                <template v-slot:widget>
                 <base-list-box
                    v-if="companyCollectionTagsFilter.length > 0"
                      class="w-full rounded-b-md"
                      placeholder=""
                      v-model="companyFilters.companyCollectionTagSourcesFilter.value"
                      :options="companyFilters.companyCollectionTagSourcesFilter.options"
                      @update:modelValue="setCompanyCollectionTagSourcesFilter"
                      :multiple="true"
                    />
                    <div v-else class="w-full rounded-b-md h-full bg-gray-200 border shadow-md border-gray-200 py-2 pl-3 text-gray-700 truncate">
                      Select Collection Tag(s) to Filter
                    </div>
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
                <base-filter v-if="visibleFilters.companyCollectionReviewFilter"  @close="toggleShowCompanyFilter('companyCollectionReviewFilter')"  :active="!!companyFilters.companyCollectionReviewFilter.value">
                <template v-slot:label>Collection Review</template>
                <template v-slot:widget>
                    <base-list-box
                    v-if="companyCollectionTagsFilter.length > 0"
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyCollectionReviewFilter.options"
                    v-model="companyFilters.companyCollectionReviewFilter.value"
                    />
                    <div v-else class="w-full rounded-b-md h-full bg-gray-200 border shadow-md border-gray-200 py-2 pl-3 text-gray-700 truncate">
                      Select Collection Tag(s) to Filter
                    </div>
                </template>
                </base-filter>
          </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyCollectionTagExcludeFilter" @close="toggleShowCompanyFilter('companyCollectionTagExcludeFilter')" @click="setCollectionTagOptions"  :active="companyFilters.companyCollectionTagExcludeFilter.value.length > 0">
                <template v-slot:label>
                    Collection Tag Exclude
                </template>
                <template v-slot:widget>
                      <BaseComboBox
                        v-model="companyFilters.companyCollectionTagExcludeFilter.value"
                        :options="companyFilters.companyCollectionTagExcludeFilter.options"
                        @update:modelValue="setCompanyCollectionTagExcludeFilter"
                      />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyNAICSFilter" @close="toggleShowCompanyFilter('companyNAICSFilter')" @click="setNAICSOptions"  :active="companyFilters.companyNAICSFilter.value.length > 0">
                <template v-slot:label>
                    NAICS Code
                </template>
                <template v-slot:widget>
                    <BaseComboBox :options="companyFilters.companyNAICSFilter.options" v-model="companyFilters.companyNAICSFilter.value" @update:model-value="setCompanyNAICSFilter" />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
          <base-filter v-if="visibleFilters.companyProductServiceCodeFilter" @close="toggleShowCompanyFilter('companyProductServiceCodeFilter')"  :active="!!companyFilters.companyProductServiceCodeFilter.value">
            <template v-slot:label>
              <div class="flex justify-between">
                Product Service Code
              </div>
            </template>
            <template v-slot:widget>
              <input
                  v-model="companyFilters.companyProductServiceCodeFilter.value"
                  class="h-full px-2 w-full rounded-b-md shadow-md"
                  type="text">
            </template>
          </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyRevenueFilter" @close="toggleShowCompanyFilter('companyRevenueFilter')"  :active="!!companyFilters.companyMinRevenueFilter.value || !!companyFilters.companyMaxRevenueFilter.value">
                <template v-slot:label>Revenue</template>
                <template v-slot:widget>
                    <div class="flex h-full">
                    <input  placeholder="min" v-model="companyFilters.companyMinRevenueFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="number">
                    <input  placeholder="max" v-model="companyFilters.companyMaxRevenueFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="number">
                    </div>
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyRevenueZiCombinedFilter" @close="toggleShowCompanyFilter('companyRevenueZiCombinedFilter')"  :active="!!companyFilters.companyMinRevenueZiCombinedFilter.value || !!companyFilters.companyMaxRevenueZiCombinedFilter.value">
                <template v-slot:label>Revenue - ZI Combined</template>
                <template v-slot:widget>
                    <div class="flex h-full">
                    <input placeholder="min" v-model="companyFilters.companyMinRevenueZiCombinedFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="number">
                    <input placeholder="max" v-model="companyFilters.companyMaxRevenueZiCombinedFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="number">
                    </div>
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter v-if="visibleFilters.companyHeadcountFilter" @close="toggleShowCompanyFilter('companyHeadcountFilter')"  :active="!!companyFilters.companyMinHeadcountFilter.value || !!companyFilters.companyMaxHeadcountFilter.value">
                <template v-slot:label>Headcount</template>
                <template v-slot:widget>
                    <div class="flex h-full">
                    <input placeholder="min" v-model="companyFilters.companyMinHeadcountFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="number">
                    <input placeholder="max" v-model="companyFilters.companyMaxHeadcountFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="number">
                    </div>
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter  v-if="visibleFilters.companyParentCompanyStatusFilter" @close="toggleShowCompanyFilter('companyParentCompanyStatusFilter')"  :active="!!companyFilters.companyParentCompanyStatusFilter.value">
                <template v-slot:label>
                    Parent Company Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyParentCompanyStatusFilter.options"
                    v-model="companyFilters.companyParentCompanyStatusFilter.value"
                    :multiple="false"
                    />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
            <base-filter  v-if="visibleFilters.companyInvestorsStatusFilter" @close="toggleShowCompanyFilter('companyInvestorsStatusFilter')"  :active="!!companyFilters.companyInvestorsStatusFilter.value">
                <template v-slot:label>
                    Investors Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyInvestorsStatusFilter.options"
                    v-model="companyFilters.companyInvestorsStatusFilter.value"
                    :multiple="false"
                    />
                </template>
            </base-filter>
        </filter-transition>
        <filter-transition>
          <base-filter  v-if="visibleFilters.companyParentTypeFilter" @close="toggleShowCompanyFilter('companyParentTypeFilter')"  :active="companyFilters.companyParentTypeFilter.value.length > 0">
            <template v-slot:label>
                Parent Type
            </template>
            <template v-slot:widget>
                <base-list-box
                class="w-full rounded-b-md"
                placeholder=""
                :options="companyFilters.companyParentTypeFilter.options"
                v-model="companyFilters.companyParentTypeFilter.value"
                :multiple="true"
                />
            </template>
          </base-filter>
        </filter-transition>

        <filter-transition>
            <base-filter @click="setCapabilitiesOptions" v-if="visibleFilters.companyCapabilitiesFilter" @close="toggleShowCompanyFilter('companyCapabilitiesFilter')"  :active="companyFilters.companyCapabilitiesFilter.value.length > 0">
                <template v-slot:label>
                    Capabilities
                </template>
                <template v-slot:widget>
                    <base-list-box
                      class="w-full rounded-b-md"
                      placeholder=""
                      :options="companyFilters.companyCapabilitiesFilter.options"
                      :modelValue="companyFilters.companyCapabilitiesFilter.value"
                      :multiple="true"
                      @update:modelValue="setCompanyCapabilitiesFilter"
                    />
                </template>
            </base-filter>
        </filter-transition>
    </div>
  </div>

    <!-- Campaign Info Filters Section -->
    </transition-group>
    <transition-group tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" v-show="showCampaignFilters" key="campaign-info">
        <div>

        </div>
        <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
          <div class="group flex justify-start items-center space-x-2">
            <h3 class="text-sm">Campaign Filters:</h3>
            <button type="button" @click="hideCampaignFilters" class="text-xs w-4 h-4 bg-light-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle mt-0.5">-</button>
          </div>
        </div>
        <div class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"  id="campaign-info">
          <filter-transition>
                <base-filter v-if="visibleFilters.companySourcesFilter" @close="toggleShowCompanyFilter('companySourcesFilter')" @click="setSourceOptions"  :active="companyFilters.companySourcesFilter.value.length > 0">
                <template v-slot:label>
                    Sources
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companySourcesFilter.value"
                        :options="companyFilters.companySourcesFilter.options"
                        @update:modelValue="setCompanySourcesFilter"
                        :multiple="true"
                        />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter  v-if="visibleFilters.companyCampaignFilter" @close="toggleShowCompanyFilter('companyCampaignFilter')" @click="setCampaignOptions"  :active="companyFilters.companyCampaignFilter.value.length > 0">
                <template v-slot:label>
                    Campaign
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyCampaignFilter.options"
                    :modelValue="companyFilters.companyCampaignFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyCampaignFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter  v-if="visibleFilters.companyCampaignExcludeFilter" @close="toggleShowCompanyFilter('companyCampaignExcludeFilter')" @click="setCampaignOptions"  :active="companyFilters.companyCampaignExcludeFilter.value.length > 0">
                <template v-slot:label>
                    Campaign Exclude
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyCampaignExcludeFilter.options"
                    :modelValue="companyFilters.companyCampaignExcludeFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyCampaignExcludeFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter  v-if="visibleFilters.companyCampaignExcludeFilter" @close="toggleShowCompanyFilter('companyPreviousCampaignFilter')" @click="setCampaignOptions"  :active="companyFilters.companyPreviousCampaignFilter.value.length > 0">
                <template v-slot:label>
                    Previous Campaign
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyPreviousCampaignFilter.options"
                    :modelValue="companyFilters.companyPreviousCampaignFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyPreviousCampaignsFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter  v-if="visibleFilters.companyTierFilter" @close="toggleShowCompanyFilter('companyTierFilter')" @click="setTierOptions"  :active="companyFilters.companyTierFilter.value.length > 0">
                <template v-slot:label>
                    Tier
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyTierFilter.options"
                    :modelValue="companyFilters.companyTierFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyTierFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyAccountManagerResearchNotesFilter" @close="toggleShowCompanyFilter('companyAccountManagerResearchNotesFilter')"  :active="!!companyFilters.companyAccountManagerResearchNotesFilter.value">
                <template v-slot:label>Account Manager Research Notes</template>
                <template v-slot:widget>
                    <input
                        v-model="companyFilters.companyAccountManagerResearchNotesFilter.value"
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text">
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyClientFeedbackFilter" @close="toggleShowCompanyFilter('companyClientFeedbackFilter')"  :active="companyFilters.companyClientFeedbackFilter.value.length > 0">
                <template v-slot:label>
                    Client Feedback
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyClientFeedbackFilter.options"
                    :modelValue="companyFilters.companyClientFeedbackFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyClientFeedbackFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyClientFilter" @close="toggleShowCompanyFilter('companyClientFilter')" @click="setClientOptions"  :active="companyFilters.companyClientFilter.value.length > 0">
                <template v-slot:label>
                    Client
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyClientFilter.options"
                    :modelValue="companyFilters.companyClientFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyClientFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyCRMStatusFilter" @close="toggleShowCompanyFilter('companyCRMStatusFilter')"  :active="companyFilters.companyCRMStatusFilter.value.length > 0">
                <template v-slot:label>
                    CRM Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companyCRMStatusFilter.value"
                        :options="companyFilters.companyCRMStatusFilter.options"
                        @update:modelValue="setCompanyCRMStatusFilter"
                        :multiple="true"
                        />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyCRMNextActionFilter" @close="toggleShowCompanyFilter('companyCRMNextActionFilter')"  :active="companyFilters.companyCRMNextActionFilter.value.length > 0">
                <template v-slot:label>
                    CRM Next Action
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companyCRMNextActionFilter.value"
                        :options="companyFilters.companyCRMNextActionFilter.options"
                        @update:modelValue="setCompanyCRMNextActionFilter"
                        :multiple="true"
                        />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyDueTimeRangeFilter" @close="toggleShowCompanyFilter('companyDueTimeRangeFilter')"  :active="!!companyFilters.companyDueDateStartFilter.value || !!companyFilters.companyDueDateEndFilter.value">
                  <template v-slot:label>
                    Due Time Range
                  </template>
                  <template v-slot:widget>
                    <!-- <base-list-box
                        class="w-full rounded-b-md"
                        placeholder="Select Tag"
                        v-model="companyDueTimeRangeFilter"
                        :options="dueTimeRangeOptions"
                        @update:modelValue="setCompanyDueTimeRangeFilter"
                        :multiple="true"
                        /> -->
                      <input placeholder="Start" v-model="companyFilters.companyDueDateStartFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="date">
                      <input placeholder="End" v-model="companyFilters.companyDueDateEndFilter.value" class="h-full px-2 w-1/2 rounded-b-md border shadow-md" type="date">
                  </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyDrivingTimeFocusFilter" @close="toggleShowCompanyFilter('companyDrivingTimeFocusFilter')" @click="setDrivingTimeFocusOptions"  :active="!!companyFilters.companyDrivingTimeFocusFilter.value">
                <template v-slot:label>
                    Driving Time Focus
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companyDrivingTimeFocusFilter.value"
                        :options="companyFilters.companyDrivingTimeFocusFilter.options"
                        :multiple="false"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
            <base-filter-add-on v-if="visibleFilters.companyDrivingTimeFilter" @close="toggleShowCompanyFilter('companyDrivingTimeFilter')"  :active="!!companyFilters.companyDrivingTimeFilter.value">
              <template v-slot:label>
                  Driving Time
              </template>
              <template v-slot:widget>
                  <input  v-model="companyFilters.companyDrivingTimeFilter.value" class="h-full px-2 w-full rounded-b-md shadow-md" type="number">
              </template>
              <template v-slot:addOn>
                  <MinHourButtons :activeButton="companyFilters.companyDrivingTimeExpressionFilter.value" @updateActiveButton="setCompanyDrivingTimeExpression" />
              </template>
            </base-filter-add-on>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyTaskUserFilter" @close="toggleShowCompanyFilter('companyTaskUserFilter')" @click="setTaskUserOptions"  :active="companyFilters.companyTaskUserFilter.value.length > 0">
                <template v-slot:label>
                    CRM Event User
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        v-model="companyFilters.companyTaskUserFilter.value"
                        :options="companyFilters.companyTaskUserFilter.options"
                        @update:modelValue="setCompanyTaskUserFilter"
                        :multiple="true"
                        />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyRelevantBusinessFilter" @close="toggleShowCompanyFilter('companyRelevantBusinessFilter')"  :active="!!companyFilters.companyRelevantBusinessFilter.value">
                <template v-slot:label>Relevant Business</template>
                <template v-slot:widget>
                    <input
                        v-model="companyFilters.companyRelevantBusinessFilter.value"
                        class="h-full px-2 w-full rounded-b-md shadow-md"
                        type="text">
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyEveraxisPillarFilter" @close="toggleShowCompanyFilter('companyEveraxisPillarFilter')"  :active="companyFilters.companyEveraxisPillarFilter.value.length > 0">
                <template v-slot:label>EverAxis Pillar</template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyEveraxisPillarFilter.options"
                    :modelValue="companyFilters.companyEveraxisPillarFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyEveraxisPillarFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyIgpCategoryFilter" @close="toggleShowCompanyFilter('companyIgpCategoryFilter')"  :active="companyFilters.companyIgpCategoryFilter.value.length > 0">
                <template v-slot:label>IGP Category</template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyIgpCategoryFilter.options"
                    :modelValue="companyFilters.companyIgpCategoryFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyIgpCategoryFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="userPermissions.isManagement || userPermissions.isDev"  :active="companyFilters.companyAccountManagerFilter.value.length > 0">
                <template v-slot:label>Account Manager</template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyAccountManagerFilter.options"
                    :modelValue="companyFilters.companyAccountManagerFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyAccountManagerFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyCRMActionsFilter"  @close="toggleShowCompanyFilter('companyCRMActionsFilter')"  :active="companyFilters.companyCRMActionsFilter.value.length > 0">
                <template v-slot:label>CRM Event</template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyCRMActionsFilter.options"
                    :modelValue="companyFilters.companyCRMActionsFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyCRMActionsFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyHasCRMActionsFilter"  @close="toggleShowCompanyFilter('companyHasCRMActionsFilter')"  :active="!!companyFilters.companyHasCRMActionsFilter.value">
                <template v-slot:label>Has CRM Events</template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyHasCRMActionsFilter.options"
                    v-model="companyFilters.companyHasCRMActionsFilter.value"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyHasConflictsFilter"  @close="toggleShowCompanyFilter('companyHasConflictsFilter')"  :active="!!companyFilters.companyHasConflictsFilter.value">
                <template v-slot:label>Has Conflicts</template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyHasConflictsFilter.options"
                    v-model="companyFilters.companyHasConflictsFilter.value"
                    />
                </template>
                </base-filter>
          </filter-transition>
        </div>
      </div>
    </transition-group>
    <transition-group tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" key="research-info" v-show="showResearchFilters">
        <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
          <div class="group flex justify-start items-center space-x-2">
            <h3 class="text-sm">Research Filters:</h3>
            <button type="button" @click="hideResearchFilters" class="text-xs w-4 h-4 bg-light-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle mt-0.5">-</button>
          </div>
        </div>
        <div class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8" id="research-info">
          <filter-transition>
                <base-filter v-if="visibleFilters.companySourceOwnerFilter" @close="toggleShowCompanyFilter('companySourceOwnerFilter')" @click="setSourceOwnerOptions"  :active="companyFilters.companySourceOwnerFilter.value.length > 0">
                <template v-slot:label>
                    Source Owner(s)
                </template>
                <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md"
                        placeholder=""
                        :modelValue="companyFilters.companySourceOwnerFilter.value"
                        :options="companyFilters.companySourceOwnerFilter.options"
                        :multiple="true"
                        @update:modelValue="setCompanySourceOwnersFilter"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyOnboardingStatusFilter" @close="toggleShowCompanyFilter('companyOnboardingStatusFilter')"  :active="!!companyFilters.companyOnboardingStatusFilter.value">
                <template v-slot:label>
                    Onboarding Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyOnboardingStatusFilter.options"
                    v-model="companyFilters.companyOnboardingStatusFilter.value"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyProblemStatusFilter" @close="toggleShowCompanyFilter('companyProblemStatusFilter')"  :active="!!companyFilters.companyProblemStatusFilter.value">
                <template v-slot:label>
                    Problem Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="w-full rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyProblemStatusFilter.options"
                    v-model="companyFilters.companyProblemStatusFilter.value"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyDNRReasonFilter" @close="toggleShowCompanyFilter('companyDNRReasonFilter')"  :active="!!companyFilters.companyDNRReasonFilter.value">
                <template v-slot:label>
                    Do Not Research
                    Reason
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyDNRReasonFilter.options"
                    v-model="companyFilters.companyDNRReasonFilter.value"
                    />
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter-add-on v-if="visibleFilters.companyResearchStatusFilter" @close="toggleShowCompanyFilter('companyResearchStatusFilter')" :active="companyFilters.companyResearchStatusFilter.value.length > 0">
                <template v-slot:label>
                    Research Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="rounded-bl-md"
                    placeholder=""
                    :options="companyFilters.companyResearchStatusFilter.options"
                    :modelValue="companyFilters.companyResearchStatusFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyResearchStatusFilter"
                    />
                </template>
                <template v-slot:addOn>
                    <AndOrButtons :activeButton="companyFilters.companyResearchStatusExpressionFilter.value" @updateActiveButton="setResearchStatusExpression" />
                </template>
                </base-filter-add-on>
          </filter-transition>
          <filter-transition>
                <base-filter-add-on v-if="visibleFilters.companyResearchStatusFilterTwo" @close="toggleShowCompanyFilter('companyResearchStatusFilterTwo')"  :active="companyFilters.companyResearchStatusFilterTwo.value.length > 0">
                <template v-slot:label>
                    Research Status 2
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="rounded-bl-md"
                    placeholder=""
                    :options="companyFilters.companyResearchStatusFilterTwo.options"
                    :modelValue="companyFilters.companyResearchStatusFilterTwo.value"
                    :multiple="true"
                    @update:modelValue="setCompanyResearchStatusFilterTwo"
                    />
                </template>
                <template v-slot:addOn>
                    <AndOrButtons :activeButton="companyFilters.companyResearchStatusExpressionFilterTwo.value" @updateActiveButton="setResearchStatusExpressionTwo" />
                </template>
                </base-filter-add-on>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyShowResearchHaltedFilter" @close="toggleShowCompanyFilter('companyShowResearchHaltedFilter')" :active="!!companyFilters.companyShowResearchHaltedFilter.value">
                <template v-slot:label>
                    Show Research Halted
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyShowResearchHaltedFilter.options"
                    v-model="companyFilters.companyShowResearchHaltedFilter.value"
                    >
                    </base-list-box>
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyReferralSourceFilter" @close="toggleShowCompanyFilter('companyReferralSourceFilter')"  :active="companyFilters.companyReferralSourceFilter.value.length > 0">
                <template v-slot:label>
                    Referral Source
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyReferralSourceFilter.options"
                    :modelValue="companyFilters.companyReferralSourceFilter.value"
                    :multiple="true"
                    @update:modelValue="setCompanyReferralSourceFilter"
                    >
                    </base-list-box>
                </template>
                </base-filter>
          </filter-transition>
          <filter-transition>
                <base-filter v-if="visibleFilters.companyReferralStatusFilter" @close="toggleShowCompanyFilter('companyReferralStatusFilter')"  :active="!!companyFilters.companyReferralStatusFilter.value">
                <template v-slot:label>
                    Referral Status
                </template>
                <template v-slot:widget>
                    <base-list-box
                    class="rounded-b-md"
                    placeholder=""
                    :options="companyFilters.companyReferralStatusFilter.options"
                    v-model="companyFilters.companyReferralStatusFilter.value"
                    >
                    </base-list-box>
                </template>
                </base-filter>
          </filter-transition>
        </div>
      </div>
    </transition-group>
    <transition-group tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    > 
      <div v-if="showActiveFilters">
        <div class="flex flex-wrap items-center space-x-2 mx-1.5">
          <div class="group flex justify-start items-center space-x-2">
            <h3 class="text-sm">Active Filters:</h3>
            <button type="button" @click="hideActiveFilters" class="text-xs w-4 h-4 bg-light-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle mt-0.5">-</button>
          </div>
          <base-pill class="bg-amber-300 flex items-center justify-between space-x-2 p-2 mb-0.5 " v-for="filter in activeFilters" :key="filter">
          <div> {{ filter.name }}: {{ filter.value }} </div><button @click="clearFilter(filter.filter)">✕</button> 
          </base-pill>
        </div>
      </div>
    </transition-group>
  </div>  
</template>

<script>
export default {
  name: 'company-filters'
}
</script>
<script setup>
import { ref, computed, watch, shallowReactive, defineEmits, reactive } from 'vue';
import FilterTransition from "@/components/FilterTransition";
import BaseFilter from "@/components/BaseFilter";
import { getSources } from '@/services/Source';
import { getSourceOwners } from '@/services/SourceOwner';
import { getCampaigns } from '@/services/Campaign';
import { getCrmStatuses } from '@/services/CrmStatus';
import { getTiers } from '@/services/Tiers';
import { getClients } from '@/services/Client';
import {getBusinessTypes} from '@/services/BusinessType'
import {getSectors} from '@/services/Sector'
import {getSubSectors} from '@/services/SubSector'
import {getGeoLocations} from '@/services/GeoLocation';
import { getUsersWithTasks } from '@/services/User';
import store from '@/store';
import AndOrButtons from './AndOrButtons.vue';
import MinHourButtons from './MinHourButtons.vue';
import AllMineButtons from './AllMineButtons.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { getCollectionTags } from '@/services/CollectionTag';
import { getCollectionTagsForActiveUser } from '@/services/CollectionTag';
import BaseComboBox from './BaseComboBox.vue'
import { getNAICSCodes } from '@/services/NAICSCode';
import { getReferralSources } from '@/services/Referrer';
import { getAsynchronousJobQueue } from "@/services/AsynchronousJobs";
import { formatDateTime } from '@/composables/formatDateTime';
import {getActions} from '@/services/CrmAction'
import HeadlessPopoverClick from './HeadlessPopoverClick.vue';
import { getCapabilities } from '@/services/Capability';

const companyFilters = reactive({
  companyNameFilter: {
      name: 'Company',
      value: null,
      defaultValue: null
  },
  companyDescriptionFilter: {
      name: 'Description',
      value: null,
      defaultValue: null
  },
  companyWebsiteUrlFilter: {
      name: 'Website URL',
      value: null,
      defaultValue: null
  },
  companyAddressFilter: {
      name: 'Address',
      value: null,
      defaultValue: null
  },
  companySourcesFilter: {
      name: 'Sources',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companySourceOwnerFilter: {
      name: 'Source Owner',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyCampaignFilter: {
      name: 'Campaign',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyCampaignExcludeFilter: {
      name: 'Campaign Exclude',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyTierFilter: {
      name: 'Tier',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyClientFeedbackFilter: {
      name: 'Client Feedback',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: "None", label: "None"},
          {value: "approved", label: "Approved"},
          {value: "excluded", label: "Excluded"},
          {value: "hold", label: "Hold"},
          {value: "pass", label: "Pass"},
          {value: "research_further", label: "Research Further"},
      ]
  },
  companyOnboardingStatusFilter: {
      name: 'Onboarding Status',
      value: null,
      defaultValue: null,
      options: [
          {value: "clear", label: "------------"},
          {value: "imported", label: "Imported"},
          {value: "url_enriched", label: "URL Enriched"},
          {value: "verified_unique", label: "Verified Unique"},
          {value: "research_complete", label: "Research Complete"}
      ]
  },
  companyDNRReasonFilter: {
      name: 'Do Not Research Reason',
      value: null,
      defaultValue: null,
      options: [
          {value: "clear", label: "------------"},
          {value: "Duplicate", label: "Duplicate"},
          {value: "Duplicate Assimilated", label: "Duplicate Assimilated"},
          {value: "Already Known", label: "Already Known"},
          {value: "No Website", label: "No Website"},
          {value: "Address Not Valid", label: "Address Not Valid"},
          {value: "Permanently Closed", label: "Permanently Closed"},
          {value: "Acquired", label: "Acquired"},
          {value: "Public/Large PE Owned", label: "Public/Large PE Owned"},
      ]
  },
  companyResearchStatusFilter: {
      name: 'Research Status',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: "research_not_complete", label: "Research Not Complete"},
          {value: "research_complete", label: "Research Complete"},
          {value: "research_halted", label: "Research Halted"},
          {value: "url_found", label: "URLs Found"},
          {value: "urls_missing", label: "URLs Missing"},
          {value: "urls_vetted", label: "URLs Vetted"},
          {value: "marked_no_url", label: "Marked No URL"},
          {value: "urls_found_not_vetted", label: "URLs Found Not Vetted"},
          {value: "urls_not_vetted", label: "URLs Not Vetted"},
          {value: "contact_email_missing", label: "Contact Email Missing"},
          {value: "has_primary_contact", label: "Has Primary Contact"},
          {value: "no_primary_contact", label: "No Primary Contact"},
          {value: "primary_outreach_email_missing", label: "Primary Outreach Email Missing"},
          {value: "has_primary_outreach_email", label: "Has Primary Outreach Email"},
          {value: "no_contact", label: "No Contacts"}, 
          {value: "multiple_zoom_info_ids", label: "Multiple Zoom Info IDs"},
          {value: "no_zoom_info_id", label: "No Zoom Info ID"},
          {value: "one_zoom_info_id", label: "One Zoom Info ID"},
          {value: "revenue_found", label: "Revenue Found"},
          {value: "revenue_missing", label: "Revenue Missing"},
          {value: "acquisition_researched", label: 'Acquisiition Researched'},
          {value: "acquisition_not_researched", label: 'Acquisition Not Researched'},
          {value: "do_not_research", label: "Do Not Research"},
          {value: "do_research", label: "Do Research"},
          {value: "further_research_needed", label: "Research Further"},
      ]
  },
  companyResearchStatusExpressionFilter: {
      name: 'Research Status Expression',
      value: 'or',
      defaultValue: 'or'
  },
  companyResearchStatusFilterTwo: {
      name: 'Research Status 2',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: "research_not_complete", label: "Research Not Complete"},
          {value: "research_complete", label: "Research Complete"},
          {value: "research_halted", label: "Research Halted"},
          {value: "url_found", label: "URLs Found"},
          {value: "urls_missing", label: "URLs Missing"},
          {value: "urls_vetted", label: "URLs Vetted"},
          {value: "marked_no_url", label: "Marked No URL"},
          {value: "urls_found_not_vetted", label: "URLs Found Not Vetted"},
          {value: "urls_not_vetted", label: "URLs Not Vetted"},
          {value: "contact_email_missing", label: "Contact Email Missing"},
          {value: "has_primary_contact", label: "Has Primary Contact"},
          {value: "no_primary_contact", label: "No Primary Contact"},
          {value: "primary_outreach_email_missing", label: "Primary Outreach Email Missing"},
          {value: "has_primary_outreach_email", label: "Has Primary Outreach Email"},
          {value: "no_contact", label: "No Contacts"}, 
          {value: "multiple_zoom_info_ids", label: "Multiple Zoom Info IDs"},
          {value: "no_zoom_info_id", label: "No Zoom Info ID"},
          {value: "one_zoom_info_id", label: "One Zoom Info ID"},
          {value: "revenue_found", label: "Revenue Found"},
          {value: "revenue_missing", label: "Revenue Missing"},
          {value: "acquisition_researched", label: 'Acquisiition Researched'},
          {value: "acquisition_not_researched", label: 'Acquisition Not Researched'},
          {value: "do_not_research", label: "Do Not Research"},
          {value: "do_research", label: "Do Research"},
          {value: "further_research_needed", label: "Research Further"},
      ]
  },
  companyResearchStatusExpressionFilterTwo: {
      name: 'Research Status Expression 2',
      value: 'or',
      defaultValue: 'or'
  },
  companyMinRevenueFilter: {
      name: 'Min Revenue',
      value: null,
      defaultValue: null
  },
  companyMaxRevenueFilter: {
      name: 'Max Revenue',
      value: null,
      defaultValue: null
  },
  companyMinHeadcountFilter: {
      name: 'Min Headcount',
      value: null,
      defaultValue: null
  },
  companyMaxHeadcountFilter: {
      name: 'Max Headcount',
      value: null,
      defaultValue: null
  },
  companyMinRevenueZiCombinedFilter: {
      name: 'Min Revenue ZI Combined',
      value: null,
      defaultValue: null
  },
  companyMaxRevenueZiCombinedFilter: {
      name: 'Max Revenue ZI Combined',
      value: null,
      defaultValue: null
  },
  companyClientFilter: {
      name: 'Client',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]

  },
  companyBusinessTypeFilter: {
      name: 'Business Type',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companySectorFilter: {
      name: 'Sector',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companySubSectorFilter: {
      name: 'Sub Sector',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyCRMStatusFilter: {
      name: 'CRM Status',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyCRMNextActionFilter: {
      name: 'CRM Next Action',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: 'None', label: 'None'},
          {value: 'Determine Next Action', label: 'Determine Next Action'},
          {value: 'Scott to Follow-Up', label: 'Scott to Follow-Up'},
          {value: 'Send Initial Email', label:'Send Initial Email' },
          {value: 'Send Another Email', label: 'Send Another Email'},
          {value: 'Send Email on Acquisition', label: 'Send Email on Acquisition'},
          {value: 'Research Correct Email', label: 'Research Correct Email'},
          {value: 'Call', label: 'Call'},
          {value: 'Schedule Meeting', label: 'Schedule Meeting'},
          {value: 'Wait on Response', label: 'Wait on Response'},
          {value: 'Wait for Client Feedback', label: 'Wait for Client Feedback'},
          {value: 'Meet', label: 'Meet'},
          {value: 'Not Now', label: 'Not Now'},
          {value: 'Leave Alone', label: 'Leave Alone'},
          {value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out'},
          {value: "Send 2nd Email", label: "Send 2nd Email"},
          {value: "Send 3rd Email", label: "Send 3rd Email"},
          {value: "Send 4th Email", label: "Send 4th Email"},
          {value: "Cold Call 1", label: "Cold Call 1"},
          {value: "Cold Call 2", label: "Cold Call 2"},
          {value: "Call again", label: "Call again"},
          {value: "Periodic Check In", label: "Periodic Check In"},
      ]
  },
  companyDueDateStartFilter: {
      name: 'Due Date Start',
      value: null,
      defaultValue: null
  },
  companyDueDateEndFilter: {
      name: 'Due Date End',
      value: null,
      defaultValue: null
  },
  companyStateFilter: {
      name: 'State',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: 'alabama', label: 'Alabama'},
          {value: 'alaska', label: 'Alaska'},
          {value: 'arizona', label: 'Arizona'},
          {value: 'arkansas', label: 'Arkansas'},
          {value: 'california', label: 'California'},
          {value: 'colorado', label: 'Colorado'},
          {value: 'connecticut', label: 'Connecticut'},
          {value: 'delaware', label: 'Delaware'},
          {value: 'florida', label: 'Florida'},
          {value: 'georgia', label: 'Georgia'},
          {value: 'hawaii', label: 'Hawaii'},
          {value: 'idaho', label: 'Idaho'},
          {value: 'illinois', label: 'Illinois'},
          {value: 'indiana', label: 'Indiana'},
          {value: 'iowa', label: 'Iowa'},
          {value: 'kansas', label: 'Kansas'},
          {value: 'kentucky', label: 'Kentucky'},
          {value: 'louisiana', label: 'Louisiana'},
          {value: 'maine', label: 'Maine'},
          {value: 'maryland', label: 'Maryland'},
          {value: 'massachusetts', label: 'Massachusetts'},
          {value: 'michigan', label: 'Michigan'},
          {value: 'minnesota', label: 'Minnesota'},
          {value: 'mississippi', label: 'Mississippi'},
          {value: 'missouri', label: 'Missouri'},
          {value: 'montana', label: 'Montana'},
          {value: 'nebraska', label: 'Nebraska'},
          {value: 'nevada', label: 'Nevada'},
          {value: 'new_hampshire', label: 'New Hampshire'},
          {value: 'new_jersey', label: 'New Jersey'},
          {value: 'new_mexico', label: 'New Mexico'},
          {value: 'new_york', label: 'New York'},
          {value: 'north_carolina', label: 'North Carolina'},
          {value: 'north_dakota', label: 'North Dakota'},
          {value: 'ohio', label: 'Ohio'},
          {value: 'oklahoma', label: 'Oklahoma'},
          {value: 'oregon', label: 'Oregon'},
          {value: 'pennsylvania', label: 'Pennsylvania'},
          {value: 'rhode_island', label: 'Rhode Island'},
          {value: 'south_carolina', label: 'South Carolina'},
          {value: 'south_dakota', label: 'South Dakota'},
          {value: 'tennessee', label: 'Tennessee'},
          {value: 'texas', label: 'Texas'},
          {value: 'utah', label: 'Utah'},
          {value: 'vermont', label: 'Vermont'},
          {value: 'virginia', label: 'Virginia'},
          {value: 'washington', label: 'Washington'},
          {value: 'west_virginia', label: 'West Virginia'},
          {value: 'wisconsin', label: 'Wisconsin'},
          {value: 'wyoming', label: 'Wyoming'}
      ]
  },
  companyDrivingTimeFocusFilter: {
      name: 'Driving Time Focus',
      value: null,
      defaultValue: null,
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyDrivingTimeFilter: {
      name: 'Driving Time',
      value: null,
      defaultValue: null
  },
  companyDrivingTimeExpressionFilter: {
      name: 'Driving Time Expression',
      value: 'mins',
      defaultValue: 'mins'
  },
  companyNAICSFilter: {
      name: 'NAICS Code',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyTaskUserFilter: {
      name: 'Task User',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyShowResearchHaltedFilter: {
      name: 'Show Research Halted',
      value: 'False',
      defaultValue: 'False',
      options: [
          {value: "True", label: "Show Research Halted"},
          {value: "False", label: "Don't Show Research Halted"}
      ]
  },
  companyParentCompanyStatusFilter: {
      name: 'Parent Company Status',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"},
          {value: 'has_parent_company', label: 'Has Parent Company'},
          {value: 'no_parent_company', label: 'No Parent Company'},
      ]
  },
  companyInvestorsStatusFilter: {
      name: 'Investor Status',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"},
          {value: 'has_investors', label: 'Has Investors'},
          {value: 'no_investors', label: 'No Investors'},
      ]
  },
  companyProblemStatusFilter: {
      name: 'Problem Status',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"},
          {value: 'has_problem_company_notes', label: 'Has Problem Company Notes'},
          {value: 'no_problem_company_notes', label: 'No Problem Company Notes'},
      ]
  },
  companyAccountManagerResearchNotesFilter: {
      name: 'Account Manager Notes',
      value: null,
      defaultValue: null
  },
  companyIdFilter: {
      name: 'Company ID',
      value: null,
      defaultValue: null
  },
  companyCategoryFilter: {
      name: 'Category',
      value: null,
      defaultValue: null
  },
  companyRelevantBusinessFilter: {
      name: 'Relevant Business',
      value: null,
      defaultValue: null
  },
  companyEveraxisPillarFilter: {
      name: 'EverAxis Pillar',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: 'P1: Peers', label: 'P1: Peers'},
          {value: 'P2: EverAxis in Motion', label: 'P2: EverAxis in Motion'},
          {value: 'P3: Forever Connecting', label: 'P3: Forever Connecting'},  
      ]
  },
  companyReferralSourceFilter: {
      name: 'Referral Source',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyReferralStatusFilter: {
      name: 'Referral Status',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"},
          {value: 'has_referrer', label: 'Has Referrer'},
          {value: 'no_referrer', label: 'No Referrer'}
      ]
  },
  companyProductServiceCodeFilter: {
      name: 'Product Service Code',
      value: null,
      defaultValue: null
  },
  companyCollectionTagsFilter: {
      name: 'Collection Tags',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyCollectionTagsExpressionFilter: {
      name: 'Collection Tags Expression',
      value: 'all',
      defaultValue: 'all'
  },
  companyCollectionTagSourcesFilter: {
      name: 'Collection Tag Sources',
      value: [],
      defaultValue: [],
      options: [
          {value: 'clear', label: '------------'}
      ]
  },
  companyAccountManagerFilter: {
      name: 'Account Manager',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: 37, label: "Dan Klawitter"},
          {value: 23, label: "Matthew Strickler"},
          {value: 3, label: "Scott Shedd"}
      ]
  },
  companyCRMActionsFilter: {
      name: 'CRM Event',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"} 
      ]
  },
  companyCollectionTagExcludeFilter: {
      name: 'Collection Tag Exclude',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"} 
      ]
  },
  companyHasCRMActionsFilter: {
      name: 'Has CRM Events',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"}, 
          {value: "True", label: "True"} 
      ]
  },
  companyCapabilitiesFilter: {
      name: 'Capabilities',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"} 
      ]
  },
  companyParentTypeFilter: {
      name: 'Parent Type',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"},
          {value: "None", label: "No Parent Type"},
          {value: "Government Owned", label: "Government Owned"},
          {value: "Private Equity Owned", label: "Private Equity Owned"},
          {value: "Proprietor Owned", label: "Proprietor Owned"},
          {value: "Public", label: "Public"}, 
      ]
  },
  companyHasConflictsFilter: {
      name: 'Has Conflicts',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"},
          {value: "True", label: "True"},
          {value: "False", label: "False"}
      ]
  },
  companyCollectionReviewFilter: {
      name: 'Collection Review',
      value: null,
      defaultValue: null,
      options: [
          {value: null, label: "------------"},
          {value: "None", label: "None"},
          {value: "Interesting", label: "Interesting"},
          {value: "Not Interesting", label: "Not Interesting"},
      ]
  },
  companyCageCodeFilter: {
      name: 'Cage Code',
      value: null,
      defaultValue: null
  },
  companyPreviousCampaignFilter: {
      name: 'Previous Campaign',
      value: [],
      defaultValue: [],
      options: [
          {value: "clear", label: "------------"}
      ]
  },
  companyUeiSamFilter: {
      name: 'UEI Sam',
      value: null,
      defaultValue: null
  },
  companyIgpCategoryFilter: {
    name: "IGP Category",
    value: [],
    defaultValue: [],
    options: [
        {value: "clear", label: "------------"},
        {value: "Elastomer", label: "Elastomer"},
        {value: "HMI", label: "HMI"},
        {value: "Insulation", label: "Insulation"},
        {value: "M&I", label: "M&I"},
        {value: "Materials", label: "Materials"},
        {value: "Microwave/RF", label: "Microwave/RF"},
        {value: "Oscillators", label: "Oscillators"},
        {value: "Other", label: "Other"},
        {value: "Photonics/Optics", label: "Photonics/Optics"},
        {value: "Valves, Actuators, Pumps", label: "Valves, Actuators, Pumps"},
    ]
  },
  companyCountryFilter: {
    name: "Country",
    value: [],
    defaultValue: [],
    options: [
        {value: "clear", label: "------------"},
        {value: "USA", label: "USA"}
    ]
  },
})

const emit = defineEmits(['change-company-filters', 'set-changed-company-filters'])
const route = useRoute()
const routeCampaign = ref(route.params.campaignId)

const userPermissions = shallowReactive({
  isManagement: null,
  isHourly: null,
  isStudent: null,
  isResearch: null,
  isAdvancedResearch: null,
  isSuperuser: null,
  isAccountManager: null,
  isAccountStaff: null,
  isDev: null,
  isResearchLead: null,
  isAllCompanies: null,
  isGenerateTimecard: null,
});

const globalStore = useStore()
const showBasicFilters = computed(() => globalStore.getters['company/showBasicFilters'])
const showCampaignFilters = computed(() => globalStore.getters['company/showCampaignFilters'])
const showResearchFilters = computed(() => globalStore.getters['company/showResearchFilters'])
const showActiveFilters = computed(() => globalStore.getters['company/showActiveFiltersVisible'])
const visibleFilters = computed(() => globalStore.getters['company/showVisibleFilters'])

const selectedConfig = computed(() => {
  return globalStore.getters['pageConfigs/showSelectedPageConfig']
})

const filterValues = computed(() => {
  let values = []
  for(const filter in companyFilters){
      values.push(companyFilters[filter].value)
  }
  return values
})

watch(filterValues, () => {
  emitFiltersChanged()
}, {deep: true})

function emitFiltersChanged(){
  emit("change-company-filters", companyFilters)
}

function setConfigFilters(pageConfig){
  console.log('setting page config')
  if(pageConfig && route.name == 'configurable-companies'){
      if(pageConfig.default_filters.length > 0){
          let defaultFilters = pageConfig.default_filters
          defaultFilters.forEach((filter) => {
          if(filter.value){
              if(Array.isArray(companyFilters[filter.filter_field.vue_filter_name].defaultValue)){
                  if(!isNaN(parseFloat(filter.value)) && isFinite(filter.value)){
                      companyFilters[filter.filter_field.vue_filter_name].value = [parseInt(filter.value)]
                  } else {
                      companyFilters[filter.filter_field.vue_filter_name].value = [filter.value]
                  }
              } else{
                  companyFilters[filter.filter_field.vue_filter_name].value = filter.value
              }
          }})
      }
  }
}

watch(selectedConfig, (newValue, oldValue) => {
  if(route.name == 'configurable-companies'){
      for(const filter in companyFilters){
          companyFilters[filter].value = companyFilters[filter].defaultValue
      }
      setConfigFilters(newValue)
  }
})

async function grabCampaign(){
  await setCampaignOptions()
  if(routeCampaign.value){
      companyFilters.companyCampaignFilter.value = [parseInt(routeCampaign.value)]
  }
}


const showFilter = ref(true);
const showFilterText = ref('Hide All Filters');
const showCompanyInfoFilters = ref(true);
const showCompanyInfoFiltersText = ref('Hide Company Info Filters');
const showCampaignInfoFilters = ref(true);
const showCampaignInfoFiltersText = ref('Hide Campaign Info Filters');
const showResearchInfoFilters = ref(true);
const showResearchInfoFiltersText = ref('Hide Research Info Filters');

function hideBasicFilters() {
  store.dispatch('company/setShowBasicFiltersFalse')
}

function hideCampaignFilters() {
  store.dispatch('company/setShowCampaignFiltersFalse')
}

function hideResearchFilters() {
  store.dispatch('company/setShowResearchFiltersFalse')
}

function hideActiveFilters() {
  store.dispatch('company/setShowActiveFiltersFalse')
}


function setCompanyCapabilitiesFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCapabilitiesFilter.value = []
  } else {
      companyFilters.companyCapabilitiesFilter.value = target;
  }
}
function setCompanyIgpCategoryFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyIgpCategoryFilter.value = []
  } else {
      companyFilters.companyIgpCategoryFilter.value = target;
  }
}

function setCompanyPreviousCampaignsFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyPreviousCampaignFilter.value = []
  } else {
      companyFilters.companyPreviousCampaignFilter.value = target;
  }
}


function setCompanyEveraxisPillarFilter(target) {
// emitChangeCompanyFiltersAndSetChanged()
  target.includes("clear")  ? companyFilters.companyEveraxisPillarFilter.value = [] : companyFilters.companyEveraxisPillarFilter.value = target    
}

function setCompanyReferralSourceFilter(target) {
  target.includes("clear")  ? companyFilters.companyReferralSourceFilter.value = [] : companyFilters.companyReferralSourceFilter.value = target     
// emitChangeCompanyFiltersAndSetChanged()
}


function setCompanyParentTypeFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyParentTypeFilter.value = []
  } else {
      companyFilters.companyParentTypeFilter.value = target;
  }
}

function toggleFilter() {
  showFilter.value = !showFilter.value;
  if (showFilterText.value === 'Hide All Filters') {
  showFilterText.value = 'Show All Filters'
  } else {
  showFilterText.value = 'Hide All Filters'
  }
}

function clearFilter(filterName) {
  if(Array.isArray(companyFilters[filterName].value)){
      companyFilters[filterName].value = []
  } else {
      companyFilters[filterName].value = null
  }
}

function setCompanySourcesFilter(target) {
  if(target.includes("clear")){
      companyFilters.companySourcesFilter.value = []
  } else {
      companyFilters.companySourcesFilter.value = target;
  }
}

function setCompanyTaskUserFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyTaskUserFilter.value = []
  } else {
      companyFilters.companyTaskUserFilter.value = target;
  }
}

function setCompanyClientFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyClientFilter.value = []
  } else {
      companyFilters.companyClientFilter.value = target;
  }
}

function setCompanySourceOwnersFilter(target) {
  if(target.includes("clear")){
      companyFilters.companySourceOwnerFilter.value = []
  } else {
      companyFilters.companySourceOwnerFilter.value = target;
  }
}

function setCompanyCRMActionsFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCRMActionsFilter.value = []
  } else {
      companyFilters.companyCRMActionsFilter.value = target;
  }
}

function setCompanyCampaignFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCampaignFilter.value = []
  } else {
      companyFilters.companyCampaignFilter.value = target;
  }
}

function setCompanyCampaignExcludeFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCampaignExcludeFilter.value = []
  } else {
      companyFilters.companyCampaignExcludeFilter.value = target;
  }
}

function setCompanyAccountManagerFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyAccountManagerFilter.value = []
  } else {
      companyFilters.companyAccountManagerFilter.value = target;
  }
}

function setCompanyTierFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyTierFilter.value = []
  } else {
      companyFilters.companyTierFilter.value = target;
  }
}

function setCompanyBusinessTypeFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyBusinessTypeFilter.value = []
  } else {
      companyFilters.companyBusinessTypeFilter.value = target;
  }
}

function setCompanySectorFilter(target) {
  if(target.includes("clear")){
      companyFilters.companySectorFilter.value = []
  } else {
      companyFilters.companySectorFilter.value = target;
  }
}

function setCompanySubSectorFilter(target) {
  if(target.includes("clear")){
      companyFilters.companySubSectorFilter.value = []
  } else {
      companyFilters.companySubSectorFilter.value = target;
  }
}

function setCompanyClientFeedbackFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyClientFeedbackFilter.value = []
  } else {
      companyFilters.companyClientFeedbackFilter.value = target;
  }
}

function setCompanyNAICSFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyNAICSFilter.value = []
  } else {
      companyFilters.companyNAICSFilter.value = target;
  }
}

function setCompanyResearchStatusFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyResearchStatusFilter.value = []
  } else {
      companyFilters.companyResearchStatusFilter.value = target;
  }
}

function setResearchStatusExpression(target) {
  if(companyFilters.companyResearchStatusFilter.value.length > 0){
      companyFilters.companyResearchStatusExpressionFilter.value = target
  }
}

function setCompanyResearchStatusFilterTwo(target) {
  if(target.includes("clear")){
      companyFilters.companyResearchStatusFilterTwo.value = []
  } else {
      companyFilters.companyResearchStatusFilterTwo.value = target;
  }
}

function setCompanyCountryFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCountryFilter.value = []
  } else {
      companyFilters.companyCountryFilter.value = target;
  }
}

function setResearchStatusExpressionTwo(target) {
  if(companyFilters.companyResearchStatusFilterTwo.value.length > 0){
      companyFilters.companyResearchStatusExpressionFilterTwo.value = target
  }
}

function setCompanyStateFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyStateFilter.value = []
  } else {
      companyFilters.companyStateFilter.value = target;
  }
}

function setCompanyDrivingTimeExpression(target) {
  if(companyFilters.companyDrivingTimeFilter.value){
      companyFilters.companyDrivingTimeExpressionFilter.value = target
  }
}

function setCollectionTagsExpression(target) {
  if(companyFilters.companyCollectionTagsFilter.value) {
      companyFilters.companyCollectionTagsExpressionFilter.value = target
  }
}

const companyCollectionTagsFilter = computed(() => {
  return companyFilters.companyCollectionTagsFilter.value
})

watch(companyCollectionTagsFilter, (newValue, oldValue)=> {
  if(!(companyCollectionTagsFilter.value.length > 0)){
      companyFilters.companyCollectionTagSourcesFilter.value = []
  }
  if(newValue.length < oldValue.length){
      companyFilters.companyCollectionTagSourcesFilter.value = []
  }
})

const collectionTagsExpression = ref('all')

watch(collectionTagsExpression, ()=> {
  setCollectionTagOptions()
})

function setCompanyCollectionTagsFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCollectionTagsFilter.value = []
      companyFilters.companyCollectionTagSourcesFilter.value = []
      companyFilters.collectionTagSourceOptions.value = []
  } else {
      companyFilters.companyCollectionTagsFilter.value = target;
  }
}
function setCompanyCollectionTagExcludeFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCollectionTagExcludeFilter.value = []
  } else {
      companyFilters.companyCollectionTagExcludeFilter.value = target;
  }
}

function setCompanyCollectionTagSourcesFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCollectionTagSourcesFilter.value = []
  } else {
      companyFilters.companyCollectionTagSourcesFilter.value = target;
  }
}

function setCompanyCRMStatusFilter(target) {
  if(target.includes("clear")){
      companyFilters.companyCRMStatusFilter.value = []
  } else {
      companyFilters.companyCRMStatusFilter.value = target;
  }
}

function setCompanyCRMNextActionFilter(target) {
if(target.includes("clear")){
  companyFilters.companyCRMNextActionFilter.value = []
} else {
  companyFilters.companyCRMNextActionFilter.value = target;
}
}

function toggleShowCompanyFilter(filterName) {
  if(visibleFilters.value[filterName]){
  store.dispatch('company/setVisibleFilters', {filterName: filterName, status: false})
} else {
  store.dispatch('company/setVisibleFilters', {filterName: filterName, status: true})
}
}

function resetFilters() {
  for(const filter in companyFilters){
      companyFilters[filter].value = companyFilters[filter].defaultValue
  }
}

const setUserPermissions = function () {
  let userPermissionsStore = store.getters['auth/showUserPermissions']
  userPermissions.isManagement = userPermissionsStore.isManagement
  userPermissions.isHourly = userPermissionsStore.isHourly
  userPermissions.isStudent = userPermissionsStore.isStudent
  userPermissions.isResearch = userPermissionsStore.isResearch
  userPermissions.isAdvancedResearch = userPermissionsStore.isAdvancedResearch
  userPermissions.isSuperuser = userPermissionsStore.isSuperuser
  userPermissions.isAccountManager = userPermissionsStore.isAccountManager
  userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff
  userPermissions.isDev = userPermissionsStore.isDev
  userPermissions.isResearchLead = userPermissionsStore.isResearchLead
  userPermissions.isAllCompanies = userPermissionsStore.isAllCompanies
  userPermissions.isGenerateTimecard = userPermissionsStore.isGenerateTimecard
}

async function setSourceOptions() {
  const token = store.getters['auth/showToken']
  const result = await getSources(token)
  companyFilters.companySourcesFilter.options = [{value: "clear", label: "------------"}, {value: "null", label: "Unassigned"}, ...result.map((source) => {
      return {value: source.id, label: source.source_name }
  })]
}
async function setCRMStatusOptions() {
  const token = store.getters['auth/showToken']
  const result = await getCrmStatuses(token)
  companyFilters.companyCRMStatusFilter.options = [{value: "clear", label: "------------"}, {value: "null", label: "Unassigned"}, ...result.map((status) => {
      return {value: status.id, label: status.status_name }
  })]
}

async function setCRMActionOptions() {
  const token = store.getters['auth/showToken']
  const result = await getActions(token)
  companyFilters.companyCRMActionsFilter.options = [{value: "clear", label: "------------"}, {value: "null", label: "None"}, ...result.data.map((action) => {
      return {value: action.id, label: action.action_name }
  })]
}

async function setTaskUserOptions(){
  const token = store.getters['auth/showToken']
  const result = await getUsersWithTasks(token)
  companyFilters.companyTaskUserFilter.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
      return {value: user.id, label: user.first_name + " " + user.last_name }
  })]
}

async function setSourceOwnerOptions() {
  const token = store.getters['auth/showToken']
  const result = await getSourceOwners(token)
  companyFilters.companySourceOwnerFilter.options = [{value: "clear", label: "------------"}, {value: "None", label: "None"}, ...result.map((sourceOwner) => {
      return {value: sourceOwner, label: sourceOwner}
  })]
}

async function setCampaignOptions() {
  const token = store.getters['auth/showToken']
  const result = await getCampaigns(token)
  companyFilters.companyCampaignFilter.options = [{value: "clear", label: "------------"}, {value: "null", label: "Unassigned"}, ...result.map((campaign) => {
      return {value: campaign.id, label: campaign.campaign_name}
  })]
  companyFilters.companyPreviousCampaignFilter.options = [{value: "clear", label: "------------"}, ...result.map((campaign) => {
      return {value: campaign.id, label: campaign.campaign_name}
  })]
  companyFilters.companyCampaignExcludeFilter.options = [{value: "clear", label: "------------"}, ...result.map((campaign) => {
      return {value: campaign.id, label: campaign.campaign_name}
  })]
}

async function setTierOptions() {
  const token = store.getters['auth/showToken']
  const result = await getTiers(token)
  companyFilters.companyTierFilter.options = [{value: "clear", label: "------------"}, {value: "unmarked", label: "None"}, ...result.map((tier) => {
      return {value: tier.id, label: tier.tier_name}
  })]
}

async function setClientOptions() {
  const token = store.getters['auth/showToken']
  const result = await getClients({token: token})
  companyFilters.companyClientFilter.options = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
      return {value: client.id, label: client.client_name}
  })]
}

async function setCapabilitiesOptions() {
  const token = store.getters['auth/showToken']
  const result = await getCapabilities(token)
  companyFilters.companyCapabilitiesFilter.options = [{value: "clear", label: "------------"},{value: "null", label: "None"}, ...result.map((capability) => {
      return {value: capability.id, label: capability.capability_name}
  })]
}

async function setBusinessTypeOptions() {
  const token = store.getters['auth/showToken']
  const result = await getBusinessTypes(token)
  companyFilters.companyBusinessTypeFilter.options = [{value: "clear", label: "------------"}, ...result.map((businessType) => {
      return {value: businessType.id, label: businessType.business_type_name}
  })]
}

async function setSectorOptions() {
  const token = store.getters['auth/showToken']
  const result = await getSectors(token)
  companyFilters.companySectorFilter.options = [{value: "clear", label: "------------"}, ...result.map((sector) => {
      return {value: sector.id, label: sector.sector_name}
  })]
}

async function setSubSectorOptions() {
  const token = store.getters['auth/showToken']
  const result = await getSubSectors(token)
  companyFilters.companySubSectorFilter.options = [{value: "clear", label: "------------"}, ...result.map((subSector) => {
      return {value: subSector.id, label: subSector.sub_sector_name}
  })]
}

async function setCollectionTagOptions() {
  const token = store.getters['auth/showToken']
  let result = null
  if(collectionTagsExpression.value == 'all') {
      result = await getCollectionTags(token)
  } else if (collectionTagsExpression.value == 'mine') {
      result = await getCollectionTagsForActiveUser(token)
  }
  companyFilters.companyCollectionTagsFilter.options = [{value: "clear", label: "------------"}, ...result.map((collectionTag) => {
      return {value: collectionTag.id, label: collectionTag.collection_tag_name }
  })]
  companyFilters.companyCollectionTagExcludeFilter.options = [{value: "clear", label: "------------"}, ...result.map((collectionTag) => {
      return {value: collectionTag.id, label: collectionTag.collection_tag_name }
  })]
}

async function setCollectionTagSourceOptions(collectionTagIDs=null) {
  console.log('collectionTagIDs', collectionTagIDs)
  let query = '?is_collection_tag_source=True'
  if(collectionTagIDs){
      for(const collectionTagID of collectionTagIDs){
      query += `&collection_tag=${collectionTagID}`
      }
  }
  console.log('query', query)
  let payload = {
      token: store.getters['auth/showToken'],
      endpoint: query
  }
  const result = await getAsynchronousJobQueue(payload)
  companyFilters.companyCollectionTagSourcesFilter.options = [{value: "clear", label: "------------"}, ...result.jobs.map((job) => {
      return {value: job.id, label: `${formatDateTime(job.created_at)} - ${job.job_title}`}
  })]
}

async function setNAICSOptions() {
  const token = store.getters['auth/showToken']
  const result = await getNAICSCodes(token)
  companyFilters.companyNAICSFilter.options = [{value: "clear", label: "------------"}, ...result.map((naics) => {
      return {value: naics.id, label: `${naics.code} - ${naics.title}` }
  })]
}

async function setDrivingTimeFocusOptions() {
  const token = store.getters['auth/showToken']
  const result = await getGeoLocations(token)
  companyFilters.companyDrivingTimeFocusFilter.options = [{value: "clear", label: "------------"},...result.map((drivingTimeFocus) => {
      return {value: drivingTimeFocus.id, label: drivingTimeFocus.geo_location_name }
  })]
}

const activeFilters = computed(() => {
  let activeFilterArr = []
  const skipFilters = ['Research Status Expression', 'Research Status Expression 2', 'Driving Time Expression', 'Collection Tags Expression']
  for(const filter in companyFilters) {
      if(skipFilters.includes(companyFilters[filter].name)){
          continue
      }
      if(Array.isArray(companyFilters[filter].value) && companyFilters[filter].value.length > 0){
          // console.log('found array')
          activeFilterArr.push({filter: filter, name: companyFilters[filter].name, value: companyFilters[filter].options.filter(option => companyFilters[filter].value.includes(option.value)).map(option => option.label)})
      } else if (!Array.isArray(companyFilters[filter].value) && companyFilters[filter].value) {
          activeFilterArr.push({filter: filter, name: companyFilters[filter].name, value: companyFilters[filter].value})
      }
  }
  return activeFilterArr
})

async function setReferralSourceOptions() {
  const payload = {
      token: store.getters['auth/showToken'],
      endpoint: '?status=active'
  }
  const result = await getReferralSources(payload)
  companyFilters.companyReferralSourceFilter.options = [{value: "clear", label: "------------"},...result.referrals.map((referralSource) => {
      return {value: referralSource.id, label: `${referralSource.referrer_first_name} ${referralSource.referrer_last_name} - ${referralSource.referrer_email}`}
  })]  
}





grabCampaign()
setTaskUserOptions()
setSourceOptions()
setSourceOwnerOptions()
setTierOptions()
setClientOptions()
setBusinessTypeOptions()
setSectorOptions()
setSubSectorOptions()
setCollectionTagOptions()
setNAICSOptions()
setDrivingTimeFocusOptions()
setReferralSourceOptions()
setCRMStatusOptions()
setCRMActionOptions()
setUserPermissions()
setCapabilitiesOptions()
setConfigFilters(selectedConfig.value)
</script>

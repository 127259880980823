<template>
    <button :disabled="disabled" class="
        rounded-md
        px-1
        py-1
        bg-accent-primary
        text-white
        border
        border-accent-primary 
        hover:shadow-md
        active:text-white
        active:bg-accent-secondary
        focus:text-white
        focus:bg-accent-secondary
        text-xs
        cursor-pointer
        sm:text-xs
        sm:h-fit
      "
      :class="{
        'bg-gray-500 border-gray-500 hover:cursor-not-allowed active:bg-gray-500 focus:bg-gray-500': disabled 
      }"
      >
        <slot></slot>
    </button>
  
  </template>
  
  <script>
  export default {
    name: "BaseButtonAffirmative.vue"
  }
  </script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  disabled: Boolean
})
</script>

  <style scoped>
  </style>
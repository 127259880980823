<template>
      <form @submit.prevent="submitForm" action="" class="h-full w-full">
        <base-form class="h-full w-full">
          <template #body>
            <div class="flex justify-between">
              <h3 class="text-sm font-bold">Referrer</h3>
            </div>
            <div class="grid xl:grid-cols-6 lg-grid-cols-1 md:grid-cols-1 gap-2 mt-2 sm:grid-cols-1 text-xs py-1">
              <div class="flex-col">
                <div class="mb-0.5" for="">First Name*</div>
                <input
                  type="text"
                  required
                  class="border border-dark-primary rounded-md p-1 w-full"
                  v-model="firstName"
                />
              </div>
              <div class="flex-col">
                <div class="mb-0.5" for="">Last Name*</div>
                <input
                  type="text"
                  class="border border-dark-primary rounded-md p-1 w-full"
                  v-model="lastName"
                />
              </div>
              <div class="flex-col">
                <div class="mb-0.5" for="">Email*</div>
                <input
                  type="text"
                  class="border border-dark-primary rounded-md p-1 w-full"
                  v-model="email"
                />
              </div>
              <div class="flex-col">
                <div class="mb-0.5">Phone Number</div>
                <input
                  type="text"
                  class="border border-dark-primary rounded-md p-1 w-full"
                  v-model="phone"
                />
              </div>
              <div class="flex-col col-span-2">
                <div class="mb-0.5">Address</div>
                <input
                  type="text"
                  class="border border-dark-primary rounded-md p-1 w-full"
                  v-model="address"
                />
              </div>
            </div>
          </template>
          <template #footer>
            <div class="flex flex-col items-center my-2">
              <base-button-affirmative type="submit"
                >Save</base-button-affirmative
              >
            </div>
          </template>
        </base-form>
      </form>
</template>

<script setup>
import { ref, defineEmits } from "vue";

const emit = defineEmits(["submit-form"]);

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const phone = ref("");
const address = ref("");

function submitForm() {
  const formData = JSON.stringify({
    referrer_first_name: firstName.value,
    referrer_last_name: lastName.value,
    referrer_email: email.value,
    referrer_phone: phone.value,
    referrer_address: address.value,
  });
  emit("submit-form", formData);
}
</script>

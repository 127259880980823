import pageConfigMutations from './mutations.js';
import pageConfigActions from './actions.js';
import pageConfigGetters from './getters.js';


export default {
    namespaced: true,
    state() {
        return {
            pageConfigs: [],
            selectedPageConfig: null,
            pageConfigLoading: false,
        };
    },
    mutations: pageConfigMutations,
    actions: pageConfigActions,
    getters: pageConfigGetters
};
<template>
  <td class="p-1">
    <ResearcherCell :projectAssignment="projectAssignment" />
  </td>
  <td class="p-1">
    <ResearchProjectCell :projectAssignment="projectAssignment" />
  </td>
  <td class="p-1">
    <ActionsCell
      @open-edit-window="openEditWindow"
      @open-archive-window="openArchiveWindow"
      :projectAssignment="projectAssignment"
    />
  </td>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import ResearcherCell from "./project-assignment-cell-components/ResearcherCell.vue";
import ResearchProjectCell from "./project-assignment-cell-components/ResearchProjectCell.vue";
import StartDateCell from "./project-assignment-cell-components/StartDateCell.vue";
import EndDateCell from "./project-assignment-cell-components/EndDateCell.vue";
import HourlyCapacityPerWeekCell from "./project-assignment-cell-components/HourlyCapacityPerWeekCell.vue";
import ActionsCell from "./project-assignment-cell-components/ActionsCell.vue";
const props = defineProps({
  projectAssignment: Object,
});
const emit = defineEmits(["open-edit-window", "open-archive-window"]);

function openEditWindow() {
  emit("open-edit-window", props.projectAssignment);
}

function openArchiveWindow() {
  emit("open-archive-window", props.projectAssignment);
}
</script>

export default {
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
    setShowActionsPaneTrue(state) {
        state.showActionsPane = true;
    },
    setShowActionsPaneFalse(state) {
        state.showActionsPane = false;
    },
    setShowTimecardUsersTrue(state) {
        state.showTimecardUsers = true;
    },
    setShowTimecardUsersFalse(state) {
        state.showTimecardUsers = false;
    },
}
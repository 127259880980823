<template>
    <form @submit.prevent class="w-full">
        <textarea
            class="block p-2 w-full border border-gray-400 rounded-md" 
            ref="editText"
            rows="10"
            @keyup.alt.enter.exact="addLine"
            @keydown.enter.exact.prevent="handleInputChangeAndClose"
            :value="inputText"
            name="inputName"
        >
        </textarea>
    </form>
</template>

<script setup>
import { updateCompany } from '@/services/Company';
import { updateContact } from '@/services/Contact';
import { updateCompanyContact } from '@/services/CompanyContact';
import { updateContactCompanyRelationship } from '@/services/Contact';
import { updateContactResearchNote } from '@/services/ContactResearchNote';
import { updateBusinessType } from '@/services/BusinessType';
import { updateSector } from '@/services/Sector';
import { updateSubSector } from '@/services/SubSector';
import { updateServiceRecord } from '@/services/ServiceRecord';
import { defineProps, defineEmits, reactive } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const props = defineProps([
    'inputText',
    'modelId',
    'fieldName',
    'inputType',
    'modelType'
])

const emit = defineEmits([
    'toggle-editable',
    'refetch',
    'close-modal',
    'update-field-value'
])

const modelUpdateMethodMapping = reactive({
    company: updateCompany.bind(null),
    contact: updateContact.bind(null),
    companyContact: updateCompanyContact.bind(null),
    contactCompanyRelationship: updateContactCompanyRelationship.bind(null),
    contactResearchNote: updateContactResearchNote.bind(null),
    businessType: updateBusinessType.bind(null),
    sector: updateSector.bind(null),
    subSector: updateSubSector.bind(null),
    serviceRecord: updateServiceRecord.bind(null),
})

async function handleInputChange(e) {
    if(props.modelType) {
        let newValue = e.target.value
        const targetField = props.fieldName
        let updatedData = {}
        updatedData[targetField] = newValue
        const data = JSON.stringify(updatedData);
        console.log('data', data)
        let token = store.state.auth.token
        console.log('company id', props.modelId)
        console.log('new name', newValue)
        let result = null
        if(props.modelType in modelUpdateMethodMapping) {
            const modelUpdateMethod = modelUpdateMethodMapping[props.modelType]
            result = await modelUpdateMethod(props.modelId, data, token)
        // if(props.modelType=='company') {
        //     result = await updateCompany(props.modelId, data, token)
        // } else if(props.modelType=='contact') {
        //     result = await updateContact(props.modelId, data, token)
        // } else if(props.modelType=='companyContact') {
        //     result = await updateCompanyContact(props.modelId, data, token)
        // } else if(props.modelType=='contactCompanyRelationship') {
        //     result = await updateContactCompanyRelationship(props.modelId, data, token)
        // } else if(props.modelType=='contactResearchNote') {
        //     result = await updateContactResearchNote(props.modelId, data, token)
        // } else if(props.modelType=='businessType') {
        //     result = await updateBusinessType(props.modelId, data, token)
        // } else if(props.modelType=='sector') {
        //     result = await updateSector(props.modelId, data, token)
        // } else if(props.modelType=='subSector') {
        //     result = await updateSubSector(props.modelId, data, token)
        } else {
            console.log('BaseEditTextArea: modelType not recognized.')
            $toast.open({
                message: `model name not recognized.`,
                type: 'error',
                duration: 20000
            })
            return
        }
        if(result.status == 200) {
            $toast.open({
                message: `Successfully updated ${props.fieldName} for ${props.modelType} (id-${props.modelId}).`,
                type: 'success',
                duration: 5000
            })
            emit('toggle-editable')
            emit('refetch')
            if(!newValue) {
                emit('update-field-value', '--')
            } else {
                emit('update-field-value', newValue)
            }
        } else {
            let errorMessage = result.response.data[props.fieldName][0]
            $toast.open({
                message: `Unable to update ${props.fieldName} for ${props.modelType} (id-${props.modelId}). \n ${errorMessage}`,
                type: 'error',
                duration: 20000
            })
        }
    } else {
        console.log('BaseEditTextArea: No modelType defined')
        $toast.open({
            message: `No model selected.`,
            type: 'error',
            duration: 20000
        })
    }
}

async function handleInputChangeAndClose(e) {
    if(props.modelType) {
        let newValue = e.target.value
        const targetField = props.fieldName
        let updatedData = {}
        updatedData[targetField] = newValue
        const data = JSON.stringify(updatedData);
        console.log('data', data)
        let token = store.state.auth.token
        console.log('company id', props.modelId)
        console.log('new name', newValue)
        let result = null
        if(props.modelType in modelUpdateMethodMapping) {
            const modelUpdateMethod = modelUpdateMethodMapping[props.modelType]
            result = await modelUpdateMethod(props.modelId, data, token)
        // if(props.modelType=='company') {
        //     result = await updateCompany(props.modelId, data, token)
        // } else if(props.modelType=='contact') {
        //     result = await updateContact(props.modelId, data, token)
        // } else if(props.modelType=='companyContact') {
        //     result = await updateCompanyContact(props.modelId, data, token)
        // } else if(props.modelType=='contactCompanyRelationship') {
        //     result = await updateContactCompanyRelationship(props.modelId, data, token)
        // } else if(props.modelType=='contactResearchNote') {
        //     result = await updateContactResearchNote(props.modelId, data, token)
        // } else if(props.modelType=='businessType') {
        //     result = await updateBusinessType(props.modelId, data, token)
        // } else if(props.modelType=='sector') {
        //     result = await updateSector(props.modelId, data, token)
        // } else if(props.modelType=='subSector') {
        //     result = await updateSubSector(props.modelId, data, token)
        } else {
            console.log('BaseEditTextArea: modelType not recognized.')
            $toast.open({
                message: `model name not recognized.`,
                type: 'error',
                duration: 20000
            })
            return
        }
        if(result.status == 200) {
            $toast.open({
                message: `Successfully updated ${props.fieldName} for ${props.modelType} (id-${props.modelId}).`,
                type: 'success',
                duration: 5000
            })
            emit('toggle-editable')
            emit('refetch')
            emit('close-modal')
            if(!newValue) {
                emit('update-field-value', '--')
            } else {
                emit('update-field-value', newValue)
            }
        } else {
            $toast.open({
                message: `Unable to update ${props.fieldName} for company (id-${props.modelId}).`,
                type: 'error',
                duration: 20000
            })
        }
    } else {
        console.log('BaseEditTextArea: No modelType defined')
        $toast.open({
            message: `No model selected.`,
            type: 'error',
            duration: 20000
        })
    }
}

function addLine(e) {
    const textArea = this.$refs.editText
    const cursorPosition = textArea.selectionStart
    e.target.value = e.target.value.slice(0, cursorPosition) + '\n' + e.target.value.slice(cursorPosition)
    textArea.selectionStart = cursorPosition
    textArea.selectionEnd = cursorPosition
}
</script>
<template>
    <div>
        <RevenueHeadcountRevLastUpdatedCell :company="company" />
        <ZiRevenueHeadcountCell :company="company" />
    </div>
</template>

<script>
    export default {
        name: "zi-revenue-headcount-revenue-headcount-rev-last-updated-cell"
    }
</script>

<script setup>
import { defineProps } from 'vue';
import ZiRevenueHeadcountCell from '../company-cell-components/ZiRevenueHeadcountCell.vue';
import RevenueHeadcountRevLastUpdatedCell from '../company-cell-components/RevenueHeadcountRevLastUpdatedCell.vue';

    const props = defineProps({
        company: Object
    })
</script>
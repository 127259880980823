<template>
    <div class="flex flex-col space-y-1 dark:text-black w-[20vh]">

        <div v-if="!currentRouteViewConfig">
            <p> There are no toggle view options for this page.</p>
        </div>

        <div v-else-if="!(currentRouteViewConfig.module)">
            <p> There are no toggle view options for this page.</p>
        </div>

        <div v-else>

            <div v-if="currentRouteViewConfig.hasShowAllHideAll">
                <ShowHideAllMenu
                    :module="currentRouteViewConfig.module"
                />
            </div>

            <div v-if="currentRouteViewConfig.hasFilters">
                <FilterMenu v-if="showCompanyFilterMenu()"/>
                <CustomizedFilterMenu v-if="showCustomizedFilterMenu()"
                    :module="currentRouteViewConfig.module"
                    :routeName="currentRouteName"
                />
            </div>
            <div v-if="currentRouteViewConfig.hasBulkOps">
               <BulkOperationsMenu
                    :module="currentRouteViewConfig.module"
                /> 
            </div>

            <div v-if="currentRouteViewConfig.hasStatusSummary">
                <StatusSummaryMenu
                    :module="currentRouteViewConfig.module"
                />
            </div>

            <div v-if="currentRouteViewConfig.hasStatusSummary">
                <CRMSummaryStagesMenu
                    :module="currentRouteViewConfig.module"
                />
            </div>
        </div>
    </div>   
</template>

<script setup>
import { computed, onMounted} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ShowHideAllMenu from './ShowHideAllMenu.vue';
import CustomizedFilterMenu from './CustomizedFilterMenu.vue';
import FilterMenu from './FilterMenu.vue';
import BulkOperationsMenu from './BulkOperationsMenu.vue';
import StatusSummaryMenu from './StatusSummaryMenu.vue'
import CRMSummaryStagesMenu from './CRMSummaryStagesMenu.vue';

// import ContactBulkOperationsMenu from './ContactBulkOperationsMenu.vue';
// import ClientFilterMenu from './ClientFilterMenu.vue';
// import ClientAppendixFilterMenu from './ClientAppendixFilterMenu.vue';
// import CampaignsFilterMenu from './CampaignsFilterMenu.vue';
// import ResearchFilterMenu from './ResearchFilterMenu.vue';
// import ReferralFilterMenu from './ReferralFilterMenu.vue';
// import TimecardFilterMenu from './TimecardFilterMenu.vue';
// import ContactFilterMenu from './ContactFilterMenu.vue';
// import JobQueueFilterMenu from './JobQueueFilterMenu.vue';
// import ContactShowHideAllMenu from './ContactShowHideAllMenu.vue';
// import JobQueueShowHideAllMenu from './JobQueueShowHideAllMenu.vue';

const route = useRoute()

const currentRouteName = computed(() => route.name)
const currentRouteViewConfig = computed(() => route.meta.viewMenuConfig)

function printViewConfig() {
   // console.log("VIEW CONFIG:")
   // console.log(currentRouteViewConfig.value)
}

onMounted(() => {
  printViewConfig();
});

function showCompanyFilterMenu() {
    //DLA QPL Page + Client Appendices are edge cases with less filters; all companies/campaigns pages have ALL filters
    return currentRouteName.value !=  'dla-qpl-companies' && currentRouteName.value != 'client-appendices' && currentRouteViewConfig.value.module === 'company';
}

function showCustomizedFilterMenu() {
    return currentRouteName.value == 'dla-qpl-companies' || currentRouteName.value == 'client-appendices' || currentRouteName.value == 'campaign-crm-status-summary';
}

// function showDefaultShowHideMenu() {
//     switch(currentRouteName.value) {
//     //  no show/hide all menu
//         case 'client-appendices':
//         case 'clients':
//         case 'referrers':
//         case 'timecards':
//         case 'crm-for-pdf':
//         case 'campaigns':
//         case 'parts_manufacturer_approvals':
//         case 'all-tasks':
//         case 'research-projects':
//         case 'project_assignments':
//         case 'emails-received':
//         case 'email-templates':
//             return false;

//     //  custom show/hide all menu
//         case 'contacts':
//             return false;
//         default:
//             return true;
//     }
// }

// function showContactShowHideMenu() {
//     return currentRouteName.value === 'contacts';
// }

// function showDefaultFilterMenu() {
//     switch(currentRouteName.value) {
//     //  has custom filters
//         case 'pdf-tier-view':
//         case 'clients':
//         case 'client-appendices':
//         case 'referrers':
//         case 'timecards':
//         case 'crm-for-pdf':
//         case 'campaigns':
//         case 'contacts':
//         case 'parts_manufacturer_approvals':
//         case 'all-tasks':
//         case 'research-projects':
//         case 'project_assignments':
//             return false;

//     //  has no filters
//         case 'emails-received':
//         case 'email-templates':
//             return false;
//         default:
//             return true;
//     }
// }

// function showClientFilterMenu() {
//     return currentRouteName.value == 'clients';
// }
// function showClientAppendixFilterMenu() {
//     return currentRouteName.value == 'client-appendices';
// }
// function showReferralFilterMenu() {
//     return currentRouteName.value == 'referrers';
// }
// function showTimecardsFilterMenu() {
//     return currentRouteName.value == 'timecards';
// }
// function showCampaignsFilterMenu() {
//     return (currentRouteName.value == 'campaigns' ||  currentRouteName.value == 'crm-for-pdf');
// }
// function showContactsFilterMenu() {
//     return currentRouteName.value == 'contacts';
// }
// function showResearchFilterMenu() {
//     return (currentRouteName.value == 'parts_manufacturer_approvals' || currentRouteName.value === 'all-tasks' || currentRouteName.value === 'research-projects' || currentRouteName.value == 'project_assignments');
// }


// function showBulkOperationsMenu() {
//     switch(currentRouteName.value) {
//         case 'referrers':
//         case 'timecards':
//         case 'pdf-tier-view':
//         case 'crm-for-pdf':
//         case 'clients':
//         case 'campaigns':
//         case 'contacts':
//         case 'client-appendices':
//         case 'parts_manufacturer_approvals':
//         case 'all-tasks':
//         case 'research-projects':
//         case 'project_assignments':
//         case 'emails-received':
//         case 'email-templates':
//             return false;
//         case 'campaign-activity-summary':
//             return true;
//         default:
//             return true;
//     }
// }


// function showStatusSummaryMenu() {
//     switch(currentRouteName.value) {
//         case 'referrers':
//         case 'timecards':
//         case 'crm-for-pdf':
//         case 'campaigns':
//         case 'pdf-tier-view':
//         case 'dla-companies':
//         case 'contacts':
//         case 'clients':
//         case 'client-appendices':
//         case 'parts_manufacturer_approvals':
//         case 'all-tasks':
//         case 'research-projects':
//         case 'project_assignments':
//         case 'emails-received':
//         case 'email-templates':
//             return false;
//         default:
//             return true;
//     }
// }
</script>

        <!-- SHOW/HIDE ALL MENU -->
        <!-- <div>
            <ShowHideAllMenu v-if="showDefaultShowHideMenu()"/>
            <ContactShowHideAllMenu v-if="showContactShowHideMenu()"/>
        </div> -->

        <!-- FILTERS -->
        <!-- <div class="border-solid border-gray-400 h-full">
            <FilterMenu v-if="showDefaultFilterMenu()"/>
            <ClientFilterMenu v-if="showClientFilterMenu()"/>
            <ClientAppendixFilterMenu v-if="showClientAppendixFilterMenu()"/>
            <ReferralFilterMenu v-if="showReferralFilterMenu()"/>
            <TimecardFilterMenu v-if="showTimecardsFilterMenu()"/>
            <CampaignsFilterMenu v-if="showCampaignsFilterMenu()"/>
            <ContactFilterMenu v-if="showContactsFilterMenu()"/>
            <ResearchFilterMenu v-if="showResearchFilterMenu()"/>
        </div> -->

        <!-- BULK OPERATIONS  -->
        <!-- <div v-if="showBulkOperationsMenu()" class="border-t border-b border-solid border-gray-400 h-full">
            <BulkOperationsMenu/>
        </div>
         -->
       
         <!-- STATUS SUMMARY -->
        <!-- <div v-if="showStatusSummaryMenu()">
            <StatusSummaryMenu/>
        </div> -->
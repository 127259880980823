<template>
  <base-form class="h-full w-full">
    <template #body>
      <div
        class="grid grid-cols-3 w-full gap-2 mb-1 p-2 text-xs"
      >
        <div class="flex flex-col">
          <label>Project Name</label>
          <input
            type="text"
            required
            class="p-[9px] border w-full"
            v-model="researchProjectData.project_name"
          />
        </div>
        <div class="flex flex-col">
          <label>Research Lead</label>
          <select required class="border p-2 w-full" v-model="researchProjectData.research_lead">
            <option v-for="lead in leadsList" :key="lead.id" :value="lead.id">
              {{ lead.first_name + " " + lead.last_name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col">
          <label>Client</label>
          <select required class="border p-2 w-full" v-model="researchProjectData.client">
            <option
              v-for="client in clientsList"
              :key="client.id"
              :value="client.id"
            >
              {{ client.client_name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col">
          <label> Start Date</label>
          <input type="date" class="p-2 border" v-model="researchProjectData.start_date" />
        </div>
        <div class="flex flex-col">
          <label>End Date</label>
          <input type="date" class="p-2 border" v-model="researchProjectData.end_date" />
        </div>
        <div class="flex flex-col">
          <label> Percent Complete</label>
          <input
            type="number"
            min="0"
            max="100"
            class="p-2 border"
            v-model="researchProjectData.percent_complete"
          />
        </div>
        <div class="flex flex-col">
          <label>Estimate to Complete (Person-Days)</label>
          <input
            type="number"
            min="0"
            class="p-2 border"
            v-model="researchProjectData.etc_person_days"
          />
        </div>
        <div class="flex flex-col">
          <label>Ordinal</label>
          <input
            type="number"
            min="0"
            class="p-2 border"
            v-model="researchProjectData.ordinal"
          />
        </div>
        <div class="flex flex-col">
          <label>Priority</label>
          <select required class="border p-2 w-full" v-model="researchProjectData.priority">
            <option
              v-for="priority in priorityOptions"
              :key="priority.value"
              :value="priority.value"
            >
              {{ priority.label }}
            </option>
          </select>
        </div>
        <div class="flex flex-col">
          <label>Stage</label>
          <select required class="border p-2 w-full" v-model="researchProjectData.stage">
            <option
              v-for="stage in stageOptions"
              :key="stage.value"
              :value="stage.value"
            >
              {{ stage.label }}
            </option>
          </select>
        </div>
        <div class="flex flex-col col-span-5">
          <label>Description</label>
          <textarea
            class="p-2 border h-full w-full"
            v-model="researchProjectData.description"
            rows="8"
          ></textarea>
        </div>
        <div class="flex flex-col justify-left w-full col-span-5 text-red-600">
          <p v-if="projectNameEmptyError">
            Please include a
            <span class="font-bold">Project Name</span>.
          </p>
          <p v-if="percentCompleteOutOfBoundsError">
            Please enter a <span class="font-bold">Percent Complete</span> value
            between 0 and 100.
          </p>
          <p v-if="estimateToCompleteNegativeValueError">
            Please enter a non-negative
            <span class="font-bold">Estimate to Complete (Person-Days)</span>
            value.
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row space-x-2 justify-center w-full col-span-5 my-2">
        <base-button-affirmative @click="saveEntry">Save</base-button-affirmative>
        <base-button-negative @click="emit('close')">Cancel</base-button-negative>
      </div>
    </template>
  </base-form>
</template>

<script setup>
import { ref, defineProps, onMounted, reactive, defineEmits } from "vue";
import { useStore } from "vuex";
import { getClients } from "@/services/Client";
import { getAllUsers } from "@/services/User";
import { editResearchProject } from "@/services/ResearchProjects";
const store = useStore();

const clientsList = ref([]);
const leadsList = ref([]);

const emit = defineEmits(['close', 'close-and-reload']);
const props = defineProps({
  project: Object,
});

const stageOptions = ref([
  { value: "clear", label: "------------" },
  { value: "Stage 1", label: "Stage 1" },
  { value: "Stage 2", label: "Stage 2" },
  { value: "Contacts", label: "Contacts" },
  { value: "Programs", label: "Programs" },
  { value: "Certification", label: "Certification" },
  { value: "Acquisition", label: "Acquisition" },
]);

const priorityOptions = ref([
  { value: "clear", label: "------------" },
  { value: 0, label: "No Priority" },
  { value: 1, label: "Priority 1" },
  { value: 2, label: "Priority 2" },
  { value: 3, label: "Priority 3" },
  { value: 4, label: "Priority 4" },
  { value: 5, label: "Priority 5" },
  { value: 6, label: "Priority 6" },
  { value: 7, label: "Priority 7" },
  { value: 8, label: "Priority 8" },
  { value: 9, label: "Priority 9" },
  { value: 10, label: "Priority 10" },
]);

const researchProjectData = reactive({
  project_name: props.project.project_name,
  description: props.project.description,
  ordinal: props.project.ordinal,
  percent_complete: props.project.percent_complete,
  etc_person_days: props.project.etc_person_days,
  start_date: props.project.start_date,
  end_date: props.project.end_date,
  research_lead: props.project.research_lead ? props.project.research_lead.id : null,
  client: props.project.client ? props.project.client.id : null,
  priority: props.project.priority,
  stage: props.project.stage
})

const percentCompleteOutOfBoundsError = ref(false);
const estimateToCompleteNegativeValueError = ref(false);
const projectNameEmptyError = ref(false);


onMounted(() => {
  loadClients();
  loadResearchLead();
});

async function loadClients() {
  const output = await getClients({ token: store.state.auth.token });
  console.log(output);
  clientsList.value = output.clients;
  console.log(clientsList.value);
  return output;
}

async function loadResearchLead() {
  const output = await getAllUsers(store.state.auth.token);
  console.log(output.users);
  leadsList.value = output.users;
  console.log(leadsList.value);

  return output;
}

async function saveEntry() {  
  let error = false;
  percentCompleteOutOfBoundsError.value = false;
  estimateToCompleteNegativeValueError.value = false;
  projectNameEmptyError.value = false;

  if (researchProjectData.percent_complete != "") {
    if (researchProjectData.percent_complete> 100 || researchProjectData.percent_complete < 0) {
      percentCompleteOutOfBoundsError.value = true;
      error = true;
    }
  }
  if (researchProjectData.etc_person_days != "") {
    if (researchProjectData.etc_person_days > 100 || researchProjectData.etc_person_days < 0) {
      estimateToCompleteNegativeValueError.value = true;
      error = true;
    }
  }
  if (!researchProjectData.project_name || researchProjectData.project_name == "") {
    projectNameEmptyError.value = true;
    error = true;
  }

  if (error) {
    return;
  }

  const formData = JSON.stringify({
    id: props.project.id,
    project_name: researchProjectData.project_name,
    description: researchProjectData.description,
    ordinal: researchProjectData.ordinal,
    percent_complete: researchProjectData.percent_complete,
    etc_person_days: researchProjectData.etc_person_days,
    start_date: researchProjectData.start_date,
    end_date: researchProjectData.end_date,
    research_lead: researchProjectData.research_lead,
    client: researchProjectData.client,
    priority:researchProjectData.priority,
    stage: researchProjectData.stage 
  });

  console.log(formData);
  editExistingResearchProject(formData);

}


async function editExistingResearchProject(data) {
    const payload = {
      'token': store.state.auth.token
    }
    const result = await editResearchProject(props.project.id, data, payload)
    if(result.status == 201) {
        emit('close-and-reload');
    } else {
        alert("Error, research project not edited.");
    }
}


</script>


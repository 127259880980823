<template>
    <div>
        <base-card>
            <h2 class="text-lg border-b border-b-dark-primary p-1 mb-3">Create New Page</h2>
            <div>
                <PageConfigForm @submit-form="createConfig" :key="formKey" />
            </div>
        </base-card>

    </div>
</template>

<script>
    export default {
        name: "create-page-config"
    }
</script>

<script setup>
import PageConfigForm from './PageConfigForm.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { createPageConfig } from '@/services/PageConfig';

const store = useStore()

const formKey = ref(0)

async function createConfig(data) {
    const token = store.state.auth.token
    const result = await createPageConfig(token, data)
    console.log('result', result)
    if(result.status == 200) {
        alert("Config created successfully")
        formKey.value += 1
        store.dispatch('pageConfigs/setPageConfigs')
    } else {
        alert("Error, config not created")
    }
}

</script>
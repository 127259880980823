import axios from 'axios';
import { BASE_URL } from './api';

export async function getClientEmails(payload) {
  return axios({
      method: 'get',
      url: `${BASE_URL}/emails_received/${payload.endpoint}`,
      headers: {
          'Authorization': `Token ${payload.token}`
      },
  }).then((response) => {
      return response;
  }).catch((error) => {
      throw error
  });
}

export async function getClientById(payload) {
  return axios({
    method: 'get',
    url: `${BASE_URL}/simple_client/${payload.clientId}`,
    headers: {
        'Authorization': `Token ${payload.token}`
    },
  }).then((response) => {
      return {
          "client": response.data,
      };
  }).catch((error) => {
      throw error
  });
}

export async function deleteEmail(payload) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/emails_received/${payload.emailId}/`,
        headers: {
            'Authorization': `Token ${payload.token}`,
            'Content-Type': 'application/json'
        },
    
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function createActivity(payload) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/emails_received/${payload.emailId}/create_new_activity/`,
        headers: {
            'Authorization': `Token ${payload.token}`,
            'Content-Type': 'application/json'
        },
        data: payload.data
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function bulkFileEmails(token, data) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/emails_received/bulk_quick_file/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}
<template>
    <base-table-row>
    <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
        <template #header>
            <span>{{ responseStatus }}</span>
        </template>
        <template #body>
            <span>{{ responseMessage }}</span>
        </template>
    </HeadlessDialogBox>
    <base-table-cell>{{ job.id }}</base-table-cell>
    <base-table-cell>{{ job.job_title }}</base-table-cell>
    <base-table-cell v-if="job.status==='queued'" class="bg-yellow-100 text-yellow-900">
        Queued   
    </base-table-cell>
    <base-table-cell v-else-if="job.status==='running'" class="bg-blue-100 text-blue-900">
        Running       
    </base-table-cell>
    <base-table-cell v-else-if="job.status==='halted'" class="bg-red-100 text-red-900">
        Halted        
    </base-table-cell>
    <base-table-cell v-else-if="job.status==='rejected'" class="bg-red-100 text-red-900">
        Rejected        
    </base-table-cell>
    <base-table-cell v-else-if="job.status==='complete'" class="bg-green-100 text-green-900">
        Complete
    </base-table-cell>
    <base-table-cell>{{ job.job_details }}</base-table-cell>
    <base-table-cell>{{ job.job_type }}</base-table-cell>
    <base-table-cell>{{ job.user.first_name }} {{ job.user.last_name }}</base-table-cell>
    <base-table-cell>{{ createdAt }}</base-table-cell>
    <base-table-cell>{{ updatedAt}}</base-table-cell>
    <base-table-cell class="flex flex-row space-x-1">
        <!-- <div v-if="job.status !== 'running' && job.status !== 'queued'">
            <base-button @click="runJob">Run</base-button>
        </div>
        <div v-else>
            <base-button-disabled>Run</base-button-disabled>
        </div> -->
        <div class="h-full">
            <base-button v-if="showDetails" class="flex items-center" @click="toggleShowDetails"><div class="flex flex-row items-center justify-center">Details &#8964;</div></base-button>
            <base-button v-else class="flex items-center" @click="toggleShowDetails"><div class="flex flex-row items-center justify-center">Details &gt;</div></base-button>
        </div>
        <div>
            <base-button v-if="hasDownloadLink" class="flex items-center h-full"><a :href="downloadUrl" target="_blank" class="flex flex-row items-center justify-center">Download CSV</a></base-button>
        </div>
    </base-table-cell>
    </base-table-row>
    <template v-if="showDetails">
        <base-table-cell></base-table-cell>
        <base-table-cell
            colspan="8"
            class="break-words bg-gray-100 pb-6"
        >
            <div class="grid grid-cols-5 space-x-4 w-full px-4">
                <div class="col-span-3 flex flex-col w-full">
                    <div class="border-b border-gray-300 text-sm font-bold py-1 mb-2">
                        Elastic Container Service Details:
                    </div>     
                    <JobECSDetails :job="job" />
                </div> 
                <div class="col-span-2 flex flex-col w-full">
                    <div class="border-b border-gray-300 text-sm font-bold py-1 mb-2">
                        Job Parameters:
                    </div>
                    <JobParameterTable :job="job" class="overflow-scroll" />
                </div>              
            </div>     
        </base-table-cell>
    </template>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import { formatDateTime } from '@/composables/formatDateTime';
import JobParameterTable from './JobParameterTable.vue';
import { useStore } from 'vuex';
import JobECSDetails from './JobECSDetails.vue';
import { runAsynchronousJob, getDownloadLinkFromS3 } from '@/services/AsynchronousJobs';
import HeadlessDialogBox from '@/components/HeadlessDialogBox.vue';

const props = defineProps(['job'])
const emit = defineEmits(['refetch-jobs'])
const downloadUrl = computed(() => {
    return props.job.s3_download_link
})

async function getDownloadLink(){
    if(store.state.auth.user.id === props.job.user.id){
        const token = store.state.auth.token
        const response = await getDownloadLinkFromS3(props.job.id, token)
        if(response.status === 200){
            downloadUrl.value = response.data
        }
    }
}

const store = useStore()

const showDetails = ref(false)

const openDialogBox = ref(false);

const responseStatus = ref('');
const responseMessage = ref('');

const hasDownloadLink = computed(() => {
    return props.job.job_type === "export_to_csv" && props.job.user.id === store.state.auth.user.id && downloadUrl.value
})

const createdAt  = computed(()=> {
    try {
        return formatDateTime(props.job.created_at)
    } catch {
        return 'None'
    }
})

const updatedAt  = computed(()=> {
    try {
        return formatDateTime(props.job.updated_at)
    } catch {
        return 'None'
    }
})

function toggleShowDetails() {
    showDetails.value = !showDetails.value
    console.log('show details')
}

function closeDialogBox() {
    emit('refetch-jobs')
    openDialogBox.value = false
}

async function runJob() {
    const token = store.state.auth.token
    const response = await runAsynchronousJob(props.job.id, token)
    console.log(response)
    if (response.status == 200) {
        responseStatus.value = "Success"
        responseMessage.value = response.data
        openDialogBox.value = true
    } else {
        responseStatus.value = "Error"
        responseMessage.value = response.data
        openDialogBox.value = true
    }
    // emit('refetch-jobs')
}

// getDownloadLink()

</script>
<template>
    <div class="">
        <transition
            enter-active-class="transition duration-200 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-200 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
            >
            <div
                v-if="isFormVisible"
                class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] flex justify-center items-center z-40"
                tabindex="-1" >
            </div>
        </transition>
        <transition
                enter-active-class="transition duration-200 ease-in"
                enter-from-class="scale-0"
                enter-to-class="scale-100"
                leave-active-class="transition duration-200 ease-out"
                leave-from-class="scale-100"
                leave-to-class="scale-0"
                class="h-full"
                >
            <div
                v-if="isFormVisible"
                v-on-escape="close"
                class="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-40 h-full"
                tabindex="-1">
                <div class="flex flex-col bg-white shadow-lg-black p-3 w-3/4 rounded-md h-3/4">
                    <header class="flex shrink-0 justify-between items-center relative p-15  pl-2 border-solid border-b border-gray-200 ">
                        <slot name="header"> </slot>
                        <base-button-negative
                        class="mb-3 px-2 font-bold"
                        type="button"
                        @click="close"
                        >
                        X
                        </base-button-negative>
                    </header>
                    <section class="flex h-full overflow-auto w-full">
                        <slot name="body"> </slot>
                    </section>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: "BaseModal.vue",
}
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps(['isFormVisible'])

const emit = defineEmits(['close'])

function close () {
  emit('close')
}
</script>

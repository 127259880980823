import clientMutations from './mutations.js';
import clientActions from './actions.js';
import clientGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            clients: [],
            next: null,
            prev: null,
            count: null,
            isLoading: false,
            page: null,
            pageSize: null,
            showFilterPane: true,
        };
    },
    mutations: clientMutations,
    actions: clientActions,
    getters: clientGetters
    // ...mapMutations['clientMutations'],
    // ...mapActions['clientActions'],
    // ...mapGetters['clientGetters'],
};
<template>
    <div class="mb-2 min-h-screen w-full">
        <div v-show="showFilterPane" class="pt-3 pb-0.5 border-b mx-5 mb-5 border-b-gray-400 flex flex-row justify-between">
            <h1>CRM For PDF</h1>
            <div>
                <base-button-affirmative @click="resetFilters" class="text-xs">
                    Reset
                </base-button-affirmative>
            </div>
        </div>
        <div v-show="showFilterPane" class="px-5 grid grid-cols-8">
            <base-filter>
                <template v-slot:label>Company</template>
                <template v-slot:widget><input @input="setCompanyName" v-model="crmForPDFFilters.company_name"
                        class="h-full px-2 w-full rounded-b-md shadow-md" type="text"></template>
            </base-filter>
            <base-filter @click="setClientOptions">
                <template v-slot:label>
                    Choose Client
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Client" :options="clientOptions"
                        :modelValue="crmForPDFFilters.client" :multiple="false" @update:modelValue="setClientFilter" />
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>
                    CRM Status
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Tag"
                        v-model="crmForPDFFilters.crm_status" :options="crmStatusOptions"
                        @update:modelValue="setCRMStatusFilter" :multiple="false" />
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>
                    CRM Next Action
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Tag"
                        v-model="crmForPDFFilters.crm_next_action" :options="crmNextActionOptions"
                        @update:modelValue="setCRMNextActionFilter" :multiple="false" />
                </template>
            </base-filter>

            <base-filter>
                <template v-slot:label>
                    Due Time Range
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Tag"
                        v-model="crmForPDFFilters.due_time_range" :options="dueTimeRangeOptions"
                        @update:modelValue="setDueTimeRangeFilter" :multiple="false" />
                </template>
            </base-filter>
            <base-filter>
                <template v-slot:label>
                    Client Feedback
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Feedback"
                        :options="clientFeedbackOptions" :modelValue="crmForPDFFilters.client_feedback" :multiple="true"
                        @update:modelValue="setClientFeedbackFilter" />
                </template>
            </base-filter>
            <base-filter @click="setTierOptions">
                <template v-slot:label>
                    Tier
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Tier" :options="tierOptions"
                        :modelValue="crmForPDFFilters.tiers" :multiple="true" @update:modelValue="setTierFilter" />
                </template>
            </base-filter>
            <base-filter @click="setCampaignOptions">
                <template v-slot:label>
                    Campaign
                </template>
                <template v-slot:widget>
                    <base-list-box class="w-full rounded-b-md" placeholder="Select Campaign" :options="campaignOptions"
                        :modelValue="crmForPDFFilters.campaigns" :multiple="true" @update:modelValue="setCampaignFilter" />
                </template>
            </base-filter>
        </div>

        <div v-if="isLoading" class="flex justify-center items-center h-2/3">
            <div role="status" class="flex items-center mt-3 ml-3">
                <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA" />
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="mx-6">
            <div v-if="showStatusSummaryPane" class="my-2">
                <div>Summary: Company Count: {{ companyCount }}</div>
                <CRMStatusSummary :query="fetchFilter" :company-count="companyCount" :companies="companyResults" />
            </div>
            <div v-if="respondedCoverageCompanies.length > 0">
                <div class="text-3xl my-4">Responded / Coverage</div>
                <CRMForPDFTable :companies="respondedCoverageCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="revisitCutCompanies.length > 0">
                <div class="text-3xl my-4">Revisit / Cut</div>
                <CRMForPDFTable :companies="revisitCutCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="clientPassCompanies.length > 0">
                <div class="text-3xl my-4">Client Pass</div>
                <CRMForPDFTable :companies="clientPassCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="notContactedCompanies.length > 0">
                <div class="text-3xl my-4">Not Contacted</div>
                <CRMForPDFTable :companies="notContactedCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="noResponseCompanies.length > 0">
                <div class="text-3xl my-4">No Response</div>
                <CRMForPDFTable :companies="noResponseCompanies" :tableHeaders="tableHeaders" />
            </div>
            <div v-if="hardNoCompanies.length > 0">
                <div class="text-3xl my-4">Hard No</div>
                <CRMForPDFTable :companies="hardNoCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="activeInDialogueCompanies.length > 0">
                <div class="text-3xl my-4">Active - In Dialogue</div>
                <CRMForPDFTable :companies="activeInDialogueCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="underLOICompanies.length > 0">
                <div class="text-3xl my-4">Under LOI</div>
                <CRMForPDFTable :companies="underLOICompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="holdCompanies.length > 0">
                <div class="text-3xl my-4">Hold</div>
                <CRMForPDFTable :companies="holdCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="researchFurtherCompanies.length > 0">
                <div class="text-3xl my-4">Research Further</div>
                <CRMForPDFTable :companies="researchFurtherCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="closedCompanies.length > 0">
                <div class="text-3xl my-4">Closed</div>
                <CRMForPDFTable :companies="closedCompanies" :tableHeaders="tableHeaders" />
            </div>

            <div v-if="deadAcquiredByOtherPartyCompanies.length > 0">
                <div class="text-3xl my-4">Dead - Acquired by Other Party</div>
                <CRMForPDFTable :companies="deadAcquiredByOtherPartyCompanies" :tableHeaders="tableHeaders" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { getCompanies } from '@/services/CRMForPDF'
import { getClients } from '@/services/Client'
import { getTiers } from '@/services/Tiers'
import { getCampaigns } from '@/services/Campaign'
import CRMForPDFTable from './CRMForPDFTable.vue'
import CRMStatusSummary from '@/components/CRMStatusSummary.vue'
import { resetFiltersVisiblity } from '@/composables/resetFilterVisibility'

const store = useStore()
const companyResults = ref([])
const isLoading = ref(false)
const awaitingSearch = ref(false)
const companyCount = ref(0)

const showFilterPane = computed(() => store.getters["campaigns/showFilterPane"]);

function setShowStatusSummaryPaneTrue() {
    store.dispatch('company/setShowStatusSummaryPaneTrue')
}

const showStatusSummaryPane = computed(() => store.getters['company/showStatusSummaryPane'])

const selectedConfig = computed(() => {
    return store.getters['pageConfigs/showSelectedPageConfig']
})

const crmForPDFFilters = reactive({
    company_name: '',
    client: null,
    crm_status: null,
    crm_next_action: null,
    due_time_range: null,
    client_feedback: [],
    tiers: [],
    campaigns: []
})
const tableHeaders = ref(
    ['Company\nCRM Summary', 'Account Manager Notes',
        'Revenue / Headcount\nLocation', 'Days Since Last Activity',
        'CRM Next Action', 'CRM Next Action Due Date', 'Days Until Next Action'
    ])

let respondedCoverageCompanies = ref([])
let revisitCutCompanies = ref([])
let clientPassCompanies = ref([])
let notContactedCompanies = ref([])
let noResponseCompanies = ref([])
let hardNoCompanies = ref([])
let activeInDialogueCompanies = ref([])
let underLOICompanies = ref([])
let holdCompanies = ref([])
let researchFurtherCompanies = ref([])
let closedCompanies = ref([])
let deadAcquiredByOtherPartyCompanies = ref([])


const clientOptions = ref([
    { value: "clear", label: "------------" }
])

async function setClientOptions() {
    const token = store.getters['auth/showToken']
    const result = await getClients({ token: token })
    clientOptions.value = [{ value: "clear", label: "------------" }, ...result.clients.map((client) => {
        return { value: client.id, label: client.client_name }
    })]
}

const crmStatusOptions = ref([
    { value: "clear", label: "------------" },
    { value: "None", label: 'None' },
    { value: 'Revisit / Cut', label: 'Revisit / Cut' },
    { value: 'Client Pass', label: 'Client Pass' },
    { value: 'Not Contacted', label: 'Not Contacted' },
    { value: 'No Response', label: 'No Response' },
    { value: 'Hard No', label: 'Hard No' },
    { value: 'Responded / Coverage', label: 'Responded / Coverage' },
    { value: 'Active - In Dialogue', label: 'Active - In Dialogue' },
    { value: 'IOI Submitted', label: 'IOI Submitted' },
    { value: 'Under LOI', label: 'Under LOI' },
    { value: 'Research Further', label: 'Research Further' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Closed', label: 'Closed' },
    { value: 'Dead - Acquired by Other Party', label: 'Dead - Acquired by Other Party' },
    { value: 'Not Now/Keep Warm', lable: 'Not Now/Keep Warm' }
])

const crmNextActionOptions = ref([
    { value: "clear", label: "------------" },
    { value: 'None', label: 'None' },
    { value: 'Determine Next Action', label: 'Determine Next Action' },
    { value: 'Scott to Follow-Up', label: 'Scott to Follow-Up' },
    { value: 'Send Initial Email', label: 'Send Initial Email' },
    { value: 'Send Another Email', label: 'Send Another Email' },
    { value: 'Send Email on Acquisition', label: 'Send Email on Acquisition' },
    { value: 'Research Correct Email', label: 'Research Correct Email' },
    { value: 'Call', label: 'Call' },
    { value: 'Schedule Meeting', label: 'Schedule Meeting' },
    { value: 'Wait on Response', label: 'Wait on Response' },
    { value: 'Wait for Client Feedback', label: 'Wait for Client Feedback' },
    { value: 'Meet', label: 'Meet' },
    { value: 'Not Now', label: 'Not Now' },
    { value: 'Leave Alone', label: 'Leave Alone' },
    { value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out' },
    { value: 'Any Next Step', label: 'Any Next Step' },
    { value: 'Do Not Act', label: 'Do Not Act' }

])

const dueTimeRangeOptions = ref([
    { value: "clear", label: "------------" },
    { value: 'In 2 Weeks', label: 'In 2 Weeks' },
    { value: 'In 1 Month', label: 'In 1 Month' },
    { value: 'Overdue', label: 'Overdue' }
]
)

const clientFeedbackOptions = ref([
    { value: "clear", label: "------------" },
    { value: "None", label: "None" },
    { value: "approved", label: "Approved" },
    { value: "excluded", label: "Excluded" },
    { value: "hold", label: "Hold" },
    { value: "pass", label: "Pass" },
    { value: "research_further", label: "Research Further" },
])

const tierOptions = ref([
    { value: "clear", label: "------------" }
])

async function setTierOptions() {
    const token = store.getters['auth/showToken']
    const result = await getTiers(token)
    tierOptions.value = [{ value: "clear", label: "------------" }, { value: "unmarked", label: "None" }, ...result.map((tier) => {
        return { value: tier.id, label: tier.tier_name }
    })]
}

const campaignOptions = ref([
    { value: "clear", label: "------------" }
])

async function setCampaignOptions() {
    const token = store.getters['auth/showToken']
    const result = await getCampaigns(token)
    campaignOptions.value = [{ value: "clear", label: "------------" }, ...result.map((campaign) => {
        return { value: campaign.id, label: campaign.campaign_name }
    })]
}

function setCompanyName(event) {
    crmForPDFFilters.company_name = event.target.value
}

function setClientFilter(target) {
    target == "clear"
        ? (crmForPDFFilters.client = null)
        : (crmForPDFFilters.client = target)

}

function setCRMStatusFilter(target) {
    target == "clear"
        ? (crmForPDFFilters.crm_status = null)
        : (crmForPDFFilters.crm_status = target)

}

function setCRMNextActionFilter(target) {
    target == "clear"
        ? (crmForPDFFilters.crm_next_action = null)
        : (crmForPDFFilters.crm_next_action = target)

}

function setDueTimeRangeFilter(target) {
    target == "clear"
        ? (crmForPDFFilters.due_time_range = null)
        : (crmForPDFFilters.due_time_range = target)

}

function setClientFeedbackFilter(target) {
    target.includes("clear")
        ? (crmForPDFFilters.client_feedback = [])
        : (crmForPDFFilters.client_feedback = target)
}

function setTierFilter(target) {
    target.includes("clear")
        ? (crmForPDFFilters.tiers = [])
        : (crmForPDFFilters.tiers = target)
}

function setCampaignFilter(target) {
    target.includes("clear")
        ? (crmForPDFFilters.campaigns = [])
        : (crmForPDFFilters.campaigns = target)
}

async function loadCompanies(endpoint = fetchFilter.value) {
    if (crmForPDFFilters.campaigns.length > 0) {
        isLoading.value = true
        let payload = {
            token: store.state.auth.token,
            endpoint: endpoint
        }
        const result = await getCompanies(payload)
        companyResults.value = result
        sortCompanies()
        setCompanyCount()
        // console.log('results: ', companyResults.value)
        // console.log('lengh of result: ', companyResults.value ? companyResults.value.length : 0)
        isLoading.value = false
        setShowStatusSummaryPaneTrue()
    }
}

function setCompanyCount() {
    companyCount.value = companyResults.value ? companyResults.value.length : 0
}

function sortCompanies() {
    respondedCoverageCompanies.value = []
    revisitCutCompanies.value = []
    clientPassCompanies.value = []
    notContactedCompanies.value = []
    noResponseCompanies.value = []
    hardNoCompanies.value = []
    activeInDialogueCompanies.value = []
    underLOICompanies.value = []
    holdCompanies.value = []
    researchFurtherCompanies.value = []
    closedCompanies.value = []
    deadAcquiredByOtherPartyCompanies.value = []

    for (let company of companyResults.value) {
        if (company.crm_status) {
            if (company.crm_status == 'Responded / Coverage') {
                respondedCoverageCompanies.value.push(company)
            }
            if (company.crm_status == 'Revisit / Cut') {
                revisitCutCompanies.value.push(company)
            }
            if (company.crm_status == 'Client Pass') {
                clientPassCompanies.value.push(company)
            }
            if (company.crm_status == 'Not Contacted') {
                notContactedCompanies.value.push(company)
            }
            if (company.crm_status == 'No Response') {
                noResponseCompanies.value.push(company)
            }
            if (company.crm_status == 'Hard No') {
                hardNoCompanies.value.push(company)
            }
            if (company.crm_status == 'Active - In Dialogue') {
                activeInDialogueCompanies.value.push(company)
            }
            if (company.crm_status == 'Under LOI') {
                underLOICompanies.value.push(company)
            }
            if (company.crm_status == 'Hold') {
                holdCompanies.value.push(company)
            }
            if (company.crm_status == 'Research Further') {
                researchFurtherCompanies.value.push(company)
            }
            if (company.crm_status == 'Closed') {
                closedCompanies.value.push(company)
            }
            if (company.crm_status == 'Dead - Acquired by Other Party') {
                deadAcquiredByOtherPartyCompanies.value.push(company)
            }
        }
    }
}

function resetFilters() {
    crmForPDFFilters.company_name = ''
    crmForPDFFilters.client = null
    crmForPDFFilters.crm_status = null
    crmForPDFFilters.crm_next_action = null
    crmForPDFFilters.due_time_range = null
    crmForPDFFilters.client_feedback = []
    crmForPDFFilters.tiers = []
    crmForPDFFilters.campaigns = []

    respondedCoverageCompanies.value = []
    revisitCutCompanies.value = []
    clientPassCompanies.value = []
    notContactedCompanies.value = []
    noResponseCompanies.value = []
    hardNoCompanies.value = []
    activeInDialogueCompanies.value = []
    underLOICompanies.value = []
    holdCompanies.value = []
    researchFurtherCompanies.value = []
    closedCompanies.value = []
    deadAcquiredByOtherPartyCompanies.value = []
    resetFiltersVisiblity(selectedConfig)
}

const fetchFilter = computed(function () {
    let query = new URLSearchParams({})
    if (crmForPDFFilters.company_name) {
        query.append('company_name', crmForPDFFilters.company_name)
    }
    if (crmForPDFFilters.client) {
        query.append('client', crmForPDFFilters.client)
    }
    if (crmForPDFFilters.crm_status) {
        query.append('crm_status', crmForPDFFilters.crm_status)
    }
    if (crmForPDFFilters.crm_next_action) {
        query.append('next_action', crmForPDFFilters.crm_next_action)
    }
    if (crmForPDFFilters.due_time_range) {
        query.append('due_date', crmForPDFFilters.due_time_range)
    }
    if (crmForPDFFilters.client_feedback.length > 0) {
        crmForPDFFilters.client_feedback.forEach((feedback) => {
            query.append("client_feedback", feedback)
        })
    }
    if (crmForPDFFilters.tiers.length > 0) {
        crmForPDFFilters.tiers.forEach((tier) => {
            query.append("tier_selection", tier)
        })
    }
    if (crmForPDFFilters.campaigns) {
        crmForPDFFilters.campaigns.forEach((campaign) => {
            query.append("campaign", campaign)
        })
    }
    let url = "?"
    let queriesCount = 0
    for (let value of query.values()) {
        queriesCount = queriesCount + 1
    }
    if (queriesCount > 0) {
        url = url + query.toString()
    }
    // console.log(url)
    return url
})

watch(
    () => crmForPDFFilters,
    () => {
        if (!awaitingSearch.value) {
            setTimeout(() => {
                loadCompanies(fetchFilter.value, false)
                awaitingSearch.value = false
            }, 1000)
        }
        awaitingSearch.value = true
    },
    { deep: true }
)

setClientOptions()
setTierOptions()
setCampaignOptions()
resetFiltersVisiblity(selectedConfig)
</script>
<template>
    <div class="overflow-y-auto">
        <base-table-full class="">
            <template #table-header>
                <base-table-header
                    :key="header.id"
                    :header="header"
                    v-for="header in tableHeadersList"
                >
                {{ header }}
                </base-table-header>
            </template>
            <template #table-body>
                <JobQueueRow
                @refetch-jobs="refetchJobs"
                    :key="job.id"
                    :job="job"
                    v-for="job in jobs"
                />
            </template>
        </base-table-full>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import JobQueueRow from "./JobQueueRow.vue";

const props = defineProps(['jobs'])

const emit = defineEmits(['refetch-jobs'])

const tableHeadersList = [
    'ID',
    'Job Title',
    'Status',
    'Details',
    'Type',
    'Created By',
    'Created At',
    'Updated At',
    'Actions'
]

function refetchJobs() {
    emit('refetch-jobs')
}

</script>
<template>
    <div v-if="finishedAction">
        <div v-if="result.status === 200">
            <p>{{ title }}: Success!</p>
            <br>
            <p>{{ result.data["status"] }}</p>
        </div>
        <div v-else>
            <p>{{ title }}: Failed: Bad Request</p>
            <br>
            <p>An error occurred that prevented the completion of this action.</p>
        </div>
        <br>
        <div class="flex justify-center">
            <base-button-negative
                type="button"
                class=""
                @click="closeModal"
            >
                Close
            </base-button-negative>
        </div>
    </div>
    <div v-else>
        <div v-if="isLoading" role="status" class="flex justify-center items-center">
            <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="white"/>
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#2E9CCA"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
        <div v-else>
            <p>{{ title }}: Are you sure you would like to perform this action?</p>
            <br>
            <div v-if="endpoint=='remove_all_companies_from_collection_and_delete_collection'">
                <p>{{ message }}</p>
                <br>
                <p>Please choose the collection tag you wish to delete:</p>
                <form action="" class="max-h-10">
                    <div class="m-1 flex justify-center">
                        <select name="collectionTag" id="collectionTag"
                            class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            @input="setPayload"
                            >
                            <option value="null">------------</option>
                            <option v-for="collectionTag in collectionTags" :key="collectionTag.id" :value="collectionTag.id">{{ collectionTag.collection_tag_name }}</option>
                        </select>
                    </div>
                </form>
                <br>
                <div class="flex justify-center">
                    <base-button-affirmative
                        type="button"
                        class="mx-1"
                        @click="deleteCollection()"
                    >
                        Continue
                    </base-button-affirmative>
                    <base-button-negative
                        type="button"
                        class="mx-1"
                        @click="closeModal"
                    >
                        Cancel
                    </base-button-negative>
                </div>
            </div>
            <div v-if="endpoint=='bulk_assign_query_results_to_collection_tag'">
                <p>{{ message }}</p>
                <br>
                <p>Please choose the collection tag you wish to assign:</p>
                    <div class="flex flex-wrap p-1 border rounded-md">
                        Tags:
                        <base-pill class="hover:cursor-pointer m-1" @click="removeTag(value)" v-for="value in payload" :key="value">{{ value }} | X</base-pill>
                        <div class="ml-1" v-if="!payload || payload.length == 0">
                            None
                        </div>
                    </div>
                    <form action="" @submit.prevent="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input
                                name="collectionTag"
                                id="collectionTag"
                                type="text" list="collectionTags"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @change="setCollectionTagPayload"
                            />
                            <datalist id="collectionTags">
                                <option value="null">------------</option>
                                <option v-for="collectionTag in collectionTags" :key="collectionTag.id" :value="`${collectionTag.collection_tag_name}`">{{ collectionTag.collection_tag_name }}</option>
                            </datalist>
                        </div>
                    </form>
                <br>
                <div class="flex justify-center">
                    <base-button-affirmative
                        type="button"
                        class="mx-1"
                        @click="performBulkAssignQueryResultsToCollectionTag()"
                    >
                        Continue
                    </base-button-affirmative>
                    <base-button-negative
                        type="button"
                        class="mx-1"
                        @click="closeModal"
                    >
                        Cancel
                    </base-button-negative>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'collection-tag-bulk-actions',
    data: () => ({
    }),
    methods: {
    }
}
</script>

<script setup>
import {ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { removeAllCompaniesAndDeleteCollectionTag } from '../services/CollectionTag.js';
import { bulkAssignQueryResultsToCollectionTag } from '@/services/Company';

const props = defineProps(['title', 'message', 'endpoint', 'collectionTags', 'filters'])
const emit = defineEmits(['close-modal', 'refetch-companies'])
const store = useStore();
const payload = ref(null);
const isLoading = ref(false);
const finishedAction = ref(false);
const result = ref('')

function setPayload(event) {
    payload.value = event.target.value
}

function closeModal() {
    emit('close-modal')
}

function setCollectionTagPayload(event) {
            if(event.target.value){
                if(Array.isArray(payload.value)){
                    if(!payload.value.includes(event.target.value)){
                        payload.value.push(event.target.value)
                    }
                } else {
                    payload.value = [event.target.value]
                }
                event.target.value = null
            }
            // console.log('payload', payload.value)
        }
        
function removeTag(tagName){
    payload.value.splice(payload.value.indexOf(tagName), 1)
}

async function deleteCollection() {
    if(payload.value){
        isLoading.value = true;
        const token = store.state.auth.token
        result.value = await removeAllCompaniesAndDeleteCollectionTag(payload.value, token)
        if(!result.value) {
            result.value = {
                'status': 'Error'
            }
        }
        isLoading.value = false
        finishedAction.value = true
        emit('refetch-companies')
    }
}

async function performBulkAssignQueryResultsToCollectionTag() {
    isLoading.value = true
    const token = store.state.auth.token
    result.value = await bulkAssignQueryResultsToCollectionTag(payload.value, props.filters, token)
    if(!result.value) {
        result.value = {
            'status': 'Error'
        }
    }
    isLoading.value = false
    finishedAction.value = true
    emit('refetch-companies')
}


</script>
<template>
    <div class="flex items-center mt-0.5">
        <input type="text" required :placeholder=placeholderText class="grow p-1 border inline" v-model="newRow">
        <div>
            <base-button-affirmative @click="addRow" class="ml-1 pt-1">Save</base-button-affirmative>
            <base-button-negative @click="emit('hide-add-card')" class="ml-1 pt-1">Cancel</base-button-negative>
        </div>
    </div>
</template>
  
<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { createNewBusinessType } from '@/services/BusinessType'
import { createNewSector } from '@/services/Sector'
import { createNewSubSector } from '@/services/SubSector'

const store = useStore()
const props = defineProps({
    tableType: String
})
const emit = defineEmits(['hide-add-card', 'refetch-table'])

const newRow = ref(null)
const placeholderText = ref('')
setPlaceholderText()

function setPlaceholderText() {
    if (props.tableType == 'businessTable') {
        placeholderText.value = 'New Business Type'
    } else if (props.tableType == 'sectorsTable') {
        placeholderText.value = 'New Sector'
    } else if (props.tableType == 'subSectorsTable') {
        placeholderText.value = 'New Sub-Sector'
    }
}

async function addRow() {
    const token = store.state.auth.token
    let errorMessage = "Error: Missing "
    if (!newRow.value) {
        if (props.tableType == 'businessTable') {
            errorMessage += "Business Type "
        } else if (props.tableType == 'sectorsTable') {
            errorMessage += 'Sector'
        } else if (props.tableType == 'subSectorsTable') {
            errorMessage += 'Sub-Sector'
        }
        alert(errorMessage)
        return
    }
    const data = newRow.value
    // console.log('data:', data)
    if (props.tableType == 'businessTable') {
        const businessTypeObject = ref({
            business_type_name: data
        })

        await createNewBusinessType(businessTypeObject.value, token)
    } else if (props.tableType == 'sectorsTable') {
        const sectorObject = ref({
            sector_name: data
        })

        await createNewSector(sectorObject.value, token)
    } else if (props.tableType == 'subSectorsTable') {
        const subSectorObject = ref({
            sub_sector_name: data
        })

        await createNewSubSector(subSectorObject.value, token)

    }
    emit('refetch-table')
    emit('hide-add-card')
}

</script>
<template>
    <div @dblclick="toggleEditable">
        <span v-if="isEditable">
            <BusinessTypesSectorsSubSectorsEditInput :row="row" :tableType="tableType" inputType="text" @toggle-editable="toggleEditable"
                @refetch-table="refetchTable" />
        </span>
        <span v-else>
            <div v-if="props.tableType == 'businessTable'">{{ row.business_type_name }}</div>
            <div v-if="props.tableType == 'sectorsTable'">{{ row.sector_name }}</div>
            <div v-if="props.tableType == 'subSectorsTable'">{{ row.sub_sector_name }}</div>
        </span>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import BusinessTypesSectorsSubSectorsEditInput from './BusinessTypes+Sectors+SubSectorsEditInput.vue'
const props = defineProps({
    row: Object,
    tableType: String
})

const isEditable = ref(false)
const emit = defineEmits(['refetch-table'])

function refetchTable() {
    emit('refetch-table')
}

function toggleEditable() {
    isEditable.value = !isEditable.value
}
</script>
import axios from 'axios';
import { BASE_URL } from './api';

export async function getEngagements(token, queryParams='') {
    return axios({
        method: 'get',
        url: `${BASE_URL}/engagements/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });
}

export async function updateEngagement(engagementId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/engagements/${engagementId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
<template>
  <div id="app" class="bg-gray-200">
    <div class="flex flex-row w-full z-50 fixed top-0 left-0">
      <NavBar v-if="token"  :windowWidth="windowWidth"></NavBar>
      <RightSideBarWidget 
        v-if="canViewSidebar"
        :windowWidth="windowWidth"
      />

    </div>
    <div :style="{ 'margin-top': navSize }" class="flex flex-row min-w-screen">
        <router-view class="min-w-full overflow-x-scroll" v-slot="{ Component }">
          <transition
            id="router-view"
          >
            <!--      <keep-alive include="aerocle-companies, aerocle-clients">-->
              <component class="" :is="Component"/>
            <!--      </keep-alive>-->
          </transition>
        </router-view>
        <div v-if="showRightSideBar" class="bg-dark-primary" :style="{ 'min-width': `${scrollWidth}px` }"></div>
        <Transition
          class="duration-300 ease-out"
            enter-from-class="transform translate-x-full"
            leave-to-class="transform translate-x-full"
            key="side-bar"
        >
          <RightSideBar
            v-show="showRightSideBar"
            class="-mt-2 z-50"
            :userId="userId"
            @change-sidebar-width="changeScrollWidth"
          >
            <template #title>
              <div class="text-white pl-2">Toolbar</div>
            </template>
          </RightSideBar>
        </Transition>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/GuestWelcome.vue'
import NavBar from './components/NavBar.vue';
import RightSideBarWidget from './components/RightSideBarWidget.vue';
import RightSideBar from './components/RightSideBar.vue';
import { useElementSize } from '@vueuse/core';
import { ref } from 'vue';

export default {
  name: 'App',
  components: {
    NavBar,
    RightSideBarWidget,
    RightSideBar
  },
  setup() {
    // const rightSideBar = ref(null)
    const routerView = ref(null)
    // const { sideBarWidth, sideBarHeight } = this.showRightSideBar ? useElementSize(rightSideBar) : {'width': 0, 'height': 0}
    const { routerViewWidth, routerViewHeight } = useElementSize(routerView)
    

    return {
      // rightSideBar,
      routerView,
      // sideBarWidth,
      // sideBarHeight,
      routerViewWidth,
      routerViewHeight
    }
  },
  created: async function () {

  },
  data: () => ({
    windowWidth: window.innerWidth,
    scrollWidth: 650,
  }),
  mounted() {
    this.$nextTick(()=> {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    token() {
      return this.$store.getters['auth/showToken']
    },
    navSize() {
      if (this.$store.getters['auth/showToken']) {
        return "3.5rem";
      } else {
        return "0px"
      }
    },
    user() {
      return this.$store.getters['auth/showUser']
    },
    userId() {
      return this.$store.getters['auth/showUser'] ? this.$store.getters['auth/showUser'].id : null
    },
    userPermissions() {
      return this.$store.getters['auth/showUserPermissions']
    },
    canViewSidebar() {
      if(this.$store.getters['auth/showUserPermissions']) {
        return (this.$store.getters['auth/showUserPermissions'].isDev || this.$store.getters['auth/showUserPermissions'].isAccountManager || this.$store.getters['auth/showUserPermissions'].isSuperuser || this.$store.getters['auth/showUserPermissions'].isAccountStaff || this.$store.getters['auth/showUserPermissions'].isManagement)
      } else {
        return null
      }
      // return this.$store.getters['auth/showUserPermissions'] ? this.$store.getters['auth/showUserPermissions'].isDev : null
    },
    showRightSideBar() {
      return this.$store.getters['showRightSideBar']
    },
  },
  methods: {
    getCompanies() {
      this.$store.dispatch('company/setCompanies')
    },
    onResize() {
      this.windowWidth = window.innerWidth
      // console.log(this.windowWidth)
    },
    changeScrollWidth(sideBarWidth) {
      // console.log('changing scroll width')
      // console.log('sideBar Width', sideBarWidth)
      this.scrollWidth = sideBarWidth
    }
  }
}
</script>


<!-- <script setup>

</script> -->

<style>

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(@/assets/fonts/Poppins-Medium.ttf) format("truetype");
}

#app {
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  /* font-weight: bold; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/

}
</style>

import axios from 'axios';
import { BASE_URL } from './api';

export async function deleteClientInvoiceReminderConfiguration(token, postId) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/client_invoice_reminder_configurations/${postId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
<template>
  <base-dblclick-input
      modelType="company"
      :model-id="company.id"
      field-name="category"
      :field-value="company.category"
      inputType="text"
      @refetch="fetchNewCompanies"
  ></base-dblclick-input>
</template>

<script>
  export default {
      name: "category-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      company: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }

</script>
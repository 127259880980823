<template>
  <div>
      <base-menu-dropdown v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement || userPermissions.isSuperuser || userPermissions.isAccountStaff" class="hover:text-gray-200 shrink"
      :links="links" :page-configs="pageConfigs" :user-permissions="userPermissions" @click="fetchPageConfigs">
          <template #label>Campaigns</template>
      </base-menu-dropdown>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
userPermissions: {
  type: Object,
  required: true
},
links: {
  type: Array,
  required: false
},
pageConfigs: {
  type: Array,
  required: false
}

})

const emit = defineEmits(['fetch-page-configs'])

function fetchPageConfigs() {
emit('fetch-page-configs')
}

</script>
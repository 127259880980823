<template>
    <!-- is-form-visible always true right now, needs to be modified -->
    <base-modal :is-form-visible=true @close="closeModal">
        <template #header>
          <h2 class="text-lg">Edit Timecard</h2>
        </template>
        <template #body>
            <div class="h-full w-full">
                <base-form class="h-full w-full">
                    <template #body>
                        <div class="m-5">
                    <p class="text-md"> {{ timecard.user.first_name }} {{ timecard.user.last_name }}'s Timecard:  {{ formatTimecardDates(addDays(timecard.timecard_start, 1)) + " - " + formatTimecardDates(addDays(timecard.timecard_start, 7)) }}</p>
                    <p class="text-md mb-2"> Status: {{ timecard.status }}</p>
                </div>  
                <div class="border-b "></div>
                <div class="m-5  grid grid-cols-4 gap-x-8 gap-y-4">
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Saturday </div>
                        <div> {{ timecard.sat_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.sat_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.sat_notes"
                        rows="1"
                        ></textarea>
                    </div>
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Sunday </div>
                        <div> {{ timecard.sun_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.sun_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.sun_notes"
                        rows="1"
                        ></textarea>
                    </div>
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Monday </div>
                        <div> {{ timecard.mon_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.mon_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.mon_notes"
                        rows="1"
                        ></textarea>
                    </div>
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Tuesday </div>
                        <div> {{ timecard.tue_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.tue_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.tue_notes"
                        rows="1"
                        ></textarea>
                    </div>
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Wednesday </div>
                        <div> {{ timecard.wed_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.wed_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.wed_notes"
                        rows="1"
                        ></textarea>
                    </div>
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Thursday </div>
                        <div> {{ timecard.thu_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.thu_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.thu_notes"
                        rows="1"
                        ></textarea>
                    </div>
                    <div class="text-sm flex-col">
                        <div class="font-bold mb-1"> Friday </div>
                        <div> {{ timecard.fri_date }}</div>
                        <div class="mt-1 mb-1"> Hours: </div>
                        <input
                            type="number"
                            min="0"
                            max="24"
                            class="px-1 border w-full mr-2"
                            v-model="modifiedTimecardObject.fri_hours"
                        />
                        <div class="mt-2 mb-1"> Notes: </div>
                        <textarea
                        class="p-1 border w-full"
                        v-model="modifiedTimecardObject.fri_notes"
                        rows="1"
                        ></textarea>
                    </div>
                </div>
                <div class="text-sm flex flex-col justify-left w-full text-red-600">
                    <p v-if="numHoursOutOfBoundsError">
                        Please ensure that entered hours for all given days are within the range 0-24 hours.
                    </p>
                    <p v-if="noNoteForEnteredHoursError">
                        Please include notes for any day where hours are entered.
                    </p>
                </div>
                <hr>
                    </template>
                    <template #footer>
                        <div class="flex flex-row space-x-2 justify-center w-full col-span-5 my-2">
                            <base-button-affirmative @click="submitEditedTimecard">Save</base-button-affirmative>
                            <base-button-negative @click="emit('close')">Cancel</base-button-negative>
                        </div>
                    </template>
                </base-form>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { defineProps, defineEmits, reactive , ref } from 'vue';
import { editTimecard } from '@/services/Timecard.js'
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
    timecard: Object
})
const numHoursOutOfBoundsError = ref(false);
const noNoteForEnteredHoursError = ref(false);

const modifiedTimecardObject = reactive({
    mon_hours: props.timecard.mon_hours,
    tue_hours: props.timecard.tue_hours,
    wed_hours: props.timecard.wed_hours,
    thu_hours: props.timecard.thu_hours,
    fri_hours: props.timecard.fri_hours,
    sat_hours: props.timecard.sat_hours,
    sun_hours: props.timecard.sun_hours,

    mon_notes: props.timecard.mon_notes,
    tue_notes: props.timecard.tue_notes,
    wed_notes: props.timecard.wed_notes,
    thu_notes: props.timecard.thu_notes,
    fri_notes: props.timecard.fri_notes,
    sat_notes: props.timecard.sat_notes,
    sun_notes: props.timecard.sun_notes,
});

const emit = defineEmits(['close', 'close-and-reload']);

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function formatTimecardDates(ts) {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let formatted = days[ts.getDay()] + " " + (ts.getMonth() + 1) + '/' + ts.getDate() + '/' + ts.getFullYear();
    return formatted;
} 

function closeModal() {
    emit('close');
}

async function submitEditedTimecard() {
    numHoursOutOfBoundsError.value = false;
    noNoteForEnteredHoursError.value = false;
    if (modifiedTimecardObject.mon_hours > 24 || modifiedTimecardObject.mon_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.mon_hours != 0 && !modifiedTimecardObject.mon_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }
    if (modifiedTimecardObject.tue_hours > 24 || modifiedTimecardObject.tue_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.tue_hours != 0 && !modifiedTimecardObject.tue_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }
    if (modifiedTimecardObject.wed_hours > 24 || modifiedTimecardObject.wed_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.wed_hours != 0 && !modifiedTimecardObject.wed_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }
    if (modifiedTimecardObject.thu_hours > 24 || modifiedTimecardObject.thu_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.thu_hours != 0 && !modifiedTimecardObject.thu_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }
    if (modifiedTimecardObject.fri_hours > 24 || modifiedTimecardObject.fri_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.fri_hours != 0 && !modifiedTimecardObject.fri_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }
    if (modifiedTimecardObject.sat_hours > 24 || modifiedTimecardObject.sat_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.sat_hours != 0 && !modifiedTimecardObject.sat_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }
    if (modifiedTimecardObject.sun_hours > 24 || modifiedTimecardObject.sun_hours < 0) {
        numHoursOutOfBoundsError.value = true; 
        return;
    }
    if (modifiedTimecardObject.sun_hours != 0 && !modifiedTimecardObject.sun_notes) {
        noNoteForEnteredHoursError.value = true;
        return;
    }

    const total_hours = +modifiedTimecardObject.mon_hours + +modifiedTimecardObject.tue_hours + 
                        +modifiedTimecardObject.wed_hours + +modifiedTimecardObject.thu_hours + 
                        +modifiedTimecardObject.fri_hours + +modifiedTimecardObject.sat_hours + 
                        +modifiedTimecardObject.sun_hours;

    const formData = JSON.stringify({
        id: props.timecard.id,
        mon_hours: modifiedTimecardObject.mon_hours,
        tue_hours: modifiedTimecardObject.tue_hours,
        wed_hours: modifiedTimecardObject.wed_hours,
        thu_hours: modifiedTimecardObject.thu_hours,
        fri_hours: modifiedTimecardObject.fri_hours,
        sat_hours: modifiedTimecardObject.sat_hours,
        sun_hours: modifiedTimecardObject.sun_hours,

        mon_notes: modifiedTimecardObject.mon_notes,
        tue_notes: modifiedTimecardObject.tue_notes,
        wed_notes: modifiedTimecardObject.wed_notes,
        thu_notes: modifiedTimecardObject.thu_notes,
        fri_notes: modifiedTimecardObject.fri_notes,
        sat_notes: modifiedTimecardObject.sat_notes,
        sun_notes: modifiedTimecardObject.sun_notes,

        total_hours: total_hours
    })
    editExistingTimecard(formData);
}

async function editExistingTimecard(data) {
    const payload = {
      'token': store.state.auth.token
    }
    const result = await editTimecard(props.timecard.id, data, payload)
    if(result.status == 201) {
        emit('close-and-reload');
    } else {
        alert("Error, timecard not edited.");
    }
}

</script>

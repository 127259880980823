<template>
    <div class="h-full relative" ref="inputElement">
      <Combobox :model-value="props.modelValue" :multiple="!singleValue" @update:modelValue="value => emit('update:modelValue', value)">
        <div class="relative h-full">
          <div
            class="relative h-full w-full cursor-default rounded-b-md bg-white text-left text-xs shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300"
          >
            <ComboboxInput 
              class="w-full border-none h-full py-2 pl-3 pr-10 rounded-b-md text-xs leading-5 text-gray-900 focus:ring-0 focus:rounded-b" @mouseover="showPopover"  @mouseleave="hidePopover" 
              @change="query = $event.target.value"
              :display-value="display"
            />
            <ComboboxButton
              class="absolute inset-y-0 right-0 flex items-center pr-2" 
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
          <TransitionRoot
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            @after-leave="query = ''"
          >
            <ComboboxOptions
              class="absolute mt-0.5 w-full min-w-[240px] rounded-md text-xs shadow-lg bg-white focus:outline-none z-30"
            >
              <div
                v-if="filteredOptions.length === 0 && query !== ''"
                class="relative cursor-default select-none py-2 px-4 text-gray-700 z-30 "
              >
                Nothing found.
              </div>
              <div v-bind="containerProps" class="max-h-60 p-2 rounded-lg z-30">
                <div v-bind="wrapperProps">
                  <ComboboxOption
                    v-for="{index, data} in list"
                    as="template"
                    :key="index"
                    :value="data.value"
                    v-slot="{ selected, active }"
                  >
                    <li
                      class="relative cursor-default select-none py-2 pl-4 pr-2 lg:pl-6"
                      :class="{
                        'bg-amber-100 text-amber-900': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span
                        class="block"
                        :class="{ 'font-medium': selected, 'font-normal': !selected }"
                      >
                        {{ data.label }}
                      </span>
                      <span
                        v-if="selected"
                        class="absolute inset-y-0 left-0 flex items-center"
                        :class="{ 'text-amber-900': active, 'text-gray-900': !active }"
                      >
                        <CheckIcon class="pl-1 h-3 w-3 lg:h-5 lg:w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </div>
              </div>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
    </Combobox>
    </div>
    <!-- <base-popover-hover 
        v-if="isPopoverVisible"
        :xPosition="xPosition"
        :yPosition="yPosition"
      >
      <template v-slot:title>Selected Codes:</template>
      <template v-slot:body>
        <div v-for="value in displayOptions" :key="value">{{ value }}</div>
      </template>
    </base-popover-hover> -->
</template>

<script>
</script>
  
<script setup>
import { ref, computed, defineProps, defineEmits, watch } from 'vue'
import {
Combobox,
ComboboxInput,
ComboboxButton,
ComboboxOptions,
ComboboxOption,
TransitionRoot,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useVirtualList } from "@vueuse/core";

const props = defineProps({
    options: Array,
    modelValue: [String, Number, Array],
    singleValue: Boolean
})

const xPosition = ref(0)
const yPosition = ref(0)
const inputElement = ref(null)
const isPopoverVisible = ref(false)

const emit = defineEmits(['update:modelValue'])

let query = ref('')

function showPopover() {
  if(Array.isArray(props.modelValue) && props.modelValue.length > 1){
      isPopoverVisible.value = true;
      xPosition.value = inputElement.value.getBoundingClientRect().right
      yPosition.value = inputElement.value.getBoundingClientRect().top
  }
}

function hidePopover() {
  isPopoverVisible.value = false;
  xPosition.value = 0;
  yPosition.value = 0;
}

const display = () => {
  return props.options.filter(option => {
  if (Array.isArray(props.modelValue)) {
      // console.log('model value is array', props.modelValue)
      return props.modelValue.includes(option.value);
      }
      return props.modelValue === option.value;
  }).map(option => option.label).join(', ')
}

const displayOptions = computed(() => {
  return props.options.filter(option => {
  if (Array.isArray(props.modelValue)) {
      // console.log('model value is array', props.modelValue)
      return props.modelValue.includes(option.value);
      }
      return props.modelValue === option.value;
  }).map(option => option.label)
})

const filteredOptions = computed(() =>
  query.value === ''
    ? props.options
    : props.options.filter((option) =>
        option.label
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
)

const { list, containerProps, wrapperProps } = useVirtualList(
  filteredOptions,
  {
    itemHeight: 10,
    overscan: 6,
  }
);

// watch(filteredOptions, () => {
//   handleScrollTo();
// })

// function handleScrollTo() {
//   scrollTo(query.value);
// }

</script>
  
<template>
    <div>
        <base-card>
            <h2 class="text-lg border-b border-b-dark-primary p-1 mb-3">Update {{ pageName }} <span v-if="isSaved">(Saved)</span></h2>
            <div>
                <EditPageConfigForm :pageConfig="pageConfig" @submit-form="updateConfig" @delete-config="deleteConfig" :key="formKey"/>
            </div>
        </base-card>
        
    </div>
</template>

<script>
    export default {
        name: "manage-page-config"
    }
</script>

<script setup>
import EditPageConfigForm from './EditPageConfigForm.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import { getPageConfig, updatePageConfig, deletePageConfig } from '@/services/PageConfig';


const route = useRoute()
const router = useRouter()

const routePageConfig = ref(route.params.configId)

const store = useStore()

const pageConfig = ref(null)

const isSaved = ref(false)

const pageName = computed(() => {
    return pageConfig.value ? pageConfig.value.configuration_name : "Page"
})

const formKey = ref(0)

async function loadPageConfig(){
    if(routePageConfig.value){
        await getConfig()
    }
}

async function getConfig() {
    const token = store.state.auth.token
    const result = await getPageConfig(token, routePageConfig.value)
    console.log('result', result)
    pageConfig.value = result
}

async function updateConfig(data) {
    const token = store.state.auth.token
    const result = await updatePageConfig(token, data, pageConfig.value.id)
    console.log('result', result)
    if(result.status == 200) {
        alert("Config updated successfully")
        // formKey.value += 1
        store.dispatch('pageConfigs/setPageConfigs')
        getConfig()
        isSaved.value = true
    } else {
        alert("Error, config not updated")
    }
}

async function deleteConfig() {
    const user_id = store.state.auth.user.id
    if(user_id === pageConfig.value.user.id){
        const token = store.state.auth.token
        const result = await deletePageConfig(token, pageConfig.value.id)
        console.log('result', result)
        if(result.status == 200) {
            alert("Config Deleted")
            store.dispatch('pageConfigs/setPageConfigs')
            router.push({path: '/companies'})
        } else {
            alert("Error, config not deleted")
        } 
    } else {
        alert("You do not have permission to delete this page configuration.")
    }
}


loadPageConfig()


</script>
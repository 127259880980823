export default {
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
    setActiveFilters(context, activeFiltersArray) {
        context.commit('setActiveFilters', activeFiltersArray)
    },
}
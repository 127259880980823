<template>
    <div>
        {{ daysSinceActivity }}
    </div>
</template>

<script>
    export default {
        name: "days-since-last-activity"
    }
</script>

<script setup>
import {defineProps, computed} from "vue"

    const props = defineProps({
        company: Object
    })

    const daysSinceActivity = computed(()=>{
        let activities = props.company.activities
        let dateList = []
        if(activities.length == 0){
            return "N/A"
        } else {
            dateList = activities.map((activity) => {
                return activity.activity_date
            })
            const maxDate = dateList.reduce((accumulator, currentValue) =>{
                let accDate = new Date(accumulator).getTime()
                let currDate = new Date(currentValue).getTime()
                return Math.max(accDate, currDate)
            })
            const now = new Date()
            const diffTime = Math.abs(now - maxDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays - 1
        }

    })
</script>
<template>
  <div class="w-full">
    <div class="shadow-lg">
      <!-- Enrich With Zoom Info -->

      <div class="pt-2 ml-[15px]">
        <div
          class="flex flex-row justify-between items-center pb-1.5 border-b border-b-gray-400"
        >
          <h3 class="text-sm align-middle">Company Info:</h3>
        </div>
        <div class="flex mt-2 rounded-sm pb-1">
          <div
            class="text-sm text-dark-primary pr-4 pl-1 border-b border-l border-t border-gray-400 rounded-l-md py-1"
          >
            Source
          </div>
          <div class="w-1/4 border border-gray-400">
            <DevAdminListBox
              class="w-full h-full text-black"
              placeholder="------------"
              v-model="source"
              :options="sourceOptions"
              :multiple="false"
            />
          </div>

          <button
            @click="enrichWithZoomInfo"
            class="text-sm align-middle hover:bg-dark-primary hover:text-white pr-10 border-r pl-2 border-b border-t border-gray-400 rounded-r-md"
          >
            Get ZI IDs and ZI Basic for Companies in Source
          </button>
        </div>
      </div>

      <!-- Find and Merge Duplicates -->

      <div class="ml-[15px]">
        <div
          class="flex flex-row justify-between items-center pb-0.5 border-b border-b-gray-400"
        >
          <h3 class="text-sm">Find and Merge Duplicates:</h3>
        </div>
        <div class="flex pt-2 pb-2 gap-3">
          <base-pill-button @click="setFindMergeText('Remove All UIUDS')"
            >Remove All UIUDS</base-pill-button
          >
          <base-pill-button
            @click="setFindMergeText('Find and Mark UUIDs via URL')"
            >Find and Mark UUIDs via URL</base-pill-button
          >
          <base-pill-button
            @click="setFindMergeText('Find and Mark UUIDs via OIN')"
            >Find and Mark UUIDs via OIN</base-pill-button
          >
          <base-pill-button @click="setFindMergeText('Batch Merge Duplicates')"
            >Batch Merge Duplicates</base-pill-button
          >
        </div>
      </div>
    </div>

    <!-- Delete Warning -->

    <ConfirmScreen
      v-if="isConfirmModalVisible"
      :message="confirmText"
      @close-confirm-modal="closeConfirmModal"
      @confirm-modal="confirmActionDispatch"
    ></ConfirmScreen>

    <!-- Header Component -->
    <base-card>
      <base-pagination-header
        @load-data="loadLogs(fetchFilter.value, true)"
        @get-next="getNext"
        @go-to-first="goToFirst"
        @get-prev="getPrev"
        @go-to-last="goToLast"
        @jump-to-page="jumpToPage"
        @set-page-size-filter="setPageSizeFilter"
        :page_filter="adminFilters"
        :page="page"
        :numPages="num_pages"
        :isLoading="isLoading"
      >
        <template v-slot:message>
          <div class="ml-3 mt-1">
            {{ count }} activities in the last 30 minutes.
          </div>
        </template>
      </base-pagination-header>

      <!-- Table Component -->

      <div class="h-full overflow-y-auto border ml-2">
        <base-table-full lass="table-auto">
          <template #table-header>
            <base-table-header
              :key="header.id"
              :header="header"
              class="pt-3 pb-3"
              v-for="header in tableHeadersList"
            >
              {{ header }}
            </base-table-header>
          </template>

          <template #table-body>
            <DevAdminRow
              v-for="log in logs"
              :key="log.id"
              :log="log"
            ></DevAdminRow>
          </template>
        </base-table-full>
      </div>
    </base-card>
  </div>
</template>

<script>
export default {
  name: "aerocle-admin",
};
</script>

<script setup>
import { ref, computed, onMounted, reactive } from "vue";
import {
  getLogs,
  removeAllUUIDS,
  findAndMarkUUIDSViaUrl,
  findAndMarkUUIDSViaOin,
  batchMergeDuplicates,
} from "../services/Admin.js";
import { useStore } from "vuex";
import { getSources } from "../services/Source.js";

import DevAdminRow from "./admin_page/table/AdminRow.vue";
import DevAdminListBox from "./admin_page/UI/AdminListBox.vue";
import ConfirmScreen from "./admin_page/UI/AdminConfirmScreen.vue";

// constants/variables

const store = useStore();

const next = ref();
const prev = ref();
const count = ref();
const num_pages = ref();
const page_size = ref();
const source = ref("");
const page = ref(1);
const isLoading = ref(false);
const logs = ref([]);
const sourceOptions = ref([]);
const isConfirmModalVisible = ref(false);
const confirmText = ref("");

const adminFilters = reactive({
  pageSizeFilter: 50,
});

const tableHeadersList = ref(["ID", "Title", "Details", "User", "Timestamp"]);

// computed values

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});

  if (adminFilters.pageSizeFilter) {
    query.append("page_size", adminFilters.pageSizeFilter); // To append more data
  }

  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

// async functions

async function loadLogs(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const response = await getLogs(payload);
  next.value = response.next ? `&page=${response.page + 1}` : null;
  prev.value = response.prev ? `&page=${response.page - 1}` : null;
  count.value = response.count;
  page.value = response.page;
  num_pages.value = response.num_pages;
  logs.value = response.logs;
  page_size.value = response.logs.length;
  isLoading.value = false;
}

async function setSourceOptions() {
  let token = store.state.auth.token;
  const result = await getSources(token);
  sourceOptions.value = [
    { value: "clear", label: "------------" },
    ...result.map((source) => {
      return {
        value: source.id,
        label: source.source_name,
      };
    }),
  ];
}

async function removeAllUIUDSCall() {
  const token = store.state.auth.token;
  alert("removeAllUIUDSCall");
  //   const result = await removeAllUUIDS(token);
  //   if (result.status == 200) {
  //     alert("Remove all UIUDS succeeded");
  //   } else {
  //     alert("Remove all UUIDS failed");
  //   }
}

async function findAndMarkUUIDSViaURLCall() {
  const token = store.state.auth.token;
  alert("findAndMarkUUIDSViaURLCall");
  //   const result = await findAndMarkUUIDSViaUrl(token);
  //   if (result.status == 200) {
  //     alert("Find and Mark UUIDS via URL succeeded");
  //   } else {
  //     alert("Find and Mark UUIDS via URL failed");
  //   }
}

async function findAndMarkUUIDSViaOINCall() {
  const token = store.state.auth.token;
  alert("findAndMarkUUIDSViaOINCall");
  //   const result = await findAndMarkUUIDSViaOin(token);
  //   if (result.status == 200) {
  //     alert("Find and Mark UUIDS via OIN succeeded");
  //   } else {
  //     alert("Find and Mark UUIDS via OIN failed");
  //   }
}

async function batchMergeDuplicatesCall() {
  const token = store.state.auth.token;
  alert("batchMergeDuplicatesCall");
  //   const result = await batchMergeDuplicates(token);
  //   if (result.status == 200) {
  //     alert("Batch Merge Duplicates succeeded");
  //   } else {
  //     alert("Batch Merge Duplicates failed");
  //   }
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (adminFilters.pageSizeFilter = 50)
    : (adminFilters.pageSizeFilter = target);
  await loadLogs(fetchFilter.value);
}

// functions

function getNext() {
  if (next.value) {
    loadLogs(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadLogs(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadLogs(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (num_pages.value != page.value) {
    loadLogs(fetchFilter.value + `&page=${num_pages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadLogs(fetchFilter.value + `&page=${targetPage}`);
}

function enrichWithZoomInfo() {
  if (source.value !== "" && source.value !== "clear") {
    alert(source.value);
  }
}

function closeConfirmModal() {
  isConfirmModalVisible.value = false;
}

function setFindMergeText(text) {
  confirmText.value = text;
  isConfirmModalVisible.value = true;
}

function confirmActionDispatch(message) {
  if (message === "Remove All UIUDS") {
    removeAllUIUDSCall();
  } else if (message === "Find and Mark UUIDs via URL") {
    findAndMarkUUIDSViaURLCall();
  } else if (message === "Find and Mark UUIDs via OIN") {
    findAndMarkUUIDSViaOINCall();
  } else {
    batchMergeDuplicatesCall();
  }
  closeConfirmModal();
}

// lifecyle

onMounted(() => {
  loadLogs();
  setSourceOptions();
});
</script>

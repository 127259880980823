<template>
  <form @submit.prevent>
    <div class="flex">
      <div class="group m-1 w-full">
        <label class="bg-light-primary text-xs py-1 px-2 w-full rounded-t-md flex items-center justify-between" :class="{'bg-amber-300': active}" for="companyName">
          <slot name="label"></slot>
          <button type="button" @click="close" class="text-xs w-4 h-4 bg-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-sm items-center text-center align-middle">-</button>
          <slot name="button"></slot>
        </label>
        <div class="rounded-b-md h-8 text-xs
                    border text-gray-900
                    focus:ring-dark-primary focus:border-dark-primary">
          <slot name="widget"></slot>
        </div>
      </div>
    </div>
  </form>
</template>
  
<script>
export default {
  name: "BaseFilter.vue",
  // methods: {
  //       close() {
  //           this.$emit("close");
  //       },
  //   },
}
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits(['close'])
const props = defineProps({
  active: Boolean
})

function close () {
  emit('close')
}
</script>
<template>
    <div class="text-sm px-1 pb-1 flex flex-row justify-between items-center border-b border-gray-100 print:hidden w-full">
      <p class="inline">QPL Search:<span
            class="pl-1 align-middle text-accent-primary hover:text-dark-primary"
            @click="showModal"
            >{{numSelectedCompanies}} Selected Companies
            </span> |
            <span
            class=""
            >{{numSelectedGovernmentDesignations}} Designations
            </span> |
            <span
            class=""
            >{{numSelectedGoverningSpecifications}} Governing Specs
            </span>
            <!-- <div class="text-sm text-right"> -->
                <!-- <span class="hover:text-accent-primary hover:cursor-pointer" @click="resetSelectAllClearSelectedCompanies">Clear All</span>  -->
                <!-- (<span class="">{{ numSelectedCompaniesOnPage }} on page</span> | 
                <span class="">{{ numSelectedCompaniesOffPage }} off page</span>) -->
            <!-- </div> -->
        </p>
        <base-modal-small 
            v-if="isModalVisible"
            @close="closeModal"
        >
        <template v-slot:header>
            Selected Companies
        </template>
        <template v-slot:body>
            <SelectedCompaniesTable :selectedCompanies="selectedCompanies" />
        </template>
        </base-modal-small>
      <div class="flex">
        <base-button-negative @click="emitSplitPage" class="ml-1">Hide Search</base-button-negative>
      </div>
    </div>
    <div class="pt-2 grid grid-cols-2 print:hidden">
        <base-filter @click="setGoverningSpecificationOptions">
        <template v-slot:label>
          Governing Specifications
        </template>
        <template v-slot:widget>
          <base-list-box
              class="w-full rounded-b-md"
              placeholder="Specification"
              v-model="resultGoverningSpecificationFilter"
              :options="governingSpecificationOptions"
              @update:modelValue="setResultGoverningSpecificationFilter"
              :multiple="true"
              />
        </template>
        </base-filter>
        <base-filter @click="setGovernmentDesignationOptions">
        <template v-slot:label>
          Government Designations
        </template>
        <template v-slot:widget>
          <base-list-box
              class="w-full rounded-b-md"
              placeholder="Designation"
              v-model="resultGovernmentDesignationFilter"
              :options="governmentDesignationOptions"
              @update:modelValue="setResultGovernmentDesignationFilter"
              :multiple="true"
              />
        </template>
      </base-filter>
    </div>
    <div class="flex flex-rows justify-between py-1">
      <div class="flex justify-end py-1 pb-2">
        <base-button-affirmative @click="searchByQPL" class="mx-1">Search</base-button-affirmative>
        <base-button-negative @click="clearFilters" class="mx-1">Clear Specs</base-button-negative>
      </div>
      <div class="flex justify-between py-1">
        <div class="flex">
          <base-pill-button
          v-if="userPermissions.isDev || 
              userPermissions.isManagment || 
              userPermissions.isSuperuser
            " 
          @click="setCompanyBulkAction('Assign To Collection', 'bulk_assign_collection_tag');loadCollectionTags()" class="mx-1">
            Assign Selected Results to Collection
          </base-pill-button>
          <base-pill-button 
          v-if="userPermissions.isDev || 
              userPermissions.isManagment || 
              userPermissions.isSuperuser
            " 
          @click="setCollectionTagBulkAction('Assign All Results To Collection', 'This will assign all companies in search results (total companies) to the designated collection.', 'bulk_assign_query_results_to_collection_tag'); loadCollectionTags()" class="mx-1">
            Assign All Results to Collection
          </base-pill-button>
      </div>
      </div>
      <base-dialog-box v-if="isCompanyModalVisible" @close="closeCompanyModal">
          <template #header>
            <h2>Bulk Action: {{ bulkActionTitle }}</h2>
          </template>
          <template #body>
            <company-bulk-actions
              :title="bulkActionTitle"
              :endpoint="bulkActionEndpoint"
              :bulkActionData="bulkActionData"
              :sourceOwners="sourceOwners"
              :collectionTags="collectionTags"
              :selectedCompanies="selectedResults"
              :emailTemplates="emailTemplates"
              @reset-select-all="resetSelectAll"
              @close-modal="closeCompanyModal"
              @refetch-companies="fetchNewCompanies"
              @clear-selected-companies="clearSelectedResults"
            ></company-bulk-actions>
          </template>
      </base-dialog-box>
      <base-dialog-box v-if="isCollectionTagModalVisible" @close="closeCollectionTagModal">
          <template #header>
            <h2>Bulk Action: {{ bulkActionTitle }}</h2>
          </template>
          <template #body>
            <collection-tag-bulk-actions
              :title="bulkActionTitle"
              :message="bulkActionMessage"
              :filters="filters"
              :endpoint="bulkActionEndpoint"
              :bulkActionData="bulkActionData"
              :collectionTags="collectionTags"
              @close-modal="closeCollectionTagModal"
              @refetch-companies="fetchNewCompanies"
            ></collection-tag-bulk-actions>
          </template>
      </base-dialog-box>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import CompanyBulkActions from './CompanyBulkActions.vue';
  import CollectionTagBulkActions from './CollectionTagBulkActions.vue';
  import { getCollectionTags } from '@/services/CollectionTag';
  import SelectedCompaniesTable from './SelectedCompaniesTable.vue';
  
  export default {
    name: "QPLSearch",
    components: {SelectedCompaniesTable, CompanyBulkActions, CollectionTagBulkActions},
    emits: [
      'change-result-filters',
      'set-changed-result-filters',
      'split-page',
      'search-by-qpl',
      'reset-select-all',
      'clear-selected-companies'
    ],
    props: [
        'splitPage',
        'selectedCompanies',
        'selectedResults',
        'numSelectedCompanies',
        'selectedGovernmentDesignations',
        'numSelectedGovernmentDesignations',
        'selectedGoverningSpecifications',
        'numSelectedGoverningSpecifications',
        'userPermissions',
        'filters'
    
    ],
    setup(props, context) {

    const resultGovernmentDesignationFilter = ref([])
    const resultGoverningSpecificationFilter = ref([])
    const isCompanyModalVisible = ref(false);
    const isCollectionTagModalVisible = ref(false);
    const collectionTags = ref([])
    const bulkActionTitle = ref(null);
    const bulkActionMessage = ref(null);
    const bulkActionEndpoint = ref(null);
    const bulkActionData = ref(null);
    const store = useStore()

    const governmentDesignationOptions = ref([
        {value: "clear", label: "------------"}
      ])
    const governingSpecificationOptions = ref([
        {value: "clear", label: "------------"}
      ])

    const isModalVisible = ref(false);

    const filters = computed(()=> {
        return {
            resultGovernmentDesignationFilter: resultGovernmentDesignationFilter,
            resultGoverningSpecificationFilter: resultGoverningSpecificationFilter,
        }
    })

    function emitSplitPage(){
        console.log('emitting split-page')
        context.emit('split-page')
    }

    function showModal() {
        isModalVisible.value = true;
        }
  
    function closeModal() {
        isModalVisible.value = false;
    }

    function emitChangeResultFiltersAndSetChanged(){
        context.emit('change-result-filters', filters)
        context.emit('set-changed-result-filters')
      }

    function setResultGovernmentDesignationFilter(target) {
        if(target.includes("clear")){
          resultGovernmentDesignationFilter.value = []
        } else {
          resultGovernmentDesignationFilter.value = target;
        }
        emitChangeResultFiltersAndSetChanged()
    }

    function setResultGoverningSpecificationFilter(target) {
        if(target.includes("clear")){
          resultGoverningSpecificationFilter.value = []
        } else {
          resultGoverningSpecificationFilter.value = target;
        }
        emitChangeResultFiltersAndSetChanged()
    }

    function setGovernmentDesignationOptions() {
        governmentDesignationOptions.value = [{value: "clear", label: "------------"}, ...props.selectedGovernmentDesignations.map((governmentDesignation) => {
            return {value: governmentDesignation.id, label: `${governmentDesignation.government_designation} (${governmentDesignation.governing_specification.qpl_number})`}
        })]
    }

    function setGoverningSpecificationOptions() {
        governingSpecificationOptions.value = [{value: "clear", label: "------------"}, ...props.selectedGoverningSpecifications.map((governingSpecification) => {
            return {value: governingSpecification.id, label: `${governingSpecification.qpl_number} ${governingSpecification.title}`}
        })]
    }

    function clearFilters() {
        resultGovernmentDesignationFilter.value=[]
        resultGoverningSpecificationFilter.value=[]
        emitChangeResultFiltersAndSetChanged()
    }

    function searchByQPL() {
        context.emit('search-by-qpl')
    }

    function setCompanyBulkAction(title, endpoint, data=null) {
      if(props.selectedResults.length > 0) {
        bulkActionTitle.value = title
        bulkActionEndpoint.value = endpoint
        bulkActionData.value = data
      } else {
        bulkActionTitle.value = 'Alert'
        bulkActionEndpoint.value = null
        bulkActionData.value = null
      }
      showCompanyModal()
    }

    function showCompanyModal() {
      isCompanyModalVisible.value = true;
    }

    function closeCompanyModal() {
      isCompanyModalVisible.value = false;
    }

    function showCollectionTagModal() {
      isCollectionTagModalVisible.value = true;
    }

    function closeCollectionTagModal() {
      isCollectionTagModalVisible.value = false;
    }

    function setCollectionTagBulkAction (title, message, endpoint, data=null) {
      bulkActionTitle.value = title
      bulkActionMessage.value = message
      bulkActionEndpoint.value = endpoint
      bulkActionData.value = data
      showCollectionTagModal()
    }

    async function loadCollectionTags(){
      const token = store.getters['auth/showToken']
      const result = await getCollectionTags(token)
      collectionTags.value = result
    }
  
      return {
        emitSplitPage: emitSplitPage,
        showModal: showModal,
        closeModal: closeModal,
        setResultGovernmentDesignationFilter: setResultGovernmentDesignationFilter,
        setResultGoverningSpecificationFilter: setResultGoverningSpecificationFilter,
        setGovernmentDesignationOptions: setGovernmentDesignationOptions,
        setGoverningSpecificationOptions: setGoverningSpecificationOptions,
        setCompanyBulkAction: setCompanyBulkAction,
        setCollectionTagBulkAction: setCollectionTagBulkAction,
        searchByQPL: searchByQPL,
        clearFilters: clearFilters,
        loadCollectionTags: loadCollectionTags,
        closeCompanyModal: closeCompanyModal,
        closeCollectionTagModal: closeCollectionTagModal,
        resultGovernmentDesignationFilter,
        resultGoverningSpecificationFilter,
        governmentDesignationOptions,
        governingSpecificationOptions,
        isModalVisible,
        isCompanyModalVisible,
        isCollectionTagModalVisible,
        bulkActionTitle,
        bulkActionMessage,
        bulkActionData,
        bulkActionEndpoint
      }
    }
  }
  </script>
  
  <style scoped>
    /* @import "vue-select/dist/vue-select.css"; */
  </style>
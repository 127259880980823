<template>
  <tr v-if="!isEditing" class="max-h-[10px]">
    <base-table-cell v-if="!isExpanded">
      <div class="pt-1">
        <base-button
          @click="toggleExpand"
          class="text-base font-bold ml-2 w-[35px] h-[35px] px-[10px]">
          +
        </base-button>
      </div>
    </base-table-cell>
    <base-table-cell v-if="isExpanded">
      <div class="pt-1">
        <base-button
          @click="toggleExpand"
          class="text-base font-bold ml-2 w-[35px] h-[35px] px-[10px]">
          -
        </base-button>
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pt-1">
        {{ activity.activity_name }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pt-1">
        {{ activity.email_from ? `FROM: ${activity.email_from}` : ""}} {{ activity.email_to ? `TO: ${activity.email_to}` : ""}}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pt-1">
        {{ activity.activity_note ? activity.activity_note.substring(0, 150) : "None"}} {{ activity.activity_note && activity.activity_note.length > 150 ? "..." : ""}}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pt-1">
        {{ activity.activity_date }}
      </div>
    </base-table-cell>
    <base-table-cell>
      <div class="pt-1">
        {{ activity.activity_type }}
      </div>
    </base-table-cell>
    <base-table-cell v-if="canEdit">
      <div class="pt-1">
        <base-button @click="showEdit" class="w-full">Edit</base-button>
        <base-button @click="showDelete" class="w-full">Delete</base-button>
      </div>
    </base-table-cell>
  </tr>
  <!-- EMAIL BODY EXPANSION -->
  <template v-if="isExpanded">
    <base-table-cell></base-table-cell>
    <base-table-cell 
      colspan="6"
      class="break-words bg-yellow-100"
    >
      <div class="xl:max-w-[100%-104px] max-w-[1200px]">
        <p v-if="activity.activity_note" v-html="activityNote"/>
        <p v-if="!activity.activity_note">
          (No activity note)
        </p>
      </div>
    </base-table-cell>
  </template>
  <!-- EDIT EXPANSION -->
  <template v-if="isEditing">
    <tr>
      <base-table-cell
        colspan="7"  
        class="w-full"
      >
        <div class="w-full border border-dark-primary">
          <div class="w-full">
            <div
              class="grid grid-cols-6 w-full gap-2 mb-1 p-1 text-[0.8rem]"
            >
              <div v-if="!missingDate" class="flex flex-col my-auto col-span-2">
                <label>Activity Date*</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.activityDate"
                />
              </div>
              <div v-if="missingDate" class="flex flex-col my-auto col-span-2">
                <label class="text-red-600">Activity Date*</label>
                <input
                  class="p-1 w-full inline border-red-400 border"
                  v-model="editData.activityDate"
                />
              </div>
              <div v-if="!missingName" class="flex flex-col my-auto col-span-2">
                <label>Activity Name* (Subject)</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.activityName"
                />
              </div>
              <div v-if="missingName" class="flex flex-col my-auto col-span-2">
                <label class="text-red-600">Activity Name* (Subject)</label>
                <input
                  class="p-1 w-full inline border-red-400 border"
                  v-model="editData.activityName"
                />
              </div>
              <div class="flex flex-col my-auto col-span-1">
                <label>Activity Type</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.activityType"
                />
              </div>
              <div class="flex flex-col my-auto col-span-1">
                <label>Sequence</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.activitySeq"
                />
              </div>
              <div class="flex flex-col my-auto col-span-2">
                <label>From (if email, otherwise leave blank)</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.emailFrom"
                />
              </div>
              <div class="flex flex-col my-auto col-span-2">
                <label>To (if email, otherwise leave blank)</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.emailTo"
                />
              </div>
              <div class="flex flex-col my-auto col-span-2">
                <label>CC (if email, otherwise leave blank)</label>
                <input
                  class="p-1 border w-full inline"
                  v-model="editData.emailCc"
                />
              </div>
              <div
                v-if="missingDate || missingName"
                class="text-center col-span-6 text-red-500 font-semibold"
              >
                Please complete all required fields.
              </div>
              <div class="flex flex-col h-[25vh] mt-auto col-span-6 row-span-2 mb-5">
                <label>Activity Note</label>
                <textarea
                  class="p-1 border h-full w-full"
                  v-model="editData.activityNote"
                />
              </div>
            </div>
            <div
                class="flex flex-row space-x-2 justify-center w-full my-3"
              >
              <base-button-affirmative 
                @click="submitEdit"
                :disabled="ongoingAPICall"
              >
                Save
              </base-button-affirmative>
              <base-button-negative @click="hideEdit">Cancel</base-button-negative>
            </div>
          </div>
        </div>
      </base-table-cell>
    </tr>
  </template>
  <!-- DELETE POP-UP -->
  <template>
    <TransitionRoot appear :show="isDeleteVisible" as="template">
      <Dialog as="div" @close="hideDelete" class="absolute z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
  
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  <slot name="header"></slot>
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-center text-gray-500">
                    Are you sure you want to delete this activity?
                  </p>
                </div>
                <div class="w-full">
                  <div class="w-min h-min mx-auto flex">
                    <div class="mt-4 mr-2">
                      <base-button-affirmative
                        type="button"
                        :disabled="ongoingAPICall"
                        @click="submitDelete"
                      >
                        Delete
                      </base-button-affirmative>
                    </div>
                    <div class="mt-4">
                      <base-button-negative
                        type="button"
                        @click="hideDelete"
                      >
                        Cancel
                      </base-button-negative>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  <div>
    <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
      <template #header>
          <span>{{ sourceStatus }}</span>
      </template>
      <template #body>
          <span>{{ sourceMessage }}</span>
      </template>
    </HeadlessDialogBox>
  </div>
</template>

<script setup>
  // <------------ GENERAL ------------>
  import { ref, defineEmits, defineProps, watch, onMounted} from "vue";
  import HeadlessDialogBox from "./HeadlessDialogBox.vue";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  } from '@headlessui/vue'
  import { deleteActivity, updateActivity } from "@/services/Activity";
  import { useStore } from "vuex";
  import { useToast } from 'vue-toast-notification';

  const $toast = useToast()
  const store = useStore()
  const props = defineProps({
    activity: Object,
    // states: Object,
    canEdit: Boolean
  })
  // console.log(props.activity.id)
  const emit = defineEmits([
    'toggle-expand',
    'show-edit',
    'hide-edit',
    'refetch-activities'
  ])
  const isExpanded = ref(false)
  const isEditing = ref(false)
  const activityNote = ref(null)

  function formatEmailBody() {
    if (props.activity.activity_note) {
      // emailBody.value = props.email.email_body.replace(/\r\n/g, "<br>");
      activityNote.value = props.activity.activity_note.replace(/(\r\n|\r|\n)/g, '<br>')
      // console.log(JSON.stringify(emailBody.value))
    }
  }

  function toggleExpand() {
    isExpanded.value = !isExpanded.value;
    formatEmailBody();
  }

  const sourceStatus = ref("")
  const sourceMessage = ref("")
  const openDialogBox = ref(false)  

  const editData = ref({
    activityDate: null,
    activityName: null,
    activityType: null,
    emailFrom: null,
    emailTo: null,
    emailCc: null,
    activitySeq: null,
    activityNote: null,
  })

  watch(
    () => props.states,
    () => {
      isExpanded.value = props.states.isExpanded
      isEditing.value = props.states.isEditing
      // console.log("UPDATE")
    }
  );

  // <------------ EDIT ------------>
  const missingDate = ref(false)
  const missingName = ref(false)
  const ongoingAPICall = ref(false)

  function showEdit() {
    editData.value.activityDate = props.activity.activity_date;
    editData.value.activityName = props.activity.activity_name;
    editData.value.activityType = props.activity.activity_type;
    editData.value.emailFrom = props.activity.email_from;
    editData.value.emailTo = props.activity.email_to;
    editData.value.emailCc = props.activity.email_cc;
    editData.value.activitySeq = props.activity.activity_seq;
    editData.value.activityNote = props.activity.activity_note;

    isExpanded.value = false;
    isEditing.value = true;
    // console.log(props.activity)
  }

  function hideEdit() {
    isExpanded.value = false;
    isEditing.value = false
  }

  async function submitEdit() {
    ongoingAPICall.value = true
    if (!editData.value.activityDate || !editData.value.activityName) {
      if (!editData.value.activityDate) {
        missingDate.value = true
      }
      if (!editData.value.activityName) {
        missingName.value = true
      }
      // console.log("Here")
      return
    }
    // popup of success/failure
    const updatedActivity = JSON.stringify({
        activity_date: editData.value.activityDate,
        activity_name: editData.value.activityName,
        activity_type: editData.value.activityType,
        email_from: editData.value.emailFrom,
        email_to: editData.value.emailTo,
        email_cc: editData.value.emailCc,
        activity_seq: editData.value.activitySeq,
        activity_note: editData.value.activityNote,
      });
    const payload = {
      token: store.state.auth.token,
      activityId: props.activity.id,
      activity: updatedActivity
    }
    // console.log(payload)
    // console.log(result)

    try {
      const result = await updateActivity(payload);
      if (result.status == 200) {
        // sourceStatus.value = "Success";
        // sourceMessage.value = "Updated activity.";
        // openDialogBox.value = true;
        hideEdit();
        $toast.open(
          {
            message: `Successfully Updated: '${editData.value.activityName}'`,
            type: 'success',
            duration: 20000
          }
        )
      } else {
        // sourceStatus.value = "Error";
        // sourceMessage.value = "Could not update activity.";
        // openDialogBox.value = true;
        $toast.open(
          {
            message: `${result.status} - could not create  '${editData.value.activityName}'`,
            type: 'error',
            duration: 20000
          }
        )
      }
    } catch (exception) {
      $toast.open(
        {
          message: `${exception.response.status} - could not create  '${editData.value.activityName}'`,
          type: 'error',
          duration: 20000
        }
      )
    }
    ongoingAPICall.value = false
    emit('refetch-activities')
  }

  function closeDialogBox(){
    openDialogBox.value = false
    hideEdit();
  } 

  // <------------ DELETE ------------>
  const isDeleteVisible = ref(false)

  function showDelete() {
    // console.log("DELETE WINDOW")
    isDeleteVisible.value = true
    sourceStatus.value = "Delete";
  }

  function hideDelete() {
    isDeleteVisible.value = false
  }

  async function submitDelete() {
    // axios call to update current activity
    ongoingAPICall.value = true
    const payload = {
      token: store.state.auth.token,
      activityId: props.activity.id
    }
    try {
      const result = await deleteActivity(payload)
      if (result.status == 204) {
        hideDelete();
        $toast.open(
          {
            message: `Activity deleted`,
            type: 'success',
            duration: 20000
          }
        )
      } else {
        $toast.open(
          {
            message: `Could not delete`,
            type: 'Error',
            duration: 20000
          }
        )
      }
      // console.log(result)
    } catch(exception) {
      // NOT TESTED YET!
      $toast.open(
        {
          message: `Could not delete`,
          type: 'Error',
          duration: 20000
        }
      )
    }
    // console.log("DELETING")
    ongoingAPICall.value = false
    emit('refetch-activities')
  }

  formatEmailBody();
</script>
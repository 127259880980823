export default {
    showCampaigns(state) {
        return state.campaigns;
    },
    showLoading(state) {
        return state.isLoading;
    },
    showFilterPane(state){
        return state.showFilterPane
    },
}
<template>
    <div class="w-full flex "
        @mouseenter="showInsert"
        @mouseleave="hideInsert"
    >
        <!-- <div class="w-12 flex justify-end items-center px-2 cursor-pointer"
            @click="parseSelectedToRow"
                    @mouseenter="setInsertDark"
                    @mouseleave="setInsertLight"
        >
            <div v-if="visibleInsert" class="h-6 w-6 rounded-full flex justify-center items-center"
            :style="insertStyle"
            >
                <InsertSVG
                />
            </div>
        </div> -->
        <div 
            class="w-full grid grid-cols-12"
        >
            <div class="w-full col-span-2  border-x border-b border-gray-300">
                <select v-model="eventRow.action_id" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                >
                    <option v-for="option in crmEventTypeOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="w-full border-r col-span-2 border-b border-gray-300">
                <input type="date" 
                    v-model="eventRow.resolved_date" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                />
            </div>
            <div class="w-full border-r border-b col-span-2 border-gray-300">
                <select v-model="eventRow.user_id" 
                    class="px-2 py-1 w-full h-full"
                    :style="cellStyle"
                >
                    <option v-for="option in userOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="w-full border-r border-b col-span-4 border-gray-300"
                    :style="cellStyle"
            >
                <textarea
                    v-model="eventRow.action_notes" 
                    class="px-2 py-1 w-full h-full overflow-hidden"
                    rows="1"
                    ref="eventNotes"
                    :style="cellStyle"
                ></textarea>
            </div>
            <div class="w-full border-r border-b col-span-2 border-gray-300"
                :style="cellStyle"
            >
                <textarea
                    v-model="eventRow.action_details" 
                    class="px-2 py-1 w-full h-full overflow-hidden"
                    rows="1"
                    ref="eventDetails"
                    :style="cellStyle"
                ></textarea>
            </div>
        </div>
        <div class="w-12 flex justify-end items-center px-3">
            <button class="bg-dark-secondary text-white hover:bg-dark-primary w-5 h-5 rounded-full"
                @click="removeEvent"
            >
                -
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, reactive, computed, watch, onMounted, onUpdated } from 'vue';
import InsertSVG from '../svg-components/InsertSVG.vue';

const props = defineProps(['crmEvent', 'type', 'crmEventTypeOptions', 'userOptions'])

const emit = defineEmits(['remove-event-row', 'update-event-row', 'parse-selected-to-row'])

const eventNotes = ref(null)

const visibleInsert = ref(false)
const insertStyle = ref('')

const eventDetails = ref(null)

const eventOrderMappingDict = ref({
    2: 0,
    3: 1,
    4: 2,
    21: 3,
    1: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    22: 9,
    9: 10,
    10: 11,
    11: 12,
    12: 13,
    13: 14,
    14: 15,
    15: 16,
    16: 17,
    17: 18,
    18: 19,
    20: 20,
    19: 21,
})


const eventRow = reactive({
    id: props.crmEvent ? props.crmEvent.id : null,
    action_id: props.crmEvent ? props.crmEvent.action ? props.crmEvent.action.id : null : null,
    resolved_date: props.crmEvent ? props.crmEvent.resolved_date : null,
    user_id: props.crmEvent ? props.crmEvent.user ? props.crmEvent.user.id : null : null,
    action_notes: props.crmEvent ? props.crmEvent.action_notes : null,
    action_details: props.crmEvent ? props.crmEvent.action_details : null,
    action_order: props.crmEvent ? props.crmEvent.action_order : null,
    type: props.crmEvent ? props.crmEvent.type : null,
})


watch(props.crmEvent, () => {
    validateRow(props.crmEvent)
    if(props.crmEvent) {
        if(props.crmEvent.action_id) {
            eventRow.action_id = props.crmEvent.action_id
        } else if (props.crmEvent.event) {
            eventRow.action_id = props.crmEvent.event.id
        }
        else {
            eventRow.action_id = null
        }
        if(props.crmEvent.user_id) {
            eventRow.user_id = props.crmEvent.user_id
        } else {
            if(props.crmEvent.user) {
                eventRow.user_id = props.crmEvent.user.id
            }
            else {
                eventRow.user_id = null
            }
        }
        eventRow.resolved_date = props.crmEvent.resolved_date
        eventRow.action_notes = props.crmEvent.action_notes
        eventRow.action_details = props.crmEvent.action_details
        eventRow.action_order = props.crmEvent.action_order
        eventRow.type = props.crmEvent.type
    } else {
        eventRow.action_id = null
        eventRow.resolved_date = null
        eventRow.user_id = null
        eventRow.action_notes = null
        eventRow.action_details = null
        eventRow.action_order = null
        eventRow.type = null
    }

}, {deep: true})

watch(eventRow, ()=> {
    console.log('eventRow Watcher Fired')
    updateEvent(eventRow)
})

const defaultCellStyle = computed(() => {
    if(props.crmEvent) {
        if(props.crmEvent.type) {
            if(props.crmEvent.type == 1) {
                return 'background-color: #A7F3D0;'
            } else if(props.crmEvent.type == 3) {
                return 'background-color: #FEF3C7;'
            } else if(props.crmEvent.type == 0) {
                return 'background-color: #F87171;'
            } else {
                return 'background-color: #BAE6FD;'
            }
        }
    }
    return ''
})

const cellStyle = ref(defaultCellStyle.value)

function resize() {
    eventDetails.value.style.height = eventDetails.value.style.scrollHeight + 'px';
    eventNotes.value.style.height = eventNotes.value.scrollHeight + 'px';

}

onMounted(() => {
    validateRow(props.crmEvent)
    resize()
})

onUpdated(() => {
    resize()
})

function validateRow(row) {
    console.log('validate row')
    // console.log(row)
    // if(!row.event_name) {
    if(!row.action_id){
        // console.log('no event id')
        cellStyle.value = 'background-color: #F87171;'
        row.type = 0
    } else {
        if(row.user_id || row.action_notes || row.action_details) {
            if(!row.resolved_date) {
                console.log('no resolved date')
                cellStyle.value = 'background-color: #F87171;'
                row.type = 0
                row.action_order = eventOrderMappingDict.value[row.action_id]
            } else {
                if(row.type == 1) {
                    cellStyle.value = 'background-color: #A7F3D0'
                } else {
                    cellStyle.value = 'background-color: #BAE6FD;'
                    row.type = 2
                    row.action_order = eventOrderMappingDict.value[row.action_id]
                }
            }
        } else if(row.resolved_date) {
            if(row.type == 1){
                cellStyle.value = 'background-color: #A7F3D0;'
            } else {
                cellStyle.value = 'background-color: #BAE6FD;'
                row.type = 2
                row.action_order = eventOrderMappingDict.value[row.action_id]
            }
        } else {
            cellStyle.value = 'background-color: #FEF3C7;'
            row.type = 3
            row.action_order = eventOrderMappingDict.value[row.action_id]
        }
    }
}

function showInsert() {
    visibleInsert.value = true
}

function hideInsert() {
    visibleInsert.value = false
}

function setInsertLight() {
    insertStyle.value = 'background-color: #EEF2FF;'
}

function setInsertDark() {
   insertStyle.value = 'background-color: #D1D5DB'
}

function removeEvent() {
    emit('remove-event-row', eventRow.id)
}

function updateEvent(rowData) {
    console.log('CRMEventRow: updateEvent (emit)')
    emit('update-event-row', rowData)
}

async function parseSelectedToRow() {
    emit('parse-selected-to-row', eventRow.id)
}

</script>
<template>
    <div class="">
      <div class="">
        <a href="#"
           class="text-sm
                hover:text-accent-primary
                  no-underline
                  border-b border-gray-400 
                  py-1
                  flex
                  justify-between"
           @click.prevent="active = !active">
            <slot name="title"></slot>
          <span class="down-Arrow" v-show="!active">&#9660;</span>
          <span class="up-Arrow" v-show="active">&#9650;</span>
        </a>
      </div>
      <transition
        v-on:before-enter="beforeEnter" v-on:enter="enter"
        v-on:before-leave="beforeLeave" v-on:leave="leave"
        >
        <div class="text-justify transition-all duration-200 ease-out h-full"  v-show="active">
              <slot name="accordian-body"></slot>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    name: "BaseAccordian.vue",
    props: ['startCollapsed'],
    data() {
      return {
        active: true,
      }
    },
    methods: {
      beforeEnter: function(el) {
        el.style.height = '0';
      },
      enter: function(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      beforeLeave: function(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      leave: function(el) {
        el.style.height = '0';
      }
    },
    created() {
      if(this.startCollapsed){
        this.active = false
      }
    }
  
  }
  </script>
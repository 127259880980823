<template>
    <div class="flex flex-col items-center">
        <ZiIdCell class="items-center" :company="company" />
        <div class="flex flex-col items-center"
            v-if="
                permissions.isDev ||
                permissions.isAdvancedResearch ||
                permissions.isAccountManager ||
                permissions.isManagement ||
                permissions.isSuperuser
                "
        >
            <GetZiBasicButtonCell v-if="company.zoom_info_company" :company="company" @refetch-companies="fetchNewCompanies" />
            <ClearZiButtonCell v-if="company.zoom_info_company" :company="company" @refetch-companies="fetchNewCompanies" />
            <GetZiIdButtonCell v-else :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
    </div>


</template>

<script>
    export default {
        name: "ziId-getZi-clearZi-cell"
    }
</script>

<script setup>
import {defineProps, defineEmits} from "vue"
import ZiIdCell from "../company-cell-components/ZiIdCell.vue";
import GetZiIdButtonCell from "../company-cell-components/GetZiIdButtonCell.vue";
import GetZiBasicButtonCell from "../company-cell-components/GetZiBasicButtonCell.vue";
import ClearZiButtonCell from "../company-cell-components/ClearZiButtonCell.vue";

    const props = defineProps({
        company: Object,
        permissions: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies() {
        emit('refetch-companies')
    }

</script>
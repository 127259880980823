import timecardsMutations from './mutations.js';
import timecardsActions from './actions.js';
import timecardsGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            showFilterPane: true,
            showActionsPane: true,
            showTimecardUsers: true,
        };

    },
    mutations: timecardsMutations,
    actions: timecardsActions,
    getters: timecardsGetters

};
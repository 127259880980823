<template>
    <div v-for="governingSpecification in governingSpecifications" :key="governingSpecification.id">
        <a target='_blank' :href="`${governingSpecification.document_url}`"><span class="font-bold text-accent-primary hover:text-dark-primary">{{ governingSpecification.qpl_number }}</span></a>
    </div>

</template>

<script>
    export default {
        name: "dla-governing-specification-cell"
    }
</script>

<script setup>
    import { defineProps, ref, computed } from 'vue';

    const props = defineProps({
        governingSpecifications: Array
    })


</script>
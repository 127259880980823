<template>
    <div>
        {{fullAddress}}
    </div>

</template>

<script>
    export default {
        name: "location-cell"
    }
</script>

<script setup>
    import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const fullAddress = computed(() => {
                return `${props.company.address ? props.company.address : "--"} ${props.company.city ? props.company.city : "--"}, ${props.company.state ? props.company.state : "--"} ${props.company.country ? props.company.country : "--"}`
            })
</script>
<template>
    <div>
        {{fullAddress}}
    </div>

</template>

<script>
    export default {
        name: "location-city-state-cell"
    }
</script>

<script setup>
    import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const fullAddress = computed(() => {
        if(props.company.country === 'USA' || props.company.country === 'United States' || props.company.country === 'United States of America') {
            return `${props.company.city ? props.company.city : "--"}, ${props.company.state ? props.company.state : "--"}`
        } else {
            return `${props.company.city ? props.company.city : "--"}, ${props.company.state ? props.company.state : "--"} ${props.company.country ? props.company.country : "--"}`
        }

    })
</script>
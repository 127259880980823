<template>
    <div 
        class="whitespace-pre-wrap px-1 py-2 border-b border-gray-400">
        <div class="flex justify-between items-center pb-1 mb-1 tracking-wider">
            <div class="flex justify-start items-center space-x-2">
                <div class="font-semibold ">{{ actionResolvedDate }}</div>
                <div class="font-semibold truncate tracking-wide"> {{ actionUser }}</div>
            </div>
            <div v-if="actionDetails==''"  class="font-semibold" >{{ actionName }}</div>
            <div  v-else class="flex justify-end items-center">
                <div class="font-semibold cursor-pointer underline hover:text-accent-primary" @click="showActionDetails">{{ actionName }}</div>
                <InfoModal
                    :is-modal-visible="visibleActionDetails"
                    @close="hideActionDetails"
                >
                    <template #header>
                    Event Details
                    </template>
                    <template #body>
                        <div class="w-full whitespace-pre-wrap break-before-auto text-start">
                            {{ actionDetails }}
                        </div>
                    </template>
                </InfoModal>
            </div>
        </div>
        <div>
            <div>{{ actionNotes}}</div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { formatDate } from '@/composables/formatDate';
import InfoModal from "../InfoModal.vue"

const props = defineProps(['crmAction'])

const visibleActionDetails = ref(false)

const actionResolvedDate = computed(() => {
    if(props.crmAction) {
        if(props.crmAction.resolved_date) {
            return `${formatDate(props.crmAction.resolved_date)}:`
        }
    }
    return ''
})

const actionUser = computed(() => {
    if(props.crmAction) {
        if(props.crmAction.user){
            return `${props.crmAction.user.first_name} ${props.crmAction.user.last_name}`
        }
    }
    return ''
})

const actionName = computed(() => {
    if(props.crmAction) {
        if(props.crmAction.action){
            return props.crmAction.action.action_name
        }
    }
    return ''
})

const actionNotes = computed(() => {
    if(props.crmAction) {
        if(props.crmAction.action_notes){
            return props.crmAction.action_notes
        }
    }
    return ''
})

const actionDetails = computed(() => {
    if(props.crmAction) {
        if(props.crmAction.action_details){
            return props.crmAction.action_details
        }
    }
    return ''
})

function showActionDetails() {
    visibleActionDetails.value = true
}

function hideActionDetails() {
    visibleActionDetails.value = false
}

</script>
<template>
<div class=" w-full grid grid-cols-12">
    <div class="col-span-2 px-3 py-2 bg-emerald-200 border-b border-r border-l border-gray-300">{{ event.action ? event.action.action_name : '--' }}</div>
    <div class="col-span-2 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ event.resolved_date ? formatDate(event.resolved_date) : '--'}}</div>
    <div class="col-span-2 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ event.user ? `${event.user.first_name} ${event.user.last_name}` : '--' }}</div>
    <div class="col-span-4 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ event.action_notes ? event.action_notes : '--' }}</div>
    <div class="col-span-2 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300 flex justify-center">
        <div v-if="event.action_details" class="flex justify-center items-center">
            <button @click="showDetailsModal" class="bg-dark-secondary text-white hover:bg-dark-primary px-2 py-1 rounded">Details</button>
            <InfoModal
                v-if="event.action_details"
                :is-modal-visible="visibleDetailsModal"
                @close="closeDetailsModal"
            >
                <template #header>
                Event Details
                </template>
                <template #body>
                    <div class="w-full whitespace-pre-wrap break-before-auto text-start">
                        <p >{{ event.action_details }}</p>
                    </div>
                </template>
            </InfoModal>
        </div>
        <div v-else class="flex justify-center items-center">
            --
        </div>
    </div>
</div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import { formatDate } from '@/composables/formatDate';
import InfoModal from '../InfoModal.vue';

const props = defineProps(['event'])

const visibleDetailsModal = ref(false)

function showDetailsModal() {
    visibleDetailsModal.value = true
}

function closeDetailsModal() {
    visibleDetailsModal.value = false
}

</script>
<template>
    <base-table-auto>
        <template #table-header>
            <base-table-header
                :key="header.id"
                :header="header"
                v-for="header in tableHeadersList"
            >
            {{ header }}
            </base-table-header>
        </template>
        <template #table-body>
            <JobParameterRow
                :key="parameter.id"
                :parameter="parameter"
                v-for="parameter in job.parameters"
            />
        </template>
    </base-table-auto>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import JobParameterRow from './JobParameterRow.vue';


const props = defineProps(['job'])

const tableHeadersList = ref([
    'Parameter Name',
    'Parameter Value',
    'Parameter Type',
    'Metrics'
])



</script>
<template>
    <div class="flex justify-center items-center">
        <div>
            <base-button @click="showDetails" >⛶</base-button>
        </div>
        <base-modal :is-form-visible="detailsVisible" @close="closeDetails">
            <template #header>
                <h2 class="text-lg">Award <a :href="contract.usaspending_permalink" class="text-accent-secondary" target="_blank">{{ contract.award_id_piid }}</a>  ({{ contract.id }})</h2>
            </template>
            <template #body>
                <PrimeContractDetailsPage
                :contract="contract"
                @close="closeDetails"
                />
            </template>
        </base-modal>
    </div>
</template>

<script>
export default {
    name: 'prime-contract-details-cell'
}
</script>

<script setup>
import { ref, computed, defineProps } from 'vue';
import PrimeContractDetailsPage from './PrimeContractDetailsPage.vue';

const props = defineProps({
    contract: Object    
})

const detailsVisible = ref(false)

function showDetails(){
    detailsVisible.value = true
}

function closeDetails(){
    detailsVisible.value = false
}

</script>
<template>
    <button class="
        rounded-md
        px-1
        py-1
        hover:shadow-md
        bg-dark-secondary
        text-white
        border
        border-dark-secondary
        active:bg-dark-primary
        active:text-white
        focus:bg-dark-primary
        focus:text-white
        text-sm
        cursor-pointer
        sm:text-xs
        sm:h-fit
      ">
        <slot></slot>
    </button>
  
  </template>
  
  <script>
  export default {
    name: "BaseButtonNegative.vue"
  }
  </script>
  <style scoped>
  </style>
import axios from 'axios';
import { BASE_URL } from './api';
import { saveAs } from 'file-saver';

export async function downloadTemplate(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/companies/download_xlsx_template/`,
        headers: {
            'Authorization': `Token ${token}`,
        },
        responseType: 'blob'
    }).then((response) => {
        console.log('response xlsx', response)
        saveAs(new Blob([response.data]), `companies_template_${new Date().toDateString()}.xlsx`)
        return {
            response
        }
    }).catch((error) => {
        console.log(error)
    })
}


export async function checkCompaniesTemplateValid(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/companies/check_companies_template_valid/`,
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}

export async function createCompaniesFromXLSX(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/companies/create_companies_from_file/`,
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}


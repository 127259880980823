<template>
    <div v-if="company.collection_tags.length > 0">
        <div class="flex justify-start">
            <base-button v-if="!details" @click="showDetails">Expand All</base-button>
            <base-button v-else @click="hideDetails">Collapse All</base-button>
        </div>
        <base-card v-for="collectionTag in company.collection_tags" :key="collectionTag.id">
            <div class="flex flex-col p-0.5 whitespace-nowrap">
                <div v-if="details">
                    <div class="flex flex-row items-center border-b border-gray-300 pb-0.5 mb-0.5 justify-between">
                        <span class="pr-1">
                            <strong>Collection:</strong> {{collectionTag.collection_tag.collection_tag_name}}
                        </span>
                        <base-button-negative @click="remove(collectionTag.collection_tag.id)">X</base-button-negative>
                    </div>
                    <div><strong>Created:</strong> {{ formatDateTime(`${collectionTag.created_at}`) }}</div>
                    <div><strong>Source:</strong> {{collectionTag.source ? collectionTag.source : '--'}}</div>
                    <div><strong>Async Job:</strong> {{ collectionTag.async_job ? collectionTag.async_job.job_title : '--' }}</div>
                </div>
                <div v-else>
                    <div class="flex flex-row items-center mb-0.5 justify-between">
                        <span class="pr-1">
                            <strong>Collection:</strong> {{collectionTag.collection_tag.collection_tag_name}}
                        </span>
                        <base-button-negative @click="remove(collectionTag.collection_tag.id)">X</base-button-negative>
                    </div>
                </div>
            </div>
        </base-card>
    </div>
    <div class="flex flex-row justify-center" v-else>
        --
    </div>
</template>

<script>
    export default {
        name: "collection-tag-cell"
    }
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { removeCollectionTag } from '@/services/Company';
import { formatDateTime } from '@/composables/formatDateTime';
import {useStore} from 'vuex';

const store = useStore()


const props = defineProps({
    company: [Object],
})

const tableHeadersList = [
    'Collection Tag', 'Source', 'Related Job', 'Date Added'
]

const emit = defineEmits(['refetch-companies'])

const details = ref(false)

function showDetails () {
    details.value = true;
}

function hideDetails () {
    details.value = false;
}

async function remove(collectionTagId) {
    const token = store.state.auth.token
    const result = await removeCollectionTag(collectionTagId, props.company.id, token)
    console.log(result)
    emit('refetch-companies')
}

</script>
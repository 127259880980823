<template>
    <tr>
        <td class="w-1/6">
            <select name="action"  v-model="actionData.action_id" class="p-1 border h-full w-full" id="">
                <option v-for="action in actionOptions" :value="action.id" :key="action.id">{{ action.action_name }}</option>
            </select>
        </td>
        <!-- <td class="w-1/12">
            <input type="date" class="p-1 border h-full w-full" v-model="actionCreatedDate" disabled>
        </td> -->
        <!-- <td class="w-1/12">
            <input type="date" class="p-1 border " required @input="setDueDate" v-model="actionDueDate">
        </td> -->
        <!-- <td class="w-1/12">
            <select name="actionStatus" @change="setActionStatus" v-model="actionStatus" class="p-1 border h-full w-full" id="">
                <option value="Open">Open</option>
                <option value="Done">Done</option>
                <option value="Cancelled">Cancelled</option>
            </select>
        </td> -->
        <td class="w-1/12">
            <input type="date" class="p-1 border " v-model="actionData.resolved_date">
        </td>
        <td class="w-1/12">
            <select name="user" @change="setUser" v-model="user" class="p-1 border h-full w-full" id="">
                <option v-if="user == 22" value=22>Patrick Alvarez</option>
                <option value=55>Brandon Ahearn</option>
                <option value=37>Dan Klawitter</option>
                <option value=56>Tony Patsatzis</option>
                <option value=3>Scott Shedd</option>
                <option value=23>Matthew Strickler</option>
            </select>
        </td>
        <td class="w-1/4">
            <textarea name="" id="" @change="setActionNotes" class="p-1 border h-full w-full" v-model="actionNotes"></textarea>
        </td>
        <td class="w-1/4">
            <textarea name="" id="" @change="setActionDetails" v-model="actionDetails" class="p-1 border h-full w-full"></textarea>
        </td>
        <td>
            <div class="flex justify-evenly">
                <base-button-affirmative @click="addAction">Save</base-button-affirmative>
                <base-button-negative @click="closeForm">X</base-button-negative>
            </div>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "crm-action-form"
    }
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { addNewCrmAction } from '@/services/Company'
import { useStore } from 'vuex';

    const store = useStore()

    const props = defineProps({
        company: Object,
        actionOptions: Array
    })

    const emit = defineEmits(['refetch-companies', 'close-form'])

    const actionName = ref('')
    const actionId = ref(null)
    const actionCreatedDate = ref(new Date().toJSON().substring(0, 10))
    const actionDueDate = ref('')
    const actionStatus = ref("Open")
    const actionResolvedDate = ref(getToday())
    const actionNotes = ref('')
    const actionDetails = ref('')
    const user = ref (store.state.auth.user.id)

    function getTomorrow(){
        let today = new Date()
        let tomorrow = today.getDate() + 1
        today.setDate(tomorrow)
        actionDueDate.value = today.toJSON().substring(0,10)
        actionData.value.due_date = actionDueDate.value
    }

    function getToday(){
        let today = new Date()
        today = today.toJSON().substring(0,10)
        return today
    }

    function setActionName(event) {
        actionData.value.action_name = event.target.value
    }

    function setDueDate(event) {
        actionData.value.due_date = event.target.value
    }

    function setActionStatus(event) {
        actionData.value.status = event.target.value
    }

    function setActionResolvedDate(event){
        actionData.value.resolved_date = event.target.value
    }

    function setActionNotes(event) {
        actionData.value.action_notes = event.target.value
    }

    function setActionDetails(event) {
        actionData.value.action_details = event.target.value
    }

    function setUser(event){
        actionData.value.user = event.target.value
    }

    const actionData = ref({
        action_name: null,
        status: actionStatus.value,
        due_date: actionDueDate.value,
        user: user.value,
        action_notes: null,
        resolved_date: getToday(),
        action_details: null,
        action_id: null
    })

    function closeForm(){
        emit('close-form')
    }

    async function addAction(){
        const token = store.state.auth.token
        let errorMessage = "Error, missing "
        if(!actionData.value.action_id || !actionData.value.due_date){
            if(!actionData.value.action_id){
                errorMessage += "event"
            }
            if(!actionData.value.due_date){
                errorMessage += "due date"
            }
            alert(errorMessage)
            return
        }
        if(!actionData.value.resolved_date){
            actionData.value.resolved_date = null
        }
        const data = actionData.value
        console.log('form data', data)
        await addNewCrmAction(props.company.id, data, token)
        emit('refetch-companies')
        emit('close-form')
    }

    getTomorrow()

</script>
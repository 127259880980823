<template>
    <base-dblclick-input
      modelType="contact"
      :model-id="contact.id"
      field-name="personal_phone"
      :field-value="contact.personal_phone"
      inputType="tel"
      @refetch="fetchNewContacts"
    >
        <span class="pr-1"><img id="phone" src="@/assets/images/icons8-phone-30.png" alt="phone" class="w-[16px] inline"/></span>
    </base-dblclick-input>
</template>

<script>
    export default {
        name: "contact-personal-phone-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    contact: Object
})

const emit = defineEmits(['refetch-contacts'])

function fetchNewContacts () {
    emit('refetch-contacts')
}
</script>
<template>
  <th class="bg-gray-100 border drop-shadow-md h-full text-sm pt-1 pr-3 p1-2 pl-2 sticky -top-0.5">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "base-table-header",
  props: ['header', 'alignment'],
}
</script>

<style scoped>
</style>
<template>
      <div @dblclick="toggleEditable">
        <span v-if="isEditable">
          <BaseEditInput
            modelType="company"
            :inputText="company.company_name"
            :modelId="company.id"
            fieldName="company_name"
            inputType="text"
            @toggle-editable="toggleEditable"
            @refetch="fetchNewCompanies"
            @update-field-value="setCompanyName"
          /></span>
        <span v-else>
            <span v-if="company.acquirer">
            <span  @mouseover="showPopover" @mouseleave="hidePopover" class="text-alert-primary" ref="nameElement">{{ displayedCompanyName }}</span>
            <base-popover-hover 
              v-if="isPopoverVisible"
              :xPosition="xPosition"
              :yPosition="yPosition"
            >
              <template v-slot:title>
                Acquired By:
              </template>
              <template v-slot:body>
                <span class="font-bold">Acquirer:</span> {{ company.acquirer }}<br>
                <span class="font-bold">Acq. Date:</span> {{ formattedAcquisitionDate }}
              </template>
            </base-popover-hover>
          </span>
          <span v-else-if="company.further_research_needed">
            <span  @mouseover="showPopover" @mouseleave="hidePopover" class="text-alert-secondary" ref="nameElement">{{ displayedCompanyName }}</span>
            <base-popover-hover 
              v-if="isPopoverVisible"
              :xPosition="xPosition"
              :yPosition="yPosition"
            >
              <template v-slot:title>
                Further Research Needed:
              </template>
              <template v-slot:body>
                {{ company.further_research_needed }}
              </template>
            </base-popover-hover>
          </span>
          <span v-else>{{ displayedCompanyName }}</span>
          <span v-if="company.reason_cannot_acquire">
            <base-pill-popover-hover
              class="bg-amber-300 border-none text-dark-primary p-0.5 ml-2"
              :popoverTitle="'Reason Cannot Acquire'"
              :popoverBody="company.reason_cannot_acquire"
            >
              Can't Acquire
            </base-pill-popover-hover>
          </span>
          <span v-if="company.archive_reason">
            <base-pill-popover-hover
              class="bg-red-400 border-none text-alert-pill-primary-dark p-0.5 ml-2"
              :popoverTitle="'Archive Reason'"
              :popoverBody="company.archive_reason"
            >
              Archive
            </base-pill-popover-hover>
          </span>
        </span>
      </div>
</template>

<script>
    export default {
        name: "company-name-cell"
    }

</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";
import {formatDate} from '@/composables/formatDate'
import BaseEditInput from "../BaseEditInput.vue";

const isEditable = ref(false)
const isPopoverVisible = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const nameElement = ref(null)
const companyName = ref(null)

const props = defineProps({
    company: Object,
})

    

const emit = defineEmits(['refetch-companies'])

watch(props, ()=> {
  companyName.value = null
})

watch(companyName, ()=> {
    if(companyName.value=='--') {
        companyName.value = null
    }
})


function setCompanyName(newValue) {
  companyName.value = newValue
}

function fetchNewCompanies () {
    emit('refetch-companies')
}

function toggleEditable () {
    isEditable.value = !isEditable.value
}

function showPopover() {
          isPopoverVisible.value = true;
          xPosition.value = nameElement.value.getBoundingClientRect().right
          yPosition.value = nameElement.value.getBoundingClientRect().top
        }

function hidePopover() {
          isPopoverVisible.value = false;
          xPosition.value = 0;
          yPosition.value = 0;
        }

const formattedAcquisitionDate = computed(() => {
  try {
    return formatDate(`${props.company.acquisition_date}`)
  } catch {
    return '--'
  }
})

const displayedCompanyName = computed(() => {
  if(props.company.research_complete === -1) {
    if(companyName.value) {
      return `${companyName.value} (Research Halted)`
    } else {
      return `${props.company.company_name} (Research Halted)`
    }
  } else {
    if(companyName.value) {
      return companyName.value
    } else {
      return props.company.company_name
    }
  }
})

</script>
<template>
    <div>
        <div v-if="nextTaskDue">
            <div :class="{'bg-yellow-300': daysUntilDue < 0}">
                {{ daysUntilDue }}
            </div>
        </div>
        <div v-else>
            --
        </div>
    </div>
</template>

<script>
    export default {
        name: "days-until-next-task-due-cell"
    }
</script>

<script setup>
import { defineProps, computed } from 'vue';

    const props = defineProps({
        company: Object
    })

    const nextTaskDue = computed(() => {
        let openTasks = props.company.crm_actions.filter(action => action.campaign.id == props.company.campaign.id && action.status == 'Open')
        if(openTasks.length == 1){
            return openTasks[0]
        } else if(openTasks.length == 0) {
            return null
        }
        let nextDue = openTasks.reduce((accumulator, currentValue) => {
            let accDate = new Date(accumulator.due_date).getTime()
            let currDate = new Date(currentValue.due_date).getTime()
            if(accDate < currDate){
                return accumulator
            } else {
                return currentValue
            }
        })
        return nextDue
    })

    const daysUntilDue = computed(() => {
        const dueDate = new Date(nextTaskDue.value.due_date) 
        const now = new Date()
        const diffTime = dueDate - now;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays 
    })


</script>
<template>
    <TableRow
        :isSelected="isSelected"
    >
        <TableCell>
            <div
                @mouseover="showSelectBox"
                @mouseleave="hideSelectBox"
                class="flex justify-start items-center p-2 pl-4 w-[4.5rem]"
            >
                <div v-if="visibleSelectBox" class="flex justify-start items-center">
                    <input type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
                </div>
                <div v-else class="whitespace-nowrap flex justify-start items-center w-full">
                    {{ serviceRecord.id }}
                </div>
            </div>

        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap w-full">
                <base-dblclick-select
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="type"
                    :field-value="serviceRecord.type"
                    :field-style="cellDisplayStyle"
                    :field-options="serviceRecordTypeOptions"
                    :displayed-field-value="serviceRecord.type"
                    @refetch="refetchResults"
                    class="w-full"
                ></base-dblclick-select>
            </div>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full">
                <base-dblclick-input
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="title"
                    :field-value="serviceRecord.title"
                    :field-style="cellDisplayStyle"
                    inputType="text"
                    @refetch="refetchResults"
                    class="w-full"
                ></base-dblclick-input>
            </div>
        </TableCell>
        <TableCell class="p-2">
            <div
                @mouseover="showDescriptionPopover"
                @mouseleave="hideDescriptionPopover"
                ref="descriptionBoundary"
                class="min-w-40 h-fit hover:cursor-help  whitespace-nowrap"
            >
                <base-dblclick-textarea
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="description"
                    :field-value="serviceRecord.description"
                    :field-style="descriptionDisplayStyle"
                    inputType="text"
                    @refetch="refetchResults"
                    @is-editable="enableDescriptionPopoverOverride"
                    @not-editable="disableDescriptionPopoverOverride"
                    class="w-full min-w-40"
                ></base-dblclick-textarea>
                <base-popover-hover-small
                    v-if="visibleDescriptionPopover"
                    :xPosition="xPosition"
                    :yPosition="yPosition"
                    class="w-full mr-24"
                >
                    <template v-slot:title>
                        Description:
                    </template>
                    <template v-slot:body>
                        <span  class="inline whitespace-pre-wrap">{{ serviceRecordDescription }}</span>
                    </template>
                </base-popover-hover-small>
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap">
                <base-dblclick-input
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="date"
                    :field-value="serviceRecord.date"
                    :field-style="cellDisplayStyle"
                    inputType="date"
                    @refetch="refetchResults"
                    class="w-full"
                ></base-dblclick-input>
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap">
                <base-dblclick-input
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="hours"
                    :field-value="serviceRecord.hours"
                    :field-style="cellDisplayStyle"
                    inputType="number"
                    @refetch="refetchResults"
                    class="w-full"
                ></base-dblclick-input>
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap  p-2">
                {{ serviceRecordHourlyRate }}
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap p-2">
                {{ serviceRecordAmountDue }}
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap">
                <!-- {{ serviceRecordUser }} -->
                <base-dblclick-select
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="user_id"
                    :field-options="userOptions.slice(1)"
                    :field-value="serviceRecord.user ? serviceRecord.user.id : null"
                    :displayed-field-value="serviceRecord.user ? `${serviceRecord.user.first_name} ${serviceRecord.user.last_name}` : null"
                    :field-style="cellDisplayStyle"
                    inputType="number"
                    @refetch="refetchResults"
                    class="w-full"
                ></base-dblclick-select>
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap ">
                <!-- {{ serviceRecordEngagement }} -->
                <base-dblclick-select
                    modelType="serviceRecord"
                    :model-id="serviceRecord.id"
                    field-name="engagement_id"
                    :field-options="engagementOptions"
                    :field-value="serviceRecord.engagement ? serviceRecord.engagement.id : null"
                    :displayed-field-value="serviceRecord.engagement ? serviceRecord.engagement.title : null"
                    :field-style="cellDisplayStyle"
                    inputType="number"
                    @refetch="refetchResults"
                    class="w-full"
                ></base-dblclick-select>
            </div>
        </TableCell>
        <TableCell>
            <div class="p-2">
                {{ serviceRecordInvoice }}
            </div>
        </TableCell>
        <TableCell>
            <div class="w-full flex justify-between items-center py-1.5 px-2.5">
                <button disabled class="bg-dark-secondary text-white rounded-md py-0.5 px-1 invert hover:shadow-md" v-if="isLoading">❐</button>
                <button v-else @click="duplicateRecord" class="bg-dark-secondary text-white rounded-md py-0.5 px-1 hover:invert hover:shadow-md">
                    ❐
                </button>
                <button
            class="bg-dark-secondary text-white rounded-md py-0.5 px-1 hover:shadow-md"
            @click="removeServiceRecord"
        >
            X    
        </button>
            </div>
        </TableCell>
    </TableRow>
</template>

<script setup>
import {ref, defineProps, defineEmits, computed, watch } from "vue";
import { useStore } from "vuex";
import TableRow from '@/components/base-components/TableRow.vue';
import TableCell from '@/components/base-components/TableCell.vue';
import { deleteServiceRecord, duplicateServiceRecord } from "@/services/ServiceRecord";
import { useToast } from 'vue-toast-notification';
import { formatDate } from "@/composables/formatDate";

const store = useStore()
const $toast = useToast()

const props = defineProps([
    'serviceRecord',
    'selectAll',
    'engagementOptions',
    'userOptions',
    'isLoading'
])

const emit = defineEmits([
    'refetch-results', 
    'add-selected-service-record', 
    'remove-selected-service-record'
])

const isSelected = ref(props.selectAll)
const visibleSelectBox = ref(false)
const descriptionBoundary = ref(null)
const visibleDescriptionPopover = ref(false)
const descriptionPopoverOverride = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const cellDisplayStyle = ref('padding:0.5rem;')
const descriptionDisplayStyle = ref('white-space: nowrap;')
const serviceRecordTypeOptions = ref([
    {value: null, label: "------------"},
    {value: 'Billed Services', label: 'Billed Services'},
    {value: 'PTO', label: 'PTO'},
    {value: 'Sick Leave', label: 'Sick Leave'},  
])


watch(() => props.selectAll, () => {
    isSelected.value = props.selectAll
    if(props.selectAll) {
        showSelectBox()
        emit('add-selected-service-record', props.serviceRecord)
    } else if (!props.selectAll) {
        hideSelectBox()
        emit('remove-selected-service-record', props.serviceRecord)
    }
})

const serviceRecordUser = computed(() => {
    if(props.serviceRecord.user) {
        return `${props.serviceRecord.user.first_name} ${props.serviceRecord.user.last_name}`
    } else {
        return '--'
    }
})

const serviceRecordHourlyRate = computed(() => {
    if(props.serviceRecord.hourly_rate) {
        return `${props.serviceRecord.hourly_rate}`
    } else {
        return'--'
    }
})

const serviceRecordAmountDue = computed(() => {
    if(props.serviceRecord.amount_due) {
        return `$${props.serviceRecord.amount_due}`
    } else {
        return'--'
    }
})

const serviceRecordDescription = computed(() => {
    if(props.serviceRecord.description) {
        return props.serviceRecord.description
    } else {
        return '--'
    }
})

const serviceRecordEngagement = computed(() => {
    if(props.serviceRecord.engagement) {
        return `${props.serviceRecord.engagement.client.client_name}: ${formatDate(props.serviceRecord.engagement.start_date)} to ${props.serviceRecord.engagement.end_date ? formatDate(props.serviceRecord.engagement.end_date): 'Current'}`
    } else {
        return '--'
    }
})

const serviceRecordInvoice = computed(() => {
    if(props.serviceRecord.invoice) {
        return `#-${props.serviceRecord.invoice.invoice_number}`
    } else {
        return props.serviceRecord.invoicing_in_progress ? 'Invoicing In Progress': '--'
    }
})

function toggleSelected() {
    if(isSelected.value) {
        isSelected.value = false
        emit('remove-selected-service-record', props.serviceRecord)
    } else {
        isSelected.value = true
        emit('add-selected-service-record', props.serviceRecord)
    }
}

function refetchResults() {
    emit('refetch-results')
}

function showDescriptionPopover() {
    if(!descriptionPopoverOverride.value) {
        visibleDescriptionPopover.value = true;
        xPosition.value = descriptionBoundary.value.getBoundingClientRect().left + 80
        yPosition.value = descriptionBoundary.value.getBoundingClientRect().bottom
    }

}

function enableDescriptionPopoverOverride() {
    descriptionPopoverOverride.value = true
    hideDescriptionPopover()
}

function disableDescriptionPopoverOverride() {
    descriptionPopoverOverride.value = false
}

function hideDescriptionPopover() {
    visibleDescriptionPopover.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}

function showSelectBox() {
    visibleSelectBox.value = true
}

function hideSelectBox() {
    if(!props.selectAll & !isSelected.value) {
        visibleSelectBox.value = false
    }

}

async function removeServiceRecord() {
    if(confirm(`You Are About to Delete Service Record:  ${props.serviceRecord.title} (ID-${props.serviceRecord.id}). Do you wish to continue?`)) {
        const token = store.state.auth.token
        const result = await deleteServiceRecord(props.serviceRecord.id, token)
        if(result.status == 200) {
            $toast.open({
                message: `Successfully Deleted Service Record: ${props.serviceRecord.title} (ID-${props.serviceRecord.id}).`,
                type: 'success',
                duration: 5000
            })
            refetchResults()
        } else {
            $toast.open({
                message: `Unable to Delete Service Record: ${props.serviceRecord.title} (ID-${props.serviceRecord.id}).`,
                type: 'error',
                duration: 20000
            })
        }
    }

}

async function duplicateRecord() {
    console.log('duplicate record')
    const token = store.state.auth.token
    const result = await duplicateServiceRecord(props.serviceRecord.id, token)
    if(result.status === 200) {
        $toast.open({
            message: `Successfully Duplicated Service Record: ${props.serviceRecord.title} (ID-${props.serviceRecord.id}).`,
            type: 'success',
            duration: 5000
        })
        refetchResults()
    } else {
            $toast.open({
                message: `Unable to Duplicate Service Record: ${props.serviceRecord.title} (ID-${props.serviceRecord.id}).`,
                type: 'error',
                duration: 20000
            })
        }
}

</script>
import axios from "axios";
import { BASE_URL } from "./api";

export async function getClients(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/clients/${payload.endpoint ? payload.endpoint : ""}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      return {
        clients: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        page_size: response.data.page_size,
        num_pages: response.data.num_pages,
      };
    })
    .catch((error) => {
      throw error;
    });
}

export async function createClient(formData, token) {
  return axios({
    method: "post",
    url: `${BASE_URL}/clients/create_client/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: formData,
  })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function editClient(clientId, formData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/clients/${clientId}/edit_client/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: formData,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function deleteClient(payload) {
  return axios({
    method: "delete",
    url: `${BASE_URL}/clients/${payload.id}/delete_client/`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status == 403) {
        return error.response;
      }
      throw error;
    });
}

export async function createBucket(payload) {
  return axios({
    method: "put",
    url: `${BASE_URL}/clients/${payload.id}/create_bucket/`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function addInvoiceReminderConfiguration(payload) {
  return axios({
    method: "post",
    url: `${BASE_URL}/clients/${payload.id}/add_invoice_reminder_configuration/`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
    data: payload.data
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getAllClientsSimple(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/clients/get_all_clients_simple`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getClientsWithBuckets(payload) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/clients/get_clients_with_buckets`,
        headers: {
            'Authorization': `Token ${payload.token}`
        },
    }).then((response) => {
        return {
            "count": response.data.count,
            "clients": response.data.clients,
        };
    }).catch((error) => {
        throw error
    });
}

export async function getAllClientInvoices(token, clientId) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/clients/${clientId}/get_all_client_invoices`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response;
    }).catch((error) => {
        throw error
    });
}
<template>
    <base-dblclick-input
        modelType="company"
        :modelId="company.id"
        fieldName="website_url"
        :fieldValue="company.website_url"
        inputType="url"
        @refetch="fetchNewCompanies"
    >
    <base-pill>URL: </base-pill>
    </base-dblclick-input>
</template>

<script>
    export default {
        name: "website-url-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits } from "vue";

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies () {
        emit('refetch-companies')
    }
</script>
<template>
    <div class="min-w-[580px]">
        <div v-if="finishedAction"
            class="w-full"
        >
            <div v-if="responseStatus === 200"
                class="w-full"
            >
                <div
                    class="flex flex-col justify-center items-center p-3"
                >
                    <div v-if="bulkAction.endpoint=='bulk_generate_invoice_template_from_service_records'">
                        Successfully generated invoice template. Check recent downloads to view .xlsx file.
                        <!-- <div>
                            Invoice Summary:
                        </div>
                        <div class="py-2">
                            <table
                                class="text-left table-auto w-full rounded-md border-separate border-spacing-0 bg-white"
                            >
                                <thead>
                                    <th
                                        class="border-t border-b border-r border-gray-300 h-full text-xs sticky -top-[1px] first-of-type:border-t-0 first-of-type:border-r-0 last-of-type:border-t-0 last-of-type:border-r-0 "
                                        v-for="(tableHeader, index) in tableHeaders"
                                        :key="tableHeader.value"
                                        :header="tableHeader.value"
                                        :class="tableHeader.class"
                                    >
                                        <div v-if="index==0" class="bg-gray-100 font-semibold w-full h-full p-2  border-l border-t border-r border-gray-300 rounded-tl-md">
                                            {{ tableHeader.label }}
                                        </div>
                                        <div v-else-if="index==1" class="font-semibold  bg-gray-100 w-full h-full p-2 border-r border-t border-gray-300 rounded-tr-md">
                                            {{ tableHeader.label }}
                                        </div>
                                        <div v-else class="font-semibold  p-2 bg-gray-100">
                                            {{ tableHeader.label }}
                                        </div>
                                    </th>
                                </thead>
                                <tbody>
                                    <TableRow>
                                        <td
                                            class="p-2 rounded-bl-md border-b border-r border-l border-gray-300 text-xs align-top"
                                        >
                                            {{ response.data.total_hours.hours__sum }}
                                        </td>
                                        <td
                                            class="p-2 rounded-br-md border-b border-r border-gray-300 text-xs align-top"
                                        >
                                            ${{ response.data.total_amount_due.amount_due__sum }}
                                        </td>
                                    </TableRow>
                                </tbody>
                            </table>
                        </div>
                        <div class="flex justify-between items-end w-full">
                            <div v-if="csvLoading" role="status" class="flex justify-start items-end">
                                <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="white"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="#2E9CCA"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div v-else>
                                <span class="text-accent-primary hover:text-dark-primary hover:cursor-pointer pl-1" @click="downloadInvoiceTemplateCSV">Click Here</span> to download CSV of all invoice items.
                            </div>
                            <div class="flex justify-end items-end cursor-pointer p-0.5 hover:bg-gray-300 rounded-md">
                                <CopySVG
                                    class=""
                                />
                            </div>
                        </div> -->

                    </div>
                    <div v-else>
                        This action has been performed on {{ numSelectedResults}} service records.
                    </div>
                </div>
                <div class="flex justify-center items-center py-1.5 border-t border-gray-300 bg-gray-50 rounded-b-md">
                    <button
                        class="p-0.5 bg-dark-primary border border-dark-primary text-white rounded-md mx-1"
                        @click="closeBulkOperationClearSelected"
                    >
                        Close
                    </button>
                </div>
            </div>
            <div v-else>
                <p>{{ title }}: Failed - Bad Request.</p>
                <br>
                <p>An error occurred that prevented the completion of this action.</p>
            </div>
        </div>
        <div v-else>
            <div v-if="isLoading" role="status" class="flex justify-center items-center w-full h-[100px]">
                <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
                <!-- <div>
                    Are you sure you would like to perform this operation on {{ numSelectedResults}} service records?
                </div> -->
                <div class="flex justify-center items-center px-3 pt-6">
                    {{ bulkAction.message }}
                </div>
                <div v-if="bulkAction.endpoint=='bulk_add_service_records_to_invoice'"
                    class="grid grid-cols-2 px-8 pb-8 pt-3"
                >
                    <select
                            name="client"
                            id="client"
                            class="bg-gray-50 p-1 border block border-gray-300 rounded-l-md text-gray-800"
                            v-model="clientId"
                        >
                            <option value="" disabled selected>Select Client</option>
                            <option v-for="client in bulkAction.filters.client.options" :key="client.value" :value="client.value">{{ client.label }}</option>
                        </select>
                        <select
                            v-if="clientId"
                            name="invoice"
                            id="invoice"
                            class="bg-gray-50 p-1 border-r border-b border-t block border-gray-300 rounded-r-md text-gray-800"
                            v-model="invoiceId"
                        >
                            <option value="" disabled selected>Select Invoice</option>
                            <option v-for="invoice in bulkAction.filters.invoice.options" :key="invoice.value" :value="invoice.value">{{ invoice.label }}</option>
                        </select>
                        <div v-else
                            class="w-full border-r border-b border-t rounded-r-md text-gray-600 bg-gray-200 border-gray-300 p-1.5"
                        >
                            Select Client To Filter Invoices
                        </div>
                </div>
                <div 
                    v-else
                    class="flex justify-center items-center px-8 pb-8 pt-3 w-full"
                >
                </div>
                <div class="grid grid-cols-8 items-center py-1.5 border-t border-gray-300 bg-gray-50 rounded-b-md">
                    <div class="col-span-3"></div>
                    <button
                    v-if="bulkAction.endpoint=='bulk_generate_invoice_template_from_service_records'"
                        @click="generateInvoiceTemplate()"
                        class="p-0.5 bg-accent-primary border border-accent-primary text-white rounded-md mx-1"
                    >
                        Continue
                    </button>
                    <button
                    v-else
                        @click="performBulkOperation(bulkAction.endpoint)"
                        class="p-0.5 bg-accent-primary border border-accent-primary text-white rounded-md mx-1"
                    >
                        Continue
                    </button>
                    <button
                        @click="cancelBulkOperation"
                        class="p-0.5 bg-dark-primary border border-dark-primary text-white rounded-md mx-1"
                    >
                        Cancel
                    </button>
                    <div class="col-span-3"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { performBulkAction } from '@/services/ServiceRecord.js';
import { getInvoiceTemplateFromServiceRecords } from '@/services/ServiceRecord.js';
import { exportToCSV } from '@/services/ServiceRecord.js';
import TableRow from '../base-components/TableRow.vue';
import CopySVG from '@/components/svg-components/CopySVG.vue'


const store = useStore()

const props = defineProps([
    'bulkAction',
    'selectedResults'
])

const emit = defineEmits([
    'set-bulk-operation-options',
    'cancel-bulk-operation',
    'close-bulk-operation',
    'refetch-results',
    'clear-selected-results',
])

const isLoading = ref(false);
const csvLoading = ref(false);
const finishedAction = ref(false);
const response = ref(null);
const responseStatus = ref(null);
const bulkAction=reactive(props.bulkAction)
const clientId = ref(null);
const engagementId = ref(null);
const invoiceId = ref(null);
const tableHeaders = ref([
    {value: 'total_hours', label: 'Total Hours', class: 'p-0 h-6', orderFieldName: ""},
    {value: 'total_amount_due', label: 'Total Amount Due', class: 'p-0 whitespace-nowrap', orderFieldName: ""},
])


const title = computed(() => {
    if(props.bulkAction) {
        if(props.bulkAction.label) {
            return props.bulkAction.label
        }
    }
    return ''
});

const numSelectedResults = computed(() => {
    if(props.selectedResults){
        if(props.selectedResults.length > 0) {
            return props.selectedResults.length
        }
    }
    return 0
})

watch(clientId, () => {
    console.log(clientId.value)
    setBulkOperationOptions('engagement', clientId.value)
    setBulkOperationOptions('invoice', clientId.value)
})

function cancelBulkOperation() {
    console.log('cancelBulkOperation')
    emit('close-bulk-operation')
    emit('cancel-bulk-operation')
}

function closeBulkOperationClearSelected() {
    console.log('closeBulkOperationClearSelected')
    emit('close-bulk-operation')
    emit('cancel-bulk-operation')
    emit('clear-selected-results')
}

function setBulkOperationOptions(optionName, queryParams) {
    emit('set-bulk-operation-options', optionName, queryParams)
}

async function performBulkOperation() {
    // need to create and test this function, also manage response for gen invoice template.
    console.log('performBulkOperation')
    isLoading.value = true
    const token = store.state.auth.token
    const data = {
        'service_record_ids': props.selectedResults.map(({id}) => id),
        'engagement_id': engagementId.value,
        'invoice_id': invoiceId.value,
    }
    console.log('data')
    console.log(data)
    response.value = await performBulkAction(bulkAction.endpoint, data, token)
    console.log('bulk action response')
    console.log(response.value)
    console.log(response.value.data)
    isLoading.value = false;
    finishedAction.value = true;
    responseStatus.value = response.value.status
    if(responseStatus.value == 200) {
        
        emit('refetch-results')
    }
}

async function generateInvoiceTemplate() {
    console.log('generateInvoiceTemplate')
    isLoading.value = true
    const token = store.state.auth.token
    response.value = await getInvoiceTemplateFromServiceRecords(token)
    console.log(response.value)
    console.log('STATUS')
    responseStatus.value = response.value.response.status
    console.log(responseStatus.value)
    isLoading.value = false;
    finishedAction.value = true;
    if(responseStatus.value == 200) {
        emit('refetch-results')
    }
}

async function downloadInvoiceTemplateCSV() {
    csvLoading.value = true
    const token = store.state.auth.token
    const data = {
        'service_record_ids': props.selectedResults.map(({id}) => id),
        'engagement_id': engagementId.value,
    }
    await exportToCSV('bulk_generate_invoice_template_csv_from_service_records', data, token)
    csvLoading.value = false;
}

setBulkOperationOptions('client')
</script>
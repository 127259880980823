<template>
        <TableRow
        :isSelected="isSelected" :class="{'cursor-wait': loadingCompany}"
    >
        <TableCell>
            <div @click="clearSelectedCompany" class="w-full h-full flex justify-end">
                <div class="grid grid-cols-2 h-full">
                    <div class="flex items-center h-full pl-1">
                        <div class="flex items-center space-x-1"><label for="approveOrDeny">✘:</label> <input type="radio" v-model="approvedCompany" value="deny" :name="'approveOrDeny' + email.id"></div>
                    </div>
                    <div class="p-1 grid grid-cols-1 gap-2">
                        <div  v-for="company in email.suggested_companies" :key="company.id + email.id" class="flex items-center space-x-1"><label for="approveOrDeny">&#x2714;:</label> <input type="radio" :checked="isSelected" v-model="approvedCompany" :value="company.id" :name="'approveOrDeny' + email.id"></div>
                    </div>
                </div>
            </div>
        </TableCell>
        <TableCell>
        <div class="p-1 grid grid-cols-1" v-for="company in email.suggested_companies" :key="company.id">
            <div class="flex space-x-1 items-center justify-between">
                <div>
                <span @click="getCompanyandOpenForm(company.id)" class="whitespace-nowrap hover:underline hover:cursor-pointer">{{ company.company_name }}</span> (<a target="_blank" class="text-accent-primary underline" :href="company.website_url">{{ company.website_url }}</a>), [{{ company.campaign ? company.campaign.campaign_name : "No Campaign" }}]
                </div>
            </div>
        </div>
        <base-modal :is-form-visible="isCompanyFormVisible" @close="closeCompanyForm">
            <template #header>
                <h2 class="text-lg">{{ detailCompany.company_name }} {{ detailCompany.id }}</h2>
            </template>
            <template #body>
                <EditCompany
                :company="detailCompany"
                :campaigns="campaigns"
                @close="closeCompanyForm"
                :default-tab="null"
                />
            </template>
        </base-modal> 
        </TableCell>
        <TableCell>
            <div>
                {{ email.email_subject }}
            </div>
        </TableCell>
        <TableCell>
            <div ref="emailBodyBoundary" @mouseover="showEmailBodyPopover" @mouseleave="hideEmailBodyPopover" class="flex justify-center text-xl">
                ✉
                <base-popover-hover-small
                    v-if="visibleEmailBodyPopover"
                    :xPosition="xPosition"
                    :yPosition="yPosition"
                    class="w-full mr-24"
                >
                    <template v-slot:title>
                        Email Body:
                    </template>
                    <template v-slot:body>
                        <div @mouseover="showEmailBodyPopover" class="max-h-72 overflow-auto">
                            <div ref="emailBodyPopover" id="emailBodyPopover" class="xl:max-w-[100%-104px] max-w-[1200px]">
                                <p v-if="email.email_body" v-html="emailBody"/>
                                <p v-if="!email.email_body">
                                    (No email body)
                                </p>
                            </div>                        
                    </div>
                    </template>
                </base-popover-hover-small>
            </div>
        </TableCell>
        <TableCell>
            <div>
                {{ emailFrom }}
            </div>
        </TableCell>
      <TableCell>
        <div class="">
          {{ emailTo }}
        </div>
      </TableCell>
      
    </TableRow>
  </template>
  
<script setup>
import { defineProps, ref, defineEmits, computed, reactive, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import BaseButton from '../BaseButton.vue'
import TableRow from '@/components/base-components/TableRow.vue';
import TableCell from '@/components/base-components/TableCell.vue';
import { getCompanyByID } from '@/services/Company'
import EditCompany from "../EditCompany.vue";

const props = defineProps({
    expanded: Boolean,
    email: Object,
    tableHeaders: Array,
    isLoading: Boolean,
    campaigns: Array,
    selectAll: Boolean
});

const store = useStore()
const detailCompany = ref(null)
const isCompanyFormVisible = ref(false)
const loadingCompany = ref(false)
const emailBodyBoundary = ref(null)
const visibleEmailBodyPopover = ref(false)
const shouldHideEmailBodyPopover = ref(false)
const emailBodyPopoverOverride = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const isSelected = ref(props.selectAll)
const visibleSelectBox = ref(false)

const approvedCompany = ref(null)

const suggestedCompany = reactive({
    company: null,
    approve: null
})

const approvedAndDeniedCompanies = ref([])

watch(() => approvedCompany.value, () => {
    emit('remove-suggested-company', {id: props.email.id})
    emit('add-suggested-company', {company: approvedCompany.value, id: props.email.id})
})

const isExpanded = ref(props.expanded)
const emit = defineEmits(['toggle-expand', 'modify-email', 'delete-email', 'refetch-results', 'add-suggested-company', 'remove-suggested-company'])

function setSuggestedCompany(companyId, approveOrDeny){
    suggestedCompany.company = companyId
    suggestedCompany.approve = approveOrDeny
}


const emailFrom = computed(() => {
    if(props.email.email_from){
        return props.email.email_from
    } else {
        return '--'
    }
})

const emailTo = computed(() => {
    if(props.email.email_to.length > 0){
        return props.email.email_to.join(', ')
    } else {
        return '--'
    }
})

function toggleExpand() {
    isExpanded.value = !isExpanded.value
}

function showSelectBox() {
    visibleSelectBox.value = true
}

function hideSelectBox() {
    if(!props.selectAll & !isSelected.value) {
        visibleSelectBox.value = false
    }
}

function clearSelectedCompany() {
    if(approvedCompany.value){
        isSelected.value = null
        emit('remove-suggested-company', {id: props.email.id})
        approvedCompany.value = null 
    }
}

watch(() => props.selectAll, () => {
    if(props.selectAll && props.email.suggested_companies.length === 1) {
        isSelected.value = props.selectAll
        emit('remove-suggested-company', {id: props.email.id})
        emit('add-suggested-company', {company: approvedCompany.value, id: props.email.id})
    } else {
        isSelected.value = null
        emit('remove-suggested-company', {id: props.email.id})
        approvedCompany.value = null
    }
})

function showEmailBodyPopover() {
    shouldHideEmailBodyPopover.value = false
    if(!emailBodyPopoverOverride.value) {
        visibleEmailBodyPopover.value = true;
        xPosition.value = emailBodyBoundary.value.getBoundingClientRect().left + 50
        yPosition.value = emailBodyBoundary.value.getBoundingClientRect().bottom
        nextTick(() => {
            emailBodyBoundary.value.focus()
        })
    }

}
function hideEmailBodyPopover() {
    shouldHideEmailBodyPopover.value = true
    setTimeout(() => {
        if(shouldHideEmailBodyPopover.value){
            visibleEmailBodyPopover.value = false;
            xPosition.value = 0;
            yPosition.value = 0;
        }
    }, 500)
}

const emailBody = computed(() => {
    if (props.email.email_body) {
    return props.email.email_body.replace(/(\r\n|\r|\n)/g, '<br>')
    } else {
    return null
    }
})

const emailBodyPopover = ref(null)

async function getCompanyandOpenForm(companyId) {
    loadingCompany.value = true
    const token = store.state.auth.token
    const result = await getCompanyByID({token: token, companyId: companyId})
    detailCompany.value = result.response.data
    console.log('detailCompany', detailCompany.value)
    isCompanyFormVisible.value = true
    loadingCompany.value = false
}

function closeCompanyForm(){
    isCompanyFormVisible.value = false
}
</script>
<template>
    <div>
        <CompanyPhoneCell :company="company" @refetch-companies="fetchNewCompanies" />
        <div class="border-t my-1 pt-1 border-gray-300 whitespace-nowrap tracking-wide font-semibold pr-1 text-gray-800">
            Primary Contact:
        </div>
        <PrimaryContactsCell :company="company" @refetch-companies="fetchNewCompanies" />
        <AllContactsCell :company="company" @refetch-companies="fetchNewCompanies"/>
    </div>
</template>

<script>
    export default {
        name: 'company-phone-primary-contact-all-contacts-cell'
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import CompanyPhoneCell from '../company-cell-components/CompanyPhoneCell.vue';
import PrimaryContactsCell from '../company-cell-components/PrimaryContactsCell.vue';
import AllContactsCell from '../company-cell-components/AllContactsCell.vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
<template>
    <div>
        <LocationCell :company="company" />
        <ZiLocationCell :company="company" />
    </div>
</template>

<script>
    export default {
        name: "zi-location-location-transfer-zi-cell"
    }
</script>

<script setup>
import ZiLocationCell from '../company-cell-components/ZiLocationCell.vue';
import LocationCell from '../company-cell-components/LocationCell.vue';
import { defineProps } from 'vue';

    const props = defineProps({
        company: Object
    })



</script>
<template>
    <div class="flex flex-col space-y-0.5">
        <CompanyNameCell :company="company" @refetch-companies="fetchNewCompanies" class="font-bold" />
        <DBACell :company="company" @refetch-companies="fetchNewCompanies" />
            <div class="flex space-x-0.5">
            <base-pill>Tier: </base-pill> <TierCell :company="company" class="inline"/>
        </div>
        <ParentCompanyCell :company="company" @refetch-companies="fetchNewCompanies" />
        <InvestorsCell :company="company" @refetch-companies="fetchNewCompanies" />
        <div>
            <base-pill><span>Summary: </span></base-pill> 
            <CRMSummaryCell :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
    </div>

</template>

<script>
    export default {
        name: 'company-name-dba-tier-pc-inv-crm-summary-cell'
    }
</script>

<script setup>
import CompanyNameCell from '../company-cell-components/CompanyNameCell.vue';
import DBACell from '../company-cell-components/DBACell.vue';
import TierCell from '../company-cell-components/TierCell.vue';
import ParentCompanyCell from '../company-cell-components/ParentCompanyCell.vue';
import InvestorsCell from '../company-cell-components/InvestorsCell.vue';
import CRMSummaryCell from '../company-cell-components/CRMSummaryCell.vue';
import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
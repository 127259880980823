export default {
    setPageConfigs(state, pageConfigs) {
        state.pageConfigs = pageConfigs;
    },
    setSelectedPageConfig(state, pageConfig) {
        state.selectedPageConfig = pageConfig;
    },
    setPageConfigLoadingTrue(state) {
        state.pageConfigLoading = true;
    },
    setPageConfigLoadingFalse(state) {
        state.pageConfigLoading = false;
    }

}
<template>
    <div class="h-full w-full">
        <div class="w-full py-3 border-b border-gray-400">
            Manipulate Collections:
        </div>
        <form @submit.prevent="submitForm" action="" class="w-full h-full">
            <div v-if="isLoadingFormResponse" class="flex justify-center items-center">
                <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
            <base-form v-else class="h-[60%] w-full">
                <template #body>
                    <div class="grid grid-cols-12 space-x-2 space-y-2 pr-4 border-gray-400 w-full">
                        <div class="col-span-2 flex items-start h-full pt-4">
                            <div class="pl-2 flex justify-start items-start">
                                
                            </div>
                        </div>
                        <div class="col-span-10 flex flex-col items-center space-x-2 w-full h-full">
                            <div class="w-full flex flex-row items-center space-x-2 justify-between">
                                <BaseListBoxSmallDropdown
                                    class="rounded-bl-md w-full h-8 border-gray-400"
                                    placeholder="Choose Collection(s)"
                                    :options="collectionOptions"
                                    :modelValue="selectedCollectionsOne"
                                    :multiple="true"
                                    @update:modelValue="setSelectedCollectionsOne"
                                    required
                                />
                                <div class="w-fit whitespace-nowrap flex items-center space-x-1">
                                    <input type="checkbox" id="checkbox" :checked="deleteOnCompleteOne" @change="toggleDeleteOnCompleteOne"/>
                                    <div>
                                        Delete on Completion
                                    </div>
                                </div>
                            </div>
                            <div class="w-full flex justify-start py-1.5">
                                <!-- <div v-if="isLoadingOne">
                                    <svg aria-hidden="true" class="mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="white"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="#2E9CCA"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else> -->
                                <div>
                                    {{ numCompaniesCollectionGroupOne }} Companies in {{ selectedCollectionsOne.length }} Collection(s).
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 flex items-start justify-end">
                            <div class="flex justify-end items-start">
                                <select class="border border-gray-400 rounded-md h-8" required v-model="collectionAction">
                                    <option value="none">--</option>
                                    <option value="and">And</option>
                                    <option value="exclude">Exclude</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="collectionAction !='none'" class="col-span-10 flex flex-col items-center space-x-2 w-full">
                            <div class="w-full flex flex-row items-center space-x-2 justify-between">
                                <BaseListBoxSmallDropdown
                                    class="rounded-bl-md w-full h-8"
                                    placeholder="Choose Collection(s)"
                                    :options="collectionOptions"
                                    :modelValue="selectedCollectionsTwo"
                                    :multiple="true"
                                    @update:modelValue="setSelectedCollectionsTwo"
                                />
                                <div class="w-fit whitespace-nowrap flex items-center space-x-1">
                                    <input type="checkbox" id="checkbox" :checked="deleteOnCompleteTwo" @change="toggleDeleteOnCompleteTwo"/>
                                    <div>
                                        Delete on Completion
                                    </div>
                                </div>
                            </div>
                            <div class="w-full flex justify-start py-1.5">
                                <!-- <div v-if="isLoadingTwo">
                                    <svg aria-hidden="true" class="mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="white"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="#2E9CCA"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else> -->
                                <div>
                                    {{ numCompaniesCollectionGroupTwo }} Companies in {{ selectedCollectionsTwo.length }} Collection(s).    
                                </div>
                            </div>
                        </div>
                        <div v-else class='col-span-10 w-full h-full'> </div>
                        <div class="col-span-2 flex w-full justify-end items-center pr-2">
                            =
                        </div>
                        <div class="col-span-10 flex w-full">
                            <input type="text" class="border border-gray-400 rounded-md w-full pl-2 py-1.5" required placeholder=' New / Existing Collection Name' v-model="collectionName">
                        </div>
                    </div>
                </template>
                <template #footer>
                    <div class="grid grid-cols-12 space-x-2 space-y-2 pr-4 border-by border-gray-400 w-full">
                        <div class="col-span-2"></div>
                        <div class="col-span-10 w-full mt-4 flex justify-between items-center">
                            <div v-if="isLoadingTotal">
                                <svg aria-hidden="true" class="mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="white"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="#2E9CCA"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div v-else>
                                Companies to Assign: {{ numCompaniesTotal }}, Collections to Delete: {{ collectionsToDelete }}
                            </div>
                            <base-button-affirmative v-if="!isLoadingOne && !isLoadingTwo && !isLoadingTotal" type="submit">Submit</base-button-affirmative>
                            <base-button-disabled v-else>Submit</base-button-disabled>
                        </div>
                    </div>
                </template>
            </base-form>
        </form>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import BaseListBoxSmallDropdown from '@/components/BaseListBoxSmallDropdown.vue';
import { getCompanies } from '@/services/Company';
import { bulkAssignQueryResultsToCollectionTagAndDeletePreviousCollections } from '@/services/Company';
import { useToast } from 'vue-toast-notification';
import store from '@/store';

const props = defineProps(['collections'])

const emit = defineEmits(['refetch-collection-tags'])

const selectedCollectionsOne = ref([])
const selectedCollectionsTwo = ref([])

const collectionAction = ref('none')

const $toast = useToast()

// const collectionExpressionOne = ref('OR')
// const collectionExpressionTwo = ref('OR')


function toggleDeleteOnCompleteOne() {
    deleteOnCompleteOne.value = !deleteOnCompleteOne.value
}

function toggleDeleteOnCompleteTwo() {
    deleteOnCompleteTwo.value = !deleteOnCompleteTwo.value
}

const collectionName = ref('')

const companiesInCollectionGroupOne = ref([])
const numCompaniesCollectionGroupOne = ref(0)
const deleteOnCompleteOne = ref(false)
const isLoadingOne = ref(false)
const companiesInCollectionGroupTwo = ref([])
const numCompaniesCollectionGroupTwo = ref(0)
const deleteOnCompleteTwo = ref(false)
const isLoadingTwo = ref(false)
const companiesTotal = ref([])
const numCompaniesTotal = ref(0)
const isLoadingTotal = ref(false)
const isLoadingFormResponse = ref(false)


watch(collectionAction, ()=> {
    if(collectionAction.value == 'none') {
        selectedCollectionsTwo.value = []
    }
    loadCompaniesGroupOne()
    loadCompaniesGroupTwo()
    loadCompaniesTotal()
})  

watch(selectedCollectionsOne, ()=> {
    if(selectedCollectionsOne.value.length > 0){
        loadCompaniesGroupOne()
        loadCompaniesGroupTwo()
    } else {
        companiesInCollectionGroupOne.value = []
        numCompaniesCollectionGroupOne.value = 0
    }
    loadCompaniesTotal()
})

watch(selectedCollectionsTwo, ()=> {
    if(selectedCollectionsTwo.value.length > 0){
        loadCompaniesGroupOne()
        loadCompaniesGroupTwo()
    } else {
        companiesInCollectionGroupTwo.value = []
        numCompaniesCollectionGroupTwo.value = 0
    }
    loadCompaniesTotal()
})

watch(props.collections, () => {
    clearFields()
}, {deep:true})


const collectionOptions = computed(() => {
    if(props.collections){
        if(props.collections.length > 0) {
            return [{value: "clear", label: "------------"}, ...props.collections.map((collection) => {
            return {value:collection.id, label:collection.collection_tag_name}
        })] 
        } else {
            return [{value: "clear", label: "------------"}]
        } 
    } else {
        return [{value: "clear", label: "------------"}]    
    }
})

const filterGroupOne = computed(() => {
    let query = new URLSearchParams({});
    if(selectedCollectionsOne.value) {
        selectedCollectionsOne.value.forEach(collectionTag => {
            query.append("collection_tag", collectionTag)
        })
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    // console.log(url)
    return url
})

const filterGroupTwo = computed(() => {
    let query = new URLSearchParams({});
    if(selectedCollectionsTwo.value) {
        if(collectionAction.value == 'and') {
            selectedCollectionsTwo.value.forEach(collectionTag => {
                query.append("collection_tag_and", collectionTag)
            })
        } else if (collectionAction.value == 'exclude') {
            selectedCollectionsTwo.value.forEach(collectionTag => {
                query.append("collection_tag_exclude", collectionTag)
            })
        } else {
            // selectedCollectionsTwo.value.forEach(collectionTag => {
            //     query.append("collection_tag", collectionTag)
            // })
        }
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    return url
})

const filterGroupTwoCount = computed(() => {
    let query = new URLSearchParams({});
    if(selectedCollectionsTwo.value){
        selectedCollectionsTwo.value.forEach(collectionTag => {
            query.append("collection_tag", collectionTag)
        })
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    return url
})


const filterGroupTotal = computed(() => {
    if(filterGroupOne.value !== '?' && filterGroupTwo.value !== '?') {
        return `${filterGroupOne.value}&${filterGroupTwo.value.substring(1)}`
    } else if (filterGroupOne.value !== '?') {
        return filterGroupOne.value
    } else if (filterGroupTwo.value !== '?') {
        return filterGroupTwo.value
    } else {
        return "?"
    }
})

const collectionsToDelete = computed(() => {
    let collections = []
    if(deleteOnCompleteOne.value) {
        for(let collection of selectedCollectionsOne.value) {
            if(!collections.includes(collection)){
                collections.push(collection)
            }
        }
    }
    if(deleteOnCompleteTwo.value) {
        for(let collection of selectedCollectionsTwo.value) {
            if(!collections.includes(collection)){
                collections.push(collection)
            }
        }
    }
    return collections
})


function setSelectedCollectionsOne(target) {
    if(target.includes("clear")){
        selectedCollectionsOne.value = []
    } else {
        // collectionExpressionOne
        selectedCollectionsOne.value = target;
    }
}

function setSelectedCollectionsTwo(target) {
    if(target.includes("clear")){
        selectedCollectionsTwo.value = []
    } else {
        // collectionExpressionTwo
        selectedCollectionsTwo.value = target;
    }
}

function clearFields() {
    collectionName.value = ''
    selectedCollectionsOne.value = []
    companiesInCollectionGroupOne.value = []
    numCompaniesCollectionGroupOne.value = 0
    deleteOnCompleteOne.value = false
    selectedCollectionsTwo.value = []
    companiesInCollectionGroupTwo.value = []
    numCompaniesCollectionGroupTwo.value = 0
    deleteOnCompleteTwo.value = false
    companiesTotal.value = []
    numCompaniesTotal.value = 0
}

// function setCollectionExpressionOne(target) {
//     collectionExpressionOne.value = target
//     if(selectedCollections.value.length > 0) {
        
//     }
// }

// function setCollectionExpressionTwo(target) {
//     collectionExpressionTwo.value = target
//     if(selectedCollections.value.length > 0) {
        
//     }
// }


async function loadCompaniesGroupOne(endpoint = filterGroupOne.value) {
    if(endpoint != '?'){
        isLoadingOne.value = true
        let payload = {
            token: store.state.auth.token,
            endpoint: endpoint
        }
        const result = await getCompanies(payload);
        companiesInCollectionGroupOne.value = result.companies
        numCompaniesCollectionGroupOne.value = result.count
        isLoadingOne.value = false
    }
}

async function loadCompaniesGroupTwo(endpoint = filterGroupTwo.value, countEndpoint = filterGroupTwoCount.value) {
    if(endpoint != '?'){
        if(collectionAction.value === 'and') {
            isLoadingTwo.value = true
            let payload = {
                token: store.state.auth.token,
                endpoint: endpoint
            }
            const result = await getCompanies(payload);
            companiesInCollectionGroupTwo.value = result.companies
            numCompaniesCollectionGroupTwo.value = result.count
            isLoadingTwo.value = false
        } else if (collectionAction.value === 'exclude') {
            isLoadingTwo.value = true
            let payload = {
                token: store.state.auth.token,
                endpoint: endpoint
            }
            let countPayload = {
                token: store.state.auth.token,
                endpoint: countEndpoint  
            }
            const result = await getCompanies(payload);
            companiesInCollectionGroupTwo.value = result.companies
            const countResult = await getCompanies(countPayload)
            numCompaniesCollectionGroupTwo.value = countResult.count
            isLoadingTwo.value = false
        }

    }
}

async function loadCompaniesTotal(endpoint = filterGroupTotal.value) {
    isLoadingTotal.value = true
    // console.log('total endpoint', endpoint)
    let payload = {
        token: store.state.auth.token,
        endpoint: endpoint
    }
    if(endpoint  != '?') {
        const result = await getCompanies(payload);
        companiesTotal.value = result.companies
        numCompaniesTotal.value = result.count
        // console.log('total count', result.count)
    } else {
        companiesTotal.value = []
        numCompaniesTotal.value = 0   
    }
    isLoadingTotal.value = false
}

async function submitForm() {
    if(companiesTotal.value.length > 0) {
        isLoadingFormResponse.value = true
        const token = store.state.auth.token
        const result = await bulkAssignQueryResultsToCollectionTagAndDeletePreviousCollections(collectionName.value, filterGroupTotal.value, collectionsToDelete.value, token)
        isLoadingFormResponse.value = false
        if(result.status == 200) {
            // console.log('submit result')
            // console.log(result)
            $toast.open({
                message: `${result.data.status}, ${result.data.delete_status} `,
                type: 'success',
                duration: 5000
            })
            clearFields()
            emit('refetch-collection-tags')
        } else {
            $toast.open({
                message: result.status,
                type: 'error',
                duration: 20000
            })
        }
    } else {
        alert("No companies to assign.")
    }


}


</script>

<style>
.collectionActions-enter-active,
.collectionActions-leave-active {
  transition: all 0.5s ease;
}
.collectionActions-enter-from,
.collectionActions-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
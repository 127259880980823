<template>
  <div class="print:hidden mx-1" v-if="showFilterPane">
    <transition-group
      tag="div"
      enter-active-class="duration-400 ease-out"
      enter-from-class="transform opacity-0 -translate-y-10"
      enter-to-class="opacity-100"
      leave-active-class="duration-400 ease-out absolute"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 -translate-y-10"
      move="transition-all duration-400 ease"
    >
      <div class="transition-all duration-300 ease-out" key="company-info">
        <div
          class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400"
        >
          <h3 class="text-sm">Referral Info:</h3>
          <base-button-affirmative @click="resetFilters" class="text-xs -py-2"
            >Reset</base-button-affirmative
          >
        </div>
        <div
          class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"
          id="company-info"
        >
          <base-filter>
            <template v-slot:label> Status </template>
            <template v-slot:widget>
              <base-list-box
                class="w-full rounded-b-md text-black"
                placeholder="Status"
                v-model="statusFilter"
                :options="statusOptions"
                @update:modelValue="setStatusFilter"
                :multiple="false"
              />
            </template>
          </base-filter>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import BaseListBox from "../BaseListBox.vue";
import { useStore } from "vuex";

const store = useStore();
const showFilterPane = computed(() => store.getters["referrals/showFilterPane"]);

const statusFilter = ref(['active']);
const emit = defineEmits([
  "change-referral-filters",
  "set-changed-referral-filters",
]);

const statusOptions = ref([
  {value: "clear", label: "------------"},
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
]);

const filters = computed(() => {
  return {
    statusFilter: statusFilter,
  };
});

function setStatusFilter(target) {
  if (target.includes("clear")) {
    statusFilter.value = [];
  } else {
    statusFilter.value = target;
  }
  emitChangeCompanyFiltersAndSetChanged();
}

function emitChangeCompanyFiltersAndSetChanged() {
  emit("change-referral-filters", filters);
  emit("set-changed-referral-filters");
}

function resetFilters() {
  statusFilter.value = "";
  emitChangeCompanyFiltersAndSetChanged();
}
</script>

<template>
    <tr class="w-full border-b border-b-gray-300 text-xs">
        <td class="p-2 align-text-top">
           {{ index + 1 }}.
        </td>
        <td v-if="tableHeaders.includes('ID')" class="p-2 align-text-top">
            {{ company.id }}
        </td>
        <td class="p-2 align-text-top">
            {{ company.company_name }}
        </td>
        <td v-if="tableHeaders.includes('Revenue')" class="p-2 align-text-top">
            {{ company.revenue ? company.revenue : '--' }}
        </td>
        <td v-if="tableHeaders.includes('Full-Time Equivalent')" class="p-2 align-text-top">
            {{ company.employee_headcount ? company.employee_headcount : '--' }}
        </td>
        <td v-if="tableHeaders.includes('Square Footage')" class="p-2 align-text-top">
            {{ company.square_footage ? company.square_footage : '--'}}
        </td>
        <td v-if="tableHeaders.includes('Location')" class="p-2 align-text-top">
           {{ company.city ? `${company.city},` : null }} {{ company.state }} {{ company.country }}
        </td>
        <td v-if="tableHeaders.includes('Account Manager Research Notes')" class="p-2 align-text-top">
            {{ company.account_manager_research_notes ? company.account_manager_research_notes : '--' }}
        </td>
        <td v-if="tableHeaders.includes('Primary Contacts')" class="p-2 align-text-top">
            <div v-if="primaryContacts.length > 0">
                <div v-for="contact in primaryContacts" :key="contact.id">
                    {{ contactFullName(contact) }}, {{ contactTitle(contact) }}
                </div>
            </div>
            <div v-else>--</div>
        </td>
        <td v-if="tableHeaders.includes('Client Feedback Notes')" class="p-2 align-text-top">
            <ClientFeedbackCell
                :company="company"
                @refetch-companies="refetchCompanies"
            />
        </td>
        <td v-if="tableHeaders.includes('Client Update Notes')" class="p-2 align-text-top">
            <ClientUpdateNotesCellVue
                :company="company"
                @refetch-companies="refetchCompanies"
            />
        </td>
        <td v-if="tableHeaders.includes('CRM Summary')" class="p-2 align-text-top">
            <CRMSummaryCell
                :company="company"
                @refetch-companies="refetchCompanies"
            />
        </td>
    </tr>
</template>

<script>
    export default {
        name: "campaign-crm-status-summary-row"
    }
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import ClientFeedbackCell from './company-cell-components/ClientFeedbackCell.vue';
import ClientUpdateNotesCellVue from './company-cell-components/ClientUpdateNotesCell.vue';
import CRMSummaryCell from './company-cell-components/CRMSummaryCell.vue';

const props = defineProps({
    company: Object,
    index: Number,
    tableHeaders: Array,
})

const emit = defineEmits(['refetch-companies'])

const primaryContacts = computed(() => {
    if(props.company.contacts){
            let filteredCompanies = []
            props.company.contacts.forEach((contact) => {
                for(let i = 0; i < contact.company_relationships.length; i++){
                    if(contact.company_relationships[i].company_id == props.company.id && contact.company_relationships[i].is_primary_contact){
                        filteredCompanies.push(contact)
                    }
                }
            })
            return filteredCompanies
        } else {
            return []
        }
})

const contactTitle = (contact) => {
    for(let companyRelationship of contact.company_relationships){
        if(companyRelationship.company_id == props.company.id) {
            return companyRelationship.title
        }
    }
    return '--'
}

const contactFullName = (contact) => {
    let fullName = ''
    if(contact.prefix){
        fullName += `${contact.prefix} `
    }
    if(contact.first_name){
        fullName += `${contact.first_name} `
    }
    if(contact.middle_name){
        fullName += `${contact.middle_name} `
    }
    if(contact.last_name){
        fullName += `${contact.last_name}`
    }
    if(contact.suffix){
        fullName += ` ${contact.suffix}`
    }
    if(fullName !== '') {
        return fullName
    } else {
        return '--'
    }
}

const refetchCompanies = () => {
    // console.log('refetchCompanies crm summary row')
    emit('refetch-companies')
}

</script>
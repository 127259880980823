<template>
  <div class="w-full flex flex-row">
    <div class="w-2 border-dark-secondary border-b"></div>
    <div v-for="tab in tabs" :key="tab.tabName" class="w-[15%] flex flex-row">
      <div @click="focusTab(tab)" :class="tab.style">
        {{ tab.tabName }}
      </div>
      <div class="w-1 border-dark-secondary border-b">
      </div>
    </div>
    <div :class="blankSpaceStyle" :style="{ 'width': `${parseInt(width)}%`, 'border-bottom': '1px solid dark-secondar'}">
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, defineProps, computed} from 'vue'
  const props = defineProps({
    // elements within tabList should be in the format {tabName: "", emitName: ""} 
    // tabList ORDER MATTERS
    tabList: Array,
    selectedColor: String, // i.e. red-100, gray-200
    notSelectedColor: String, // i.e. red-100, gray-200
    hoverColor: String // i.e. red-100, gray-200
  })

  const emit = defineEmits(['set-page'])
  const tabs = ref([]) // true is selected, false is not selected
  const selectedStyling = `px-2 w-full text-center text-sm py rounded-t-md border-dark-secondary border-t border-l border-r bg-${props.selectedColor} truncate`
  const notSelectedStyling = `px-2 w-full text-center text-sm py rounded-t-md border border-dark-secondary bg-${props.notSelectedColor} text-slate-500 hover:bg-${props.hoverColor} hover:text-black cursor-pointer truncate`

  // populate states
  // first tab is initially focused
  for(let i = 0; i < props.tabList.length; i++) {
    if (props.tabList[i].isSelected) {
      tabs.value.push({
        tabName: props.tabList[i].tabName,
        style: selectedStyling
      })
    } else {
      tabs.value.push({
        tabName: props.tabList[i].tabName,
        style: notSelectedStyling
      })
    }
  }
  // console.log(tabs.value)

  // spacing
  const width = computed(() => {
    console.log('tab blank width', 100-(15*props.tabList.length))
    return 100-(15*props.tabList.length)
  })


  const blankSpaceStyle = computed(() => {
    return `w-[${parseInt(width.value)}%] border-b border-dark-secondary`
  })

  // general tab control
  function focusTab(selectedTab) {
    // reset stylings
    tabs.value.forEach((tab) => {
      tab.style = notSelectedStyling
    })

    // Set selected tab to correct styling
    selectedTab.style = selectedStyling

    emit('set-page', selectedTab.tabName)
  }
</script>

<script>
export default {
  name: "base-tab-bar",
}
</script>
import axios from "axios";
import { BASE_URL } from "./api";

export async function getResearchProjects(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/research_projects/${payload.endpoint}`,
    headers: {
      Authorization: `Token ${payload.token}`,
    },
  })
    .then((response) => {
      console.log("Gets to response" + response);
      return {
        results: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        pageSize: response.data.page_size,
        numPages: response.data.num_pages,
      };
    })
    .catch((error) => console.error(error));
}

export async function createResearchProject(projectData, token) {
  return axios({
    method: "post",
    url: `${BASE_URL}/research_projects/create_research_project/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: projectData,
  })
    .then((response) => response.data)
    .catch((error) => alert(error));
}

export async function markResearchProjectStatus(changeData, token) {
  return axios({
    method: "put",
    url: `${BASE_URL}/research_projects/mark_research_project_status/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: changeData,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error);
    });
}

export async function editResearchProject(researchProjectID, formData, payload) {
  // console.log(formData)
  // console.log(payload)
  return axios({
      method: 'put',
      url: `${BASE_URL}/research_projects/${researchProjectID}/edit_research_project/`,
      headers: { 
        'Authorization': `Token ${payload.token}`, 
        'Content-Type': 'application/json'
      },
      data: formData
  }).then((response) => {
      return response
  }).catch((error) => {
      throw error
  })
}

<template>
    <div>
        <div v-if="routeName == 'campaign-crm-status-summary'" class="ml-5 -mt-3 text-dark-primary ">
            <input type="checkbox" id="checkbox" class="align-middle" :checked="showTableFilters" @change="toggleShowTableFilters"/>
            <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Table Filters</span> 
            <span v-else class="ml-1 mr-2">Table Filters</span>
        </div>
        <div v-else class="text-dark-primary flex flex-col">
            <p class="underline">
                Filter Options
            </p>
            <div class="flex flex-col items-start ml-1 mb-3">
                <div class="mt-1">
                    <input type="checkbox" id="checkbox" class="align-middle" :checked="showFilterPane" @change="toggleShowFilterPane"/>
                    <span class="ml-1 mr-2">Filter Pane</span>
                </div>

                <!-- TIMECARDS SPECIFIC CONFIGURATION -->
                <div class="mt-3" v-if="module === 'timecards'">
                    <p class="underline">
                        Actions Options
                    </p>
                    <div class="flex flex-col items-start ml-1 mb-3">
                        <div class="mt-1">
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="showActionsPane" @change="toggleShowActionsPane"/>
                            <span class="ml-1 mr-2">Actions Pane</span>
                        </div>
                        
                    </div>
                    <p class="underline">
                        Timecard User Options
                    </p>
                    <div class="flex flex-col items-start ml-1 mb-3">
                        <div class="mt-1">
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="showTimecardUsers" @change="toggleShowTimecardUsers"/>
                            <span class="ml-1 mr-2">Timecard Users</span>
                        </div>
                    </div>
                </div>

                <!-- CONTACTS SPECIFIC CONFIGURATION -->
                <div v-if="module === 'contact'">
                    <div class="ml-4 mt-1">
                        <div>
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="showBasicContactFilters" @change="toggleShowBasicContactFilters"/>
                            <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Basic Contact Filters</span> 
                            <span v-else class="ml-1 mr-2">Basic Contact Filters</span>
                        </div>
                    </div>
                    <div class="ml-4 mt-1">
                        <div>
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="showBasicCompanyFilters" @change="toggleShowBasicCompanyFilters"/>
                            <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Basic Company Filters</span> 
                            <span v-else class="ml-1 mr-2">Basic Company Filters</span>
                        </div>
                    </div>
                </div>

                <!-- CLIENT APPENDICES SPECIFIC CONFIGURATION -->
                <div v-if="routeName == 'client-appendices'" class="ml-4 mt-1">
                    <div>
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="showBasicFilters" @change="toggleShowBasicFilters"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Basic Filters</span> 
                        <span v-else class="ml-1 mr-2">Basic Filters</span>
                    </div>
                    <div>
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="showTableFilters" @change="toggleShowTableFilters"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Table Filters</span> 
                        <span v-else class="ml-1 mr-2">Table Filters</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, defineProps } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
    module: String, 
    routeName: String,
})

const showFilterPane = computed(() => store.getters[`${props.module}/showFilterPane`])
// const activeFilters = computed(() => store.getters['contact/showActiveFilters'].join(', '))


const toggleShowFilterPane = () => {
    if(showFilterPane.value) {
        store.dispatch(`${props.module}/setShowFilterPaneFalse`);
    } else {
        store.dispatch(`${props.module}/setShowFilterPaneTrue`);
    }
}

// SPECIFIC TO CONTACTS
const showBasicContactFilters = computed(() => store.getters['contact/showBasicContactFilters'])
const showBasicCompanyFilters = computed(() => store.getters['contact/showBasicCompanyFilters'])

const toggleShowBasicContactFilters = () => {
    if(showBasicContactFilters.value) {
        store.dispatch('contact/setShowBasicContactFiltersFalse')
    } else {
        store.dispatch('contact/setShowBasicContactFiltersTrue')
    }
}

const toggleShowBasicCompanyFilters = () => {
    if(showBasicCompanyFilters.value) {
        store.dispatch('contact/setShowBasicCompanyFiltersFalse')
    } else {
        store.dispatch('contact/setShowBasicCompanyFiltersTrue')
    }
}

// SPECIFIC TO TIMECARDS
const showActionsPane = computed(() => store.getters['timecards/showActionsPane'])
const showTimecardUsers = computed(() => store.getters['timecards/showTimecardUsers'])

const toggleShowActionsPane = () => {
    if(showActionsPane.value) {
        store.dispatch('timecards/setShowActionsPaneFalse')
    } else {
        store.dispatch('timecards/setShowActionsPaneTrue')
    }
}
const toggleShowTimecardUsers = () => {
    if(showTimecardUsers.value) {
        store.dispatch('timecards/setShowTimecardUsersFalse')
    } else {
        store.dispatch('timecards/setShowTimecardUsersTrue')
    }
}

// SPECIFIC TO CLIENT APPENDICES
const showBasicFilters = computed(() => store.getters['company/showBasicFilters'])
const showTableFilters = computed(() => store.getters['company/showTableFilters'])

const toggleShowBasicFilters = () => {
    if(showBasicFilters.value) {
        store.dispatch('company/setShowBasicFiltersFalse')
    } else {
        store.dispatch('company/setShowBasicFiltersTrue')
    }
}

const toggleShowTableFilters = () => {
    if(showTableFilters.value) {
        store.dispatch('company/setShowTableFiltersFalse')
    } else {
        store.dispatch('company/setShowTableFiltersTrue')
    }
}

// DLA QPL (Company) - done
// Clients (Client) - done
// CRM for PDF (Campaigns) - done
// Campaigns (Campaigns) - done
// Timecards (Timecards) - done
// Project Assignments (Research) - done
// Research Projects (research) - done
// PMA Approval (research) - done
// Job Queue (asyncjobs)
// All Tasks (research) - done
// Referrers (referrals) - done

// Contacts
</script>
<template>
    <div>
      <base-button @click="showModal">Sources</base-button>
      <InfoModal
          :is-modal-visible="isModalVisible"
          @close="closeModal"
      >
        <template #header>
          Company Sources
        </template>
        <template #body>
            <div class="p-2 flex justify-center">
                <ol class="list-decimal flex flex-col justify-start">
                    <li v-for="source in company.sources" :key="source.id">
                        {{ source.source_name }}
                    </li>
                </ol>
            </div>
        </template>
    </InfoModal>
    </div>
  
  </template>
  
  <script>
      export default {
          name: 'sources-modal-cell'
      }
  </script>
  
  <script setup>
  import {ref, defineProps, defineEmits} from "vue";
  import InfoModal from "../InfoModal.vue";
  
    const isModalVisible = ref(false)
  
    const props = defineProps({
        company: Object
    })
  
    const emit = defineEmits(['refetch-companies'])
  
    function showModal () {
      isModalVisible.value = true
    }
  
    function closeModal () {
      isModalVisible.value = false
    }
  </script>
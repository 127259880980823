<template>
    <div>
        <div v-if="nextTaskDue">
            <div>
                {{ nextTaskDue.action_name }} 
            </div>
            <div>
                <base-pill>Due Date: </base-pill> {{ getDate(nextTaskDue.due_date)  }}
            </div>
            <div>
                <base-pill>Notes: </base-pill> {{ nextTaskDue.action_notes }}
            </div>
            <div class="flex space-x-1 mt-1">
                <base-button @click="updateStatus('Done')" class="block whitespace-nowrap" >Mark Done</base-button>
                <base-button @click="updateStatus('Cancelled')" class="block">Cancel</base-button>
                <AddTaskModalCell :company="company" @refetch-companies="fetchNewCompanies" />
            </div>

        </div>
        <div class="flex-col" v-else>
            <div> --</div>
            <AddTaskModalCell :company="company" @refetch-companies="fetchNewCompanies" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "next-task-due-cell"
    }
</script>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import { markStatus } from '@/services/CrmAction';
import { useStore } from 'vuex';
import AddTaskModalCell from './AddTaskModalCell.vue';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    const nextTaskDue = computed(() => {
        let openTasks = props.company.crm_actions.filter(action => action.campaign.id == props.company.campaign.id && action.status == 'Open')
        if(openTasks.length == 1){
            return openTasks[0]
        } else if(openTasks.length == 0) {
            return null
        }
        let nextDue = openTasks.reduce((accumulator, currentValue) => {
            let accDate = new Date(accumulator.due_date).getTime()
            let currDate = new Date(currentValue.due_date).getTime()
            if(accDate < currDate){
                return accumulator
            } else if (accDate > currDate) {
                return currentValue
            } else if(accDate == currDate){
                accDate = new Date(accumulator.created_at).getTime()
                currDate = new Date(currentValue.created_at).getTime()
                if(accDate < currDate){
                    return accumulator
                } else {
                    return currentValue
                }
            }
        })
        return nextDue
    })

    function getDate(dueDate) {
        let date = new Date(dueDate)
        date.setDate(date.getDate() + 1)
        return date.toLocaleDateString()
    }

    async function updateStatus(status) {
        const token = store.state.auth.token
        const newStatus = JSON.stringify({
            status: status
        })
        await markStatus(nextTaskDue.value.id, newStatus, token)
        emit('refetch-companies')
    }

    function fetchNewCompanies(){
        emit('refetch-companies')
    }


</script>
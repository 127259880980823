<template>
    <div>
        <PrimaryContactsCell :company="company" @refetch-companies="fetchNewCompanies" />
        <AllContactsCell :company="company" @refetch-companies="fetchNewCompanies"/>
    </div>
</template>

<script>
    export default {
        name: 'primary-contact-all-contacts-cell'
    }
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import PrimaryContactsCell from '../company-cell-components/PrimaryContactsCell.vue';
import AllContactsCell from '../company-cell-components/AllContactsCell.vue';

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
import axios from 'axios';
import { BASE_URL } from './api';


export async function createCompanyContactResearchNote(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/contact_research_notes/create_company_contact_research_note/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function updateContactResearchNote(contactResearchNoteId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/contact_research_notes/${contactResearchNoteId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
import axios from 'axios';
import { BASE_URL } from './api';


export async function createResearchPost(formData, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/research_posts/create_research_post/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function updateResearchPost(researchPostId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/research_posts/${researchPostId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function deleteResearchPost(researchPostId, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/research_posts/${researchPostId}/delete_post/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error
    });
}

export async function getContactResearchPostsByCompany(companyId, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/research_posts/?post_type=contact_research&company=${companyId}`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

export async function getCollectionResearchPostsByCompany(companyId, token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/research_posts/?post_type=collection_research&company=${companyId}`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        throw error
    })
}

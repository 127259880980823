<template>
    <TablePage
        class="px-0 2xl:px-32 pb-2"
        :module-name="moduleName"
        :response="response"
        :isLoading="isLoading"
        :tableHeaders="tableHeaders"
        :filters="clientEmailFilter"
        :bulkOperations="clientEmailBulkOperations"
        :menuOptions="menuOptions"
        :defaultPageSize="15"
        :first-header-styling="firstHeaderStyling"
        :should-reload="shouldReload"
        @refetch-results="loadClientEmails"
        @clear-filter="clearFilter"
        @change-filters="setFilters"
        @set-filter-options="setFilterOptions"
        @do-quick-bulk="executeQuickBulkAction"
    >
        <!-- <template #bulk-operations="{ events: { hideBulkActionMenu, hideBulkOperations, clearSelectAllClearSelectedResults, refetchResults }, bulkAction, fetchFilter, selectedResults }">
            <EmailsReceivedBulkOperations
                :bulkAction="bulkAction"
                :selectedResults="selectedResults"
                @cancel-bulk-operation="hideBulkActionMenu"
                @close-bulk-operation="hideBulkOperations"
                @clear-selected-results="clearSelectAllClearSelectedResults"
                @refetch-results="refetchResults(fetchFilter)"
            />
        </template> -->
        <!-- <template #row-form="{ events: { closeFormRow, refetchResults }, index, fetchFilter, userPermissions }">
        </template> -->
        <template #table-row="{ events: { addSelectedResult, removeSelectedResult, refetchResults }, selectAll, fetchFilter, userPermissions }">
            <SuggestedCompaniesEmailsReceivedRow
                v-for="clientEmail in response.data.results"
                :key="clientEmail.id"
                :email="clientEmail"
                :selectAll="selectAll"
                :userPermissions="userPermissions"
                :is-loading="isLoading"
                :table-headers="tableHeaders"
                :campaigns="campaigns"
                @add-suggested-company="addSelectedResult"
                @remove-suggested-company="removeSelectedResult"
                @refetch-results="refetchResults(fetchFilter)"
            />
        </template>
    </TablePage>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { getClientEmails, bulkFileEmails } from "@/services/ClientEmail";
import { getClients } from "@/services/Client"
import TablePage from "@/components/base-components/TablePage";
import EmailsReceivedBulkOperations from './EmailsReceivedBulkOperations.vue';
import SuggestedCompaniesEmailsReceivedRow from './SuggestedCompaniesEmailsReceivedRow.vue';
import { getUsersByGroup } from '@/services/User'
import { getCampaigns } from '@/services/Campaign';
import { useToast } from 'vue-toast-notification';

const $toast = useToast()
const moduleName = ref('Client Emails Received')
const response = ref(null)
const store = useStore();
const isLoading = ref(false);
const campaigns = ref([])
const shouldReload = ref(0)

async function executeQuickBulkAction(bulkActionName, selectedResults) {
    // console.log('quick bulk', bulkActionName)
    // console.log(selectedResults)
    isLoading.value = true
    const token = store.state.auth.token
    const data = {
        emails: selectedResults
    }
    if(bulkActionName === 'quickFile'){
        const result = await bulkFileEmails(token, data)
        if(result.status == 200){
            $toast.open({
                message: result.data.message,
                type: 'success',
                duration: 5000
            })
            shouldReload.value++
        } else {
            $toast.open({
                message: "Error, could not file emails",
                type: "error",
                duration: 5000
            })
        }
    }
    isLoading.value = false
  }

const tableHeaders = ref([
    // {
    //     value: 'expand',
    //     label: 'Expand',
    //     class: 'p-0 w-5 lg:w-5  whitespace-nowrap'
    // },
    {
        value: 'suggested_companies',
        label: 'Suggested Companies (Website) [Campaign]',
        class: 'p-0 w-36 lg:w-36  whitespace-nowrap '
    },
    {
        value: 'email_subject',
        label: 'Subject',
        class: 'p-0 w-24 lg:w-24  whitespace-nowrap'
    },
    {
        value: 'body',
        label: 'Body',
        class: 'p-0 w-5 lg:w-5  whitespace-nowrap'
    },
    {
        value: 'from',
        label: 'From',
        class: 'p-0 w-16 lg:w-16  whitespace-nowrap'
    },
    {
        value: 'to',
        label: 'To',
        class: 'p-0 w-16 lg:w-16  whitespace-nowrap '
    }
])

const firstHeaderStyling = ref("w-5")
// const defaultOrdering = ref(['-date'])

async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
}

// const fetchFilter = computed(() => {
//     let query = new URLSearchParams({});
//     for(const filter in clientEmailFilter) {
//         // console.log('filter')
//         // console.log(filter)
//         if(clientEmailFilter[filter].multiSelect) {
//             if(clientEmailFilter[filter].value.length > 0) {
//                 clientEmailFilter[filter].value.forEach(filterValue => {
//                     query.append(clientEmailFilter[filter].name, filterValue)
//                 })
//             }
//         } else {
//             if(clientEmailFilter[filter].value) {
//                 query.append(clientEmailFilter[filter].name, clientEmailFilter[filter].value)
//             }
//         }
//     }
//     // if (pageSizeFilter.pageSizeFilter) {
//     //   query.append("page_size", pageSizeFilter.pageSizeFilter);
//     // }
//     // if(orderBy.value.length > 0){
//     //   query.append("order_by", orderBy.value.join(','))
//     // }
//     let url;
//     let queriesCount = 0;
//     for (let value of query.values()) {
//         queriesCount = queriesCount + 1;
//     }
//     if (queriesCount > 0) {
//         url = "?" + query.toString();
//     } else {
//         url = "?";
//     }
//     return url
// })


const clientEmailFilter = reactive({
    clientId: {
        name: "client_id",
        label: "Client",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setClientOptions.bind(null),
        awaitingOptions: false
    }
})

async function setClientOptions() {
    const token = store.state.auth.token
    const result = await getClients({token: token})
    clientEmailFilter.clientId.options = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
            return {value: client.id, label: client.client_name}
        }
    )]
}


const clientEmailBulkOperations = reactive({
    approveRequests: {
        label: 'Approve Company Campaign Change Requests',
        modelPath: 'company_campaign_change_requests',
        endpoint: 'bulk_approve_requests',
        message: 'Click continue to approve the selected change requests.'
    },
    rejectRequests: {
        label: 'Reject Company Campaign Change Requests',
        modelPath: 'company_campaign_change_requests',
        endpoint: 'bulk_reject_requests',
        message: 'Add a rejection reason for the selected change requests.',
    }
})

function setLoading(){
    isLoading.value = true
}

const menuOptions = reactive({
    selectAll: true,
    paginationArrows: true,
    totalResults: false,
    pageSize: true,
    refreshButton: true,
    filters: true,
    bulkOperations: false,
    addFormRows: false,
    quickBulkButtons: [
        {
            label: "Quick File",
            bulkAction: "quickFile" 
        }
    ]
})

function setFilters(updatedFilters) {
    clientEmailFilter.clientId = updatedFilters.clientId;
}

async function loadClientEmails(fetchFilter='') {
    // console.log('loading now')
    isLoading.value = true;
    const token = store.state.auth.token
    const endpoint = fetchFilter + '&has_suggested_companies=True'
    response.value = await getClientEmails({token: token, endpoint: endpoint});
    // console.log('response', response.value)
    isLoading.value = false;
}

async function setFilterOptions(filterName, queryParams='') {
    console.log('setFilterOptions', filterName)
    if(!clientEmailFilter[filterName].awaitingOptions){
        if(clientEmailFilter[filterName].setOptionsMethod){
            clientEmailFilter[filterName].awaitingOptions = true
            clientEmailFilter[filterName].setOptionsMethod(queryParams)
            setTimeout(() => {
                clientEmailFilter[filterName].awaitingOptions = false;
            }, 30000)
        }
    }
}

function clearFilter(filterName) {
    if(Array.isArray(clientEmailFilter[filterName].value)){
        clientEmailFilter[filterName].value = []
    } else {
        clientEmailFilter[filterName].value = null
    }
}

loadCampaigns()

</script>
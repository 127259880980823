import axios from "axios"
import { BASE_AUTH_URL, BASE_URL } from "./api"


export async function login(userName, passWord) {
    return axios({
        method: 'post',
        url: `${BASE_AUTH_URL}/auth/login/`,
        headers: { 
            'Content-Type': 'application/json'
        },
        auth: {
            username: userName,
            password: passWord
        }
    })
    .then((response) => {
        return response.data
    })
    .catch(error => console.log(error))
}

export async function verifyToken(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/verify_token`,
        headers: { 
          "Authorization": `Token ${token}`
        }
        })
        .then(response => {
            // console.log('verify token service response', response)
            return response
        })
        .catch((error) => {
            console.log('unauthorized')
            console.log(error)
            return false
        })
}

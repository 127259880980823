<template>
    <form @submit.prevent class="inline w-full">
        <input :type="inputType" class="border inline border-black rounded-sm px-1 w-full break-normal"
            v-on:keyup.enter="handleInputChange" v-on:focusout.prevent="handleInputChange" :value="oldInput"
            name="inputName">
    </form>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { updateBusinessType } from '@/services/BusinessType'
import { updateSector } from '@/services/Sector'
import { updateSubSector } from '@/services/SubSector'


const store = useStore()

const props = defineProps({
    row: Object,
    tableType: String,
    inputType: String
})
const emit = defineEmits(['toggle-editable', 'refetch-table'])

let targetField = null

const oldInput = ref((() => {
    if (props.tableType == 'businessTable') {
        targetField = "business_type_name"
        return props.row.business_type_name;
    } else if (props.tableType == 'sectorsTable') {
        targetField = "sector_name"
        return props.row.sector_name;
    } else if (props.tableType == 'subSectorsTable') {
        targetField = "sub_sector_name"
        return props.row.sub_sector_name;
    }
})())


const handleInputChange = async (e) => {
    let newValue = e.target.value

    let errorMessage = "Error: Missing "
    if (!newValue) {
        errorMessage += targetField
        alert(errorMessage)
        return
    }
    
    let updatedData = {}
    updatedData[targetField] = newValue
    const data = JSON.stringify(updatedData)
    // console.log(data)
    let token = store.state.auth.token

    if (props.tableType == 'businessTable') {
        await updateBusinessType(props.row.id, data, token)
    } else if (props.tableType == 'sectorsTable') {
        await updateSector(props.row.id, data, token)
    } else if (props.tableType == 'subSectorsTable') {
        await updateSubSector(props.row.id, data, token)
    }
    emit('refetch-table')
    emit('toggle-editable')
}
</script>
<template>
    <div class="fixed inset-0 flex items-center justify-center">

    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
  
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  <slot name="header"></slot>
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    <slot name="body"></slot>
                  </p>
                </div>
  
                <div class="mt-4">
                  <base-button-affirmative
                    type="button"
                    @click="closeModal"
                  >
                    Close
                  </base-button-affirmative>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>

<script>
export default {
    name: "headless-dialog-box"
}
</script>
  
  <script setup>
  import { ref, defineProps, watch, defineEmits } from 'vue'
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  } from '@headlessui/vue'
  

  const props = defineProps({
    isOpen: Boolean
  })

  const emit = defineEmits(['close-dialog-box'])

//   watch(props, ()=> {
//     isOpen.value = props.isOpen
//   })

  const isOpen = ref(true)
  
  function closeModal() {
    // isOpen.value = false
    emit('close-dialog-box')
  }
  function openModal() {
    isOpen.value = true
  }
  </script>
  
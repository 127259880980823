<template>
    <div class="text-xs text-center block bg-dark-primary text-white p-2 m-1 rounded-2xl hover:cursor-auto" @mouseenter="showX" @mouseleave="hideX">
        <slot class="hover:cursor-auto"></slot>
        <span v-if="xIsVisible" @click="remove" class="hover:text-accent-primary hover:cursor-pointer">
                X
        </span>
    </div>

</template>

<script>
    export default {
        name: 'base-removable-pill'
    }
</script>

<script setup>
import {  defineEmits, ref } from 'vue';

const emit = defineEmits(['remove'])

const xIsVisible = ref(false)

function showX () {
    xIsVisible.value = true
}

function hideX () {
    xIsVisible.value = false
}

function remove () {
    console.log('remove')
    emit('remove')
}

</script>
<template>
  <base-dblclick-input
    modelType="contact"
    :model-id="companyContact.id"
    field-name="suffix"
    :field-value="companyContact.suffix"
    inputType="text"
    @refetch="fetchNewCompanies"
  ></base-dblclick-input>
</template>

<script>
  export default {
      name: "company-contact-suffix-cell"
  }

</script>

<script setup>
import { defineProps, defineEmits } from "vue";

  const props = defineProps({
      companyContact: Object
  })

  const emit = defineEmits(['refetch-companies'])

  function fetchNewCompanies () {
      emit('refetch-companies')
  }
</script>

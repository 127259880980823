<template>
    <div v-if="index==startingIndex">
        <button
            class="w-full border p-1.5 border-gray-400 hover:bg-gray-200 rounded-l-md"
            @click="showBulkActionMenu"
        >
            {{ bulkOperation.label }}
        </button>
    </div>
    <div v-else-if="index==endingIndex">
        <button
            class="w-full border-t border-r border-b p-1.5 border-gray-400 hover:bg-gray-200 rounded-r-md"
            @click="showBulkActionMenu"
        >
            {{ bulkOperation.label }}
        </button>
    </div>
    <div v-else>
        <button
            class="w-full border p-1.5 border-t border-r border-b border-gray-400 hover:bg-gray-200"
            @click="showBulkActionMenu"
        >
            {{ bulkOperation.label }}
        </button>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';


const props = defineProps([
    'index',
    'startingIndex',
    'endingIndex',
    'bulkOperation'
])

const emit = defineEmits([
    'show-bulk-action-menu'
])


function showBulkActionMenu() {
    emit('show-bulk-action-menu', props.bulkOperation)
}
</script>
<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        style="isolation:isolate" 
        viewBox="0 0 16 16" 
        class="h-3 w-3"
    >
        <defs>
            <clipPath id="_clipPath_JIQrdHEFl2kXA7epnpdk4uqaj6PSiZF8">
                <rect width="16" height="16"/>
            </clipPath>
        </defs>
        <g clip-path="url(#_clipPath_JIQrdHEFl2kXA7epnpdk4uqaj6PSiZF8)">
            <path d=" M 3.126 7.344 L 15.098 7.344 C 15.532 7.344 15.884 7.696 15.884 8.13 L 15.884 8.13 C 15.884 8.564 15.532 8.916 15.098 8.916 L 3.109 8.916 L 5.869 11.504 L 7.07 12.629 Q 7.51 13.309 7.07 13.749 Q 6.629 14.189 5.925 13.725 L 3.48 11.504 L 0.351 8.57 Q 0.167 8.417 0.157 8.147 Q 0.148 7.878 0.383 7.675 L 3.48 4.772 L 5.974 2.559 Q 6.628 2.083 7.084 2.539 Q 7.54 2.995 7.07 3.647 L 5.869 4.772 L 3.126 7.344 Z " fill-rule="evenodd" fill="rgb(0,0,0)"/>
        </g>
    </svg>
</template>
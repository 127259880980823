<template>
    <button class="
        cursor-not-allowed 
        focus:outline-none 
        disabled:opacity-75 
        border 
        border-gray-400 
        text-gray-400
        rounded-md
        px-1
        py-1
        max-w-32
        my-1
        border-solid
        text-xs
        cursor-pointer
        sm:h-fit
      ">
        <slot></slot>
    </button>
  
  </template>
  
  <script>
  export default {
    name: "BaseButton.vue"
  }
  </script>
  <style scoped>
  </style>
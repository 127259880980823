<template>
    <div v-if="company.bing_places_results.length > 0">
        <div class="flex justify-between whitespace-nowrap" v-for="bingResult in company.bing_places_results" :key="bingResult.id">
            <div>
                <a class=" text-accent-primary hover:text-dark-primary hover:underline hover:cursor-pointer" target="_blank" :href="bingResult.url">{{ bingResult.url }}</a> ({{ bingResult.accuracy_confidence }})
            </div>
            <div v-if="isMatch(bingResult.url)">
               <span class="bg-yellow-300">Match</span> 
            </div>
        </div>
    </div>
    <div v-else>
        --
    </div>
</template>

<script>
export default {
    name: "bing-places-results-cell"
}
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    company: Object 
})

function isMatch(bingUrl) {
    let companyUrl = props.company.website_url
    let bingResultUrl = bingUrl
    if(!companyUrl || !bingResultUrl){
        return false
    }
    if(companyUrl.startsWith("http://")){
        companyUrl = companyUrl.slice(7)
    }
    if(companyUrl.startsWith("https://")){
        companyUrl = companyUrl.slice(8)
    }
    if(companyUrl.startsWith('www.')){
        companyUrl = companyUrl.slice(4)
    }
    if(companyUrl.indexOf('/') > -1){
        companyUrl = companyUrl.slice(0, companyUrl.indexOf('/'))
    }
    if(bingResultUrl.startsWith("http://")){
        bingResultUrl = bingResultUrl.slice(7)
    }
    if(bingResultUrl.startsWith("https://")){
        bingResultUrl = bingResultUrl.slice(8)
    }
    if(bingResultUrl.startsWith('www.')){
        bingResultUrl = bingResultUrl.slice(4)
    }
    if(bingResultUrl.indexOf('/') > -1){
        bingResultUrl = bingResultUrl.slice(0, bingResultUrl.indexOf('/'))
    }
    if(bingResultUrl == companyUrl){
        return true 
    } else {
        return false
    }

}


</script>
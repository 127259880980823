<template>
    <div class="flex mt-6 lg:mt-1 mb-1items-center">
        <div v-if="isAddCardVisible" class="w-full mb-1 text-sm">
            <NewBusinessTypesSectorsSubSectors :tableType="tableType" @refetch-table="refetchTable"
                @hide-add-card="toggleAddCard" />
        </div>
        <div v-else>
            <base-button class="mb-1.5" v-if="!isAddCardVisible" @click="toggleAddCard">
                {{ buttonName }}
            </base-button>
        </div>

    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import NewBusinessTypesSectorsSubSectors from './NewBusinessTypes+Sectors+SubSectors.vue'

const props = defineProps({
    tableType: String
})
const emit = defineEmits(['refetch-table'])
function refetchTable() {
    emit('refetch-table')
}

const isAddCardVisible = ref(false)
function toggleAddCard() {
    isAddCardVisible.value = !isAddCardVisible.value
}

const buttonName = ref('')
setButtonName()

function setButtonName() {
    if (props.tableType == 'businessTable') {
        buttonName.value = 'Add Business Type'
    } else if (props.tableType == 'sectorsTable') {
        buttonName.value = 'Add Sector'
    } else if (props.tableType == 'subSectorsTable') {
        buttonName.value = 'Add Sub Sector'
    }
}
</script>
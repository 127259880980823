<template>
    <div>
      <base-button @click="showModal">Customers</base-button>
      <InfoModal
      :is-modal-visible="isModalVisible"
          @close="closeModal"
      >
        <template #header>
          Customers
        </template>
        <template #body>
          <base-dblclick-textarea
              model-type="company"
              :model-id="company.id"
              field-name="customers"
              :field-value="company.customers"
              @refetch="fetchNewCompanies"
              @close="closeModal"
          ></base-dblclick-textarea>
        </template>
      </InfoModal>
    </div>
  
  </template>
  
  <script>
      export default {
          name: 'customer-notes-cell'
      }
  </script>
  
  <script setup>
  import {ref, defineProps, defineEmits} from "vue";
  import InfoModal from "../InfoModal.vue"
  
    const isModalVisible = ref(false)
  
    const props = defineProps({
        company: Object
    })
  
    const emit = defineEmits(['refetch-companies'])
  
    function fetchNewCompanies () {
        emit('refetch-companies')
    }
  
    function showModal () {
      isModalVisible.value = true
    }
  
    function closeModal () {
      isModalVisible.value = false
    }
  </script>
<template>
  <div class="w-full h-full">
    <base-form class="h-full w-full">
      <template #body>
        <div
          class="grid grid-cols-2 w-full gap-2 mb-1 p-1 text-xs"
        >
          <div class="flex-col col-span-1 my-auto">
            <div>Researcher*</div>
            <select
              required
              class="border border-dark-primary rounded-md p-1 w-full"
              v-model="projectAssignmentData.researcher"
            >
              <option
                v-for="researcher in researcherOptions"
                :key="researcher.id"
                :value="researcher.id"
              >
                {{ researcher.username }}
              </option>
            </select>
          </div>
          <div class="flex-col col-span-1 my-auto">
            <div>Research Project*</div>
            <select
              required
              class="border border-dark-primary rounded-md p-1 w-full"
              v-model="projectAssignmentData.researchProject"
            >
              <option
                v-for="researchProject in projectOptions"
                :key="researchProject.id"
                :value="researchProject.id"
              >
                {{ researchProject.project_name }}
              </option>
            </select>
          </div>
          <div class="flex flex-col my-auto">
            <label>Start Date</label>
            <input
              type="date"
              required
              class="p-1 border w-full inline"
              v-model="projectAssignmentData.startDate"
            />
          </div>
          <div class="flex flex-col my-auto">
            <label>End Date</label>
            <input
              type="date"
              required
              class="p-1 border w-full inline"
              v-model="projectAssignmentData.endDate"
            />
          </div>
          <div class="flex flex-col my-auto">
            <label>Hourly Capacity Per Week</label>
            <input
              type="number"
              step="any"
              min="0"
              max="80"
              placeholder="Input Time"
              required
              class="p-1 border w-full inline"
              v-model="projectAssignmentData.hourlyCapacityPerWeek"
            />
          </div>
          <div
            v-if="highlightBoxes"
            class="text-center col-span-2 text-red-500 font-semibold"
          >
            Please complete all required fields.
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row space-x-2 justify-center w-full col-span-2 my-2">
          <base-button-affirmative @click="submitForm"
            >Save</base-button-affirmative
          >
          <base-button-negative @click="closeForm">Cancel</base-button-negative>
        </div>
      </template>
    </base-form>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { editProjectAssignment } from "../../services/ProjectAssignment.js";

const store = useStore();

const props = defineProps({
  researchers: Array,
  researchProjects: Array,
  currProjectAssignment: Object,
});

const emit = defineEmits(["close-form"]);
// console.log(typeof(parseInt(props.currProjectAssignment.hourly_capacity_per_week)))

const projectAssignmentData = reactive({
  id: props.currProjectAssignment.id,
  researcher: props.currProjectAssignment.researcher.id,
  researchProject: props.currProjectAssignment.research_project.id,
  startDate: props.currProjectAssignment.start_date,
  endDate: props.currProjectAssignment.end_date,
  hourlyCapacityPerWeek: parseInt(
    props.currProjectAssignment.hourly_capacity_per_week
  ),
});

const researcherOptions = ref(props.researchers);
const projectOptions = ref(props.researchProjects);

const highlightBoxes = ref(false);

function closeForm() {
  emit("close-form");
}

async function submitForm() {
  if (
    projectAssignmentData.researcher == null ||
    projectAssignmentData.researchProject == null
  ) {
    highlightBoxes.value = true;
    return;
  }
  const formData = JSON.stringify({
    id: projectAssignmentData.id,
    research_project: projectAssignmentData.researchProject,
    researcher: projectAssignmentData.researcher,
    status: "active",
    hourly_capacity_per_week: projectAssignmentData.hourlyCapacityPerWeek,
    start_date: projectAssignmentData.startDate,
    end_date: projectAssignmentData.endDate,
  });
  // console.log(formData)
  editExistingProjectAssignment(formData);
}

async function editExistingProjectAssignment(formData) {
  const payload = {
    token: store.state.auth.token,
  };
  const result = await editProjectAssignment(
    projectAssignmentData.id,
    formData,
    payload
  );
  // console.log('result', result)
  if (result.status == 200) {
    // alert("Project assignment edited sucessfully.")
    emit("close-form");
    // emit('refetch-companies')
  } else {
    alert("Error, project assignment not edited.");
  }
}

onMounted(() => {
  highlightBoxes.value = false;
});
</script>

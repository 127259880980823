<template>
    <!-- <div :style="pageStyling"> -->
<div>
    <transition
        enter-active-class="transition duration-400 ease-in"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-400 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="h-full"
    >
    </transition>
    <base-card>
        <EngagementFilters @change-engagement-filters="setEngagementFilters"></EngagementFilters>
    </base-card>
    <base-card>
    <div class="flex flex-col text-sm text-gray-900 h-[85vh]">
        <div class="flex flex-col justify-start lg:flex-row w-full mb-2">
            <div class="px-3 h-full flex w-full lg:w-fit flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center 0">
            </div>
            <div class="flex flex-row items-center justify-between py-2 border-b border-light-primary lg:py-0 lg:border-none w-full h-full px-2 ">
                <base-pagination-header
                class="w-full"
                @load-data="loadEngagements(fetchFilter)"
                @get-next="getNext"
                @go-to-first="goToFirst"
                @get-prev="getPrev"
                @go-to-last="goToLast"
                @jump-to-page="jumpToPage"
                @set-page-size-filter="setPageSizeFilter"
                :page_filter="pageSizeFilter"
                :page="page"
                :numPages="totalPages"
                :isLoading="isLoading"
                >
                <template v-slot:message>
                    <div class="pl-3 pt-1 whitespace-nowrap">
                    {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Engagements | Total Retainers: {{ retainerAmount }}
                    </div>
                </template>
                </base-pagination-header>
            </div>
        </div>
        <div v-if="engagements.length > 0" class="h-full overflow-y-auto">
        <base-table>
            <template #table-header>
            <base-table-header class="text-center" :key="header.value" :header="header" v-for="header in tableHeadersList"> 
              <div class="flex  items-center justify-between space-x-1">
                <div>
                  {{ header.value }}
                </div>
                <div v-if="header.orderFieldName">
                  <div @click="changeOrdering(header.orderFieldName)" v-if="noOrdering(header.orderFieldName)" class="text-sm flex flex-col space-y-0" >
                    <div class=" hover:cursor-pointer">▲</div>
                    <div class=" hover:cursor-pointer">▼</div>
                  </div>
                  <div @click="changeOrdering(header.orderFieldName)" v-else-if="orderedAscending(header.orderFieldName)">
                    <div class=" hover:cursor-pointer">▲</div>
                  </div>
                  <div @click="changeOrdering(header.orderFieldName)" v-else-if="orderedDescending(header.orderFieldName)">
                    <div class=" hover:cursor-pointer">▼</div>
                  </div>
                </div>
                <div v-else-if="header.value == 'Days Until Target Invoice Day'">
                  <div @click="setOrderByInvoiceDay('desc')" v-if="!orderByInvoiceDay" class="text-sm flex flex-col space-y-0" >
                    <div class=" hover:cursor-pointer">▲</div>
                    <div class=" hover:cursor-pointer">▼</div>
                  </div>
                  <div @click="setOrderByInvoiceDay(null)" v-else-if="orderedByInvoiceDayAscending">
                    <div class=" hover:cursor-pointer">▲</div>
                  </div>
                  <div @click="setOrderByInvoiceDay('asc')" v-else-if="orderedByInvoiceDayDescending">
                    <div class=" hover:cursor-pointer">▼</div>
                  </div>
                </div>
              </div>
            </base-table-header>
            </template>

            <template #table-body>
            <EngagementRow
                :key="engagement.id"
                :engagement="engagement"
                v-for="engagement in orderedEngagements"
                @refetch-engagements="loadEngagements"
                        />
            </template>
        </base-table>
        </div>
    </div>
    </base-card>
</div>
</template>

<script>
export default {
name: 'engagements-table'
}
</script>

<script setup>
import {getEngagements} from '@/services/Engagement.js'
import {useStore} from 'vuex';
import {ref, reactive, shallowReactive, computed, watch} from 'vue';
import EngagementFilters from './EngagementFilters.vue';
import EngagementRow from './EngagementRow.vue';
import BaseCard from "@/components/BaseCard";

const store = useStore()
const isLoading = ref(false);
const awaitingSearch = ref(false);
const totalRetainers = ref(0)
const showAllItems = reactive({
value: false,
changeCount: 0
})
const tableHeadersList = ref([
{value: 'ID'},
{value: 'Client', orderFieldName: 'client__client_name'},
{value: 'Start Date', orderFieldName: 'start_date'},
{value: 'End Date', orderFieldName: 'end_date'},
{value: 'Retainer Amount'},
{value: 'Payment Terms'},
{value: 'Billing Timeframe'},
{value: 'Target Invoice Date'},
{value: 'Target Invoice Day'},
{value: 'Days Until Target Invoice Day'},
{value: 'Target Due Date'},
{value: 'Last Invoice Nbr: Date (Due)'},
{value: 'Missing Invoice'},
{value: 'Typical Payday'},
{value: 'Accounting Posts'},
])
const orderBy = ref(["-start_date"])
function setShowAllItems(showOrHide) {
showAllItems.value = showOrHide
showAllItems.changeCount++
}

const engagementFilters = reactive({
client: null,
status: "active",
shouldInvoice: true
});

const pageSizeFilter = reactive({
pageSizeFilter: 50
})

function noOrdering(fieldName) {
  if(!orderBy.value.includes(fieldName) && !orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }
}

function orderedDescending(fieldName){
  if(orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }  
}

const orderByInvoiceDay = ref('asc')

const orderedEngagements = computed(() => {
  if(orderByInvoiceDay.value == 'asc'){
    return engagements.value.toSorted((a, b) => {
      return a.days_until_target_invoice_day - b.days_until_target_invoice_day
    })
  } else if (orderByInvoiceDay.value == 'desc'){
    return engagements.value.toSorted((a, b) => {
      return b.days_until_target_invoice_day - a.days_until_target_invoice_day
    })
  }
  return engagements.value
})

function orderedAscending(fieldName){
  if(orderBy.value.includes(fieldName)){
    return true
  } else {
    return false
  }  
}

const orderedByInvoiceDayAscending = computed(() => {
  return orderByInvoiceDay.value == 'asc'
})

const orderedByInvoiceDayDescending = computed(() => {
  return orderByInvoiceDay.value == 'desc'
})

function changeOrdering(fieldName) {
  if(orderedAscending(fieldName)){
    const index = orderBy.value.indexOf(fieldName)
    orderBy.value.splice(index, 1)
  } else if(orderedDescending(fieldName)) {
    const index = orderBy.value.indexOf('-' + fieldName)
    orderBy.value[index] = fieldName
  } else {
    orderBy.value.push('-' + fieldName)
  }
}

const engagements = ref([])
const next = ref(null);
const prev = ref(null);
const count = ref(null);
const page = ref(1);
const pageSize = ref(null);
const totalPaid = ref(0)
const totalUnpaid = ref(0)
const totalPages = ref(0)

const fetchFilter = computed(function () {
  let query = new URLSearchParams({
  });
  if (engagementFilters.client) {
      engagementFilters.client.forEach(client => {
        query.append("client", client)
      })
  }
  if (engagementFilters.status) {
      query.append("status", engagementFilters.status)
  }
  if (engagementFilters.shouldInvoice) {
      query.append("should_invoice", engagementFilters.shouldInvoice)
  }
  if (pageSizeFilter.pageSizeFilter) {
    query.append("page_size", pageSizeFilter.pageSizeFilter); // To append more data
  }
  if(orderBy.value.length > 0){
      query.append("order_by", orderBy.value.join(','))
    }
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  // console.log(url);
  return url
})

async function loadEngagements(queryParams = fetchFilter.value) {
isLoading.value = true;
const token = store.state.auth.token 
const result = await getEngagements(token, queryParams);
  engagements.value = result.results;
  next.value = result.next ? `&page=${result.page + 1}` : null
  prev.value = result.prev ? `&page=${result.page - 1}` : null
  count.value = result.count
  page.value = result.page
  pageSize.value = result.results.length
  totalRetainers.value = result.total_retainer_amount
  totalPages.value = result.num_pages
  isLoading.value = false;

}

const retainerAmount = computed(() => {

  const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(totalRetainers.value);
})

function setPageSizeFilter(target=null){
if(target) {
  target == "clear" ? (pageSizeFilter.pageSizeFilter = 50) : (pageSizeFilter.pageSizeFilter = target);
  loadEngagements(fetchFilter.value)
}
}

function setEngagementFilters(updatedFilters) {
// engagementFilters.client = updatedFilters.client;
// engagementFilters.status = updatedFilters.status;
    for(const filter in updatedFilters){
        engagementFilters[filter] = updatedFilters[filter].value
    }
}

function getNext() {
if (next.value) {
  loadEngagements(fetchFilter.value + next.value)
} else {
  alert("No next page")
}
}

function getPrev() {
if (prev.value) {
  loadEngagements(fetchFilter.value + prev.value)
} else {
  alert("No prev page")
}
}

function jumpToPage(targetPage) {
loadEngagements(fetchFilter.value + `&page=${targetPage}`)
}

const viewingFromNbr = computed(function () {
return (page.value * pageSize.value + 1) - pageSize.value
})


const viewingToNbr = computed(function () {
if (page.value === totalPages.value) {
  return count.value;
} else {
  return page.value * pageSize.value
}
})

function setOrderByInvoiceDay(target) {
  orderByInvoiceDay.value = target
  if(target){
    orderBy.value = []
  }
}

function goToFirst(){
loadEngagements(fetchFilter.value + `&page=${1}`)
}

function goToLast(){
loadEngagements(fetchFilter.value + `&page=${totalPages.value}`)
}

watch(() => engagementFilters, () => {
if(!awaitingSearch.value) {
      setTimeout(() => {
        loadEngagements(fetchFilter.value);
        awaitingSearch.value = false;
      }, 2000); // 2.5 sec delay
    }
    awaitingSearch.value = true
}, {deep: true})


watch(() => orderBy, () => {
    if(!awaitingSearch.value) {
          setTimeout(() => {
            loadEngagements(fetchFilter.value);
            awaitingSearch.value = false;
          }, 1000); // 1 sec delay
        }
        awaitingSearch.value = true
}, {deep: true})

loadEngagements()

</script>
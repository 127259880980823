<template>
    <div>
        {{ company.cage_code }}
    </div>

</template>

<script>
    export default {
        name: "cage-code-cell"
    }
</script>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        company: Object
    })
</script>
import axios from 'axios';
import { BASE_URL } from './api';
import { saveAs } from 'file-saver';
import { formatDateYearMonthDay } from '@/composables/formatDate';


export async function getServiceRecords(token, queryParams) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/service_records/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        console.log(response)
        return response;
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function updateServiceRecord(serviceRecordId, formData, token) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/service_records/${serviceRecordId}/update_service_record/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function createServiceRecord(data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/service_records/create_service_record/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function deleteServiceRecord(serviceRecordId, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/service_records/${serviceRecordId}/delete_service_record/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function performBulkAction(endpoint, data, token) {
    return axios({
        method: "put",
        url: `${BASE_URL}/service_records/${endpoint}/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        },
        data: data
    })
    .then(response => response)
    .catch(error => error)
}

export async function getInvoiceTemplateFromServiceRecords(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/service_records/bulk_generate_invoice_template_from_service_records/`,
        headers: {
            'Authorization': `Token ${token}`,
        },
        responseType: 'blob'
    }).then((response) => {
        console.log('response xlsx', response)
        saveAs(new Blob([response.data]), `${formatDateYearMonthDay(new Date().toDateString())} Invoice Detail.xlsx`)
        return {
            response
        }
    }).catch((error) => {
        console.log(error)
        return error
    })
}

export async function exportToCSV(endpoint, data, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/service_records/${endpoint}/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        },
        data: data,
        responseType: 'blob'
    })
    .then(response => {
        console.log('response')
        console.log(response)
        saveAs(new Blob([response.data]), `services_invoice_template_${new Date().toDateString()}.csv`)
        return {
            response
        }
    })
    .catch(error => error)
}

export async function duplicateServiceRecord(recordId, token) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/service_records/${recordId}/duplicate_service_record/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
        return error
    })
}
export default {
    showFilterPane(state){
        return state.showFilterPane
    },
    showBasicFilters(state){
        return state.showBasicFilters
    },
    showActiveFilters(state){
        return state.showActiveFilters
    }
}
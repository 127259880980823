<template>
    <base-table-row>
        <base-table-cell-condensed>
            <BusinessTypesSectorsSubSectorsCell :row="row" :tableType="tableType" @refetch-table="refetchTable"/>
        </base-table-cell-condensed>
        <base-table-cell-condensed class="w-1/12">
            <base-button class="flex justify-center" @click="checkDeleteRow">X</base-button>
        </base-table-cell-condensed>
    </base-table-row>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { deleteBusinessType } from '@/services/BusinessType'
import { deleteSector } from '@/services/Sector'
import { deleteSubSector } from '@/services/SubSector'
import BusinessTypesSectorsSubSectorsCell from './BusinessTypes+Sectors+SubSectorsCell.vue'

const store = useStore()
const props = defineProps({
    row: Object,
    tableType: String
})
const emit = defineEmits(['refetch-table'])
function refetchTable() {
    emit('refetch-table')
}

function checkDeleteRow() {
    let rowName = null
    let tableName = null
    if (props.tableType == 'businessTable') {
        rowName = props.row.business_type_name
        tableName = 'Business Types'
    } else if (props.tableType == 'sectorsTable') {
        rowName = props.row.sector_name
        tableName = 'Sectors'
    } else if (props.tableType == 'subSectorsTable') {
        rowName = props.row.sub_sector_name
        tableName = 'Sub Sectors'
    }
    if (!confirm("You are about to delete ''" +
        rowName +
        "'' from the " + tableName + " table. Are you sure you want to proceed?")) {
        return;
    } else {
        deleteRow()
    }
}

async function deleteRow() {
    let token = store.state.auth.token
    if (props.tableType == 'businessTable') {
        await deleteBusinessType(props.row.id, token)
    } else if (props.tableType == 'sectorsTable') {
        await deleteSector(props.row.id, token)
    } else if (props.tableType == 'subSectorsTable') {
        await deleteSubSector(props.row.id, token)
    }
    emit('refetch-table')
}

</script>
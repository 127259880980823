<template>
    <div>
        <CampaignCell :company="company" />
        <base-pill>Tier:</base-pill> <TierCell :company="company" class="inline"/>
    </div>

</template>

<script>
    export default {
        name: "campaign-tier-cell"
    }
</script>

<script setup>
import { defineProps } from 'vue';
import CampaignCell from '../company-cell-components/CampaignCell.vue';
import TierCell from '../company-cell-components/TierCell.vue';

    const props = defineProps({
        company: Object
    })

</script>
<template>
    <div class="grid grid-cols-3 gap-1">
        <button @click="assignTier(1)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">1</button>
        <button @click="assignTier(2)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">2</button>
        <button @click="assignTier(3)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">3</button>
        <button @click="assignTier(4)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">4</button>
        <button @click="assignTier(5)" class="p-1 border border-dark-primary rounded hover:bg-dark-primary hover:text-white hover:underline">5</button>
        <button @click="assignTier('None')" class="p-1 border border-dark-primary rounded bg-dark-primary text-white hover:underline">RM</button>
    </div>
</template>

<script>
    export default {
        name: "mark-tier-buttons-cell"
    }
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { markTier } from '@/services/Company';
import { useStore } from 'vuex';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    async function assignTier(tierId){
        const token = store.state.auth.token
        await markTier(props.company.id, tierId, token)
        emit('refetch-companies')
    }

    

</script>
export default {
    showContacts(state) {
        return state.contacts;
    },
    showLoading(state) {
        return state.isLoading;
    },
    showCount(state){
        return state.count
    },
    showNext(state){
        return state.next
    },
    showPrev(state){
        return state.prev
    },
    showPage(state){
        return state.page
    },
    showPageSize(state){
        return state.pageSize
    },
    showActiveFilters(state){
        return state.activeFilters
    },
    showFilterPane(state){
        return state.showFilterPane
    },
    showBasicContactFilters(state){
        return state.showBasicContactFilters
    },
    showBasicCompanyFilters(state){
        return state.showBasicCompanyFilters
    },
    showBulkOperationsPane(state){
        return state.showBulkOperationsPane
    }
}
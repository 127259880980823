<template>
    <div class="w-full pb-10">
        <div class="flex flex-col">
            <div class="text-base">
                Prime Contracts <span v-if="!contractsLoading">({{ primeContracts.length }})</span> and Sub Contracts <span v-if="!contractsLoading">({{ subContracts.length }})</span> 
            </div>
            <div v-if="contractsLoading && !tablesVisible" role="status" class="pl-3 pb-3 w-full flex">
                <div class="flex flex-col">
                <div class="flex flex-row">
                    <svg
                    aria-hidden="true"
                    class="mx-2 mt-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"
                    />
                    </svg>
                    <div class="mt-2 text-lg">
                    Getting Contracts...
                    </div>
                </div>
                </div>
            </div>
            <div v-if="tablesVisible" class="flex flex-col space-y-4">
                <div class="w-full ml-2">
                    <h1>Prime Contracts</h1>
                    <div class="ml-2" v-if="primeContracts.length > 0">
                        <base-table class="overflow-y-auto">
                            <template #table-header>
                                <base-table-header v-for="header in primeContractHeaderList" :key="header">
                                    {{ header }}
                                </base-table-header>
                            </template>
                            <template #table-body>
                                <tr v-for="primeContract in primeContracts" :key="primeContract.id">
                                    <base-table-cell>
                                    <a target="_blank" class="text-blue-500 underline" :href="primeContract.usaspending_permalink">{{ primeContract.award_id_piid }}</a>
                                    </base-table-cell>
                                    <base-table-cell>
                                        {{ primeContract.modification_number }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        ${{ primeContract.total_dollars_obligated }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        {{ primeContract.awarding_agency_name }} | {{ primeContract.awarding_sub_agency_name }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        {{ primeContract.product_or_service_code }}: {{ primeContract.product_or_service_code_description }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        {{ primeContract.action_date }}
                                    </base-table-cell>
                                </tr>
                            </template>
                        </base-table>
                    </div>
                    <div class="ml-2" v-else>
                        <p>No prime contracts associated with this company.</p>
                    </div>
                </div>
                <div class="w-full ml-2">
                    <h1>Sub Contracts</h1>
                    <div class="ml-2" v-if="subContracts.length > 0">
                        <base-table class="overflow-y-auto">
                            <template #table-header>
                                <base-table-header v-for="header in subContractHeaderList" :key="header">
                                    {{ header }}
                                </base-table-header>
                            </template>
                            <template #table-body>
                                <tr v-for="subContract in subContracts" :key="subContract.id">
                                    <base-table-cell>
                                    {{ subContract.subaward_number }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        ${{ subContract.subaward_amount }}
                                    </base-table-cell>
                                    <base-table-cell>
                                    {{ subContract.subaward_description }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        {{ subContract.subaward_action_date }}
                                    </base-table-cell>
                                    <base-table-cell>
                                        <a target="_blank" class="text-blue-500 underline" :href="subContract.usaspending_permalink">{{ subContract.prime_award_id_piid }}</a>
                                    </base-table-cell>
                                </tr>
                            </template>
                        </base-table>
                    </div>
                    <div class="ml-2" v-else>
                        <p>No sub contracts associated with this company.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
  
  </template>
  
  <script>
      export default {
          name: 'company-contracts-and-sub-contracts'
      }
  </script>
  
  <script setup>
  import {ref, defineProps} from "vue";
  import { getContractsAndSubContracts } from "@/services/Company";
  import { useStore } from "vuex";
  
    const store = useStore()
    const contractsLoading = ref(false)
    const tablesVisible = ref(false)
    const primeContracts = ref([])
    const subContracts = ref([])
    const primeContractHeaderList = ref([
        "Award PIID",
        "Modification Number",
        "Total Obligated",
        "Awarding Agency / Sub Agency",
        "Product or Service Code",
        "Action Date"
    ])
    const subContractHeaderList = ref([
        "Sub Award Number",
        "Amount",
        "Description",
        "Action Date", 
        "Prime Award PIID",
    ])
  
    const props = defineProps({
        company: Object
    })
  
  
    async function loadContractData () {
      contractsLoading.value = true
      const token = store.state.auth.token
      const result = await getContractsAndSubContracts(token, props.company.id)
      primeContracts.value = result.data.prime_contracts
      subContracts.value = result.data.sub_contracts
      console.log('prime_contracts', primeContracts.value)
      console.log('sub contracts', subContracts.value)
      contractsLoading.value = false
      tablesVisible.value = true


    }
    
    loadContractData()
  

  </script>
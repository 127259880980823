<template>
    <span class=
        "border
        bg-gray-100
        border-dark-primary
        whitespace-nowrap
        rounded-l-full
        rounded-r-full
        w-fit
        px-2
        py-1
        mr-0.5
        text-center 
        text-xs">
        <slot></slot>
        <!-- <button @click="removeFilter" class="text-s w-4 h-4">x</button> -->
    </span>
</template>

<script>
    export default{
        name: "base-pill-x.vue",
        methods: {
            removeFilter() {
                this.$emit("removeFilter")
            },
        },
    }
</script>
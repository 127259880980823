<template>
    <div class="my-5 ml-1">
    <base-table-condensed class="h-full overflow-y-scroll">
        <template #table-header>
            <base-table-header class="border-b border-b-gray-400 overflow-y-auto ">
                Injection Syntax
            </base-table-header>
            <base-table-header class="border-b border-b-gray-400 overflow-y-auto">
                Field Description
            </base-table-header>
        </template>
        
        <template #table-body>
            <base-table-row
            :key="data.syntax"
            v-for="data in syntaxData">
                <base-table-cell> {{data.syntax}}</base-table-cell>
                <base-table-cell> {{data.description}}</base-table-cell>
            </base-table-row>
        </template>
    </base-table-condensed>
    </div>
</template>

<script setup>
import BaseTableCondensed from '../BaseTableCondensed.vue';
import BaseTableHeader from '../BaseTableHeader.vue';
import BaseTableRow from '../BaseTableRow.vue';

const syntaxData = [
    {syntax: '{{account_manager_research_notes}}', description: 'Company Field - account_manager_research_notes'},
    {syntax: '{{category}}', description: 'Company Field - category'},
    {syntax: '{{client_feedback}}', description: 'Company Field - client_feedback'},
    {syntax: '{{client_feedback_notes}}', description: 'Company Field - client_feedback_notes'},
    {syntax: '{{company_name}}', description: 'Company Field - company_name'},
    {syntax: '{{crm_summary}}', description: 'Company Field - crm_summary'},
    {syntax: '{{customers}}', description: 'Company Field - customers'},
    {syntax: '{{dba}}', description: 'Company Field - doing_business_as'},
    {syntax: '{{email_bcc}}', description: 'Email Template Field - email_bcc'},
    {syntax: '{{investors}}', description: 'Company Field - investors'},
    {syntax: '{{parent_company}}', description: 'Company Field - parent_company'},
    {syntax: '{{phone}}', description: 'Company Field - phone'},
    {syntax: '{{platforms}}', description: 'Company Field - platforms'},
    {syntax: '{{primary_contact_email}}', description: 'Contact Fields - email_address'},
    {syntax: '{{primary_contact_first_name}}', description: 'Contact Fields - first_name'},
    {syntax: '{{primary_contact_full_name}}', description: 'Contact Fields - full_name'},
    {syntax: '{{primary_contact_linkedin}}', description: 'Contact Fields - linkedin_profile'},
    {syntax: '{{primary_contact_notes}}', description: 'Contact Fields - contact_notes'},
    {syntax: '{{primary_contact_phone}}', description: 'Contact Fields - phone_number'},
    {syntax: '{{primary_contact_title}}', description: 'Contact Fields - title'},
    {syntax: '{{primary_contact_preferred_name}}', description: 'Contact Fields - preferred_name'},
    {syntax: '{{out_reach_notes}}', description: 'Company Fields - out_reach_notes'},
    {syntax: '{{relevant_business}}', description: 'Company Field - relevant_business'},
    {syntax: '{{square_footage}}', description: 'Company Fields - square_footage'},
    {syntax: '{{company_nickname}}', description: 'Company Fields - company_nickname'},
    {syntax: '{{personalized_outreach_text}}', description: 'Company Fields - personalized_outreach_text'},
    {syntax: '{{website_url}}', description: 'Company Fields - website_url'},
    {syntax: '<img src="cid:signature">', description: 'Add image saved in Signature Image uploader.'},
    {syntax: '<img src="cid:logo">', description: 'Add image saved in Logo Image uploader.'},
    {syntax: '<img src="cid:logo_2">', description: 'Add image saved in Logo Image 2 uploader.'},
]
</script>
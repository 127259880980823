<template>
    <div class="text-dark-primary flex flex-col">
        <p class="underline">
            CRM Summary Stages Options
        </p>
        <div class="flex flex-col items-start  ml-1 mb-3" >
            <div class="mt-1">
                <input type="checkbox" id="checkbox" class="align-middle" v-model="checkedValue" :checked="showCRMSummaryStages" @click="toggleShowCRMSummaryStages"/>
                <span class="ml-1 mr-2">Show Summary Stages Counts</span>
            </div>
            <div>
                <div>
                    <span class="mr-2">Client: {{ clientName }}</span> 
                    <span @click="toggleShowClientOptions" class="hover:cursor-pointer"  v-if="showClientOptions">▼</span>
                    <span @click="toggleShowClientOptions" class="hover:cursor-pointer"  v-else>▶</span>
                </div>
                <div v-show="showClientOptions" class="flex flex-col">
                    <div class="ml-4 mt-1" v-if="clients.length == 0">
                        <div class="flex items-center space-x-1">
                            <input type="radio" id="default" name="crmSummaryStageClient" :checked="!crmSummaryStageClient" :value="null">
                            <label for="default">Default</label>
                        </div>
                    </div>
                    <div class="ml-4 mt-1" v-else>
                        <div class="flex items-center space-x-1">
                            <input type="radio" id="default" name="crmSummaryStageClient" :checked="!crmSummaryStageClient" :value="null" @change="setCRMSummaryStageClient">
                            <label for="default">Default</label>
                        </div>
                        <div v-for="client in clients" :key="client.id" class="flex items-center space-x-1">
                            <input type="radio" :id="client.id" name="crmSummaryStageClient" :value="client.id" @change="setCRMSummaryStageClient">
                            <label :for="client.id">{{ client.client_name }}</label>
                        </div>
                    </div>
                </div>
                <!-- <input type="radio" id="default" name="crmSummaryStageClient" :value="null">
                <label for="default">Default</label> -->
            </div>
        </div>

    </div>
</template>

<script setup>
import { computed, watch, ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import { getClients } from '@/services/Client';

const store = useStore()

const props = defineProps({
    module: String
});
const checked = ref(null)
const clients = ref([])
const crmSummaryStageClient = computed(() => store.getters["company/showCRMSummaryStagesClient"])
const showClientOptions = ref(false)
function toggleShowClientOptions(){
    showClientOptions.value = !showClientOptions.value
}

const clientName = computed(() => {
    if(crmSummaryStageClient.value){
        console.log('crm summary stage value', crmSummaryStageClient.value)
        return clients.value.filter((client) => {
            return client.id == crmSummaryStageClient.value
        }).map((client) => client.client_name)[0]
    } else {
        return "Default"
    }
})

const showCRMSummaryStages = computed(() => store.getters[`${props.module}/showCRMSummaryStages`])

const checkedValue = ref(showCRMSummaryStages.value)

watch(checkedValue, () => {
    if(showCRMSummaryStages.value){
        checkedValue.value = true
    } else {
        checkedValue.value = false
    }
}, {
    flush: 'post'
})

const toggleShowCRMSummaryStages = () => {
    if(showCRMSummaryStages.value) {
        store.dispatch(`${props.module}/setShowCRMSummaryStagesFalse`)
    } else {
        store.dispatch(`${props.module}/setShowCRMSummaryStagesTrue`)
    }
}

async function getClientsWithSummaryStages(){
    const token = store.state.auth.token
    const result = await getClients({endpoint: '?has_crm_summary_stages=true', token: token})
    clients.value = result.clients
}

function setCRMSummaryStageClient(e){
    if(e.target.value == "on"){
        store.dispatch('company/setCRMSummaryStageClient', null)
    } else {
        store.dispatch('company/setCRMSummaryStageClient', e.target.value)
    }
}

getClientsWithSummaryStages()

</script>
import axios from "axios";

import { BASE_URL } from "./api";

export async function getAllTasks(payload) {
  return axios({
    method: "get",
    url: `${BASE_URL}/tasks/${payload.endpoint}`,
    headers: {
        'Authorization': `Token ${payload.token}`
    },
  })
    .then((response) => {
      return {
        all_tasks: response.data.results,
        count: response.data.count,
        next: response.data.next,
        prev: response.data.previous,
        page: response.data.page,
        page_size: response.data.page_size,
        num_pages: response.data.num_pages,
      };
    })
    .catch((error) => {
      throw error;
    });
}

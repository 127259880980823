<template>
    <div>
        <span>{{revenueHeadcount}}</span> <SquareFootageCell class="inline" :company="company" @refetch-companies="fetchNewCompanies" />
    </div>
</template>

<script>
  export default {
      name: "revenue-headcount-square-footage-cell"
  }
</script>

<script setup>
import {defineProps, computed, defineEmits} from "vue"
import SquareFootageCell from "./SquareFootageCell.vue";
import {formatDate} from '@/composables/formatDate'


    const props = defineProps({
        company: Object
    })

    const emit = defineEmits(['refetch-companies'])

    const revenueHeadcount = computed(() => {
        let revenue = props.company.revenue ? `${props.company.revenue} M` : '--'
        let headcount = props.company.employee_headcount ? `${props.company.employee_headcount} ppl` : '--'
        // let square_footage = props.company.square_footage ?  `${props.company.square_footage} K` : '--'
        return `${revenue} / ${headcount} /`
    })

    function fetchNewCompanies(){
        emit('refetch-companies')
    }

</script>
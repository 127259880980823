<template>
    <div class="flex flex-col pt-1 pb-1 justify-center h-9 -ml-1 mr-1">
        <button type="button" @click="setMinActive" :class="{'w-full border-r border-t border-t-light-primary border-r-light-primary rounded-tr h-full p-1 bg-blue-500 text-white text-sm' : minActive, 'w-full border-r border-t border-t-light-primary border-r-light-primary rounded-tr-md p-1 h-full bg-gray-300 text-gray-500 text-sm' : hourActive}" >Min</button>
        <button type="button" @click="setHourActive" :class="{'w-full rounded-br border-r border-b border-b-light-primary border-r-light-primary h-full p-1 bg-blue-500 text-white text-sm' : hourActive, 'w-full border-r border-b border-b-light-primary border-r-light-primary rounded-br-md p-1 h-full bg-gray-300 text-gray-500 text-sm' : minActive}" >Hour</button>
    </div>
</template>

<script>
export default {
  name: "MinHourButtons.vue"
}
</script>

<script setup>
import {ref, computed, defineProps, defineEmits, watch} from "vue";

    const minActive = ref(false)
    const hourActive = ref(false)

    const activeButton = ref('mins')
    const props = defineProps({
        activeButton : String
    })

    function setMinActive() {
        hourActive.value = false
        minActive.value = true
        activeButton.value = 'mins'
        emit('updateActiveButton', 'mins')
    }

    function setHourActive() {
        minActive.value = false
        hourActive.value = true
        activeButton.value = 'hours'
        emit('updateActiveButton', 'hours')
    }

    function determineActive(){
        if(props.activeButton == 'mins'){
            minActive.value = true
            hourActive.value = false
        } else if (props.activeButton == 'hours'){
            minActive.value = false
            hourActive.value = true
        }
    }

    const emit = defineEmits(['updateActiveButton'])

    const activeClass = "rounded p-1 bg-blue-500 text-white text-sm"
    const inactiveClass = "rounded p-1 bg-gray-300 text-gray-800 text-sm"

    watch(() => props.activeButton, () => {
        console.log('activebutton', props.activeButton)
        determineActive()
        console.log(minActive.value)
        console.log(hourActive.value)
    })

    determineActive()

</script>
<template>
    <form @submit.prevent>
      <div class="flex flex-row">
            <div class="w-[75px]">
                <label class="bg-light-primary text-xs py-1 px-2 w-full flex justify-center items-center rounded-l-md h-full text-center" for="companyName">
                    <slot name="label"></slot>
                </label>
            </div>
            <div class="w-[100px] rounded-r-md h-8 text-xs
                        border border-gray-300 text-gray-900
                        focus:ring-dark-primary focus:border-dark-primary">
                <slot name="widget"></slot>
            </div>
        </div>
    </form>
  </template>
  
  <script>
  export default {
    name: "BaseFilterHorizontal.vue"
  }
  </script>
  
  <style scoped>
  
  </style>
import axios from 'axios';
import { BASE_URL } from './api';



export async function getCrmStatuses(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/crm_statuses/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}
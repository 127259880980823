<template>
    <div class="h-full w-full">
        <base-form class="h-full w-full">
            <template #body>
                <div v-if="contacts.length > 0" class="w-full h-fit">
                    <div class="grid grid-cols-2 p-1 border-b border-b-light-primary" v-for="contact in contacts" :key="contact.id">
                        <div class="flex flex-col xl:grid xl:gap-1 xl:grid-cols-2 2xl:grid-cols-4 border-r border-dotted border-light-primary items-start px-1.5">
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Prefix:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactPrefixCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Fist Name:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactFirstNameCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Middle Name:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactMiddleNameCell class="inline" :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Last Name:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactLastNameCell :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Preferred Name:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactPreferredNameCell :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Suffix:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactSuffixCell :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Personal Email:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactPersonalEmailCell :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Contact Personal Cell Phone:</base-pill>
                            </div>
                            <div class="flex space-x-1  2xl:col-span-3 w-full">
                                <CompanyContactPhoneCell class="w-fit" :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Linkedin:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <ContactLinkedInCell :contact="contact" @refetch-contacts="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Personal Notes:</base-pill>
                            </div>
                            <div class="2xl:col-span-3 w-full">
                                <CompanyContactPersonalNotesCell :company-contact="contact" @refetch-companies="fetchNewCompanies" />
                            </div>
                        </div>
                        <div class="flex flex-col xl:grid xl:gap-1 xl:grid-cols-2 2xl:grid-cols-4 items-start px-1.5">
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Title:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactTitleCell :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Contact Business Email:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactBusinessEmailCell :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5 ">
                                <base-pill class="h-fit">Contact Direct Company Line:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactBusinessPhoneCell :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Contact Notes:</base-pill>
                            </div>
                            <div class="flex space-x-1 2xl:col-span-3 w-full">
                                <CompanyContactBusinessNotesCell :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" />
                            </div>
                            <div class="flex justify-end py-0.5">
                                <base-pill class="h-fit">Is Primary:</base-pill>
                            </div>
                            <div class="flex space-x-1">
                                <div><CompanyContactIsPrimaryCell :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies" /></div>
                            </div>
                                <div class="flex flex-row justify-end items-center py-0.5 col-span-2 2xl:col-span-4 space-x-1 w-full my-1.5">
                                <CompanyContactMarkEmailVerifiedCell class="inline"  :company-contact="contactCompanyRelationships(contact)" @refetch-companies="fetchNewCompanies"  />
                                <base-button-negative @click="removeCompanyContact(contact, contactCompanyRelationships(contact))">Archive</base-button-negative>
                        </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    --
                </div>
                <CompanyContactForm v-for="(row, index) in formRows" :key="row.key" :company="row.company" @refetch-companies="fetchNewCompanies" @close-form="closeContactRow(index)"/>
            </template>
            <template #footer>
                <div class="flex flex-row justify-end py-0.5">
                    <base-button @click="addFormRow">Add Contact</base-button>
                </div>
            </template>
        </base-form>
    </div>
</template>

<script>
export default {
    name: 'all-contacts-list'
}
</script>

<script setup>
    import {ref, computed, defineProps, defineEmits } from "vue";
    import { useStore } from "vuex";
    import CompanyContactPrefixCell from "./CompanyContactPrefixCell.vue";
    import CompanyContactFirstNameCell from './CompanyContactFirstNameCell.vue';
    import CompanyContactMiddleNameCell from "./CompanyContactMiddleNameCell.vue";
    import CompanyContactLastNameCell from './CompanyContactLastNameCell.vue';
    import CompanyContactSuffixCell from './CompanyContactSuffixCell.vue';
    import CompanyContactTitleCell from './CompanyContactTitleCell.vue';
    import CompanyContactPersonalEmailCell from './CompanyContactPersonalEmailCell.vue';
    import CompanyContactPhoneCell from './CompanyContactPhoneCell.vue';
    import ContactLinkedInCell from "../contact/contact-cell-components/ContactLinkedInCell.vue";
    import CompanyContactPersonalNotesCell from './CompanyContactPersonalNotesCell.vue';
    import CompanyContactIsPrimaryCell from "./CompanyContactIsPrimaryCell.vue";
    import CompanyContactForm from "../CompanyContactForm.vue";
    import CompanyContactBusinessEmailCell from "./CompanyContactBusinessEmailCell.vue";
    import CompanyContactBusinessPhoneCell from "./CompanyContactBusinessPhoneCell.vue";
    import CompanyContactBusinessNotesCell from "./CompanyContactBusinessNotesCell.vue";
    import CompanyContactMarkEmailVerifiedCell from "./CompanyContactMarkEmailVerifiedCell.vue";
    import CompanyContactPreferredNameCell from "./CompanyContactPreferredNameCell.vue";
    import {updateContactCompanyRelationship} from '@/services/Contact.js'
  
    const store = useStore()

    const formRows = ref([])
  
    const props = defineProps({
        company: Object
    })

    const showContactForm = ref(false)
  
    const emit = defineEmits(['refetch-companies'])
  
    function fetchNewCompanies () {
        emit('refetch-companies')
    }
  
    const contacts = computed(() => {
        return props.company.contacts.filter(contact => contact.company_relationships.length > 0)
    })

    function contactCompanyRelationships (contact) {
        return contact.company_relationships.filter(relationship => relationship.company_id == props.company.id)[0]
    }

    function addFormRow(){
        formRows.value.push(
            {key: Math.random(), company: props.company}
            )
    }
    function closeContactRow(index) {
        formRows.value.splice(index, 1)
    }

    async function removeCompanyContact(contact, contactRelationship){
        const confirm_message = `You are about to archive ${contact.first_name} ${contact.last_name}, ${contactRelationship.title} at ${props.company.company_name}.\n Do you wish to proceed?`
        if(window.confirm(confirm_message)){
            const token = store.state.auth.token
            const data = JSON.stringify({
                "status": "inactive"
            })
            await updateContactCompanyRelationship(contactRelationship.id, data, token)
            emit('refetch-companies')
        }

    }

</script>
import { onMounted, onBeforeUnmount } from "vue";

export function useClickOutside (elTargetRef, callbackFunction){
    // console.log('elTargetRef', elTargetRef)
    // console.log('callbackFunction', callbackFunction)
    if(!elTargetRef) return;

    let listener = async (e) => {
        if (e.target == elTargetRef.value || e.composedPath().includes(elTargetRef.value)){
            return
        }
        if (typeof callbackFunction == 'function') {
            // console.log('listener fired')
            callbackFunction()
        }
    }

    onMounted(() => {
        window.addEventListener('click', listener)
    })
    // onUpdated(() => {
    //     window.addEventListener('click', listener)
    // })
    onBeforeUnmount(() => {
        window.removeEventListener('click', listener)
    })
    return {
        listener
    }
}